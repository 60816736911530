import React from 'react';
import {
    CircularProgress,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/DoneOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import EditIcon from '@material-ui/icons/Edit';
import TransferIcon from '@material-ui/icons/Redo';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import UndeleteIcon from '@material-ui/icons/RestoreFromTrash';
import classNames from 'classnames';
// import { sortBy } from 'lodash';
import { withSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/Add';
import {
    compact,
    sortBy,
    uniq,
    uniqBy,
} from 'lodash';
// import ProductEditDialog from './productEditDialog';
// import EngineerExpireDialog from './engineerExpireDialog';

const styles = (theme) => ({
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
        minHeight: 100,
        paddingTop: theme.spacing.unit * 3,
    },
    progress: {
        opacity: '.5',
    },
    tableTitleLeft: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        alignItems: 'left',
        paddingTop: theme.spacing.unit * 2,
        fontWeight: 500,
        textTransform: 'uppercase',
        paddingBottom: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 2,
    },
    tableTitleRight: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        alignItems: 'right',
        paddingTop: theme.spacing.unit * 2,
        fontWeight: 500,
        textTransform: 'uppercase',
        paddingBottom: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    shortDescription: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
    noWrap: {
        whiteSpace: 'nowrap',
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    noWrapPlain: {
        whiteSpace: 'nowrap',
    },
    filterIcon: {
        width: 16,
    },
    filterButton: {
        marginLeft: theme.spacing.unit * 3,
    },
    orderDirectionIcon: {
        marginBottom: -10,
    },
    pageDetail: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        paddingTop: 13,
        fontWeight: 500,
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        textTransform: 'uppercase',
        paddingBottom: theme.spacing.unit * 2,
    },
    hidden: {
        display: 'none',
    },
    downloadIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 20,
        marginLeft: 'auto',
        marginTop: 16,
        marginRight: 16,
    },
    topControls: {
        marginTop: theme.spacing.unit * 3,
    },
    noDataText: {
        padding: theme.spacing.unit * 3,
    },
    greenChip: {
        backgroundColor: 'green',
        color: 'white',
        height: theme.spacing.unit * 3,
        marginTop: -4,
    },
    blackTableIcons: {
        color: '#000000',
        fontSize: 22,
        opacity: 0.6,
    },
    colourTableIcons: {
        fontSize: 22,
        opacity: 1,
    },
    pass: {
        color: theme.palette.primary.testPass,
    },
    fail: {
        color: theme.palette.primary.testFail,
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogContent: {
        paddingBottom: 16,
        minWidth: 600,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 4,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '8px 24px',
        margin: '0px 0px 0px 0px !important',
    },
    dialogText: {
        marginTop: theme.spacing.unit * 2,
    },
    dialogDivider: {
        marginBottom: theme.spacing.unit * 2,
        marginLeft: -24,
        marginRight: -24,
        marginTop: theme.spacing.unit * 2,
    },
    disabled: {
        opacity: 0.26,
    },
    tableExpiredCheckBox: {
        textAlign: 'left',
    },
    addProductButton: {
        textAlign: 'right',
    },
    dialogExpiredCheckBox: {
        textAlign: 'right',
    },
    checkBox: {
        fontSize: 14,
    },
    searchAdornment: {
        // marginTop: 4,
        // marginBottom: 4,
        margin: 'none',
        padding: 'none',
    },
    searchBox: {
        marginBottom: 16,
    },
    searchContainer: {
        paddingLeft: 48,
        textAlign: 'center',
    },
    clearButton: {
        marginTop: 8,
    },
    newButton: {
        marginTop: 8,
    },
    expiredCheckbox: {
        marginTop: 8,
    },
    searchMenuFormControl: {
        marginRight: theme.spacing.unit * 4,
    },
    searchMenu: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 1,
        textAlign: 'left',
    },
    customTableCell: {
        verticalAlign: 'top',
        marginTop: 16,
    },
    lastTableCell: {
        // width: '100%',
    },
    postcodeZonesCell: {
        width: '100%',
        whiteSpace: 'normal',
    },
    menuBox: {
        padding: '15px 14px',
        minWidth: 130,
    },
    menuInputLabel: {
        marginTop: 10,
        background: '#ffffff',
        paddingLeft: 6,
        paddingRight: 6,
        marginLeft: -6,
    },
});

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
}))(TableCell);

class EngineerTable extends React.Component {
    state = {
        currentEngineer: {},
        hideExpiredEngineers: true,
        searchBoxData: {
            branchCode: '',
            searchString: '',
        },
        showEditEngineerDialog: false,
        showExpireEngineerDialog: false,
        showTransferEngineerVisitsDialog: false,
    };

    handleRowClick = (engineerContactCode) => {
        window.open(`/contact/${engineerContactCode}/`);
    };

    // handleExpireEngineerClick = (engineerCode, event) => {
    //     event.stopPropagation();
    //     this.setState({
    //         currentEngineer: this.props.engineers.find((engineer) => engineer.engineer.code === engineerCode),
    //         showExpireEngineerDialog: true,
    //     });
    // };

    // handleEditEngineerClick = (productCode, event) => {
    //     event.stopPropagation();
    //     const currentEngineer = this.props.engineers.find((product) => product.code === productCode);
    //     this.setState({
    //         currentEngineer,
    //         showEditEngineerDialog: true,
    //     });
    // };

    // handleCloseEditProductDialogClick = () => {
    //     this.setState({
    //         showEditEngineerDialog: false,
    //     });
    // };

    // handleCloseExpireProductDialogClick = () => {
    //     this.setState({
    //         showExpireEngineerDialog: false,
    //     });
    // };

    // handleExpireProductCloseDialogClick = () => {
    //     this.setState({
    //         showExpireEngineerDialog: false,
    //     });
    // };

    // handleEditProductCloseDialogClick = () => {
    //     this.setState({
    //         showEditEngineerDialog: false,
    //     });
    // };

    // handleExpireProduct = () => {
    //     this.setState({
    //         disableExpireProductSaveButton: true,
    //     });
    //     const payload = this.state.newPurchaseOrderData;
    //     this.props.expireProduct(this.state.currentEngineer.code).then(() => {
    //         this.setState({
    //             disableExpireProductSaveButton: false,
    //             showExpireEngineerDialog: false,
    //         });
    //         this.props.enqueueSnackbar('Expire Manufacturer/Supplier Success', { variant: 'success' });
    //     }).catch(() => {
    //         this.props.enqueueSnackbar('Expire Manufacturer/Supplier Failed', { variant: 'error' });
    //         this.setState({
    //             disableExpireProductSaveButton: false,
    //         });
    //     });
    // };

    // updateEditProductData = (event) => {
    //     const { name, value } = event.target;

    //     let formattedValue;
    //     let addressData;
    //     switch (name) {
    //     case 'address1':
    //     case 'address2':
    //     case 'townCity':
    //     case 'county':
    //     case 'postcode':
    //     case 'country':
    //         addressData = {
    //             ...this.state.editProductData.address,
    //             [name]: value,
    //         };
    //         break;
    //     default:
    //         formattedValue = value;
    //         break;
    //     }
    //     event.target.value = formattedValue;

    //     this.setState((state) => ({
    //         editProductData: {
    //             ...state.editProductData,
    //             [name]: formattedValue,
    //             address: {
    //                 ...state.editProductData.address,
    //                 ...addressData,
    //             },
    //         },
    //     }));
    // };

    // handleEditProduct = () => {
    //     this.setState({
    //         disableEditProductSaveButton: true,
    //     });
    //     const payload = this.state.editProductData;
    //     this.props.editProduct(payload).then(() => {
    //         this.setState({
    //             disableEditProductSaveButton: false,
    //             showEditEngineerDialog: false,
    //         });
    //         this.props.enqueueSnackbar('Manufacturer/Supplier Save', { variant: 'success' });
    //     }).catch(() => {
    //         this.props.enqueueSnackbar('Save Manufacturer/Supplier Failed', { variant: 'error' });
    //         this.setState({
    //             disableEditProductSaveButton: false,
    //         });
    //     });
    // };

    // updateEditProductDataCheckbox = (name) => (event) => {
    //     const newState = !!event.target.checked;
    //     this.setState((state) => ({
    //         editProductData: {
    //             ...state.editProductData,
    //             [name]: newState,
    //         },
    //     }));
    // };

    updateHideExpiredEngineersCheckbox = () => (event) => {
        const newState = !!event.target.checked;
        this.setState({
            hideExpiredEngineers: newState,
        });
    };

    updateSearchboxData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            searchBoxData: {
                ...state.searchBoxData,
                [name]: formattedValue,
            },
        }));
    };

    handleClearSearchStringClick = () => {
        this.setState((state) => ({
            searchBoxData: {
                ...state.searchBoxData,
                searchString: '',
            },
        }));
    };

    handleUnexpireEngineer = (productCode, event) => {
        event.stopPropagation();
        this.props.unexpireEngineer(productCode).then(() => {
            this.props.enqueueSnackbar('Expire Manufacturer/Supplier Success', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Expire Manufacturer/Supplier Failed', { variant: 'error' });
        });
    };

    render() {
        const {
            branches,
            classes,
            // errors,
            engineers,
            engineersLoading,
        } = this.props;
        const {
            currentEngineer,
            // disableEditProductSaveButton,
            // disableExpireProductSaveButton,
            // editProductData,
            hideExpiredEngineers,
            searchBoxData,
            showEditEngineerDialog,
            showExpireEngineerDialog,
        } = this.state;

        const displayEngineers = searchBoxData.searchString.length < 3
            ? engineers.reduce((acc, engineer) => {
                if (searchBoxData.branchCode === '') return [...acc, engineer];
                if ((engineer.engineer.branchCode || null) === searchBoxData.branchCode) return [...acc, engineer];
                return [...acc];
            }, [])
            : engineers.filter((engineer) => (
                ((searchBoxData.branchCode === '') || (searchBoxData.branchCode === engineer.engineer.branchCode))
                && ((engineer.firstname.toLowerCase().includes(searchBoxData.searchString.toLowerCase()))
                || ((engineer.surname || '').toLowerCase().includes(searchBoxData.searchString.toLowerCase()))
                || ((branches.find((branch) => branch.code === engineer.engineer.branchCode).name || '').toLowerCase().includes(searchBoxData.searchString.toLowerCase())))));

        const engineerBranches = engineers.map((engineer) => ({ code: engineer.engineer.branchCode, name: branches.find((branch) => branch.code === engineer.engineer.branchCode).name }));
        const displayBranches = uniqBy(engineerBranches, 'code');

        return (
            <>
                <Grid
                    container
                    spacing={0}
                >
                    <Grid
                        item
                        xs={2}
                        className={classes.tableExpiredCheckBox}
                    >
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    name="hideExpiredEngineers"
                                    id="hideExpiredEngineers"
                                    onChange={this.updateHideExpiredEngineersCheckbox()}
                                    checked={hideExpiredEngineers}
                                    value={hideExpiredEngineers}
                                />
                            )}
                            classes={{ label: classes.checkBox }}
                            label="Hide Expired"
                            className={classes.expiredCheckbox}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={8}
                        className={classes.searchContainer}
                    >
                        <FormControl
                            className={classes.searchMenuFormControl}
                            variant="outlined"
                        >
                            <InputLabel htmlFor="branchCode" className={classes.menuInputLabel}>Branch</InputLabel>
                            <Select
                                displayEmpty
                                keyboard="true"
                                classes={{ outlined: classes.menuBox }}
                                input={(
                                    <OutlinedInput
                                        onChange={this.updateSearchboxData}
                                        name="branchCode"
                                        id="branchCode"
                                        className={classes.searchMenu}
                                    />
                                )}
                                value={searchBoxData.branchCode || ''}
                            >
                                <MenuItem value="">
                                    <em>All</em>
                                    {' '}
                                </MenuItem>
                                {sortBy(displayBranches, 'name').map((branch) => (
                                    <MenuItem
                                        key={branch.code}
                                        value={branch.code}
                                    >
                                        {`${branch.name} `}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            autoFocus
                            className={classes.searchBox}
                            name="searchString"
                            id="searchString"
                            onChange={this.updateSearchboxData}
                            margin="dense"
                            variant="outlined"
                            value={searchBoxData.searchString}
                            label="Filter"
                            // inputProps={{
                            //     paddingBottom: 8,
                            //     paddingLeft: 14,
                            //     paddingRight: 14,
                            //     paddingTop: 8,
                            // }}
                            InputProps={{
                                startAdornment:
    <InputAdornment
        disableTypography
        className={classes.searchAdornment}
        position="start"
    >
        <SearchIcon />
    </InputAdornment>,
                            }}

                        />
                        <IconButton
                            className={classes.clearButton}
                            onClick={this.handleClearSearchStringClick}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        className={classes.addProductButton}
                    >
                        <IconButton
                            className={classes.newButton}
                            onClick={this.props.handleCreateProductClick}
                            // disabled={!newPurchaseOrderData.supplierCode}
                        >
                            <AddIcon />
                        </IconButton>

                    </Grid>
                </Grid>
                <Paper>
                    { engineersLoading
                        ? (
                            <div className={classes.progressContainer}>
                                <CircularProgress className={classes.progress} variant="indeterminate" />
                            </div>
                        )
                        : (
                            <>
                                { engineers.length === 0
                                    ? (
                                        <Typography className={classes.noDataText}>
                                            No Products
                                        </Typography>
                                    )
                                    : (
                                        <>
                                            <Table className={classes.table} padding="dense">
                                                <TableHead>
                                                    <TableRow>
                                                        <CustomTableCell className={classes.tableHead}>
                                                            Contact Code
                                                        </CustomTableCell>
                                                        <CustomTableCell className={classes.tableHead}>
                                                            Name
                                                        </CustomTableCell>
                                                        <CustomTableCell className={classes.tableHead}>
                                                            Branch
                                                        </CustomTableCell>
                                                        <CustomTableCell className={classes.tableHead}>
                                                            Future Visits
                                                        </CustomTableCell>
                                                        <CustomTableCell className={classes.tableHead}>
                                                            Postcode Zones
                                                        </CustomTableCell>
                                                        <CustomTableCell className={classes.tableHead} align="right" />
                                                        <CustomTableCell className={classes.tableHead} align="right" />
                                                        <CustomTableCell className={classes.tableHead} align="right" />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {sortBy(displayEngineers, 'name').map((engineer) => (!hideExpiredEngineers || (hideExpiredEngineers && !engineer.engineer.expired)
                                                        ? (
                                                            <TableRow onClick={() => this.handleRowClick(engineer.code)} className={classes.row} key={engineer.code}>
                                                                <CustomTableCell
                                                                    className={classNames(
                                                                        classes.customTableCell,
                                                                        {
                                                                            [classes.disabled]: engineer.engineer.expired,
                                                                        },
                                                                    )}
                                                                    align="left"
                                                                >
                                                                    C
                                                                    {engineer.code}
                                                                </CustomTableCell>
                                                                <CustomTableCell
                                                                    className={classNames(
                                                                        classes.customTableCell,
                                                                        {
                                                                            [classes.disabled]: engineer.engineer.expired,
                                                                        },
                                                                    )}
                                                                    align="left"
                                                                >
                                                                    {`${engineer.firstname} ${engineer.surname}`}
                                                                </CustomTableCell>
                                                                <CustomTableCell
                                                                    className={classNames(
                                                                        classes.customTableCell,
                                                                        {
                                                                            [classes.disabled]: engineer.engineer.expired,
                                                                        },
                                                                    )}
                                                                    align="left"
                                                                >
                                                                    {branches.find((branch) => branch.code === engineer.engineer.branchCode).name}
                                                                </CustomTableCell>
                                                                <CustomTableCell
                                                                    className={classNames(
                                                                        classes.customTableCell,
                                                                        {
                                                                            [classes.disabled]: engineer.engineer.expired,
                                                                        },
                                                                    )}
                                                                    align="left"
                                                                >
                                                                    {engineer.engineer.futureVisitCodes.length || 0}
                                                                </CustomTableCell>
                                                                <CustomTableCell
                                                                    className={classNames(
                                                                        classes.postcodeZonesCell,
                                                                        {
                                                                            [classes.disabled]: engineer.engineer.expired,
                                                                        },
                                                                    )}
                                                                    align="left"
                                                                >
                                                                    {engineer.engineer.postcodeZones.join(', ')}
                                                                </CustomTableCell>
                                                                <CustomTableCell align="left">
                                                                    <IconButton
                                                                        id={engineer.engineer.code}
                                                                        onClick={(event) => this.props.handleEditEngineerClick(engineer.engineer.code, event)}
                                                                        disabled
                                                                    >
                                                                        <EditIcon className={classNames(
                                                                            classes.blackTableIcons,
                                                                            classes.disabled,
                                                                        )}
                                                                        />
                                                                    </IconButton>
                                                                </CustomTableCell>
                                                                <CustomTableCell align="left">
                                                                    {engineer.engineer.futureVisitCodes.length > 0
                                                                        ? (
                                                                            <IconButton
                                                                                id={engineer.code}
                                                                                onClick={(event) => this.props.handleTransferEngineerVisitsClick(engineer.engineer.code, event)}
                                                                            >
                                                                                <TransferIcon className={classNames(
                                                                                    classes.blackTableIcons,
                                                                                )}
                                                                                />
                                                                            </IconButton>
                                                                        )
                                                                        : ''}
                                                                </CustomTableCell>
                                                                <CustomTableCell className={classes.lastTableCell} align="right">
                                                                    {!engineer.engineer.expired
                                                                        ? (
                                                                            <>
                                                                                {engineer.engineer.futureVisitCodes.length === 0
                                                                                    ? (
                                                                                        <IconButton id={engineer.code} onClick={(event) => this.props.handleExpireEngineerClick(engineer.engineer.code, event)}>
                                                                                            <DeleteIcon className={classes.blackTableIcons} />
                                                                                        </IconButton>
                                                                                    )
                                                                                    : ''}
                                                                            </>
                                                                        )
                                                                        : (
                                                                            <IconButton id={engineer.code} onClick={(event) => this.handleUnexpireEngineer(engineer.engineer.code, event)}>
                                                                                <UndeleteIcon className={classes.blackTableIcons} />
                                                                            </IconButton>
                                                                        )}
                                                                </CustomTableCell>
                                                            </TableRow>
                                                        )
                                                        : ''))}
                                                </TableBody>
                                            </Table>
                                        </>
                                    )}
                            </>
                        )}
                </Paper>

                {/* <ProductEditDialog
                    handleCloseEditProductDialogClick={this.handleCloseEditProductDialogClick}
                    product={currentEngineer}
                    productLoading={engineersLoading}
                    showEditEngineerDialog={showEditEngineerDialog}
                    passNewProduct={this.props.passNewProduct}
                />
                <EngineerExpireDialog
                    handleCloseExpireEngineerDialogClick={this.handleCloseExpireEngineerDialogClick}
                    handleExpireEngineerCloseDialogClick={this.handleExpireEngineerCloseDialogClick}
                    engineer={currentEngineer}
                    engineerLoading={engineersLoading}
                    passNewEngineer={this.props.passNewEngineer}
                    showExpireEngineerDialog={showExpireEngineerDialog}
                /> */}
            </>

        );
    }
}

export default withSnackbar(withStyles(styles)(EngineerTable));
