import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { DatePicker } from 'material-ui-pickers';
import sortBy from 'lodash/sortBy';
import AddIcon from '@material-ui/icons/Add';
import uniq from 'lodash/uniq';
import moment from 'moment-timezone';
import fetch, { fetchJson2 } from '../../utilities/fetch';
import commaFormattedDecimal from '../../utilities/commaFormattedDecimal';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    dialogContentContainerLarge: {
        width: 800,
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    dialogContentContainer: {
        // width: 600,
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    dialogContent: {
        minWidth: 600,
        paddingBottom: 16,
    },
    dialogButton: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 4,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        margin: '0px 0px 0px 0px !important',
        padding: '8px 24px',
    },
    dialogDivider: {
        marginBottom: theme.spacing.unit * 2,
        marginLeft: -24,
        marginRight: -24,
        marginTop: theme.spacing.unit * 2,
    },
    disabled: {
        opacity: 0.26,
    },
    dialogExpiredCheckBox: {
        textAlign: 'right',
    },
    checkBox: {
        fontSize: 16,
    },
    dialogTitle: {
        marginBottom: theme.spacing.unit * 2,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    inputLabel: {
        backgroundColor: '#fff',
        marginLeft: -5,
        paddingLeft: 8,
        paddingRight: 8,
    },
    fieldAddButton: {
        marginLeft: 24,
        marginTop: 24,
    },
    dialogAddButton: {
        marginLeft: 24,
        marginTop: 8,
    },
    dialogAddButtonContainer: {
        textAlign: 'right',
    },
    radioFormControl: {
        marginTop: 16,
    },
    itemsContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    itemsAddButtonContainer: {
        textAlign: 'right',
    },
    itemsTitleContainer: {
        marginTop: 13,
    },
});

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
}))(TableCell);

class PurchaseOrderCreateDialog extends React.Component {
    state = {
        addPurchaseOrderItemData: {},
        branchesLoading: true,
        costCentresLoading: true,
        createPurchaseOrderData: {
            deliveryDateType: 'ASAP',
            items: [],
        },
        createManufacturerData: {
            name: '',
        },
        createProductData: {
            manufacturerCode: '',
            modelNo: '',
        },
        createSupplierData: {
            name: '',
        },
        disableCreatePurchaseOrderSaveButton: false,
        disableCreateManufacturerSaveButton: false,
        disableCreateProductSaveButton: false,
        disableCreateSupplierSaveButton: false,
        errors: [],
        manufacturersLoading: true,
        productsLoading: true,
        showAddPurchaseOrderItemDialog: false,
        showAllProducts: false,
        showCreateSupplierDialog: false,
        showCreateProductDialog: false,
        showCreateManufacturerDialog: false,
        suppliersLoading: true,
    };

    componentDidMount() {
        fetchJson2('v2/costCentres')
            .then((costCentres) => {
                this.setState({
                    costCentres: costCentres.payload,
                    costCentresLoading: false,
                });
            });
        fetchJson2('v2/suppliers?expired=false')
            .then((suppliers) => {
                this.setState({
                    suppliers: suppliers.payload,
                    suppliersLoading: false,
                });
            });
        fetchJson2('v2/branches/?branchType=BUILDING')
            .then((branches) => {
                this.setState({
                    branches: branches.payload,
                    branchesLoading: false,
                });
            });
        fetchJson2('v2/products')
            .then((products) => {
                this.setState({
                    products: products.payload,
                    productsLoading: false,
                // manufacturers: sortBy(uniqBy(products.payload.map((product) => ({ code: product.manufacturer.code, name: product.manufacturer.name })), 'code'), 'name'),
                });
            });
        fetchJson2('v2/manufacturers')
            .then((manufacturers) => {
                this.setState({
                    manufacturers: sortBy(manufacturers.payload, 'name'),
                    manufacturersLoading: false,
                });
            });
        this.setFormDefaults();
    }

    componentDidUpdate(prevProps) {
        if (this.props.showCreatePurchaseOrderDialog !== prevProps.showCreatePurchaseOrderDialog) {
            this.setFormDefaults();
        }
    }

    setFormDefaults = () => {
        this.setState({
            createPurchaseOrderData: {
                deliveryDateType: 'ASAP',
                items: [],
            },
        });
    };

    updateCreatePurchaseOrderData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        let branchAddressValue;
        let deliveryDateTypeValue;
        switch (name) {
        case 'deliveryFee':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            break;
        case 'deliveryBranchCode': {
            formattedValue = value;
            const currentBranch = this.state.branches.find((branch) => (branch.code === value));
            branchAddressValue = {
                deliveryBranchAddressCode: currentBranch.addressCode,
            };
        }
            break;
        case 'deliveryDateType':
            formattedValue = value;
            if (value === 'ASAP') {
                deliveryDateTypeValue = {
                    requestedDeliveryDate: null,
                };
            }
            break;

        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            createPurchaseOrderData: {
                ...state.createPurchaseOrderData,
                ...branchAddressValue,
                ...deliveryDateTypeValue,
                [name]: formattedValue,
            },
        }));
    };

    updateCreateSupplierData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            createSupplierData: {
                ...state.createSupplierData,
                [name]: formattedValue,
            },
        }));
    };

    updateCreateManufacturerData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            createManufacturerData: {
                ...state.createManufacturerData,
                [name]: formattedValue,
            },
        }));
    };

    updateCreateProductData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        case 'modelNo':
            formattedValue = value.toUpperCase().replace(/ /g, '-');
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            createProductData: {
                ...state.createProductData,
                [name]: formattedValue,
            },
        }));
    };

    updateAddPurchaseOrderItemData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        let costPriceValue;
        let quantityValue;
        switch (name) {
        case 'costPrice':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            break;
        case 'quantity':
            formattedValue = value.replace(/[^0-9$]/g, '');
            break;
        case 'productCode': {
            formattedValue = value;
            const currentProduct = this.state.products.find((product) => (product.code === value));
            const length = currentProduct.defaultLength;
            const width = currentProduct.defaultWidth;
            costPriceValue = {
                costPrice: currentProduct.defaultCostPrice,
                length,
                quantity: currentProduct.productTypeSubCategory === 'Carpet' || currentProduct.productTypeSubCategory === 'Vinyl' ? width * length : this.state.addPurchaseOrderItemData.quantity,
                width,
            };
        }
            break;
        case 'length':
        case 'width': {
            const length = name === 'length' ? value : this.state.addPurchaseOrderItemData.length;
            const width = name === 'width' ? value : this.state.addPurchaseOrderItemData.width;
            formattedValue = value;
            quantityValue = {
                quantity: width * length,
            };
        }
            break;
        case 'manufacturerCode':
            formattedValue = value;
            costPriceValue = {
                costPrice: 0,
            };
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            addPurchaseOrderItemData: {
                ...state.addPurchaseOrderItemData,
                ...costPriceValue,
                ...quantityValue,
                [name]: formattedValue,
            },
        }));
    };

    handleRequestedDeliveryDateChange = (chosenDate) => {
        this.setState((state) => ({
            createPurchaseOrderData: {
                ...state.createPurchaseOrderData,
                requestedDeliveryDate: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    handleCreateManufacturerSupplier = () => {
        this.setState({
            disableCreatePurchaseOrderSaveButton: true,
        });
        const payload = this.state.createPurchaseOrderData;
        this.createManufacturerSupplier(payload).then(() => {
            this.setState({
                disableCreatePurchaseOrderSaveButton: false,
            });
            this.props.handleCloseCreateManufacturerSupplierDialogClick();
            this.props.enqueueSnackbar('Manufacturer/Supplier Created', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Create Manufacturer/Supplier Failed', { variant: 'error' });
            this.setState({
                disableCreatePurchaseOrderSaveButton: false,
            });
        });
    };

    updateCreateManufacturerSupplierDataCheckbox = (name) => (event) => {
        const newState = !!event.target.checked;
        this.setState((state) => ({
            createPurchaseOrderData: {
                ...state.createPurchaseOrderData,
                [name]: newState,
            },
        }));
    };

    createManufacturerSupplier = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/manufacturerSupplier/`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
              && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const createManufacturerSupplier = {
                ...body.manufacturerSupplier,
                histories: [
                    body.history,
                ],
            };
            this.props.passCreateManufacturerSupplier(createManufacturerSupplier);
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    handleCreateSupplierClick = () => {
        this.setState({
            createSupplierData: {
                name: '',
            },
            showCreateSupplierDialog: true,
        });
    };

    handleCreateManufacturerClick = () => {
        this.setState({
            createManufacturerData: {
                name: '',
            },
            showCreateManufacturerDialog: true,
        });
    };

    handleCreateProductClick = () => {
        this.setState((state) => ({
            createProductData: {
                manufacturerCode: state.addPurchaseOrderItemData.manufacturerCode,
                modelNo: '',
            },
            showCreateProductDialog: true,
        }));
    };

    handleAddPurchaseOrderItemClick = () => {
        const currentSupplierProducts = this.state.products.filter((product) => (!!product.supplierCodes.find((supplierCode) => supplierCode === this.state.createPurchaseOrderData.supplierCode)));
        const currentSupplierManufacturers = uniq(currentSupplierProducts.reduce((acc, currentSupplierProduct) => [...acc, this.state.manufacturers.find((manufacturer) => manufacturer.code === currentSupplierProduct.manufacturer.code)], []));

        this.setState((state) => ({
            addPurchaseOrderItemData: {
                costPrice: 0,
                // manufacturerCode: state.manufacturers[0].code,
                manufacturerCode: (currentSupplierManufacturers[0] || {}).code,
                productCode: (currentSupplierProducts[0] || {}).code,
                quantity: 1,
                unitType: 'ROLL',
            },
            showAddPurchaseOrderItemDialog: true,
        }));
    };

    handleAddPurchaseOrderItemCloseDialogClick = () => {
        this.setState({
            showAddPurchaseOrderItemDialog: false,
        });
    };

    handleCreateSupplierCloseDialogClick = () => {
        this.setState({
            showCreateSupplierDialog: false,
        });
    };

    handleCreateManufacturerCloseDialogClick = () => {
        this.setState({
            showCreateManufacturerDialog: false,
        });
    };

    handleCreateProductCloseDialogClick = () => {
        this.setState({
            showCreateProductDialog: false,
        });
    };

    handleSubmitCreateSupplier = () => {
        this.setState({
            disableCreateSupplierSaveButton: true,
        });
        const payload = this.state.createSupplierData;
        this.submitCreateSupplier(payload).then(() => {
            this.setState({
                disableCreateSupplierSaveButton: false,
                showCreateSupplierDialog: false,
            });
            this.props.enqueueSnackbar('New Supplier Created', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Create New Supplier Failed', { variant: 'error' });
            this.setState({
                disableCreateSupplierSaveButton: false,
            });
        });
    };

    submitCreateSupplier = async (payload) => {
        let response;
        let body;
        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/supplier`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
              && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                suppliers: [
                    ...state.suppliers,
                    body,
                ],
                createPurchaseOrderData: {
                    ...state.createPurchaseOrderData,
                    supplierCode: body.code,
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    addPurchaseOrderItem = () => {
        const length = this.state.addPurchaseOrderItemData.unitType === 'ROLL' ? 20 : this.state.addPurchaseOrderItemData.length;
        this.setState((state) => ({
            createPurchaseOrderData: {
                ...state.createPurchaseOrderData,
                items: [
                    ...state.createPurchaseOrderData.items,
                    {
                        costPrice: parseFloat(state.addPurchaseOrderItemData.costPrice),
                        length: parseFloat(length),
                        productCode: parseInt(state.addPurchaseOrderItemData.productCode, 10),
                        quantity: parseInt(state.addPurchaseOrderItemData.quantity, 10),
                        reference: state.addPurchaseOrderItemData.reference || null,
                        unitType: state.addPurchaseOrderItemData.unitType,
                        width: parseFloat(state.addPurchaseOrderItemData.width),
                    },
                ],
            },
            showAddPurchaseOrderItemDialog: false,
        }));
    };

    handleSubmitPurchaseOrder = () => {
        this.setState({
            disableCreatePurchaseOrderSaveButton: true,
        });
        const payload = this.state.createPurchaseOrderData;
        this.submitPurchaseOrder(payload).then(() => {
            this.setState({
                disableCreatePurchaseOrderSaveButton: false,
            });
            this.props.handleCloseCreatePurchaseOrderDialogClick();
            this.props.enqueueSnackbar('Purchase Order submitted', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Purchase Order submit failed', { variant: 'error' });
            this.setState({
                disableCreatePurchaseOrderSaveButton: false,
            });
        });
    };

    submitPurchaseOrder = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/purchaseOrder`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
              && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            const createPurchaseOrder = {
                ...body.purchaseOrder,
                histories: [
                    body.history,
                ],
            };
            this.props.passNewPurchaseOrder(createPurchaseOrder);
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    updateShowAllProductsCheckbox = () => (event) => {
        const newState = !!event.target.checked;
        this.setState({
            showAllProducts: newState,
        });
    };

    handleSubmitCreateProduct = () => {
        this.setState({
            disableCreateProductSaveButton: true,
        });
        const payload = this.state.createProductData;
        this.submitCreateProduct(payload).then(() => {
            this.setState({
                disableCreateProductSaveButton: false,
                showCreateProductDialog: false,
            });
            this.props.enqueueSnackbar('New Product Created', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Create New Product Failed', { variant: 'error' });
            this.setState({
                disableCreateProductSaveButton: false,
            });
        });
    };

    submitCreateProduct = async (payload) => {
        let response;
        let body;
        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/product`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
              && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                products: [
                    ...state.products,
                    body.product,
                ],
                addPurchaseOrderItemData: {
                    ...state.addPurchaseOrderItemData,
                    manufacturerCode: body.product.manufacturer.code,
                    productCode: body.product.code,
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    render() {
        const {
            classes,
            purchaseOrdersLoading,
            showCreatePurchaseOrderDialog,
        } = this.props;

        const {
            branches,
            branchesLoading,
            costCentres,
            costCentresLoading,
            createManufacturerData,
            createProductData,
            createPurchaseOrderData,
            createSupplierData,
            addPurchaseOrderItemData,
            disableCreatePurchaseOrderSaveButton,
            disableCreateManufacturerSaveButton,
            disableCreateProductSaveButton,
            disableCreateSupplierSaveButton,
            errors,
            manufacturers,
            manufacturersLoading,
            products,
            productsLoading,
            showAddPurchaseOrderItemDialog,
            showAllProducts,
            showCreateManufacturerDialog,
            showCreateProductDialog,
            showCreateSupplierDialog,
            suppliers,
            suppliersLoading,
        } = this.state;

        let currentAddPurchaseOrderItemProduct = {};
        if (addPurchaseOrderItemData.productCode) {
            currentAddPurchaseOrderItemProduct = products.find((product) => product.code === addPurchaseOrderItemData.productCode);
        }

        let createPurchaseOrderCostPriceNetTotal = 0;
        let createPurchaseOrderCostPriceTotalVAT = 0;
        let createPurchaseOrderCostPriceTotal = 0;
        if (createPurchaseOrderData.items.length > 0) {
            createPurchaseOrderCostPriceNetTotal = createPurchaseOrderData.items.reduce((acc, item) => (acc + (item.quantity * item.costPrice)), 0);
            createPurchaseOrderCostPriceTotalVAT = (createPurchaseOrderCostPriceNetTotal + (parseInt(createPurchaseOrderData.deliveryFee, 10) || 0)) * 0.2;
            createPurchaseOrderCostPriceTotal = createPurchaseOrderCostPriceNetTotal + (parseInt(createPurchaseOrderData.deliveryFee, 10) || 0) + createPurchaseOrderCostPriceTotalVAT;
        }
        let currentSupplierProducts = [];
        let currentSupplierManufacturers = [];
        let nonNormalUnitTypeItemsOnPurchaseOrder = false;
        if (!productsLoading) {
            currentSupplierProducts = showAllProducts ? products : products.filter((product) => (!!product.supplierCodes.find((supplierCode) => supplierCode === createPurchaseOrderData.supplierCode)));
            currentSupplierManufacturers = uniq(currentSupplierProducts.reduce((acc, currentSupplierProduct) => [...acc, manufacturers.find((manufacturer) => manufacturer.code === currentSupplierProduct.manufacturer.code)], []));
            nonNormalUnitTypeItemsOnPurchaseOrder = !!this.state.createPurchaseOrderData.items.filter((item) => item.unitType !== 'NORMAL').length;
            // console.log('>>> currentSupplierManufacturers:', currentSupplierManufacturers);
            // console.log('>>> currentSupplierProducts:', currentSupplierProducts);
            console.log('>>> nonNormalUnitTypeItemsOnPurchaseOrder:', nonNormalUnitTypeItemsOnPurchaseOrder);
        }

        // console.log('>>> disableCreateSupplierSaveButton:', disableCreateSupplierSaveButton);
        // console.log('>>> createSupplierData.name.length:', createSupplierData.name.length === 0);
        // console.log('>>> Duplicate: ', createSupplierData.name ? !!suppliers.find((supplier) => supplier.name.toLowerCase() === createSupplierData.name.toLowerCase()) : false);

        return (
            <>
                {!purchaseOrdersLoading
                    && !branchesLoading
                    && !costCentresLoading
                    && !suppliersLoading
                    && !productsLoading
                    && !manufacturersLoading ? (
                        <>
                            <Dialog
                                open={showCreatePurchaseOrderDialog}
                                onClose={this.props.handleCloseCreatePurchaseOrderDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                className={classes.createPurchaseOrderDialog}
                                maxWidth="lg"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainerLarge}>
                                        <>
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                                className={classes.dialogTitle}
                                            >
                                                New Purchase Order
                                            </Typography>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.costCentreCode}
                                                    >
                                                        <InputLabel htmlFor="costCentreCode" className={classes.inputLabel}>Cost Centre</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateCreatePurchaseOrderData}
                                                                    name="costCentreCode"
                                                                    id="costCentreCode"
                                                                />
                                                            )}
                                                            value={createPurchaseOrderData.costCentreCode || ''}
                                                        >
                                                            <MenuItem value=""><em>None</em></MenuItem>
                                                            {costCentres.map((costCentre, i) => (
                                                                <MenuItem
                                                                    key={i}
                                                                    value={costCentre.code}
                                                                >
                                                                    {`${costCentre.name} (${costCentre.description})`}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.costCentreCode && (
                                                            <FormHelperText>{errors.costCentreCode}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={10}>
                                                        <FormControl
                                                            className={classes.menuFormControl}
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.supplier}
                                                        >
                                                            <InputLabel htmlFor="supplier" className={classes.inputLabel}>Supplier</InputLabel>
                                                            <Select
                                                                keyboard="true"
                                                                input={(
                                                                    <OutlinedInput
                                                                        onChange={this.updateCreatePurchaseOrderData}
                                                                        name="supplierCode"
                                                                        id="supplierCode"
                                                                    />
                                                                )}
                                                                value={createPurchaseOrderData.supplierCode || ''}
                                                            >
                                                                {sortBy(suppliers, 'name').map((supplier, i) => (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={supplier.code}
                                                                    >
                                                                        {supplier.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {errors.supplier && (
                                                                <FormHelperText>{errors.supplier}</FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Button
                                                            className={classes.fieldAddButton}
                                                            onClick={this.handleCreateSupplierClick}
                                                        >
                                                            NEW

                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.deliveryBranchCode}
                                                    >
                                                        <InputLabel htmlFor="deliveryBranchCode" className={classes.inputLabel}>Delivery Branch</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateCreatePurchaseOrderData}
                                                                    name="deliveryBranchCode"
                                                                    id="deliveryBranchCode"
                                                                />
                                                            )}
                                                            value={createPurchaseOrderData.deliveryBranchCode || ''}
                                                        >
                                                            <MenuItem value=""><em>None</em></MenuItem>
                                                            {branches.map((branch, i) => (
                                                                <MenuItem
                                                                    key={i}
                                                                    value={branch.code}
                                                                >
                                                                    {branch.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.deliveryBranchCode && (
                                                            <FormHelperText>{errors.deliveryBranchCode}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset" className={`${classes.formControl} ${classes.radioFormControl}`}>
                                                        <FormLabel component="legend">Delivery Date</FormLabel>
                                                        <RadioGroup
                                                            aria-label="address change type"
                                                            name="deliveryDateType"
                                                            value={createPurchaseOrderData.deliveryDateType}
                                                            onChange={this.updateCreatePurchaseOrderData}
                                                            className={classes.radioGroup}
                                                        >
                                                            <FormControlLabel
                                                                value="ASAP"
                                                                control={<Radio color="primary" />}
                                                                label="ASAP"
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                // eslint-disable-next-line react/jsx-boolean-value
                                                                value="DATE"
                                                                control={<Radio color="primary" />}
                                                                label="Request Date"
                                                                labelPlacement="end"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>

                                                {createPurchaseOrderData.deliveryDateType === 'DATE'
                                                    ? (
                                                        <Grid item xs={12}>
                                                            <DatePicker
                                                                name="requestedDeliveryDate"
                                                                id="requestedDeliveryDate"
                                                                margin="normal"
                                                                label="Requested Delivery Date"
                                                                value={createPurchaseOrderData.requestedDeliveryDate}
                                                                onChange={this.handleRequestedDeliveryDateChange}
                                                                fullWidth
                                                                variant="outlined"
                                                                format="DD-MM-YYYY"
                                                                showTodayButton
                                                            />
                                                        </Grid>
                                                    )
                                                    : ''}
                                                <Grid container spacing={0} className={classes.itemsContainer}>
                                                    <Grid item xs={6} className={classes.itemsTitleContainer}>
                                                        <DialogContentText>Items</DialogContentText>
                                                    </Grid>
                                                    <Grid item xs={6} className={classes.itemsAddButtonContainer}>
                                                        <IconButton
                                                            className={classes.button}
                                                            onClick={this.handleAddPurchaseOrderItemClick}
                                                            disabled={!createPurchaseOrderData.supplierCode}
                                                        >
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {createPurchaseOrderData.items.length > 0
                                                            ? (
                                                                <Table className={classes.table} padding="dense">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <CustomTableCell className={classes.tableHead}>
                                                                                #
                                                                            </CustomTableCell>
                                                                            <CustomTableCell className={classes.tableHead} align="left">
                                                                                Manufacturer
                                                                            </CustomTableCell>
                                                                            <CustomTableCell className={classes.tableHead} align="left">
                                                                                Model Number
                                                                            </CustomTableCell>
                                                                            <CustomTableCell className={classes.tableHead} align="right">
                                                                                Unit Cost
                                                                            </CustomTableCell>
                                                                            <CustomTableCell className={classes.tableHead} align="right">
                                                                                Quantity
                                                                            </CustomTableCell>
                                                                            <CustomTableCell className={classes.tableHead} align="right">
                                                                                Total
                                                                            </CustomTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {createPurchaseOrderData.items.map((item, i) => (
                                                                            <TableRow className={classes.row} key={item.code}>
                                                                                <CustomTableCell
                                                                                    className={classes.customTableCell}
                                                                                    align="left"
                                                                                >
                                                                                    {i + 1}
                                                                                </CustomTableCell>
                                                                                <CustomTableCell
                                                                                    className={classes.customTableCell}
                                                                                    align="left"
                                                                                >
                                                                                    {products.find((product) => product.code === item.productCode).manufacturer.name}
                                                                                </CustomTableCell>
                                                                                <CustomTableCell
                                                                                    className={classes.customTableCell}
                                                                                    align="left"
                                                                                >
                                                                                    {products.find((product) => product.code === item.productCode).modelNo}
                                                                                </CustomTableCell>
                                                                                <CustomTableCell
                                                                                    className={classes.customTableCell}
                                                                                    align="right"
                                                                                >
                                                                                    £
                                                                                    {item.costPrice.toFixed(2)}
                                                                                </CustomTableCell>
                                                                                <CustomTableCell
                                                                                    className={classes.customTableCell}
                                                                                    align="right"
                                                                                >
                                                                                    {`${item.quantity} ${item.unitType === 'NORMAL' ? '' : ` x ${item.unitType}`}`}
                                                                                </CustomTableCell>
                                                                                <CustomTableCell
                                                                                    className={classes.customTableCell}
                                                                                    align="right"
                                                                                >
                                                                                    {item.unitType === 'ROLL'
                                                                                        ? (
                                                                                            <>
                                                                                                n/a
                                                                                            </>
                                                                                        )
                                                                                        : (
                                                                                            <>
                                                                                                £
                                                                                                {(item.quantity * item.costPrice).toFixed(2)}
                                                                                            </>
                                                                                        )}
                                                                                </CustomTableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                            )
                                                            : (
                                                                <DialogContentText>No Items Found</DialogContentText>
                                                            )}
                                                    </Grid>

                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={`${classes.formControl} ${classes.marginTop3x}`}
                                                        name="createPurchaseOrderCostPriceNetTotal"
                                                        id="createPurchaseOrderCostPriceNetTotal"
                                                        value={nonNormalUnitTypeItemsOnPurchaseOrder ? 'n/a' : commaFormattedDecimal(createPurchaseOrderCostPriceNetTotal.toFixed(2))}
                                                        label="Net Total"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled
                                                        InputProps={{
                                                            startAdornment:
                            <InputAdornment
                                disableTypography
                                className={classes.inputAdornment}
                                position="start"
                            >
                                £
                            </InputAdornment>,
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={`${classes.formControl} ${classes.marginTop3x}`}
                                                        name="deliveryFee"
                                                        id="deliveryFee"
                                                        value={createPurchaseOrderData.deliveryFee}
                                                        onChange={this.updateCreatePurchaseOrderData}
                                                        label="Delivery Fee"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.deliveryFee}
                                                        helperText={errors.deliveryFee}
                                                        InputProps={{
                                                            startAdornment:
                            <InputAdornment
                                disableTypography
                                className={classes.inputAdornment}
                                position="start"
                            >
                                £
                            </InputAdornment>,
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={`${classes.formControl} ${classes.marginTop3x}`}
                                                        name="createPurchaseOrderCostPriceTotalVAT"
                                                        id="createPurchaseOrderCostPriceTotalVAT"
                                                        value={nonNormalUnitTypeItemsOnPurchaseOrder ? 'n/a' : commaFormattedDecimal(createPurchaseOrderCostPriceTotalVAT.toFixed(2))}
                                                        label="VAT"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled
                                                        InputProps={{
                                                            startAdornment:
                            <InputAdornment
                                disableTypography
                                className={classes.inputAdornment}
                                position="start"
                            >
                                £
                            </InputAdornment>,
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={`${classes.formControl} ${classes.marginTop3x}`}
                                                        name="createPurchaseOrderCostPriceTotal"
                                                        id="createPurchaseOrderCostPriceTotal"
                                                        value={nonNormalUnitTypeItemsOnPurchaseOrder ? 'n/a' : commaFormattedDecimal(createPurchaseOrderCostPriceTotal.toFixed(2))}
                                                        label="Total"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled
                                                        InputProps={{
                                                            startAdornment:
                            <InputAdornment
                                disableTypography
                                className={classes.inputAdornment}
                                position="start"
                            >
                                £
                            </InputAdornment>,
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="quoteReference"
                                                        id="quoteReference"
                                                        onChange={this.updateCreatePurchaseOrderData}
                                                        value={createPurchaseOrderData.quoteReference}
                                                        label="Quote Reference"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.quoteReference}
                                                        helperText={errors.quoteReference}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="supplierContact"
                                                        id="supplierContact"
                                                        onChange={this.updateCreatePurchaseOrderData}
                                                        value={createPurchaseOrderData.supplierContact}
                                                        label="Supplier Contact"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.supplierContact}
                                                        helperText={errors.supplierContact}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="specialInstructions"
                                                        id="specialInstructions"
                                                        onChange={this.updateCreatePurchaseOrderData}
                                                        value={createPurchaseOrderData.specialInstructions}
                                                        label="Special Instructions"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        multiline
                                                        rows="3"
                                                        error={!!errors.specialInstructions}
                                                        helperText={errors.specialInstructions}
                                                    />
                                                </Grid>

                                            </Grid>

                                            <Grid
                                                container
                                                spacing={0}
                                                direction="column"
                                                alignItems="center"
                                                justify="center"
                                            >
                                                <Grid item xs={12}>
                                                    <Button
                                                        className={classes.dialogButton}
                                                        type="submit"
                                                        disabled={disableCreatePurchaseOrderSaveButton}
                                                        onClick={this.handleSubmitPurchaseOrder}
                                                    >
                                                        SUBMIT
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    </div>
                                </DialogContent>
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.props.handleCloseCreatePurchaseOrderDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showAddPurchaseOrderItemDialog}
                                onClose={this.handleAddPurchaseOrderItemCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                className={classes.createPurchaseOrderDialog}
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>

                                                    <Typography
                                                        variant="h6"
                                                        gutterBottom
                                                        className={classes.dialogTitle}
                                                    >
                                                        Add Purchase Order Item
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={0}>
                                                <Grid item xs={6}>

                                                    <FormControlLabel
                                                        control={(
                                                            <Checkbox
                                                                name="showAllProducts"
                                                                id="showAllProducts"
                                                                onChange={this.updateShowAllProductsCheckbox()}
                                                                checked={showAllProducts}
                                                                value={showAllProducts}
                                                            />
                                                        )}
                                                        classes={{ label: classes.checkBox }}
                                                        label="Show All Products"
                                                        className={classes.expiredCheckbox}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} className={classes.dialogAddButtonContainer}>
                                                    <Button className={classes.dialogAddButton} onClick={this.handleCreateProductClick}>NEW</Button>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.manufacturerCode}
                                                    >
                                                        <InputLabel htmlFor="manufacturerCode" className={classes.inputLabel}>Manufacturer</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateAddPurchaseOrderItemData}
                                                                    name="manufacturerCode"
                                                                    id="manufacturerCode"
                                                                />
                                                            )}
                                                            value={addPurchaseOrderItemData.manufacturerCode || manufacturers[0].code}
                                                        >
                                                            {currentSupplierManufacturers.map((manufacturer, i) => (
                                                                <MenuItem
                                                                    key={i}
                                                                    value={manufacturer.code}
                                                                >
                                                                    {manufacturer.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.manufacturerCode && (
                                                            <FormHelperText>{errors.manufacturerCode}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.modelNo}
                                                    >
                                                        <InputLabel htmlFor="modelNo" className={classes.inputLabel}>modelNo</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateAddPurchaseOrderItemData}
                                                                    name="productCode"
                                                                    id="productCode"
                                                                />
                                                            )}
                                                            value={addPurchaseOrderItemData.productCode || ''}
                                                        >
                                                            {products.filter((product) => (product.manufacturer || {}).code === addPurchaseOrderItemData.manufacturerCode).map((product, i) => (
                                                                <MenuItem
                                                                    key={i}
                                                                    value={product.code}
                                                                >
                                                                    {product.modelNo}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.modelNo && (
                                                            <FormHelperText>{errors.modelNo}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                {currentAddPurchaseOrderItemProduct.productTypeSubCategory === 'Carpet'
                                        || currentAddPurchaseOrderItemProduct.productTypeSubCategory === 'Vinyl'
                                                    ? (
                                                        <>
                                                            <Grid item xs={12}>
                                                                <FormControl component="fieldset" className={`${classes.formControl} ${classes.radioFormControl}`}>
                                                                    <FormLabel component="legend">Unit Type</FormLabel>
                                                                    <RadioGroup
                                                                        aria-label="Unit Type"
                                                                        name="unitType"
                                                                        value={addPurchaseOrderItemData.unitType}
                                                                        onChange={this.updateAddPurchaseOrderItemData}
                                                                        className={classes.radioGroup}
                                                                    >
                                                                        <FormControlLabel
                                                                            value="ROLL"
                                                                            control={<Radio color="primary" />}
                                                                            label="Roll"
                                                                            labelPlacement="end"
                                                                        />
                                                                        <FormControlLabel
                                                                            // eslint-disable-next-line react/jsx-boolean-value
                                                                            value="NORMAL"
                                                                            control={<Radio color="primary" />}
                                                                            label="Normal"
                                                                            labelPlacement="end"
                                                                        />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Grid>
                                                        </>
                                                    )
                                                    : '' }

                                                {currentAddPurchaseOrderItemProduct.productTypeSubCategory === 'Carpet'
                                                // && addPurchaseOrderItemData.unitType === 'NORMAL'
                                                    ? (
                                                        <>

                                                            <Grid item xs={12}>
                                                                <FormControl
                                                                    className={classes.menuFormControl}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    error={!!errors.width}
                                                                >
                                                                    <InputLabel htmlFor="width" className={classes.inputLabel}>Width</InputLabel>
                                                                    <Select
                                                                        keyboard="true"
                                                                        input={(
                                                                            <OutlinedInput
                                                                                onChange={this.updateAddPurchaseOrderItemData}
                                                                                name="width"
                                                                                id="width"
                                                                            />
                                                                        )}
                                                                        value={addPurchaseOrderItemData.width || ''}
                                                                    >
                                                                        <MenuItem
                                                                            key="4"
                                                                            value="4"
                                                                        >
                                                                            4m
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            key="5"
                                                                            value="5"
                                                                        >
                                                                            5m
                                                                        </MenuItem>
                                                                    </Select>
                                                                    {errors.width && (
                                                                        <FormHelperText>{errors.width}</FormHelperText>
                                                                    )}
                                                                </FormControl>
                                                            </Grid>
                                                        </>
                                                    )
                                                    : '' }

                                                {currentAddPurchaseOrderItemProduct.productTypeSubCategory === 'Vinyl'
                                        && addPurchaseOrderItemData.unitType === 'NORMAL'
                                                    ? (
                                                        <Grid item xs={12}>
                                                            <FormControl
                                                                className={classes.menuFormControl}
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.width}
                                                            >
                                                                <InputLabel htmlFor="width" className={classes.inputLabel}>Width</InputLabel>
                                                                <Select
                                                                    keyboard="true"
                                                                    input={(
                                                                        <OutlinedInput
                                                                            onChange={this.updateAddPurchaseOrderItemData}
                                                                            name="width"
                                                                            id="width"
                                                                        />
                                                                    )}
                                                                    value={addPurchaseOrderItemData.width || ''}
                                                                >
                                                                    <MenuItem
                                                                        key="3"
                                                                        value="3"
                                                                    >
                                                                        3m
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        key="4"
                                                                        value="4"
                                                                    >
                                                                        4m
                                                                    </MenuItem>
                                                                </Select>
                                                                {errors.width && (
                                                                    <FormHelperText>{errors.width}</FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Grid>
                                                    )
                                                    : '' }
                                                {addPurchaseOrderItemData.unitType === 'NORMAL'
                                        && (currentAddPurchaseOrderItemProduct.productTypeSubCategory === 'Carpet' || currentAddPurchaseOrderItemProduct.productTypeSubCategory === 'Vinyl')
                                                    ? (
                                                        <>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    className={`${classes.formControl} ${classes.marginTop3x}`}
                                                                    name="length"
                                                                    id="length"
                                                                    value={addPurchaseOrderItemData.length}
                                                                    onChange={this.updateAddPurchaseOrderItemData}
                                                                    label="Length"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    // error={!!errors.length}
                                                                    // helperText={errors.length}
                                                                    InputProps={{
                                                                        endAdornment:
                                                            <InputAdornment
                                                                disableTypography
                                                                className={classes.inputAdornment}
                                                                position="start"
                                                            >
                                                                m
                                                            </InputAdornment>,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </>
                                                    )
                                                    : '' }

                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="quantity"
                                                        id="quantity"
                                                        onChange={this.updateAddPurchaseOrderItemData}
                                                        value={addPurchaseOrderItemData.quantity}
                                                        label="Quantity"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.quantity}
                                                        helperText={errors.quantity}
                                                        disabled={addPurchaseOrderItemData.productCode ? !!((products.find((product) => product.code === addPurchaseOrderItemData.productCode).stockControlType === 'TRACKED') && addPurchaseOrderItemData.unitType !== 'ROLL') : false}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={`${classes.formControl} ${classes.marginTop3x}`}
                                                        name="costPrice"
                                                        id="costPrice"
                                                        value={addPurchaseOrderItemData.costPrice}
                                                        onChange={this.updateAddPurchaseOrderItemData}
                                                        label="Unit Cost Price"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.costPrice}
                                                        helperText={errors.costPrice}
                                                        InputProps={{
                                                            startAdornment:
                            <InputAdornment
                                disableTypography
                                className={classes.inputAdornment}
                                position="start"
                            >
                                £
                            </InputAdornment>,
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="reference"
                                                        id="reference"
                                                        onChange={this.updateAddPurchaseOrderItemData}
                                                        value={addPurchaseOrderItemData.reference}
                                                        label="Reference"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.reference}
                                                        helperText={errors.reference}
                                                    />
                                                </Grid>

                                            </Grid>

                                            <Grid
                                                container
                                                spacing={0}
                                                direction="column"
                                                alignItems="center"
                                                justify="center"
                                            >
                                                <Grid item xs={12}>
                                                    <Button
                                                        className={classes.dialogButton}
                                                        type="submit"
                                                        disabled={disableCreatePurchaseOrderSaveButton}
                                                        onClick={this.addPurchaseOrderItem}
                                                    >
                                                        ADD
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    </div>
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleAddPurchaseOrderItemCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showCreateSupplierDialog}
                                onClose={this.handleCreateSupplierCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                className={classes.createSupplierDialog}
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <>
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                                className={classes.dialogTitle}
                                            >
                                                New Supplier
                                            </Typography>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        autoFocus
                                                        className={classes.formControl}
                                                        name="name"
                                                        id="name"
                                                        onChange={this.updateCreateSupplierData}
                                                        value={createSupplierData.name}
                                                        label="Company Name"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={suppliers.find((supplier) => supplier.name.toLowerCase() === createSupplierData.name.toLowerCase())}
                                                        helperText={suppliers.find((supplier) => supplier.name.toLowerCase() === createSupplierData.name.toLowerCase()) ? 'A supplier already exisits with this name' : null}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                spacing={0}
                                                direction="column"
                                                alignItems="center"
                                                justify="center"
                                            >
                                                <Grid item xs={12}>
                                                    <Button
                                                        className={classes.dialogButton}
                                                        type="submit"
                                                        disabled={
                                                            disableCreateSupplierSaveButton
                                                            || createSupplierData.name.length === 0
                                                            || createSupplierData.name ? !!suppliers.find((supplier) => supplier.name.toLowerCase() === createSupplierData.name.toLowerCase()) : false
                                                        }
                                                        onClick={this.handleSubmitCreateSupplier}
                                                    >
                                                        SUBMIT
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    </div>
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleCreateSupplierCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showCreateProductDialog}
                                onClose={this.handleCreateProductCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                className={classes.createProductDialog}
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <>
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                                className={classes.dialogTitle}
                                            >
                                                New Product
                                            </Typography>
                                            <Grid container spacing={0}>
                                                <Grid item xs={10}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.manufacturerCode}
                                                    >
                                                        <InputLabel htmlFor="manufacturerCode" className={classes.inputLabel}>Manufacturer</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateCreateProductData}
                                                                    name="manufacturerCode"
                                                                    id="manufacturerCode"
                                                                />
                                                            )}
                                                            value={addPurchaseOrderItemData.manufacturerCode || manufacturers[0].code}
                                                        >
                                                            {currentSupplierManufacturers.map((manufacturer, i) => (
                                                                <MenuItem
                                                                    key={i}
                                                                    value={manufacturer.code}
                                                                >
                                                                    {manufacturer.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.manufacturerCode && (
                                                            <FormHelperText>{errors.manufacturerCode}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Button className={classes.fieldAddButton} onClick={this.handleCreateManufacturerClick}>NEW</Button>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={0}>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="modelNo"
                                                        id="modelNo"
                                                        onChange={this.updateCreateProductData}
                                                        value={createProductData.modelNo}
                                                        label="Model Number"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        disabled={!createProductData.manufacturerCode}
                                                        error={products.find((product) => product.modelNo.toLowerCase() === createProductData.modelNo.toLowerCase())}
                                                        helperText={(() => {
                                                            // console.log('>>> createProductData.modelNo.toLowerCase():', createProductData.modelNo.toLowerCase());
                                                            // console.log('>>> products.find((product) => product.modelNo.toLowerCase() === createProductData.modelNo.toLowerCase()):', !!products.find((product) => product.modelNo.toLowerCase() === createProductData.modelNo.toLowerCase()));
                                                            switch (true) {
                                                            case !!products.find((product) => product.modelNo.toLowerCase() === createProductData.modelNo.toLowerCase()):
                                                                return 'A product already exisits with this model number for this supplier';
                                                            case products.find((product) => product.modelNo.toLowerCase() === createProductData.modelNo.toLowerCase()):
                                                                return 'A product already exisits with this model number';
                                                            default: return 'HELLO';
                                                            }
                                                            // products.find((product) => product.modelNo.toLowerCase() === createProductData.modelNo.toLowerCase()) ? 'A product already exisits with this model number' : null
                                                        })()}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                spacing={0}
                                                direction="column"
                                                alignItems="center"
                                                justify="center"
                                            >
                                                <Grid item xs={12}>
                                                    <Button
                                                        className={classes.dialogButton}
                                                        type="submit"
                                                        disabled={disableCreateProductSaveButton || createProductData.modelNo ? products.find((product) => product.modelNo.toLowerCase() === createProductData.modelNo.toLowerCase()) : false}
                                                        onClick={this.handleSubmitCreateProduct}
                                                    >
                                                        SUBMIT
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    </div>
                                </DialogContent>
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleCreateProductCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showCreateManufacturerDialog}
                                onClose={this.handleCreateManufacturerCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                className={classes.createManufacturerDialog}
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <>
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                                className={classes.dialogTitle}
                                            >
                                                New Manufacturer
                                            </Typography>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="name"
                                                        id="name"
                                                        onChange={this.updateCreateManufacturerData}
                                                        value={createManufacturerData.name}
                                                        label="Company Name"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={manufacturers.find((manufacturer) => manufacturer.name.toLowerCase() === createManufacturerData.name.toLowerCase())}
                                                        helperText={manufacturers.find((manufacturer) => manufacturer.name.toLowerCase() === createManufacturerData.name.toLowerCase()) ? 'A manufacturer already exisits with this name' : null}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                spacing={0}
                                                direction="column"
                                                alignItems="center"
                                                justify="center"
                                            >
                                                <Grid item xs={12}>
                                                    <Button
                                                        className={classes.dialogButton}
                                                        type="submit"
                                                        disabled={disableCreateManufacturerSaveButton || createManufacturerData.name ? manufacturers.find((manufacturer) => manufacturer.name.toLowerCase() === createManufacturerData.name.toLowerCase()) : false}
                                                        onClick={this.handleSubmitCreateManufacturer}
                                                    >
                                                        SUBMIT
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    </div>
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleCreateManufacturerCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    )
                    : ''}
            </>

        );
    }
}

export default withSnackbar(withStyles(styles)(PurchaseOrderCreateDialog));
