import React from 'react';
import {
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import qs from 'qs';
import Cookies from 'universal-cookie';

import Layout from './Layout';
import { fetchJson2 } from '../utilities/fetch';

const cookies = new Cookies();

const styles = (theme) => ({
});

class Gate extends React.Component {
    state = {
        userLoading: true,
    };

    async componentDidMount() {
        await fetchJson2('v2/user')
            .then((user) => {
                this.setState({
                    user: user.payload,
                    userLoading: false,
                });
            });

        const { companyCode } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        const { companyShortName } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        const { companyLongName } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        let { pathname = '/home' } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        const targetPage = pathname.split('/')[1];
        switch (targetPage) {
        case 'newCustomer':
        case 'customer':
            pathname = '/customers';
            break;
        case 'lead':
            pathname = '/leads';
            break;
        case 'item':
            pathname = '/items';
            break;
        }
        cookies.set('companyCode', companyCode, { path: '/' });
        cookies.set('companyShortName', companyShortName, { path: '/' });
        cookies.set('companyLongName', companyLongName, { path: '/' });
        window.location.href = pathname;
    }

    render() {
        return (
            <>
            </>
        );
    }
}

export default withStyles(styles)(Gate);
