import React from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import GoogleMapReact from 'google-map-react';
import moment from 'moment-timezone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { DatePicker, DateTimePicker } from 'material-ui-pickers';
import HybridTextField from '../hybridTextField';
import titalise from '../../utilities/titalise';
import {
    titles,
    counties,
    countries,
} from '../../utilities/menuOptions';
import Tile from '../../containers/tile';

const styles = theme => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    tileBottomDivider: {
        marginTop: theme.spacing.unit * 3,
        // marginBottom: theme.spacing.unit * 2,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginLeft: -24,
        marginRight: -24,
    },
    bottomBarContainer: {
        width: '100%',
        textAlign: 'right',
    },
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    nameField: {
        width: 350,
    },
    addressField: {
        width: 250,
    },
    postcodeField: {
        width: 120,
    },
    countryField: {
        width: 200,
        marginLeft: theme.spacing.unit * 3,
    },
    emailField: {
        width: 350,
    },
    rightOfFieldButton: {
        marginTop: 10,
        marginLeft: theme.spacing.unit * 3,
        color: 'rgba(0, 121, 255, 0.87)',
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
        minWidth: 600,
    },
    formHelperText: {
        color: '#f44336',
    },
    contactLocationIcon: {
        color: 'red',
        fontSize: 30,
        position: 'absolute',
        transform: 'translate(-50%, -100%)',
    },
    branchLocationIcon: {
        color: 'blue',
        fontSize: 30,
        position: 'absolute',
        transform: 'translate(-50%, -100%)',
    },
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    checkboxLabel: {
        fontSize: 16,
    },
    dialogAddButton: {
        textAlign: 'right',
    },
    dialogTitle: {
        marginBottom: 24,
    },
});

class ContactDebt extends React.Component {
    state = {
        addCompanyData: {
            name: '',
            telephoneNumber: '',
            emailAddress: '',
        },
        contactDebtData: {
            companyReference: '',
            debtCompanyCode: '',
            status: '',
            suspendCommunication: '',
        },
        disableEditContactDebtSaveButton: false,
        showAddCompanyDialog: false,
        showContactDebtEditDialog: false,
    }

    componentDidMount() {
        this.setupFormData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.contact !== prevProps.contact) {
            this.setupFormData();
        }
    }

    setupFormData() {
        if (this.props.contact) {
            this.setState(state => ({
                contactDebtData: {
                    ...state.contactDebtData,
                    companyReference: this.props.contact.debt ? this.props.contact.debt.companyReference : '',
                    status: this.props.contact.debt ? this.props.contact.debt.status : '',
                    debtCompanyCode: this.props.contact.debt ? this.props.contact.debt.company.code : '',
                    suspendCommunication: this.props.contact.debt ? this.props.contact.debt.suspendCommunication : '',
                },
            }));
        }
    }

    handleContactDebtEditClick = () => {
        this.setState({
            showContactDebtEditDialog: true,
        });
    }

    handleContactDebtDetailsCloseDialogClick = () => {
        this.setState({
            showContactDebtEditDialog: false,
        });
    }

    handleAddCompanyClick = () => {
        this.setState({
            showAddCompanyDialog: true,
        });
    }

    handleAddCompanyCloseDialogClick = () => {
        this.setState({
            showAddCompanyDialog: false,
        });
    }

    handleEditContactDebtCheckBoxClick = name => (event) => {
        const newValue = this.state.contactDebtData[name] !== true;
        this.setState(state => ({
            contactDebtData: {
                ...state.contactDebtData,
                [name]: newValue,
            },
        }));
    }

    updateEditContactDebtData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState(state => ({
            contactDebtData: {
                ...state.contactDebtData,
                [name]: formattedValue,
            },
        }));
    }

    updateAddCompanyData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        case 'name':
            formattedValue = titalise(value);
            break;
        case 'telephoneNumber':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            break;
        case 'emailAddress':
            formattedValue = value.toLowerCase();
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState(state => ({
            addCompanyData: {
                ...state.addCompanyData,
                [name]: formattedValue,
            },
        }));
    }

    handleSaveContactDebtDetails = (event) => {
        event.preventDefault();
        this.setState({
            disableEditContactDebtSaveButton: true,
        });
        this.props.saveContactDebtDetails(this.state.contactDebtData).then(() => {
            this.setState({
                showContactDebtEditDialog: false,
                disableEditContactDebtSaveButton: false,
            });
            this.props.enqueueSnackbar('Contact Debt Details Saved', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Contact Debt Details Save Failed', { variant: 'error' });
            this.setState({
                disableEditContactDebtSaveButton: false,
            });
        });
    }

    handleAddCompany = (event) => {
        event.preventDefault();
        this.setState({
            disableAddCompanySaveButton: true,
        });
        this.props.addCompany(this.state.addCompanyData).then(() => {
            this.setState({
                showAddCompanyDialog: false,
                disableAddCompanySaveButton: false,
            });
            this.props.enqueueSnackbar('New Debt Company Saved', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('New Debt Company Save Failed', { variant: 'error' });
            this.setState({
                disableAddCompanySaveButton: false,
            });
        });
    }

    render() {
        const {
            classes,
            contact,
            contactLoading,
            debtCompanies,
            debtStatuses,
            errors,
            fetchContactData,
        } = this.props;
        const {
            addCompanyData,
            contactDebtData,
            disableAddCompanySaveButton,
            disableEditContactDebtSaveButton,
            showAddCompanyDialog,
            showContactDebtEditDialog,
        } = this.state;

        return (
            <Tile
                tileTitle="Contact Debt"
                refreshAction={fetchContactData}
                refreshing={contactLoading}
                tileMenuItems={[
                    {
                        action: this.handleContactDebtEditClick,
                        label: 'Edit Debt Detail',
                        section: 2,
                    },
                ]}
                style
            >
                { !contactLoading
                    ? (
                        <React.Fragment>
                            <Grid container spacing={0}>
                                <Grid item xs={4}>
                                    <Grid item xs={12}>
                                        <HybridTextField
                                            label="Status"
                                            text={contact.debt ? titalise(contact.debt.status.replaceAll('_', ' ')) : null}
                                            alert={contact.debt && contact.debt.status !== 'NORMAL'}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <HybridTextField label="Suspend Communication" text={contact.debt && contact.debt.suspendCommunication ? 'Yes' : 'No'} />
                                    </Grid>
                                </Grid>

                                <Grid item xs={4}>
                                    <Grid item xs={12}>
                                        <HybridTextField label="Managed By" text={contact.debt ? ((contact.debt.company || {}).name) : ''} />
                                    </Grid>
                                    {contact.debt && contact.debt.company.name !== 'Pay Weekly' && (
                                        <>
                                            <Grid item xs={12}>
                                                <HybridTextField label="Reference" text={contact.debt ? contact.debt.companyReference : ''} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <HybridTextField label="Telephone Number" text={contact.debt ? contact.debt.company.telephoneNumber : ''} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <HybridTextField label="Email Address" text={contact.debt ? contact.debt.company.emailAddress : ''} />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>

                            </Grid>

                            <Dialog
                                open={showContactDebtEditDialog}
                                onClose={this.handleContactDebtDetailsCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <React.Fragment>
                                            <Typography variant="h6" className={classes.dialogTitle}>
                                                Edit Debt Details
                                            </Typography>
                                            <Grid item xs={12}>
                                                <FormControl
                                                    className={classes.menuFormControl}
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    error={!!errors.title}
                                                >
                                                    <InputLabel htmlFor="status" className={classes.inputLabel}>Status</InputLabel>
                                                    <Select
                                                        keyboard="true"
                                                        input={(
                                                            <OutlinedInput
                                                                onChange={this.updateEditContactDebtData}
                                                                name="status"
                                                                id="status"
                                                            />
                                                        )}
                                                        value={contactDebtData.status || ''}
                                                    >
                                                        <MenuItem value=""><em>None</em></MenuItem>
                                                        {debtStatuses.map((debtStatus, i) => (
                                                            <MenuItem
                                                                key={i}
                                                                value={debtStatus}
                                                            >
                                                                {titalise(debtStatus.replaceAll('_', ' '))}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errors.status && (
                                                        <FormHelperText>{errors.status}</FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl
                                                    className={classes.menuFormControl}
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    error={!!errors.title}
                                                >
                                                    <InputLabel htmlFor="debtCompanyCode" className={classes.inputLabel}>Managed By</InputLabel>
                                                    <Select
                                                        keyboard="true"
                                                        input={(
                                                            <OutlinedInput
                                                                onChange={this.updateEditContactDebtData}
                                                                name="debtCompanyCode"
                                                                id="debtCompanyCode"
                                                            />
                                                        )}
                                                        value={contactDebtData.debtCompanyCode || ''}
                                                    >
                                                        <MenuItem value=""><em>None</em></MenuItem>
                                                        {debtCompanies.map(debtCompany => (
                                                            <MenuItem
                                                                key={debtCompany.code}
                                                                value={debtCompany.code}
                                                            >
                                                                {debtCompany.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errors.debtCompanyCode && (
                                                        <FormHelperText>{errors.debtCompanyCode}</FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} className={classes.dialogAddButton}>
                                                <Button onClick={this.handleAddCompanyClick}>ADD COMPANY</Button>
                                            </Grid>
                                            {(debtCompanies.find(debtCompany => debtCompany.code === parseInt(contactDebtData.debtCompanyCode, 10)) || {}).name !== 'Pay Weekly' && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            onChange={this.updateEditContactDebtData}
                                                            className={classes.formControl}
                                                            name="companyReference"
                                                            id="companyReference"
                                                            value={contactDebtData.companyReference}
                                                            label="Company Reference"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            multiline
                                                            rows="1"
                                                            autoFocus
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={(
                                                        <Checkbox
                                                            name="suspendCommunication"
                                                            id="suspendCommunication"
                                                            checked={contactDebtData.suspendCommunication === true}
                                                            onChange={this.handleEditContactDebtCheckBoxClick('suspendCommunication')}
                                                            value="true"
                                                        />
                                                    )}
                                                    label={<Typography className={classes.checkboxLabel}>Suspend Communication</Typography>}
                                                />

                                            </Grid>
                                            <Grid
                                                container
                                                spacing={0}
                                                direction="column"
                                                alignItems="center"
                                                justify="center"
                                            >
                                                <Grid item xs={12}>
                                                    <Button
                                                        className={classes.dialogButton}
                                                        type="submit"
                                                        disabled={disableEditContactDebtSaveButton || !contactDebtData.debtCompanyCode || !contactDebtData.status}
                                                        onClick={this.handleSaveContactDebtDetails}
                                                    >
                                                        SAVE
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    </div>
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleContactDebtDetailsCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showAddCompanyDialog}
                                onClose={this.handleAddCompanyCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <React.Fragment>
                                            <Typography variant="h6" className={classes.dialogTitle}>
                                                Add Debt Management Company
                                            </Typography>
                                            <Grid item xs={12}>
                                                <TextField
                                                    onChange={this.updateAddCompanyData}
                                                    className={classes.formControl}
                                                    name="name"
                                                    id="name"
                                                    value={addCompanyData.name}
                                                    label="Company Name"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    multiline
                                                    rows="1"
                                                    autoFocus
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    onChange={this.updateAddCompanyData}
                                                    className={classes.formControl}
                                                    name="telephoneNumber"
                                                    id="telephoneNumber"
                                                    value={addCompanyData.telephoneNumber}
                                                    label="Telephone Number"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    multiline
                                                    rows="1"
                                                    autoFocus
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    onChange={this.updateAddCompanyData}
                                                    className={classes.formControl}
                                                    name="emailAddress"
                                                    id="emailAddress"
                                                    value={addCompanyData.emailAddress}
                                                    label="Email Address"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    multiline
                                                    rows="1"
                                                    autoFocus
                                                />
                                            </Grid>
                                            <Grid
                                                container
                                                spacing={0}
                                                direction="column"
                                                alignItems="center"
                                                justify="center"
                                            >
                                                <Grid item xs={12}>
                                                    <Button
                                                        className={classes.dialogButton}
                                                        type="submit"
                                                        disabled={disableAddCompanySaveButton || !addCompanyData.name || !addCompanyData.telephoneNumber || !addCompanyData.emailAddress}
                                                        onClick={this.handleAddCompany}
                                                    >
                                                        SAVE
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    </div>
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleAddCompanyCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </React.Fragment>

                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )
                }
            </Tile>
        );
    }
}

const formShape = {
    title: PropTypes.string,
    firstname: PropTypes.string,
    surname: PropTypes.string,
    fullName: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    townCity: PropTypes.string,
    county: PropTypes.string,
    postcode: PropTypes.string,
    country: PropTypes.string,
    homeTelephoneNumber: PropTypes.string,
    mobileTelephoneNumber: PropTypes.string,
    emailAddress: PropTypes.string,
    gdpr: PropTypes.number,
};

ContactDebt.propTypes = {
    classes: PropTypes.object.isRequired,
    contact: PropTypes.shape(formShape).isRequired,
    errors: PropTypes.object.isRequired,
    saveContactDetails: PropTypes.func.isRequired,
    sendSMS: PropTypes.func.isRequired,
    sendEmail: PropTypes.func.isRequired,
};

export default withSnackbar(withStyles(styles)(ContactDebt));
