import React from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment-timezone';
import fetch from '../utilities/fetch';
import sleep from '../utilities/sleep';

const styles = theme => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    divider: {
        marginTop: theme.spacing.unit * 2,
        // marginBottom: theme.spacing.unit * 2,
    },
    bodyContainer: {
        minHeight: 100,
    },
    bottomBarContainer: {
        width: '100%',
        textAlign: 'right',
    },
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    dialogContentContainer: {
        // marginTop: theme.spacing.unit * 2,
        // marginBottom: theme.spacing.unit * 2,
        minWidth: 600,
        minHeight: 269,
        paddingBottom: 0,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
        marginRight: theme.spacing.unit * 2,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 2,
        // marginBottom: theme.spacing.unit * 2,
    },
    dialogActions: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
    },
    progress: {
        margin: theme.spacing.unit * 3,
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
    },
});

class AgreementDelivery extends React.Component {
    state = {
        error: false,
        loading: false,
        snackbarOpen: false,
        loadingStep: false,
        showDialog: false,
        rentalAgreementCode: '',
        deliveryStatus: '',
        deliveryDate: '',
        pickedDeliveryDate: null,
        saved: false,
        errors: {},
    }

    componentDidMount() {
        this.setState({
            rentalAgreementCode: this.props.rentalAgreementCode,
            deliveryStatus: this.props.deliveryStatus,
            deliveryDate: this.props.deliveryDate,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.rentalAgreementCode !== prevProps.rentalAgreementCode) {
            this.setState({
                rentalAgreementCode: this.props.rentalAgreementCode,
                deliveryStatus: this.props.deliveryStatus,
                deliveryDate: this.props.deliveryDate,
            });
        }
    }

    handleFormSubmit = async (event) => {
        event.preventDefault();
        let url;
        let data;
        const formData = new FormData(event.currentTarget);

        url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/payment-plan`);
        // console.log(this.props.rentalAgreementCode, this.props.contactCode, this.state.pickedDeliveryDate);
        data = await fetch(url, {
            method: 'PUT',
            body: JSON.stringify({
                rentalAgreementCode: this.props.rentalAgreementCode,
                action: "Complete Installation",
                contactCode: this.props.contactCode,
                installationDate: this.state.pickedDeliveryDate,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/rental-agreement`);
        // console.log(this.props.rentalAgreementCode, this.props.contactCode, this.state.pickedDeliveryDate);
        data = await fetch(url, {
            method: 'PATCH',
            body: JSON.stringify({
                rentalAgreementCode: this.props.rentalAgreementCode,
                deliveryStatus: 'delivered',
                deliveryDate: this.state.pickedDeliveryDate,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });



        this.setState({
            deliveryDate: this.state.pickedDeliveryDate,
            deliveryStatus: 'delivered',
            showDialog: false,
        });
    }

    handleEditItemRentalAgreementClick = async () => {
        this.setState({
            showDialog: true,
        });
    }

    handleCloseDialogClick = () => {
        this.setState({
            showDialog: false,
        });
    }

    handleDateChange = (chosenDate) => {
        this.setState({
            pickedDeliveryDate: moment(chosenDate).format('YYYY-MM-DD'),
        });
    }

    render() {
        const { classes } = this.props;
        const {
            rentalAgreementCode,
            deliveryStatus,
            deliveryDate,
            pickedDeliveryDate,
            showDialog,
            errors,
            loadingStep,
        } = this.state;

        return (
            <React.Fragment>
                    { deliveryDate
                        ? (
                            <div className={classes.bodyContainer}>
                                <Typography>
                                    deliverySatus:
                                    {' '}
                                    {deliveryStatus}
                                    <br />
                                    deliveryDate:
                                    {' '}
                                    {deliveryDate}
                                    <br />
                                </Typography>
                            </div>
                        )
                        : (
                            <React.Fragment>
                            <div className={classes.bodyContainer}>
                                    <Typography>
                                        No delivery date set.
                                    </Typography>
                                <Divider className={classes.divider} />
                            </div>
                <div className={classes.bottomBarContainer}>
                    <Button
                        color="default"
                        className={classes.textButton}
                        onClick={this.handleEditItemRentalAgreementClick}
                    >
                    SET DELIVERY DATE
                    </Button>
                </div>
            </React.Fragment>
            )
        }

                <Dialog
                    open={showDialog}
                    onClose={this.handleCloseDialogClick}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={classes.dialogContentContainer}>
                        <div>
                            <React.Fragment>
                                <Typography variant="h6" gutterBottom>
                                    Add Delivery Date
                                </Typography>
                                <DialogContentText>
                                        Select the delivery date using the picker below and click [SUBMIT].
                                </DialogContentText>
                                <form onSubmit={this.handleFormSubmit}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                name="pickedDeliveryDate"
                                                id="pickedDeliveryDate"
                                                margin="normal"
                                                label="Delivery Date"
                                                value={pickedDeliveryDate}
                                                onChange={this.handleDateChange}
                                                fullWidth
                                                variant="outlined"
                                                format="DD-MM-YYYY"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button
                                        variant="contained"
                                        className={classes.dialogButton}
                                        type="submit"
                                    >
                                        SUBMIT
                                    </Button>
                                </form>
                            </React.Fragment>
                        </div>
                        <Divider className={classes.dialogDivider} />
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button onClick={this.handleCloseDialogClick} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(AgreementDelivery);
