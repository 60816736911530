import React from 'react';
import {
    CssBaseline,
    Chip,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import qs from 'qs';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import Layout from './Layout';
import fetch from '../utilities/fetch';

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        marginBottom: theme.spacing.unit * 3,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    authorisedPaymentChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '87px',
    },
    warmChip: {
        backgroundColor: theme.palette.primary.warm,
        minWidth: '87px',
    },
    declinedPaymentChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '87px',
    },
    unknownPaymentChip: {
        minWidth: '87px',
    },
    noMissedPaymentsChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '40px',
    },
    missedPaymentsChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '40px',
    },
    unknownMissedPaymentsChip: {
        minWidth: '40px',
    },
    chip: {
        height: 21,
        borderRadius: 5,
    },
    address: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
});

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        verticalAlign: 'top',
        paddingTop: 16,
        paddingBottom: 8,
    },
}))(TableCell);

class Agreements extends React.Component {
    state = {
        agreements: [],
        subset: '',
    };

    async componentDidMount() {
        const { subset } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || 0;
        const { orderColumn } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || 0;
        const { orderDirection } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || 0;
        this.getAgreements(
            subset,
            orderColumn,
            orderDirection,
        );
    }

    componentDidUpdate(prevProps) {
        const { subset: prevSubset } = qs.parse(prevProps.location.search, { ignoreQueryPrefix: true }) || 0;
        const { subset } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || 0;
        const { orderColumn: prevOrderColumn } = qs.parse(prevProps.location.search, { ignoreQueryPrefix: true }) || 0;
        const { orderColumn } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || 0;
        const { orderDirection: prevOrderDirection } = qs.parse(prevProps.location.search, { ignoreQueryPrefix: true }) || 0;
        const { orderDirection } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || 0;
        if (subset !== prevSubset || orderColumn !== prevOrderColumn || orderDirection !== prevOrderDirection) {
            this.getAgreements(
                subset,
                orderColumn,
                orderDirection,
            );
        }
    }

    getAgreements = async (subset, orderColumn, orderDirection) => {
        this.setState({
            subset,
            orderColumn,
            orderDirection,
        });

        const data = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/agreements?subset=${subset}&orderColumn=${orderColumn || ''}&orderDirection=${orderDirection || ''}`).then((response) => response.json());
        this.setState({ agreements: data });
    };

    handleLeadClick = (customerCode, agreementCode) => {
        this.props.history.push(`/contact/${customerCode}?agreementCode=${agreementCode}`);
    };

    render() {
        const { classes } = this.props;
        const { agreements, subset } = this.state;

        return (
            <Layout pageTitle={`Agreements - ${subset}`}>
                <CssBaseline />
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>#</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Agreement Code</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Customer</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Start Date</CustomTableCell>
                                <CustomTableCell align="center" className={classes.tableHead}>Due Paid Percentage</CustomTableCell>
                                <CustomTableCell align="center" className={classes.tableHead}>Arrears</CustomTableCell>
                                <CustomTableCell align="center" className={classes.tableHead}>Missed Payments</CustomTableCell>
                                <CustomTableCell align="center" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {agreements.map((agreement, index) => (
                                <TableRow onClick={() => this.handleLeadClick(agreement.contactCode, agreement.code)} className={classes.row} key={agreement.code}>
                                    <CustomTableCell component="th" scope="row">{index + 1}</CustomTableCell>
                                    <CustomTableCell align="left">{`RA${agreement.code}`}</CustomTableCell>
                                    <CustomTableCell align="left">
                                        <div>{agreement.fullName}</div>
                                        <div className={classes.address}>{agreement.longAddress}</div>
                                    </CustomTableCell>
                                    <CustomTableCell align="right">{agreement.startDate}</CustomTableCell>

                                    <CustomTableCell align="center">
                                        {
                                            (() => {
                                                switch (true) {
                                                case agreement.duePaidPercentage < 80: {
                                                    return <Chip label={`${agreement.duePaidPercentage}%`} className={`${classes.chip} ${classes.declinedPaymentChip}`} />;
                                                }
                                                case agreement.duePaidPercentage < 90 && agreement.duePaidPercentage >= 80: {
                                                    return <Chip label={`${agreement.duePaidPercentage}%`} className={`${classes.chip} ${classes.warmChip}`} />;
                                                }
                                                default: {
                                                    return (
                                                        <>
                                                            {agreement.duePaidPercentage}
                                                            %
                                                        </>
                                                    );
                                                }
                                                }
                                            })()
                                        }

                                    </CustomTableCell>

                                    <CustomTableCell align="center">
                                        £
                                        {agreement.arrears.toFixed(2)}
                                    </CustomTableCell>
                                    <CustomTableCell align="center">{agreement.missedPayments === 0 ? '<1' : agreement.missedPayments}</CustomTableCell>
                                    <CustomTableCell align="right"><IconButton className={classes.button} aria-label="More info"><InfoIcon /></IconButton></CustomTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Layout>
        );
    }
}

export default withStyles(styles)(Agreements);
