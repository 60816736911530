import React from 'react';
import {
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    MenuItem,
    OutlinedInput,
    InputLabel,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';

const styles = theme => ({
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    formHelperText: {
        color: '#f44336',
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    formInstructions: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    },
    nextButton: {
        width: '90%',
    },
    nextButtonContainer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100vw',
        marginBottom: theme.spacing.unit * 2,
        textAlign: 'center',
    },
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
    },
});

class DeliveryArea extends React.Component {
    state = {
        postcodeLoading: true,
        postcodeForm: {},
    }

    componentDidMount() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.props.fetchLocationPostcode(position).then((autoPostcode) => {
                    this.setState({
                        postcodeForm: {
                            postcode: autoPostcode,
                        },
                        postcodeLoading: false,
                    });
                });
            },
            (error) => {
                if (error.code == error.PERMISSION_DENIED) {
                    this.setState({
                        postcodeLoading: false,
                    });
                }
            });
        } else {
            console.log('Geolocation is not supported by this browser.');
            this.setState({
                postcodeLoading: false,
            });
        }
    }

    updatePostcodeData = (event) => {
        const { value, name } = event.target;
        const formattedValue = value.replace(/[^a-zA-Z0-9\s+]/g, '').toUpperCase();
        this.setState({
            postcodeForm: {
                ...this.state.postcodeForm,
                [name]: formattedValue,
            },
        });
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.props.setPostcode(this.state.postcodeForm.postcode).then(() => {
        }).catch(() => {
            console.log('Error setting postcode');
        });
    }

    showDelivery = () => {
        console.log(this.props.delivery);
    }

    render() {
        const {
            classes,
            delivery,
            disableNextButton,
        } = this.props;
        const {
            postcodeLoading,
            postcodeForm,
        } = this.state;
        return (
            <React.Fragment>
                <Typography onClick={this.showDelivery} variant="h6">
                    Area
                </Typography>
                {postcodeLoading
                    ? (
                        <React.Fragment>
                            <div className={classes.progressContainer}>
                                <CircularProgress className={classes.progress} variant="indeterminate" />
                            </div>
                            <Typography align="center">
                                Please wait
                            </Typography>
                        </React.Fragment>
                    )
                    : (
                        <React.Fragment>
                            <Typography variant="body1" className={classes.formInstructions}>
                                Enter current location Postcode
                            </Typography>
                            <form onSubmit={this.handleFormSubmit}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <TextField
                                            onChange={this.updatePostcodeData}
                                            className={classes.formControl}
                                            name="postcode"
                                            id="postcode"
                                            label="Current location"
                                            margin="normal"
                                            variant="outlined"
                                            value={postcodeForm.postcode || ''}
                                            fullWidth
                                            error={!!this.props.postcodeError}
                                            helperText={this.props.postcodeError}
                                            autoFocus
                                        />
                                    </Grid>
                                </Grid>
                                {postcodeForm.postcode != null
                                    ? <div className={classes.nextButtonContainer}>
                                        <Button
                                            variant="contained"
                                            className={classes.nextButton}
                                            type="submit"
                                            disabled={disableNextButton}
                                        >
                                            NEXT
                                        </Button>
                                    </div>
                                    : null
                                }
                            </form>
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        );
    }
}

DeliveryArea.propTypes = {
    classes: PropTypes.object.isRequired,
    delivery: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(DeliveryArea));
