import React from 'react';

import {
    Button,
    CssBaseline,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography,
} from '@material-ui/core';

import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import { withSnackbar } from 'notistack';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import fetch, { fetchJson2 } from '../utilities/fetch';

const removeFalseyValuesFromObject = (obj) => pickBy(obj, identity);

const styles = (theme) => ({
    main: {
        maxWidth: 300,
        textAlign: 'center',
        display: 'block', // Fix IE 11 issue.
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    logo: {
        margin: theme.spacing.unit * 5,
        width: 128,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    snackbar: {
        backgroundColor: theme.palette.error.dark,
    },
    snackbarIcon: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
        fontSize: 20,
        // margin: theme.spacing.unit,
    },
    snackbarMessage: {
        fontSize: 'inherit',
        // marginBottom: theme.spacing.unit,
        display: 'flex',
        alignItems: 'center',
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 1,
    },
    title: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 1,
    },
    checkPass: {
        color: 'green',
    },
    checkFail: {
        color: 'red',
    },
    condenseList: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    inputBox: {
        backgroundColor: '#fff',
    },
});

class ResetPassword extends React.Component {
    state = {
        capitalLetterCheck: false,
        disableSaveButton: false,
        errors: false,
        formData: {
            password: '',
            retypePassword: '',
        },
        lengthCheck: false,
        numberCheck: false,
        passwordReset: false,
        requestValid: true,
        requestValidationLoading: true,
        specialCharacterCheck: false,
        validPassword: false,
    };

    // componentDidMount() {
    //     this.validateRequest();
    // }

    static getDerivedStateFromProps(props, state) {
        const { guid } = props.match.params;
        return {
            guid,
        };
    }

    validateRequest = async () => {
        let validateRequest;
        try {
            validateRequest = fetchJson2(`v2/user/password/${this.state.guid}/validateRequest`);
            this.setState({
                requestValid: validateRequest.status === 204,
            });
        } finally {
            this.setState({
                requestValidationLoading: false,
            });
        }
    };

    updateFormData = (event) => {
        const { name, value } = event.target;

        event.target.value = value;

        const formattedValue = value.replace(' ', '');

        this.setState((state) => ({
            formData: {
                ...state.formData,
                [name]: formattedValue,
            },
        }));
    };

    handleResetPassword = (event) => {
        event.preventDefault();
        this.setState({
            disableSaveButton: true,
        });
        this.resetPassword(this.state.formData).then(() => {
            this.setState({
                disableSaveButton: false,
            });
            this.props.enqueueSnackbar('Password reset', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Password reset failed', { variant: 'error' });
            this.setState({
                disableSaveButton: false,
            });
        });
    };

    resetPassword = async (payload) => {
        if (this.validateFormData(payload) === false) throw new Error({ error: 'Generic API error' });
        let response;
        let body;

        body = JSON.stringify(payload);
        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/v2/user/password/${this.state.guid}`, {
                method: 'PATCH',
                body,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 204: {
            this.setState({
                passwordReset: true,
            });
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    validateFormData = (payload) => {
        const fieldOrder = [
            'password',
            'retypePassword',
        ];

        const password = (() => {
            switch (true) {
            case !payload.password: return 'Password required';
            case payload.password.length < 8: return 'Password less than 8 characters';
            default: return '';
            }
        })();
        const retypePassword = (() => {
            switch (true) {
            case payload.password !== payload.retypePassword: return 'Passwords do not match';
            default: return '';
            }
        })();

        const errors = removeFalseyValuesFromObject({
            password,
            retypePassword,
        });

        const firstError = fieldOrder.find((field) => !!errors[field]);

        this.setState({
            errors,
            firstError,
        });

        if (isEmpty(errors)) return true;

        return false;
    };

    render = () => {
        const { classes } = this.props;
        const {
            disableSaveButton,
            errors,
            formData,
            passwordReset,
            requestValid,
        } = this.state;

        const lengthCheck = (formData.password.length >= 8);
        const capitalLetterCheck = /[A-Z]/.test(formData.password);
        const numberCheck = /[0-9]/.test(formData.password);
        const specialCharacterCheck = /[^A-Za-z0-9\s]/.test(formData.password);
        const validPassword = lengthCheck && capitalLetterCheck && numberCheck && specialCharacterCheck;

        return (
            <div style={{ width: '100%' }}>
                <main className={classes.main}>
                    <CssBaseline />
                    <img
                        className={classes.logo}
                        src="https://pwc-email-images.s3.eu-west-1.amazonaws.com/logo-main-small.png"
                        alt="Pay Weekly Logo"
                    />
                    {requestValid
                        ? (
                            <>
                                {passwordReset
                                    ? (
                                        <Typography variant="h8" className={classes.title}>
                                            Your password has been reset
                                        </Typography>
                                    )
                                    : (
                                        <form>
                                            <Typography variant="h6" className={classes.title}>
                                                Reset Password
                                            </Typography>
                                            <List dense>
                                                <ListItem className={classes.condenseList}>
                                                    <ListItemText
                                                        disableTypography
                                                        className={lengthCheck ? classes.checkPass : classes.checkFail}
                                                        primary="Minimum 8 characters"
                                                    />
                                                    <ListItemIcon>
                                                        {lengthCheck
                                                            ? (
                                                                <CheckIcon style={{ color: 'green' }} />
                                                            )
                                                            : (
                                                                <ErrorIcon style={{ color: 'red' }} />
                                                            )}
                                                    </ListItemIcon>
                                                </ListItem>
                                                <ListItem className={classes.condenseList}>
                                                    <ListItemText
                                                        disableTypography
                                                        className={capitalLetterCheck ? classes.checkPass : classes.checkFail}
                                                        primary="Minimum 1 capital letter"
                                                    />
                                                    <ListItemIcon>
                                                        {capitalLetterCheck
                                                            ? (
                                                                <CheckIcon style={{ color: 'green' }} />
                                                            )
                                                            : (
                                                                <ErrorIcon style={{ color: 'red' }} />
                                                            )}
                                                    </ListItemIcon>
                                                </ListItem>
                                                <ListItem className={classes.condenseList}>
                                                    <ListItemText
                                                        disableTypography
                                                        className={numberCheck ? classes.checkPass : classes.checkFail}
                                                        primary="Minimum 1 number"
                                                    />
                                                    <ListItemIcon>
                                                        {numberCheck
                                                            ? (
                                                                <CheckIcon style={{ color: 'green' }} />
                                                            )
                                                            : (
                                                                <ErrorIcon style={{ color: 'red' }} />
                                                            )}
                                                    </ListItemIcon>
                                                </ListItem>
                                                <ListItem className={classes.condenseList}>
                                                    <ListItemText
                                                        disableTypography
                                                        className={specialCharacterCheck ? classes.checkPass : classes.checkFail}
                                                        primary="Minimum 1 special character"
                                                    />
                                                    <ListItemIcon>
                                                        {specialCharacterCheck
                                                            ? (
                                                                <CheckIcon style={{ color: 'green' }} />
                                                            )
                                                            : (
                                                                <ErrorIcon style={{ color: 'red' }} />
                                                            )}
                                                    </ListItemIcon>
                                                </ListItem>
                                            </List>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="password"
                                                        id="password"
                                                        onChange={this.updateFormData}
                                                        value={formData.password}
                                                        label="Password"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.password}
                                                        helperText={errors.password}
                                                        type="password"
                                                        InputProps={{
                                                            className: classes.inputBox,
                                                            endAdornment: <InputAdornment position="end">{validPassword ? (<CheckIcon style={{ color: 'green' }} />) : (<ErrorIcon style={{ color: 'red' }} />)}</InputAdornment>,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="retypePassword"
                                                        id="retypePassword"
                                                        onChange={this.updateFormData}
                                                        value={formData.retypePassword}
                                                        label="Re-Type Password"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.retypePassword || (formData.password !== '' && formData.password !== formData.retypePassword)}
                                                        helperText={formData.password !== '' && formData.password !== formData.retypePassword ? 'Passwords do not match' : errors.retypePassword}
                                                        type="password"
                                                        InputProps={{
                                                            className: classes.inputBox,
                                                            endAdornment: <InputAdornment position="end">{formData.password !== '' && formData.password === formData.retypePassword ? (<CheckIcon style={{ color: 'green' }} />) : (<ErrorIcon style={{ color: 'red' }} />)}</InputAdornment>,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                spacing={0}
                                                direction="column"
                                                alignItems="center"
                                                justify="center"
                                            >
                                                <Grid item xs={12}>
                                                    <Button
                                                        onClick={this.handleResetPassword}
                                                        className={classes.dialogButton}
                                                        // type="submit"
                                                        disabled={(!validPassword) || (disableSaveButton || (!formData.password) || (formData.password !== formData.retypePassword))}
                                                    >
                                                        SAVE
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                            </>
                        )
                        : (
                            <Typography variant="h8" className={classes.title}>
                                Your request has expired.
                            </Typography>

                        )}
                </main>
            </div>
        );
    };
}

ResetPassword.propTypes = {
    classes: PropTypes.object.isRequired,
};

// export default withStyles(styles)(withRouter(ResetPassword));
export default withSnackbar(withStyles(styles)(withRouter(ResetPassword)));
