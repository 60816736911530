import React, { isValidElement } from 'react';
import {
    Button,
    CircularProgress,
    CssBaseline,
    Dialog,
    DialogContent,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty, uniq } from 'lodash';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import classNames from 'classnames';
import Layout from '../Layout';
import fetch, { fetchJson2 } from '../../utilities/fetch';
import logo from '../../logo-main-small.png';

const EUROWAY_1 = 1033;
const MANUFACTURING = 1043;
const NEW = 1000;
const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    header: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: 64,
        backgroundColor: '#344955',
        color: '#fff',
        padding: 10,
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    logo: {
        height: 60,
        float: 'inline-start',
        paddingLeft: 8,
    },
    headerTitle: {
        marginLeft: 88,
        lineHeight: '68px',
        fontSize: 28,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 500,
    },
    bigButton: {
        textAlign: 'center',
        height: 120,
        '&:hover': {
            backgroundColor: 'orange',
            cursor: 'pointer',
        },
    },
    buttonLabelContainer: {
        lineHeight: '120px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 30,
        fontWeight: 600,
        color: '#5c5c5c',
        '&:hover': {
            color: '#ffffff',
        },
    },
    background: {
        backgroundColor: '#bfcad0',
        paddingTop: 128,
        height: 8000,
    },
    dialogContentContainer: {
        width: 350,
        height: 200,
        textAlign: 'center',
    },
    dialogTitle: {
        fontSize: 28,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 600,
        color: '#344955',
    },
    progressContainer: {
        textAlign: 'center',
        paddingTop: 32,
    },
    progress: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    backButton: {
        backgroundColor: '#344955',
        borderRadius: '50%',
        color: '#ffffff',
        height: 80,
        width: 80,
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
        '&:hover': {
            backgroundColor: 'orange',
            cursor: 'pointer',
        },
        textAlign: 'center',
        lineHeight: '80px',
        marginLeft: 70,
        position: 'fixed',
        bottom: '160px',
    },
    backIcon: {
        paddingTop: 21,
        paddingLeft: 13,
        fontSize: 36,
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 64,
        backgroundColor: '#344955',
        color: '#fff',
        padding: 10,
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    footerContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 'fit-content',
        display: 'flex',
        marginTop: 5,
    },
    footerStep: {
        width: 'fit-content',
        height: 44,
        borderRadius: 14,
        // lineHeight: 44,
        // color: 'orange',
        fontSize: 20,
        /* lineHeight: 120, */
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 600,
        padding: '0px 16px 0px 16px',
        borderStyle: 'solid',
        borderWidth: 5,
    },
    footerStepContent: {
        lineHeight: '44px',
        color: '#fff',
    },
    stepLine: {
        display: 'block',
        height: 1,
        border: 0,
        borderTop: '5px solid orange',
        margin: '23px 0',
        padding: 0,
        width: 16,
    },
    stepSet: {
        color: 'orange',
        borderTop: '5px solid orange',
    },
    stepUnset: {
        color: '#696969',
        borderTop: '5px solid #696969',
    },
});

class ManufacturedItem extends React.Component {
    state = {
        buildItemData: {
            createdByContactCode: null,
        },
        buildProducts: null,
        buildProductsLoading: true,
        showPrintingLabelDialog: false,
        contacts: null,
        contactsLoading: true,
    };

    componentDidMount() {
        this.fetchContactViewData();
    }

    fetchContactViewData = async () => {
        await fetchJson2('v2/group/1000/contacts')
            .then((contacts) => {
                this.setState({
                    contacts: contacts.payload,
                    contactsLoading: false,
                });
            });
        await fetchJson2('v2/buildProducts')
            .then((buildProducts) => {
                this.setState({
                    buildProducts: buildProducts.payload,
                    buildProductsLoading: false,
                });
            });
    };

    updateBuildItemData = (name, value) => {
        this.setState((state) => ({
            buildItemData: {
                ...state.buildItemData,
                [name]: value,
            },
        }));
        window.scrollTo({
            top: 0,
            // behavior: 'smooth' // Optional: for smooth scrolling behavior
        });
    };

    delay = async (time) => new Promise((resolve) => setTimeout(resolve, time));

    handleCreateItem = async (productCode) => {
        const productColour = this.state.buildProducts.find((buildProduct) => buildProduct.productCode === productCode).colour;
        this.setState((state) => ({
            buildItemData: {
                ...state.buildItemData,
                colour: productColour,
            },
        }));
        const payload = {
            ...this.state.buildItemData,
            productCode,
            branchCode: EUROWAY_1,
            stockRoomCode: MANUFACTURING,
            itemConditionCode: NEW,
            createdByContactCode: this.state.buildItemData.createdByContactCode,
        };

        let itemCode;
        try {
            itemCode = await this.createItem(payload);
            this.props.enqueueSnackbar('Item Created', { variant: 'success' });
        } catch (e) {
            console.log(e);
            this.props.enqueueSnackbar('Create Item Failed', { variant: 'error' });
            return;
        }

        try {
            this.setState({
                showPrintingLabelDialog: true,
            });
            await this.printStockLabel({
                contactCode: this.state.buildItemData.createdByContactCode,
                itemCode,
            });
            await this.delay(1000);
            this.setState({
                showPrintingLabelDialog: false,
                buildItemData: {},
            });
            this.props.enqueueSnackbar('Label Printed', { variant: 'success' });
        } catch (e) {
            console.log(e);
            this.props.enqueueSnackbar('Print Label Failed', { variant: 'error' });
        }

        // this.setState({
        //     showPrintingLabelDialog: true,
        // });
        // this.createItem(payload).then(() => {
        //     this.setState({
        //         showPrintingLabelDialog: false,
        //     });
        //     this.props.enqueueSnackbar('Item Created', { variant: 'success' });
        // }).catch(() => {
        //     console.log(this.props.errors);
        //     this.props.enqueueSnackbar('Create Item Failed', { variant: 'error' });
        // });
    };

    createItem = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/item`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            return Promise.resolve(body.item.code);
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    printStockLabel = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/item/${payload.itemCode}/stockLabel`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            return Promise.resolve();
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    handleBackButtonClick = () => {
        window.scrollTo({
            top: 0,
            // behavior: 'smooth',
        });
        if (this.state.buildItemData.component) {
            this.setState((state) => ({ buildItemData: { ...state.buildItemData, component: null } }));
            return;
        }
        if (this.state.buildItemData.range) {
            this.setState((state) => ({ buildItemData: { ...state.buildItemData, range: null } }));
            return;
        }
        if (this.state.buildItemData.createdByContactCode) {
            this.setState((state) => ({ buildItemData: { ...state.buildItemData, createdByContactCode: null } }));
        }
    };

    render() {
        const {
            classes,
        } = this.props;

        const {
            buildItemData,
            buildProducts,
            buildProductsLoading,
            showPrintingLabelDialog,
            contacts,
            contactsLoading,
        } = this.state;

        let currentBuildProducts = buildProducts;
        let currentRange = [];
        let currentComponents = [];
        let currentContact = {};
        let currentFullname = '';
        if (!buildProductsLoading) {
            currentBuildProducts = buildProducts.filter((buildProduct) => buildProduct.range === buildItemData.range && buildProduct.component === buildItemData.component);

            currentRange = uniq(buildProducts.map((buildProduct) => buildProduct.range));
            currentComponents = uniq(buildProducts.reduce((acc, buildProduct) => {
                if (buildProduct.range === buildItemData.range) return ([...acc, buildProduct.component]);
                return (acc);
            }, []));
        }
        if (!contactsLoading) {
            currentContact = contacts.find((contact) => contact.code === buildItemData.createdByContactCode);
            currentFullname = currentContact ? `${currentContact.firstname.toUpperCase()} ${currentContact.surname.toUpperCase()}` : '';
        }

        console.log('>>> :', currentBuildProducts);

        return (
            <>
                {
                    !contactsLoading && !buildProductsLoading
                        ? (
                            <>
                                <div className={classes.header}>
                                    <img className={classes.logo} src={logo} alt="logo" />
                                    <div className={classes.headerTitle}>Furniture Manufacturing</div>
                                </div>
                                <div className={classes.background}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={3}>
                                            {buildItemData.createdByContactCode
                                                ? (
                                                    <div
                                                        className={classes.backButton}
                                                        onClick={this.handleBackButtonClick}
                                                    >
                                                        <BackIcon className={classes.backIcon} />
                                                    </div>
                                                ) : null}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {!buildItemData.createdByContactCode
                                                ? (
                                                    <Grid container spacing={32}>
                                                        {contacts.map((contact) => (
                                                            <>
                                                                <Grid item xs={12}>
                                                                    <Paper
                                                                        onClick={() => this.updateBuildItemData('createdByContactCode', contact.code)}
                                                                        className={classes.bigButton}
                                                                    >
                                                                        <div className={classes.buttonLabelContainer}>
                                                                            <div>{`${contact.firstname} ${contact.surname}`.toUpperCase()}</div>
                                                                        </div>
                                                                        {' '}

                                                                    </Paper>
                                                                </Grid>
                                                            </>
                                                        ))}
                                                    </Grid>

                                                ) : null}
                                            {buildItemData.createdByContactCode && !buildItemData.range
                                                ? (
                                                    <Grid container spacing={32}>
                                                        {currentRange.map((range) => (
                                                            <>
                                                                <Grid item xs={12}>
                                                                    <Paper
                                                                        onClick={() => this.updateBuildItemData('range', range)}
                                                                        className={classes.bigButton}
                                                                    >
                                                                        <div className={classes.buttonLabelContainer}>
                                                                            <div>{range}</div>
                                                                        </div>
                                                                        {' '}

                                                                    </Paper>
                                                                </Grid>
                                                            </>
                                                        ))}
                                                    </Grid>

                                                ) : null}
                                            {buildItemData.range && !buildItemData.component
                                                ? (
                                                    <Grid container spacing={32}>
                                                        {currentComponents.map((component) => (
                                                            <>
                                                                <Grid item xs={12}>
                                                                    <Paper
                                                                        onClick={() => this.updateBuildItemData('component', component)}
                                                                        className={classes.bigButton}
                                                                    >
                                                                        <div className={classes.buttonLabelContainer}>
                                                                            <div>{component.replace('_', ' ')}</div>
                                                                        </div>
                                                                        {' '}

                                                                    </Paper>
                                                                </Grid>
                                                            </>
                                                        ))}
                                                    </Grid>

                                                ) : null}
                                            {buildItemData.component && !buildItemData.colour
                                                ? (
                                                    <Grid container spacing={32}>
                                                        {currentBuildProducts.map((currentBuildProduct) => (
                                                            <>
                                                                <Grid item xs={12}>
                                                                    <Paper
                                                                        onClick={() => this.handleCreateItem(currentBuildProduct.productCode)}
                                                                        className={classes.bigButton}
                                                                    >
                                                                        <div className={classes.buttonLabelContainer}>
                                                                            <div>{currentBuildProduct.colour.replace('_', ' ')}</div>
                                                                        </div>
                                                                        {' '}

                                                                    </Paper>
                                                                </Grid>
                                                            </>
                                                        ))}
                                                    </Grid>
                                                ) : null}

                                            <Dialog
                                                open={showPrintingLabelDialog}
                                                onClose={this.handleAddComplaintCloseClick}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogContent className={classes.dialogContent}>
                                                    <div className={classes.dialogContentContainer}>
                                                        <>
                                                            <div className={classes.dialogTitle}>
                                                                PRINTING
                                                                <br />
                                                                LABEL
                                                            </div>
                                                            {/* <Grid container spacing={0}> */}
                                                            <div className={classes.progressContainer}>
                                                                <CircularProgress className={classes.progress} variant="indeterminate" />
                                                            </div>
                                                            {/* </Grid> */}
                                                        </>
                                                    </div>
                                                </DialogContent>
                                            </Dialog>
                                        </Grid>
                                        <Grid item xs={3} />
                                    </Grid>
                                </div>
                                <div className={classes.footer}>
                                    <div className={classes.footerContainer}>
                                        <span className={classNames(
                                            classes.footerStep,
                                            {
                                                [classes.stepSet]: buildItemData.createdByContactCode,
                                            },
                                            {
                                                [classes.stepUnset]: !buildItemData.createdByContactCode,
                                            },
                                        )}
                                        >
                                            <div className={classes.footerStepContent}>
                                                {currentFullname}
                                            </div>
                                        </span>
                                        <hr className={classNames(
                                            classes.stepLine,
                                            {
                                                [classes.stepSet]: buildItemData.createdByContactCode,
                                            },
                                            {
                                                [classes.stepUnset]: !buildItemData.createdByContactCode,
                                            },
                                        )}
                                        />
                                        <span className={classNames(
                                            classes.footerStep,
                                            {
                                                [classes.stepSet]: buildItemData.range,
                                            },
                                            {
                                                [classes.stepUnset]: !buildItemData.range,
                                            },
                                        )}
                                        >
                                            <div className={classes.footerStepContent}>
                                                {buildItemData.range ? buildItemData.range.toUpperCase() : ''}
                                            </div>
                                        </span>
                                        <hr className={classNames(
                                            classes.stepLine,
                                            {
                                                [classes.stepSet]: buildItemData.range,
                                            },
                                            {
                                                [classes.stepUnset]: !buildItemData.range,
                                            },
                                        )}
                                        />
                                        <span className={classNames(
                                            classes.footerStep,
                                            {
                                                [classes.stepSet]: buildItemData.component,
                                            },
                                            {
                                                [classes.stepUnset]: !buildItemData.component,
                                            },
                                        )}
                                        >
                                            <div className={classes.footerStepContent}>
                                                {buildItemData.component ? buildItemData.component.replace('_', ' ').toUpperCase() : ''}
                                            </div>
                                        </span>
                                        <hr className={classNames(
                                            classes.stepLine,
                                            {
                                                [classes.stepSet]: buildItemData.component,
                                            },
                                            {
                                                [classes.stepUnset]: !buildItemData.component,
                                            },
                                        )}
                                        />
                                        <span className={classNames(
                                            classes.footerStep,
                                            {
                                                [classes.stepSet]: buildItemData.colour,
                                            },
                                            {
                                                [classes.stepUnset]: !buildItemData.colour,
                                            },
                                        )}
                                        >
                                            <div className={classes.footerStepContent}>
                                                {buildItemData.colour ? buildItemData.colour.replace('_', ' ').toUpperCase() : ''}
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </>
                        )
                        : (
                            <div className={classes.progressContainer}>
                                <CircularProgress className={classes.progress} variant="indeterminate" />
                            </div>
                        )
                }
            </>
        );
    }
}

export default withSnackbar(withStyles(styles)(ManufacturedItem));
