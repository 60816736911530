import React from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import GaugeChart from 'react-gauge-chart';
import HybridTextField from './hybridTextField';
import titalise from '../utilities/titalise';
import {
    titles,
    counties,
    countries,
} from '../utilities/menuOptions';
import Tile from '../containers/tile';

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.lighter,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        whiteSpace: 'nowrap',
        verticalAlign: 'top',
        paddingTop: 16,
        paddingBottom: 16,
    },
}))(TableCell);

const styles = theme => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    tileBottomDivider: {
        marginTop: theme.spacing.unit * 3,
        // marginBottom: theme.spacing.unit * 2,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 2.5,
        marginBottom: theme.spacing.unit * 2,
    },
    bottomBarContainer: {
        width: '100%',
        textAlign: 'right',
    },
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    nameField: {
        width: 350,
    },
    addressField: {
        width: 250,
    },
    postcodeField: {
        width: 120,
    },
    countryField: {
        width: 200,
        marginLeft: theme.spacing.unit * 3,
    },
    emailField: {
        width: 350,
    },
    rightOfFieldButton: {
        marginTop: 10,
        marginLeft: theme.spacing.unit * 3,
        color: 'rgba(0, 121, 255, 0.87)',
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
    },
    dialogActionsDivider: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    formHelperText: {
        color: '#f44336',
    },
    contactLocationIcon: {
        color: 'red',
        fontSize: 30,
        position: 'absolute',
        transform: 'translate(-50%, -100%)',
    },
    branchLocationIcon: {
        color: 'blue',
        fontSize: 30,
        position: 'absolute',
        transform: 'translate(-50%, -100%)',
    },
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    passChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '87px',
    },
    failChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '87px',
    },
    lightGrey: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
    table: {
        marginTop: theme.spacing.unit * 2,
        border: '1px solid #e0e0e0',
    },
    gaugeContainer: {
        width: 100,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    statusCell: {
        maxWidth: 250,
        whiteSpace: 'normal',
    },
});

class CustomerDuplicates extends React.Component {
    state = {
        showCustomerDetailsEditDialog: false,
        showCustomerAddressEditDialog: false,
        disableEditCustomerDetailsSaveButton: false,
        disableEditCustomerAddressSaveButton: false,
        showSendSMSDialog: false,
        disableSendSMSButton: false,
        smsForm: {
            message: '',
        },
        customerDetailsForm: {
            title: '',
            firstname: '',
            surname: '',
            homeTelephoneNumber: '',
            mobileTelephoneNumber: '',
            emailAddress: '',
            gdpr: '',
        },
        customerAddressForm: {
            newAddress: 0,
            address1: '',
            address2: '',
            townCity: '',
            county: '',
            postcode: '',
            country: '',
        },
    }

    componentDidMount() {
        this.setupFormData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.customer !== prevProps.customer) {
            this.setupFormData();
        }
    }

    setupFormData() {
        if (this.props.customer) {
            this.setState(state => ({
                customerDetailsForm: {
                    ...state.customerDetailsForm,
                    title: this.props.customer.title,
                    firstname: this.props.customer.firstname,
                    surname: this.props.customer.surname,
                    homeTelephoneNumber: this.props.customer.homeTelephoneNumber,
                    mobileTelephoneNumber: this.props.customer.mobileTelephoneNumber,
                    emailAddress: this.props.customer.emailAddress,
                    gdpr: this.props.customer.gdpr.toString(),
                },
                customerAddressForm: {
                    ...state.customerAddressForm,
                    newAddress: '0',
                    address1: this.props.customer.address1,
                    address2: this.props.customer.address2,
                    townCity: this.props.customer.townCity,
                    county: this.props.customer.county,
                    postcode: this.props.customer.postcode,
                    country: this.props.customer.country,
                },
            }));
        }
    }

    handleCustomerDetailsEditClick = () => {
        this.setState({
            showCustomerDetailsEditDialog: true,
        });
    }

    handleCustomerAddressEditClick = () => {
        this.setState({
            showCustomerAddressEditDialog: true,
        });
    }

    handleCustomerDetailsCloseDialogClick = () => {
        this.setState({
            showCustomerDetailsEditDialog: false,
        });
    }

    handleCustomerAddressCloseDialogClick = () => {
        this.setState({
            showCustomerAddressEditDialog: false,
        });
    }

    handleSendSMSClick = () => {
        this.setState({
            showSendSMSDialog: true,
        });
    }

    handleSendSMSCloseDialogClick = () => {
        this.setState({
            showSendSMSDialog: false,
        });
    }

    handleEditCustomerDetailsCheckBoxClick = name => (event) => {
        const newValue = this.state.customerDetailsForm[name] === '1' ? '0' : '1';
        console.log(this.state.customerDetailsForm[name], newValue);
        this.setState(state => ({
            customerDetailsForm: {
                ...state.customerDetailsForm,
                [name]: newValue,
            },
        }));
    }

    updateEditCustomerDetailsData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        case 'emailAddress':
            formattedValue = value.toLowerCase();
            break;
        default:
            formattedValue = titalise(value);
            break;
        }
        event.target.value = formattedValue;

        this.setState(state => ({
            customerDetailsForm: {
                ...state.customerDetailsForm,
                [name]: formattedValue,
            },
        }));

        // console.log({
        //     customerForm: {
        //         ...this.state.customerForm,
        //         [name]: formattedValue,
        //     },
        // })
        // console.log(formattedValue, name);
        // console.log(this.state.customerForm);
    }

    updateEditCustomerAddressData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        let customerAddressFormValues = { ...this.state.customerAddressForm };
        switch (name) {
        case 'postcode':
            formattedValue = value.toUpperCase();
            break;
        case 'county':
        case 'country':
            formattedValue = value;
            break;
        default:
            formattedValue = titalise(value);
            break;
        }
        event.target.value = formattedValue;

        if (name === 'newAddress' && value === '1') {
            Object.keys(customerAddressFormValues).forEach(k => customerAddressFormValues[k] = '');
        } else if (name === 'newAddress' && value === '0') {
            customerAddressFormValues = {
                address1: this.props.customer.address1,
                address2: this.props.customer.address2,
                townCity: this.props.customer.townCity,
                county: this.props.customer.county,
                postcode: this.props.customer.postcode,
                country: this.props.customer.country,
            };
        }

        this.setState({
            customerAddressForm: {
                ...customerAddressFormValues,
                [name]: formattedValue,
            },
        });

        // this.setState(state => ({
        //     customerAddressForm: {
        //         ...state.customerAddressForm,
        //         [name]: formattedValue,
        //     },
        // }));

        // console.log({
        //     customerForm: {
        //         ...this.state.customerForm,
        //         [name]: formattedValue,
        //     },
        // })
        // console.log(formattedValue, name);
        // console.log(this.state.customerForm);
    }

    handleSaveCustomerDetails = (event) => {
        event.preventDefault();
        this.setState({
            disableEditCustomerDetailsSaveButton: true,
        });
        this.props.saveCustomerDetails(this.state.customerDetailsForm).then(() => {
            this.setState({
                showCustomerDetailsEditDialog: false,
                disableEditCustomerDetailsSaveButton: false,
            });
            this.props.enqueueSnackbar('Customer details saved', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Customer details save failed', { variant: 'error' });
            this.setState({
                disableEditCustomerDetailsSaveButton: false,
            });
        });
    }

    handleSaveCustomerAddress = (event) => {
        event.preventDefault();
        this.setState({
            disableEditCustomerAddressSaveButton: true,
        });
        this.props.saveCustomerAddress(this.state.customerAddressForm).then(() => {
            this.setState({
                showCustomerAddressEditDialog: false,
                disableEditCustomerAddressSaveButton: false,
            });
            this.props.enqueueSnackbar('Customer Address saved', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Customer Address save failed', { variant: 'error' });
            this.setState({
                disableEditCustomerAddressSaveButton: false,
            });
        });
    }

    updateSendSMSData = (event) => {
        const { name, value } = event.target;
        const wrappedMessage = `PAY WEEKLY: ${value}. Do not reply.`;
        this.setState({
            smsForm: {
                ...this.state.smsForm,
                message: wrappedMessage,
            },
        });

        // console.log({
        //     smsForm: {
        //         ...this.state.smsForm,
        //         [name]: value,
        //     },
        // })
    }

    handleSendSMS = (event) => {
        event.preventDefault();
        this.setState({
            disableSendSMSButton: true,
        });
        this.props.sendSMS(this.state.smsForm).then(() => {
            this.setState({
                showSendSMSDialog: false,
                disableSendSMSButton: false,
            });
            this.props.enqueueSnackbar('SMS message sent', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('SMS message failed', { variant: 'error' });
            this.setState({
                disableSendSMSButton: false,
            });
        });
    }

    handleDuplicateClick = (link) => {
        window.open(link);
    }

    render() {
        const {
            classes,
            customer,
            duplicates,
            errors,
        } = this.props;
        const {
            customerAddressForm,
            customerDetailsForm,
            disableEditCustomerAddressSaveButton,
            disableEditCustomerDetailsSaveButton,
            disableSendSMSButton,
            showCustomerAddressEditDialog,
            showCustomerDetailsEditDialog,
            showSendSMSDialog,
        } = this.state;

        return (
            <Tile
                tileTitle="Customer Duplicates"
                style
            >
                { customer !== null && duplicates !== null
                    ? (
                        <React.Fragment>
                            {duplicates.length !== 0
                                ? (
                                    <React.Fragment>
                                        <Typography variant="body1">
                                            Possible duplicates found
                                        </Typography>
                                        <Paper elevation={0}>
                                            <Table className={classes.table} padding="dense">
                                                <TableHead>
                                                    <TableRow>
                                                        <CustomTableCell align="left">Company/App</CustomTableCell>
                                                        <CustomTableCell align="left">Reference</CustomTableCell>
                                                        <CustomTableCell align="left">Name/Address</CustomTableCell>
                                                        <CustomTableCell align="left">Email Address</CustomTableCell>
                                                        <CustomTableCell align="left">Telephone Numbers</CustomTableCell>
                                                        <CustomTableCell className={classes.statusCell} align="left">Status</CustomTableCell>
                                                        <CustomTableCell align="center">Scheduled Payments</CustomTableCell>
                                                        <CustomTableCell align="center">Payment Risk</CustomTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {duplicates.sort((a, b) => a.score - b.score).reverse().map(duplicate => (
                                                        <TableRow onClick={() => this.handleDuplicateClick(duplicate.link)} className={classes.row} key={duplicate.reference}>
                                                            <CustomTableCell align="left">
                                                                <div>{duplicate.companyName}</div>
                                                                <div className={classes.lightGrey}>{duplicate.application}</div>
                                                            </CustomTableCell>
                                                            <CustomTableCell align="left">{duplicate.reference}</CustomTableCell>
                                                            <CustomTableCell align="left">
                                                                <div>{duplicate.fullName}</div>
                                                                <div className={classes.lightGrey}>{duplicate.shortAddress}</div>
                                                            </CustomTableCell>
                                                            <CustomTableCell align="left">{duplicate.emailAddress}</CustomTableCell>
                                                            <CustomTableCell align="left">
                                                                <div>{duplicate.telephoneNumber1}</div>
                                                                <div>{duplicate.telephoneNumber2}</div>
                                                            </CustomTableCell>
                                                            <CustomTableCell className={classes.statusCell} align="left">
                                                                {duplicate.status}
                                                            </CustomTableCell>
                                                            <CustomTableCell align="center">
                                                                <div>{`${duplicate.authorisedPayments}/${duplicate.paymentAttempts}`}</div>
                                                                <div className={classes.lightGrey}>
                                                                    {`${duplicate.authorisedPayments > 0
                                                                        ? ((duplicate.authorisedPayments / duplicate.paymentAttempts) * 100).toFixed(0)
                                                                        : 0
                                                                    }%`}

                                                                </div>
                                                            </CustomTableCell>
                                                            <CustomTableCell align="center">
                                                                <div className={classes.gaugeContainer}>
                                                                    <GaugeChart
                                                                        id={`gauge-chart-${duplicate.reference}`}
                                                                        cornerRadius={1}
                                                                        hideText
                                                                        nrOfLevels={5}
                                                                        percent={duplicate.authorisedPayments > 0
                                                                            ? 1 - (duplicate.authorisedPayments / duplicate.paymentAttempts)
                                                                            : 0}
                                                                    />
                                                                </div>
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Paper>
                                    </React.Fragment>
                                )
                                : (
                                    <Typography variant="body1">
                                        No duplicates found
                                    </Typography>
                                )
                            }
                        </React.Fragment>
                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )
                }
            </Tile>
        );
    }
}

const formShape = {
    title: PropTypes.string,
    firstname: PropTypes.string,
    surname: PropTypes.string,
    fullName: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    townCity: PropTypes.string,
    county: PropTypes.string,
    postcode: PropTypes.string,
    country: PropTypes.string,
    homeTelephoneNumber: PropTypes.string,
    mobileTelephoneNumber: PropTypes.string,
    emailAddress: PropTypes.string,
    gdpr: PropTypes.number,
};

CustomerDuplicates.propTypes = {
    classes: PropTypes.object.isRequired,
    sendSMS: PropTypes.func.isRequired,
    saveCustomerDetails: PropTypes.func.isRequired,
    customer: PropTypes.shape(formShape).isRequired,

    errors: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(CustomerDuplicates));
