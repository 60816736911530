import React from 'react';
import {
    CircularProgress,
    Paper,
    Grid,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import moment from 'moment-timezone';
import HybridTextField from '../../hybridTextField';

const styles = (theme) => ({
    activeIcon: {
        color: '#4caf50',
    },
    alert: {
        color: '#f1180b',
    },
    hybridTextIconRight: {
        fontSize: 16,
        marginLeft: theme.spacing.unit * 1,
        transform: 'translate(0%, 13%)',
    },
    signatureContainer: {
        align: 'left',
        marginBottom: theme.spacing.unit * 1,
        // width: '100%',
    },
    signaturePaper: {
        backgroundColor: '#ffffff',
        border: '1px solid #e0e0e0',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing.unit * 2,
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 1}px`,
        width: '100%',
        maxWidth: 380,
    },
    notSetIcon: {
        color: '#d7d7d7',
    },
    image: {
        maxWidth: '70%',
    },
    paper: {
        alignItems: 'center',
        border: '1px solid #e0e0e0',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        width: '100%',
    },
    progress: {
        opacity: '.5',
    },
    progressContainer: {
        marginBottom: theme.spacing.unit * 3,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        width: 60,
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        marginBottom: 4,
    },
});

class ContactVisitSignature extends React.Component {
    state = {
    };

    render() {
        const {
            classes,
            signatureLink,
        } = this.props;

        // console.log('>>>To Deliver', visitItemsToDeliver);

        return (
            <div className={classes.signatureContainer}>
                <div className={classes.textFieldLabel}>
                    Signature
                </div>
                {signatureLink !== null
                    ? (
                        <>
                            {signatureLink
                                ? (
                                    <Paper
                                        className={classes.signaturePaper}
                                        elevation={1}
                                    >
                                        <img className={classes.image} src={signatureLink} alt="Visit Signature" />
                                    </Paper>
                                )
                                : (
                                    <Typography variant="body1">
                                        No signature found
                                    </Typography>
                                )}
                        </>
                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )}
            </div>
        );
    }
}

export default withSnackbar(withStyles(styles)(ContactVisitSignature));
