import React from 'react';
import {
    Button,
    Collapse,
    Divider,
    Grid,
    Menu,
    MenuItem,
    Paper,
    Switch,
} from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import RefreshIcon from '@material-ui/icons/Refresh';

const styles = theme => ({
    expandButton: {
        transform: 'rotate(0deg)',
        transitionDuration: '0.3s',
        transitionProperty: 'transform',
        cursor: 'pointer',
        marginTop: 1,
        fontSize: 23,
        width: '100%',
        opacity: 0.8,
    },
    collapsedExpandButton: {
        transform: 'rotate(-180deg)',
        transitionDuration: '0.3s',
        transitionProperty: 'transform',
    },
    paper: {
        marginTop: 34,
        // marginBottom: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    titleBarContainer: {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        width: '100%',
        backgroundColor: theme.palette.primary.light,
    },
    collapsedTitleBarContainer: {
        transitionDelay: '.2s',
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
    },
    tileTitle: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        textTransform: 'uppercase',
        // backgroundColor: theme.palette.primary.light,
        // margin: 0,
        // width: '100%',
        alignItems: 'left',
        color: 'white',
        paddingLeft: theme.spacing.unit * 2,
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 15,
        fontWeight: 500,
        // borderTopLeftRadius: 4,
        // borderTopRightRadius: 4,
    },
    tileContent: {
        // minHeight: 80,
        marginTop: theme.spacing.unit * 4,
        marginRight: theme.spacing.unit * 4,
        marginBottom: theme.spacing.unit * 4,
        marginLeft: theme.spacing.unit * 4,
        // display: 'none',
    },
    tileMenu: {
        lineHeight: 1.5,
        letterSpacing: '0.16065px',
        marginTop: 6,
        // width: '100%',
        textAlign: 'right',
        color: 'white',
        paddingRight: theme.spacing.unit * 2,
        // paddingTop: theme.spacing.unit,
        // paddingBottom: theme.spacing.unit,
        fontSize: 15,
        fontWeight: 500,
    },
    expandButtonContainer: {
        lineHeight: 1.5,
        letterSpacing: '0.16065px',
        marginTop: 6,
        textAlign: 'right',
        color: 'white',
        paddingRight: theme.spacing.unit * 2,
        fontWeight: 500,
    },
    menuIcon: {
        cursor: 'pointer',
        marginTop: 3,
        color: 'white',
        fontSize: 23,
    },
    refreshIcon: {
        cursor: 'pointer',
        marginTop: 3,
        marginRight: 8,
        // color: 'white',
        fontSize: 23,
    },
    menu: {
        minWidth: 150,
    },
    switchLabel: {
        marginRight: 8,
    },
});

class Tile extends React.Component {
    state = {
        anchorEl: null,
        collapsedTile: false,
    };

    componentDidMount() {
        const { initialCollapsedTile } = this.props;
        this.setState({
            collapsedTile: initialCollapsedTile,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.initialCollapsedTile !== prevProps.initialCollapsedTile) {
            this.setInitialCollapsedTile(this.props.initialCollapsedTile);
        }
    }

    setInitialCollapsedTile = (initialCollapsedTile) => {
        this.setState({
            collapsedTile: initialCollapsedTile,
        });
    }

    handleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleExpandClick = () => {
        this.setState(state => ({
            collapsedTile: !state.collapsedTile,
        }));
    }

    render() {
        const {
            allowCollapse,
            children,
            classes,
            refreshAction,
            refreshing,
            scrollToMarker,
            style,
            switchAction,
            switchLabel,
            switchValue,
            tileMenuItems,
            tileTitle,
        } = this.props;
        const {
            anchorEl,
            collapsedTile,
        } = this.state;

        return (
            <Collapse in={!collapsedTile} collapsedHeight="72px">
                <Paper name={scrollToMarker} className={classes.paper}>
                    <div className={`${classes.titleBarContainer} ${allowCollapse && collapsedTile && classes.collapsedTitleBarContainer}`}>
                        <Grid container spacing={0}>
                            <Grid item xs={5}>
                                <div className={classes.tileTitle}>
                                    {tileTitle}
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                {allowCollapse
                                    ? (
                                        <div className={classes.expandButtonContainer}>
                                            <ExpandIcon
                                                className={`${classes.expandButton} ${!collapsedTile && classes.collapsedExpandButton}`}
                                                onClick={this.handleExpandClick}
                                            />
                                        </div>
                                    )
                                    : null
                                }
                            </Grid>
                            {collapsedTile
                                ? null
                                : (
                                    <Grid item xs={5}>
                                        {tileMenuItems
                                    && tileMenuItems.length > 0
                                    && (
                                        <div className={classes.tileMenu}>
                                            {switchAction
                                                ? (
                                                    <>
                                                        <Switch
                                                            checked={switchValue}
                                                            onChange={switchAction}
                                                            value={switchValue}
                                                        />
                                                        <span className={classes.switchLabel}>
                                                            {switchLabel}
                                                        </span>
                                                    </>
                                                )
                                                : ''
                                            }
                                            {refreshAction
                                                ? (
                                                    <RefreshIcon
                                                        className={classes.refreshIcon}
                                                        color={refreshing ? 'disabled' : 'inherit'}
                                                        onClick={() => !refreshing && refreshAction()}
                                                    />
                                                )
                                                : ''
                                            }
                                            <MenuIcon
                                                className={classes.menuIcon}
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={this.handleClick}
                                            />
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={this.handleClose}
                                                classes={{ paper: classes.menu }}
                                            >
                                                {tileMenuItems.map((tileMenuItem, index) => (
                                                    tileMenuItem.section
                                                    && tileMenuItem.section > 1
                                                    && tileMenuItem.section !== tileMenuItems[index === 0 ? 0 : index - 1].section
                                                        ? (
                                                            [
                                                                <div key={`${index}-fragment`}>
                                                                    <Divider key={`${index}-divider`} className={classes.dialogDivider} />
                                                                    <MenuItem disabled={tileMenuItem.disabled} key={tileMenuItem.label} onClick={() => { this.handleClose(); tileMenuItem.action(); }}>{tileMenuItem.label}</MenuItem>
                                                                </div>,
                                                            ]
                                                        )
                                                        : (<MenuItem disabled={tileMenuItem.disabled} key={tileMenuItem.label} onClick={() => { this.handleClose(); tileMenuItem.action(); }}>{tileMenuItem.label}</MenuItem>)
                                                ))}
                                            </Menu>
                                        </div>
                                    )
                                        }
                                    </Grid>
                                )
                            }
                        </Grid>
                    </div>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <div className={style && classes.tileContent}>
                                {children}
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Collapse>
        );
    }
}

Tile.propTypes = {
    classes: PropTypes.object.isRequired,
    style: PropTypes.bool,
};

export default withStyles(styles)(Tile);
