import React from 'react';
import {
    CssBaseline,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Dialog,
    Button,
    DialogContent,
    Grid,
    Typography,
    FormControl,
    InputLabel,
    TextField,
    DialogActions,
    Select,
    MenuItem,
    ListSubheader,
    Tooltip,
    Fade,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker, DateTimePicker } from 'material-ui-pickers';
import MoreIcon from '@material-ui/icons/MoreHorizOutlined';
import FilterIcon from '@material-ui/icons/FilterListOutlined';
import SendIcon from '@material-ui/icons/SendOutlined';
import { withSnackbar } from 'notistack';
import moment from 'moment-timezone';
import Cookie from 'js-cookie';
import Layout from './Layout';
import fetch from '../utilities/fetch';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        marginBottom: theme.spacing.unit * 3,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    authorisedPaymentChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '87px',
    },
    declinedPaymentChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '87px',
    },
    unknownPaymentChip: {
        minWidth: '87px',
    },
    chip: {
        height: 21,
        borderRadius: 5,
    }
});

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        padding: '4px'
    },
    body: {
        fontSize: 14,
        padding: '4px'
    },
}))(TableCell);

class Arrears extends React.Component {

    state = {
        actions: [],
        details: [],
        filterOpen: false,
        detailsOpen: false,
        startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
    };

    async componentDidMount() {
        this.filterOpen();
    }

    getQuery() {
        return {
            startDate: this.state.startDate ? this.state.startDate : '',
            endDate: this.state.endDate ? this.state.endDate : '',
        };
    }

    async update() {
        try {
            const query = this.getQuery();
            const url = `${process.env.REACT_APP_API_SERVER_SYD}core/v2/arrears/report?${(new URLSearchParams(query)).toString()}`;
            console.log(url);
            const data = await fetch(url).then(
                response => response.json(),
            );
            this.setState({ actions: data });
        } catch (err) {
            console.log(err);
        }
    }

    updateDetails = async (batch) => {
        try {
            const query = { batch: batch};
            const url = `${process.env.REACT_APP_API_SERVER_SYD}core/v2/arrears/report/details?${(new URLSearchParams(query)).toString()}`;
            console.log(url);
            const data = await fetch(url).then(
                response => response.json(),
            );
            this.setState({ details: data });
        } catch (err) {
            console.log(err);
        }
    }

    detailsClick = async (batch) => {
        try {
            await this.setState({
                detailsOpen: true
            });
            this.updateDetails(batch);
        } catch (err) {
            console.log(err);
        }
    }

    filterOpen = async () => {
        try {
            await this.setState({
                filterOpen: true
            });
        } catch (err) {
            console.log(err);
        }
    }

    sendOpen = async () => {
        try {
            await this.setState({
                sendOpen: true,
            });
        } catch (err) {
            console.log(err);
        }
    }

    updateData = (event) => {
        const { name, value } = event.target;
        this.setState(state => ({
            [name]: value,
        }));
    }

    updateStartDate = (dt) => {
        this.setState(state => ({
            startDate: moment(dt).format('YYYY-MM-DD'),
        }));
    }

    updateEndDate = (dt) => {
        this.setState(state => ({
            endDate: moment(dt).format('YYYY-MM-DD'),
        }));
    }

    filterCancel = () => {
        this.setState({ filterOpen: false });
    };

    detailsClose = () => {
        this.setState({ detailsOpen: false });
    };

    filterApply = async () => {
        try {
            await this.setState({
                filterOpen: false
            });
            this.update();
            const timer = setTimeout(() => {
                this.setState({ helpSend: false });
            }, 7000);
    
        } catch (err) {
            console.log(err);
        }
    };

    render() {
        const { classes } = this.props;
        const { actions, details } = this.state;
        return (

            <Layout pageTitle="Arrears Report">
                <CssBaseline />
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>Last Recurring Payment</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Action Date</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Action</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Sent</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Pending</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Errors</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Paid</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Collected</CustomTableCell>
                                <CustomTableCell className={classes.tableHead} align="right">
                                    <IconButton onClick={() => this.filterOpen()} className={classes.button} aria-label="Edit"><FilterIcon /></IconButton>
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {actions.map((action, index) => (
                                <TableRow className={classes.row} key={action.Batch}>
                                    <CustomTableCell align="left">{ (index > 0 && action.Root == actions[index - 1].Root ? '↳ ' : '') + (action.LastRecurringPaymentDate  ? moment(action.LastRecurringPaymentDate).format('DD/MM/YY') : '*')}</CustomTableCell>
                                    <CustomTableCell align="left">{action.Date  ? moment(action.Date).format('DD/MM/YY') : '-'}</CustomTableCell>
                                    <CustomTableCell align="left">{action.Action}</CustomTableCell>
                                    <CustomTableCell align="left">{action.Sent}</CustomTableCell>
                                    <CustomTableCell align="left">{action.Pending}</CustomTableCell>
                                    <CustomTableCell align="left">{action.Errors}</CustomTableCell>
                                    <CustomTableCell align="left">{action.Paid}</CustomTableCell>
                                    <CustomTableCell align="left">£{action.Collected.toFixed(2)}</CustomTableCell>
                                    <CustomTableCell align="right"><IconButton onClick={() => this.detailsClick(action.Batch)} className={classes.button} aria-label="Edit"><MoreIcon /></IconButton></CustomTableCell>
                                </TableRow>

                            ))}
                        </TableBody>
                    </Table>
                    <Dialog
                        open={this.state.filterOpen}
                        className={classes.dialogContent}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogContent>
                            <div className={classes.dialogContentContainer}>
                                <React.Fragment>
                                    <Grid container spacing={0}>
                                        <Grid item container xs={6}>
                                            <Typography variant="h6" gutterBottom>
                                                Filter
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <form>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <FormControl
                                                    required
                                                    className={`${classes.menuFormControl} ${classes.filterSet}`}
                                                    variant="outlined"
                                                    fullWidth
                                                >
                                                    <DatePicker
                                                        required
                                                        name="startDate"
                                                        id="startDate"
                                                        margin="normal"
                                                        label="Date ≥"
                                                        value={this.state.startDate}
                                                        onChange={this.updateStartDate}
                                                        fullWidth
                                                        variant="outlined"
                                                        format="DD/MM/YYYY"
                                                        showTodayButton
                                                        autoOk
                                                    />
                                                    <DatePicker
                                                        required
                                                        name="endDate"
                                                        id="endDate"
                                                        margin="normal"
                                                        label="Date ≤"
                                                        value={this.state.endDate}
                                                        onChange={this.updateEndDate}
                                                        fullWidth
                                                        variant="outlined"
                                                        format="DD/MM/YYYY"
                                                        showTodayButton
                                                        autoOk
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </React.Fragment>
                            </div>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                onClick={this.filterApply}
                                color="primary"
                                autoFocus
                            >
                                Apply
                            </Button>
                            <Button
                                onClick={this.filterCancel}
                                color="primary"
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.detailsOpen}
                        className={classes.dialogContent}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogContent>
                            <Grid container spacing={0}>
                                <Grid item container xs={6} style={ { maxWidth: '100%', flexBasis: '100%' } }>
                                    <Typography variant="h6" gutterBottom>
                                        {details.length > 0 ? details[0].Action : ''} - {details.length > 0 && details[0].LastRecurringPaymentDate ? moment(details[0].LastRecurringPaymentDate).format('DD/MM/YY') : '*'} / {details.length > 0 ? moment(details[0].Date).format('DD/MM/YY') : '-'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Paper className={classes.root}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell className={classes.tableHead}>RA No</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead}>Payment Date</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead}>Source</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead}>Amount</CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {details.map((detail, index) => (
                                            <TableRow className={classes.row} key={index}>
                                                <CustomTableCell align="left">{detail.RANo}</CustomTableCell>
                                                <CustomTableCell align="left">{detail.NextPaymentDate  ? moment(detail.NextPaymentDate).format('DD/MM/YY HH:mm') : '-'}</CustomTableCell>
                                                <CustomTableCell align="left">{detail.NextPaymentSource ? detail.NextPaymentSource.replace(/([a-z0-9])([A-Z])/g, '$1 $2') : ''}</CustomTableCell>
                                                <CustomTableCell align="left">{detail.NextPaymentAmount ? '£' + detail.NextPaymentAmount.toFixed(2): ''}</CustomTableCell>
                                            </TableRow>

                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                onClick={this.detailsClose}
                                color="primary"
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Layout>
        );
    }
}

export default withSnackbar(withStyles(styles)(Arrears));
