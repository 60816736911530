import React from 'react';
import {
    CssBaseline,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Layout from './Layout';
import ControlDevice from '../components/controlDevice';
import ItemAssignment from '../components/itemAssignment';
import ItemLocation from '../components/itemLocation';
import ItemRentalAgreement from '../components/itemRentalAgreement';
import fetch from '../utilities/fetch';
import Tile from '../containers/tile';

const styles = theme => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
});

class Item extends React.Component {
    state = {
        error: false,
        loading: false,
        snackbarOpen: false,
        item: {
            code: '',
            createdDateTime: '',
            createdByFullName: '',
            modelNo: '',
            shortDescription: '',
            defaultWeeklyRentalAmount: '',
            defaultDeposit: '',
            department: '',
            manufacturer: '',
            supplier: '',
            branch: '',
            itemAssignmentCode: '',
            itemAssignmentDescription: '',
            controlDeviceCode: '',
            itemRentalAgreementCode: '',
        },
        saved: false,
        errors: {},
    }

    // static getDerivedStateFromProps(props, state) {
    //   const itemCode = props.match.params.itemCode;
    //   return {
    //     itemCode,
    //   }
    // }

    async componentDidMount() {
        const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/item/${this.props.match.params.itemCode}`);
        const data = await fetch(url).then(response => response.json());
        console.log(data);
        this.setState({ item: data });
    }

    handleSnackbarClose = (event, reason) => {
        this.setState({
            snackbarOpen: false,
        });
    }

    //itemAssignmentDescription={item.itemAssignmentDescription}
    render() {
        const { classes } = this.props;
        const { item } = this.state;

        return (
            <Layout pageTitle="Item">
                <CssBaseline />
                <Tile tileTitle="Location">
                    <ItemLocation
                        itemCode={item.code}
                        itemBranchCode={item.branchCode}
                        />
                </Tile>
                <Tile tileTitle="Assignment">
                    <ItemAssignment
                        itemCode={item.code}
                        itemAssignmentCode={item.itemAssignmentCode}
                        />
                </Tile>
                <Tile tileTitle="Rental Agreement">
                    <ItemRentalAgreement
                        itemCode={item.code}
                        itemRentalAgreementCode={item.itemRentalAgreementCode}
                        />
                </Tile>
                <Tile tileTitle="Control Device Details">
                    <ControlDevice
                        controlDeviceIccid={item.controlDeviceIccid}
                        parentItemCode={item.code}
                        controlDeviceTypeCode={item.controlDeviceTypeCode}
                        />
                </Tile>
            </Layout>
        );
    }
}

export default withStyles(styles)(Item);
