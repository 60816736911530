import React from 'react';
import {
    Badge,
    Tab,
    Tabs,
} from '@material-ui/core';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import ManufacturerSupplierDetails from './manufacturerSupplierDetails';

const styles = (theme) => ({
    badge: {
        verticalAlign: 'middle',
        marginLeft: 16,
        marginBottom: 3,
    },
    badgeContent: {
        paddingTop: 1,
    },
    tabs: {
        marginTop: theme.spacing.unit * 3,
    },
});

const tabs = [
    {
        disabled: false,
        label: 'DETAILS',
        key: 'details',
    },
    {
        disabled: false,
        label: 'PURCHASE ORDERS',
        key: 'purchaseOrders',
    },
];
class manufacturerSupplierTabs extends React.Component {
    state = {
        currentTab: 0,
    };

    componentDidMount() {
    }

    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value,
        });
    };

    render() {
        const {
            classes,
            errors,
            fetchHistoriesData,
            fetchManufacturerSupplierData,
            manufacturerSupplier,
            manufacturerSupplierHistoriesLoading,
            manufacturerSupplierLoading,
        } = this.props;
        const {
            currentTab,
        } = this.state;

        return (
            <>
                <Tabs
                    className={classes.tabs}
                    value={currentTab}
                    onChange={this.handleTabChange}
                >
                    {tabs.map((tab) => (
                        <Tab
                            key={tab.label}
                            label={(
                                <>
                                    {tab.label}
                                    {' '}
                                    {/* {!contactLoading && tab.label === 'DEBT' && contact.debt && contact.debt.status !== 'NORMAL' && (
                                        <FontAwesomeIcon icon={faExclamationTriangle} color="red" className={classes.WarningIcon} />
                                    )} */}
                                </>
                            )}
                            disabled={manufacturerSupplierLoading || tab.disabled}
                        />
                    ))}
                </Tabs>
                <>
                    { tabs[currentTab].label === 'DETAILS'
                        ? (
                            <>
                                <ManufacturerSupplierDetails
                                    errors={errors}
                                    fetchHistoriesData={fetchHistoriesData}
                                    fetchManufacturerSupplierData={fetchManufacturerSupplierData}
                                    manufacturerSupplier={manufacturerSupplier}
                                    manufacturerSupplierHistoriesLoading={manufacturerSupplierHistoriesLoading}
                                    manufacturerSupplierLoading={manufacturerSupplierLoading}
                                    passNewManufacturerSupplier={this.props.passNewManufacturerSupplier}
                                />
                            </>
                        )
                        : null}
                    { tabs[currentTab].label === 'PURCHASE ORDERS   '
                        ? (
                            <>
                                {/* <ManufcaturerPurchaseOrders
                                    addCompany={addCompany}
                                    contact={contact}
                                    contactLoading={contactLoading}
                                    debtCompanies={debtCompanies}
                                    debtStatuses={debtStatuses}
                                    errors={errors}
                                    fetchContactData={fetchContactData}
                                    saveContactDebtDetails={saveContactDebtDetails}
                                /> */}
                            </>
                        )
                        : null}
                </>
            </>
        );
    }
}

export default withStyles(styles)(manufacturerSupplierTabs);
