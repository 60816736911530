import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment-timezone';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import titalise from '../utilities/titalise';

const styles = theme => ({
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
        width: 500,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 2.5,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    filterSet: {
        colour: 'red',
    },
    minTextField: {
        paddingRight: theme.spacing.unit * 1.5,
    },
    marginTop3: {
        marginTop: theme.spacing.unit * 3,
    },
    maxTextField: {
        paddingLeft: theme.spacing.unit * 1.5,
    },
    filterNotSet: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
});

class BranchesTableEditDialog extends React.Component {
    state = {
        // errors: [],
        disableSubmitButton: false,
        showDialog: false,
        formData: {
        },
        formDataDefault: {
            hasStockrooms: 0,
        },
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.showEditBranchDialog !== prevProps.showEditBranchDialog) {
            if (this.props.showEditBranchDialog) this.handleOpenDialog();
            this.props.resetShowEditBranchDialog();
        }
    }

    presetFormData = () => {
        this.setState((state, props) => ({
            formData: {
                ...state.formDataDefault,
                branchCode: props.selectedBranch.code,
                name: props.selectedBranch.name,
                shortName: props.selectedBranch.shortName,
                hasStockrooms: props.selectedBranch.hasStockrooms,
                parentBranchCode: props.selectedBranch.parentBranchCode,
                expired: props.selectedBranch.expired,
            },
        }));
    }

    handleOpenDialog = () => {
        this.presetFormData();
        this.setState({
            showDialog: true,
        });
    }

    handleCloseDialog = () => {
        this.setState({
            showDialog: false,
        });
    }

    updateData = (event) => {
        const { value, name, selectionStart } = event.target;
        const currentCaretPosition = selectionStart;
        let formattedValue;
        switch (name) {
        case 'shortName':
            formattedValue = value.toUpperCase();
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;
        event.target.selectionStart = currentCaretPosition;
        event.target.selectionEnd = currentCaretPosition;
        this.setState(state => ({
            formData: {
                ...state.formData,
                [name]: formattedValue,
            },
        }));
    }

    updateFormDataCheckbox = name => (event) => {
        const newState = event.target.checked ? 1 : 0;
        this.setState(state => ({
            formData: {
                ...state.formData,
                [name]: newState,
            },
        }));
    }

    handleSubmitButtonClick = () => {
        const { formData } = this.state;
        this.setState({
            disableSubmitButton: true,
        });
        this.props.editBranch(formData).then(() => {
            this.setState({
                showDialog: false,
                disableSubmitButton: false,
            });
            this.props.enqueueSnackbar('Branch added', { variant: 'success' });
        }).catch(() => {
            this.setState({
                disableSubmitButton: false,
            });
            this.props.enqueueSnackbar('Add Branch failed', { variant: 'error' });
        });
    }

    render() {
        const {
            classes,
            selectedBranch,
            allBranches,
            errors,
        } = this.props;

        const {
            // errors,
            disableSubmitButton,
            formData,
            showDialog,
        } = this.state;

        return (
            <React.Fragment>
                {allBranches !== null
                    ? (
                        <Dialog
                            open={showDialog}
                            onClose={this.handleCloseDialog}
                        >
                            <DialogContent className={classes.dialogContent}>
                                <div className={classes.dialogContentContainer}>
                                    <React.Fragment>
                                        <Grid container spacing={0}>
                                            <Grid item container xs={6}>
                                                <Typography variant="h6" gutterBottom>
                                                    Edit Branch
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <form>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        disabled={formData.expired}
                                                        required
                                                        className={classes.formControl}
                                                        name="name"
                                                        id="name"
                                                        onChange={this.updateData}
                                                        value={formData.name}
                                                        label="Name"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.name}
                                                        helperText={errors.name}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        disabled={formData.expired}
                                                        required
                                                        className={classes.formControl}
                                                        name="shortName"
                                                        id="shortName"
                                                        onChange={this.updateData}
                                                        value={formData.shortName}
                                                        label="Short Name"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.shortName}
                                                        helperText={errors.shortName}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={(
                                                            <Checkbox
                                                                disabled={formData.expired}
                                                                name="hasStockrooms"
                                                                id="hasStockrooms"
                                                                checked={formData.hasStockrooms === 1}
                                                                onChange={this.updateFormDataCheckbox('hasStockrooms')}
                                                            />
                                                        )}
                                                        label="Has Stockrooms"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        disabled={formData.expired}
                                                        className={`${classes.menuFormControl} ${classes.filterSet}`}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.parentBranchCode}
                                                    >
                                                        <InputLabel htmlFor="parentBranchCode" className={classes.inputLabel}>Parent Branch</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateData}
                                                                    name="parentBranchCode"
                                                                    id="parentBranchCode"
                                                                />
                                                            )}
                                                            value={formData.parentBranchCode || ''}
                                                        >
                                                            {allBranches.map(branch => (
                                                                <MenuItem
                                                                    key={branch.code}
                                                                    value={branch.code}
                                                                >
                                                                    {branch.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.parentBranchCode && (
                                                            <FormHelperText>{errors.parentBranchCode}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Divider className={classes.dialogDivider} />
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={(
                                                            <Checkbox
                                                                name="expired"
                                                                id="expired"
                                                                checked={formData.expired === 1}
                                                                onChange={this.updateFormDataCheckbox('expired')}
                                                            />
                                                        )}
                                                        label="Expired"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} align="center">
                                                    <Button
                                                        onClick={this.handleSubmitButtonClick}
                                                        className={classes.dialogButton}
                                                        disabled={disableSubmitButton}
                                                    >
                                                        SUBMIT
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </React.Fragment>
                                </div>
                            </DialogContent>
                            <Divider className={classes.dialogActionsDivider} />
                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    onClick={this.handleCloseDialog}
                                    color="primary"
                                    autoFocus
                                >
                            Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                    : null
                }
            </React.Fragment>
        );
    }
}

const branchesShape = {
    code: PropTypes.number,
    name: PropTypes.string,
};

BranchesTableEditDialog.propTypes = {
    branches: PropTypes.arrayOf(PropTypes.shape(branchesShape)).isRequired,
    classes: PropTypes.object.isRequired,
    resetShowFiltersDialog: PropTypes.func.isRequired,
    showFiltersDialog: PropTypes.bool.isRequired,
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
};

export default withSnackbar(withStyles(styles)(BranchesTableEditDialog));
