import React from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import { DatePicker, DateTimePicker } from 'material-ui-pickers';
import moment from 'moment-timezone';

const styles = theme => ({
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
        width: 500,
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    filterSet: {
        colour: 'red',
    },
    minTextField: {
        paddingRight: theme.spacing.unit * 1.5,
    },
    maxTextField: {
        paddingLeft: theme.spacing.unit * 1.5,
    },
    progressContainer: {
        marginBottom: theme.spacing.unit * 3,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 200,
        width: 60,
    },
    filterNotSet: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
});

const currentDate = moment();
const lastMonthStartDate = moment(currentDate).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
const lastMonthEndDate = moment(currentDate).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');

class customerVisitAddDialog extends React.Component {
    state = {
        disableSubmitButton: false,
        errors: [],
        formData: {
        },
        formDataDefault: {
            atDate: lastMonthEndDate,
            branchCode: '',
            endDate: lastMonthEndDate,
            report: 'Agreement Without Card',
            startDate: lastMonthStartDate,
        },
    }

    componentDidMount = () => {
        this.presetFormData();
    }

    presetFormData = () => {
        this.setState(state => ({
            formData: {
                ...state.formDataDefault,
            },
        }));
    }

    handleCloseDialog = () => {
        this.props.resetShowCustomerVisitAddDialog();
    }

    updateData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;
        this.setState(state => ({
            formData: {
                ...state.formData,
                [name]: formattedValue,
            },
        }));
    }

    handleSubmitButtonClick = () => {
        this.setState({
            disableSubmitButton: true,
        });
        // const formattedFormData = {};
        // Object.keys(this.state.formData).forEach((k, i) => {
        //     if (this.state.formData[k] === '') {
        //         formattedFormData[k] = null;
        //         return;
        //     }
        //     formattedFormData[k] = this.state.formData[k];
        // });
        this.props.updateCriteria(this.state.formData).then(() => {
            this.setState({
                disableSubmitButton: false,
            });
            this.handleCloseDialog();
        }).catch(() => {
            this.setState({
                disableSubmitButton: false,
            });
        });
    }

    handleScheduledVisitDateChange = (chosenDate) => {
        this.setState(state => ({
            formData: {
                ...state.formData,
                scheduledVisitDateTime: moment(chosenDate).format('YYYY-MM-DD HH:mm'),
            },
        }));
    }

    render() {
        const {
            classes,
            customerVisitAddDialogLoading,
            engineers,
            // reportSummaryLoading,
            showCustomerVisitAddDialog,
        } = this.props;

        const {
            disableSubmitButton,
            errors,
            formData,
        } = this.state;

        console.log('>>> customerVisitAddDialogLoading', customerVisitAddDialogLoading);

        return (
            <React.Fragment>
                {!customerVisitAddDialogLoading
                // !reportSummaryLoading && !reportDataLoading
                    ? (
                        <Dialog
                            open={showCustomerVisitAddDialog}
                            onClose={this.handleCloseDialog}
                        >
                            <DialogContent className={classes.dialogContent}>
                                <div className={classes.dialogContentContainer}>
                                    <React.Fragment>
                                        <Grid container spacing={0}>
                                            <Grid item container xs={6}>
                                                <Typography variant="h6" gutterBottom>
                                            New Visit
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <form>
                                            <Grid container spacing={0}>
                                                <DateTimePicker
                                                    name="scheduledVisitDateTime"
                                                    id="scheduledVisitDateTime"
                                                    margin="normal"
                                                    label="Visit Date and Time"
                                                    value={formData.scheduledVisitDateTime}
                                                    onChange={this.handleScheduledVisitDateChange}
                                                    fullWidth
                                                    variant="outlined"
                                                    format="DD-MM-YYYY HH:mm"
                                                    showTodayButton
                                                    invalidLabel="No limit"
                                                    autoOk
                                                    ampm={false}
                                                />
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.scheduledEngineerContactCode}
                                                    >
                                                        <InputLabel htmlFor="scheduledEngineerContactCode" className={classes.inputLabel}>Operative</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateData}
                                                                    name="scheduledEngineerContactCode"
                                                                    id="scheduledEngineerContactCode"
                                                                />
                                                            )}
                                                            value={formData.scheduledEngineerContactCode}
                                                        >
                                                            {engineers.map(engineer => (
                                                                <MenuItem
                                                                    key={engineer.code}
                                                                    value={engineer.code || ''}
                                                                >
                                                                    {`${engineer.firstName} ${engineer.surname}`}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.scheduledEngineerContactCode && (
                                                            <FormHelperText>{errors.scheduledEngineerContactCode}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} align="center">
                                                    <Button
                                                        onClick={this.handleSubmitButtonClick}
                                                        className={classes.dialogButton}
                                                        disabled={disableSubmitButton}
                                                    >
                                                SUBMIT
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </React.Fragment>
                                </div>
                            </DialogContent>
                            <Divider className={classes.dialogActionsDivider} />
                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    onClick={this.handleCloseDialog}
                                    color="primary"
                                    autoFocus
                                >
                            Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    ) : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )}
            </React.Fragment>
        );
    }
}


customerVisitAddDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(customerVisitAddDialog));
