import React from 'react';

import {
    Button,
    List,
    ListItem,
    ListItemText,
    FormControl,
    Grid,
    Paper,
    TextField,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';
import { red } from '@material-ui/core/colors';
import { faAlignCenter } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import fetch from '../../utilities/fetch';

const currentDateTime = moment.tz(moment(), 'Europe/London');

const styles = (theme) => ({
    buttonBar: {
        position: 'fixed',
        paddingTop: 11,
        paddingBottom: 18,
        bottom: 0,
        width: '100%',
        height: 30,
        boxShadow: '0px 1px 8px 0px rgb(0 0 0 / 80%)',
        backgroundColor: '#f2f1f1',
        textAlign: 'center',
    },
    topButtonBar: {
        position: 'fixed',
        paddingTop: 11,
        paddingBottom: 18,
        top: 0,
        width: '100%',
        height: 30,
        // boxShadow: '0px 1px 8px 0px rgb(0 0 0 / 80%)',
        backgroundColor: 'aliceblue',
        textAlign: 'left',
    },
    clearButton: {
        textAlign: 'right',
        paddingRight: theme.spacing.unit * 2,
    },
    signatureText: {
        textAlign: 'left',
        paddingLeft: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit * 1,
    },
    contentContainer: {
        paddingBottom: 40,
        maxWidth: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    logo: {
        margin: theme.spacing.unit * 7,
        width: 128,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    logoContainer: {
        textAlign: 'center',
    },
    startContainer: {
        paddingRight: theme.spacing.unit * 4,
        paddingLeft: theme.spacing.unit * 4,
    },
    signatureMain: {
        width: '100%',
        height: '100vh',
        top: '10%',
        left: '10%',
    },
    signatureContainer: {
        width: '100%',
        // height: 200,
        // margin: '0 auto',
        paddingTop: 59,
    },
    signaturePad: {
        // minHeight: 300,
        height: '100vh',
        width: '100%',
        // opacity: 0.5,
        '&::after': {
            position: 'absolute',
            bottom: 100,
            width: '100%',
            borderBottom: 'dashed 1px red',
            content: '\'\'',
        },
    },
    text: {
        fontSize: 16,
    },
    buttonContainer: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
    centre: {
        textAlign: 'center',
    },
    signOff: {
        paddingTop: theme.spacing.unit * 2,
    },
    dialogTelephoneNumber: {
        textAlign: 'center',
        marginTop: theme.spacing.unit * 2,
        fontSize: 32,
    },
    telephoneNUmber: {
        textDecoration: 'none',
        color: '#808080',
    },
});

class VisitRebook extends React.Component {
    state = {
        visitLoading: true,
        showGuidErrorDialog: false,
        showConfirmDialog: false,
        showPleaseCallDialog: false,
        stage: 'CONFIRM',
        visit: {},
    };

    static getDerivedStateFromProps(props, state) {
        const { guid } = props.match.params;
        return {
            guid,
        };
    }

    componentDidMount = async () => {
        // await this.fetchRebookVisit;
        this.fetchRebookVisit().then(() => {
            this.props.enqueueSnackbar('Visit fetched', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('visit fetch failed', { variant: 'error' });
        });
    };

    fetchRebookVisit = async () => {
        let response;
        let body;

        try {
            // response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/v2/p/visit/${this.state.guid}`, {
            response = await fetch(`http://api.portal2.payweeklycarpets.localhost:3001/v2/p/visit/${this.state.guid}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'GET',
            });
        } catch (e) {
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            const daysBeforeVisit = (moment(body.scheduledVisitDateTime).diff(currentDateTime, 'days')) + 1;
            this.setState({
                showConfirmDialog: daysBeforeVisit > 3,
                showPleaseCallDialog: daysBeforeVisit <= 3,
                visit: body,
                visitLoading: false,
            });
            return Promise.resolve();
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    handleYesButton = async () => {
        let response;
        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/v2/p/visit/${this.state.guid}/rebook`, {
            // response = await fetch(`http://api.portal2.payweeklycarpets.localhost:3001/v2/p/visit/${this.state.guid}/rebook`, {
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.status !== 204) {
            this.setState({
                showGuidErrorDialog: true,
                showConfirmDialog: false,
                stage: 'ERROR',
            });
            return;
        }

        this.setState({
            showConfirmDialog: false,
            stage: 'YES',
        });
    };

    handleNoButton = () => {
        this.setState({
            showConfirmDialog: false,
            stage: 'NO',
        });
    };

    // fetchContract = async () => {
    //     // let contract;
    //     // try {
    //     //     contract = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/v2/agreement/${this.state.guid}/contract`, {
    //     //         method: 'GET',
    //     //     }).then(response => response.text());
    //     // } catch (e) {
    //     //     // something went really wrong; timeout/ blocked by client etc
    //     //     // debugger;
    //     //     console.log(e);
    //     // }
    //     let response;
    //     try {
    //         response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/v2/agreement/${this.state.guid}/contract`, {
    //         // response = await fetch(`http://api.portal2.payweeklycarpets.localhost:3001/v2/agreement/${this.state.guid}/contract`, {
    //             method: 'GET',
    //         });
    //     } catch (e) {
    //         // something went really wrong; timeout/ blocked by client etc
    //         // debugger;
    //         console.log(e);
    //     }

    //     console.log('>>> response:', response);

    //     if (response.status !== 200) {
    //         this.setState({
    //             showGuidErrorDialog: true,
    //         });
    //     }

    //     const contract = await response.text();
    //     this.setState({
    //         contract,
    //     });
    // }

    // fetchStartNotes = async () => {
    //     let startNotes;
    //     try {
    //         startNotes = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/v2/agreement/${this.state.guid}/startNotes`, {
    //             method: 'GET',
    //         }).then(response => response.text());
    //     } catch (e) {
    //         // something went really wrong; timeout/ blocked by client etc
    //         // debugger;
    //         console.log(e);
    //     }
    //     this.setState({
    //         startNotes,
    //     });
    // }

    // handleSignatureClear = () => {
    //     this.sigPad.clear();
    // }

    handleConfirmDialogClose = () => {
        this.setState({
            showConfirmDialog: false,
        });
    };

    // handleSubmitSignature = async () => {
    //     if (this.sigPad.isEmpty()) {
    //         this.setState({
    //             showEmptyDialog: true,
    //         });
    //         return;
    //     }
    //     const formData = new FormData();
    //     formData.append('termsConditionsCode', 1000);
    //     const signature = this.sigPad.getTrimmedCanvas();
    //     const blob = await new Promise((resolve) => {
    //         signature.toBlob(resolve);
    //     });
    //     formData.append('signature', blob, 'signature.png');
    //     try {
    //         await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/v2/test/agreement/${this.state.guid}/start`, {
    //         // await fetch('http://api.portal2.payweeklycarpets.localhost:3001/v2/test/agreement/71201/start', {
    //             body: formData,
    //             method: 'POST',
    //         });
    //     } catch (e) {
    //         // something went really wrong; timeout/ blocked by client etc
    //         // debugger;
    //         console.log(e);
    //     }
    //     const newStageNo = this.state.stageNo + 1;
    //     this.setState({
    //         stageNo: newStageNo,
    //     });
    // }

    render = () => {
        const { classes } = this.props;
        const {
            showConfirmDialog,
            showGuidErrorDialog,
            showPleaseCallDialog,
            stage,
            visit,
            visitLoading,
        } = this.state;

        const imageUrl = window.location.host === 'portal2.payweeklycarpets.co.uk' ? 'https://pwc-email-images.s3.eu-west-1.amazonaws.com/logo-main-small.png' : 'https://pwc-email-images.s3.eu-west-1.amazonaws.com/ass_logo_main_small.jpg';

        return (
            <>
                <div className={classes.logoContainer}>
                    <img
                        className={classes.logo}
                        src={imageUrl}
                        alt="Pay Weekly Logo"
                    />
                </div>

                {stage === 'NO'
                    ? (
                        <>
                            <div className={classes.contentContainer}>
                                <div className={classes.startContainer}>
                                    <Typography paragraph className={[classes.text, classes.centre]}>
                                        Your visit has not been changed.
                                    </Typography>
                                    <Typography paragraph className={[classes.text, classes.centre, classes.signOff]}>
                                        The Pay Weekly team
                                    </Typography>

                                </div>
                            </div>
                        </>
                    )
                    : ''}
                {stage === 'YES'
                    ? (
                        <>
                            <div className={classes.contentContainer}>
                                <div className={classes.startContainer}>
                                    <Typography paragraph className={[classes.text, classes.centre]}>
                                        Your visit has been cancelled.
                                    </Typography>
                                    <Typography paragraph className={[classes.text, classes.centre]}>
                                        You will be contacted shortly to arrange a new date.
                                    </Typography>
                                    <Typography paragraph className={[classes.text, classes.centre, classes.signOff]}>
                                        The Pay Weekly team
                                    </Typography>
                                </div>
                            </div>
                        </>
                    )
                    : ''}
                <Dialog
                    open={showGuidErrorDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Invalid Link</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <DialogContentText id="alert-dialog-description">
                            This link has already been used or has expired.
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={showConfirmDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Cancel Visit</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to cancel your visit?
                        </DialogContentText>
                        <Grid container spacing={0} className={classes.buttonContainer}>
                            <Grid item xs={6} style={{ textAlign: 'center' }}>
                                <Button
                                    onClick={() => this.handleYesButton()}
                                    variant="outline"
                                    className={classes.dialogButton}
                                >
                                    YES
                                </Button>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'center' }}>
                                <Button
                                    onClick={() => this.handleNoButton()}
                                    variant="outline"
                                    className={classes.dialogButton}
                                >
                                    NO
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={showPleaseCallDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Cancel Visit</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <DialogContentText id="alert-dialog-description">
                            Please call customer services to rebook your appointment
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description" className={classes.dialogTelephoneNumber}>
                            <a className={classes.telephoneNUmber} href="tel:01535 609200">01535 609200</a>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

            </>

        );
    };
}

export default withSnackbar(withStyles(styles)(withRouter(VisitRebook)));
