import React from 'react';
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import CrossIcon from '@material-ui/icons/Close';
import fetch, { fetchJson2 } from '../../utilities/fetch';
import AgreementPaymentSummaryTable from './contactAgreements/agreementPaymentSummaryTable';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    bumpBottom4U: {
        marginBottom: theme.spacing.unit * 4,
    },
    bumpBottom3U: {
        marginBottom: theme.spacing.unit * 3,
    },
    bumpBottom2U: {
        marginBottom: theme.spacing.unit * 2,
    },
    bumpBottom1U: {
        marginBottom: theme.spacing.unit * 1,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '8px 24px',
        margin: '0px 0px 0px 0px !important',
    },
    dialogContent: {
        paddingBottom: 16,
        minWidth: 390,
    },
    dialogContentContainer: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    paymentSummaryContainer: {
        display: 'block',
        boxSizing: 'unset',
    },
    pipContainer: {
        display: 'inline-flex',
        marginTop: 8,
        marginBottom: 8,
    },
    paymentPip: {
        borderRadius: 4,
        // marginRight: 4,
        minWidth: 17,
        height: 22,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        '&:not(:last-child)': {
            marginRight: 4,
        },
        fontWeight: 400,
        fontSize: 14,
        lineHeight: 'normal',
    },
    paidPip: {
        borderRadius: 4,
        // marginRight: 4,
        minWidth: 110,
        height: 22,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: 'normal',
    },
    payment: {
        backgroundColor: '#4caf50',
        border: '#52d157',
        borderWidth: 1,
        borderStyle: 'solid',
        textAlign: 'center',
        paddingTop: 4,
        // height: 22,
    },
    noPayment: {
        backgroundColor: '#f44336',
        border: '#f59992',
        borderWidth: 1,
        borderStyle: 'solid',
        textAlign: 'center',
        paddingTop: 4,
        // height: 22,
    },
    noScheduledPayment: {
        backgroundColor: 'grey',
        border: '#a2a1a1',
        borderWidth: 1,
        borderStyle: 'solid',
        paddingTop: 4,
        // height: 22,
    },
    pipIcon: {
        marginTop: 2,
    },
});

class AgreementPaymentSummary extends React.Component {
    state = {
        payments: [],
        showPaymentsDialog: false,
    };

    pipClick = async (event) => {
        event.stopPropagation();
        let response;
        try {
            response = await fetchJson2(`v2/agreement/${this.props.agreementCode}/payments`);
            this.setState({
                payments: response.payload,
            });
        } finally {
            this.setState({
                showPaymentsDialog: true,
            });
        }
    };

    handleClosePaymentsDialogClick = (event) => {
        event.stopPropagation();
        this.setState({
            showPaymentsDialog: false,
        });
    };

    render() {
        const {
            agreementCode,
            classes,
            agreementAmount,
            agreementBalance,
            agreementPaymentSummary,
            agreementPaymentSummaryLoading,
        } = this.props;
        const {
            payments,
            showPaymentsDialog,
        } = this.state;

        console.log('>>> Agreement Balance:', agreementBalance);

        const paddedAgreementPaymentSummary = new Array(5 - agreementPaymentSummary.slice(-5).length).fill(null).concat(agreementPaymentSummary);
        return (
            <>
                {!agreementPaymentSummaryLoading
                    ? (
                        <div
                            className={classes.paymentSummaryContainer}
                            onClick={this.pipClick}
                        >
                            {agreementAmount > 0 && agreementBalance === 0
                                ? (
                                    <div className={classes.pipContainer}>
                                        <div
                                            className={classNames(classes.paidPip, classes.payment)}
                                        >
                                            PAID
                                        </div>
                                    </div>

                                )
                                : (
                                    <div className={classes.pipContainer}>
                                        {paddedAgreementPaymentSummary.slice(-5).map((payment, i) => (
                                            <div
                                                key={i}
                                                className={classNames(
                                                    classes.paymentPip,
                                                    {
                                                        [classes.payment]: payment === true,
                                                    },
                                                    {
                                                        [classes.noPayment]: payment === false,
                                                    },
                                                    {
                                                        [classes.noScheduledPayment]: payment === null,
                                                    },
                                                )}
                                            >
                                                {payment && payment != null
                                                    ? '£'
                                                    : (
                                                        <>
                                                            {payment === false
                                                                ? (
                                                                    <CrossIcon
                                                                        className={classes.pipIcon}
                                                                        fontSize="inherit"
                                                                    />
                                                                )
                                                                : ''}
                                                        </>
                                                    ) }
                                            </div>
                                        ))}
                                    </div>
                                )}
                        </div>
                    )
                    : ''}

                <Dialog
                    open={showPaymentsDialog}
                    onClose={this.handleClosePaymentsDialogClick}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="lg"
                >
                    <DialogContent
                        className={classes.dialogContent}
                        // className={classes.PaymentsDialog}
                    >
                        <div className={classes.dialogContentContainer}>
                            <>
                                <Typography variant="h6" gutterBottom className={classes.bumpBottom4U}>
                                    <div>
                                        RA
                                        {agreementCode}
                                    </div>
                                    <div>
                                        Agreement Payments
                                    </div>
                                </Typography>
                                <Grid item xs={12}>
                                    <AgreementPaymentSummaryTable
                                        payments={payments}
                                    />
                                </Grid>
                            </>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            onClick={this.handleClosePaymentsDialogClick}
                            color="primary"
                            autoFocus
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

            </>
        );
    }
}

export default withStyles(styles)(AgreementPaymentSummary);
