import React from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import orderBy from 'lodash/orderBy';
import sleep from '../utilities/sleep';

const styles = theme => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 11,
        paddingTop: 8,
        paddingBottom: 4,
        paddingLeft: 4,
        paddingRight: 4,
    },
    shortDescription: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
    noWrap: {
        whiteSpace: 'nowrap',
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    tableCell: {
        fontSize: 11,
        verticalAlign: 'top',
        paddingTop: 8,
        paddingBottom: 4,
        paddingLeft: 4,
        paddingRight: 4,
    },
});

class ItemsTable extends React.Component {
    state = {
    }

    componentDidMount = async () => {
        await sleep(1500);
        window.parent.postMessage({ action: 'report-loaded' });
    }

    render() {
        const {
            classes,
            controls,
            items,
            keyLookup,
            orderColumn,
            orderDirection,
        } = this.props;

        return (
            <React.Fragment>
                {controls != null && (
                    <React.Fragment>
                        <Paper>
                            { items !== []
                                ? (
                                    <React.Fragment>
                                        <Table className={classes.table} padding="dense">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.tableHead}>
                                                        <div
                                                            id="code"
                                                            className={classes.noWrap}
                                                        >
                                                    Code
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.tableHead}>
                                                        <div id="manufacturer" className={classes.noWrap}>
                                                    Manufacturer
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.tableHead}>
                                                        <div id="model" className={classes.noWrap}>
                                                    Model
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.tableHead}>
                                                        <div id="colour" className={classes.noWrap}>
                                                    Colour
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.tableHead}>
                                                        <div id="type" className={classes.noWrap}>
                                                    Type
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.tableHead}>
                                                        <div id="branch" className={classes.noWrap}>
                                                    Branch
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.tableHead}>
                                                        <div id="stockRoom" className={classes.noWrap}>
                                                    Stock Room
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.tableHead}>
                                                        <div id="condition" className={classes.noWrap}>
                                                    Condition
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.tableHead}>
                                                        <div id="length" className={classes.noWrap}>
                                                    Length
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.tableHead}>
                                                        <div id="width" className={classes.noWrap}>
                                                    Width
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.tableHead}>
                                                        <div id="width" className={classes.noWrap}>
                                                    Assignment
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.tableHead}>
                                                        <div id="width" className={classes.noWrap}>
                                                    Customer Ref
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.tableHead}>
                                                        <div id="width" className={classes.noWrap}>
                                                    Created Date
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {orderBy(items, keyLookup[`${controls.orderColumn}`], controls.orderDirection).map(item => (
                                                    <TableRow className={classes.row} key={item.code}>
                                                        <TableCell className={classes.tableCell} align="left">{`SC${item.code}`}</TableCell>
                                                        <TableCell className={classes.tableCell} align="left">{item.manufacturerName}</TableCell>
                                                        <TableCell className={classes.tableCell} align="left">
                                                            <div>{item.modelNo}</div>
                                                            <div className={classes.shortDescription}>{item.shortDescription}</div>
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell} align="left">{item.colour}</TableCell>
                                                        <TableCell className={classes.tableCell} align="left">{item.productTypeDescription}</TableCell>
                                                        <TableCell className={classes.tableCell} align="left">{item.branchName}</TableCell>
                                                        <TableCell className={classes.tableCell} align="left">
                                                            <div>{item.stockRoom}</div>
                                                            <div className={classes.shortDescription}>{item.binName}</div>
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell} align="left">{item.itemConditionDescription}</TableCell>
                                                        <TableCell className={classes.tableCell} align="right">{item.length ? `${item.length}m` : ''}</TableCell>
                                                        <TableCell className={classes.tableCell} align="right">{item.width ? `${item.width}m` : ''}</TableCell>
                                                        <TableCell className={classes.tableCell} align="left">{item.assignment}</TableCell>
                                                        <TableCell className={classes.tableCell} align="right">{item.supplierOrderCustomerReference}</TableCell>
                                                        <TableCell className={classes.tableCell} align="left">{item.createdDateFormatted}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </React.Fragment>
                                )
                                : null
                            }
                        </Paper>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const itemShape = {
    branchName: PropTypes.string,
    code: PropTypes.number,
    colour: PropTypes.string,
    itemConditionDescription: PropTypes.string,
    length: PropTypes.number,
    manufacturerName: PropTypes.string,
    modelNo: PropTypes.string,
    productTypeDescription: PropTypes.string,
    shortDescription: PropTypes.string,
    width: PropTypes.number,
};

ItemsTable.propTypes = {
    classes: PropTypes.object.isRequired,
    controls: PropTypes.object.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
};

export default withStyles(styles)(ItemsTable);
