import React from 'react';
import {
    CssBaseline,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSimCard } from '@fortawesome/free-solid-svg-icons'
import qs from 'qs';

import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import Layout from './Layout';
import fetch from '../utilities/fetch';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        marginBottom: theme.spacing.unit * 3,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
        // whiteSpace: 'nowrap',
    },
    customTableCell: {
        whiteSpace: 'nowrap',
    },
    simCard: {
        fontSize: 20,
    },
});

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

class Items extends React.Component {
    state = {
        items: [],
    }

    async componentDidMount() {
        const { itemAssignmentCode } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || 0;
        this.getItems(itemAssignmentCode)
    }

    componentDidUpdate(prevProps) {
        const { itemAssignmentCode: prevItemAssignmentCode } = qs.parse(prevProps.location.search, { ignoreQueryPrefix: true }) || 0;
        const { itemAssignmentCode } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || 0;

        if(itemAssignmentCode !== prevItemAssignmentCode) {
            this.getItems(itemAssignmentCode);
        }
    }

    getItems = async(itemAssignmentCode) => {
        const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/item-assignment/${itemAssignmentCode}`);
        const data = await fetch(url).then(response => response.json());
        this.setState({ items: data });
    }

    handleLeadClick = (itemCode) => {
        this.props.history.push(`/item/${itemCode}`);
    }

    render() {
        const { classes } = this.props;
        const { items } = this.state;

        const someOtherJsx = (
            <div>some jsx</div>
        );

        return (
            <Layout pageTitle="Items">
                <CssBaseline />
                <Paper className={classes.root}>
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>#</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Stock Code</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Manufacturer</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Model</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Description</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>location</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Assignment</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Goods In</CustomTableCell>
                                <CustomTableCell align="center" />
                                <CustomTableCell align="center" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item, index) => {
                                return (
                                    <TableRow onClick={() => this.handleLeadClick(item.code)} className={classes.row} key={item.code}>
                                        <CustomTableCell className={classes.customTableCell} component="th" scope="row">{index+1}</CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="center">{item.code}</CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="left">{item.manufacturer}</CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="left">{item.modelNo}</CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="left">{item.shortDescription}</CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="left">{item.branch}</CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="left">{item.itemAssignmentDescription}</CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="left">{item.createdDate}</CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="center">{item.controlDeviceCode && <FontAwesomeIcon icon={faSimCard} color="green" className={classes.simCard}/>}</CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="left"><IconButton className={classes.button} aria-label="More info"><InfoIcon /></IconButton></CustomTableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Paper>
            </Layout>
        );
    }
}

export default withStyles(styles)(Items);
