const visitStatusLookUp = {
    CANCELLED: 'Cancelled',
    COMPLETE: 'Complete',
    SCHEDULED: 'Scheduled',
};

const paymentSourceLookUp = {
    HOLIDAYPAYMENT: 'HOLIDAY PAYMENT',
    INTERIMPAYMENT: 'INTERIM PAYMENT',
    LINK: 'LINK',
    MANUALADJUSTMENT: 'MANUAL ADJUSTMENT',
    MANUALPAYMENT: 'MANUAL PAYMENT',
    RECURRINGPAYMENT: 'RECURRING PAYMENT',
    REFUND: 'REFUND',
    RETRYPAYMENT: 'RETRY PAYMENT',
    REVERSAL: 'REVERSAL',
    VIRTUALTERMINAL: 'VIRTUAL TERMINAL',
};

export { visitStatusLookUp, paymentSourceLookUp };
