import React from 'react';
import {
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Error';
import CrossCircleIcon from '@material-ui/icons/Cancel';
import moment from 'moment-timezone';

const CustomTableCell = withStyles((theme) => ({
    body: {
        fontSize: 14,
        paddingBottom: 16,
        paddingTop: 16,
        verticalAlign: 'top',
        whiteSpace: 'nowrap',
    },
    head: {
        backgroundColor: theme.palette.primary.lighter,
        color: theme.palette.common.white,
    },
}))(TableCell);

const styles = (theme) => ({
    activeIcon: {
        color: '#4caf50',
    },
    alert: {
        color: '#f1180b',
    },
    hybridTextIconRight: {
        fontSize: 16,
        marginLeft: theme.spacing.unit * 1,
        transform: 'translate(0%, 13%)',
    },
    notSetIcon: {
        color: '#d7d7d7',
    },
    paper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        width: '100%',
    },
    progress: {
        opacity: '.5',
    },
    progressContainer: {
        marginBottom: theme.spacing.unit * 3,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        width: 60,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    statusColumn: {
        width: 110,
    },
    table: {
        border: '1px solid #e0e0e0',
    },
    tableContainer: {
        align: 'left',
        marginBottom: theme.spacing.unit * 3,
        width: '100%',
    },
    tableHead: {
        fontSize: 12,
        height: theme.spacing.unit * 5,
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        marginBottom: 4,
    },
    warningIcon: {
        color: '#fac518',
    },
});

class ContactVisitItemsToDeliver extends React.Component {
    state = {
    };

    statusIcon = (alertStatus, warningStatus, cancelledStatus) => {
        switch (true) {
        case (alertStatus): {
            return (<WarningIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.alert}`} />);
        }
        case (warningStatus): {
            return (<WarningIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.warningIcon}`} />);
        }
        case (cancelledStatus): {
            return (<CrossCircleIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.cancelledIcon}`} />);
        }
        default: {
            return (<CheckCircleIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.activeIcon}`} />);
        }
        }
    };

    render() {
        const {
            classes,
            scheduledDeliveryDateTime,
            visitItems,
        } = this.props;

        const now = moment.tz(moment(), 'Europe/London');
        const visitItemsToDeliver = visitItems.filter((visitItem) => visitItem.action === 'DELIVER');
        return (
            <div className={classes.tableContainer}>
                <div className={classes.textFieldLabel}>
                    Items to Deliver
                </div>
                {visitItemsToDeliver !== null
                    ? (
                        <>
                            {visitItemsToDeliver.length !== 0
                                ? (
                                    <>
                                        <Paper elevation={0}>
                                            <Table className={classes.table} padding="dense">
                                                <TableHead>
                                                    <TableRow className={classes.tableHead}>
                                                        <CustomTableCell align="left">Barcode</CustomTableCell>
                                                        <CustomTableCell align="left">Description</CustomTableCell>
                                                        <CustomTableCell align="left">Colour</CustomTableCell>
                                                        <CustomTableCell align="left">Type</CustomTableCell>
                                                        <CustomTableCell align="left">Components</CustomTableCell>
                                                        <CustomTableCell align="left">Reference</CustomTableCell>
                                                        <CustomTableCell align="left">Agreement</CustomTableCell>
                                                        <CustomTableCell align="left">Confirmed Status</CustomTableCell>
                                                        <CustomTableCell className={classes.statusColumn} align="center">Complete</CustomTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {visitItemsToDeliver.sort((a, b) => a.brandModelNo - b.brandModelNo).map((visitItem) => {
                                                        if (visitItem.agreementItem.code) {
                                                            return (
                                                                <TableRow className={classes.row} key={visitItem.code}>
                                                                    <CustomTableCell align="left">{(visitItem.agreementItem.item || {}).barcode}</CustomTableCell>
                                                                    <CustomTableCell align="left">{`${visitItem.agreementItem.product.manufacturer.name} ${visitItem.agreementItem.product.modelNo}`}</CustomTableCell>
                                                                    <CustomTableCell align="left">{visitItem.agreementItem.product.colour}</CustomTableCell>
                                                                    <CustomTableCell align="left">{visitItem.agreementItem.product.productType}</CustomTableCell>
                                                                    <CustomTableCell align="left">{visitItem.agreementItem.product.componentQty}</CustomTableCell>
                                                                    <CustomTableCell align="left">{visitItem.agreementItem.reference}</CustomTableCell>
                                                                    <CustomTableCell align="left">{`RA${visitItem.agreementItem.agreementCode}`}</CustomTableCell>
                                                                    <CustomTableCell align="left">{visitItem.status}</CustomTableCell>
                                                                    <CustomTableCell className={classes.statusColumn} align="center">
                                                                        {/* {this.statusIcon(
                                                                    visitItem.deliveryStatus === 0 && moment(scheduledDeliveryDateTime).isBefore(now),
                                                                    visitItem.deliveryStatus === 0 && moment(scheduledDeliveryDateTime).isAfter(now),
                                                                    false,
                                                                )} */}
                                                                    </CustomTableCell>
                                                                </TableRow>
                                                            );
                                                        }
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </Paper>
                                    </>
                                )
                                : (
                                    <Typography variant="body1">
                                        No visit items to deliver found
                                    </Typography>
                                )}
                        </>
                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )}
            </div>
        );
    }
}

export default withSnackbar(withStyles(styles)(ContactVisitItemsToDeliver));
