import React from 'react';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Menu,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import InfoIconFilled from '@material-ui/icons/Info';
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import TickIcon from '@material-ui/icons/Done';
import { withSnackbar } from 'notistack';
import HybridTextField from '../hybridTextField';
import titalise from '../../utilities/titalise';
import { paymentSourcesArray } from '../../constants/arrays';
import { paymentSourceLookUp } from '../../utilities/lookUpTables';
import commaFormattedDecimal from '../../utilities/commaFormattedDecimal';

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
}))(TableCell);

const styles = (theme) => ({
    label: {
        fontSize: 11,
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: 7,
        marginTop: theme.spacing.unit * 2,
        width: '100%',
        textAlign: 'left',
    },
    componentContainer: {
        width: '100%',
        marginTop: theme.spacing.unit * 1,
        marginBottom: theme.spacing.unit * 4,
    },

    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    divider: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    authorisedPaymentChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '102px',
    },
    declinedPaymentChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '102px',
    },
    chip: {
        height: 21,
        borderRadius: 5,
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    table: {
        // width: '50%',
    },
    menuIcon: {
        color: '#737373',
    },
    currentPurchaseOrderItem: {
        backgroundColor: '#c6edff !important',
    },
    dialogContent: {
        paddingBottom: 16,
        minWidth: 390,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '8px 24px',
        margin: '0px 0px 0px 0px !important',
    },
    bumpTop1U: {
        marginTop: theme.spacing.unit * 1,
    },
    bumpTop2U: {
        marginTop: theme.spacing.unit * 2,
    },
    bumpTop3U: {
        marginTop: theme.spacing.unit * 3,
    },
    bumpBottom1U: {
        marginBottom: theme.spacing.unit * 1,
    },
    bumpBottom2U: {
        marginBottom: theme.spacing.unit * 2,
    },
    bumpBottom3U: {
        marginBottom: theme.spacing.unit * 3,
    },
    refundIconContainer: {
        display: 'flex',
        width: 40,
        height: 21,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    refundIconLeft: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        width: '100%',
    },
    refundIconRight: {
        color: 'white',
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        width: '100%',
    },
    refundLetter: {
        textAlign: 'center',
        position: 'relative',
        left: 20,
        top: 2,
        color: '#fff',
    },
    backgroundPass: {
        backgroundColor: theme.palette.primary.testPass,
    },
    backgroundFail: {
        backgroundColor: theme.palette.primary.testFail,
    },
    backgroundDisabled: {
        backgroundColor: '#e0e0e0',
    },
    backgroundWarning: {
        backgroundColor: '#ffbf00',
    },
    dialogContentAlertText: {
        color: '#c80000',
        fontSize: 16,
        opacity: 0.54,
    },
    dialogContentTitle: {
        color: '#c80000',
        fontSize: 16,
        opacity: 0.54,
        fontWeight: 700,
    },
    summary: {
        fontWeight: 500,
    },
    alert: {
        color: theme.palette.primary.testFail,
    },
});

class PurchaseOrderItemsTable extends React.Component {
    state = {
        anchorEl: null,
        currentPurchaseOrderItem: {},
        disableRefundSubmitButton: false,
        disableReversalSubmitButton: false,
        errors: false,
        refundData: {},
        reversalData: {},
        showDetailDialog: false,
        showRefundDialog: false,
        showReversalDialog: false,
    };

    async componentDidMount() {
    }

    // handleReversalClick = () => {
    //     this.setState({
    //         reversalData: {
    //             reason: '',
    //         },
    //         showReversalDialog: true,
    //     });
    // };

    // handleCloseReversalDialogClick = () => {
    //     this.setState({
    //         disableReversalSubmitButton: false,
    //         showReversalDialog: false,
    //     });
    // };

    // updateReversalData = (event) => {
    //     const { name, value } = event.target;
    //     let formattedValue;
    //     switch (name) {
    //     default:
    //         formattedValue = value;
    //         break;
    //     }
    //     event.target.value = formattedValue;

    //     this.setState((state) => ({
    //         reversalData: {
    //             ...state.reversalData,
    //             [name]: formattedValue,
    //         },
    //     }));
    // };

    // handleReversalSubmitClick = () => {
    //     this.setState({
    //         disableReversalSubmitButton: true,
    //     });
    //     const agreementCode = this.props.payments[0].planReference;
    //     const { paymentID } = this.state.currentPurchaseOrderItem;
    //     const params = {
    //         ...this.state.reversalData,
    //         amount: parseFloat(this.state.reversalData.amount),
    //         paymentID,
    //     };
    //     this.props.reversePayment(agreementCode, params).then(() => {
    //         this.setState({
    //             disableReversalSubmitButton: false,
    //             showReversalDialog: false,
    //         });
    //         this.props.enqueueSnackbar('Payment Reversal Successful', { variant: 'success' });
    //     }).catch(() => {
    //         console.log(this.props.errors);
    //         this.setState({
    //             disableReversalSubmitButton: false,
    //         });
    //         this.props.enqueueSnackbar('Payment Reversal Failed', { variant: 'error' });
    //     });
    // };

    // handleRefundClick = () => {
    //     this.setState((state) => ({
    //         refundData: {
    //             amount: (state.currentPurchaseOrderItem.amount - state.currentPurchaseOrderItem.refundedAmount).toFixed(2),
    //             reason: '',
    //         },
    //         showRefundDialog: true,
    //     }));
    // };

    // handleCloseRefundDialogClick = () => {
    //     this.setState({
    //         disableRefundSubmitButton: false,
    //         showRefundDialog: false,
    //     });
    // };

    // updateRefundData = (event) => {
    //     const { name, value } = event.target;
    //     let formattedValue;
    //     switch (name) {
    //     case 'amount':
    //         formattedValue = value.replace(/[^0-9$.]/g, '');
    //         break;
    //     default:
    //         formattedValue = value;
    //         break;
    //     }
    //     event.target.value = formattedValue;

    //     this.setState((state) => ({
    //         refundData: {
    //             ...state.refundData,
    //             [name]: formattedValue,
    //         },
    //     }));
    // };

    // handleRefundSubmitClick = () => {
    //     this.setState({
    //         disableRefundSubmitButton: true,
    //     });
    //     const agreementCode = this.props.payments[0].planReference;
    //     const params = {
    //         ...this.state.refundData,
    //         contactCode: this.props.contact.code,
    //         refundPayments: [{
    //             amount: parseFloat(this.state.refundData.amount),
    //             paymentDateTime: this.state.currentPurchaseOrderItem.paymentDateTime,
    //             paymentID: this.state.currentPurchaseOrderItem.code,
    //             source: paymentSourceLookUp[this.state.currentPurchaseOrderItem.source],
    //         }],
    //     };
    //     this.props.refundPayments(agreementCode, params).then(() => {
    //         this.setState({
    //             disableRefundSubmitButton: false,
    //             showRefundDialog: false,
    //         });
    //         this.props.enqueueSnackbar('Payment Refund Successful', { variant: 'success' });
    //     }).catch(() => {
    //         console.log(this.props.errors);
    //         this.setState({
    //             disableRefundSubmitButton: false,
    //         });
    //         this.props.enqueueSnackbar('Payment Refund Failed', { variant: 'error' });
    //     });
    // };

    handleOpenMenu = (event) => {
        // const { anchorEl, id } = event.currentTarget;
        const anchorEl = event.currentTarget;
        const { id } = anchorEl;
        this.setState({
            anchorEl,
            currentPurchaseOrderItem: this.props.purchaseOrder.purchaseOrderItems.find((purchaseOrderItem) => purchaseOrderItem.code === parseInt(id, 10)),
        });
    };

    handleCloseMenu = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleDetailClick = (code) => {
        const currentPurchaseOrderItem = this.props.purchaseOrder.purchaseOrderItems.find((purchaseOrderItem) => purchaseOrderItem.code === code);
        this.setState({
            currentPurchaseOrderItem,
            showDetailDialog: true,
        });
    };

    handleCloseDetailDialogClick = () => {
        this.setState({
            showDetailDialog: false,
        });
    };

    render() {
        const {
            classes,
            purchaseOrder,
        } = this.props;
        const {
            anchorEl,
            currentPurchaseOrderItem,
            errors,
            showDetailDialog,
        } = this.state;

        const { purchaseOrderItems } = purchaseOrder;

        const nonNormalUnitTypeItemsOnPurchaseOrder = !!purchaseOrderItems.filter((item) => item.unitType !== 'NORMAL').length;
        console.log('>>> nonNormalUnitTypeItemsOnPurchaseOrder:', nonNormalUnitTypeItemsOnPurchaseOrder);

        return (
            <div className={classes.componentContainer}>
                { purchaseOrderItems.length === 0
                    ? (
                        <HybridTextField
                            alert
                            label="Payments"
                            text="None"
                            fieldStyle={{ style: 'alert', tooltip: 'No payments' }}
                        />
                    )
                    : (
                        <>
                            <div
                                className={classes.label}
                            >
                                Purchase Order Items
                            </div>
                            <Paper>
                                <Table className={classes.table} padding="dense">
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell>#</CustomTableCell>
                                            <CustomTableCell align="left">Manufacturer</CustomTableCell>
                                            <CustomTableCell align="left">Model No</CustomTableCell>
                                            <CustomTableCell align="left">Reference</CustomTableCell>
                                            <CustomTableCell align="right">Dimension</CustomTableCell>
                                            <CustomTableCell align="right">Unit Cost</CustomTableCell>
                                            <CustomTableCell align="right">Quantity</CustomTableCell>
                                            <CustomTableCell align="right">Total</CustomTableCell>
                                            <CustomTableCell align="center" />
                                            <CustomTableCell align="center" />
                                        </TableRow>
                                    </TableHead>
                                    {/* {purchaseOrderItems.sort((a, b) => a.paymentDate - b.paymentDate).reverse().map((payment, i) => { */}
                                    <TableBody>
                                        {purchaseOrder.purchaseOrderItems.map((purchaseOrderItem, i) => {
                                            const isFlooring = purchaseOrderItem.product.productType === 'Flooring';
                                            const isNonNormalUnitType = purchaseOrderItem.purchaseOrderUnitType !== 'NORMAL';
                                            const unit = isFlooring ? 'm' : 'mm';
                                            i++;
                                            return (
                                                <TableRow
                                                    className={`${classes.row} ${anchorEl && currentPurchaseOrderItem.code === purchaseOrderItem.code ? classes.currentPurchaseOrderItem : ''}`}
                                                    key={purchaseOrderItem.code}
                                                >
                                                    <CustomTableCell component="th" scope="row">{i}</CustomTableCell>
                                                    <CustomTableCell align="left">{purchaseOrderItem.product.manufacturer.name}</CustomTableCell>
                                                    <CustomTableCell align="left">{purchaseOrderItem.product.modelNo}</CustomTableCell>
                                                    <CustomTableCell align="left">{purchaseOrderItem.reference}</CustomTableCell>
                                                    <CustomTableCell align="right" className={`${classes.customTableCell} ${classes.right}`} component="th" scope="row">
                                                        <>
                                                            {isFlooring
                                                                ? (
                                                                    <>
                                                                        {`${purchaseOrderItem.width}${unit}${isFlooring && isNonNormalUnitType ? '' : ` x ${purchaseOrderItem.length}${unit}`}`}
                                                                    </>
                                                                )
                                                                : (
                                                                    <>
                                                                        ''
                                                                    </>
                                                                )}
                                                        </>
                                                    </CustomTableCell>
                                                    <CustomTableCell align="right">
                                                        £
                                                        {(purchaseOrderItem.costPrice).toFixed(2)}
                                                    </CustomTableCell>
                                                    <CustomTableCell align="right">
                                                        {`${purchaseOrderItem.quantity}${isNonNormalUnitType ? ` x ${purchaseOrderItem.purchaseOrderUnitType}` : ''}`}
                                                    </CustomTableCell>
                                                    <CustomTableCell align="right">
                                                        {isNonNormalUnitType
                                                            ? (
                                                                <>
                                                                    n/a
                                                                </>
                                                            )
                                                            : (
                                                                <>
                                                                    £
                                                                    {(purchaseOrderItem.quantity * purchaseOrderItem.costPrice).toFixed(2)}
                                                                </>
                                                            )}
                                                    </CustomTableCell>

                                                    <CustomTableCell align="right">
                                                        <IconButton id={purchaseOrderItem.code} onClick={() => this.handleDetailClick(purchaseOrderItem.code)} className={classes.button}>
                                                            {purchaseOrderItem.product.recordComplete
                                                                ? (
                                                                    <InfoIcon className={classes.menuIcon} />
                                                                )
                                                                : (
                                                                    <InfoIconFilled className={classNames(classes.menuIcon, classes.alert)} />
                                                                )}
                                                        </IconButton>
                                                    </CustomTableCell>
                                                    <CustomTableCell align="right">
                                                        <IconButton id={purchaseOrderItem.code} onClick={this.handleOpenMenu.bind(this)} className={classes.button}>
                                                            <MoreVertIcon className={classes.menuIcon} />
                                                        </IconButton>
                                                    </CustomTableCell>
                                                </TableRow>
                                            );
                                        })}
                                        <TableRow
                                            className={classes.row}
                                            key="TOTAL_NET"
                                        >
                                            <CustomTableCell />
                                            <CustomTableCell>&nbsp;</CustomTableCell>
                                            <CustomTableCell>&nbsp;</CustomTableCell>
                                            <CustomTableCell className={classes.summary} colSpan="4" align="right">TOTAL NET</CustomTableCell>
                                            <CustomTableCell className={classes.summary} align="right">
                                                {nonNormalUnitTypeItemsOnPurchaseOrder
                                                    ? (
                                                        <>
                                                            n/a
                                                        </>
                                                    )
                                                    : (
                                                        <>
                                                            £
                                                            {commaFormattedDecimal((purchaseOrder.totalNet).toFixed(2))}
                                                        </>
                                                    )}
                                            </CustomTableCell>
                                            <CustomTableCell>&nbsp;</CustomTableCell>
                                            <CustomTableCell>&nbsp;</CustomTableCell>
                                        </TableRow>

                                        <TableRow
                                            className={classes.row}
                                            key="DELIVERY_FEE"
                                        >
                                            <CustomTableCell>&nbsp;</CustomTableCell>
                                            <CustomTableCell>&nbsp;</CustomTableCell>
                                            <CustomTableCell>&nbsp;</CustomTableCell>
                                            <CustomTableCell className={classes.summary} colSpan="4" align="right">DELIVERY</CustomTableCell>
                                            <CustomTableCell className={classes.summary} align="right">
                                                {nonNormalUnitTypeItemsOnPurchaseOrder
                                                    ? (
                                                        <>
                                                            n/a
                                                        </>
                                                    )
                                                    : (
                                                        <>
                                                            £
                                                            {commaFormattedDecimal((purchaseOrder.deliveryFee).toFixed(2))}
                                                        </>
                                                    )}
                                            </CustomTableCell>
                                            <CustomTableCell>&nbsp;</CustomTableCell>
                                            <CustomTableCell>&nbsp;</CustomTableCell>
                                        </TableRow>

                                        <TableRow
                                            className={classes.row}
                                            key="VAT"
                                        >
                                            <CustomTableCell>&nbsp;</CustomTableCell>
                                            <CustomTableCell>&nbsp;</CustomTableCell>
                                            <CustomTableCell>&nbsp;</CustomTableCell>
                                            <CustomTableCell className={classes.summary} colSpan="4" align="right">VAT</CustomTableCell>
                                            <CustomTableCell className={classes.summary} align="right">
                                                {nonNormalUnitTypeItemsOnPurchaseOrder
                                                    ? (
                                                        <>
                                                            n/a
                                                        </>
                                                    )
                                                    : (
                                                        <>
                                                            £
                                                            {commaFormattedDecimal((purchaseOrder.vat).toFixed(2))}
                                                        </>
                                                    )}
                                            </CustomTableCell>
                                            <CustomTableCell>&nbsp;</CustomTableCell>
                                            <CustomTableCell>&nbsp;</CustomTableCell>
                                        </TableRow>

                                        <TableRow
                                            className={classes.row}
                                            key="TOTAL"
                                        >
                                            <CustomTableCell>&nbsp;</CustomTableCell>
                                            <CustomTableCell>&nbsp;</CustomTableCell>
                                            <CustomTableCell>&nbsp;</CustomTableCell>
                                            <CustomTableCell className={classes.summary} colSpan="4" align="right">TOTAL</CustomTableCell>
                                            <CustomTableCell className={classes.summary} align="right">
                                                {nonNormalUnitTypeItemsOnPurchaseOrder
                                                    ? (
                                                        <>
                                                            n/a
                                                        </>
                                                    )
                                                    : (
                                                        <>
                                                            £
                                                            {commaFormattedDecimal((purchaseOrder.total).toFixed(2))}
                                                        </>
                                                    )}
                                            </CustomTableCell>
                                            <CustomTableCell>&nbsp;</CustomTableCell>
                                            <CustomTableCell>&nbsp;</CustomTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </>
                    )}

                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={this.handleCloseMenu}
                    classes={{ paper: classes.menu }}
                    disableAutoFocusItem
                >
                    {/* <Divider className={classes.dialogDivider} /> */}
                    <MenuItem
                        disabled={false}
                        key="editPurchaseOrderItem"
                        onClick={() => { this.handleCloseMenu(); this.handleRefundClick(); }}
                    >
                        Edit Purchase Order Item
                    </MenuItem>
                    <MenuItem
                        disabled={false}
                        key="deletePurchaseOrderItem"
                        onClick={() => { this.handleCloseMenu(); this.handleRefundClick(); }}
                    >
                        Remove from Purchase Order
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        disabled={(currentPurchaseOrderItem.product || {}).recordComplete}
                        key="chargebackPayment"
                        onClick={() => { this.handleCloseMenu(); this.handleReversalClick(); }}
                    >
                        Edit Item
                    </MenuItem>
                </Menu>

                {!isEmpty(currentPurchaseOrderItem)
                    ? (
                        <>
                            <Dialog
                                open={showDetailDialog}
                                onClose={this.handleCloseDetailDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <Typography variant="h6" gutterBottom>
                                            Purchase Order Item Detail
                                        </Typography>
                                        {/* {currentPayment.reversalPaymentID
                                            ? (
                                                <Typography className={classNames(
                                                    classes.bumpBottom2U,
                                                    classes.dialogContentAlertText,
                                                )}
                                                >
                                                    This payment has been reversed by paymentID
                                                    {' '}
                                                    {currentPayment.reversalPaymentID}
                                                    .
                                                </Typography>
                                            )
                                            : ''}
                                        <Grid
                                            container
                                            spacing={0}
                                            className={classNames(
                                                classes.sectionContainer,
                                                classes.bumpTop3U,
                                            )}
                                        >
                                            <Grid
                                                container
                                                spacing={0}
                                            >

                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Type"
                                                        text={paymentSourceLookUp[currentPayment.source]}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Payment Reference"
                                                        text={currentPayment.paymentReference}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Parent ID"
                                                        text={currentPayment.source === 'REFUND'
                                                            || currentPayment.source === 'REVERSAL'
                                                            ? currentPayment.parentID
                                                            : 'n/a'}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Created By"
                                                        text={currentPayment.createdByContact}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Reason"
                                                        text={currentPayment.reason}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Notes"
                                                        text={currentPayment.notes}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Refunded Amount"
                                                        text={!['MANUALADJUSTMENT', 'REVERSAL', 'REFUND'].includes(currentPayment.source)
                                                        && !currentPayment.reversalPaymentID
                                                            ? `£${currentPayment.refundedAmount.toFixed(2)}  (${(currentPayment.refundedAmount / currentPayment.amount) * 100}%)`
                                                            : 'n/a'}
                                                        alert={currentPayment.refundedAmount > 0}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid> */}

                                    </div>
                                </DialogContent>
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleCloseDetailDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    )
                    : ''}
            </div>
        );
    }
}

export default withSnackbar(withStyles(styles)(PurchaseOrderItemsTable));
