import React from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import HybridTextField from './hybridTextField';
import titalise from '../utilities/titalise';
import {
    titles,
    counties,
    countries,
} from '../utilities/menuOptions';
import Tile from '../containers/tile';

const styles = theme => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    tileBottomDivider: {
        marginTop: theme.spacing.unit * 3,
        // marginBottom: theme.spacing.unit * 2,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 2.5,
        marginBottom: theme.spacing.unit * 2,
    },
    bottomBarContainer: {
        width: '100%',
        textAlign: 'right',
    },
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    nameField: {
        width: 350,
    },
    addressField: {
        width: 250,
    },
    postcodeField: {
        width: 120,
    },
    countryField: {
        width: 200,
        marginLeft: theme.spacing.unit * 3,
    },
    emailField: {
        width: 350,
    },
    rightOfFieldButton: {
        marginTop: 10,
        marginLeft: theme.spacing.unit * 3,
        color: 'rgba(0, 121, 255, 0.87)',
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
    },
    dialogActionsDivider: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    formHelperText: {
        color: '#f44336',
    },
    contactLocationIcon: {
        color: 'red',
        fontSize: 30,
        position: 'absolute',
        transform: 'translate(-50%, -100%)',
    },
    branchLocationIcon: {
        color: 'blue',
        fontSize: 30,
        position: 'absolute',
        transform: 'translate(-50%, -100%)',
    },
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    googleMapsNotFound: {
        fontSize: 16,
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.87)',
        minHeight: 19,
    },
});

class CustomerDetail extends React.Component {
    state = {
        showCustomerDetailsEditDialog: false,
        showCustomerAddressEditDialog: false,
        disableEditCustomerDetailsSaveButton: false,
        disableEditCustomerAddressSaveButton: false,
        showSendSMSDialog: false,
        disableSendSMSButton: false,
        smsForm: {
            message: '',
        },
        customerDetailsForm: {
            title: '',
            firstname: '',
            surname: '',
            homeTelephoneNumber: '',
            mobileTelephoneNumber: '',
            emailAddress: '',
            gdpr: '',
        },
        customerAddressForm: {
            newAddress: 0,
            address1: '',
            address2: '',
            townCity: '',
            county: '',
            postcode: '',
            country: '',
        },
    }

    componentDidMount() {
        this.setupFormData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.contact !== prevProps.contact) {
            this.setupFormData();
        }
    }

    setupFormData() {
        if (this.props.contact) {
            this.setState(state => ({
                customerDetailsForm: {
                    ...state.customerDetailsForm,
                    title: this.props.contact.title,
                    firstname: this.props.contact.firstname,
                    surname: this.props.contact.surname,
                    homeTelephoneNumber: this.props.contact.homeTelephoneNumber,
                    mobileTelephoneNumber: this.props.contact.mobileTelephoneNumber,
                    emailAddress: this.props.contact.emailAddress,
                    gdpr: this.props.contact.gdpr.toString(),
                },
                customerAddressForm: {
                    ...state.customerAddressForm,
                    newAddress: '0',
                    address1: this.props.contactAddress.address1,
                    address2: this.props.contactAddress.address2,
                    townCity: this.props.contactAddress.townCity,
                    county: this.props.contactAddress.county,
                    postcode: this.props.contactAddress.postcode,
                    country: this.props.contactAddress.country,
                },
            }));
        }
    }

    handleCustomerDetailsEditClick = () => {
        this.setState({
            showCustomerDetailsEditDialog: true,
        });
    }

    handleCustomerAddressEditClick = () => {
        this.setState({
            showCustomerAddressEditDialog: true,
        });
    }

    handleCustomerDetailsCloseDialogClick = () => {
        this.setState({
            showCustomerDetailsEditDialog: false,
        });
    }

    handleCustomerAddressCloseDialogClick = () => {
        this.setState({
            showCustomerAddressEditDialog: false,
        });
    }

    handleSendSMSClick = () => {
        this.setState({
            showSendSMSDialog: true,
        });
    }

    handleSendSMSCloseDialogClick = () => {
        this.setState({
            showSendSMSDialog: false,
        });
    }

    handleEditCustomerDetailsCheckBoxClick = name => (event) => {
        const newValue = this.state.customerDetailsForm[name] === '1' ? '0' : '1';
        console.log(this.state.customerDetailsForm[name], newValue);
        this.setState(state => ({
            customerDetailsForm: {
                ...state.customerDetailsForm,
                [name]: newValue,
            },
        }));
    }

    updateEditCustomerDetailsData = (event) => {
        const { value, name } = event.target;

        let formattedValue;
        switch (name) {
        case 'emailAddress':
            formattedValue = value.toLowerCase();
            break;
        default:
            formattedValue = titalise(value);
            break;
        }
        event.target.value = formattedValue;

        this.setState(state => ({
            customerDetailsForm: {
                ...state.customerDetailsForm,
                [name]: formattedValue,
            },
        }));

        // console.log({
        //     customerForm: {
        //         ...this.state.customerForm,
        //         [name]: formattedValue,
        //     },
        // })
        // console.log(formattedValue, name);
        // console.log(this.state.customerForm);
    }

    updateEditCustomerAddressData = (event) => {
        const { value, name } = event.target;
        let formattedValue;
        let customerAddressFormValues = { ...this.state.customerAddressForm };
        switch (name) {
        case 'postcode':
            formattedValue = value.toUpperCase();
            break;
        case 'county':
        case 'country':
            formattedValue = value;
            break;
        default:
            formattedValue = titalise(value);
            break;
        }
        event.target.value = formattedValue;

        if (name === 'newAddress' && value === '1') {
            Object.keys(customerAddressFormValues).forEach(k => customerAddressFormValues[k] = '');
        } else if (name === 'newAddress' && value === '0') {
            customerAddressFormValues = {
                address1: this.props.customer.address1,
                address2: this.props.customer.address2,
                townCity: this.props.customer.townCity,
                county: this.props.customer.county,
                postcode: this.props.customer.postcode,
                country: this.props.customer.country,
            };
        }

        this.setState({
            customerAddressForm: {
                ...customerAddressFormValues,
                [name]: formattedValue,
            },
        });

        // this.setState(state => ({
        //     customerAddressForm: {
        //         ...state.customerAddressForm,
        //         [name]: formattedValue,
        //     },
        // }));

        // console.log({
        //     customerForm: {
        //         ...this.state.customerForm,
        //         [name]: formattedValue,
        //     },
        // })
        // console.log(formattedValue, name);
        // console.log(this.state.customerForm);
    }

    handleSaveCustomerDetails = (event) => {
        event.preventDefault();
        this.setState({
            disableEditCustomerDetailsSaveButton: true,
        });
        this.props.saveCustomerDetails(this.state.customerDetailsForm).then(() => {
            this.setState({
                showCustomerDetailsEditDialog: false,
                disableEditCustomerDetailsSaveButton: false,
            });
            this.props.enqueueSnackbar('Customer details saved', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Customer details save failed', { variant: 'error' });
            this.setState({
                disableEditCustomerDetailsSaveButton: false,
            });
        });
    }

    handleSaveCustomerAddress = (event) => {
        event.preventDefault();
        this.setState({
            disableEditCustomerAddressSaveButton: true,
        });
        this.props.saveCustomerAddress(this.state.customerAddressForm).then(() => {
            this.setState({
                showCustomerAddressEditDialog: false,
                disableEditCustomerAddressSaveButton: false,
            });
            this.props.enqueueSnackbar('Customer Address saved', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Customer Address save failed', { variant: 'error' });
            this.setState({
                disableEditCustomerAddressSaveButton: false,
            });
        });
    }

    updateSendSMSData = (event) => {
        const { value, name } = event.target;
        const wrappedMessage = `PAY WEEKLY: ${value}. Do not reply.`;
        this.setState({
            smsForm: {
                ...this.state.smsForm,
                message: wrappedMessage,
            },
        });

        // console.log({
        //     smsForm: {
        //         ...this.state.smsForm,
        //         [name]: value,
        //     },
        // })
    }

    handleSendSMS = (event) => {
        event.preventDefault();
        this.setState({
            disableSendSMSButton: true,
        });
        this.props.sendSMS(this.state.smsForm).then(() => {
            this.setState({
                showSendSMSDialog: false,
                disableSendSMSButton: false,
            });
            this.props.enqueueSnackbar('SMS message sent', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('SMS message failed', { variant: 'error' });
            this.setState({
                disableSendSMSButton: false,
            });
        });
    }


    render() {
        const {
            classes,
            contact,
            contactAddress,
            branches,
            errors,
        } = this.props;
        const {
            customerDetailsForm,
            customerAddressForm,
            showCustomerDetailsEditDialog,
            showCustomerAddressEditDialog,
            disableEditCustomerDetailsSaveButton,
            disableEditCustomerAddressSaveButton,
            showSendSMSDialog,
            disableSendSMSButton,
        } = this.state;

        return (
            <Tile
                tileTitle="Customer Details"
                tileMenuItems={[
                    {
                        label: 'Edit Detail',
                        action: this.handleCustomerDetailsEditClick,
                        section: 1,
                    },
                    {
                        label: 'Edit Address',
                        action: this.handleCustomerAddressEditClick,
                        section: 1,
                    },
                    {
                        label: 'Send SMS',
                        action: this.handleSendSMSClick,
                        section: 2,
                    },
                    {
                        label: 'Send Email',
                        action: this.handleSendSMSClick,
                        section: 2,
                        disabled: true,
                    },
                ]}
                style
            >
                { contact !== null && contactAddress !== null && branches !== null
                    ? (
                        <React.Fragment>
                            <Grid container spacing={0}>
                                <Grid item xs={4}>
                                    <Grid item xs={12}>
                                        <HybridTextField label="Name" text={contact.fullName || ' '} />

                                    </Grid>
                                    <Grid item xs={12}>
                                        <HybridTextField label="Address" text={[contactAddress.address1, contactAddress.address2, contactAddress.townCity, contactAddress.county].filter(e => e === 0 || e).join('\n')} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={4}>
                                                <HybridTextField label="Postcode" text={contactAddress.postcode} />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <HybridTextField label="Country" text={contactAddress.country} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <HybridTextField label="GDPR Permission" text={(contact.gdpr || ' ') === 1 ? 'Yes' : 'No'} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={12}>
                                        <HybridTextField label="Email" text={contact.emailAddress} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <HybridTextField label="Home Telephone" text={contact.homeTelephoneNumber} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <HybridTextField label="Mobile Telephone" text={contact.mobileTelephoneNumber} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Paper style={{ height: '100%', width: '100%' }}>
                                        {contactAddress.lat && contactAddress.lng
                                            ? (
                                                <GoogleMapReact
                                                    bootstrapURLKeys={
                                                        {
                                                            key: 'AIzaSyA6ab1HSjU6dWSWQGevVUZrq61ynXzTujk',
                                                        }
                                                    }
                                                    center={{
                                                        lat: parseFloat(contactAddress.lat),
                                                        lng: parseFloat(contactAddress.lng),
                                                    }}
                                                    defaultZoom={11}
                                                >
                                                    <React.Fragment>
                                                        <LocationOnIcon
                                                            className={classes.contactLocationIcon}
                                                            lat={contactAddress.lat}
                                                            lng={contactAddress.lng}
                                                        />
                                                    </React.Fragment>
                                                    {branches.map((branch) => {
                                                        if (branch.code > 1) {
                                                            return (
                                                                <React.Fragment key={branch.code}>
                                                                    <LocationOnIcon
                                                                        key={branch.name}
                                                                        className={classes.branchLocationIcon}
                                                                        lat={branch.lat}
                                                                        lng={branch.lng}
                                                                    />
                                                                </React.Fragment>
                                                            );
                                                        }
                                                    })}
                                                </GoogleMapReact>
                                            ) : (
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    direction="column"
                                                    alignItems="center"
                                                    justify="center"
                                                    style={{ minHeight: 230 }}
                                                >
                                                    <Grid item xs={12}>
                                                        <div className={classes.googleMapsNotFound}>
                                                            Location not found
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>

                            <Dialog
                                open={showCustomerDetailsEditDialog}
                                onClose={this.handleCustomerDetailsCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <React.Fragment>
                                            <Typography variant="h6" gutterBottom>
                                                Edit Customer Details
                                            </Typography>
                                            <form onSubmit={this.handleSaveCustomerDetails}>

                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <FormControl
                                                            className={classes.menuFormControl}
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.title}
                                                        >
                                                            <InputLabel htmlFor="title" className={classes.inputLabel}>Title</InputLabel>
                                                            <Select
                                                                keyboard="true"
                                                                input={(
                                                                    <OutlinedInput
                                                                        onChange={this.updateEditCustomerDetailsData}
                                                                        name="title"
                                                                        id="title"
                                                                    />
                                                                )}
                                                                value={customerDetailsForm.title || ''}
                                                            >
                                                                <MenuItem value=""><em>None</em></MenuItem>
                                                                {titles.map((title, i) => (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={title}
                                                                    >
                                                                        {title}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {errors.title && (
                                                                <FormHelperText>{errors.title}</FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="firstname"
                                                            id="firstname"
                                                            onChange={this.updateEditCustomerDetailsData}
                                                            value={customerDetailsForm.firstname}
                                                            label="Firstname"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.firstname}
                                                            helperText={errors.firstname}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="surname"
                                                            id="surname"
                                                            onChange={this.updateEditCustomerDetailsData}
                                                            value={customerDetailsForm.surname}
                                                            label="Surname"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.surname}
                                                            helperText={errors.surname}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Divider className={classes.dialogDivider} />
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="emailAddress"
                                                            id="emailAddress"
                                                            value={customerDetailsForm.emailAddress}
                                                            onChange={this.updateEditCustomerDetailsData}
                                                            label="Email Address"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.emailAddress}
                                                            helperText={errors.emailAddress}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="homeTelephoneNumber"
                                                            id="homeTelephoneNumber"
                                                            value={customerDetailsForm.homeTelephoneNumber}
                                                            onChange={this.updateEditCustomerDetailsData}
                                                            label="Home Telephone"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="mobileTelephoneNumber"
                                                            id="mobileTelephoneNumber"
                                                            value={customerDetailsForm.mobileTelephoneNumber}
                                                            onChange={this.updateEditCustomerDetailsData}
                                                            label="Mobile Telephone"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.mobileTelephoneNumber}
                                                            helperText={errors.mobileTelephoneNumber}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControlLabel
                                                            control={(
                                                                <Checkbox
                                                                    name="gdpr"
                                                                    id="gdpr"
                                                                    checked={customerDetailsForm.gdpr === '1'}
                                                                    onChange={this.handleEditCustomerDetailsCheckBoxClick('gdpr')}
                                                                    value="1"
                                                                />
                                                            )}
                                                            label="GDPR Permission"
                                                        />

                                                    </Grid>
                                                </Grid>
                                                <Button
                                                    variant="contained"
                                                    className={classes.dialogButton}
                                                    type="submit"
                                                    disabled={disableEditCustomerDetailsSaveButton}
                                                >
                                                    SAVE
                                                </Button>
                                            </form>
                                        </React.Fragment>
                                    </div>
                                </DialogContent>
                                <Divider className={classes.dialogActionsDivider} />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleCustomerDetailsCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showCustomerAddressEditDialog}
                                onClose={this.handleCustomerAddressCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <React.Fragment>
                                            <Typography variant="h6" gutterBottom>
                                                Edit Customer Address
                                            </Typography>
                                            <form onSubmit={this.handleSaveCustomerAddress}>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <FormControl component="fieldset" className={classes.formControl}>
                                                            <FormLabel component="legend">Address change type</FormLabel>
                                                            <RadioGroup
                                                                aria-label="address change type"
                                                                name="newAddress"
                                                                value={customerAddressForm.newAddress}
                                                                onChange={this.updateEditCustomerAddressData}
                                                            >
                                                                <FormControlLabel
                                                                    value="0"
                                                                    control={<Radio color="primary" />}
                                                                    label="Edit Address"
                                                                    labelPlacement="end"
                                                                />
                                                                <FormControlLabel
                                                                    value="1"
                                                                    control={<Radio color="primary" />}
                                                                    label="New Address"
                                                                    labelPlacement="end"
                                                                />
                                                            </RadioGroup>
                                                            <FormHelperText>Please ensure you only select NEW where address has changed and EDIT where correcting an address error.</FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="address1"
                                                            id="address1"
                                                            value={customerAddressForm.address1}
                                                            onChange={this.updateEditCustomerAddressData}
                                                            label="Address"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.address1}
                                                            helperText={errors.address1}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="address2"
                                                            id="address2"
                                                            value={customerAddressForm.address2}
                                                            onChange={this.updateEditCustomerAddressData}
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="townCity"
                                                            id="townCity"
                                                            value={customerAddressForm.townCity}
                                                            onChange={this.updateEditCustomerAddressData}
                                                            label="Town/City"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.townCity}
                                                            helperText={errors.townCity}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl
                                                            className={classes.menuFormControl}
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.title}
                                                        >
                                                            <InputLabel htmlFor="county" className={classes.inputLabel}>County</InputLabel>
                                                            <Select
                                                                keyboard="true"
                                                                input={(
                                                                    <OutlinedInput
                                                                        onChange={this.updateEditCustomerAddressData}
                                                                        name="county"
                                                                        id="county"
                                                                    />
                                                                )}
                                                                value={customerAddressForm.county || ''}
                                                            >
                                                                <MenuItem value=""><em>None</em></MenuItem>
                                                                {counties.map((county, i) => (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={county}
                                                                    >
                                                                        {county}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {errors.county && (
                                                                <FormHelperText>{errors.county}</FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="postcode"
                                                            id="postcode"
                                                            value={customerAddressForm.postcode}
                                                            onChange={this.updateEditCustomerAddressData}
                                                            label="Postcode"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.postcode}
                                                            helperText={errors.postcode}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl
                                                            className={classes.menuFormControl}
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.country}
                                                        >
                                                            <InputLabel htmlFor="country" className={classes.inputLabel}>Country</InputLabel>
                                                            <Select
                                                                keyboard="true"
                                                                input={(
                                                                    <OutlinedInput
                                                                        onChange={this.updateEditCustomerAddressData}
                                                                        name="country"
                                                                        id="country"
                                                                    />
                                                                )}
                                                                value={customerAddressForm.country || ''}
                                                            >
                                                                <MenuItem value=""><em>None</em></MenuItem>
                                                                {countries.map((country, i) => (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={country}
                                                                    >
                                                                        {country}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {errors.country && (
                                                                <FormHelperText>{errors.country}</FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Button
                                                    variant="contained"
                                                    className={classes.dialogButton}
                                                    type="submit"
                                                    disabled={disableEditCustomerAddressSaveButton}
                                                >
                                                    SAVE
                                                </Button>
                                            </form>
                                        </React.Fragment>
                                    </div>
                                </DialogContent>
                                <Divider className={classes.dialogActionsDivider} />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleCustomerAddressCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showSendSMSDialog}
                                onClose={this.handleSendSMSCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <React.Fragment>
                                            <Typography variant="h6" gutterBottom>
                                                Send SMS Message
                                            </Typography>
                                            <DialogContentText>
                                                    Enter the message text below and click [SEND]. Message will automatically start "PAY WEEKLY:" and end ". Do not reply"
                                            </DialogContentText>
                                            <form onSubmit={this.handleSendSMS}>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            onChange={this.updateSendSMSData}
                                                            className={classes.formControl}
                                                            name="message"
                                                            id="message"
                                                            label="SMS Message"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            multiline
                                                            rows="6"
                                                        /* {error={!!errors.rentalAgreementCode}} */
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Button
                                                    variant="contained"
                                                    className={classes.dialogButton}
                                                    type="submit"
                                                    disabled={disableSendSMSButton}
                                                >
                                                    SEND
                                                </Button>
                                            </form>
                                        </React.Fragment>
                                    </div>
                                    <Divider />
                                </DialogContent>
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleSendSMSCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </React.Fragment>
                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )
                }
            </Tile>
        );
    }
}

const formShape = {
    title: PropTypes.string,
    firstname: PropTypes.string,
    surname: PropTypes.string,
    fullName: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    townCity: PropTypes.string,
    county: PropTypes.string,
    postcode: PropTypes.string,
    country: PropTypes.string,
    homeTelephoneNumber: PropTypes.string,
    mobileTelephoneNumber: PropTypes.string,
    emailAddress: PropTypes.string,
    gdpr: PropTypes.number,
};

CustomerDetail.propTypes = {
    classes: PropTypes.object.isRequired,
    sendSMS: PropTypes.func.isRequired,
    saveCustomerDetails: PropTypes.func.isRequired,
    customer: PropTypes.shape(formShape).isRequired,

    errors: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(CustomerDetail));
