import React from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

import fetch from '../utilities/fetch';
import sleep from '../utilities/sleep';

const removeFalseyValuesFromObject = obj => pickBy(obj, identity);

const styles = theme => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    divider: {
        marginTop: theme.spacing.unit * 2,
        // marginBottom: theme.spacing.unit * 2,
    },
    bodyContainer: {
        minHeight: 100,
    },
    bottomBarContainer: {
        width: '100%',
        textAlign: 'right',
    },
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    dialogContentContainer: {
        // marginTop: theme.spacing.unit * 2,
        // marginBottom: theme.spacing.unit * 2,
        minWidth: 600,
        minHeight: 269,
        paddingBottom: 0,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
        marginRight: theme.spacing.unit * 2,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 2,
        // marginBottom: theme.spacing.unit * 2,
    },
    dialogActions: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
    },
    progress: {
        margin: theme.spacing.unit * 3,
    },
});

class AgreementDetail extends React.Component {
    state = {
        agreement: {},
    }

    componentDidMount() {
        this.fetchAgreementDetailData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.rentalAgreementCode !== prevProps.rentalAgreementCode) {
            this.fetchAgreementDetailData();
        }
    }

    async fetchAgreementDetailData() {
        if (this.props.rentalAgreementCode) {
            const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/agreement/${this.props.rentalAgreementCode}`);
            const data = await fetch(url).then(response => response.json());
            this.setState({ agreement: data });
        }
    }

    render() {
        const { classes, rentalAgreementCode } = this.props;
        const {
            agreement,
        } = this.state;

        // const step1 = (
        // );

        return (
            <React.Fragment>
                {rentalAgreementCode
                    ? (
                        <div className={classes.bodyContainer}>
                            <Typography>
                            Status:
                                {' '}
                                {agreement.Status}
                                <br />
                                Delivery Status:
                                    {' '}
                                    {agreement.deliveryStatus}
                                    <br />
                                    Total Deposit:
                                    {' '}
                                    {agreement.totalDeposit}
                                    <br />
                                    Arrears:
                                    {' '}
                                    {agreement.arrears}
                                    <br />
                                    First Payment:
                                    {' '}
                                    {agreement.portalFirstPaymentAmount}
                                    <br />
                                    Recurring Amount:
                                    {' '}
                                    {agreement.portalRecurringAmount}
                                    <br />
                                    Total Paid:
                                    {' '}
                                    {agreement.portalPaidAmount}
                                    <br />
                                    Next Payment Date:
                                    {' '}
                                    {agreement.portalNextPaymentDate}
                                    <br />
                                    Next Payment Day of Week:
                                    {' '}
                                    {agreement.nextPaymentDayOfWeek}
                                    <br />
                                    Start Date:
                                    {' '}
                                    {agreement.portalStartDate}
                                    <br />
                                    Card:
                                    {' '}
                                    {agreement.Card}
                                    <br />
                                    Card Number:
                                    {' '}
                                    {agreement.CardNo}
                                    <br />
                                    Card Expiry:
                                    {' '}
                                    {agreement.CardExpiry}
                                    <br />
                                    Schedule Frequency:
                                    {' '}
                                    {agreement.ScheduleFrequency}
                                    <br />
                                    Schedule Interval:
                                    {' '}
                                    {agreement.ScheduleInterval}
                                    <br />
                                    Schedule Day:
                                    {' '}
                                    {agreement.ScheduleDay}
                                    <br />
                                    On Decline:
                                    {' '}
                                    {agreement.OnPaymentDeclined}
                                    <br />
                            </Typography>
                        </div>
                    )
                    : (
                        <React.Fragment>
                            <div className={classes.bodyContainer}>
                                <Typography>
                                No Agreement Found.
                                </Typography>
                            </div>
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(AgreementDetail);
