import React from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import moment from 'moment-timezone';
import GoogleMapReact from 'google-map-react';
import Tile from '../../containers/tile';
import HybridTextField from '../hybridTextField';
import { historyEntryTypesArray } from '../../constants/arrays';

const stylesx = require('../../constants/googleMapsStyle.json');

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        marginBottom: theme.spacing.unit * 3,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    authorisedPaymentChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '87px',
    },
    declinedPaymentChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '87px',
    },
    unknownPaymentChip: {
        minWidth: '87px',
    },
    chip: {
        height: 21,
        borderRadius: 5,
    },
    paper: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 6,
    },
    graph: {
        width: 800,
    },
});

class HeatMap extends React.Component {
    state = {
    };

    render() {
        const {
            classes,
            reportData,
            reportDataLoading,
        } = this.props;

        const {
        } = this.state;

        return (
            <>
                {!reportDataLoading && (
                    <GoogleMapReact
                        bootstrapURLKeys={
                            {
                                key: 'AIzaSyA6ab1HSjU6dWSWQGevVUZrq61ynXzTujk',
                            }
                        }
                        defaultCenter={{
                            lat: 54.00,
                            lng: -4.00,
                            // lat: 54.66,
                            // lng: -4.95,
                        }}
                        options={{
                            disableDefaultUI: true, // disable default map UI
                            draggable: true, // make map draggable
                            keyboardShortcuts: false, // disable keyboard shortcuts
                            scaleControl: false, // allow scale controle
                            scrollwheel: true, // allow scroll wheel
                            styles: stylesx, // change default map styles
                        }}
                        defaultZoom={5.8}
                        heatmapLibrary
                        heatmap={reportData}
                    />
                )}
            </>
        );
    }
}

export default withSnackbar(withStyles(styles)(HeatMap));
