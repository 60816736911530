import React from 'react';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Menu,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import TickIcon from '@material-ui/icons/Done';
import { withSnackbar } from 'notistack';
import HybridTextField from '../../hybridTextField';
import titalise from '../../../utilities/titalise';
import { paymentSourcesArray } from '../../../constants/arrays';
import { paymentSourceLookUp } from '../../../utilities/lookUpTables';

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
}))(TableCell);

const styles = (theme) => ({
    label: {
        fontSize: 11,
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: 7,
        marginTop: theme.spacing.unit * 2,
        width: '100%',
        textAlign: 'left',
    },
    componentContainer: {
        width: '100%',
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: theme.spacing.unit * 1.5,
    },

    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    divider: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    authorisedPaymentChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '102px',
    },
    declinedPaymentChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '102px',
    },
    chip: {
        height: 21,
        borderRadius: 5,
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    table: {
        // width: '50%',
    },
    menuIcon: {
        color: '#737373',
    },
    currentPayment: {
        backgroundColor: '#c6edff !important',
    },
    dialogContent: {
        paddingBottom: 16,
        minWidth: 390,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '8px 24px',
        margin: '0px 0px 0px 0px !important',
    },
    bumpTop1U: {
        marginTop: theme.spacing.unit * 1,
    },
    bumpTop2U: {
        marginTop: theme.spacing.unit * 2,
    },
    bumpTop3U: {
        marginTop: theme.spacing.unit * 3,
    },
    bumpBottom1U: {
        marginBottom: theme.spacing.unit * 1,
    },
    bumpBottom2U: {
        marginBottom: theme.spacing.unit * 2,
    },
    bumpBottom3U: {
        marginBottom: theme.spacing.unit * 3,
    },
    refundIconContainer: {
        display: 'flex',
        width: 40,
        height: 21,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    refundIconLeft: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        width: '100%',
    },
    refundIconRight: {
        color: 'white',
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        width: '100%',
    },
    refundLetter: {
        textAlign: 'center',
        position: 'relative',
        left: 20,
        top: 2,
        color: '#fff',
    },
    backgroundPass: {
        backgroundColor: theme.palette.primary.testPass,
    },
    backgroundFail: {
        backgroundColor: theme.palette.primary.testFail,
    },
    backgroundDisabled: {
        backgroundColor: '#e0e0e0',
    },
    backgroundWarning: {
        backgroundColor: '#ffbf00',
    },
    dialogContentAlertText: {
        color: '#c80000',
        fontSize: 16,
        opacity: 0.54,
    },
    dialogContentTitle: {
        color: '#c80000',
        fontSize: 16,
        opacity: 0.54,
        fontWeight: 700,
    },
});

class AgreementPaymentSummaryTable extends React.Component {
    state = {
        anchorEl: null,
        currentPayment: {},
        showPaymentDetailDialog: false,
    };

    async componentDidMount() {
    }

    handlePaymentDetailClick = (code) => {
        const currentPayment = this.props.payments.find((payment) => payment.code === code);
        this.setState({
            currentPayment,
            showPaymentDetailDialog: true,
        });
    };

    handleClosePaymentDetailDialogClick = () => {
        this.setState({
            showPaymentDetailDialog: false,
        });
    };

    render() {
        const {
            classes,
            payments,
        } = this.props;
        const {
            anchorEl,
            currentPayment,
            disableReversalSubmitButton,
            disableRefundSubmitButton,
            errors,
            showPaymentDetailDialog,
            showReversalDialog,
            showRefundDialog,
            reversalData,
            refundData,
        } = this.state;

        return (
            <div className={classes.componentContainer}>
                { payments.length === 0
                    ? (
                        <HybridTextField
                            alert
                            label="Payments"
                            text="None"
                            fieldStyle={{ style: 'alert', tooltip: 'No payments' }}
                        />
                    )
                    : (
                        <>
                            <Paper>
                                <Table className={classes.table} padding="dense">
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell>#</CustomTableCell>
                                            <CustomTableCell>Source</CustomTableCell>
                                            <CustomTableCell align="center">Payment Date</CustomTableCell>
                                            <CustomTableCell align="center">Payment Time</CustomTableCell>
                                            <CustomTableCell align="center">Amount</CustomTableCell>
                                            <CustomTableCell align="center">Status</CustomTableCell>
                                            <CustomTableCell align="center" />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {payments.map((payment, i) => {
                                            i++;
                                            return (
                                                <TableRow
                                                    className={`${classes.row} ${anchorEl && currentPayment.paymentID === payment.paymentID ? classes.currentPayment : ''}`}
                                                    key={payment.paymentID}
                                                >
                                                    <CustomTableCell component="th" scope="row">{payments.length + 1 - i}</CustomTableCell>
                                                    <CustomTableCell align="left">{paymentSourcesArray.find((paymentSource) => paymentSource.source === payment.source).displaySource}</CustomTableCell>
                                                    <CustomTableCell align="center">
                                                        {moment(payment.paymentDateTime).format('DD/MM/YYYY')}
                                                    </CustomTableCell>
                                                    <CustomTableCell align="center">
                                                        {moment(payment.paymentDateTime).format('HH:mm')}
                                                    </CustomTableCell>
                                                    <CustomTableCell align="right">{`${payment.amount < 0 ? '-' : ''}£${(Math.abs(payment.amount)).toFixed(2)}`}</CustomTableCell>
                                                    <CustomTableCell align="center">{payment.transactionStatus === 'AUTHORISED' ? <Chip label="AUTHORISED" className={`${classes.chip} ${classes.authorisedPaymentChip}`} /> : <Chip label={payment.transactionStatus} className={`${classes.chip} ${classes.declinedPaymentChip}`} /> }</CustomTableCell>
                                                    <CustomTableCell align="right">
                                                        <IconButton id={payment.paymentID} onClick={() => this.handlePaymentDetailClick(payment.code)} className={classes.button}>
                                                            <InfoIcon className={classes.menuIcon} />
                                                        </IconButton>
                                                    </CustomTableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </>
                    )}

                {!isEmpty(currentPayment)
                    ? (
                        <>
                            <Dialog
                                open={showPaymentDetailDialog}
                                onClose={this.handleClosePaymentDetailDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <Typography variant="h6" gutterBottom>
                                            Payment Detail
                                        </Typography>
                                        {currentPayment.reversalPaymentID
                                            ? (
                                                <Typography className={classNames(
                                                    classes.bumpBottom2U,
                                                    classes.dialogContentAlertText,
                                                )}
                                                >
                                                    This payment has been reversed by paymentID
                                                    {' '}
                                                    {currentPayment.reversalPaymentID}
                                                    .
                                                </Typography>
                                            )
                                            : ''}
                                        <Grid
                                            container
                                            spacing={0}
                                            className={classNames(
                                                classes.sectionContainer,
                                                classes.bumpTop3U,
                                            )}
                                        >
                                            <Grid
                                                container
                                                spacing={0}
                                            >

                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Type"
                                                        text={paymentSourceLookUp[currentPayment.source]}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Payment Reference"
                                                        text={currentPayment.paymentReference}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Parent ID"
                                                        text={currentPayment.source === 'REFUND'
                                                            || currentPayment.source === 'REVERSAL'
                                                            ? currentPayment.parentID
                                                            : 'n/a'}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Created By"
                                                        text={currentPayment.createdByContact}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Reason"
                                                        text={currentPayment.reason}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Notes"
                                                        text={currentPayment.notes}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Refunded Amount"
                                                        text={!['MANUALADJUSTMENT', 'REVERSAL', 'REFUND'].includes(currentPayment.source)
                                                        && !currentPayment.reversalPaymentID
                                                            ? `£${currentPayment.refundedAmount.toFixed(2)}  (${(currentPayment.refundedAmount / currentPayment.amount) * 100}%)`
                                                            : 'n/a'}
                                                        alert={currentPayment.refundedAmount > 0}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </div>
                                </DialogContent>
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleClosePaymentDetailDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    )
                    : ''}

            </div>
        );
    }
}

export default withSnackbar(withStyles(styles)(AgreementPaymentSummaryTable));
