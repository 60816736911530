import React from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import GoogleMapReact from 'google-map-react';
import moment from 'moment-timezone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { DatePicker, DateTimePicker } from 'material-ui-pickers';
import WarningIcon from '@material-ui/icons/Error';
import HybridTextField from '../hybridTextField';
import titalise from '../../utilities/titalise';
import {
    titles,
    counties,
    countries,
} from '../../utilities/menuOptions';
import Tile from '../../containers/tile';
import PurchaseOrderHistory from './purchaseOrderHistory';
// import PurchaseOrderEditDialog from './purchaseOrderEditDialog';
import PurchaseOrderItemsTable from './purchaseOrderItemsTable';

const styles = (theme) => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    tileBottomDivider: {
        marginTop: theme.spacing.unit * 3,
        // marginBottom: theme.spacing.unit * 2,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginLeft: -24,
        marginRight: -24,
    },
    bottomBarContainer: {
        width: '100%',
        textAlign: 'right',
    },
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '8px 24px',
        margin: '0px 0px 0px 0px !important',
    },
    nameField: {
        width: 350,
    },
    addressField: {
        width: 250,
    },
    postcodeField: {
        width: 120,
    },
    countryField: {
        width: 200,
        marginLeft: theme.spacing.unit * 3,
    },
    emailField: {
        width: 350,
    },
    rightOfFieldButton: {
        marginTop: 10,
        marginLeft: theme.spacing.unit * 3,
        color: 'rgba(0, 121, 255, 0.87)',
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 4,
        marginBottom: theme.spacing.unit * 3,
    },
    dialogContent: {
        paddingBottom: 0,
        minWidth: 400,
    },
    formHelperText: {
        color: '#f44336',
    },
    contactLocationIcon: {
        color: 'red',
        fontSize: 30,
        position: 'absolute',
        transform: 'translate(-50%, -100%)',
    },
    branchLocationIcon: {
        color: 'blue',
        fontSize: 30,
        position: 'absolute',
        transform: 'translate(-50%, -100%)',
    },
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    // agents: {
    //     marginRight: theme.spacing.unit * 3,
    // },
    // 'agents:not(:first-child)': {
    //     marginLeft: 0,
    //     backgroundColor: 'red',
    // },
    debtCompanyContainer: {
        height: '100%',
    },
    debtCompany: {
        boxShadow: '0 0 0 3px #f44336, 0 0 0 2px #f44336 inset',
        border: '2px solid transparent',
        borderRadius: 4,
        display: 'inline-block',
        padding: '5px 2px',
        color: '#f44336',
        fontSize: 24,
        textTransform: 'uppercase',
        textAlign: 'center',
        // opacity: 0.6,
        fontWeight: 900,
        position: 'absolute',
        top: '60%',
        left: '50%',
        transform: 'rotate(-5deg) translate(-50%,-50%)',
        minWidth: '80%',
        background: '#ffffff7d',
    },
    debtCompanyName: {
        fontSize: 80,
    },
    debtNoContact: {
        fontSize: 40,
    },
    dialogText: {
        marginTop: theme.spacing.unit * 2,
    },
});

class PurchaseOrderDetails extends React.Component {
    state = {
        // disableInvalidAddressSwitch: false,
        // disableInvalidAddressSubmitButton: false,
        // disableForbidAgreementSwitch: false,
        // disableForbidAgreementSubmitButton: false,
        // disableForbidSaleSwitch: false,
        // disableForbidSaleSubmitButton: false,
        // disableEditContactAddressSaveButton: false,
        // disableEditContactDetailsSaveButton: false,
        // disableEditContactSecurityQuestionsSaveButton: false,
        // disableSendSMSButton: false,
        // disableSyncDetailsButton: false,
        // disableSendEmailButton: false,
        // showInvalidAddressDialog: false,
        // showForbidAgreementDialog: false,
        // showForbidSaleDialog: false,
        // showContactAddressEditDialog: false,
        // showContactDetailsEditDialog: false,
        // showContactSecurityAnswersDialog: false,
        // showContactSecurityQuestionsEditDialog: false,
        // showFindAgentsDialog: false,
        // showSendSMSDialog: false,
        // showSyncDetailsDialog: false,
        // showSendEmailDialog: false,
        // smsForm: {
        //     message: '',
        // },
        // emailForm: {
        //     content: '',
        //     subject: '',
        // },
        // contactDetailsForm: {
        //     title: '',
        //     firstname: '',
        //     surname: '',
        //     homeTelephoneNumber: '',
        //     mobileTelephoneNumber: '',
        //     emailAddress: '',
        //     gdpr: '',
        //     dateOfBirth: '',
        // },
        // contactAddressForm: {
        //     newAddress: 'false',
        //     address1: '',
        //     address2: '',
        //     townCity: '',
        //     county: '',
        //     postcode: '',
        //     country: '',
        // },
        // contactSecurityQuestionsForm: {
        //     favouritePet: '',
        //     memorableWord: '',
        //     mothersMaidenName: '',
        // },
        // invalidAddressData: {
        //     invalidAddressReason: '',
        // },
        // forbidAgreementData: {
        //     forbidAgreementReason: '',
        // },
        // forbidSaleData: {
        //     forbidSaleReason: '',
        // },
        disableSendPurchaseOrderButton: false,
        sendPurchaseOrderData: {},
        showAuthorisePurchaseOrderDialog: false,
        showEditPurchaseOrderDialog: false,
        showSendPurchaseOrderDialog: false,
        // showExpireManufacturerSupplierDialog: false,
    };

    // componentDidMount() {
    //     this.setupFormData();
    // }

    componentDidUpdate(prevProps) {
        if (this.props.purchaseOrder !== prevProps.purchaseOrder) {
            // this.setupFormData();
        }
    }

    //     setupFormData() {
    //         if (this.props.contact) {
    //             this.setState((state) => ({
    //                 contactDetailsForm: {
    //                     ...state.contactDetailsForm,
    //                     title: this.props.contact.title,
    //                     firstname: this.props.contact.firstname,
    //                     surname: this.props.contact.surname,
    //                     homeTelephoneNumber: this.props.contact.homeTelephoneNumber,
    //                     mobileTelephoneNumber: this.props.contact.mobileTelephoneNumber,
    //                     emailAddress: this.props.contact.emailAddress,
    //                     gdpr: this.props.contact.gdpr,
    //                     dateOfBirth: this.props.contact.dateOfBirth,
    //                 },
    //                 contactAddressForm: {
    //                     ...state.contactAddressForm,
    //                     newAddress: 'false',
    //                     address1: this.props.contact.address.address1,
    //                     address2: this.props.contact.address.address2,
    //                     townCity: this.props.contact.address.townCity,
    //                     county: this.props.contact.address.county,
    //                     postcode: this.props.contact.address.postcode,
    //                     country: this.props.contact.address.country,
    //                 },
    //                 emailForm: {
    //                     ...state.emailForm,
    //                     content: `Hello ${this.props.contact.firstname},

    // `,
    //                     subject: 'Pay Weekly',
    //                 },
    //             }));
    //         }
    //     }

    //     handleContactSecurityAnswersClick = () => {
    //         this.setState({
    //             showContactSecurityAnswersDialog: true,
    //         });
    //         this.props.fetchContactSecurityAnswers();
    //     };

    //     handleFindAgentsClick = () => {
    //         this.setState({
    //             showFindAgentsDialog: true,
    //         });
    //         this.props.fetchContactAgents();
    //     };

    //     handleContactSecurityAnswersCloseDialogClick = () => {
    //         this.setState({
    //             showContactSecurityAnswersDialog: false,
    //         });
    //     };

    //     //

    //     handleContactSecurityQuestionsEditClick = async () => {
    //         this.setState({
    //             showContactSecurityQuestionsEditDialog: true,
    //         });

    //         await this.props.fetchContactSecurityAnswers();

    //         this.setState({
    //             contactSecurityQuestionsForm: {
    //                 ...this.props.contact.securityAnswers,
    //             },
    //         });
    //     };

    //     handleContactSecurityQuestionsCloseDialogClick = () => {
    //         this.setState({
    //             showContactSecurityQuestionsEditDialog: false,
    //         });
    //     };

    //     handleFindAgentsCloseDialogClick = () => {
    //         this.setState({
    //             showFindAgentsDialog: false,
    //         });
    //     };

    //     //

    //     handleContactDetailsEditClick = () => {
    //         this.setState({
    //             showContactDetailsEditDialog: true,
    //         });
    //     };

    //     handleContactAddressEditClick = () => {
    //         this.setState({
    //             showContactAddressEditDialog: true,
    //         });
    //     };

    //     handleContactDetailsCloseDialogClick = () => {
    //         this.setState({
    //             showContactDetailsEditDialog: false,
    //         });
    //     };

    //     handleContactAddressCloseDialogClick = () => {
    //         this.setState({
    //             showContactAddressEditDialog: false,
    //         });
    //     };

    //     handleSendSMSClick = () => {
    //         this.setState({
    //             showSendSMSDialog: true,
    //         });
    //     };

    //     handleSyncDetailsClick = () => {
    //         this.setState({
    //             showSyncDetailsDialog: true,
    //         });
    //     };

    //     handleSendEmailClick = () => {
    //         this.setState({
    //             showSendEmailDialog: true,
    //         });
    //     };

    //     // handleSalesStatusClick = () => {
    //     //     this.setState((state) => ({
    //     //         salesStatusData: {
    //     //             ...state.salesStatusData,
    //     //             salePermission: this.props.contact.salePermission === 'PERMIT' ? 'FORBID' : 'PERMIT',
    //     //         },
    //     //         showSalesStatusDialog: true,
    //     //     }));
    //     // };

    //     handleSendSMSCloseDialogClick = () => {
    //         this.setState({
    //             showSendSMSDialog: false,
    //         });
    //     };

    //     handleSyncDetailsCloseDialogClick = () => {
    //         this.setState({
    //             showSyncDetailsDialog: false,
    //         });
    //     };

    //     handleSendEmailCloseDialogClick = () => {
    //         this.setState({
    //             showSendEmailDialog: false,
    //         });
    //     };

    //     handleInvalidAddressCloseDialogClick = () => {
    //         this.setState({
    //             showInvalidAddressDialog: false,
    //         });
    //     };

    //     handleForbidAgreementCloseDialogClick = () => {
    //         this.setState({
    //             showForbidAgreementDialog: false,
    //         });
    //     };

    //     handleForbidSaleCloseDialogClick = () => {
    //         this.setState({
    //             showForbidSaleDialog: false,
    //         });
    //     };

    //     handleEditContactDetailsCheckBoxClick = (name) => (event) => {
    //         const newValue = this.state.contactDetailsForm[name] !== true;
    //         this.setState((state) => ({
    //             contactDetailsForm: {
    //                 ...state.contactDetailsForm,
    //                 [name]: newValue,
    //             },
    //         }));
    //     };

    //     handleContactDateOfBirthChange = (chosenDate) => {
    //         this.setState((state) => ({
    //             contactDetailsForm: {
    //                 ...state.contactDetailsForm,
    //                 dateOfBirth: moment(chosenDate).format('YYYY-MM-DD'),
    //             },
    //         }));
    //     };

    //     updateEditSecurityQuestionsData = (event) => {
    //         const { name, value } = event.target;

    //         let formattedValue;
    //         switch (name) {
    //         default:
    //             formattedValue = value;
    //             break;
    //         }
    //         event.target.value = formattedValue;

    //         this.setState((state) => ({
    //             contactSecurityQuestionsForm: {
    //                 ...state.contactSecurityQuestionsForm,
    //                 [name]: formattedValue,
    //             },
    //         }));
    //     };

    //     updateInvalidAddressData = (event) => {
    //         const { name, value } = event.target;

    //         let formattedValue;
    //         switch (name) {
    //         default:
    //             formattedValue = value;
    //             break;
    //         }
    //         event.target.value = formattedValue;

    //         this.setState((state) => ({
    //             invalidAddressData: {
    //                 ...state.invalidAddressData,
    //                 [name]: formattedValue,
    //             },
    //         }));
    //     };

    //     updateForbidAgreementData = (event) => {
    //         const { name, value } = event.target;

    //         let formattedValue;
    //         switch (name) {
    //         default:
    //             formattedValue = value;
    //             break;
    //         }
    //         event.target.value = formattedValue;

    //         this.setState((state) => ({
    //             forbidAgreementData: {
    //                 ...state.forbidAgreementData,
    //                 [name]: formattedValue,
    //             },
    //         }));
    //     };

    //     updateForbidSaleData = (event) => {
    //         const { name, value } = event.target;

    //         let formattedValue;
    //         switch (name) {
    //         default:
    //             formattedValue = value;
    //             break;
    //         }
    //         event.target.value = formattedValue;

    //         this.setState((state) => ({
    //             forbidSaleData: {
    //                 ...state.forbidSaleData,
    //                 [name]: formattedValue,
    //             },
    //         }));
    //     };

    //     updateEditContactDetailsData = (event) => {
    //         const { name, value } = event.target;

    //         let formattedValue;
    //         switch (name) {
    //         case 'emailAddress':
    //             formattedValue = value.toLowerCase();
    //             break;
    //         default:
    //             formattedValue = titalise(value);
    //             break;
    //         }
    //         event.target.value = formattedValue;

    //         this.setState((state) => ({
    //             contactDetailsForm: {
    //                 ...state.contactDetailsForm,
    //                 [name]: formattedValue,
    //             },
    //         }));
    //     };

    //     updateEditContactAddressData = (event) => {
    //         const { name, value } = event.target;
    //         let formattedValue;
    //         let contactAddressFormValues = { ...this.state.contactAddressForm };
    //         switch (name) {
    //         case 'postcode':
    //             formattedValue = value.toUpperCase();
    //             break;
    //         case 'county':
    //         case 'country':
    //         case 'newAddress':
    //             formattedValue = value;
    //             break;
    //         default:
    //             formattedValue = titalise(value);
    //             break;
    //         }
    //         event.target.value = formattedValue;

    //         if (name === 'newAddress' && value === 'true') {
    //             Object.keys(contactAddressFormValues).forEach((k) => contactAddressFormValues[k] = '');
    //         } else if (name === 'newAddress' && value === 'false') {
    //             contactAddressFormValues = {
    //                 address1: this.props.contact.address.address1,
    //                 address2: this.props.contact.address.address2,
    //                 townCity: this.props.contact.address.townCity,
    //                 county: this.props.contact.address.county,
    //                 postcode: this.props.contact.address.postcode,
    //                 country: this.props.contact.address.country,
    //             };
    //         }

    //         this.setState({
    //             contactAddressForm: {
    //                 ...contactAddressFormValues,
    //                 [name]: formattedValue,
    //             },
    //         });
    //     };

    //     handleSaveContactSecurityQuestions = (event) => {
    //         event.preventDefault();
    //         this.setState({
    //             disableEditContactSecurityQuestionsSaveButton: true,
    //         });
    //         this.props.saveContactSecurityQuestions(this.state.contactSecurityQuestionsForm).then(() => {
    //             this.setState({
    //                 showContactSecurityQuestionsEditDialog: false,
    //                 disableEditContactSecurityQuestionsSaveButton: false,
    //             });
    //             this.props.enqueueSnackbar('Contact Security Questions saved', { variant: 'success' });
    //         }).catch(() => {
    //             console.log(this.props.errors);
    //             this.props.enqueueSnackbar('Contact Security Questions save failed', { variant: 'error' });
    //             this.setState({
    //                 disableEditContactSecurityQuestionsSaveButton: false,
    //             });
    //         });
    //     };

    //     handleSaveContactDetails = (event) => {
    //         event.preventDefault();
    //         this.setState({
    //             disableEditContactDetailsSaveButton: true,
    //         });
    //         this.props.saveContactDetails(this.state.contactDetailsForm).then(() => {
    //             this.setState({
    //                 showContactDetailsEditDialog: false,
    //                 disableEditContactDetailsSaveButton: false,
    //             });
    //             this.props.enqueueSnackbar('Contact details saved', { variant: 'success' });
    //         }).catch(() => {
    //             console.log(this.props.errors);
    //             this.props.enqueueSnackbar('Contact details save failed', { variant: 'error' });
    //             this.setState({
    //                 disableEditContactDetailsSaveButton: false,
    //             });
    //         });
    //     };

    //     handleSaveContactAddress = (event) => {
    //         event.preventDefault();
    //         this.setState({
    //             disableEditContactAddressSaveButton: true,
    //         });
    //         const payload = {
    //             ...this.state.contactAddressForm,
    //             newAddress: this.state.contactAddressForm.newAddress === 'true',
    //         };
    //         this.props.saveContactAddress(payload).then(() => {
    //             this.setState({
    //                 showContactAddressEditDialog: false,
    //                 disableEditContactAddressSaveButton: false,
    //             });
    //             this.props.enqueueSnackbar('Contact Address saved', { variant: 'success' });
    //         }).catch(() => {
    //             console.log(this.props.errors);
    //             this.props.enqueueSnackbar('Contact Address save failed', { variant: 'error' });
    //             this.setState({
    //                 disableEditContactAddressSaveButton: false,
    //             });
    //         });
    //     };

    //     updateSendSMSData = (event) => {
    //         const { name, value } = event.target;
    //         const wrappedMessage = `PAY WEEKLY: ${value}. Do not reply.`;
    //         this.setState({
    //             smsForm: {
    //                 ...this.state.smsForm,
    //                 content: wrappedMessage,
    //             },
    //         });
    //     };

    //     updateSendEmailData = (event) => {
    //         const { name, value } = event.target;

    //         let formattedValue;
    //         switch (name) {
    //         case 'subject':
    //             formattedValue = titalise(value);
    //             break;
    //         default:
    //             formattedValue = value;
    //             break;
    //         }
    //         event.target.value = formattedValue;

    //         this.setState((state) => ({
    //             emailForm: {
    //                 ...state.emailForm,
    //                 [name]: formattedValue,
    //             },
    //         }));
    //     };

    //     updateSalesStatusData = (event) => {
    //         const { name, value } = event.target;

    //         let formattedValue;
    //         switch (name) {
    //         default:
    //             formattedValue = value;
    //             break;
    //         }
    //         event.target.value = formattedValue;

    //         this.setState((state) => ({
    //             salesStatusData: {
    //                 ...state.salesStatusData,
    //                 [name]: formattedValue,
    //             },
    //         }));
    //     };

    //     handleSendSMS = (event) => {
    //         event.preventDefault();
    //         this.setState({
    //             disableSendSMSButton: true,
    //         });
    //         this.props.sendSMS(this.state.smsForm).then(() => {
    //             this.setState({
    //                 showSendSMSDialog: false,
    //                 disableSendSMSButton: false,
    //             });
    //             this.props.enqueueSnackbar('SMS message sent', { variant: 'success' });
    //         }).catch(() => {
    //             this.props.enqueueSnackbar('SMS message failed', { variant: 'error' });
    //             this.setState({
    //                 disableSendSMSButton: false,
    //             });
    //         });
    //     };

    //     handleSyncDetails = (event) => {
    //         event.preventDefault();
    //         this.setState({
    //             disableSyncDetailsButton: true,
    //         });
    //         this.props.syncDetails(this.state.smsForm).then(() => {
    //             this.setState({
    //                 showSyncDetailsDialog: false,
    //                 disableSyncDetailsButton: false,
    //             });
    //             this.props.enqueueSnackbar('Contact Details Synchronised', { variant: 'success' });
    //         }).catch(() => {
    //             this.props.enqueueSnackbar('Synchronise Contact Details Failed', { variant: 'error' });
    //             this.setState({
    //                 disableSyncDetailsButton: false,
    //             });
    //         });
    //     };

    //     handleSendSMSAgentDetails = (agent) => {
    //         console.log('>>> agent:', agent);
    //         this.setState({
    //             disableSendSMSAgentDetailsButton: true,
    //         });
    //         const payload = {
    //             content: `Hello ${this.props.contact.firstname}, Your local Pay Weekly sales agent is ${agent.firstname} ${agent.surname} - ${agent.mobileTelephoneNumber}. Do not reply.`,
    //         };
    //         this.props.sendSMSAgentDetails(payload).then(() => {
    //             this.setState({
    //                 showFindAgentsDialog: false,
    //                 disableSendSMSAgentDetailsButton: false,
    //             });
    //             this.props.enqueueSnackbar('SMS agent details message sent', { variant: 'success' });
    //         }).catch(() => {
    //             this.props.enqueueSnackbar('SMS agent details message failed', { variant: 'error' });
    //             this.setState({
    //                 disableSendSMSAgentDetailsButton: false,
    //             });
    //         });
    //     };

    //     handleSendEmail = (event) => {
    //         event.preventDefault();
    //         this.setState({
    //             disableSendEmailButton: true,
    //         });
    //         this.props.sendEmail(this.state.emailForm).then(() => {
    //             this.setState({
    //                 showSendEmailDialog: false,
    //                 disableSendEmailButton: false,
    //             });
    //             this.props.enqueueSnackbar('Email message sent', { variant: 'success' });
    //         }).catch(() => {
    //             this.props.enqueueSnackbar('Email message failed', { variant: 'error' });
    //             this.setState({
    //                 disableSendEmailButton: false,
    //             });
    //         });
    //     };

    //     handleSalesStatusSubmit = (event) => {
    //         event.preventDefault();
    //         this.setState({
    //             disableSalesStatusSubmitButton: true,
    //         });
    //         this.props.salesSatusSubmit(this.state.salesStatusData).then(() => {
    //             this.setState({
    //                 showSalesStatusDialog: false,
    //                 disableSalesStatusSubmitButton: false,
    //             });
    //             this.props.enqueueSnackbar('Changed Sales Status', { variant: 'success' });
    //         }).catch(() => {
    //             this.props.enqueueSnackbar('Change Sales Status Failed', { variant: 'error' });
    //             this.setState({
    //                 disableSalesStatusSubmitButton: false,
    //             });
    //         });
    //     };

    //     handleInvalidAddressSwitchClick = async () => {
    //         this.setState((state) => ({
    //             disableInvalidAddressSwitch: true,
    //             invalidAddressData: {
    //                 invalidAddressReason: '',
    //             },
    //         }));
    //         const newState = this.props.contact.invalidAddress !== true;
    //         if (newState === true) {
    //             this.setState(() => ({
    //                 showInvalidAddressDialog: true,
    //             }));
    //             return;
    //         }
    //         this.invalidAddressToggle();
    //     };

    //     invalidAddressToggle = () => {
    //         this.setState({
    //             disableInvalidAddressSubmitButton: true,
    //         });
    //         const newState = this.props.contact.invalidAddress !== true;
    //         const payload = {
    //             invalidAddress: newState,
    //             invalidAddressReason: this.state.invalidAddressData.invalidAddressReason,
    //         };
    //         this.props.updateContactDetails(payload).then(() => {
    //             this.setState({
    //                 disableInvalidAddressSubmitButton: false,
    //                 disableInvalidAddressSwitch: false,
    //                 showInvalidAddressDialog: false,
    //             });
    //             this.props.enqueueSnackbar('Changed Valid Address Status', { variant: 'success' });
    //         }).catch(() => {
    //             this.props.enqueueSnackbar('Change Valid Address Status Failed', { variant: 'error' });
    //             this.setState({
    //                 disableInvalidAddressSubmitButton: false,
    //                 disableInvalidAddressSwitch: false,
    //             });
    //         });
    //     };

    //     handleForbidAgreementSwitchClick = async () => {
    //         this.setState((state) => ({
    //             disableInvalidAddressSwitch: true,
    //             forbidAgreementData: {
    //                 forbidAgreementReason: '',
    //             },
    //         }));
    //         const newState = this.props.contact.forbidAgreement !== true;
    //         if (newState === true) {
    //             this.setState(() => ({
    //                 showForbidAgreementDialog: true,
    //             }));
    //             return;
    //         }
    //         this.forbidAgreementToggle();
    //     };

    //     forbidAgreementToggle = () => {
    //         this.setState({
    //             disableForbidAgreementSubmitButton: true,
    //         });
    //         const newState = this.props.contact.forbidAgreement !== true;
    //         const payload = {
    //             forbidAgreement: newState,
    //             forbidAgreementReason: this.state.forbidAgreementData.forbidAgreementReason,
    //         };
    //         this.props.updateContactDetails(payload).then(() => {
    //             this.setState({
    //                 disableForbidAgreementSubmitButton: false,
    //                 disableForbidAgreementSwitch: false,
    //                 showForbidAgreementDialog: false,
    //             });
    //             this.props.enqueueSnackbar('Changed Allow Agreement Status', { variant: 'success' });
    //         }).catch(() => {
    //             this.props.enqueueSnackbar('Change Allow Agreement Status Failed', { variant: 'error' });
    //             this.setState({
    //                 disableForbidAgreementSubmitButton: false,
    //                 disableForbidAgreementSwitch: false,
    //             });
    //         });
    //     };

    //     handleForbidSaleSwitchClick = async () => {
    //         this.setState((state) => ({
    //             disableForbidSaleSwitch: true,
    //             forbidSaleData: {
    //                 forbidSaleReason: '',
    //             },
    //         }));
    //         const newState = this.props.contact.forbidSale !== true;
    //         if (newState === true) {
    //             this.setState(() => ({
    //                 showForbidSaleDialog: true,
    //             }));
    //             return;
    //         }
    //         this.forbidSaleToggle();
    //     };

    // forbidSaleToggle = () => {
    //     this.setState({
    //         disableForbidSaleSubmitButton: true,
    //     });
    //     const newState = this.props.contact.forbidSale !== true;
    //     const payload = {
    //         forbidSale: newState,
    //         forbidSaleReason: this.state.forbidSaleData.forbidSaleReason,
    //     };
    //     this.props.updateContactDetails(payload).then(() => {
    //         this.setState({
    //             disableForbidSaleSubmitButton: false,
    //             disableForbidSaleSwitch: false,
    //             showForbidSaleDialog: false,
    //         });
    //         this.props.enqueueSnackbar('Changed Valid Address Status', { variant: 'success' });
    //     }).catch(() => {
    //         this.props.enqueueSnackbar('Change Valid Address Status Failed', { variant: 'error' });
    //         this.setState({
    //             disableForbidSaleSubmitButton: false,
    //             disableForbidSaleSwitch: false,
    //         });
    //     });
    // };

    handleShowSendPurchaseOrderDialogClick = () => {
        this.setState({
            sendPurchaseOrderData: {
                emailAddress: this.props.purchaseOrder.supplier.emailAddress,
            },
            showSendPurchaseOrderDialog: true,
        });
    };

    handleCloseSendPurchaseOrderDialogClick = () => {
        this.setState({
            showSendPurchaseOrderDialog: false,
        });
    };

    updateSendPurchaseOrderData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        case 'emailAddress':
            formattedValue = value.toLowerCase().replace(/[^a-zA-Z0-9_/-@.]/g, '');
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            sendPurchaseOrderData: {
                ...state.sendPurchaseOrderData,
                [name]: formattedValue,
            },
        }));
    };

    handleSendPurchaseOrder = () => {
        this.setState({
            disableSendPurchaseOrderButton: true,
        });

        this.props.sendPurchaseOrder(this.state.sendPurchaseOrderData).then(() => {
            this.setState({
                disableSendPurchaseOrderButton: false,
            });
            this.handleCloseSendPurchaseOrderDialogClick();
            this.props.enqueueSnackbar('Purchase Order Sent', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Send Purchase Order Failed', { variant: 'error' });
            this.setState({
                disableSendPurchaseOrderButton: false,
            });
        });
    };

    // handleShowEditManufacturerSupplierDialogClick = () => {
    //     this.setState({
    //         showEditPurchaseOrderDialog: true,
    //     });
    // };

    // handleShowExpireManufacturerSupplierDialogClick = () => {
    //     this.setState({
    //         showExpireManufacturerSupplierDialog: true,
    //     });
    // };

    // handleCloseEditManufacturerSupplierDialogClick = () => {
    //     this.setState({
    //         showEditPurchaseOrderDialog: false,
    //     });
    // };

    // handleCloseExpireManufacturerSupplierDialogClick = () => {
    //     this.setState({
    //         showExpireManufacturerSupplierDialog: false,
    //     });
    // };

    handleShowPdf = () => {
        window.open(`/purchaseOrderPdf/${this.props.purchaseOrder.code}`);
    };

    handleShowAuthorisePurchaseOrderDialogClick = () => {
        this.setState({
            showAuthorisePurchaseOrderDialog: true,
        });
    };

    handleCloseAuthorisePurchaseOrderDialogClick = () => {
        this.setState({
            showAuthorisePurchaseOrderDialog: false,
        });
    };

    handleAuthorisePurchaseOrder = () => {
        this.setState({
            disableAuthorisePurchaseOrderButton: true,
        });

        this.props.authorisePurchaseOrder().then(() => {
            this.setState({
                disableAuthorisePurchaseOrderButton: false,
                showAuthorisePurchaseOrderDialog: false,
            });
            this.props.enqueueSnackbar('Purchase Order Authorised', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Authorise Purchase Order Failed', { variant: 'error' });
            this.setState({
                disableAuthorisePurchaseOrderButton: false,
            });
        });
    };

    render() {
        const {
            classes,
            errors,
            fetchHistoriesData,
            fetchPurchaseOrderData,
            purchaseOrder,
            purchaseOrderHistoriesLoading,
            purchaseOrderLoading,
        } = this.props;
        const {
            // contactAddressForm,
            // contactDetailsForm,
            // contactSecurityQuestionsForm,
            // disableInvalidAddressSwitch,
            // disableInvalidAddressSubmitButton,
            // disableForbidAgreementSwitch,
            // disableForbidAgreementSubmitButton,
            // disableForbidSaleSwitch,
            // disableForbidSaleSubmitButton,
            // disableEditContactAddressSaveButton,
            // disableEditContactDetailsSaveButton,
            // disableEditContactSecurityQuestionsSaveButton,
            // disableSendEmailButton,
            // disableSalesStatusSubmitButton,
            // disableSendSMSAgentDetailsButton,
            // disableSendSMSButton,
            // disableSyncDetailsButton,
            // emailForm,
            // forbidAgreementData,
            // forbidSaleData,
            // salesStatusData,
            // showContactAddressEditDialog,
            // showContactDetailsEditDialog,
            // showContactSecurityAnswersDialog,
            // showContactSecurityQuestionsEditDialog,
            // showFindAgentsDialog,
            // showForbidAgreementDialog,
            // showForbidSaleDialog,
            // showSendEmailDialog,
            // showSalesStatusDialog,
            // showSendSMSDialog,
            // showSyncDetailsDialog,
            // showInvalidAddressDialog,
            // invalidAddressData,
            disableAuthorisePurchaseOrderButton,
            disableSendPurchaseOrderButton,
            sendPurchaseOrderData,
            showAuthorisePurchaseOrderDialog,
            showEditPurchaseOrderDialog,
            showExpireManufacturerSupplierDialog,
            showSendPurchaseOrderDialog,
        } = this.state;

        return (
            <>
                <Tile
                    tileTitle="Purchase Order Details"
                    refreshAction={fetchPurchaseOrderData}
                    refreshing={purchaseOrderLoading}
                    tileMenuItems={[
                        {
                            action: this.handleShowEditPurchaseOrderDialogClick,
                            label: 'Edit Purchase Order',
                            section: 1,
                        },
                        {
                            action: this.handleShowExpirePurchaseOrderDialogClick,
                            label: 'Cancel Purchase Order',
                            section: 1,
                            disabled: purchaseOrder.expired,
                        },
                        {
                            action: this.handleShowExpirePurchaseOrderDialogClick,
                            label: 'Edit Supplier',
                            section: 2,
                            disabled: purchaseOrder.expired,
                        },
                        {
                            action: this.handleShowPdf,
                            label: 'Show PDF',
                            section: 3,
                            disabled: (
                                purchaseOrder.expired
                                || !!purchaseOrder.sentByContactCode
                                || !purchaseOrder.authorisedByContactCode
                                || !purchaseOrder.supplier.recordComplete
                                || purchaseOrder.purchaseOrderItems.filter((purchaseOrderItem) => !purchaseOrderItem.product.recordComplete).length > 0
                            ),
                        },
                        {
                            action: this.handleShowAuthorisePurchaseOrderDialogClick,
                            label: 'Authorise Purchase Order',
                            section: 3,
                            disabled: purchaseOrder.expired || !!purchaseOrder.authorisedByContactCode,
                        },
                        {
                            action: this.handleShowSendPurchaseOrderDialogClick,
                            label: 'Send to Supplier ',
                            section: 4,
                            disabled: (
                                purchaseOrder.expired
                                || !!purchaseOrder.sentByContactCode
                                || !purchaseOrder.authorisedByContactCode
                                || !purchaseOrder.supplier.recordComplete
                                || purchaseOrder.purchaseOrderItems.filter((purchaseOrderItem) => !purchaseOrderItem.product.recordComplete).length > 0
                            ),
                        },
                    ]}
                    style
                >
                    { !purchaseOrderLoading
                        ? (
                            <>
                                <Grid container spacing={0}>
                                    <Grid item xs={3}>
                                        <HybridTextField
                                            label="Created By"
                                            text={purchaseOrder.createdBy}
                                            disabled={purchaseOrder.expired}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <HybridTextField
                                            label="Created Date/Time"
                                            text={moment(purchaseOrder.createdDateTime).format('DD/MM/YYYY HH:mm')}
                                            disabled={purchaseOrder.expired}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <HybridTextField
                                            label="Delivery Branch"
                                            text={purchaseOrder.deliveryBranch.name}
                                            disabled={purchaseOrder.expired}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0}>
                                    <Grid item xs={3}>
                                        <HybridTextField
                                            label="Cost Centre"
                                            text={purchaseOrder.costCentre}
                                            disabled={purchaseOrder.expired}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0}>
                                    <Grid item xs={3}>
                                        <HybridTextField
                                            label="Supplier"
                                            text={purchaseOrder.supplier.name}
                                            disabled={purchaseOrder.expired}
                                            alert={!purchaseOrder.supplier.recordComplete}
                                            fieldStyle={!purchaseOrder.supplier.recordComplete ? {
                                                style: 'alert',
                                                tooltip: 'Supplier Must be Complete Before Sending',
                                            } : false}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>

                                                <HybridTextField
                                                    label="Supplier Address"
                                                    text={purchaseOrder.supplier.address ? [purchaseOrder.supplier.address.address1, purchaseOrder.supplier.address.address2, purchaseOrder.supplier.address.townCity, purchaseOrder.supplier.address.county].filter((e) => e === 0 || e).join('\n') : ''}
                                                    disabled={purchaseOrder.expired}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <HybridTextField
                                                    label="Postcode"
                                                    text={purchaseOrder.supplier.address ? purchaseOrder.supplier.address.postcode : ''}
                                                    disabled={purchaseOrder.expired}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <HybridTextField
                                                    label="Country"
                                                    text={purchaseOrder.supplier.address ? purchaseOrder.supplier.address.country : ''}
                                                    disabled={purchaseOrder.expired}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <HybridTextField
                                                    label="Supplier Telephone Number"
                                                    text={purchaseOrder.supplier.telephoneNumber}
                                                    disabled={purchaseOrder.expired}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <HybridTextField
                                                    label="Supplier Fax Number"
                                                    text={purchaseOrder.supplier.faxNumber}
                                                    disabled={purchaseOrder.expired}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <HybridTextField
                                                    label="Supplier Email Address"
                                                    text={purchaseOrder.supplier.emailAddress}
                                                    disabled={purchaseOrder.expired}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <HybridTextField
                                                    label="Account Reference"
                                                    text={purchaseOrder.supplier.accountReference}
                                                    disabled={purchaseOrder.expired}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <HybridTextField
                                                    label="Our Account Reference"
                                                    text={purchaseOrder.supplier.ourAccountReference}
                                                    disabled={purchaseOrder.expired}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <HybridTextField
                                                    label="Paymemt Terms"
                                                    text={purchaseOrder.supplier.paymentTerms}
                                                    disabled={purchaseOrder.expired}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0}>
                                    <Grid item xs={3}>
                                        <HybridTextField
                                            label="Supplier Contact"
                                            text={purchaseOrder.supplierContact}
                                            disabled={purchaseOrder.expired}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <HybridTextField
                                            label="Quote Reference"
                                            text={purchaseOrder.quoteReference}
                                            disabled={purchaseOrder.expired}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0}>
                                    <Grid item xs={3}>
                                        <HybridTextField
                                            label="Requested Delivery Date"
                                            text={purchaseOrder.requestedDeliveryDate ? moment(purchaseOrder.requestedDeliveryDate).format('DD/MM/YYYY') : 'ASAP'}
                                            disabled={purchaseOrder.expired}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <HybridTextField
                                            label="Estimated Delivery Date"
                                            text={purchaseOrder.estimatedDeliveryDate ? moment(purchaseOrder.estimatedDeliveryDate).format('DD/MM/YYYY') : ''}
                                            disabled={purchaseOrder.expired}
                                            green={!!purchaseOrder.estimatedDeliveryDate}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <PurchaseOrderItemsTable
                                            purchaseOrder={this.props.purchaseOrder}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <HybridTextField
                                            label="Special Instructions"
                                            text={purchaseOrder.specialInstructions}
                                            disabled={purchaseOrder.expired}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0}>
                                    <Grid item xs={3}>
                                        <HybridTextField
                                            label="Authorised By"
                                            text={purchaseOrder.authorisedByContactCode ? purchaseOrder.authorisedBy : 'Not Authorised'}
                                            disabled={purchaseOrder.expired}
                                            alert={!purchaseOrder.authorisedByContactCode}
                                            green={purchaseOrder.authorisedByContactCode}
                                            fieldStyle={!purchaseOrder.authorisedByContactCode ? {
                                                style: 'alert',
                                                tooltip: 'PO Must Be Authorised Before Sending',
                                            } : false}
                                        />
                                    </Grid>
                                    {purchaseOrder.authorisedByContactCode
                                        ? (
                                            <Grid item xs={3}>
                                                <HybridTextField
                                                    label="Authorised Date/Time"
                                                    text={moment(purchaseOrder.authorisedDateTime).format('DD/MM/YYYY HH:mm')}
                                                    disabled={purchaseOrder.expired}
                                                />
                                            </Grid>
                                        )
                                        : ''}
                                </Grid>
                                <Grid container spacing={0}>
                                    <Grid item xs={3}>
                                        <HybridTextField
                                            label="Sent By"
                                            text={purchaseOrder.sentByContactCode ? purchaseOrder.sentBy : 'Not Sent'}
                                            disabled={purchaseOrder.expired}
                                            alert={!purchaseOrder.sentByContactCode}
                                            green={purchaseOrder.sentByContactCode}
                                            fieldStyle={!purchaseOrder.sentByContactCode ? {
                                                style: 'alert',
                                                tooltip: 'PO Not Sent',
                                            } : false}
                                        />
                                    </Grid>
                                    {purchaseOrder.sentByContactCode
                                        ? (
                                            <Grid item xs={3}>
                                                <HybridTextField
                                                    label="Sent Date/Time"
                                                    text={moment(purchaseOrder.sentDateTime).format('DD/MM/YYYY HH:mm')}
                                                    disabled={purchaseOrder.expired}
                                                />
                                            </Grid>
                                        )
                                        : ''}
                                </Grid>

                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <HybridTextField
                                            label="Expired"
                                            text={purchaseOrder.expired ? 'Yes' : 'No'}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )
                        : (
                            <div className={classes.progressContainer}>
                                <CircularProgress className={classes.progress} variant="indeterminate" />
                            </div>
                        )}
                </Tile>

                <Dialog
                    open={showSendPurchaseOrderDialog}
                    onClose={this.handleCloseSendPurchaseOrderDialogClick}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={classes.newManufacturerDialog}
                >
                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.dialogContentContainer}>
                            <Typography variant="h6" gutterBottom>
                                Send Supplier Purchase Order
                            </Typography>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <TextField
                                        className={classes.formControl}
                                        name="emailAddress"
                                        id="emailAddress"
                                        onChange={this.updateSendPurchaseOrderData}
                                        value={sendPurchaseOrderData.emailAddress}
                                        label="Supplier Email Address"
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        error={!!errors.emailAddress}
                                        helperText={errors.emailAddress}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justify="center"
                            >
                                <Grid item xs={12}>
                                    <Button
                                        className={classes.dialogButton}
                                        type="submit"
                                        onClick={this.handleSendPurchaseOrder}
                                        disabled={disableSendPurchaseOrderButton || !sendPurchaseOrderData.emailAddress}
                                    >
                                        SEND
                                    </Button>
                                </Grid>
                            </Grid>

                        </div>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            onClick={this.handleCloseSendPurchaseOrderDialogClick}
                            color="primary"
                            autoFocus
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={showAuthorisePurchaseOrderDialog}
                    onClose={this.handleCloseAuthorisePurchaseOrderDialogClick}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={classes.newManufacturerDialog}
                >
                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.dialogContentContainer}>
                            <>
                                <Typography variant="h6" gutterBottom>
                                    Authorise Purchase Order
                                </Typography>
                                <DialogContentText className={classes.dialogText}>
                                    Are you sure you want to authorise this purchase order?
                                </DialogContentText>
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justify="center"
                                >
                                    <Grid item xs={12}>
                                        <Button
                                            className={classes.dialogButton}
                                            type="submit"
                                            onClick={this.handleAuthorisePurchaseOrder}
                                            disabled={disableAuthorisePurchaseOrderButton}
                                        >
                                            AUTHORISE
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            onClick={this.handleCloseAuthorisePurchaseOrderDialogClick}
                            color="primary"
                            autoFocus
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <PurchaseOrderHistory
                    // addNewHistoryNote={addNewHistoryNote}
                    purchaseOrder={purchaseOrder}
                    purchaseOrderHistoriesLoading={purchaseOrderHistoriesLoading}
                    fetchHistoriesData={fetchHistoriesData}
                    histories={purchaseOrder.histories}
                    errors={errors}
                />
                {/* <ManufacturerSupplierEditDialog
                    handleCloseEditManufacturerSupplierDialogClick={this.handleCloseEditManufacturerSupplierDialogClick}
                    purchaseOrder={purchaseOrder}
                    purchaseOrderLoading={purchaseOrderLoading}
                    showEditPurchaseOrderDialog={showEditPurchaseOrderDialog}
                    passNewManufacturerSupplier={this.props.passNewManufacturerSupplier}
                />
                <ManufacturerSupplierExpireDialog
                    handleCloseExpireManufacturerSupplierDialogClick={this.handleCloseExpireManufacturerSupplierDialogClick}
                    handleExpireManufacturerSupplierCloseDialogClick={this.handleExpireManufacturerSupplierCloseDialogClick}
                    purchaseOrder={purchaseOrder}
                    purchaseOrderLoading={purchaseOrderLoading}
                    passNewManufacturerSupplier={this.props.passNewManufacturerSupplier}
                    showExpireManufacturerSupplierDialog={showExpireManufacturerSupplierDialog}
                /> */}
            </>
        );
    }
}

export default withSnackbar(withStyles(styles)(PurchaseOrderDetails));
