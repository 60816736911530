import React from 'react';
import PropTypes from 'prop-types';
import {
    Collapse,
    Divider,
    Drawer,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import { withStyles } from '@material-ui/core/styles';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import StarBorder from '@material-ui/icons/StarBorder';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import orderBy from 'lodash/orderBy';
import fetch, { fetchJson } from '../../utilities/fetch';
import { version } from '../../version';

const drawerWidth = 250;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
        // backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
    },
    nested: {
        paddingLeft: theme.spacing.unit * 2,
    },
    menuTextParent: {
        color: 'rgba(0, 0, 0, 0.80)',
        fontWeight: '500',
    },
    menuTextChild: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: '500',
    },
    link: {
        // color: theme.palette.primary.contrastText,
        color: 'inherit',
        textDecoration: 'inherit',
    },
    currentPath: {
        backgroundColor: '#dadada',
    },
});


class MainMenu extends React.Component {
    state = {
        mobileOpen: false,
        open: false,
        mainMenu: [],
        testOpen: true,
        // drawerOpen: null,
    }

    componentDidMount() {
        fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/mainMenu')
            .then((mainMenu) => {
                this.setState({ mainMenu });
            });
    }

    handleClick = () => {
        this.setState(state => ({ open: !state.open }));
    }

    handleTestClick = () => {
        this.setState(state => ({ testOpen: !state.testOpen }));
    }

    render() {
        const {
            classes,
            drawerOpen,
        } = this.props;
        const {
            mainMenu,
            testOpen,
        } = this.state;

        const drawer = (
            <div>
                <div className={classes.drawerHeader}>
                    <IconButton>
                        <ChevronLeftIcon onClick={this.props.onCloseMenu} />
                    </IconButton>
                    <Typography>
                        Version
                        {' '}
                        {version}
                    </Typography>
                </div>
                <Divider />
                <List>
                    { mainMenu.length > 0 && orderBy(mainMenu, 'newOrder', 'asc').map((menuItem) => {
                        const isParent = mainMenu.filter(menuItemx => menuItemx.newOrder.substring(0, menuItem.newOrder.length + 1) === `${menuItem.newOrder}.`).length > 0;
                        const isChild = menuItem.newOrder.indexOf('.') !== -1;
                        const currentPath = window.location.pathname === menuItem.link;
                        // if (menuItem.newOrderCode !== '1') {
                            // console.log(window.location.pathname);
                            // console.log('menuItem', menuItem.title);
                            // console.log('isParent', isParent);
                            // console.log('isChild', isChild);
                            // console.log('>>', menuItem.newOrder.substring(0, menuItem.newOrder.length + 1));
                            // console.log('childQty', mainMenu.filter(menuItemx => menuItemx.newOrder.substring(0, menuItem.newOrder.length + 1) === `${menuItem.newOrder}.`).length);
                        // }
                        return (
                            <React.Fragment key={menuItem.title}>
                                <ListItem button key={menuItem.code}>
                                    <ListItemText
                                        className={currentPath ? classes.currentPath : ''}
                                        inset={isChild}
                                        disableTypography
                                        primary={(
                                            <Typography className={isParent ? classes.menuTextParent : classes.menuTextChild}>
                                                {!currentPath && menuItem.link
                                                    ? (
                                                        <Link
                                                            className={classes.link}
                                                            to={menuItem.link}
                                                            onClick={this.props.onMenuClick}
                                                        >
                                                            {menuItem.title}
                                                        </Link>
                                                    )
                                                    : (
                                                        <React.Fragment>
                                                            {menuItem.title}
                                                        </React.Fragment>
                                                    )
                                                }
                                            </Typography>
                                        )}
                                    />

                                {isParent ? <ExpandMore className={classes.menuTextParent} /> : null}
                                </ListItem>

                            </React.Fragment>
                        );
                    })}
                </List>
            </div>
        );

        return (
            <nav className={classes.drawer}>
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor="left"
                        open={drawerOpen}
                        onClose={this.state.handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                {/*                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                        >
                    {drawer}
                    </Drawer>
                </Hidden> */}
            </nav>
        );
    }
}

MainMenu.propTypes = {
    // Interal
    classes: PropTypes.object.isRequired,

    // External
    // leftTitle: PropTypes.string,
    // leftText: PropTypes.string,
    // rightTitle: PropTypes.string,
    // rightText: PropTypes.string,
};

// MainMenu.defaultProps = {
//     content: {},
// }

export default withStyles(styles)(MainMenu);
