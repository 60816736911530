import React from 'react';
import {
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import { uniqBy } from 'lodash';
import Tile from '../containers/tile';
import HybridTextField from './hybridTextField';
import numberWithCommas from '../utilities/numberWithCommas';
import commaFormattedDecimal from '../utilities/commaFormattedDecimal';
import titalise from '../utilities/titalise';
import HeatMap from './v2/heatMap';
import formatTimePeriod from '../utilities/formatTimePeriod';
import winsorized from '../utilities/winsorized';

const styles = (theme) => ({
    buttonBar: {
        marginBottom: theme.spacing.unit * -4,
    },
    fieldTitle: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        marginBottom: theme.spacing.unit * 1,
    },
    progress: {
        opacity: '.5',
    },
    progressContainer: {
        marginBottom: theme.spacing.unit * 3,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        width: 60,
    },
    paper: {
        height: 600,
        width: '100%',
        // height: 600,
        // width: 480,
    },
});

class ReportSummary extends React.Component {
    state = {
    };

    render() {
        const {
            branches,
            classes,
            criteria,
            groups,
            productTypes,
            reportData,
            reportSummary,
            reportSummaryLoading,
            reports,
            users,
            vulnerabilityTypes,
        } = this.props;

        // console.log('>>> criteria: ', criteria);
        // console.log('>>> users: ', users);
        let summaryContent;
        let options;
        let summaryReportData;
        let currentCreatedBy;
        let totalLeadsQuantity;
        let payments;
        let paymentsQty;
        let paymentsAmount;

        if (!reportSummaryLoading && !isEmpty(reportSummary)) {
            const currentReport = reports.find((report) => report.name === criteria.report);
            switch (criteria.report) {
            case 'furnitureLeads':
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HybridTextField label="Report Type" text={currentReport.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Description" text="Overview of Sofa and Bed Leads" />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                                </Grid>
                                <Grid item xs={2}>
                                    <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Referred Quantity" text={numberWithCommas(reportSummary.referredQty)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Declined Quantity" text={numberWithCommas(reportSummary.declinedQty)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="New Quantity" text={numberWithCommas(reportSummary.newQty)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Open Quantity" text={numberWithCommas(reportSummary.openQty)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Converted Quantity" text={numberWithCommas(reportSummary.convertedQty)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Total Lead Quantity" text={numberWithCommas(reportSummary.totalRecordsFound)} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );
                break;
            case 'externalDebtRecoveryPayments':
                payments = reportData;
                paymentsQty = payments.length;
                paymentsAmount = payments.reduce((acc, payment) => (acc + payment.amount), 0);
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HybridTextField label="Report Type" text={currentReport.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Description" text="Authorised payments following Connected or Voicemail call from External Debt Agent within 7 days" />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Payments Qty" text={paymentsQty} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Payments Amount" text={`£${(paymentsAmount).toFixed(2)}`} />
                        </Grid>
                    </Grid>
                );
                break;
            case 'debtRecoveryPayments':
                payments = reportData;
                paymentsQty = payments.length;
                paymentsAmount = payments.reduce((acc, payment) => (acc + payment.amount), 0);
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HybridTextField label="Report Type" text={currentReport.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Description" text="Authorised payments initiated by Internal Debt Team" />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Payments Qty" text={paymentsQty} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Payments Amount" text={`£${(paymentsAmount).toFixed(2)}`} />
                        </Grid>
                    </Grid>
                );
                break;
            case 'selfMeasurementChecks':
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HybridTextField label="Report Type" text={currentReport.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Description" text="Self-Measure Agreements Awaiting Checking" />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Agreements Qty" text={reportSummary.totalRecordsFound} />
                        </Grid>
                    </Grid>
                );
                break;
            case 'installationIncludedAgreements':
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HybridTextField label="Report Type" text={currentReport.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Description" text="Active and Complete Agreements flagged as Installation Included" />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Agreements Quantity" text={reportSummary.totalRecordsFound} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Total Agreement Amount" text={`£${numberWithCommas((reportSummary.totalAgreementAmount || 0).toFixed(2))}`} />
                        </Grid>
                    </Grid>
                );
                break;
            case 'fastTrackSalesAgreements':
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HybridTextField label="Report Type" text={currentReport.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Description" text="Active and Complete Agreements flagged as Fast Track" />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Agreements Quantity" text={reportSummary.totalRecordsFound} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Total Agreement Amount" text={`£${numberWithCommas((reportSummary.totalAgreementAmount || 0).toFixed(2))}`} />
                        </Grid>
                    </Grid>
                );
                break;
            case 'leadsByCampaign':
                totalLeadsQuantity = reportSummary.reduce((acc, campaign) => (acc + campaign.quantity), 0);
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HybridTextField label="Report Type" text={currentReport.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Description" text="Summary of Leads Created By A Campaign Grouped By Source" />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                                </Grid>
                                <Grid item xs={2}>
                                    <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                                </Grid>
                            </Grid>
                        </Grid>
                        {reportSummary.map((campaign) => (
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={4}>
                                        <HybridTextField label="Campaign" text={campaign.campaign} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <HybridTextField label="Quantity" text={`${numberWithCommas(campaign.quantity)}`} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <HybridTextField label="Click-Through Qty" text={`${numberWithCommas(campaign.clickThroughQty)}`} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <HybridTextField label="Total Campaign Leads" text={numberWithCommas(totalLeadsQuantity)} />
                        </Grid>
                    </Grid>
                );
                break;
            case 'salesAgentLeadsResponse':
                const leads = reportData;
                const leadsQty = leads.length;
                const firstInboundSmsResponseLeads = leads.filter((lead) => lead.firstInboundSmsResponse);
                const firstInboundSmsResponsePercent = (firstInboundSmsResponseLeads.length / leadsQty) * 100;
                const firstInboundSmsResponseQty = firstInboundSmsResponseLeads.length;

                const firstInboundSmsResponseTimePeriods = firstInboundSmsResponseLeads.map((lead) => (lead.firstInboundSmsResponse));
                const winsorizedFirstInboundSmsResponseTimePeriods = winsorized(firstInboundSmsResponseTimePeriods, 5);
                const totalWinsorizedFirstInboundSmsResponse = winsorizedFirstInboundSmsResponseTimePeriods.reduce((acc, timePeriod) => (acc + timePeriod), 0);
                const averageWinsorizedFirstContactResponse = totalWinsorizedFirstInboundSmsResponse / firstInboundSmsResponseLeads.length;

                const appointmentsScheduledQty = leads.filter((lead) => lead.state === 'APPOINTMENT_SCHEDULED' || lead.state === 'CONVERTED').length;
                const appointmentsScheduledPercent = (appointmentsScheduledQty / leadsQty) * 100;
                const totalFirstInboundSmsResponse = firstInboundSmsResponseLeads.reduce((acc, lead) => (acc + lead.firstInboundSmsResponse), 0);
                const averageFirstContactResponse = totalFirstInboundSmsResponse / firstInboundSmsResponseLeads.length;

                const firstInboundSmsResponseInHoursLeads = leads.filter((lead) => lead.firstInboundSmsResponse && !lead.firstInboundSmsOutOfHours);
                const firstInboundSmsResponseInHoursQty = firstInboundSmsResponseInHoursLeads.length;

                const totalFirstAgentResponseInHoursTime = firstInboundSmsResponseInHoursLeads.reduce((acc, lead) => (acc + lead.firstAgentResponseTime), 0);
                const averageFirstAgentResponseInHoursTime = totalFirstAgentResponseInHoursTime / firstInboundSmsResponseInHoursQty;

                const totalFirstAgentResponseTime = firstInboundSmsResponseLeads.reduce((acc, lead) => (acc + lead.firstAgentResponseTime), 0);
                const averageFirstAgentResponseTime = totalFirstAgentResponseTime / firstInboundSmsResponseLeads.length;

                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HybridTextField label="Report Type" text={currentReport.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Description" text="Response data from leads which use AutoSMS1 initial text message." />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                                </Grid>
                                <Grid item xs={2}>
                                    <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Leads Qty" text={leadsQty} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Initial Response" text={`${firstInboundSmsResponsePercent.toFixed(0)}% (${firstInboundSmsResponseQty})`} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Appointments Scheduled" text={`${appointmentsScheduledPercent.toFixed(0)}% (${appointmentsScheduledQty})`} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Avg First Contact Response" text={`${formatTimePeriod(averageWinsorizedFirstContactResponse)} (${formatTimePeriod(averageFirstContactResponse)})`} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Avg First Agent Response (all hours)" text={`${formatTimePeriod(averageFirstAgentResponseTime)}`} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Avg First Agent Response (in hours)" text={`${formatTimePeriod(averageFirstAgentResponseInHoursTime)}`} />
                        </Grid>
                    </Grid>
                );
                break;
            case 'externalDebtRecoveryCalls':
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HybridTextField label="Report Type" text={currentReport.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Description" text="Overview of External Debt Recovery Call Activity" />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                                </Grid>
                                <Grid item xs={2}>
                                    <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Unanswered Quantity" text={numberWithCommas(reportSummary.unansweredQty)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Unobtainable Quantity" text={numberWithCommas(reportSummary.unobtainableQty)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Engaged Quantity" text={numberWithCommas(reportSummary.engagedQty)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Voicemail Quantity" text={numberWithCommas(reportSummary.voicemailQty)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Connected Quantity" text={numberWithCommas(reportSummary.connectedQty)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Total Call Quantity" text={numberWithCommas((reportSummary.unansweredQty + reportSummary.unobtainableQty + reportSummary.engagedQty + reportSummary.voicemailQty + reportSummary.connectedQty).toString())} />
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                );
                break;
            case 'salesByPostcodeDistrict':
                options = {
                    radius: 17,
                    opacity: 0.6,
                    dissipating: true,
                    maxIntensity: 15,
                };
                summaryReportData = {
                    positions: reportSummary.positions,
                    options,
                };
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={7}>
                            <Grid item xs={12}>
                                <HybridTextField label="Report Type" text={currentReport.description} />
                            </Grid>
                            <Grid item xs={12}>
                                <HybridTextField label="Description" text="New agent Agreements grouped by postcode district" />
                            </Grid>
                            <Grid item xs={12}>
                                <HybridTextField label="Branch" text={criteria.branchCode === 'null' ? 'All' : branches.find((branch) => branch.code === criteria.branchCode).name} />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={2}>
                                        <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound)} />
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Paper className={classes.paper}>
                                <HeatMap
                                    reportData={summaryReportData}
                                    reportDataLoading={reportSummaryLoading}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                );
                break;
            case 'leadsByPostcode':
                options = {
                    radius: 17,
                    opacity: 0.6,
                    dissipating: true,
                    maxIntensity: 15,
                };
                summaryReportData = {
                    positions: reportSummary.positions,
                    options,
                };
                currentCreatedBy = criteria.createdByContactCode === 'null' ? null : users.find((user) => user.contactCode === criteria.createdByContactCode).contact;
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={7}>

                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <HybridTextField label="Report Type" text={currentReport.description} />
                                </Grid>
                                <Grid item xs={12}>
                                    <HybridTextField label="Description" text="Leads by Postcode" />
                                </Grid>
                                <Grid item xs={12}>
                                    <HybridTextField label="Postcode" text={criteria.postcode === 'null' ? 'All' : criteria.postcode} />
                                </Grid>
                                <Grid item xs={12}>
                                    <HybridTextField label="Product Type" text={(!criteria.productTypeCode || criteria.productTypeCode === 'null') ? 'All' : productTypes.find((productType) => productType.code === criteria.productTypeCode).description} />
                                </Grid>
                                <Grid item xs={12}>
                                    <HybridTextField label="Created By" text={criteria.createdByContactCode === 'null' ? 'All' : `${currentCreatedBy.firstname} ${currentCreatedBy.surname}`} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={2}>
                                            <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound || 0)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Paper className={classes.paper}>
                                <HeatMap
                                    reportData={summaryReportData}
                                    reportDataLoading={reportSummaryLoading}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                );
                break;
            case 'fastTrackLeads':
                options = {
                    radius: 17,
                    opacity: 0.6,
                    dissipating: true,
                    maxIntensity: 15,
                };
                summaryReportData = {
                    positions: reportSummary.positions,
                    options,
                };
                currentCreatedBy = criteria.createdByContactCode === 'null' ? null : users.find((user) => user.contactCode === criteria.createdByContactCode).contact;
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={7}>

                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <HybridTextField label="Report Type" text={currentReport.description} />
                                </Grid>
                                <Grid item xs={12}>
                                    <HybridTextField label="Description" text="Current Fast Track Leads" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={2}>
                                            <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound || 0)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Paper className={classes.paper}>
                                <HeatMap
                                    reportData={summaryReportData}
                                    reportDataLoading={reportSummaryLoading}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                );
                break;
            case 'manufacturedItems':
                currentCreatedBy = criteria.createdByContactCode === 'null' ? null : users.find((user) => user.contactCode === criteria.createdByContactCode).contact;
                const currentGroupName = criteria.groupCode === 'null' ? null : groups.find((group) => group.code === criteria.groupCode).name;
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HybridTextField label="Report Type" text={currentReport.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Description" text="Items created by company staff." />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Group" text={(!criteria.groupCode || criteria.groupCode === 'null') ? 'All' : currentGroupName} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Product Type" text={(!criteria.productTypeCode || criteria.productTypeCode === 'null') ? 'All' : productTypes.find((productType) => productType.code === criteria.productTypeCode).description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Created By" text={criteria.createdByContactCode === 'null' ? 'All' : `${currentCreatedBy.firstname} ${currentCreatedBy.surname}`} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                                </Grid>
                                <Grid item xs={2}>
                                    <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound || 0)} />
                        </Grid>
                    </Grid>
                );
                break;
            case 'complaints':
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HybridTextField label="Report Type" text={currentReport.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Description" text="Customer complaints." />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Complaint Type" text={(!criteria.type || criteria.type === 'null') ? 'All' : titalise(criteria.type.replace('_', ' '))} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Average Days Since Complaint" text={reportSummary.averageDaysSinceComplaint} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Branch" text={criteria.branchCode === 'null' ? 'All' : branches.find((branch) => branch.code === criteria.branchCode).name} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Assigned To" text={criteria.assignedToContactCode === 'null' ? 'All' : reportSummary.assignedTo} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound || 0)} />
                        </Grid>
                    </Grid>
                );
                break;
            case 'newAgreements':
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HybridTextField label="Report Type" text={currentReport.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Description" text="New ACTIVE Agreements." />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Product Type" text={(!criteria.productTypeCode || criteria.productTypeCode === 'null') ? 'All' : productTypes.find((productType) => productType.code === criteria.productTypeCode).description} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                                </Grid>
                                <Grid item xs={2}>
                                    <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound)} />
                        </Grid>
                    </Grid>
                );
                break;
            case 'deliveredAgreementIncomeToDate':
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HybridTextField label="Report Type" text={currentReport.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Description" text="Income to date from Agreements delivered during requested period. Excludes Incomplete and Cancelled Status." />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Product Type" text={(!criteria.productTypeCode || criteria.productTypeCode === 'null') ? 'All' : productTypes.find((productType) => productType.code === criteria.productTypeCode).description} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                                </Grid>
                                <Grid item xs={2}>
                                    <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                                </Grid>
                            </Grid>
                        </Grid>
                        {reportSummary.map((productType) => (
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={2}>
                                        <HybridTextField label={`${productType.productType} Quantity`} text={numberWithCommas(productType.qty)} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <HybridTextField label={`Total ${productType.productType} Amount`} text={`£${numberWithCommas(productType.planAmount.toFixed(2))}`} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <HybridTextField label={`${productType.productType} Due Amount`} text={`£${numberWithCommas(productType.duePaymentsAmount.toFixed(2))}`} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <HybridTextField label={`${productType.productType} Paid Amount`} text={`£${numberWithCommas(productType.paidAmount.toFixed(2))}`} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <HybridTextField label={`${productType.productType} Paid Amount %`} text={`${Math.round(productType.paidAmount / (productType.duePaymentsAmount / 100))}%`} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.reduce((acc, b) => acc + b.qty, 0))} />
                        </Grid>
                    </Grid>
                );
                break;
            case 'agreementIncomeToDate':
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HybridTextField label="Report Type" text={currentReport.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Description" text="Income to date from Agreements created during requested period. Excludes Incomplete and Cancelled Status." />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Product Type" text={(!criteria.productTypeCode || criteria.productTypeCode === 'null') ? 'All' : productTypes.find((productType) => productType.code === criteria.productTypeCode).description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Furniture Delivery" text={(!criteria.fullyDelivered || criteria.fullyDelivered === 'null') ? 'All' : criteria.fullyDelivered ? 'Fully Delivered' : 'Not Fully Delivered'} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                                </Grid>
                                <Grid item xs={2}>
                                    <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                                </Grid>
                            </Grid>
                        </Grid>
                        {reportSummary.map((productType) => (
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={2}>
                                        <HybridTextField label={`${productType.productType} Quantity`} text={numberWithCommas(productType.qty)} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <HybridTextField label={`Total ${productType.productType} Amount`} text={`£${numberWithCommas(productType.planAmount.toFixed(2))}`} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <HybridTextField label={`${productType.productType} Due Amount`} text={`£${numberWithCommas(productType.duePaymentsAmount.toFixed(2))}`} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <HybridTextField label={`${productType.productType} Paid Amount`} text={`£${numberWithCommas(productType.paidAmount.toFixed(2))}`} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <HybridTextField label={`${productType.productType} Paid Amount %`} text={`${Math.round(productType.paidAmount / (productType.duePaymentsAmount / 100))}%`} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.reduce((acc, b) => acc + b.qty, 0))} />
                        </Grid>
                    </Grid>
                );
                break;
            case 'referredLeads':
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HybridTextField label="Report Type" text={currentReport.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Description" text="Leads with Status 'REFERRED'" />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Product Type" text={(!criteria.productTypeCode || criteria.productTypeCode === 'null') ? 'All' : productTypes.find((productType) => productType.code === criteria.productTypeCode).description} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                                </Grid>
                                <Grid item xs={2}>
                                    <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound)} />
                        </Grid>
                    </Grid>
                );
                break;
            case 'contactVulnerabilities':
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HybridTextField label="Report Type" text={currentReport.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Description" text="Contacts with active vulnerability" />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Vulnerability Type" text={(!criteria.vulnerabilityTypeCode || criteria.vulnerabilityTypeCode === 'null') ? 'All' : vulnerabilityTypes.find((vulnerabilityType) => vulnerabilityType.code === criteria.vulnerabilityTypeCode).name} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                                </Grid>
                                <Grid item xs={2}>
                                    <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound)} />
                        </Grid>
                    </Grid>
                );
                break;
            case 'sales':
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HybridTextField label="Report Type" text={currentReport.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Description" text="Sales with Status ACTIVE or COMPLETED" />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Product Type" text={(!criteria.productTypeCode || criteria.productTypeCode === 'null') ? 'All' : productTypes.find((productType) => productType.code === criteria.productTypeCode).description} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                                </Grid>
                                <Grid item xs={2}>
                                    <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound)} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Total Amount" text={`£${numberWithCommas(reportSummary.totalAmount.toFixed(2))}`} />
                        </Grid>
                    </Grid>
                );
                break;
            case 'stockBirth':
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HybridTextField label="Report Type" text={currentReport.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Description" text="Stock items created from parent item." />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Branch" text={criteria.branchCode === 'null' ? 'All' : branches.find((branch) => branch.code === criteria.branchCode).name} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                                </Grid>
                                <Grid item xs={2}>
                                    <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound)} />
                        </Grid>
                    </Grid>
                );
                break;
            case 'paymentManualLinkStatus':
                summaryContent = (
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <HybridTextField label="Report Type" text={currentReport.description} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Description" text="Link and Manual payment result status." />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Branch" text={criteria.branchCode === 'null' ? 'All' : branches.find((branch) => branch.code === criteria.branchCode).name} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                                </Grid>
                                <Grid item xs={2}>
                                    <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Authorised" text={numberWithCommas(reportSummary.authorised)} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Declined" text={numberWithCommas(reportSummary.declined)} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Failed" text={numberWithCommas(reportSummary.failed)} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Cancelled" text={numberWithCommas(reportSummary.cancelled)} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Error" text={numberWithCommas(reportSummary.error)} />
                        </Grid>
                        <Grid item xs={12}>
                            <HybridTextField label="Pending" text={numberWithCommas(reportSummary.pending)} />
                        </Grid>
                        {/* <Grid item xs={12}>
                                <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound)} />
                            </Grid> */}
                    </Grid>
                );
                break;
            case 'agreementItemsVisitUnbooked': summaryContent = (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <HybridTextField label="Report Type" text={currentReport.description} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Description" text="Agreement with items that are not booked for delivery/installation." />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Branch" text={criteria.branchCode === 'null' ? 'All' : branches.find((branch) => branch.code === criteria.branchCode).name} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Product Type" text={criteria.productType === 'null' ? 'All' : titalise(productTypes.find((productType) => productType.code === criteria.productTypeCode).description)} />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                            </Grid>
                            <Grid item xs={2}>
                                <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound)} />
                    </Grid>
                </Grid>
            );
                break;
            case 'visits': summaryContent = (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <HybridTextField label="Report Type" text={currentReport.description} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Description" text="Record of visits." />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Branch" text={criteria.branchCode === 'null' ? 'All' : branches.find((branch) => branch.code === criteria.branchCode).name} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Status" text={criteria.visitStatus === 'null' ? 'All' : titalise(criteria.visitStatus)} />
                    </Grid>
                    {criteria.visitStatus !== 'REBOOK' && criteria.visitStatus !== 'INVITE_EXPIRED'
                        ? (
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={2}>
                                        <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                        : ''}
                    <Grid item xs={12}>
                        <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound)} />
                    </Grid>
                </Grid>
            );
                break;
            case 'salesAgentPostcodeAreas': summaryContent = (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <HybridTextField label="Report Type" text={currentReport.description} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Description" text="Postcode areas and districts assigned to sales agents." />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Postcode Areas/Districts" text={numberWithCommas(reportSummary.postcodeDistrictQty)} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Sales Agents" text={numberWithCommas(reportSummary.salesAgentQty)} />
                    </Grid>
                </Grid>
            );
                break;
                // case 'salesAgentSales': summaryContent = (
                //     <Grid container spacing={0}>
                //         <Grid item xs={12}>
                //             <HybridTextField label="Report Type" text={currentReport.description} />
                //         </Grid>
                //         <Grid item xs={12}>
                //             <HybridTextField label="Description" text="Overview of Sales Agent sales, using Portal 2 Mobile, activity." />
                //         </Grid>
                //         <Grid item xs={12}>
                //             <HybridTextField label="Branch" text={reportSummary[0].branch === null ? 'All' : reportSummary[0].branch} />
                //         </Grid>
                //         <Grid item xs={12}>
                //             <Grid container spacing={0}>
                //                 <Grid item xs={2}>
                //                     <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                //                 </Grid>
                //                 <Grid item xs={2}>
                //                     <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                //                 </Grid>
                //             </Grid>
                //         </Grid>
                //         {reportSummary.map((productType) => (
                //             <Grid item xs={12}>
                //                 <Grid container spacing={0}>
                //                     <Grid item xs={2}>
                //                         <HybridTextField label={`${productType.productType} Quantity`} text={numberWithCommas(productType.qty)} />
                //                     </Grid>
                //                     <Grid item xs={2}>
                //                         <HybridTextField label={`${productType.productType} Amount`} text={`£${numberWithCommas(productType.totalAmount.toFixed(2))}`} />
                //                     </Grid>
                //                 </Grid>
                //             </Grid>
                //         ))}
                //         <Grid item xs={12}>
                //             <Grid container spacing={0}>
                //                 <Grid item xs={2}>
                //                     <HybridTextField label="Total Quantity" text={numberWithCommas(reportSummary.reduce((acc, b) => acc + b.qty, 0))} />
                //                 </Grid>
                //                 <Grid item xs={2}>
                //                     <HybridTextField label="Total Amount" text={`£${numberWithCommas(reportSummary.reduce((acc, b) => acc + b.totalAmount, 0).toFixed(2))}`} />
                //                 </Grid>
                //             </Grid>
                //         </Grid>

            //     </Grid>
            // );
            //     break;
            case 'salesAgentSales': summaryContent = (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <HybridTextField label="Report Type" text={currentReport.description} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Description" text="Overview of Sales Agent sales, using Portal 2 Mobile, activity." />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Branch" text={reportSummary[0].branch === null ? 'All' : reportSummary[0].branch} />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                            </Grid>
                            <Grid item xs={2}>
                                <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                            </Grid>
                        </Grid>
                    </Grid>
                    {reportSummary.map((productType) => (
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <HybridTextField label={`${productType.productType} Quantity`} text={numberWithCommas(productType.qty)} />
                                </Grid>
                                <Grid item xs={2}>
                                    <HybridTextField label={`${productType.productType} Amount`} text={`£${numberWithCommas(productType.totalAmount.toFixed(2))}`} />
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <HybridTextField label="Total Quantity" text={numberWithCommas(reportSummary.reduce((acc, b) => acc + b.qty, 0))} />
                            </Grid>
                            <Grid item xs={2}>
                                <HybridTextField label="Total Amount" text={`£${numberWithCommas(reportSummary.reduce((acc, b) => acc + b.totalAmount, 0).toFixed(2))}`} />
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            );
                break;
            case 'incompleteAgreementWithActivePlan': summaryContent = (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <HybridTextField label="Report Type" text={currentReport.description} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Description" text="Agreements with status Incomplete and payment plan status Active." />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                            </Grid>
                            <Grid item xs={2}>
                                <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound)} />
                    </Grid>
                </Grid>
            );
                break;
            case 'undeliveredBlindAgreements': summaryContent = (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <HybridTextField label="Report Type" text={currentReport.description} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Description" text="Blind agreements which are not fully delivered." />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                            </Grid>
                            <Grid item xs={2}>
                                <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound)} />
                    </Grid>
                </Grid>
            );
                break;
            // case 'salesAgentAppointments': summaryContent = (
            //     <Grid container spacing={0}>
            //         <Grid item xs={12}>
            //             <HybridTextField label="Report Type" text={currentReport.description} />
            //         </Grid>
            //         <Grid item xs={12}>
            //             <HybridTextField label="Description" text="Overview of sales agents appointments." />
            //         </Grid>
            //         <Grid item xs={12}>
            //             <Grid container spacing={0}>
            //                 <Grid item xs={2}>
            //                     <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
            //                 </Grid>
            //                 <Grid item xs={2}>
            //                     <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
            //                 </Grid>
            //             </Grid>
            //         </Grid>
            //         <Grid item xs={12}>
            //             <Grid container spacing={0}>
            //                 <Grid item xs={2}>
            //                     <HybridTextField label="Total New Leads" text={numberWithCommas(reportSummary.totalNew)} />
            //                 </Grid>
            //                 <Grid item xs={2}>
            //                     <HybridTextField label="Total New Website Leads" text={numberWithCommas(reportSummary.totalNewWebsite)} />
            //                 </Grid>
            //             </Grid>
            //         </Grid>
            //         <Grid item xs={12}>
            //             <Grid container spacing={0}>
            //                 <Grid item xs={2}>
            //                     <HybridTextField label="Total Converted" text={numberWithCommas(reportSummary.totalConverted)} />
            //                 </Grid>
            //                 <Grid item xs={2}>
            //                     <HybridTextField label="Total Amount" text={`£${numberWithCommas(reportSummary.convertedAmount.toFixed(2))}`} />
            //                 </Grid>
            //             </Grid>
            //         </Grid>
            //         <Grid item xs={12}>
            //             <Grid container spacing={0}>
            //                 <Grid item xs={2}>
            //                     <HybridTextField label="Flooring Converted" text={numberWithCommas(reportSummary.flooringConverted)} />
            //                 </Grid>
            //                 <Grid item xs={2}>
            //                     <HybridTextField label="Flooring Amount" text={`£${numberWithCommas(reportSummary.flooringAmount.toFixed(2))}`} />
            //                 </Grid>
            //             </Grid>
            //         </Grid>
            //         <Grid item xs={12}>
            //             <Grid container spacing={0}>
            //                 <Grid item xs={2}>
            //                     <HybridTextField label="Blinds Converted" text={numberWithCommas(reportSummary.blindsConverted)} />
            //                 </Grid>
            //                 <Grid item xs={2}>
            //                     <HybridTextField label="Blinds Amount" text={`£${numberWithCommas(reportSummary.blindsAmount.toFixed(2))}`} />
            //                 </Grid>
            //             </Grid>
            //         </Grid>
            //     </Grid>
            // );
            //     break;
            case 'salesAgentLeads': summaryContent = (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <HybridTextField label="Report Type" text={currentReport.description} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Description" text="Overview of sales agents, using Portal 2 Mobile, activity." />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                            </Grid>
                            <Grid item xs={2}>
                                <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <HybridTextField label="Total New Leads" text={numberWithCommas(reportSummary.totalNew)} />
                            </Grid>
                            <Grid item xs={2}>
                                <HybridTextField label="Total New Website Leads" text={numberWithCommas(reportSummary.totalNewWebsite)} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <HybridTextField label="Total Converted" text={numberWithCommas(reportSummary.totalConverted)} />
                            </Grid>
                            <Grid item xs={2}>
                                <HybridTextField label="Total Amount" text={`£${numberWithCommas(reportSummary.convertedAmount.toFixed(2))}`} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <HybridTextField label="Flooring Converted" text={numberWithCommas(reportSummary.flooringConverted)} />
                            </Grid>
                            <Grid item xs={2}>
                                <HybridTextField label="Flooring Amount" text={`£${numberWithCommas(reportSummary.flooringAmount.toFixed(2))}`} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <HybridTextField label="Blinds Converted" text={numberWithCommas(reportSummary.blindsConverted)} />
                            </Grid>
                            <Grid item xs={2}>
                                <HybridTextField label="Blinds Amount" text={`£${numberWithCommas(reportSummary.blindsAmount.toFixed(2))}`} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
                break;
            case 'vat': summaryContent = (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <HybridTextField label="Report Type" text={currentReport.description} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Description" text="All authorised Adelante transactions including recurring, manual, refund & manual corrections." />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                            </Grid>
                            <Grid item xs={2}>
                                <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound)} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Total Amount" text={`£${commaFormattedDecimal(reportSummary.totalAmount.toFixed(2))}`} />
                    </Grid>
                </Grid>
            );
                break;
            case 'paymentCardExpireSoon': summaryContent = (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <HybridTextField label="Report Type" text={currentReport.description} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Description" text="All active agreements with payment cards due to expire this, or next, calendar month." />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound)} />
                    </Grid>
                </Grid>
            );
                break;
            case 'agreementWithoutCard': summaryContent = (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <HybridTextField label="Report Type" text={currentReport.description} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Description" text="All active agreements with no assigned payment card." />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound)} />
                    </Grid>
                </Grid>
            );
                break;
            case 'newAgreementProducts': summaryContent = (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <HybridTextField label="Report Type" text={currentReport.description} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Description" text="Products added to new agreements that are currently active." />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <HybridTextField label="Product Type" text={reportSummary.productType} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <HybridTextField label="Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                            </Grid>
                            <Grid item xs={2}>
                                <HybridTextField label="End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound)} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Total Products Added" text={reportSummary.totalProductsAdded} />
                    </Grid>
                </Grid>
            );
                break;
            case 'stock': summaryContent = (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <HybridTextField label="Report Type" text={currentReport.description} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Description" text="Items located within the selected branch/s." />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Items Found" text={numberWithCommas(reportSummary.totalQuantity)} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Total Cost Price" text={`£${commaFormattedDecimal(reportSummary.totalAmount.toFixed(2))}`} />
                    </Grid>
                </Grid>
            );
                break;
            case 'debtors': summaryContent = (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <HybridTextField label="Report Type" text={currentReport.description} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Description" text="Balance and payment details for all recurring agreements." />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <HybridTextField label="At Date" text={moment(criteria.atDate).format('DD-MM-YYYY')} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound)} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Total Opening Balance" text={`£${commaFormattedDecimal(reportSummary.TotalOpeningBalance.toFixed(2))}`} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Total Current Balance" text={`£${commaFormattedDecimal(reportSummary.TotalAgreementBalance.toFixed(2))}`} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Total Payments At Date" text={`£${commaFormattedDecimal(reportSummary.TotalPaymentsAtDate.toFixed(2))}`} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Total Delivery Amount" text={`£${commaFormattedDecimal(reportSummary.TotalDeliveryAmount.toFixed(2))}`} />
                    </Grid>
                </Grid>
            );
                break;
            case 'paymentDeclined': summaryContent = (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <HybridTextField label="Report Type" text={currentReport.description} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Description" text="Continuous and Repayment agreement payments (Recurring and Retry) that where recently Declined." />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <HybridTextField label="At Date" text={moment(reportSummary.atDate).format('DD-MM-YYYY')} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <HybridTextField label="Product Type" text={reportSummary.productType} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound)} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Total Declined Payments" text={`£${commaFormattedDecimal(reportSummary.TotalPaymentAmount.toFixed(2))}`} />
                    </Grid>
                </Grid>
            );
                break;
            case 'portal1-DeferredDebt(Audit)': summaryContent = (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <HybridTextField label="Report Type" text={currentReport.description} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Description" text="Portal 1  - As requested." />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <HybridTextField label="Agreement Created Start Date" text={moment(criteria.startDate).format('DD-MM-YYYY')} />
                            </Grid>
                            <Grid item xs={2}>
                                <HybridTextField label="Agreement Created End Date" text={moment(criteria.endDate).format('DD-MM-YYYY')} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Records Found" text={numberWithCommas(reportSummary.totalRecordsFound)} />
                    </Grid>
                    <Grid item xs={12}>
                        <HybridTextField label="Total Agreement Opening Balance" text={`£${commaFormattedDecimal(reportSummary.totalAgreementOpeningBalance.toFixed(2))}`} />
                    </Grid>
                </Grid>
            );
                break;
            default: summaryContent = '';
            }
        }
        return (
            <>
                <Grid container spacing={0} className={classes.buttonBar}>
                    <Grid container item xs={12} alignItems="flex-start" justify="flex-end" direction="row">
                        <IconButton
                            onClick={() => this.props.openCriteriaDialog()}
                        >
                            <AddIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Tile
                    tileTitle="SUMMARY"
                    style
                >
                    { reportSummaryLoading
                        ? (
                            <div className={classes.progressContainer}>
                                <CircularProgress className={classes.progress} variant="indeterminate" />
                            </div>
                        )
                        : (
                            <>
                                { isEmpty(reportSummary)
                                    ? (
                                        <Typography>
                                            No report data
                                        </Typography>
                                    )
                                    : (
                                        <>
                                            { summaryContent }
                                        </>
                                    )}
                            </>
                        )}
                </Tile>
            </>
        );
    }
}

ReportSummary.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReportSummary);
