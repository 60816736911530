import React from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

import fetch from '../utilities/fetch';
import sleep from '../utilities/sleep';

const styles = theme => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    divider: {
        marginTop: theme.spacing.unit * 2,
        // marginBottom: theme.spacing.unit * 2,
    },
    bodyContainer: {
        minHeight: 100,
    },
    bottomBarContainer: {
        width: '100%',
        textAlign: 'right',
    },
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    dialogContentContainer: {
        // marginTop: theme.spacing.unit * 2,
        // marginBottom: theme.spacing.unit * 2,
        minWidth: 600,
        minHeight: 269,
        paddingBottom: 0,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
        marginRight: theme.spacing.unit * 2,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 2,
        // marginBottom: theme.spacing.unit * 2,
    },
    dialogActions: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
    },
    progress: {
        margin: theme.spacing.unit * 3,
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
    },
});

class ItemLocation extends React.Component {
    state = {
        error: false,
        loading: false,
        snackbarOpen: false,
        showDialog: false,
        branches: [],
        selectedBranchCode: '',
        saved: false,
        errors: {},
    }

    componentDidMount() {
        this.fetchBranchesData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.itemBranchCode !== prevProps.itemBranchCode) {
            this.setState({
                selectedBranchCode: this.props.itemBranchCode,
                branchCode: this.props.itemBranchCode,
            });
        }
    }

    handleUpdateBranchCode = async (event) => {
        this.setState({
            selectedBranchCode: event.target.value,
        });
    }

    handleUpdateClick = async () => {
        const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/item/${this.props.itemCode}`);
        const data = await fetch(url, {
            method: 'PATCH',
            body: JSON.stringify({
                branchCode: this.state.selectedBranchCode,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        this.setState({
            branchCode: this.state.selectedBranchCode,
            showDialog: false,
        });
    }

    handleEditBranchClick = async () => {
        this.setState({
            showDialog: true,
        });
    }

    handleCloseDialogClick = () => {
        this.setState({
            showDialog: false,
        });
    }

    async fetchBranchesData() {
        const url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/branches/');
        const data = await fetch(url).then(response => response.json());
        console.log(data);
        this.setState({
            branches: data,
        });
    }

    render() {
        const { classes, branchCode } = this.props;
        const {
            selectedBranchCode,
            branches,
            showDialog,
            errors,
            loadingStep,
        } = this.state;

        const currentBranch = branches.find((branch) => branch.code === this.state.branchCode) || {};

        return (
            <React.Fragment>
                <div className={classes.bodyContainer}>
                    <Typography>
                    branch:
                        {' '}
                        {currentBranch.name}
                        <br />
                    </Typography>
                    <Divider className={classes.divider} />
                </div>
                <div className={classes.bottomBarContainer}>
                    <Button
                        color="default"
                        className={classes.textButton}
                        onClick={this.handleEditBranchClick}
                    >
                    Edit Item Location
                    </Button>
                </div>
                <Dialog
                    open={showDialog}
                    onClose={this.handleClosePaymentDetailClick}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={classes.dialogContentContainer}>
                        <div>
                            <React.Fragment>
                                <Typography variant="h6" gutterBottom>
                                    Edit Item Location
                                </Typography>
                                <DialogContentText>
                                        Select a new Item Location below and click [UPDATE].
                                </DialogContentText>
                                    <FormControl
                                        className={classes.formControl}
                                        variant="outlined"
                                        fullWidth
                                        required
                                        error={!!errors.title}
                                    >
                                        <InputLabel htmlFor="lead-title" className={classes.inputLabel}>Location</InputLabel>
                                        <Select
                                            keyboard="true"
                                            input={(
                                                <OutlinedInput
                                                    onChange={this.handleUpdateBranchCode}
                                                    name="title"
                                                    id="lead-title"
                                                />
                                            )}
                                            value={selectedBranchCode || ''}
                                        >
                                            <MenuItem value=""><em>None</em></MenuItem>
                                            {branches.map(branch => (
                                                <MenuItem
                                                    key={branch.code}
                                                    value={branch.code}
                                                >
                                                    {branch.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{errors.title}</FormHelperText>
                                    </FormControl>
                                    <Button
                                        variant="contained"
                                        className={classes.dialogButton}
                                        onClick={this.handleUpdateClick}
                                    >
                                            Update
                                    </Button>
                            </React.Fragment>
                        </div>
                        <Divider className={classes.dialogDivider} />
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button onClick={this.handleCloseDialogClick} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(ItemLocation);
