import React from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import Tile from '../containers/tile';
import HybridTextField from './hybridTextField';

const styles = theme => ({
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    divider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 2.5,
        marginBottom: theme.spacing.unit * 2,
    },
    bottomBarContainer: {
        width: '100%',
        textAlign: 'right',
    },
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    createdByFullNameField: {
        width: 350,
        marginLeft: theme.spacing.unit * 3,
    },
    createdDateField: {
        width: 120,
    },
    createdTimeField: {
        width: 120,
        marginLeft: theme.spacing.unit * 3,
    },
    tileBottomDivider: {
        marginTop: theme.spacing.unit * 3,
    },
    emailField: {
        width: 350,
    },
    rightOfFieldButton: {
        marginTop: 25,
        marginLeft: theme.spacing.unit * 3,
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
    },
    dialogActionsDivider: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    formHelperText: {
        color: '#f44336',
    },
    historyContainer: {
        // maxHeight: 300,
        overflow: 'auto',
    },
    historyPaper: {
        backgroundColor: theme.palette.background.default,
        border: '1px solid #e0e0e0',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing.unit * 2,
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 1}px`,
        width: '100%',
    },
});

class ContactHistory extends React.Component {
    state = {
        showNewHistoryNoteDialog: false,
        disableNewHistoryNoteSaveButton: false,
        historyForm: {
            note: '',
        },
    }

    handleNewHistoryNoteClick = () => {
        this.setState({
            showNewHistoryNoteDialog: true,
        });
    }

    handleNewHistoryNoteCloseDialogClick = () => {
        this.setState({
            showNewHistoryNoteDialog: false,
        });
    }

    titalise = string => string.toLowerCase()
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')

    capitaliseFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

    updateNewHistoryNoteData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'note':
            formattedValue = this.capitaliseFirstLetter(value);
            break;
        }
        event.target.value = formattedValue;

        this.setState({
            historyForm: {
                ...this.state.historyForm,
                [name]: formattedValue,
            },
        });
    }

    handleSaveNewHistoryNote = (event) => {
        event.preventDefault();
        this.setState({
            disableNewHistoryNoteSaveButton: true,
        });
        this.props.addNewHistoryNote(this.state.historyForm).then(() => {
            this.setState({
                showNewHistoryNoteDialog: false,
                // ADD NEW NOTE TO DISPLAY
                disableSaveHistoryNoteButton: false,
            });
            this.props.enqueueSnackbar('History note saved', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('History note save failed', { variant: 'error' });
            this.setState({
                disableNewHistoryNoteSaveButton: false,
            });
        });
    }

    render() {
        const {
            classes,
            errors,
            history,
        } = this.props;
        const {
            disableNewHistoryNoteSaveButton,
            historyForm,
            showNewHistoryNoteDialog,
        } = this.state;

        return (
            <Tile
                allowCollapse
                initialCollapsedTile
                tileTitle="History"
                tileMenuItems={[
                    {
                        label: 'New Entry',
                        action: this.handleNewHistoryNoteClick,
                        disabled: true,
                    },
                ]}
                style
            >
                { history !== null
                    ? (

                        <div className={classes.historyContainer}>
                            <Grid container spacing={0}>
                                {history.length === 0 && (
                                    <Typography variant="body1">
                                        No history found
                                    </Typography>
                                )}

                                {history.sort((a, b) => b.createdDateTime.localeCompare(a.createdDateTime)).map(entry => (
                                    <Paper
                                        className={classes.historyPaper}
                                        elevation={0}
                                        key={entry.code}
                                    >
                                        <Grid
                                            container
                                            spacing={0}
                                        >
                                            <Grid item xs={2}>
                                                <HybridTextField
                                                    label="Type"
                                                    text={entry.entryType}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <HybridTextField
                                                    label="Type Reference"
                                                    text={entry.entryTypeReferenceCode}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={0}
                                        >
                                            <Grid item xs={12}>
                                                <HybridTextField
                                                    label="Note"
                                                    text={entry.note}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={0}
                                        >
                                            <Grid item xs={2}>
                                                <HybridTextField
                                                    label="Date"
                                                    text={entry.createdDate}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <HybridTextField
                                                    label="Time"
                                                    text={entry.createdTime}
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <HybridTextField
                                                    label="Created By"
                                                    text={entry.createdByFullName}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                ))}
                            </Grid>

                            <Dialog
                                open={showNewHistoryNoteDialog}
                                onClose={this.handleNewHistoryNoteCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <React.Fragment>
                                            <Typography variant="h6" gutterBottom>
                                                New Contact History Note
                                            </Typography>
                                            <DialogContentText>
                                                Enter the note text below and click [SAVE].
                                                The current time, date, and your name will be
                                                recorded automatically.
                                            </DialogContentText>
                                            <form onSubmit={this.handleSaveNewHistoryNote}>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            onChange={this.updateNewHistoryNoteData}
                                                            className={classes.formControl}
                                                            name="note"
                                                            id="note"
                                                            label="Note"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            multiline
                                                            rows="6"
                                                            errors={!!errors.note}
                                                            helperText={errors.note}
                                                            autoFocus
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Button
                                                    variant="contained"
                                                    className={classes.dialogButton}
                                                    type="submit"
                                                    disabled={disableNewHistoryNoteSaveButton}
                                                >
                                                    SAVE
                                                </Button>
                                            </form>
                                        </React.Fragment>
                                    </div>
                                    <Divider />
                                </DialogContent>
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleNewHistoryNoteCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )
                }
            </Tile>
        );
    }
}

const historyShape = {
    code: PropTypes.number,
    createdByFullName: PropTypes.string,
    createdDate: PropTypes.string,
    createdDateTime: PropTypes.string,
    createdTime: PropTypes.string,
    note: PropTypes.string,
};

ContactHistory.propTypes = {
    classes: PropTypes.object.isRequired,
    addNewHistoryNote: PropTypes.func.isRequired,
    history: PropTypes.arrayOf(PropTypes.shape(historyShape)).isRequired,

    errors: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(ContactHistory));
