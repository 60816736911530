import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment-timezone';

const styles = theme => ({
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
});

class deliveryNotesDialog extends React.Component {
    state = {
        disableSubmitButton: false,
        showDialog: false,
        formData: {
            date: '',
            branchCode: '',
            supplierCode: '',
            reference: '',
            processedByContactCode: '',
        },
    }

    componentDidMount() {
        const now = moment.tz(moment(), 'Europe/London');
        const currentDate = moment(now).format('YYYY-MM-DD');
        const defaultUserBranchCode = (this.props.user || {}).branchCode || null;
        const defaultProcessedByCode = (this.props.user || {}).code || null;
        this.setState(state => ({
            formData: {
                ...state.formData,
                date: currentDate,
                branchCode: defaultUserBranchCode,
                processedByContactCode: defaultProcessedByCode,
            },
        }));
    }

    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            this.setDefaultUserDefaults();
        }
        if (this.props.showNewDeliveryNoteDialog !== prevProps.showNewDeliveryNoteDialog) {
            if (this.props.showNewDeliveryNoteDialog) this.handleOpenDialog();
            this.props.resetShowDeliveryDialog();
        }
    }

    setDefaultUserDefaults = () => {
        const { user } = this.props;
        this.setState(state => ({
            formData: {
                ...state.formData,
                branchCode: user.branchCode,
                processedByContactCode: user.code,
            },
        }));
    }

    handleOpenDialog = () => {
        this.setState({
            showDialog: true,
        });
    }

    handleCloseDialog = () => {
        this.setState({
            showDialog: false,
        });
    }

    handleDateChange = (chosenDate) => {
        this.setState(state => ({
            formData: {
                ...state.formData,
                date: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    }

    updateData = (event) => {
        const { value, name } = event.target;
        let formattedValue;
        switch (name) {
        case 'reference':
            formattedValue = value.toUpperCase();
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState(state => ({
            formData: {
                ...state.formData,
                [name]: formattedValue,
            },
        }));
    }

    handleSubmitButtonClick = () => {
        this.setState({
            disableSubmitButton: true,
        });
        this.props.createDeliveryNote(this.state.formData).then(() => {
            this.setState({
                showDialog: false,
                disableSubmitButton: false,
            });
            this.props.enqueueSnackbar('New Delivery Note Created', { variant: 'success' });
            this.props.moveToDeliveryNote(this.props.newDeliveryNoteCode);
        }).catch(() => {
            this.setState({
                disableSubmitButton: false,
            });
            this.props.enqueueSnackbar('Create New Delivery Note failed', { variant: 'error' });
        });
    }

    render() {
        const {
            branches,
            classes,
            errors,
            suppliers,
            users,
        } = this.props;

        const {
            disableSubmitButton,
            formData,
            showDialog,
        } = this.state;

        return (
            <React.Fragment>
                {branches !== null
                    && suppliers !== null
                    ? (
                        <Dialog
                            open={showDialog}
                            onClose={this.handleCloseDialog}
                        >
                            <DialogContent className={classes.dialogContent}>
                                <div className={classes.dialogContentContainer}>
                                    <React.Fragment>
                                        <Typography variant="h6" gutterBottom>
                                            New Delivery Note
                                        </Typography>
                                        <form>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <DatePicker
                                                        name="date"
                                                        id="date"
                                                        margin="normal"
                                                        label="Delivery Date"
                                                        value={formData.date}
                                                        onChange={this.handleDateChange}
                                                        fullWidth
                                                        variant="outlined"
                                                        format="DD-MM-YYYY"
                                                        showTodayButton
                                                        disableFuture
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.branchCode}
                                                    >
                                                        <InputLabel htmlFor="branchCode" className={classes.inputLabel}>Delivery Branch</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateData}
                                                                    name="branchCode"
                                                                    id="branchCode"
                                                                />
                                                            )}
                                                            value={formData.branchCode}
                                                        >
                                                            <MenuItem value=""><em>None</em></MenuItem>
                                                            {branches.map(branch => (
                                                                <MenuItem
                                                                    key={branch.code}
                                                                    value={branch.code}
                                                                >
                                                                    {branch.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.branchCode && (
                                                            <FormHelperText>{errors.branchCode}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.supplierCode}
                                                    >
                                                        <InputLabel htmlFor="supplierCode" className={classes.inputLabel}>Supplier</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateData}
                                                                    name="supplierCode"
                                                                    id="supplierCode"
                                                                />
                                                            )}
                                                            value={formData.supplierCode}
                                                        >
                                                            <MenuItem value=""><em>None</em></MenuItem>
                                                            {suppliers.map(supplier => (
                                                                <MenuItem
                                                                    key={supplier.code}
                                                                    value={supplier.code}
                                                                >
                                                                    {supplier.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.supplierCode && (
                                                            <FormHelperText>{errors.supplierCode}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="reference"
                                                        id="reference"
                                                        onChange={this.updateData}
                                                        value={formData.reference}
                                                        label="Reference"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.reference}
                                                        helperText={errors.reference}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.branchCode}
                                                    >
                                                        <InputLabel htmlFor="processedByContactCode" className={classes.inputLabel}>Processed by</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateData}
                                                                    name="processedByContactCode"
                                                                    id="processedByContactCode"
                                                                />
                                                            )}
                                                            value={formData.processedByContactCode}
                                                        >
                                                            <MenuItem value=""><em>None</em></MenuItem>
                                                            {users.map(user => (
                                                                <MenuItem
                                                                    key={user.contactCode}
                                                                    value={user.contactCode}
                                                                >
                                                                    {user.fullName}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.processedByContactCode && (
                                                            <FormHelperText>{errors.processedByContactCode}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>

                                            </Grid>
                                            <Button
                                                onClick={this.handleSubmitButtonClick}
                                                variant="contained"
                                                className={classes.dialogButton}
                                                disabled={
                                                    disableSubmitButton
                                                    || formData.branchCode === ''
                                                    || formData.supplierCode === ''
                                                    || formData.reference === ''
                                                    || formData.processedByContactCode === ''
                                                }
                                            >
                                        CREATE
                                            </Button>
                                        </form>
                                    </React.Fragment>
                                </div>
                            </DialogContent>
                            <Divider className={classes.dialogActionsDivider} />
                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    onClick={this.handleCloseDialog}
                                    color="primary"
                                    autoFocus
                                >
                            Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                    : null
                }
            </React.Fragment>
        );
    }
}

const formShape = {
    code: PropTypes.number,
    branchCode: PropTypes.number,
    reference: PropTypes.string,
    supplierCode: PropTypes.number,
    deliveryDate: PropTypes.string,
    processedByContactCode: PropTypes.number,
};

const branchesShape = {
    code: PropTypes.number,
    name: PropTypes.string,
};

const suppliersShape = {
    code: PropTypes.number,
    name: PropTypes.string,
};

const userShape = {
    accessLevel: PropTypes.number,
    fullName: PropTypes.string,
    branchCode: PropTypes.number,
};

const usersShape = {
    contactCode: PropTypes.number,
    fullName: PropTypes.string,
};

deliveryNotesDialog.propTypes = {
    branches: PropTypes.arrayOf(PropTypes.shape(branchesShape)).isRequired,
    classes: PropTypes.object.isRequired,
    createDeliveryNote: PropTypes.func.isRequired,
    errors: PropTypes.shape(formShape).isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    moveToDeliveryNote: PropTypes.func.isRequired,
    newDeliveryNoteCode: PropTypes.number,
    resetShowDeliveryDialog: PropTypes.func.isRequired,
    showNewDeliveryNoteDialog: PropTypes.bool.isRequired,
    suppliers: PropTypes.arrayOf(PropTypes.shape(suppliersShape)).isRequired,
    user: PropTypes.shape(userShape).isRequired,
    users: PropTypes.arrayOf(PropTypes.shape(usersShape)).isRequired,
};

export default withSnackbar(withStyles(styles)(deliveryNotesDialog));
