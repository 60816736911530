import React from 'react';
import {
    CssBaseline,
    Paper
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Layout from '../Layout';
import { fetchJson2 } from '../../utilities/fetch';

const styles = () => ({
    root: {
        width: '100%',
        textAlign: 'center',
        overflowX: 'auto',
    },
    widget: {
        border: '1px solid #344955',
        borderRadius: '5px',
        margin: '10px',
        width: '260px',
        display: 'inline-block',
        textAlign: 'center',
        textDecoration: 'none',
        color: '#344955',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
         '& img': {
            maxWidth: '100%'
        },
        '& table': {
            width: '100%'
        },
        '& div.title': {
            backgroundColor: '#5c6d77',
            color: 'white',
            padding: '10px',
            textTransform: 'uppercase'
        }
    }
});

class Dash extends React.Component {
    state = {
        errors: [],
        widgets: []
    };

    componentDidMount() {
        this.getWidgets();
    }

    async getWidgets() {
        try {
            await fetchJson2('s2/dash/widgets').then(
                (rWidgets) => {
                    rWidgets.payload.forEach(widget => {
                        fetchJson2(`s2/dash/widget/${widget}`).then(
                            (rWidget) => {
                                this.setState({
                                    widgets:[...this.state.widgets, rWidget.payload]
                                });
                            }
                        );
                    });
                }
            );
            
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        const {
            errors,
            widgets
        } = this.state;
        const { classes } = this.props;        
        return (
            <Layout pageTitle="Dash">
                <CssBaseline />
                <Paper className={classes.root}>
                    {widgets.map((widget, index) => (
                        <a className={classes.widget} href={widget.Link} key={index} >
                            <div class="title">{widget.Title}</div>
                            <img src={widget.Chart} />
                            <table>
                                <thead>
                                    <tr>
                                        {Object.keys(widget.Data).map((k, index) => (
                                            <th key={index}>{k}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {Object.values(widget.Data).map((v, index) => (
                                            <td key={index}>{v.toLocaleString(navigator.language, { maximumFractionDigits: 0 })}</td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        </a>
                    ))}
                </Paper>
            </Layout>
        );
    }
}

export default withStyles(styles)(Dash);
