import React from 'react';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import fetch from '../utilities/fetch';

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
}))(TableCell);

const styles = theme => ({
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    divider: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    authorisedPaymentChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '50px',
    },
    declinedPaymentChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '50px',
    },
    chip: {
        height: 21,
        borderRadius: 5,
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    table: {
        // width: '50%',
    },
});

class AgreementItems extends React.Component {
    state = {
        errors: false,
        loading: false,
        snackbarOpen: false,
        items: [],
        showPaymentDetailDialog: false,
        detailDialogTransactionStatus: null,
        // {
        //     paymentDate: '',
        //     source: '',
        //     transactionStatus: '',
        //     amount: null,
        //     cardNo: '',
        //     paymentID: null,
        //     currencyISO: '',
        //     prettyAmount: '',
        //     paymentTime: '',
        // },
    }

    async componentDidMount() {
        console.log(this.props.rentalAgreementCode);
        const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/agreement/${this.props.rentalAgreementCode}/items`);
        const data = await fetch(url).then(response => response.json());
        this.setState({ items: data });
        console.log('Agreement Items', data);
    }

    handleOpenPaymentDetailClick = (rentalAgreementCode, paymentID, transactionStatus) => {
        console.log(rentalAgreementCode, paymentID);
        this.setState({
            showPaymentDetailDialog: true,
            detailDialogTransactionStatus: transactionStatus,
        });
    }

    handleClosePaymentDetailClick = (rentalAgreementCode, paymentID) => {
        this.setState({ showPaymentDetailDialog: false });
    }

    updateRefundData = (rentalAgreementCode, paymentID) => {
        this.setState({ showPaymentDetailDialog: false });
    }

    render() {
        const { classes, rentalAgreementCode } = this.props;
        const { items, showPaymentDetailDialog, errors, detailDialogTransactionStatus } = this.state;

        return (
            <React.Fragment>
                <Table className={classes.table} padding='dense'>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell>#</CustomTableCell>
                            <CustomTableCell>Item Code</CustomTableCell>
                            <CustomTableCell align="center">Manufacturer</CustomTableCell>
                            <CustomTableCell align="center">Model</CustomTableCell>
                            <CustomTableCell align="center">Description</CustomTableCell>
                            <CustomTableCell align="center">State</CustomTableCell>
                            <CustomTableCell align="center">Last Sent</CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.sort((a, b) => a.paymentDate - b.paymentDate).reverse().map((item, i) => {
                        {/* {item.map((item, i) => { */}
                            i++;
                            return (
                                <TableRow className={classes.row} key={i}>
                                    <CustomTableCell component="th" scope="row">{i}</CustomTableCell>
                                    <CustomTableCell align="left">{item.itemCode}</CustomTableCell>
                                    <CustomTableCell align="center">{item.manufacturer}</CustomTableCell>
                                    <CustomTableCell align="center">{item.modelNo}</CustomTableCell>
                                    <CustomTableCell align="center">{item.shortDescription}</CustomTableCell>
                                    <CustomTableCell align="center">{item.controlDeviceState === 'ON' ? <Chip label="ON" className={[classes.chip, classes.authorisedPaymentChip]} /> : <Chip label="OFF" className={[classes.chip, classes.declinedPaymentChip]} /> }</CustomTableCell>
                                    <CustomTableCell align="center">{item.lastCommandSent === 'ON' ? <Chip label="ON" className={[classes.chip, classes.authorisedPaymentChip]} /> : <Chip label="OFF" className={[classes.chip, classes.declinedPaymentChip]} /> }</CustomTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(AgreementItems);
