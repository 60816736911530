import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import AppBarTop from '../components/appBarTop';
import MainMenu from '../components/mainMenu';

const styles = (theme) => ({
    root: {
        margin: theme.spacing.unit * 3,
    },
    toolbar: theme.mixins.toolbar,
});

class Layout extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    state = {
        drawerOpen: false,
        userLoading: true,
    };

    openDrawer = () => {
        this.setState({ drawerOpen: true });
    };

    closeDrawer = () => {
        this.setState({ drawerOpen: false });
    };

    render() {
        const { drawerOpen, user, userLoading } = this.state;
        const { children, classes, pageTitle } = this.props;

        return (
            <main>
                <AppBarTop onMenuToggleClick={this.openDrawer} pageTitle={pageTitle} />
                <MainMenu
                    drawerOpen={drawerOpen}
                    onCloseMenu={this.closeDrawer}
                    onMenuClick={this.closeDrawer}
                />
                <div className={classes.toolbar} />
                <div className={classes.root}>
                    { children }
                </div>
            </main>
        );
    }
}

export default withStyles(styles)(Layout);
