import React, { isValidElement } from 'react';
import {
    CircularProgress,
    CssBaseline,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import Layout from '../Layout';
import ManufacturerSuppliersTable from '../../components/v2/manufacturerSuppliersTable';
import fetch, { fetchJson2 } from '../../utilities/fetch';
import ManufacturerSupplierCreateDialog from '../../components/v2/manufacturerSupplierCreateDialog';
import ManufacturerSupplierEditDialog from '../../components/v2/manufacturerSupplierEditDialog';
import ManufacturerSupplierExpireDialog from '../../components/v2/manufacturerSupplierExpireDialog';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    inputLabel: {
        backgroundColor: '#fff',
        marginLeft: -5,
        paddingLeft: 8,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    newPurchaseOrderDialog: {
        maxWidth: 'none',
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
        minWidth: 400,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    itemsContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    itemsAddButtonContainer: {
        textAlign: 'right',
    },
    itemsTitleContainer: {
        marginTop: 13,
    },
    fieldAddButton: {
        marginLeft: 24,
        marginTop: 24,
    },
    radioGroup: {
        marginLeft: 24,
    },
    radioFormControl: {
        marginTop: 16,
    },
});

class ManufacturerSuppliers extends React.Component {
    state = {
        currentManufacturerSupplier: {},
        errors: [],
        manufacturerSuppliersLoading: true,
        showCreateManufacturerSupplierDialog: false,
        showEditManufacturerSupplierDialog: false,
        showExpireManufacturerSupplierDialog: false,
    };

    componentDidMount() {
        document.addEventListener('visibilitychange', this.onVisibilitychange);
        this.loadManufacturerSuppliersData();
    }

    componentWillUnmount() {
        document.removeEventListener('visibilitychange', this.onVisibilitychange);
    }

    onVisibilitychange = () => {
        if (document.hidden === true) return;
        this.refreshVisitItemsData();
    };

    refreshManufacturerSuppliersData = () => {
        this.loadManufacturerSuppliersData().then(() => {
            this.props.enqueueSnackbar('UPDATED', { variant: 'success', autoHideDuration: 750 });
        }).catch(() => {
            this.props.enqueueSnackbar('UPDATE FAILED', { variant: 'error', autoHideDuration: 750 });
        });
    };

    loadManufacturerSuppliersData = async () => {
        fetchJson2('v2/manufacturerSuppliers')
            .then((manufacturerSuppliers) => {
                this.setState({
                    manufacturerSuppliers: manufacturerSuppliers.payload,
                    manufacturerSuppliersLoading: false,
                });
            });
    };

    passNewManufacturerSupplier = (newManufacturerSupplier) => {
        console.log('>>> passNewManufacturerSupplier!!');
        let newManufacturerSuppliers;
        if (!this.state.manufacturerSuppliers.find((manufacturerSupplier) => manufacturerSupplier.code === newManufacturerSupplier.code)) {
            newManufacturerSuppliers = [
                ...this.state.manufacturerSuppliers,
                newManufacturerSupplier,
            ];
        } else {
            newManufacturerSuppliers = this.state.manufacturerSuppliers.map((manufacturerSupplier) => ((manufacturerSupplier.code === newManufacturerSupplier.code) ? newManufacturerSupplier : manufacturerSupplier));
        }
        this.setState({
            manufacturerSuppliers: newManufacturerSuppliers,
        });
    };

    handleExpireManufacturerSupplierClick = (manufacturerSupplierCode, event) => {
        event.stopPropagation();
        const newCurrentManufacturerSupplier = this.state.manufacturerSuppliers.find((manufacturerSupplier) => manufacturerSupplier.code === manufacturerSupplierCode);

        this.setState({
            currentManufacturerSupplier: {
                ...newCurrentManufacturerSupplier,
                histories: [],
            },
            showExpireManufacturerSupplierDialog: true,
        });
    };

    handleCreateManufacturerSupplierClick = () => {
        this.setState({
            showCreateManufacturerSupplierDialog: true,
        });
    };

    handleEditManufacturerSupplierClick = (manufacturerSupplierCode, event) => {
        event.stopPropagation();
        const newCurrentManufacturerSupplier = this.state.manufacturerSuppliers.find((manufacturerSupplier) => manufacturerSupplier.code === manufacturerSupplierCode);
        this.setState({
            currentManufacturerSupplier: {
                ...newCurrentManufacturerSupplier,
                histories: [],
            },
            showEditManufacturerSupplierDialog: true,
        });
    };

    handleCloseEditManufacturerSupplierDialogClick = () => {
        this.setState({
            showEditManufacturerSupplierDialog: false,
        });
    };

    handleCloseCreateManufacturerSupplierDialogClick = () => {
        this.setState({
            showCreateManufacturerSupplierDialog: false,
        });
    };

    handleCloseExpireManufacturerSupplierDialogClick = () => {
        this.setState({
            showExpireManufacturerSupplierDialog: false,
        });
    };

    unexpireManufacturerSupplier = async (manufacturerSupplierCode) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/manufacturerSupplier/${manufacturerSupplierCode}`, {
                body: JSON.stringify({ expired: 0 }),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
              && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            this.passNewManufacturerSupplier(body.manufacturerSupplier);
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    render() {
        const {
            classes,
        } = this.props;

        const {
            currentManufacturerSupplier,
            manufacturerSuppliersLoading,
            showCreateManufacturerSupplierDialog,
            showEditManufacturerSupplierDialog,
            showExpireManufacturerSupplierDialog,

            errors,
            manufacturerSuppliers,
        } = this.state;

        return (
            <Layout pageTitle="Manufacturers & Suppliers">
                <CssBaseline />
                {
                    !manufacturerSuppliersLoading
                        ? (
                            <>
                                <ManufacturerSuppliersTable
                                    editManufacturerSupplier={this.editManufacturerSupplier}
                                    errors={errors}
                                    expireManufacturerSupplier={this.expireManufacturerSupplier}
                                    handleCreateManufacturerSupplierClick={this.handleCreateManufacturerSupplierClick}
                                    handleExpireManufacturerSupplierClick={this.handleExpireManufacturerSupplierClick}
                                    handleEditManufacturerSupplierClick={this.handleEditManufacturerSupplierClick}
                                    handleCloseEditManufacturerSupplierDialogClick={this.handleCloseEditManufacturerSupplierDialogClick}
                                    handleCloseExpireManufacturerSupplierDialogClick={this.handleCloseExpireManufacturerSupplierDialogClick}
                                    manufacturerSuppliers={manufacturerSuppliers}
                                    manufacturerSuppliersLoading={manufacturerSuppliersLoading}
                                    passNewManufacturerSupplier={this.passNewManufacturerSupplier}
                                    unexpireManufacturerSupplier={this.unexpireManufacturerSupplier}
                                />
                                <ManufacturerSupplierCreateDialog
                                    handleCloseCreateManufacturerSupplierDialogClick={this.handleCloseCreateManufacturerSupplierDialogClick}
                                    manufacturerSuppliers={manufacturerSuppliers}
                                    manufacturerSupplierLoading={manufacturerSuppliersLoading}
                                    showCreateManufacturerSupplierDialog={showCreateManufacturerSupplierDialog}
                                    passNewManufacturerSupplier={this.passNewManufacturerSupplier}
                                />
                                <ManufacturerSupplierEditDialog
                                    handleCloseEditManufacturerSupplierDialogClick={this.handleCloseEditManufacturerSupplierDialogClick}
                                    manufacturerSupplier={currentManufacturerSupplier}
                                    manufacturerSupplierLoading={manufacturerSuppliersLoading}
                                    showEditManufacturerSupplierDialog={showEditManufacturerSupplierDialog}
                                    passNewManufacturerSupplier={this.passNewManufacturerSupplier}
                                />
                                <ManufacturerSupplierExpireDialog
                                    handleCloseExpireManufacturerSupplierDialogClick={this.handleCloseExpireManufacturerSupplierDialogClick}
                                    handleExpireManufacturerSupplierCloseDialogClick={this.handleExpireManufacturerSupplierCloseDialogClick}
                                    manufacturerSupplier={currentManufacturerSupplier}
                                    manufacturerSupplierLoading={manufacturerSuppliersLoading}
                                    passNewManufacturerSupplier={this.passNewManufacturerSupplier}
                                    showExpireManufacturerSupplierDialog={showExpireManufacturerSupplierDialog}
                                />
                            </>
                        )
                        : (
                            <div className={classes.progressContainer}>
                                <CircularProgress className={classes.progress} variant="indeterminate" />
                            </div>
                        )
                }

            </Layout>
        );
    }
}

export default withSnackbar(withStyles(styles)(ManufacturerSuppliers));
