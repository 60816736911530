import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Doughnut} from 'react-chartjs-2';

const styles = theme => ({
    chart: {
        width: 250,
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    divider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 2.5,
        marginBottom: theme.spacing.unit * 2,
    },
    bottomBarContainer: {
        width: '100%',
        textAlign: 'right',
    },
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    createdByFullNameField: {
        width: 350,
        marginLeft: theme.spacing.unit * 3,
    },
    createdDateField: {
        width: 120,
    },
    createdTimeField: {
        width: 120,
        marginLeft: theme.spacing.unit * 3,
    },
    emailField: {
        width: 350,
    },
    rightOfFieldButton: {
        marginTop: 25,
        marginLeft: theme.spacing.unit * 3,
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
    },
    dialogActionsDivider: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    formHelperText: {
        color: '#f44336',
    },
});

const data = {
	labels: [
		'Declined',
		'Authorised',
	],
	datasets: [{
		data: [4, 8],
		backgroundColor: [
		'#FF0000',
		'#00A200',
		],
		hoverBackgroundColor: [
		'#FF6384',
		'#36A2EB',
		]
	}]
};

class AgreementPaymentChart extends React.Component {

    render() {
        const { classes } = this.props;

        return (
          <div className={classes.chart}>
            <Doughnut data={data} />
          </div>
        );
      }
}

export default withStyles(styles)(AgreementPaymentChart);
