import React from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import FilterListIcon from '@material-ui/icons/FilterList';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import MenuIcon from '@material-ui/icons/MenuOutlined';
import EditIcon from '@material-ui/icons/Create';
import { saveAs } from 'file-saver';
import moment from 'moment-timezone';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import AddIcon from '@material-ui/icons/Add';
import BranchesTableFilterDialog from './branchesTableFilterDialog';
import BranchesTableEditDialog from './branchesTableEditDialog';
import BranchesTableAddDialog from './branchesTableAddDialog';

const json2csv = require('json2csv').parse;

const styles = theme => ({
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
        minHeight: 100,
        paddingTop: theme.spacing.unit * 3,
    },
    progress: {
        opacity: '.5',
    },
    tableTitleLeft: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        alignItems: 'left',
        paddingTop: theme.spacing.unit * 2,
        fontWeight: 500,
        textTransform: 'uppercase',
        paddingBottom: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 2,
    },
    tableTitleRight: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        alignItems: 'right',
        paddingTop: theme.spacing.unit * 2,
        fontWeight: 500,
        textTransform: 'uppercase',
        paddingBottom: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        // cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 16,
    },
    shortDescription: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
    noWrap: {
        whiteSpace: 'nowrap',
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    filterIcon: {
        width: 16,
    },
    filterButton: {
        marginLeft: theme.spacing.unit * 3,
    },
    orderDirectionIcon: {
        marginBottom: -10,
    },
    pageDetail: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        paddingTop: 13,
        fontWeight: 500,
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        textTransform: 'uppercase',
        paddingBottom: theme.spacing.unit * 2,
    },
    pageSizeMenuButton: {
        marginTop: 3,
        color: 'rgba(0, 0, 0, 0.54)',
    },
    pageSizeMenuButtonIcon: {
        marginLeft: theme.spacing.unit * 0.5,
    },
    hidden: {
        display: 'none',
    },
    orderColumn: {
        color: theme.palette.secondary.main,
    },
    expired: {
        color: 'rgba(0, 0, 0, 0.35)',
    },
});

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        paddingRight: 8,
    },
    body: {
        fontSize: 16,
        verticalAlign: 'top',
        paddingTop: 16,
        paddingBottom: 8,
        paddingRight: 8,
    },
}))(TableCell);

const printIframe = (id) => {
    const iframe = document.frames
        ? document.frames[id]
        : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;
    iframe.focus();
    iframeWindow.print();
    return false;
};

class BranchesTable extends React.Component {
    constructor(props) {
        super(props);
        this.handlePrint = this.handlePrint.bind(this);
    }

    state = {
        pageSizeMenuAnchor: null,
        itemsMenuAnchor: null,
        showFiltersDialog: false,
        showEditBranchDialog: false,
        showAddBranchDialog: false,
        iframeSrc: null,
    }

    componentDidMount() {
        window.addEventListener('message', this.handlePrint);
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.filters !== prevProps.filters || this.props.controls !== prevProps.controls) {
            this.setState({
                iframeSrc: null,
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handlePrint);
    }

    handleShowEditBranchClick = (selectedBranchCode) => {
        const selectedBranch = this.props.branches.filter(branch => branch.code === selectedBranchCode)[0];
        this.setState({
            selectedBranch,
            showEditBranchDialog: true,
            itemsMenuAnchor: null,
        });
    }

    handleShowAddBranchClick = () => {
        this.setState({
            showAddBranchDialog: true,
            itemsMenuAnchor: null,
        });
    }

    resetShowEditBranchDialog = () => {
        this.setState({
            showEditBranchDialog: false,
        });
    }

    resetShowAddBranchDialog = () => {
        this.setState({
            showAddBranchDialog: false,
        });
    }

    handleShowFiltersClick = () => {
        this.setState({
            showFiltersDialog: true,
        });
    }

    resetShowFiltersDialog = () => {
        this.setState({
            showFiltersDialog: false,
        });
    }

    handlePageSizeMenuOpen = (event) => {
        this.setState({
            pageSizeMenuAnchor: event.currentTarget,
        });
    }

    handlePageSizeMenuClose = () => {
        this.setState({
            pageSizeMenuAnchor: null,
        });
    }

    handleItemsMenuOpen = (event) => {
        this.setState({
            itemsMenuAnchor: event.currentTarget,
        });
    }

    handleItemsMenuClose = () => {
        this.setState({
            itemsMenuAnchor: null,
        });
    }

    updatePageSize = (event) => {
        const { textContent } = event.target;
        const newPageSize = textContent;
        const newControls = {
            ...this.props.controls,
            pageSize: newPageSize,
            pageNumber: 1,
        };
        this.setState({
            pageSizeMenuAnchor: null,
        });
        this.props.setControls(newControls);
    }

    updatePageNumber = (name) => {
        let newPageNumber;
        switch (name) {
        case 'first': newPageNumber = 1;
            break;
        case 'previous': newPageNumber = this.props.controls.pageNumber - 1;
            break;
        case 'next': newPageNumber = this.props.controls.pageNumber + 1;
            break;
        case 'last': newPageNumber = Math.ceil(this.props.totalBranchesQty / this.props.controls.pageSize);
            break;
        default: newPageNumber = this.props.controls.pageNumber;
        }
        const newControls = {
            ...this.props.controls,
            pageNumber: newPageNumber,
        };
        this.props.setControls(newControls);
    }

    handleDownloadCSVClick = async () => {
        const newControls = {
            ...this.state.controls,
            pageSize: this.props.totalBranchesQty,
            pageNumber: 1,
        };
        const printItems = await this.props.fetchProductss(newControls, this.state.filters, true);
        const csvData = json2csv(printItems);
        const csvBlob = new Blob([csvData], { type: 'text/plain;charset=utf-8' });
        const now = moment.tz(moment(), 'Europe/London');
        const currentDateStamp = moment(now).format('DDMMYYYY_HHmmss');
        saveAs(csvBlob, `ItemsReport_${currentDateStamp}.csv`);
        this.setState({
            itemsMenuAnchor: null,
        });
    }

    handlePrintClick = async () => {
        this.setState({
            iframeSrc: '/itemsPrint',
            itemsMenuAnchor: null,
        });
    }

    handlePrint = (event) => {
        if (event.data.action === 'report-loaded') {
            printIframe('report');
        }
    }

    handleColumnTitleClick = (columnTitle) => {
        let newControls;
        if (columnTitle === this.props.controls.orderColumn) {
            const newOrderDirection = this.props.controls.orderDirection === 'asc' ? 'desc' : 'asc';
            newControls = {
                ...this.props.controls,
                pageNumber: 1,
                orderDirection: newOrderDirection,
            };
        } else {
            const newOrderColumn = columnTitle;
            newControls = {
                ...this.props.controls,
                pageNumber: 1,
                orderColumn: newOrderColumn,
            };
        }
        this.props.setControls(newControls);
    }

    handleItemClick = (itemCode) => {
        this.props.history.push(`/item/${itemCode}`);
    }

    // updateCheckbox = name => (event) => {
    //     const newState = event.target.checked ? 1 : 0;
    //     let newSelectData = [];
    //     if (newState === 1) {
    //         newSelectData = [
    //             ...this.state.selectData,
    //             ...[name],
    //         ];
    //     } else {
    //         newSelectData = this.state.selectData.filter(itemCode => itemCode !== name);
    //     }
    //
    //     this.setState({
    //         selectData: [
    //             ...newSelectData,
    //         ],
    //     });
    // }

    render() {
        const {
            allBranches,
            classes,
            controls,
            errors,
            filters,
            branches,
            selectData,
            setFilters,
            totalBranchesQty,

        } = this.props;
        const {
            itemsMenuAnchor,
            pageSizeMenuAnchor,
            selectedBranch,
            showFiltersDialog,
            showAddBranchDialog,
            showEditBranchDialog,
        } = this.state;

        const orderDirectionIcon = controls && controls.orderDirection === 'asc'
            ? (<ArrowDownIcon className={classes.orderDirectionIcon} />)
            : (<ArrowUpIcon className={classes.orderDirectionIcon} />);

        const pageNumber = ((controls || {}).pageNumber) || 0;
        const pageQty = Math.ceil((((totalBranchesQty || {})) || 0) / (((controls || {}).pageSize) || 0));

        const pageControls = (
            <React.Fragment>
                <IconButton
                    disabled={pageNumber === 1}
                    className={classes.button}
                    onClick={() => this.updatePageNumber('first')}
                >
                    <FirstPageIcon />
                </IconButton>
                <IconButton
                    disabled={pageNumber === 1}
                    name="previous"
                    className={classes.button}
                    onClick={() => this.updatePageNumber('previous')}
                >
                    <ChevronLeftIcon />
                </IconButton>
                <div className={classes.pageDetail}>
        PAGE
                    {' '}
                    { pageNumber }
                    {' '}
of
                    {' '}
                    { pageQty || 0 }
                </div>
                <IconButton
                    disabled={pageNumber === pageQty}
                    name="next"
                    className={classes.button}
                    onClick={() => this.updatePageNumber('next')}
                >
                    <ChevronRightIcon />
                </IconButton>
                <IconButton
                    disabled={pageNumber === pageQty}
                    name="last"
                    className={classes.button}
                    onClick={() => this.updatePageNumber('last')}
                >
                    <LastPageIcon />
                </IconButton>
            </React.Fragment>
        );

        return (
            <React.Fragment>
                {controls != null && (
                    <React.Fragment>
                        <Grid container spacing={0}>
                            <Grid item xs={4}>
                                <div className={classes.tableTitleLeft}>Branches</div>
                            </Grid>
                            <Grid container item xs={4} align="center">
                                {pageControls}
                            </Grid>
                            <Grid container item xs={4} alignItems="flex-start" justify="flex-end" direction="row">
                                <Button
                                    onClick={this.handlePageSizeMenuOpen}
                                    size="large"
                                    className={classes.pageSizeMenuButton}
                                >
                                    {controls.pageSize}
                                    <ArrowDownIcon className={classes.pageSizeMenuButtonIcon} />
                                </Button>
                                <Menu
                                    id="pageSizeMenu"
                                    anchorEl={pageSizeMenuAnchor}
                                    open={Boolean(pageSizeMenuAnchor)}
                                    onClose={this.handlePageSizeMenuClose}
                                >
                                    <MenuItem onClick={this.updatePageSize}>5</MenuItem>
                                    <MenuItem onClick={this.updatePageSize}>25</MenuItem>
                                    <MenuItem onClick={this.updatePageSize}>50</MenuItem>
                                    <MenuItem onClick={this.updatePageSize}>100</MenuItem>
                                </Menu>
                                <IconButton
                                    className={classes.button}
                                    onClick={this.handleShowAddBranchClick}
                                >
                                    <AddIcon />
                                </IconButton>
                                <IconButton
                                    onClick={this.handleShowFiltersClick}
                                >
                                    <FilterListIcon />
                                </IconButton>
                                <IconButton
                                    onClick={this.handleItemsMenuOpen}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="itemsMenu"
                                    anchorEl={itemsMenuAnchor}
                                    open={Boolean(itemsMenuAnchor)}
                                    onClose={this.handleItemsMenuClose}
                                >
                                    <MenuItem
                                        onClick={this.handleDownloadCSVClick}
                                        disabled
                                    >
                                        Download current items
                                    </MenuItem>
                                    <MenuItem
                                        onClick={this.handlePrintClick}
                                        disabled
                                    >Print current items</MenuItem>
                                    <MenuItem
                                        onClick={this.handleShowEditItemsClick}
                                        disabled={selectData.length === 0}
                                    >
                                        Edit selected items
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                        <Paper>
                            { branches !== null
                                ? (
                                    <React.Fragment>
                                        <Table className={classes.table} padding="dense">
                                            <TableHead>
                                                <TableRow>
                                                    {/*
                                                    <CustomTableCell className={classes.tableHead}>
                                                        {`Select (${selectData.length})`}
                                                    </CustomTableCell>
                                                    */}
                                                    <CustomTableCell className={classes.tableHead}>
                                                        <div
                                                            id="name"
                                                            className={`${classes.noWrap} ${controls.orderColumn === 'name' ? `${classes.orderColumn}` : null}`}
                                                            onClick={() => this.handleColumnTitleClick('name')}
                                                        >
                                                    Name
                                                            {controls.orderColumn === 'name' ? orderDirectionIcon : null}
                                                        </div>
                                                    </CustomTableCell>
                                                    <CustomTableCell className={classes.tableHead}>
                                                        <div
                                                            id="shortName"
                                                            className={`${classes.noWrap} ${controls.orderColumn === 'shortName' ? `${classes.orderColumn}` : null}`}
                                                            onClick={() => this.handleColumnTitleClick('shortName')}
                                                        >
                                                    Short Name
                                                            {controls.orderColumn === 'shortName' ? orderDirectionIcon : null}
                                                        </div>
                                                    </CustomTableCell>
                                                    <CustomTableCell className={classes.tableHead}>
                                                        <div
                                                            id="hasStockRooms"
                                                            className={`${classes.noWrap} ${controls.orderColumn === 'hasStockRooms' ? `${classes.orderColumn}` : null}`}
                                                            onClick={() => this.handleColumnTitleClick('hasStockRooms')}
                                                        >
                                                    Has Stockrooms
                                                            {controls.orderColumn === 'hasStockRooms' ? orderDirectionIcon : null}
                                                        </div>
                                                    </CustomTableCell>
                                                    <CustomTableCell className={classes.tableHead}>
                                                        <div
                                                            id="parentBranch"
                                                            className={`${classes.noWrap} ${controls.orderColumn === 'parentBranch' ? `${classes.orderColumn}` : null}`}
                                                            onClick={() => this.handleColumnTitleClick('parentBranch')}
                                                        >
                                                    Parent Branch
                                                            {controls.orderColumn === 'parentBranch' ? orderDirectionIcon : null}
                                                        </div>
                                                    </CustomTableCell>
                                                    <CustomTableCell className={classes.tableHead} align="right">
                                                        <div
                                                            id="createdDateTime"
                                                            className={`${classes.noWrap} ${controls.orderColumn === 'createdDateTime' ? `${classes.orderColumn}` : null}`}
                                                            onClick={() => this.handleColumnTitleClick('createdDateTime')}
                                                        >
                                                    Created Date
                                                            {controls.orderColumn === 'createdDateTime' ? orderDirectionIcon : null}
                                                        </div>
                                                    </CustomTableCell>

                                                    <CustomTableCell className={classes.tableHead} />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {branches.map(branch => (
                                                    <TableRow className={classes.row} key={branch.code}>
                                                        {/*
                                                        <CustomTableCell className={`${classes.customTableCell} ${branch.expired === 1 ? classes.expired : null}`} align="left">
                                                            <Checkbox
                                                                name={branch.productCode}
                                                                id={branch.productCode}
                                                                checked={selectData.filter(selectItem => selectItem === branch.productCode).length > 0}
                                                                onChange={this.props.updateItemSelectCheckbox(branch.productCode)}
                                                            />
                                                        </CustomTableCell>
                                                        */}
                                                        <CustomTableCell className={`${classes.customTableCell} ${branch.expired === 1 ? classes.expired : null}`} align="left">{branch.name}</CustomTableCell>
                                                        <CustomTableCell className={`${classes.customTableCell} ${branch.expired === 1 ? classes.expired : null}`} align="left">{branch.shortName}</CustomTableCell>
                                                        <CustomTableCell className={`${classes.customTableCell} ${branch.expired === 1 ? classes.expired : null}`} align="left">{branch.hasStockrooms ? 'Yes' : 'No'}</CustomTableCell>
                                                        <CustomTableCell className={`${classes.customTableCell} ${branch.expired === 1 ? classes.expired : null}`} align="left">{branch.parentBranch }</CustomTableCell>
                                                        <CustomTableCell className={`${classes.customTableCell} ${branch.expired === 1 ? classes.expired : null}`} align="right">{branch.createdDateFormatted}</CustomTableCell>
                                                        <CustomTableCell className={`${classes.customTableCell} ${branch.expired === 1 ? classes.expired : null}`} align="center"><IconButton onClick={() => this.handleShowEditBranchClick(branch.code)} className={classes.button} aria-label="More info"><EditIcon /></IconButton></CustomTableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </React.Fragment>
                                )
                                : (
                                    <div className={classes.progressContainer}>
                                        <CircularProgress className={classes.progress} variant="indeterminate" />
                                    </div>
                                )
                            }
                        </Paper>
                        <Grid item xs={12} align="center">
                            {pageControls}
                        </Grid>
                        {/*
                        <BranchesTableFilterDialog
                            branches={branches}
                            showFiltersDialog={showFiltersDialog}
                            resetShowFiltersDialog={this.resetShowFiltersDialog}
                            setFilters={setFilters}
                            filters={filters}
                        />
                        */}
                        <BranchesTableEditDialog
                            allBranches={allBranches}
                            editBranch={this.props.editBranch}
                            errors={errors}
                            resetShowEditBranchDialog={this.resetShowEditBranchDialog}
                            selectedBranch={selectedBranch}
                            showEditBranchDialog={showEditBranchDialog}
                        />
                        <BranchesTableAddDialog
                            addBranch={this.props.addBranch}
                            allBranches={allBranches}
                            branches={branches}
                            errors={errors}
                            resetShowAddBranchDialog={this.resetShowAddBranchDialog}
                            showAddBranchDialog={showAddBranchDialog}
                        />
                        <iframe
                            style={{ display: 'none' }}
                            id="report"
                            src={this.state.iframeSrc}
                            title="Report"
                        />

                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const itemShape = {
    branchName: PropTypes.string,
    code: PropTypes.number,
    colour: PropTypes.string,
    itemConditionDescription: PropTypes.string,
    length: PropTypes.number,
    manufacturerName: PropTypes.string,
    modelNo: PropTypes.string,
    productTypeDescription: PropTypes.string,
    shortDescription: PropTypes.string,
    width: PropTypes.number,
};

const brancheShape = {
    code: PropTypes.number,
    name: PropTypes.string,
};

const itemConditionShape = {
    code: PropTypes.number,
    description: PropTypes.string,
};

BranchesTable.propTypes = {
    branches: PropTypes.arrayOf(PropTypes.shape(brancheShape)).isRequired,
    classes: PropTypes.object.isRequired,
    controls: PropTypes.object.isRequired,
    fetchItems: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
    itemsAssignments: PropTypes.arrayOf(PropTypes.object).isRequired,
    itemsConditions: PropTypes.arrayOf(PropTypes.shape(itemConditionShape)).isRequired,
    orderColumn: PropTypes.string.isRequired,
    orderDirection: PropTypes.string.isRequired,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    setControls: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    totalBranchesQty: PropTypes.object.isRequired,
    history: PropTypes.func.isRequired,
};

export default withStyles(styles)(BranchesTable);
