import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Badge,
    Tooltip,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import WarningIcon from '@material-ui/icons/PriorityHigh';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

const styles = theme => ({
    hybridTextField: {
        marginBottom: theme.spacing.unit * 2,
    },
    label: {
        fontSize: 11,
        fontWeight: 500,
        color: 'rgba(249, 170, 51, 1)',
        marginBottom: 4,
    },
    totalLabel: {
        fontSize: 11,
        fontWeight: 500,
        color: 'rgba(95, 144, 187, 1)',
        marginBottom: 4,
    },
    text: {
        fontSize: 16,
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.87)',
        minHeight: 19,
        whiteSpace: 'pre-line',
    },
    alert: {
        color: 'red',
        fontWeight: 500,
    },
    warning: {
        color: '#ff9800',
        fontWeight: 500,
    },
    total: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        color: 'rgba(0, 0, 0, .7)',
        fontSize: 40,
        fontWeight: 400,
        marginBottom: theme.spacing.unit * 1,
    },
    monthly: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        color: 'rgba(0, 0, 0, .5)',
        fontSize: 26,
        fontWeight: 400,
        marginBottom: theme.spacing.unit * 1,
        display: 'flex',
    },
    green: {
        color: '#4caf50',
        fontWeight: 500,
    },
    alertBadge: {
        color: '#fff',
        backgroundColor: theme.palette.primary.testFail,
        width: 17,
        height: 17,
    },
    warningBadge: {
        color: '#fff',
        backgroundColor: '#ff9800',
        width: 17,
        height: 17,
    },
    testBadge: {
        color: '#fff',
        backgroundColor: '#00ff00',
        width: 17,
        height: 17,
    },
    badgeIcon: {
        fontSize: 13,
        marginTop: 3,
    },
    alertTooltip: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.testFail,
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    varianceIcon: {
        fontSize: 44,
    },
    iconRed: {
        color: theme.palette.primary.testFail,
    },
    iconGreen: {
        color: theme.palette.primary.testPass,
    },
});

class HybridReportTextField extends Component {
    render() {
        const {
            total,
            monthly,
            classes,
            fieldStyle,
            green,
            icon,
            label,
            text,
            warning,
        } = this.props;

        return (
            <div className={classes.hybridTextField}>
                <div
                    className={
                        total
                            ? `${classes.totalLabel}`
                            : `${classes.label}`
                        }
                >
                    {label}
                </div>
                <div
                    className={
                        total
                            ? `${classes.text} ${classes.total}`
                            :monthly
                                ? `${classes.text} ${classes.monthly}`
                                    : classes.text
                        }
                >
                    {icon === 'up'
                        ? (
                            <ArrowDropUp className={`${classes.varianceIcon} ${classes.iconGreen}`} />
                        )
                        : icon === 'down'
                            ? (
                                <ArrowDropDown className={`${classes.varianceIcon} ${classes.iconRed}`} />
                            )
                            : null
                        }{text}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(HybridReportTextField);
