import React from 'react';
import {
    Button,
    Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';

const currentDateTime = moment.tz(moment(), 'Europe/London');

const styles = (theme) => ({
    tag: {
        backgroundColor: '#00bcd4',
        borderRadius: 4,
        boxSizing: 'border-box',
        color: '#fff',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.4,
        lineHeight: 4.75,
        marginRight: theme.spacing.unit * 3,
        minWidth: 64,
        padding: '10px 16px',
        textTransform: 'uppercase',
        verticalAlign: 'middle',
    },
    alert: {
        backgroundColor: '#ffffff',
        borderColor: '#ff0000',
        borderWidth: 2,
        borderStyle: 'solid',
        borderRadius: 4,
        boxSizing: 'border-box',
        color: '#ff0000',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.4,
        lineHeight: 1.2,
        // marginLeft: theme.spacing.unit * 3,
        width: '100%',
        padding: '10px 16px',
        textTransform: 'uppercase',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
    },
    badgeContainer: {
        marginTop: theme.spacing.unit * 4,
        marginBottom: theme.spacing.unit * 2,
    },
    alertContainer: {
        // marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 4,
        textAlign: 'left',
    },
    alertIcon: {
        marginRight: 8,
        color: '#ff0000',
    },
    alertText: {
        '&:not(:last-of-type)': {
            marginBottom: theme.spacing.unit * 2,
        },
    },
});

class ContactAgreementBadges extends React.Component {
    state = {
    };

    render() {
        const {
            classes,
            contact,
            contactAgreementsLoading,
            contactLoading,
            currentAgreement,
        } = this.props;

        // const badges = [
        //     {
        //         show: !contactLoading && contact.contactAgent,
        //         label: '#AGENT',
        //     },
        //     {
        //         show: !contactLoading && contact.contactUser,
        //         label: '#USER',
        //     },
        //     {
        //         show: !contactLoading && contact.contactEngineer,
        //         label: '#OPERATIVE',
        //     },
        //     {
        //         show: !contactLoading && !contactAgreementsLoading && (contact.agreements || []).length > 0,
        //         label: '#CUSTOMER',
        //     },
        // ];

        const alerts = [
            {
                show: !contactLoading && !contactAgreementsLoading && currentAgreement.installationIncluded,
                label: 'INSTALLATION INCLUDED',
            },
            {
                show: !contactLoading && !contactAgreementsLoading && currentAgreement.leadFastTrack,
                label: 'FAST TRACK',
            },
            {
                show: !contactLoading && !contactAgreementsLoading && currentAgreement.status === 'INCOMPLETE',
                label: 'AGREEMENT INCOMPLETE',
            },
            {
                show: !contactLoading && !contactAgreementsLoading && currentAgreement.plan.status === 'ACTIVE' && !currentAgreement.plan.card,
                label: 'NO PAYMENT CARD',
            },
            {
                show: !contactLoading && contact.forbidAgreement,
                label: 'AGREEMENTS PROHIBITED',
            },
            {
                show: !contactLoading
                && currentAgreement.status !== 'CANCELLED'
                && currentAgreement.status !== 'COMPLETE'
                && currentAgreement.authorisedPaymentSummary.filter((authorisedPayment) => authorisedPayment === true).length < currentAgreement.minimumPreDeliveryPayments,
                label: 'DO NOT DELIVER : Outstanding Pre-Delivery Payments',
            },
            {
                show: !contactLoading
                && currentAgreement.status === 'INCOMPLETE',
                label: 'DO NOT DELIVER : Agreement Not Active',
            },
            // {
            //     show: !contactLoading
            //     && currentAgreement.status !== 'CANCELLED'
            //     && currentAgreement.status !== 'COMPLETE'
            //     && currentAgreement.plan.arrearsDay,
            //     label: `PAYMENT PLAN ON ARREARS DAY ${currentAgreement.plan.arrearsDay}`,
            // },
            {
                show: !contactLoading
                && currentAgreement.selfMeasure,
                label: 'Self Measure Agreement',
            },
            {
                show: !contactLoading
                && currentAgreement.selfMeasure
                && !currentAgreement.selfMeasureCheckedByContactCode
                && ['ACTIVE', 'COMPLETE'].some((status) => currentAgreement.status === status),
                label: 'Self Measurement NOT Checked',
            },
        ];

        return (
            <>
                <Grid container spacing={0}>
                    {/* <Grid item xs={6}>
                        {badges.filter((badge) => badge.show).length > 0
                            ? (
                                <div className={classes.badgeContainer}>
                                    {badges.filter((badge) => badge.show).map((badge) => (
                                        <span className={classes.tag} key={badge.label}>
                                            {badge.label}
                                        </span>
                                    ))}
                                </div>
                            )
                            : null}
                    </Grid> */}
                    <Grid item xs={12}>
                        {alerts.filter((alert) => alert.show).length > 0
                            ? (
                                <div className={classes.alertContainer}>
                                    <div className={classes.alert}>
                                        {alerts.filter((alert) => alert.show).map((alert) => (
                                            <div className={classes.alertText} key={alert.label}>
                                                <FontAwesomeIcon icon={faExclamationTriangle} inverse color="red" className={classes.alertIcon} />
                                                {alert.label}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )
                            : null}
                    </Grid>

                    {/* <Grid item xs={6}>
                        {badges.filter((badge) => badge.show).length > 0
                            ? (
                                <div className={classes.badgeContainer}>
                                    {badges.filter((badge) => badge.show).map((badge) => (
                                        <React.Fragment key={badge.label}>
                                            <span className={classes.tag}>{badge.label}</span>
                                        </React.Fragment>
                                    ))}
                                </div>
                            )
                            : null}
                    </Grid>
                    <Grid item xs={6}>
                        {alerts.filter((alert) => alert.show).length > 0
                            ? (
                                <div className={classes.alertContainer}>
                                    {alerts.filter((alert) => alert.show).map((alert) => (
                                        <span className={classes.alert} key={alert.label}>
                                            <FontAwesomeIcon icon={faExclamationTriangle} inverse color="red" className={classes.alertIcon} />
                                            {alert.label}
                                        </span>
                                    ))}
                                </div>
                            )
                            : null}
                    </Grid>
 */}

                </Grid>
            </>
        );
    }
}

export default withStyles(styles)(ContactAgreementBadges);
