import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
    Badge,
    Switch,
    Tooltip,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/PriorityHigh';
import classNames from 'classnames';

const styles = (theme) => ({
    hybridTextField: {
        marginBottom: theme.spacing.unit * 2,
    },
    label: {
        fontSize: 11,
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: 4,
    },
    text: {
        fontSize: 16,
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.87)',
        minHeight: 19,
        whiteSpace: 'pre-line',
        display: 'flex',
    },
    alert: {
        color: 'red',
        fontWeight: 500,
    },
    warning: {
        color: '#ff9800',
        fontWeight: 500,
    },
    cancelled: {
        color: 'rgba(0, 0, 0, 0.40)',
    },
    green: {
        color: '#4caf50',
        fontWeight: 500,
    },
    cold: {
        color: '#00ffff',
        fontWeight: 500,
    },
    alertBadge: {
        color: '#fff',
        backgroundColor: theme.palette.primary.testFail,
        width: 17,
        height: 19,
        right: -8,
    },
    warningBadge: {
        color: '#fff',
        backgroundColor: '#ff9800',
        width: 17,
        height: 17,
    },
    testBadge: {
        color: '#fff',
        backgroundColor: '#00ff00',
        width: 17,
        height: 17,
    },
    badgeIcon: {
        fontSize: 13,
        marginTop: 3,
    },
    alertTooltip: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.testFail,
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    link: {
        color: '#2196f3',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    switch: {
        color: 'green',
    },
    disabled: {
        opacity: 0.5,
    },
    textEndIcon: {
        marginLeft: 8,
    },
});

// {alert
//     ? (
// <Badge
//     invisible={fieldStyle}
//     classes={{ badge: classes.badge }}
//     badgeContent={(<WarningIcon className={classes.badgeIcon} />)}
// >
//     {label}
// </Badge>
// )
// : (label)
// }
// </div>
// {alert
//     ? (
// <div
//     className={`${classes.text} ${classes.alert}`}
// >
//         {icon}
//         {text}
// </div>
// )
// : (
// <div
//     className={classes.text}
// >
//         {icon}
//         {text}
// </div>
//
// )
// }
// </div>
// );

// classes={{ badge: classes.alertBadge }}
// invisible={typeof fieldStyle === 'undefined'}

// className={(fieldStyle || {}).style === 'warning' ? `${classes.text} ${classes.warning}` : `${classes.text} ${classes.alert}`}

class HybridTextField extends Component {
    render() {
        const {
            action,
            active,
            alert,
            cancelled,
            classes,
            cold,
            disabled,
            fieldStyle,
            green,
            icon,
            label,
            postfix,
            text,
            toggleSwitch,
            warning,
        } = this.props;

        let content = null;

        switch (true) {
        case this.props.link: content = (
            <Link
                className={classes.link}
                to={this.props.link}
            >
                {text}
            </Link>
        );
            break;
        case typeof this.props.action === 'function':
            content = (
                <div
                    className={classes.link}
                    onClick={this.props.action}
                >
                    {text}
                </div>
            );
            break;
        default:
            content = (
                <>
                    {text}
                </>
            );
        }

        return (
            <div className={classes.hybridTextField}>
                <div
                    className={classes.label}
                >
                    <Badge
                        invisible={!fieldStyle}
                        classes={
                            (fieldStyle || {}).style === 'warning'
                                ? { badge: classes.warningBadge }
                                : (fieldStyle || {}).style === 'alert'
                                    ? { badge: classes.alertBadge }
                                    : null
                        }
                        badgeContent={(
                            <Tooltip
                                classes={{ tooltip: classes.alertTooltip }}
                                title={(fieldStyle || {}).tooltip || ''}
                                disableFocusListener={typeof (fieldStyle || {}).tooltip === 'undefined'}
                                disableHoverListener={typeof (fieldStyle || {}).tooltip === 'undefined'}
                                disableTouchListener={typeof (fieldStyle || {}).tooltip === 'undefined'}
                            >
                                <span><WarningIcon className={classes.badgeIcon} /></span>
                            </Tooltip>
                        )}
                    >
                        {label}
                    </Badge>
                </div>
                <div
                    className={classNames(
                        classes.text,
                        {
                            [classes.cancelled]: cancelled,
                        },
                        {
                            [classes.disabled]: disabled,
                        },
                        {
                            [classes.warning]: warning,
                        },
                        {
                            [classes.alert]: alert,
                        },
                        {
                            [classes.green]: green,
                        },
                        {
                            [classes.cold]: cold,
                        },
                    )}
                >
                    {icon}
                    {/* {this.props.link
                        ? (
                            <Link
                                className={classes.link}
                                to={this.props.link}
                            >
                                {text}
                            </Link>
                        )
                        : (
                            <React.Fragment className={classes.link}>
                                {text}
                            </React.Fragment>
                        )
                    } */}
                    {content}
                    <div className={classes.textEndIcon}>
                        <Tooltip
                            classes={{ tooltip: classes.alertTooltip }}
                            title={((postfix || {}).tooltip || '').toUpperCase()}
                        >
                            <div>
                                {(postfix || {}).content}
                            </div>
                        </Tooltip>
                    </div>
                    {toggleSwitch
                        ? (
                            <div>
                                <Switch
                                    className={classes.switch}
                                    checked={toggleSwitch.checked}
                                    // onChange={this.handleChange('checkedA')}
                                    value="checkedA"
                                    onClick={typeof toggleSwitch.action === 'function' ? this.props.toggleSwitch.action : undefined}
                                />
                            </div>
                        )
                        : null}

                </div>
            </div>
        );
    }
}

export default withStyles(styles)(HybridTextField);
