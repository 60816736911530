import React from 'react';
import {
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Checkbox,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    TextField,
    Select,
} from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
// import WarningIcon from '@material-ui/icons/Warning';

const styles = theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    snackbar: {
        backgroundColor: theme.palette.error.dark,
    },
    snackbarIcon: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
        fontSize: 20,
    // margin: theme.spacing.unit,
    },
    snackbarMessage: {
        fontSize: 'inherit',
        // marginBottom: theme.spacing.unit,
        display: 'flex',
        alignItems: 'center',
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    paper: {
        marginTop: theme.spacing.unit * 4,
        marginBottom: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
    },
});

const counties = [
    'Aberdeenshire',
    'Angus',
    'Antrim',
    'Argyll & Bute',
    'Armagh',
    'Ayrshire',
    'Banffshire',
    'Bedfordshire',
    'Berkshire',
    'Berwickshire',
    'Blaenau Gwent',
    'Borders',
    'Bridgend',
    'Bristol',
    'Buckinghamshire',
    'Caerphilly',
    'Caithness',
    'Cambridgeshire',
    'Cardiff',
    'Carmarthenshire',
    'Ceredigion',
    'Cheshire',
    'Clackmannanshire',
    'Conwy',
    'Cornwall',
    'County Durham',
    'Cumbria',
    'Denbighshire',
    'Derbyshire',
    'Devon',
    'Dorset',
    'Down',
    'Dumfries & Galloway',
    'Dunbartonshire',
    'East Ayrshire',
    'East Dunbartonshire',
    'East Lothian',
    'East Renfrewshire',
    'East Riding of Yorkshire',
    'East Sussex',
    'Essex',
    'Fermanagh',
    'Fife',
    'Flintshire',
    'Gloucestershire',
    'Greater London',
    'Greater Manchester',
    'Gwynedd',
    'Hampshire',
    'Herefordshire',
    'Hertfordshire',
    'Highland',
    'Inverclyde',
    'Isle of Anglesey',
    'Isle of Wight',
    'Isles of Scilly',
    'Kent',
    'Kincardineshire',
    'Lanarkshire',
    'Lancashire',
    'Leicestershire',
    'Lincolnshire',
    'Londonderry',
    'Merseyside',
    'Merthyr Tydfil',
    'Midlothian',
    'Monmouthshire',
    'Moray',
    'Neath Port Talbot',
    'Newport',
    'Norfolk',
    'North Ayrshire',
    'North Lanarkshire',
    'North Somerset',
    'North Yorkshire',
    'Northamptonshire',
    'Northumberland',
    'Nottinghamshire',
    'Orkney',
    'Oxfordshire',
    'Pembrokeshire',
    'Perth & Kinross',
    'Powys',
    'Renfrewshire',
    'Rhondda Cynon Taff',
    'Rutland',
    'Shetland',
    'Shropshire',
    'Somerset',
    'South Ayrshire',
    'South Gloucestershire',
    'South Lanarkshire',
    'South Yorkshire',
    'Staffordshire',
    'Stirlingshire',
    'Suffolk',
    'Surrey',
    'Swansea',
    'Torfaen',
    'Tyne & Wear',
    'Tyrone',
    'Vale of Glamorgan',
    'Warwickshire',
    'West Dunbartonshire',
    'West Lothian',
    'West Midlands',
    'West Sussex',
    'West Yorkshire',
    'Western Isles',
    'Wiltshire',
    'Worcestershire',
    'Wrexham',
];

const countries = [
    'United Kingdom',
];

const titles = [
    'Mr',
    'Mrs',
    'Miss',
    'Ms',
    'Mx',
];


const PromoForm = ({
    classes, updateLeadData, lead, saveLead, loading, errors,
}) => {
    console.log(lead);
    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <FormControl
                            className={classes.formControl}
                            variant="outlined"
                            fullWidth
                            required
                            error={!!errors.title}
                        >
                            <InputLabel htmlFor="lead-title" className={classes.inputLabel}>Title</InputLabel>
                            <Select
                                keyboard="true"
                                input={(
                                    <OutlinedInput
                                        onChange={updateLeadData}
                                        name="title"
                                        id="lead-title"
                                    />
                                )}
                                value={lead.title || ''}
                            >
                                <MenuItem value=""><em>None</em></MenuItem>
                                {titles.map(title => (
                                    <MenuItem
                                        value={title}
                                    >
                                        {title}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors.title}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.formControl}
                            onChange={updateLeadData}
                            name="firstname"
                            id="firstname"
                            label="Firstname"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            required
                            error={!!errors.firstname}
                            helperText={errors.firstname}
                            value={lead.firstname}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.formControl}
                            onChange={updateLeadData}
                            name="surname"
                            id="surname"
                            label="Surname"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            required
                            error={!!errors.surname}
                            helperText={errors.surname}
                            value={lead.surname}
                        />
                    </Grid>
                </Grid>
                <Divider className={classes.formDivider} />
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.formControl}
                            onChange={updateLeadData}
                            name="address1"
                            id="address1"
                            label="Address"
                            margin="normal"
                            variant="outlined"
                            type="text"
                            fullWidth
                            required
                            error={!!errors.address1}
                            helperText={errors.address1}
                            value={lead.address1}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.formControl}
                            onChange={updateLeadData}
                            name="address2"
                            id="address2"
                            label=""
                            margin="normal"
                            variant="outlined"
                            type="text"
                            fullWidth
                            error={!!errors.address2}
                            helperText={errors.address2}
                            value={lead.address2}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.formControl}
                            onChange={updateLeadData}
                            name="townCity"
                            id="townCity"
                            label="Town/City"
                            margin="normal"
                            variant="outlined"
                            type="text"
                            fullWidth
                            required
                            error={!!errors.townCity}
                            helperText={errors.townCity}
                            value={lead.townCity}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <FormControl
                            className={classes.formControl}
                            variant="outlined"
                            fullWidth
                            required
                            error={!!errors.county}
                        >
                            <InputLabel htmlFor="lead-county" className={classes.inputLabel}>County</InputLabel>
                            <Select
                                keyboard="true"
                                input={<OutlinedInput onChange={updateLeadData} name="county" id="lead-county" />}
                                value={lead.county || ''}
                            >
                                <MenuItem value=""><em>None</em></MenuItem>
                                {counties.map(county => (
                                    <MenuItem
                                        value={county}
                                    >
                                        {county}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors.county}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.formControl}
                            onChange={updateLeadData}
                            name="postcode"
                            id="postcode"
                            label="Postcode"
                            margin="normal"
                            variant="outlined"
                            type="text"
                            fullWidth
                            required
                            error={!!errors.postcode}
                            helperText={errors.postcode}
                            value={lead.postcode}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <FormControl
                            className={classes.formControl}
                            variant="outlined"
                            fullWidth
                            required
                            error={!!errors.country}
                        >
                            <InputLabel htmlFor="lead-country" className={classes.inputLabel}>Country</InputLabel>
                            <Select
                                keyboard="true"
                                input={<OutlinedInput onChange={updateLeadData} name="country" id="lead-country" />}
                                value={lead.country || ''}
                            >
                                <MenuItem value=""><em>None</em></MenuItem>
                                {countries.map(country => (
                                    <MenuItem
                                        value={country}
                                    >
                                        {country}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors.country}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider className={classes.formDivider} />
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <TextField
                            onChange={updateLeadData}
                            className={classes.formControl}
                            name="homeTelephoneNumber"
                            id="homeTelephoneNumber"
                            label="Home Phone"
                            margin="normal"
                            variant="outlined"
                            type="tel"
                            fullWidth
                            value={lead.homeTelephoneNumber}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.formControl}
                            onChange={updateLeadData}
                            id="mobileTelephoneNumber"
                            name="mobileTelephoneNumber"
                            label="Mobile Phone"
                            margin="normal"
                            variant="outlined"
                            type="tel"
                            fullWidth
                            required
                            error={!!errors.mobileTelephoneNumber}
                            helperText={errors.mobileTelephoneNumber}
                            value={lead.mobileTelephoneNumber}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.formControl}
                            onChange={updateLeadData}
                            id="emailAddress"
                            name="emailAddress"
                            label="Email Address"
                            margin="normal"
                            variant="outlined"
                            type="email"
                            fullWidth
                            required
                            error={!!errors.emailAddress}
                            helperText={errors.emailAddress}
                            value={lead.emailAddress}
                        />
                    </Grid>
                </Grid>
                <Divider className={classes.formDivider} />

                <FormControlLabel
                    control={(
                        <Checkbox
                            onChange={updateLeadData}
                            name="gdpr"
                            id="gdpr"
                            value={lead.gdpr}
                            color="primary"
                            checked={lead.gdpr}
                        />
                    )}
                    label="Tick box if you want us to send special offers and product information."
                />
                {/* <Snackbar
                open={this.state.snackbarOpen}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                autoHideDuration={5000}
                message={(
                    <span className={classes.snackbarMessage}>
                        <WarningIcon className={classes.snackbarIcon} />
                        {this.state.error}
                    </span>
                )}
                ContentProps={{ classes: { root: classes.snackbar } }}
                onClose={this.handleSnackbarClose}
            /> */}
                {/* { this.state.error && <SnackbarContent variant="error" message={this.state.error} />} */}
                {/* </Snackbar> */}
                <Button
                    type="button"
                    onClick={saveLead}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={loading}
                >
                    { loading ? 'Updating...' : 'Update' }
                </Button>
            </Paper>
        </React.Fragment>
        );
}

const formShape = {
    title: PropTypes.string,
    firstname: PropTypes.string,
    surname: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    townCity: PropTypes.string,
    county: PropTypes.string,
    postcode: PropTypes.string,
    country: PropTypes.string,
    homeTelephoneNumber: PropTypes.string,
    mobileTelephoneNumber: PropTypes.string,
    emailAddress: PropTypes.string,
    gdpr: PropTypes.number,
};

PromoForm.propTypes = {
    classes: PropTypes.object.isRequired,
    updateLeadData: PropTypes.func.isRequired,
    lead: PropTypes.shape(formShape).isRequired,
    errors: PropTypes.shape(formShape).isRequired,
    saveLead: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(PromoForm);
