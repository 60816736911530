import React from 'react';
import {
    CssBaseline,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import fetch, { fetchJson } from '../utilities/fetch';
import ItemsTable from '../components/itemsTable';
import ItemsSummary from '../components/itemsSummary';
import Layout from './Layout';

const cookies = new Cookies();

const styles = theme => ({
    newDeliveryNoteButton: {
        marginBottom: theme.spacing.unit * 3,
    },
});

class Items extends React.Component {
    state = {
        bins: null,
        branches: null,
        controls: null,
        defaultControls: {
            pageSize: 25,
            pageNumber: 1,
            orderColumn: 'manufacturer',
            orderDirection: 'asc',
        },
        defaultFilters: {
            assignment: 'In Stock',
        },
        filters: {},
        items: null,
        itemsAssignments: null,
        itemsBranchSummary: null,
        itemsConditions: null,
        keyLookup: {
            assignment: 'assignment',
            bin: 'bin',
            branch: 'branchName',
            code: 'code',
            colour: 'colour',
            condition: 'itemConditionDescription',
            length: 'length',
            manufacturer: 'manufacturerName',
            model: 'modelNo',
            stockRoom: 'stockRoom',
            type: 'productTypeDescription',
            width: 'width',
        },
        products: null,
        selectData: [],
        stockrooms: null,
        totalItemQty: null,
    }

    componentDidMount = async () => {
        await this.setDefaultFilters();
        await this.setDefaultControls();
        this.fetchItems(this.state.controls, this.state.filters, false).then((items) => {
            this.setState({
                items,
            });
        });
        this.fetchMenus();
    }

    setDefaultFilters = async () => {
        const itemsFiltersCookie = cookies.get('itemsFilters') || {};
        let newFilters = {
            ...this.state.defaultFilters,
            ...itemsFiltersCookie,
        };
        const itemsFiltersCookieQty = Object.keys(itemsFiltersCookie).length;
        const user = await fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/user');
        if (itemsFiltersCookieQty === 0) {
            newFilters = {
                ...newFilters,
                branch: user.branchName,
            };
        }
        const statePromise = new Promise((resolve) => {
            this.setState({
                filters: newFilters,
            }, resolve);
        });
        cookies.set('itemsFilters', newFilters, { path: '/' });
        return statePromise;
    }

    setDefaultControls = async () => {
        const itemsControlsCookie = cookies.get('itemsControls') || {};
        const newControls = {
            ...this.state.defaultControls,
            ...itemsControlsCookie,
        };

        const statePromise = new Promise((resolve) => {
            this.setState({
                controls: newControls,
            }, resolve);
        });
        cookies.set('itemsControls', newControls, { path: '/' });
        return statePromise;
    }

    fetchItems = async (controls, filters, download) => {
        if (!download) {
            this.setState({
                items: null,
            });
        }
        const itemsURL = new URL('https://api.portal2.payweeklycarpets.co.uk/core/barcodeItemsList');
        const itemsBranchSummaryURL = new URL('https://api.portal2.payweeklycarpets.co.uk/core/itemsList/reportSummary');
        const params = {
            ...controls,
            ...filters,
        };
        Object.keys(params).forEach(key => itemsURL.searchParams.append(key, params[key]));
        Object.keys(params).forEach(key => itemsBranchSummaryURL.searchParams.append(key, params[key]));
        const items = await fetchJson(itemsURL);
        if (!download) {
            const itemsBranchSummary = await fetchJson(itemsBranchSummaryURL);
            const totalItemQty = itemsBranchSummary.reduce((acc, branch) => (branch.itemQty + acc), 0);
            this.setState({
                itemsBranchSummary,
                totalItemQty,
            });
        }
        return (items);
    }

    fetchMenus = () => {
        const fetchProducts = fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/products?continuousRental=1&repaymentRental=1&sale=1')
            .then((products) => {
                this.setState({
                    products,
                });
            });
        const fetchBranches = fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/branches')
            .then((branches) => {
                this.setState({
                    branches,
                });
            });
        const fetchStockrooms = fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/stockrooms')
            .then((stockrooms) => {
                this.setState({
                    stockrooms,
                });
            });
        const fetchBins = fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/bins')
            .then((bins) => {
                this.setState({
                    bins,
                });
            });
        const fetchItemConditions = fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/items/conditions')
            .then((itemsConditions) => {
                this.setState({
                    itemsConditions,
                });
            });
        const fetchItemsAssignments = fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/item-assignments')
            .then((itemsAssignments) => {
                this.setState({
                    itemsAssignments,
                });
            });
        return Promise.all([fetchProducts, fetchBranches, fetchStockrooms, fetchBins, fetchItemConditions, fetchItemsAssignments]);
    }

    removeFilter = (keyToRemove) => {
        const newFiltersState = {};
        Object.keys(this.state.filters).map((key) => {
            if (key === keyToRemove) return;
            newFiltersState[key] = this.state.filters[key];
        });
        const newControlsState = {
            ...this.state.controls,
            pageNumber: 1,
        };
        this.fetchItems(newControlsState, newFiltersState, false).then((items) => {
            this.setState({
                items,
            });
        });
        this.setState({
            controls: newControlsState,
            filters: newFiltersState,
        });
        cookies.set('itemsFilters', newFiltersState, { path: '/' });
        cookies.set('itemsControls', newControlsState, { path: '/' });
    }

    setFilters = async (newFilters) => {
        const newFiltersState = {
            ...this.state.filters,
            ...newFilters,
        };
        const newControlsState = {
            ...this.state.controls,
            pageNumber: 1,
        };
        this.fetchItems(newControlsState, newFiltersState, false).then((items) => {
            this.setState({
                items,
            });
        });
        const statePromise = new Promise((resolve) => {
            this.setState({
                controls: newControlsState,
                filters: newFilters,
            }, resolve);
        });
        cookies.set('itemsFilters', newFilters, { path: '/' });
        cookies.set('itemsControls', newControlsState, { path: '/' });
        return statePromise;
    }

    setControls = async (newControls) => {
        this.fetchItems(newControls, this.state.filters, false).then((items) => {
            this.setState({
                items,
            });
        });
        const statePromise = new Promise((resolve) => {
            this.setState({
                controls: newControls,
            }, resolve);
        });
        cookies.set('itemsControls', newControls, { path: '/' });
        return statePromise;
    }

    editItems = async (payload) => {
        let response;
        let body;
        console.log('>>>Edit Items Payload', payload);
        try {
            response = await fetch('https://api.portal2.payweeklycarpets.co.uk/core/items', {
                method: 'PATCH',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState({
                selectData: [],
            });
            const currentControls = this.state.controls;
            const currentFilters = this.state.filters;
            this.fetchItems(currentControls, currentFilters, false).then((items) => {
                this.setState({
                    items,
                });
            });

            // this.setState(state => ({
            //     deliveryNoteItems: [
            //         ...body,
            //         ...state.deliveryNoteItems,
            //     ],
            // }));
            console.log('>>>Body', body);
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    }

    updateItemSelectCheckbox = name => (event) => {
        const newState = event.target.checked ? 1 : 0;
        let newSelectData = [];
        if (newState === 1) {
            newSelectData = [
                ...this.state.selectData,
                ...[name],
            ];
        } else {
            newSelectData = this.state.selectData.filter(itemCode => itemCode !== name);
        }

        this.setState({
            selectData: [
                ...newSelectData,
            ],
        });
    }

    render() {
        const {
            history,
        } = this.props;
        const {
            bins,
            branches,
            columnLookup,
            controls,
            filters,
            items,
            itemsAssignments,
            itemsBranchSummary,
            itemsConditions,
            keyLookup,
            products,
            totalItemQty,
            selectData,
            stockrooms,
        } = this.state;

        return (
            <React.Fragment>
                <Layout pageTitle="Items">
                    <CssBaseline />
                    <ItemsSummary
                        filters={filters}
                        itemsBranchSummary={itemsBranchSummary}
                        removeFilter={this.removeFilter}
                        setFilters={this.setFilters}
                        totalItemQty={totalItemQty}
                    />
                    <ItemsTable
                        bins={bins}
                        branches={branches}
                        columnLookup={columnLookup}
                        controls={controls}
                        editItems={this.editItems}
                        fetchItems={this.fetchItems}
                        filters={filters}
                        history={history}
                        items={items}
                        itemsAssignments={itemsAssignments}
                        itemsConditions={itemsConditions}
                        keyLookup={keyLookup}
                        products={products}
                        removeFilter={this.removeFilter}
                        selectData={selectData}
                        setControls={this.setControls}
                        setFilters={this.setFilters}
                        stockrooms={stockrooms}
                        totalItemQty={totalItemQty}
                        updateItemSelectCheckbox={this.updateItemSelectCheckbox}
                    />
                </Layout>
            </React.Fragment>
        );
    }
}

Items.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withStyles(styles)(Items);
