import React from 'react';
import {
    Button,
    Divider,
    Grid,
    Menu,
    MenuItem,
    Paper,
} from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import MenuIcon from '@material-ui/icons/Menu';

const styles = theme => ({
    paper: {
        // width: 200,
        // minHeight: 200,
        // marginTop: theme.spacing.unit * 4,
        // marginBottom: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    titleBarContainer: {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        width: '100%',
    },
    tileTitle: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        textTransform: 'uppercase',
        // backgroundColor: theme.palette.primary.light,
        // margin: 0,
        // width: '100%',
        alignItems: 'left',
        paddingLeft: theme.spacing.unit * 2,
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 15,
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.60)',
        // borderTopLeftRadius: 4,
        // borderTopRightRadius: 4,
    },
    tileContent: {
        minHeight: 80,
        // marginTop: theme.spacing.unit * 4,
        marginRight: theme.spacing.unit * 4,
        marginBottom: theme.spacing.unit * 4,
        marginLeft: theme.spacing.unit * 4,
    },
    tileMenu: {
        lineHeight: 1.5,
        letterSpacing: '0.16065px',
        marginTop: 6,
        // width: '100%',
        textAlign: 'right',
        color: 'white',
        paddingRight: theme.spacing.unit * 2,
        // paddingTop: theme.spacing.unit,
        // paddingBottom: theme.spacing.unit,
        fontSize: 15,
        fontWeight: 500,
    },
    menuIcon: {
        cursor: 'pointer',
        marginTop: 1,
        color: 'white',
        fontSize: 23,
    },
});

class Tile extends React.Component {
    state = {
        anchorEl: null,
    };

    handleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    render() {
        const {
            classes,
            children,
            tileTitle,
            style,
            tileMenuItems,
            scrollToMarker,
        } = this.props;
        const {
            anchorEl,
        } = this.state;

        return (
            <React.Fragment>
                <Paper name={scrollToMarker} className={classes.paper}>
                    <div className={classes.titleBarContainer}>
                        <Grid container spacing={0}>
                            <Grid item xs={10}>
                                <div className={classes.tileTitle}>
                                    {tileTitle}
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                {tileMenuItems
                                    && (
                                        <div className={classes.tileMenu}>
                                            <MenuIcon
                                                className={classes.menuIcon}
                                                aria-controls="simple-menu"
                                                aria-haspopup="true"
                                                onClick={this.handleClick}
                                                />
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={this.handleClose}
                                            >
                                                {tileMenuItems.map((tileMenuItem, index) => (tileMenuItem === 'divider'
                                                        ? (<Divider key={`${index}-divider`} className={classes.dialogDivider} />)
                                                        : (<MenuItem key={tileMenuItem.label} onClick={() => { this.handleClose(); tileMenuItem.action(); }}>{tileMenuItem.label}</MenuItem>)))}
                                            </Menu>
                                        </div>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <div className={style && classes.tileContent}>
                                {children}
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </React.Fragment>
        );
    }
}

Tile.propTypes = {
    classes: PropTypes.object.isRequired,
    style: PropTypes.bool,
};

export default withStyles(styles)(Tile);
