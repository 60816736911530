import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

const styles = theme => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        borderRight: '1px solid #e0e0e0',
        borderLeft: '1px solid #e0e0e0',
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 16,
    },
    totalCell: {
        borderBottom: 0,
    },
});

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 16,
    },
}))(TableCell);

class BranchesSummaryTable extends React.Component {
    state = {

    }

    handleBranchParentClick = (newParentBranchName) => {
        const newFilters = {
            ...this.props.filters,
            parentBranch: newParentBranchName,
        };
        this.props.setFilters(newFilters);
    }

    render() {
        const {
            classes,
            branchesParentSummary,
            totalBranchesQty,
        } = this.props;

        return (
            <Table className={classes.table} padding="dense">
                <TableHead>
                    <TableRow>
                        <CustomTableCell className={classes.tableHead}>Parent Branch</CustomTableCell>
                        <CustomTableCell align="right" className={classes.tableHead}>Qty</CustomTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderBy(uniqBy(branchesParentSummary, 'parentBranch'), 'parentBranch').map(parentBranch => (
                        <TableRow className={classes.row} key={parentBranch.parentBranch} onClick={() => this.handleBranchParentClick(parentBranch.parentBranch)}>
                            <CustomTableCell className={classes.customTableCell} align="left">{parentBranch.parentBranch}</CustomTableCell>
                            <CustomTableCell className={classes.customTableCell} align="right">{parentBranch.branchQty}</CustomTableCell>
                        </TableRow>
                    ))}
                    <TableRow key="tableTotal">
                        <CustomTableCell className={`${classes.customTableCell} ${classes.totalCell}`} align="right">Total</CustomTableCell>
                        <CustomTableCell className={`${classes.customTableCell} ${classes.totalCell}`} align="right">
                            {totalBranchesQty}
                        </CustomTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }
}

const branchesParentSummaryShape = {
    parentBranch: PropTypes.string,
    branchQty: PropTypes.number,
};

BranchesSummaryTable.propTypes = {
    classes: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    branchesParentSummary: PropTypes.arrayOf(PropTypes.shape(branchesParentSummaryShape)).isRequired,
    totalBranchesQty: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
};

export default withStyles(styles)(BranchesSummaryTable);
