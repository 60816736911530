import React from 'react';
import {
    withStyles,
} from '@material-ui/core/styles';
import {
    Badge,
    Chip,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    TableCell,
} from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import {
    Link,
    DirectLink,
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller
} from 'react-scroll'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withSnackbar } from 'notistack';
import DeliveryIcon from '@material-ui/icons/LocalShipping';
import TickIcon from '@material-ui/icons/Done';
import HybridTextField from '../hybridTextField';

const styles = theme => ({
    itemPanel: {
        width: '100%',
    },
    itemExpansionPanelDetails: {
        display: 'block',
        paddingTop: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 3,
    },
    itemExpansionSummary: {
        backgroundColor: '#e6e7e8',
        paddingRight: theme.spacing.unit * 5,
    },
    label: {
        fontSize: 11,
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: 7,
        width: '100%',
        textAlign: 'left',
    },
    itemDescription: {
        fontSize: 13,
        color: 'rgba(0, 0, 0, 0.54)',
    },
    componentContainer: {
        width: '100%',
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: theme.spacing.unit * 1.5,
    },
    greenChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '50px',
    },
    redChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '50px',
    },
    chip: {
        height: 21,
        borderRadius: 5,
    },
    deliveryIcon: {
        color: '#7a7a7b',
        fontSize: 28,
    },
    greenBadge: {
        color: '#fff',
        backgroundColor: theme.palette.primary.testPass,
    },
    alertBadge: {
        color: '#fff',
        backgroundColor: theme.palette.primary.testFail,
    },
    tickIcon: {
        fontSize: 16,
    },
    crossIcon: {
        fontSize: 16,
    },
    itemExpansionSummaryContainerLeft: {
        display: 'flex',
    },
    itemExpansionSummaryContainerRight: {
        display: 'flex',
        float: 'right',
    },
    itemExpansionSummaryIndex: {
        marginRight: theme.spacing.unit * 3,
    },
    itemSummaryControlChip: {
        marginLeft: theme.spacing.unit * 5,
        // marginRight: theme.spacing.unit * 2,
        display: 'flex',
        alignItems: 'center',
        paddingTop: 2,
    },
    itemSummaryDeliveryIcon: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 6,
    },

    tileBottomDivider: {
        marginTop: theme.spacing.unit * 3,
        width: '100%',
    },
    bottomBarContainer: {
        width: '100%',
        textAlign: 'right',
    },
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    hybridTextIconLeft: {
        transform: 'translate(0%, 13%)',
        marginRight: theme.spacing.unit * 1,
        fontSize: 16,
    },
    hybridTextIconRight: {
        transform: 'translate(0%, 13%)',
        marginLeft: theme.spacing.unit * 1,
        fontSize: 16,
    },
    hotIcon: {
        color: '#f1180b',
    },
    warmIcon: {
        color: '#E7C251',
    },
    coldIcon: {
        color: '#BBFFFF',
    },
    notSetIcon: {
        color: '#d7d7d7',
    },
    convertedIcon: {
        color: '#4caf50',
    },
    financeIcon: {
        color: '#2c18ff',
    },

    tabsPaper: {
        paddingTop: 32,
        paddingLeft: 32,
        paddingRight: 32,
        paddingBottom: 12,

        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },
    tabsAppBar: {
        backgroundColor: theme.palette.primary.lighter,
        // borderTopLeftRadius: 4,
        // borderTopRightRadius: 4,
    },
    tab: {
        color: 'white',
        fontSize: 15,
        fontWeight: 500,
        height: 38,
        minHeight: 38,
    },
    tabs: {
        padding: 0,
        minHeight: 38,
        height: 38,
        // backgroundColor: 'red',
    },
    tabButton: {
        padding: 0,
        minHeight: 38,
        height: 38,
        backgroundColor: 'red',
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    formInstructions: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    },
    statusSelect: {
        width: 200,
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        width: '100%',
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
        minWidth: 350,
    },
    dialogActionsDivider: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    radioDescription: {
        marginLeft: 34,
        marginTop: -8,
        marginRight: theme.spacing.unit * 3,
    },
    issuerRadioDescription: {
        margin: 0,
        color: theme.palette.text.secondary,
    },
    disabledText: {
        color: 'rgba(0, 0, 0, 0.38)',
    },
    radioButtonsContainer: {
        marginLeft: theme.spacing.unit * 3,
    },
    menuItemText: {
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeight,
        color: theme.palette.text.primary,
    },
    menuItemSubtext: {
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeight,
        color: theme.palette.text.secondary,
    },
    selectMultiline: {
        height: 75,
    },
    convertDialogContent: {
        paddingBottom: 0,
        width: 540,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
        height: 48,
    },
    tableHeadRow: {
        height: 48,
    },
    tableIndex: {
        verticalAlign: 'top',
        paddingTop: theme.spacing.unit,
    },
    marginTop3: {
        marginTop: theme.spacing.unit * 3,
    },
    inputAdornment: {
        fontSize: 16,
        marginTop: 1,
        color: theme.palette.text.secondary,
    },
    // divider: {
    //     marginTop: theme.spacing.unit * 2,
    //     marginBottom: theme.spacing.unit * 2,
    // },
    progress: {
        margin: theme.spacing.unit * 3,
    },
    declinedStatusIcon: {
        fontSize: 100,
        color: red[400],
        margin: theme.spacing.unit * 2,
    },
    authorisedStatusIcon: {
        fontSize: 100,
        color: green[400],
        margin: theme.spacing.unit * 2,
    },
    ErrorStatusIcon: {
        fontSize: 100,
        color: amber[400],
        margin: theme.spacing.unit * 2,
    },
    cardRadio: {
        paddingTop: '0px',
    },
    issuerRadioLabel: {
        marginTop: theme.spacing.unit * 1,
    },
});

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 16,
        height: 48,
    },
    body: {
        fontSize: 14,
        paddingLeft: 16,
    },
}))(TableCell);

const scheduleFrequencies = [
    'Weekly',
    'Monthly',
];

const scheduleWeekdays = [
    { label: 'Monday', value: 1 },
    { label: 'Tuesday', value: 2 },
    { label: 'Wednesday', value: 3 },
    { label: 'Thursday', value: 4 },
    { label: 'Friday', value: 5 },
    { label: 'Saturday', value: 6 },
    { label: 'Sunday', value: 7 },
];

class CustomerAgreementItems extends React.Component {
    state = {
        expanded: 0,
        value: 0,
        showManualPaymentDialog: false,
        disableSubmitManualPaymentButton: false,
        disableManualPaymentNextButton: true,
        manualPaymentForm: {
            amount: '',
            paymentCard: '',
        },
        manualPaymentStage: 'amount',
        manualPaymentStages: [
            'amount',
            'cards',
            'payment',
            'result',
        ],
        manualPaymentTransctionStatus: '',
    }

    async componentDidMount() {
        if (this.props.requestedAgreementTab >= 0) {
            await this.scrollToAgreementsTop();
            this.setState({
                value: this.props.requestedAgreementTab,
            });
        }
    }

    scrollToAgreementsTop = () => {
        scroller.scrollTo('agreementsTop', {
            duration: 800,
            delay: 100,
            smooth: 'easeInOutQuart',
            offset: -120,
        });
    }

    capitaliseFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

    updateSelectedProduct = (event) => {
        const { value, name } = event.target;
        const selectedProduct = this.props.convertLeadProducts.find(product => product.code === value);
        selectedProduct.uid = Math.random().toString(36).substr(2, 9);
        this.setState({
            selectedProduct,
        });
    }

    handleUpdateLeadClick = () => {
        this.setState(state => ({
            leadForm: {
                code: this.props.leads[state.value].code,
                status: this.props.leads[state.value].status,
            },
            showUpdateLeadDialog: true,
        }));
    }

    handleConvertLeadClick = () => {
        this.setState(state => ({
            convertForm: {
                ...state.convertForm,
                code: this.props.leads[state.value].code,
                status: this.props.leads[state.value].status,
            },
            showConvertLeadDialog: true,
        }));
    }

    handleCloseUpdateLeadDialogClick = () => {
        this.setState({ showUpdateLeadDialog: false });
    }

    handleCloseConvertLeadDialogClick = () => {
        this.setState({ showConvertLeadDialog: false });
    }

    iconStatus = (statusString) => {
        switch (statusString) {
        case 'New': {
            return (this.props.classes.notSetIcon);
        }
        case 'Hot': {
            return (this.props.classes.hotIcon);
        }
        case 'Warm': {
            return (this.props.classes.warmIcon);
        }
        case 'Cold': {
            return (this.props.classes.coldIcon);
        }
        case 'Finance': {
            return (this.props.classes.financeIcon);
        }
        default: {
            return (this.props.classes.notSetIcon);
        }
        }
    }

    handleSaveLeadUpdateClick = (event) => {
        event.preventDefault();
        this.saveUpdate();
        // console.log(this.state.leadForm);
        // this.setState({
        //     disableSaveLeadUpdateButton: true,
        // });
        // this.props.saveLeadUpdate(this.state.leadForm).then(() => {
        //     this.setState({
        //         showUpdateLeadDialog: false,
        //         disableSaveLeadUpdateButton: false,
        //     });
        //     this.props.enqueueSnackbar('Lead updated', { variant: 'success' });
        // }).catch(() => {
        //     console.log(this.props.errors);
        //     this.props.enqueueSnackbar('Lead update failed', { variant: 'error' });
        //     this.setState({
        //         disableSaveLeadUpdateButton: false,
        //     });
        // });
    }

    saveUpdate = () => {
        this.setState({
            disableSaveLeadUpdateButton: true,
        });
        this.props.saveLeadUpdate(this.state.leadForm).then(() => {
            this.setState({
                showUpdateLeadDialog: false,
                disableSaveLeadUpdateButton: false,
            });
            this.props.enqueueSnackbar('Lead updated', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Lead update failed', { variant: 'error' });
            this.setState({
                disableSaveLeadUpdateButton: false,
            });
        });
    }

    handleLeadChange = (event, newValue) => {
        this.setState({ value: newValue });
    }



    handleAddProductClick = () => {
        this.setState(state => ({
            convertForm: {
                ...state.convertForm,
                products: [
                    ...state.convertForm.products,
                    state.selectedProduct,
                ],
            },
            selectedProduct: {
                ...state.selectedProduct,
                uid: Math.random().toString(36).substr(2, 9),
            },
        }));
    }

    removeConvertProduct = (uid) => {
        console.log(uid);
        const newProducts = [];
        this.state.convertForm.products.map((product) => {
            if (product.uid !== uid) {
                newProducts.push(product);
            }
        });
        this.setState(state => ({
            convertForm: {
                ...state.convertForm,
                products: newProducts,
            },
        }));
    }

    ordinalSuffixOf = (i) => {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }



    ///////

    handleManualPaymentClick = () => {
        this.setState(state => ({
            showManualPaymentDialog: true,
        }));
    }

    handleCloseManualPaymentDialogClick = () => {
        this.setState(state => ({
            showManualPaymentDialog: false,
            manualPaymentStage: 'amount',
        }));
    }

    handleManualPaymentNextClick = async () => {
        let manualPaymentStageEnum;
        manualPaymentStageEnum = this.state.manualPaymentStages.indexOf(this.state.manualPaymentStage);
        manualPaymentStageEnum += 1;
        switch (this.state.manualPaymentStages[manualPaymentStageEnum]) {
        case 'cards':
            this.setState({ disableManualPaymentNextButton: true });
            this.setState({
                disableManualPaymentNextButton: false,
            });
            break;
        case 'payment':
            this.setState({
                transactionStatusWaiting: true,
                disableManualPaymentNextButton: true,
            });
            const amount = this.state.manualPaymentForm.amount;
            const agreementCode = this.props.customer.agreements[this.state.value].PlanReference;
            const paymentCard = this.state.manualPaymentForm.paymentCard;
            if (paymentCard === 'new') {
                manualPaymentStageEnum += 1; // Change manualPaymentStage to 'result'
                this.newCardManualPayment(agreementCode, amount);
            } else {
                const cardNo = this.props.paymentCards.find(card => card.token === paymentCard).cardNo;
                manualPaymentStageEnum += 1; // Change manualPaymentStage to 'result'
                await this.tokenManualPayment(agreementCode, amount, paymentCard, cardNo).then(paymentTransactionStatus => {

                    this.setState({
                        transactionStatusWaiting: false,
                        manualPaymentTransctionStatus: paymentTransactionStatus,
                    });
                });
            }
            break;
        }
        this.setState({
            disableManualPaymentNextButton: false,
            manualPaymentStage: this.state.manualPaymentStages[manualPaymentStageEnum],
        });
    }

    updateManualPaymentData = (event) => {
        console.log('here!');
        const { value, name } = event.target;
        let formattedValue;
        switch (name) {
        case 'amount':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            this.setState({
                disableManualPaymentNextButton: !parseInt(formattedValue) > 0,
            });
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState({
            manualPaymentForm: {
                ...this.state.manualPaymentForm,
                [name]: formattedValue,
            },
        });
    }

    newCardManualPayment = (agreementCode, amount) => {
        this.props.manualPayment({
            agreementCode: agreementCode,
            amount: amount,
            paymentExpiry: 90,
            paymentReturnURL: `https://${window.location.hostname}/customer/${this.props.customer.code}?paymentType=manual&agreementCode=${agreementCode}`,
        });
    }

    tokenManualPayment = (agreementCode, amount, token, cardNo) => {
        return this.props.tokenManualPayment({
            agreementCode: agreementCode,
            paymentAmount: amount,
            paymentCardToken: token,
            paymentCardNo: cardNo,
        });
    }

    handleTryAgainClick = () => {
        this.setState({
            manualPaymentStage: 'amount',
        });
    }

    handleChange = async (itemCode) => {
        this.setState({
            expanded: itemCode,
        });
    }

    render() {
        const {
            classes,
            items,
        } = this.props;
        const {
            expanded,
            value,
            showManualPaymentDialog,
            disableSubmitManualPaymentButton,
            disableManualPaymentNextButton,
            manualPaymentForm,
            manualPaymentStage,
            manualPaymentTransctionStatus,
        } = this.state;

        return (
            <div className={classes.componentContainer}>
                <div
                    className={classes.label}
                >
                    Items
                </div>
                {items.length > 0
                    ? (
                        <React.Fragment>
                            {items.map((item, index) => (
                                <ExpansionPanel
                                    key={item.code}
                                    expanded={expanded === item.code}
                                    onChange={() => this.handleChange(item.code)}
                                    className={classes.itemPanel}
                                >
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`${item.code}-content`}
                                        id={`${item.code}-header`}
                                        className={classes.itemExpansionSummary}
                                    >
                                        <Grid container spacing={0}>
                                            <Grid item xs={6}>
                                            <div className={classes.itemExpansionSummaryContainerLeft}>
                                                <div className  ={classes.itemExpansionSummaryIndex}>
                                                    {index + 1}
                                                </div>
                                                <div className={classes.itemExpansionSummaryTitleContainer}>
                                                    <div className={classes.heading}>
                                                        {`${item.manufacturer} ${item.modelNo}`}
                                                    </div>
                                                    <div className={classes.itemDescription}>
                                                        {item.shortDescription}
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className={classes.itemExpansionSummaryContainerRight}>
                                                <div className={classes.itemSummaryDeliveryIcon}>
                                                    <Badge
                                                        classes={
                                                            item.deliveryStatus
                                                                ? { badge: classes.greenBadge }
                                                                : { badge: classes.alertBadge }
                                                            }
                                                        badgeContent={(<TickIcon className={classes.tickIcon} />)}>
                                                        <DeliveryIcon className={classes.deliveryIcon} />
                                                    </Badge>
                                                </div>
                                                {item.controlDeviceState
                                                    ? (
                                                        <div className={classes.itemSummaryControlChip}>
                                                            {item.controlDeviceState === 'ON'
                                                                ? <Chip label="ON" className={`${classes.chip} ${classes.greenChip}`} />
                                                                : <Chip label="OFF" className={`${classes.chip} ${classes.redChip}`} />
                                                            }
                                                        </div>
                                                        )
                                                    : null
                                                }
                                            </div>
                                        </Grid>
                                    </Grid>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails
                                        className={classes.itemExpansionPanelDetails}
                                    >
                                        <div>
                                            <Grid container spacing={0}>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Stock Code"
                                                        text={item.itemCode
                                                            ? `SC${item.itemCode}`
                                                            : item.stockControlType !== 'Tracked'
                                                            ? item.stockControlType
                                                            : 'Unassigned'
                                                        }
                                                        link={`/item/${item.itemCode}`}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {item.stockControlType === 'Tracked'
                                                        ? (
                                                            <HybridTextField
                                                                label="Serial Number"
                                                                text={item.serialNumber}
                                                            />
                                                        )
                                                        : null
                                                    }
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={0}>
                                                <Grid item xs={4}>
                                                    <HybridTextField label="Delivery Status" text={item.deliveryStatus ? 'Delivered' : 'Undelivered'} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {item.deliveryStatus
                                                        ? (
                                                            <HybridTextField
                                                                label="Delivery Time/Date"
                                                                text={item.deliveryDateTime}
                                                            />
                                                        )
                                                        : null
                                                    }
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {item.deliveryStatus
                                                        ? (
                                                            <HybridTextField
                                                                label="Delivered By"
                                                                text={item.delveredByFullName || ''}
                                                            />
                                                        )
                                                        : null
                                                    }
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={0}>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Control Device"
                                                        text={item.controlDeviceCode ? 'Fitted' : 'None'}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {item.controlDeviceCode
                                                        ? (
                                                        <HybridTextField
                                                            label="State"
                                                            text={item.controlDeviceState}
                                                        />
                                                        )
                                                        : null
                                                    }
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {item.controlDeviceCode
                                                        ? (
                                                        <HybridTextField
                                                            label="Last Sent"
                                                            text={item.lastCommandSent}
                                                        />
                                                        )
                                                        : null
                                                    }
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            ))}
                        </React.Fragment>
                    )
                    : ( <div>No items found</div>)
                }

            </div>
        );
    }
}

export default withSnackbar(withStyles(styles)(CustomerAgreementItems));
