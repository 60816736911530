import React from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import fetch from '../utilities/fetch';
import sleep from '../utilities/sleep';

const styles = theme => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    divider: {
        marginTop: theme.spacing.unit * 2,
        // marginBottom: theme.spacing.unit * 2,
    },
    bodyContainer: {
        minHeight: 100,
    },
    bottomBarContainer: {
        width: '100%',
        textAlign: 'right',
    },
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    dialogContentContainer: {
        // marginTop: theme.spacing.unit * 2,
        // marginBottom: theme.spacing.unit * 2,
        minWidth: 600,
        minHeight: 269,
        paddingBottom: 0,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
        marginRight: theme.spacing.unit * 2,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 2,
        // marginBottom: theme.spacing.unit * 2,
    },
    dialogActions: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
    },
    progress: {
        margin: theme.spacing.unit * 3,
    },
});

class ControlDevice extends React.Component {
    state = {
        error: false,
        loading: false,
        snackbarOpen: false,
        addControlDeviceStep: 1,
        loadingStep: false,
        showAddControlDeviceDialog: false,
        iccidRefreshKey: '',
        controlDevice: {
            telephoneNumber: '',
            iccid: '',
            imsi: '',
            state: '',
            stateReceivedDateTime: '',
            lastCommandSent: '',
            lastCommandSentDateTime: '',
            parentItemCode: '',
            controlDeviceTypeCode: '',
            status: '',
        },
        saved: false,
        errors: {},
    }

    componentDidMount() {
        this.fetchControlDeviceData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.controlDeviceIccid !== prevProps.controlDeviceIccid) {
            this.fetchControlDeviceData();
        }
    }

    handleStep1Submit = async (event) => {
        let url;
        let data;
        const formData = new FormData(event.currentTarget);
        this.setState({
            loadingStep: true,
        });
        url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/jt/sim');
        const params = {
            iccid: formData.get('iccid'),
        };
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        data = await fetch(url).then(response => response.json());
        this.setState({
            // controlDevice: data,
            controlDevice: {
                ...data,
                parentItemCode: this.props.parentItemCode,
                controlDeviceTypeCode: this.props.controlDeviceTypeCode,
            },
        });

        // Update Control Device db entry
        url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/control-device/${this.state.controlDevice.iccid}`);
        data = await fetch(url, {
            method: 'PUT',
            body: JSON.stringify({
                iccid: data.iccid,
                imsi: data.imsi,
                telephoneNumber: data.telephoneNumber,
                parentItemCode: this.props.parentItemCode,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        await sleep(1000);
        this.setState({
            loadingStep: false,
            addControlDeviceStep: 2,
        });
    }

    handleStartTestClick = async () => {
        this.setState({
            loadingStep: true,
        });
        const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/control-device-iccid/${this.state.controlDevice.iccid}/command`);
        await fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                command: 'SET_PRODUCT_TYPE',
            	controlDeviceTypeCode: this.props.controlDeviceTypeCode,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        await sleep(2000);
        this.setState({
            loadingStep: false,
            addControlDeviceStep: 3,
        });
    }

    handleSaveClick = async () => {
        this.setState({
            loadingStep: true,
        });
        const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/control-device/${this.state.controlDevice.iccid}`);
        await fetch(url, {
            method: 'PUT',
            body: JSON.stringify({
                iccid: this.state.controlDevice.iccid,
                imsi: this.state.controlDevice.imsi,
                telephoneNumber: this.state.controlDevice.telephoneNumber,
                parentItemCode: this.props.parentItemCode,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        await sleep(2000);
        this.setState({
            loadingStep: false,
            addControlDeviceStep: 1,
            showAddControlDeviceDialog: false,
        });
    }

    handleRestartTestClick = async () => {
        this.setState({
            addControlDeviceStep: 2,
        });
    }

    handleOpenAddControlDeviceClick = () => {
        this.setState({
            showAddControlDeviceDialog: true,
            iccidRefreshKey: Math.random(),
        });
    }

    handleCloseAddControlDeviceClick = () => {
        this.setState({
            loadingStep: false,
            addControlDeviceStep: 1,
        });
        this.setState({ showAddControlDeviceDialog: false });
    }

    async fetchControlDeviceData() {
        if (this.props.controlDeviceIccid) {
            const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/control-device/${this.props.controlDeviceIccid}`);
            const data = await fetch(url).then(response => response.json());
            this.setState({ controlDevice: data });
        }
    }

    render() {
        const { classes } = this.props;
        const {
            controlDevice,
            errors,
            showAddControlDeviceDialog,
            addControlDeviceStep,
            loadingStep,
            iccidRefreshKey,
        } = this.state;

        // const step1 = (
        // );

        return (
            <React.Fragment>
                { controlDevice.iccid
                    ? (
                        <div className={classes.bodyContainer}>
                            <Typography>
                            telephoneNumber:
                                {' '}
                                {controlDevice.telephoneNumber}
                                <br />
                            iccid:
                                {' '}
                                {controlDevice.iccid}
                                <br />
                            imsi:
                                {' '}
                                {controlDevice.imsi}
                                <br />
                            state:
                                {' '}
                                {controlDevice.state}
                                <br />
                            stateReceivedDateTime:
                                {' '}
                                {controlDevice.stateReceivedDateTime}
                                <br />
                            lastCommandSent:
                                {' '}
                                {controlDevice.lastCommandSent}
                                <br />
                            lastCommandSentDateTime:
                                {' '}
                                {controlDevice.lastCommandSentDateTime}
                                <br />
                            parentItemCode:
                                {' '}
                                {controlDevice.parentItemCode}
                                <br />
                            controlDeviceTypeCode:
                                {' '}
                                {controlDevice.controlDeviceTypeCode}
                                <br />
                            status:
                                {' '}
                                {controlDevice.status}
                                <br />
                                <Divider className={classes.divider} />
                            </Typography>
                        </div>
                    )
                    : (
                        <React.Fragment>
                            <div className={classes.bodyContainer}>
                                <Typography>
                                No control device installed.
                                </Typography>
                            </div>
                            <Divider className={classes.divider} />
                            <div className={classes.bottomBarContainer}>
                                <Button
                                    color="default"
                                    className={classes.textButton}
                                    onClick={this.handleOpenAddControlDeviceClick}
                                >
                                Add Control Device
                                </Button>
                            </div>
                        </React.Fragment>
                    )
                }
                <Dialog
                    open={showAddControlDeviceDialog}
                    onClose={this.handleClosePaymentDetailClick}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={classes.dialogContentContainer}>
                        <div>
                            {
                                (() => {
                                    switch (addControlDeviceStep) {
                                    case 1: {
                                        return (
                                            <React.Fragment>
                                                <Typography variant="h6" gutterBottom>
                                                        Step 1
                                                </Typography>
                                                {loadingStep ? (
                                                    <CircularProgress
                                                        className={classes.progress}
                                                        color="secondary"
                                                    />
                                                ) : (
                                                    <React.Fragment>
                                                        <DialogContentText>
                                                            Either scan the ICCID barcode on the SIM holder card or type in the ICCID number on the SIM.
                                                        </DialogContentText>
                                                        <form onSubmit={this.handleStep1Submit}>
                                                            <Grid container spacing={0}>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        key={iccidRefreshKey}
                                                                        className={classes.formControl}
                                                                        name="iccid"
                                                                        id="iccid"
                                                                        label="ICCID"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                        error={!!errors.iccid}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Button
                                                                variant="contained"
                                                                className={classes.dialogButton}
                                                                type="submit"
                                                            >
                                                                SUBMIT
                                                            </Button>
                                                        </form>
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        );
                                    }
                                    case 2: {
                                        return (
                                            <React.Fragment>
                                                <Typography variant="h6" gutterBottom>
                                                        Step 2
                                                </Typography>
                                                {loadingStep ? (
                                                    <CircularProgress
                                                        className={classes.progress}
                                                        color="secondary"
                                                    />
                                                ) : (
                                                    <React.Fragment>
                                                        <DialogContentText>
                                                            <p>The control device must now be tested.</p>
                                                            <p>When the [START TEST] button is pressed the control device will automatically switch the appliance OFF wait 5 seconds and switch it back ON.</p>
                                                        </DialogContentText>
                                                        <Button
                                                            variant="contained"
                                                            className={classes.dialogButton}
                                                            onClick={this.handleStartTestClick}
                                                        >
                                                            START TEST
                                                        </Button>
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        );
                                    }
                                    case 3: {
                                        return (
                                            <React.Fragment>
                                                <Typography variant="h6" gutterBottom>
                                                        Step 3
                                                </Typography>
                                                {loadingStep ? (
                                                    <CircularProgress
                                                        className={classes.progress}
                                                        color="secondary"
                                                    />
                                                ) : (
                                                    <React.Fragment>
                                                        <DialogContentText>
                                                            <p>If the test completed as expected press the [SAVE] button.</p>
                                                            <p>Press the [RESTART TEST] button to restart the test process.</p>
                                                        </DialogContentText>
                                                        <Button
                                                            variant="contained"
                                                            className={classes.dialogButton}
                                                            onClick={this.handleRestartTestClick}
                                                        >
                                                            RESTART TEST
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            className={classes.dialogButton}
                                                            onClick={this.handleSaveClick}
                                                        >
                                                            SAVE
                                                        </Button>
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        );
                                        break;
                                    }
                                    default: {
                                        return null;
                                    }
                                    }
                                })()
                            }


                        </div>
                        <Divider className={classes.dialogDivider} />
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button onClick={this.handleCloseAddControlDeviceClick} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(ControlDevice);
