import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment-timezone';

const styles = theme => ({
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
        width: 500,
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    filterSet: {
        colour: 'red',
    },
    minTextField: {
        paddingRight: theme.spacing.unit * 1.5,
    },
    maxTextField: {
        paddingLeft: theme.spacing.unit * 1.5,
    },
    filterNotSet: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
});

class itemsTableFilterDialog extends React.Component {
    state = {
        errors: [],
        disableSubmitButton: false,
        showDialog: false,
        formData: {},
        formDataDefault: {
            assignment: 'In Stock',
            bin: 'All',
            branch: 'All',
            colour: 'All',
            condition: 'All',
            createdDateMax: '',
            createdDateMin: '',
            customerReference: '',
            lengthMax: '',
            lengthMin: '',
            manufacturer: 'All',
            model: 'All',
            stockroom: 'All',
            type: 'All',
            widthMax: '',
            widthMin: '',
        },
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.filters !== prevProps.filters) {
            this.presetFormData();
        }
        if (this.props.showFiltersDialog !== prevProps.showFiltersDialog) {
            if (this.props.showFiltersDialog) this.handleOpenDialog();
            this.props.resetShowFiltersDialog();
        }
    }

    presetFormData = () => {
        this.setState(state => ({
            formData: {
                ...state.formDataDefault,
                ...this.props.filters,
            },
        }));
    }

    resetFormData = () => {
        this.setState(state => ({
            formData: {
                ...state.formDataDefault,
            },
        }));
    }

    handleOpenDialog = () => {
        this.presetFormData();
        this.setState({
            showDialog: true,
        });
    }

    handleCloseDialog = () => {
        this.setState({
            showDialog: false,
        });
    }

    updateData = (event) => {
        const { value, name } = event.target;
        let formattedValue;
        switch (name) {
        case 'customerReference':
            formattedValue = value.toUpperCase();
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;
        this.setState(state => ({
            formData: {
                ...state.formData,
                [name]: formattedValue,
            },
        }));
    }

    handleSubmitButtonClick = () => {
        this.setState({
            disableSubmitButton: true,
        });
        const cleanedFormData = {};
        Object.keys(this.state.formData).map((key) => {
            if (this.state.formData[key] !== 'All' && this.state.formData[key] !== '') cleanedFormData[key] = this.state.formData[key];
        });


        this.props.setFilters(cleanedFormData).then(() => {
            this.setState({
                showDialog: false,
                disableSubmitButton: false,
            });
        });
    }

    handleResetAllFiltersClick = () => {
        this.resetFormData();
    }

    handleMinDateChange = (chosenDate) => {
        this.setState(state => ({
            formData: {
                ...state.formData,
                createdDateMin: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    }

    handleMaxDateChange = (chosenDate) => {
        this.setState(state => ({
            formData: {
                ...state.formData,
                createdDateMax: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    }

    render() {
        const {
            classes,
            bins,
            branches,
            products,
            itemsAssignments,
            itemsConditions,
            stockrooms,
        } = this.props;

        const {
            errors,
            disableSubmitButton,
            formData,
            showDialog,
        } = this.state;

        let formManufacturers = [];
        let formProducts = [];
        let formModels = [];
        let formColours = [];
        let formTypes = [];
        let formBranches = [];
        let formConditions = [];
        let formAssignments = [];
        let formBins = [];
        let formStockrooms = [];

        if (products !== null
            && branches !== null
            && itemsConditions !== null
            && itemsAssignments !== null) {
            formManufacturers = orderBy(uniqBy(products, 'manufacturer'), 'manufacturer');
            formProducts = products.filter(product => product.manufacturer === formData.manufacturer || formData.manufacturer === 'All');
            formModels = orderBy(formProducts, 'modelNo');
            formColours = orderBy(uniqBy(formProducts.filter(product => product.colour !== null && product.colour !== '' && (product.modelNo === formData.model || formData.model === 'All')), 'colour'), 'colour');
            formTypes = orderBy(uniqBy(formProducts.filter(product => product.type !== null && product.type !== ''), 'type'), 'type');
            formBranches = orderBy(uniqBy(branches, 'name'), 'name');
            formConditions = orderBy(uniqBy(itemsConditions, 'description'), 'description');
            formAssignments = orderBy(uniqBy(itemsAssignments, 'itemAssignmentDescription'), 'itemAssignmentDescription');
            formStockrooms = orderBy(stockrooms, 'description');
            formBins = orderBy(bins, 'name');
        }

        return (
            <React.Fragment>
                {products !== null
                    && branches !== null
                    && itemsConditions !== null
                    && itemsAssignments !== null
                    ? (
                        <Dialog
                            open={showDialog}
                            onClose={this.handleCloseDialog}
                        >
                            <DialogContent className={classes.dialogContent}>
                                <div className={classes.dialogContentContainer}>
                                    <React.Fragment>
                                        <Grid container spacing={0}>
                                            <Grid item container xs={6}>
                                                <Typography variant="h6" gutterBottom>
                                                    Filters
                                                </Typography>
                                            </Grid>
                                            <Grid item container xs={6} alignItems="flex-start" justify="flex-end" direction="row">
                                                <Button
                                                    className={classes.button}
                                                    onClick={this.handleResetAllFiltersClick}
                                                >
                                                    RESET ALL
                                                </Button>
                                            </Grid>
                                        </Grid>

                                        <form>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12} className={classes.customerReference}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="customerReference"
                                                        id="customerReference"
                                                        onChange={this.updateData}
                                                        value={formData.customerReference}
                                                        label="Customer Reference"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.customerReference}
                                                        helperText={errors.customerReference}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.manufacturer}
                                                    >
                                                        <InputLabel htmlFor="manufacturer" className={classes.inputLabel}>Manufacturer</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateData}
                                                                    name="manufacturer"
                                                                    id="manufacturer"
                                                                />
                                                            )}
                                                            value={formData.manufacturer}
                                                        >
                                                            <MenuItem value="All"><em className={classes.filterNotSet}>All</em></MenuItem>
                                                            {formManufacturers.map(product => (
                                                                <MenuItem
                                                                    key={product.manufacturer}
                                                                    value={product.manufacturer || ''}
                                                                >
                                                                    {product.manufacturer}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.manufacturer && (
                                                            <FormHelperText>{errors.manufacturer}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.model}
                                                    >
                                                        <InputLabel htmlFor="model" className={classes.inputLabel}>Model</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateData}
                                                                    name="model"
                                                                    id="model"
                                                                />
                                                            )}
                                                            value={formData.model}
                                                        >
                                                            <MenuItem value="All"><em className={classes.filterNotSet}>All</em></MenuItem>
                                                            {formModels.map(product => (
                                                                <MenuItem
                                                                    key={product.modelNo}
                                                                    value={product.modelNo}
                                                                >
                                                                    {product.modelNo}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.model && (
                                                            <FormHelperText>{errors.model}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.colour}
                                                    >
                                                        <InputLabel htmlFor="colour" className={classes.inputLabel}>Colour</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateData}
                                                                    name="colour"
                                                                    id="colour"
                                                                />
                                                            )}
                                                            value={formData.colour}
                                                        >
                                                            <MenuItem value="All"><em className={classes.filterNotSet}>All</em></MenuItem>
                                                            {formColours.map(product => (
                                                                <MenuItem
                                                                    key={product.colour}
                                                                    value={product.colour}
                                                                >
                                                                    {product.colour}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.colour && (
                                                            <FormHelperText>{errors.colour}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.type}
                                                    >
                                                        <InputLabel htmlFor="type" className={classes.inputLabel}>Type</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateData}
                                                                    name="type"
                                                                    id="type"
                                                                />
                                                            )}
                                                            value={formData.type}
                                                        >
                                                            <MenuItem value="All"><em className={classes.filterNotSet}>All</em></MenuItem>
                                                            {formTypes.map(product => (
                                                                <MenuItem
                                                                    key={product.type}
                                                                    value={product.type}
                                                                >
                                                                    {product.type}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.type && (
                                                            <FormHelperText>{errors.type}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={`${classes.menuFormControl} ${classes.filterSet}`}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.branch}
                                                    >
                                                        <InputLabel htmlFor="branch" className={classes.inputLabel}>Branch</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateData}
                                                                    name="branch"
                                                                    id="branch"
                                                                />
                                                            )}
                                                            value={formData.branch}
                                                        >
                                                            <MenuItem value="All"><em className={classes.filterNotSet}>All</em></MenuItem>
                                                            {formBranches.map(branch => (
                                                                <MenuItem
                                                                    key={branch.name}
                                                                    value={branch.name}
                                                                >
                                                                    {branch.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.branch && (
                                                            <FormHelperText>{errors.branch}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={`${classes.menuFormControl} ${classes.filterSet}`}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.stockroom}
                                                    >
                                                        <InputLabel htmlFor="stockroom" className={classes.inputLabel}>Stockroom</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateData}
                                                                    name="stockroom"
                                                                    id="stockroom"
                                                                />
                                                            )}
                                                            value={formData.stockroom}
                                                        >
                                                            <MenuItem value="All"><em className={classes.filterNotSet}>All</em></MenuItem>
                                                            {formStockrooms.map(stockroom => (
                                                                <MenuItem
                                                                    key={stockroom.description}
                                                                    value={stockroom.description}
                                                                >
                                                                    {stockroom.description}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.stockroom && (
                                                            <FormHelperText>{errors.stockroom}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={`${classes.menuFormControl} ${classes.filterSet}`}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.bin}
                                                    >
                                                        <InputLabel htmlFor="bin" className={classes.inputLabel}>BIN</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateData}
                                                                    name="bin"
                                                                    id="bin"
                                                                />
                                                            )}
                                                            value={formData.bin}
                                                        >
                                                            <MenuItem value="All"><em className={classes.filterNotSet}>All</em></MenuItem>
                                                            {formBins.map(bin => (
                                                                <MenuItem
                                                                    key={bin.name}
                                                                    value={bin.name}
                                                                >
                                                                    {bin.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.bin && (
                                                            <FormHelperText>{errors.bin}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.condition}
                                                    >
                                                        <InputLabel htmlFor="condition" className={classes.inputLabel}>Condition</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateData}
                                                                    name="condition"
                                                                    id="condition"
                                                                />
                                                            )}
                                                            value={formData.condition}
                                                        >
                                                            <MenuItem value="All"><em className={classes.filterNotSet}>All</em></MenuItem>
                                                            {formConditions.map(itemsCondition => (
                                                                <MenuItem
                                                                    key={itemsCondition.description}
                                                                    value={itemsCondition.description}
                                                                >
                                                                    {itemsCondition.description}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.condition && (
                                                            <FormHelperText>{errors.condition}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.assignment}
                                                    >
                                                        <InputLabel htmlFor="assignment" className={classes.inputLabel}>Assignment</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateData}
                                                                    name="assignment"
                                                                    id="assignment"
                                                                />
                                                            )}
                                                            value={formData.assignment}
                                                        >
                                                            <MenuItem value="All"><em className={classes.filterNotSet}>All</em></MenuItem>
                                                            {formAssignments.map(assignment => (
                                                                <MenuItem
                                                                    key={assignment.itemAssignmentDescription}
                                                                    value={assignment.itemAssignmentDescription}
                                                                >
                                                                    {assignment.itemAssignmentDescription}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.assignment && (
                                                            <FormHelperText>{errors.assignment}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>


                                                <Grid item xs={6} className={classes.minTextField}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="lengthMin"
                                                        id="lengthMin"
                                                        onChange={this.updateData}
                                                        value={formData.lengthMin}
                                                        label="Length min (m)"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.quantity}
                                                        helperText={errors.quantity}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} className={classes.maxTextField}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="lengthMax"
                                                        id="lengthMax"
                                                        onChange={this.updateData}
                                                        value={formData.lengthMax}
                                                        label="Length max (m)"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.quantity}
                                                        helperText={errors.quantity}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} className={classes.minTextField}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="widthMin"
                                                        id="widthMin"
                                                        onChange={this.updateData}
                                                        value={formData.widthMin}
                                                        label="Width min (m)"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.quantity}
                                                        helperText={errors.quantity}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} className={classes.maxTextField}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="widthMax"
                                                        id="widthMax"
                                                        onChange={this.updateData}
                                                        value={formData.widthMax}
                                                        label="Width max (m)"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.quantity}
                                                        helperText={errors.quantity}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} className={classes.minTextField}>
                                                    <DatePicker
                                                        name="createdDateMin"
                                                        id="createdDateMin"
                                                        margin="normal"
                                                        label="Created Start Date"
                                                        value={formData.createdDateMin}
                                                        onChange={this.handleMinDateChange}
                                                        fullWidth
                                                        variant="outlined"
                                                        format="DD-MM-YYYY"
                                                        showTodayButton
                                                        disableFuture
                                                        invalidLabel="No limit"
                                                        autoOk
                                                    />
                                                </Grid>
                                                <Grid item xs={6} className={classes.maxTextField}>
                                                    <DatePicker
                                                        name="createdDateMax"
                                                        id="createdDateMax"
                                                        margin="normal"
                                                        label="Created End Date"
                                                        value={formData.createdDateMax}
                                                        onChange={this.handleMaxDateChange}
                                                        fullWidth
                                                        variant="outlined"
                                                        format="DD-MM-YYYY"
                                                        showTodayButton
                                                        disableFuture
                                                        invalidLabel="No limit"
                                                        autoOk
                                                    />
                                                </Grid>

                                                <Grid item xs={12} align="center">
                                                    <Button
                                                        onClick={this.handleSubmitButtonClick}
                                                        className={classes.dialogButton}
                                                        disabled={disableSubmitButton}
                                                    >
                                                        APPLY
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </React.Fragment>
                                </div>
                            </DialogContent>
                            <Divider className={classes.dialogActionsDivider} />
                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    onClick={this.handleCloseDialog}
                                    color="primary"
                                    autoFocus
                                >
                            Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                    : null
                }
            </React.Fragment>
        );
    }
}

const branchesShape = {
    code: PropTypes.number,
    name: PropTypes.string,
};

itemsTableFilterDialog.propTypes = {
    branches: PropTypes.arrayOf(PropTypes.shape(branchesShape)).isRequired,
    classes: PropTypes.object.isRequired,
    resetShowFiltersDialog: PropTypes.func.isRequired,
    showFiltersDialog: PropTypes.bool.isRequired,
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    itemsAssignments: PropTypes.arrayOf(PropTypes.object).isRequired,
    itemsConditions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withSnackbar(withStyles(styles)(itemsTableFilterDialog));
