import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas'

import useRect from './useRect'

const Signature = ({ canvasRef }) => {
    const signatureRef = useRef(null);
    const signatureRect = useRect(signatureRef);

    return (
        <div ref={signatureRef}>
            <SignatureCanvas
                ref={canvasRef}
                penColor="#2528c3de"
                canvasProps={{ width: signatureRect.width || 0, height: signatureRect.width * 0.45, className: 'sigCanvas' }}
            />
        </div>
    )
}

export default Signature;
