import React from 'react';
import {
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Error';
import CrossCircleIcon from '@material-ui/icons/Cancel';
import moment from 'moment-timezone';

const CustomTableCell = withStyles((theme) => ({
    body: {
        fontSize: 14,
        paddingBottom: 16,
        paddingTop: 16,
        verticalAlign: 'top',
        whiteSpace: 'nowrap',
    },
    head: {
        backgroundColor: theme.palette.primary.lighter,
        color: theme.palette.common.white,
    },
}))(TableCell);

const styles = (theme) => ({
    activeIcon: {
        color: '#4caf50',
    },
    alert: {
        color: '#f1180b',
    },
    hybridTextIconRight: {
        fontSize: 16,
        marginLeft: theme.spacing.unit * 1,
        transform: 'translate(0%, 13%)',
    },
    notSetIcon: {
        color: '#d7d7d7',
    },
    paper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        width: '100%',
    },
    progress: {
        opacity: '.5',
    },
    progressContainer: {
        marginBottom: theme.spacing.unit * 3,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        width: 60,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    statusColumn: {
        width: 110,
    },
    table: {
        border: '1px solid #e0e0e0',
    },
    tableContainer: {
        align: 'left',
        marginBottom: theme.spacing.unit * 3,
        width: '100%',
    },
    tableHead: {
        fontSize: 12,
        height: theme.spacing.unit * 5,
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        marginBottom: 4,
    },
    warningIcon: {
        color: '#fac518',
    },
});

class ContactVisitTasks extends React.Component {
    state = {
    };

    statusIcon = (alertStatus, warningStatus, cancelledStatus) => {
        switch (true) {
        case (alertStatus): {
            return (<WarningIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.alert}`} />);
        }
        case (warningStatus): {
            return (<WarningIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.warningIcon}`} />);
        }
        case (cancelledStatus): {
            return (<CrossCircleIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.cancelledIcon}`} />);
        }
        default: {
            return (<CheckCircleIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.activeIcon}`} />);
        }
        }
    };

    render() {
        const {
            classes,
            scheduledDeliveryDateTime,
            visitTasks,
        } = this.props;

        const now = moment.tz(moment(), 'Europe/London');

        return (
            <div className={classes.tableContainer}>
                <div className={classes.textFieldLabel}>
                    Tasks
                </div>
                {visitTasks !== null
                    ? (
                        <>
                            {visitTasks.length !== 0
                                ? (
                                    <>
                                        <Paper elevation={0}>
                                            <Table className={classes.table} padding="dense">
                                                <TableHead>
                                                    <TableRow className={classes.tableHead}>
                                                        <CustomTableCell align="left">Description</CustomTableCell>
                                                        <CustomTableCell align="left">Created By</CustomTableCell>
                                                        <CustomTableCell className={classes.statusColumn} align="center">Complete</CustomTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {visitTasks.sort((a, b) => a.createdDatetime - b.createdDatetime).map((visitTask) => (
                                                        <TableRow className={classes.row} key={visitTask.code}>
                                                            <CustomTableCell align="left">{visitTask.description}</CustomTableCell>
                                                            <CustomTableCell align="left">{visitTask.createdByContactFullName}</CustomTableCell>
                                                            <CustomTableCell className={classes.statusColumn} align="center">
                                                                {this.statusIcon(
                                                                    visitTask.statusDescription === 'Open' && moment(scheduledDeliveryDateTime).isBefore(now),
                                                                    visitTask.deliveryStatus === 'Open' && moment(scheduledDeliveryDateTime).isAfter(now),
                                                                    false,
                                                                )}
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Paper>
                                    </>
                                )
                                : (
                                    <Typography variant="body1">
                                        No visit tasks found
                                    </Typography>
                                )}
                        </>
                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )}
            </div>
        );
    }
}

export default withSnackbar(withStyles(styles)(ContactVisitTasks));
