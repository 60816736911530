import React from 'react';
import {
    Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    tile: {
        backgroundColor: 'red',
        borderRadius: theme.spacing.unit * 3,
        height: '100%',
        width: '100%',
    },
});

class SimpleTile extends React.Component {
    state = {
    };

    render() {
        const {
            classes,
        } = this.props;

        return (
            <div className={classes.tile}>
                Hello
            </div>
        );
    }
}

export default withStyles(styles)(SimpleTile);
