import React from 'react';
import {
    CssBaseline,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSimCard } from '@fortawesome/free-solid-svg-icons'
import qs from 'qs';

import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import moment from 'moment-timezone';

import Layout from './Layout';
import fetch from '../utilities/fetch';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        marginBottom: theme.spacing.unit * 3,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
        // whiteSpace: 'nowrap',
    },
    customTableCell: {
        whiteSpace: 'nowrap',
    },
    simCard: {
        fontSize: 20,
    },
});

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

class ReportOrderedProducts extends React.Component {
    state = {
        products: [],
    }

    async componentDidMount() {
        const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/report/orderedProducts`);
        const data = await fetch(url).then(response => response.json());
        this.setState({ products: data });
        }

    handleProductClick = (productCode) => {
        // this.props.history.push(`/item/${itemCode}`);
    }

    render() {
        const { classes } = this.props;
        const { products } = this.state;

        return (
            <Layout pageTitle="Report - Ordered Products">
                <CssBaseline />
                <Paper className={classes.root}>
                    <Table className={classes.table} padding="dense">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>#</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Stock Code</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Manufacturer</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Model</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Description</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>{moment().format('DD-MM')}</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>{moment().subtract(1, 'days').format('DD-MM')}</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>{moment().subtract(2, 'days').format('DD-MM')}</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>{moment().subtract(3, 'days').format('DD-MM')}</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>{moment().subtract(4, 'days').format('DD-MM')}</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>{moment().subtract(5, 'days').format('DD-MM')}</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>{moment().subtract(6, 'days').format('DD-MM')}</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.map((product, index) => {
                                return (
                                    <TableRow onClick={() => this.handleProductClick(product.code)} className={classes.row} key={product.code}>
                                        <CustomTableCell className={classes.customTableCell} component="th" scope="row">{index+1}</CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="center">{product.code}</CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="left">{product.manufacturer}</CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="left">{product.modelNo}</CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="left">{product.shortDescription}</CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="center">{product.today}</CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="center">{product.todayMinus1}</CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="center">{product.todayMinus2}</CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="center">{product.todayMinus3}</CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="center">{product.todayMinus4}</CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="center">{product.todayMinus5}</CustomTableCell>
                                        <CustomTableCell className={classes.customTableCell} align="center">{product.todayMinus6}</CustomTableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Paper>
            </Layout>
        );
    }
}

export default withStyles(styles)(ReportOrderedProducts);
