import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { SnackbarProvider } from 'notistack';

// import { CssBaseline } from '@material-ui/core/CssBaseline';
import Login from './pages/Login';
import Leads from './pages/Leads';
import Customers from './pages/Customers';
import Customer from './pages/Customer';
import Contact from './pages/v2/Contact';
import PostcodeLookup from './pages/v2/PostcodeLookup';
import Quote from './pages/v2/Quote';
import Customer2 from './pages/Customer2';
import oldCustomer from './pages/oldCustomer';
import Items from './pages/Items';
import Item from './pages/Item';
import GoodsInItems from './pages/GoodsInItems';
import Home from './pages/Home';
import Gate from './pages/Gate';
import oldProducts from './pages/oldProducts';
import ReportOrderedProducts from './pages/ReportOrderedProducts';
import ReportAccountsOverview from './pages/ReportAccountsOverview';
import AgreementsUndelivered from './pages/AgreementsUndelivered';
import AgreementsCardExpired from './pages/AgreementsCardExpired';
import Sales from './pages/Sales';
import DeliveryNotes from './pages/DeliveryNotes';
import DeliveryNote from './pages/DeliveryNote';
import NewItems from './pages/NewItems';
import NewItem from './pages/NewItem';
import ItemsPrint from './pages/ItemsPrint';
import Products from './pages/v2/Products';
import Branches from './pages/Branches';
import Reports from './pages/Reports';
import ResetPassword from './pages/ResetPassword';
import AgreementSignature from './pages/v2/AgreementSignature';
import Templates from './pages/Templates';
import Arrears from './pages/Arrears';
import ArrearsReport from './pages/ArrearsReport';
import VisitRebook from './pages/v2/VisitRebook';
import VisitInviteReply from './pages/v2/VisitInviteReply';
import AgreementStatement from './pages/v2/AgreementStatement';
import VisitSchedule from './pages/v2/VisitSchedule';
import AgentSchedule from './pages/v2/AgentSchedule';
import Dashboard from './pages/v2/Dashboard';
import PurchaseOrders from './pages/v2/PurchaseOrders';
import PurchaseOrder from './pages/v2/PurchaseOrder';
import PurchaseOrderPdf from './pages/v2/PurchaseOrderPdf';
import PurchaseOrderEstimatedDeliveryDate from './pages/purchaseOrderEstimatedDeliveryDate';
import AndroidAppDownload from './pages/v2/AndroidAppDownload';
import Dash from './pages/v2/Dash'
import SearchResults from './pages/SearchResults';
import Agreements from './pages/Agreements';
import Delivery from './pages/Delivery';
import LeadsMap from './pages/LeadsMap';
import ManufacturersSuppliers from './pages/v2/ManufacturersSuppliers';
import ManufacturerSupplier from './pages/v2/ManufacturerSupplier';
import Cutting from './pages/v2/Cutting';
import Engineers from './pages/v2/Engineers';
import SalesMap from './pages/v2/SalesMap';
import ManufactureItem from './pages/v2/ManufactureItem';
import ManufactureBlind from './pages/v2/ManufactureBlind';

// import logo from './logo.svg';
import './App.css';

import theme from './theme';

class App extends Component {
    state = {
        company: undefined,
    };

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <Router>
                    <>
                        <SnackbarProvider maxSnack={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                {/* <CssBaseline /> */}
                                <Route exact path="/" component={Home} />
                                <Route exact path="/login" component={Login} />
                                <Route exact path="/gate" component={Gate} />
                                <Route exact path="/home" component={Home} />
                                <Route exact path="/leads" component={Leads} />
                                <Route exact path="/customer/:contactCode" component={Customer} />
                                <Route exact path="/contact/:contactCode" component={Contact} />
                                <Route exact path="/customer2/:contactCode" component={Customer2} />
                                <Route exact path="/oldCustomer/:contactCode" component={oldCustomer} />
                                <Route exact path="/customers" component={Customers} />
                                <Route exact path="/oldProducts" component={oldProducts} />
                                <Route exact path="/items" component={Items} />
                                <Route exact path="/item/:itemCode" component={Item} />
                                <Route exact path="/goodsInItems" component={GoodsInItems} />
                                <Route exact path="/agreements" component={Agreements} />
                                <Route exact path="/delivery" component={Delivery} />
                                <Route exact path="/leadsMap" component={LeadsMap} />
                                <Route exact path="/search-results" component={SearchResults} />
                                <Route exact path="/reportOrderedProducts" component={ReportOrderedProducts} />
                                <Route exact path="/reportAccountsOverview" component={ReportAccountsOverview} />
                                <Route exact path="/agreementsUndelivered" component={AgreementsUndelivered} />
                                <Route exact path="/agreementsCardExpired" component={AgreementsCardExpired} />
                                <Route exact path="/sales" component={Sales} />
                                <Route exact path="/deliveryNotes" component={DeliveryNotes} />
                                <Route exact path="/deliveryNote/:deliveryNoteCode" component={DeliveryNote} />
                                <Route exact path="/newItems" component={NewItems} />
                                <Route exact path="/newItem/:itemCode" component={NewItem} />
                                <Route exact path="/ItemsPrint" component={ItemsPrint} />
                                <Route exact path="/Products" component={Products} />
                                <Route exact path="/Branches" component={Branches} />
                                <Route exact path="/Reports" component={Reports} />
                                <Route exact path="/postcodeLookup" component={PostcodeLookup} />
                                <Route exact path="/quote" component={Quote} />
                                <Route exact path="/templates" component={Templates} />
                                <Route exact path="/arrears" component={Arrears} />
                                <Route exact path="/arrears/report" component={ArrearsReport} />
                                <Route exact path="/resetPassword/:guid" component={ResetPassword} />
                                <Route exact path="/agreementSignature/:guid" component={AgreementSignature} />
                                <Route exact path="/visitRebook/:guid" component={VisitRebook} />
                                <Route exact path="/visitInviteReply/:guid" component={VisitInviteReply} />
                                <Route exact path="/agreement/:agreementCode/statement" component={AgreementStatement} />
                                <Route exact path="/visitSchedule" component={VisitSchedule} />
                                <Route exact path="/agentSchedule" component={AgentSchedule} />
                                <Route exact path="/dashboard" component={Dashboard} />
                                <Route exact path="/purchaseOrders" component={PurchaseOrders} />
                                <Route exact path="/purchaseOrder/:purchaseOrderCode" component={PurchaseOrder} />
                                <Route exact path="/purchaseOrderPdf/:purchaseOrderCode" component={PurchaseOrderPdf} />
                                <Route exact path="/purchaseOrderEstimatedDeliveryDate/:guid" component={PurchaseOrderEstimatedDeliveryDate} />
                                <Route exact path="/manufacturersSuppliers" component={ManufacturersSuppliers} />
                                <Route exact path="/manufacturerSupplier/:manufacturerSupplierCode" component={ManufacturerSupplier} />
                                <Route exact path="/cutting" component={Cutting} />
                                <Route exact path="/androidAppDownload" component={AndroidAppDownload} />
                                <Route exact path="/engineers" component={Engineers} />
                                <Route exact path="/salesMap" component={SalesMap} />
                                <Route exact path="/manufactureItem" component={ManufactureItem} />
                                <Route exact path="/manufactureBlind" component={ManufactureBlind} />
                                <Route exact path="/dash" component={Dash} />
                            </MuiPickersUtilsProvider>
                        </SnackbarProvider>
                    </>
                </Router>
            </MuiThemeProvider>
        );
    }
}

export default App;
