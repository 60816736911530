import React from 'react';
import {
    CircularProgress,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/DoneOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import UndeleteIcon from '@material-ui/icons/RestoreFromTrash';
import classNames from 'classnames';
import { sortBy } from 'lodash';
import { withSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment-timezone';
import ManufacturerSupplierEditDialog from './manufacturerSupplierEditDialog';
import ManufacturerSupplierExpireDialog from './manufacturerSupplierExpireDialog';

const styles = (theme) => ({
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
        minHeight: 100,
        paddingTop: theme.spacing.unit * 3,
    },
    progress: {
        opacity: '.5',
    },
    tableTitleLeft: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        alignItems: 'left',
        paddingTop: theme.spacing.unit * 2,
        fontWeight: 500,
        textTransform: 'uppercase',
        paddingBottom: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 2,
    },
    tableTitleRight: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        alignItems: 'right',
        paddingTop: theme.spacing.unit * 2,
        fontWeight: 500,
        textTransform: 'uppercase',
        paddingBottom: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    shortDescription: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
    noWrap: {
        whiteSpace: 'nowrap',
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    noWrapPlain: {
        whiteSpace: 'nowrap',
    },
    filterIcon: {
        width: 16,
    },
    filterButton: {
        marginLeft: theme.spacing.unit * 3,
    },
    orderDirectionIcon: {
        marginBottom: -10,
    },
    pageDetail: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        paddingTop: 13,
        fontWeight: 500,
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        textTransform: 'uppercase',
        paddingBottom: theme.spacing.unit * 2,
    },
    hidden: {
        display: 'none',
    },
    downloadIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 20,
        marginLeft: 'auto',
        marginTop: 16,
        marginRight: 16,
    },
    topControls: {
        marginTop: theme.spacing.unit * 3,
    },
    noDataText: {
        padding: theme.spacing.unit * 3,
    },
    greenChip: {
        backgroundColor: 'green',
        color: 'white',
        height: theme.spacing.unit * 3,
        marginTop: -4,
    },
    blackTableIcons: {
        color: '#000000',
        fontSize: 22,
        opacity: 0.6,
    },
    colourTableIcons: {
        fontSize: 22,
        opacity: 1,
    },
    pass: {
        color: theme.palette.primary.testPass,
    },
    fail: {
        color: theme.palette.primary.testFail,
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogContent: {
        paddingBottom: 16,
        minWidth: 600,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 4,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '8px 24px',
        margin: '0px 0px 0px 0px !important',
    },
    dialogText: {
        marginTop: theme.spacing.unit * 2,
    },
    dialogDivider: {
        marginBottom: theme.spacing.unit * 2,
        marginLeft: -24,
        marginRight: -24,
        marginTop: theme.spacing.unit * 2,
    },
    disabled: {
        opacity: 0.26,
    },
    tableExpiredCheckBox: {
        textAlign: 'left',
    },
    addManufacturerSupplierButton: {
        textAlign: 'right',
    },
    dialogExpiredCheckBox: {
        textAlign: 'right',
    },
    checkBox: {
        fontSize: 14,
    },
    searchAdornment: {
        // marginTop: 4,
        // marginBottom: 4,
        margin: 'none',
        padding: 'none',
    },
    searchBox: {
        marginBottom: 16,
    },
    searchContainer: {
        paddingLeft: 48,
        textAlign: 'center',
    },
    clearButton: {
        marginTop: 8,
    },
    newButton: {
        marginTop: 8,
    },
    expiredCheckbox: {
        marginTop: 8,
    },
});

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
}))(TableCell);

class ManufacturerSuppliersTable extends React.Component {
    state = {
        currentManufacturerSupplier: {},
        hideExpiredManufacturerSuppliers: true,
        searchBoxData: {
            searchString: '',
        },
        showEditManufacturerSupplierDialog: false,
        showExpireManufacturerSupplierDialog: false,
    };

    handleRowClick = (purchaseOrderCode) => {
        window.open(`/purchaseOrder/${purchaseOrderCode}/`);
    };

    updateHideExpiredManufacturerSuppliersCheckbox = () => (event) => {
        const newState = !!event.target.checked;
        this.setState({
            hideExpiredManufacturerSuppliers: newState,
        });
    };

    updateSearchboxData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            searchBoxData: {
                ...state.searchBoxData,
                [name]: formattedValue,
            },
        }));
    };

    handleClearSearchStringClick = () => {
        this.setState({
            searchBoxData: {
                searchString: '',
            },
        });
    };

    handleUnexpireManufacturerSupplier = (manufacturerSupplierCode, event) => {
        event.stopPropagation();
        this.props.unexpireManufacturerSupplier(manufacturerSupplierCode).then(() => {
            this.props.enqueueSnackbar('Expire Manufacturer/Supplier Success', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Expire Manufacturer/Supplier Failed', { variant: 'error' });
        });
    };

    render() {
        const {
            classes,
            purchaseOrders,
            purchaseOrdersLoading,
        } = this.props;
        const {
            currentManufacturerSupplier,
            hideExpiredManufacturerSuppliers,
            searchBoxData,
            showEditManufacturerSupplierDialog,
            showExpireManufacturerSupplierDialog,
        } = this.state;

        const displayPurchaseOrders = searchBoxData.searchString.length < 3
            ? purchaseOrders
            : purchaseOrders.filter((purchaseOrder) => (purchaseOrder.supplier.name.toLowerCase().includes(searchBoxData.searchString.toLowerCase()))
                || (`PO${purchaseOrder.code}`.toLowerCase().includes(searchBoxData.searchString.toLowerCase())));

        return (
            <>
                <Grid
                    container
                    spacing={0}
                >
                    <Grid
                        item
                        xs={4}
                        className={classes.tableExpiredCheckBox}
                    >
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    name="hideExpiredManufacturerSuppliers"
                                    id="hideExpiredManufacturerSuppliers"
                                    onChange={this.updateHideExpiredManufacturerSuppliersCheckbox()}
                                    checked={hideExpiredManufacturerSuppliers}
                                    value={hideExpiredManufacturerSuppliers}
                                />
                            )}
                            classes={{ label: classes.checkBox }}
                            label="Hide Expired"
                            className={classes.expiredCheckbox}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        className={classes.searchContainer}
                    >
                        <TextField
                            autoFocus
                            className={classes.searchBox}
                            name="searchString"
                            id="searchString"
                            onChange={this.updateSearchboxData}
                            margin="dense"
                            variant="outlined"
                            value={searchBoxData.searchString}
                            // inputProps={{
                            //     paddingBottom: 8,
                            //     paddingLeft: 14,
                            //     paddingRight: 14,
                            //     paddingTop: 8,
                            // }}
                            InputProps={{
                                startAdornment:
    <InputAdornment
        disableTypography
        className={classes.searchAdornment}
        position="start"
    >
        <SearchIcon />
    </InputAdornment>,
                            }}

                        />
                        <IconButton
                            className={classes.clearButton}
                            onClick={this.handleClearSearchStringClick}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        className={classes.addManufacturerSupplierButton}
                    >
                        <IconButton
                            className={classes.newButton}
                            onClick={this.props.handleCreatePurchaseOrderClick}
                            // disabled={!newPurchaseOrderData.supplierCode}
                        >
                            <AddIcon />
                        </IconButton>

                    </Grid>
                </Grid>
                <Paper>
                    { purchaseOrdersLoading
                        ? (
                            <div className={classes.progressContainer}>
                                <CircularProgress className={classes.progress} variant="indeterminate" />
                            </div>
                        )
                        : (
                            <>
                                { purchaseOrders.length === 0
                                    ? (
                                        <Typography className={classes.noDataText}>
                                            No Purchase Orders
                                        </Typography>
                                    )
                                    : (
                                        <>
                                            <Table className={classes.table} padding="dense">
                                                <TableHead>
                                                    <TableRow>
                                                        <CustomTableCell className={classes.tableHead}>
                                                            PO#
                                                        </CustomTableCell>
                                                        <CustomTableCell className={classes.tableHead} align="left">
                                                            Supplier
                                                        </CustomTableCell>
                                                        <CustomTableCell className={classes.tableHead} align="left">
                                                            Branch
                                                        </CustomTableCell>
                                                        <CustomTableCell className={classes.tableHead} align="left">
                                                            Date
                                                        </CustomTableCell>
                                                        <CustomTableCell className={classes.tableHead} align="center">
                                                            Authorised
                                                        </CustomTableCell>
                                                        <CustomTableCell className={classes.tableHead} align="center">
                                                            Sent
                                                        </CustomTableCell>
                                                        {/* <CustomTableCell className={classes.tableHead} align="center">
                                                            Supplier
                                                        </CustomTableCell>
                                                        <CustomTableCell className={classes.tableHead} align="left">
                                                            Telephone Number
                                                        </CustomTableCell>
                                                        <CustomTableCell className={classes.tableHead} align="left">
                                                            Email Address
                                                        </CustomTableCell>
                                                        <CustomTableCell className={classes.tableHead} align="left">
                                                            Account Reference
                                                        </CustomTableCell>
                                                        <CustomTableCell className={classes.tableHead} align="left">
                                                            Our Account Reference
                                                        </CustomTableCell>
                                                        <CustomTableCell className={classes.tableHead} align="center">
                                                            Complete
                                                        </CustomTableCell>
                                                        <CustomTableCell className={classes.tableHead} align="center" /> */}
                                                        <CustomTableCell className={classes.tableHead} align="center" />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {sortBy(displayPurchaseOrders, 'name').map((purchaseOrder) => (!hideExpiredManufacturerSuppliers || (hideExpiredManufacturerSuppliers && !purchaseOrder.expired)
                                                        ? (
                                                            <TableRow onClick={() => this.handleRowClick(purchaseOrder.code)} className={classes.row} key={purchaseOrder.code}>
                                                                <CustomTableCell
                                                                    className={classNames(
                                                                        classes.customTableCell,
                                                                        {
                                                                            [classes.disabled]: purchaseOrder.expired,
                                                                        },
                                                                    )}
                                                                    align="left"
                                                                >
                                                                    PO
                                                                    {purchaseOrder.code}
                                                                </CustomTableCell>
                                                                <CustomTableCell
                                                                    className={classNames(
                                                                        classes.customTableCell,
                                                                        {
                                                                            [classes.disabled]: purchaseOrder.expired,
                                                                        },
                                                                    )}
                                                                    align="left"
                                                                >
                                                                    {purchaseOrder.supplier.name}
                                                                </CustomTableCell>
                                                                <CustomTableCell
                                                                    className={classNames(
                                                                        classes.customTableCell,
                                                                        {
                                                                            [classes.disabled]: purchaseOrder.expired,
                                                                        },
                                                                    )}
                                                                    align="left"
                                                                >
                                                                    {purchaseOrder.deliveryBranch.name}
                                                                </CustomTableCell>
                                                                <CustomTableCell
                                                                    className={classNames(
                                                                        classes.customTableCell,
                                                                        {
                                                                            [classes.disabled]: purchaseOrder.expired,
                                                                        },
                                                                    )}
                                                                    align="left"
                                                                >
                                                                    {moment(purchaseOrder.createdDateTime).format('DD/MM/YYYY')}
                                                                </CustomTableCell>
                                                                <CustomTableCell className={classes.customTableCell} align="center">
                                                                    {purchaseOrder.authorisedByContactCode
                                                                        ? (
                                                                            <CheckCircleIcon className={classNames(
                                                                                classes.colourTableIcons,
                                                                                {
                                                                                    [classes.pass]: purchaseOrder.authorisedByContactCode,
                                                                                    [classes.fail]: !purchaseOrder.authorisedByContactCode,
                                                                                },
                                                                            )}
                                                                            />
                                                                        )
                                                                        : (
                                                                            <WarningIcon className={classNames(
                                                                                classes.colourTableIcons,
                                                                                {
                                                                                    [classes.pass]: purchaseOrder.authorisedByContactCode,
                                                                                    [classes.fail]: !purchaseOrder.authorisedByContactCode,
                                                                                },
                                                                            )}
                                                                            />
                                                                        )}
                                                                </CustomTableCell>
                                                                <CustomTableCell className={classes.customTableCell} align="center">
                                                                    {purchaseOrder.sentByContactCode
                                                                        ? (
                                                                            <CheckCircleIcon className={classNames(
                                                                                classes.colourTableIcons,
                                                                                {
                                                                                    [classes.pass]: purchaseOrder.sentByContactCode,
                                                                                    [classes.fail]: !purchaseOrder.sentByContactCode,
                                                                                },
                                                                            )}
                                                                            />
                                                                        )
                                                                        : (
                                                                            <WarningIcon className={classNames(
                                                                                classes.colourTableIcons,
                                                                                {
                                                                                    [classes.pass]: purchaseOrder.sentByContactCode,
                                                                                    [classes.fail]: !purchaseOrder.sentByContactCode,
                                                                                },
                                                                            )}
                                                                            />
                                                                        )}
                                                                </CustomTableCell>

                                                                {/* <CustomTableCell
                                                                    className={classNames(
                                                                        classes.customTableCell,
                                                                        {
                                                                            [classes.disabled]: manufacturerSupplier.expired,
                                                                        },
                                                                    )}
                                                                    align="center"
                                                                >
                                                                    {manufacturerSupplier.supplier
                                                                        ? (
                                                                            <DoneIcon className={classes.blackTableIcons} />
                                                                        )
                                                                        : ''}
                                                                </CustomTableCell>
                                                                <CustomTableCell
                                                                    className={classNames(
                                                                        classes.customTableCell,
                                                                        {
                                                                            [classes.disabled]: manufacturerSupplier.expired,
                                                                        },
                                                                    )}
                                                                    align="left"
                                                                >
                                                                    {manufacturerSupplier.supplier ? manufacturerSupplier.telephoneNumber : ''}
                                                                </CustomTableCell>
                                                                <CustomTableCell
                                                                    className={classNames(
                                                                        classes.customTableCell,
                                                                        {
                                                                            [classes.disabled]: manufacturerSupplier.expired,
                                                                        },
                                                                    )}
                                                                    align="left"
                                                                >
                                                                    {manufacturerSupplier.supplier ? manufacturerSupplier.emailAddress : ''}
                                                                </CustomTableCell>
                                                                <CustomTableCell
                                                                    className={classNames(
                                                                        classes.customTableCell,
                                                                        {
                                                                            [classes.disabled]: manufacturerSupplier.expired,
                                                                        },
                                                                    )}
                                                                    align="left"
                                                                >
                                                                    {manufacturerSupplier.supplier ? manufacturerSupplier.accountReference : ''}
                                                                </CustomTableCell>
                                                                <CustomTableCell
                                                                    className={classNames(
                                                                        classes.customTableCell,
                                                                        {
                                                                            [classes.disabled]: manufacturerSupplier.expired,
                                                                        },
                                                                    )}
                                                                    align="left"
                                                                >
                                                                    {manufacturerSupplier.supplier ? manufacturerSupplier.ourAccountReference : ''}
                                                                </CustomTableCell>
                                                                <CustomTableCell className={classes.customTableCell} align="center">
                                                                    {manufacturerSupplier.recordComplete
                                                                        ? (
                                                                            <CheckCircleIcon className={classNames(
                                                                                classes.colourTableIcons,
                                                                                {
                                                                                    [classes.pass]: manufacturerSupplier.recordComplete,
                                                                                    [classes.fail]: !manufacturerSupplier.recordComplete,
                                                                                },
                                                                            )}
                                                                            />
                                                                        )
                                                                        : (
                                                                            <WarningIcon className={classNames(
                                                                                classes.colourTableIcons,
                                                                                {
                                                                                    [classes.pass]: manufacturerSupplier.recordComplete,
                                                                                    [classes.fail]: !manufacturerSupplier.recordComplete,
                                                                                },
                                                                            )}
                                                                            />
                                                                        )}
                                                                </CustomTableCell>
                                                                <CustomTableCell className={classes.customTableCell} align="left">
                                                                    <IconButton id={manufacturerSupplier.code} onClick={(event) => this.props.handleEditPurchaseOrderClick(manufacturerSupplier.code, event)}>
                                                                        <EditIcon className={classes.blackTableIcons} />
                                                                    </IconButton>
                                                                </CustomTableCell>
                                                                <CustomTableCell className={classes.customTableCell} align="left">
                                                                    {!manufacturerSupplier.expired
                                                                        ? (
                                                                            <IconButton id={manufacturerSupplier.code} onClick={(event) => this.props.handleExpireManufacturerSupplierClick(manufacturerSupplier.code, event)}>
                                                                                <DeleteIcon className={classes.blackTableIcons} />
                                                                            </IconButton>
                                                                        )
                                                                        : (
                                                                            <IconButton id={manufacturerSupplier.code} onClick={(event) => this.handleUnexpireManufacturerSupplier(manufacturerSupplier.code, event)}>
                                                                                <UndeleteIcon className={classes.blackTableIcons} />
                                                                            </IconButton>
                                                                        )}
                                                                </CustomTableCell> */}
                                                                <CustomTableCell className={classes.customTableCell} align="left">
                                                                    <IconButton id={purchaseOrder.code} onClick={(event) => this.props.handleEditPurchaseOrderClick(purchaseOrder.code, event)}>
                                                                        <EditIcon className={classes.blackTableIcons} />
                                                                    </IconButton>
                                                                </CustomTableCell>

                                                            </TableRow>
                                                        )
                                                        : ''))}
                                                </TableBody>
                                            </Table>
                                        </>
                                    )}
                            </>
                        )}
                </Paper>

                {/* <ManufacturerSupplierEditDialog
                    handleCloseEditManufacturerSupplierDialogClick={this.handleCloseEditManufacturerSupplierDialogClick}
                    manufacturerSupplier={currentManufacturerSupplier}
                    manufacturerSupplierLoading={purchaseOrdersLoading}
                    showEditManufacturerSupplierDialog={showEditManufacturerSupplierDialog}
                    passNewManufacturerSupplier={this.props.passNewManufacturerSupplier}
                />
                <ManufacturerSupplierExpireDialog
                    handleCloseExpireManufacturerSupplierDialogClick={this.handleCloseExpireManufacturerSupplierDialogClick}
                    handleExpireManufacturerSupplierCloseDialogClick={this.handleExpireManufacturerSupplierCloseDialogClick}
                    manufacturerSupplier={currentManufacturerSupplier}
                    manufacturerSupplierLoading={purchaseOrdersLoading}
                    passNewManufacturerSupplier={this.props.passNewManufacturerSupplier}
                    showExpireManufacturerSupplierDialog={showExpireManufacturerSupplierDialog}
                /> */}
            </>

        );
    }
}

export default withSnackbar(withStyles(styles)(ManufacturerSuppliersTable));
