import React from 'react';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import HybridTextField from '../hybridTextField';

import fetch from '../../utilities/fetch';

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
}))(TableCell);

const styles = theme => ({
    label: {
        fontSize: 11,
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: 7,
        marginTop: theme.spacing.unit * 2,
        width: '100%',
        textAlign: 'left',
    },
    componentContainer: {
        width: '100%',
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: theme.spacing.unit * 1.5,
    },

    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    divider: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    authorisedPaymentChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '87px',
    },
    declinedPaymentChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '87px',
    },
    chip: {
        height: 21,
        borderRadius: 5,
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    table: {
        // width: '50%',
    },
});

class AgreementPayments extends React.Component {
    state = {
        errors: false,
        loading: false,
        snackbarOpen: false,
        payments: [],
        showPaymentDetailDialog: false,
        detailDialogTransactionStatus: null,
        // {
        //     paymentDate: '',
        //     source: '',
        //     transactionStatus: '',
        //     amount: null,
        //     cardNo: '',
        //     paymentID: null,
        //     currencyISO: '',
        //     prettyAmount: '',
        //     paymentTime: '',
        // },
    }

    async componentDidMount() {
        // const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/portal/rental-agreement-payments/${this.props.rentalAgreementCode}`);
        // const data = await fetch(url).then(response => response.json());
        // this.setState({ payments: data });
        // console.log('Agreement Payments', data);
    }

    handleOpenPaymentDetailClick = (rentalAgreementCode, paymentID, transactionStatus) => {
        console.log(rentalAgreementCode, paymentID);
        this.setState({
            showPaymentDetailDialog: true,
            detailDialogTransactionStatus: transactionStatus,
        });
    }

    handleClosePaymentDetailClick = (rentalAgreementCode, paymentID) => {
        this.setState({ showPaymentDetailDialog: false });
    }

    updateRefundData = (rentalAgreementCode, paymentID) => {
        this.setState({ showPaymentDetailDialog: false });
    }

    render() {
        const { classes, payments, rentalAgreementCode } = this.props;
        const { detailDialogTransactionStatus, errors, showPaymentDetailDialog } = this.state;

        return (
            <div className={classes.componentContainer}>
                { payments.length === 0
                    ? (
                        <HybridTextField
                            alert
                            label="Payments"
                            text="None"
                            fieldStyle={{ style: 'alert', tooltip: 'No payments' }}
                        />
                    )
                    : (
                        <React.Fragment>
                            <div
                                className={classes.label}
                            >
                                Payments
                            </div>
                            <Paper>
                                <Table className={classes.table} padding="dense">
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell>#</CustomTableCell>
                                            <CustomTableCell>Source</CustomTableCell>
                                            <CustomTableCell align="center">Payment ID</CustomTableCell>
                                            <CustomTableCell align="center">Card Number</CustomTableCell>
                                            <CustomTableCell align="center">Payment Date</CustomTableCell>
                                            <CustomTableCell align="center">Payment Time</CustomTableCell>
                                            <CustomTableCell align="center">Amount</CustomTableCell>
                                            <CustomTableCell align="center">Status</CustomTableCell>
                                            <CustomTableCell align="center" />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {payments.sort((a, b) => a.paymentDate - b.paymentDate).reverse().map((payment, i) => {
                                            i++;
                                            return (
                                                <TableRow className={classes.row} key={i}>
                                                    <CustomTableCell component="th" scope="row">{i}</CustomTableCell>
                                                    <CustomTableCell align="left">{payment.source}</CustomTableCell>
                                                    <CustomTableCell align="center">{payment.paymentID}</CustomTableCell>
                                                    <CustomTableCell align="center">{payment.cardNo}</CustomTableCell>
                                                    <CustomTableCell align="center">{payment.paymentDate}</CustomTableCell>
                                                    <CustomTableCell align="center">{payment.paymentTime}</CustomTableCell>
                                                    <CustomTableCell align="right">{`£${payment.amount.toFixed(2)}`}</CustomTableCell>
                                                    <CustomTableCell align="center">{payment.transactionStatus === 'Authorised' ? <Chip label="Authorised" className={`${classes.chip} ${classes.authorisedPaymentChip}`} /> : <Chip label={payment.transactionStatus} className={`${classes.chip} ${classes.declinedPaymentChip}`} /> }</CustomTableCell>
                                                    <CustomTableCell align="right"><IconButton disabled onClick={() => this.handleOpenPaymentDetailClick(rentalAgreementCode, payment.paymentID, payment.transactionStatus)} className={classes.button} aria-label="More info"><InfoIcon /></IconButton></CustomTableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </React.Fragment>
                    )
                }
                <Dialog
                    open={showPaymentDetailDialog}
                    onClose={this.handleClosePaymentDetailClick}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <Typography variant="h6" gutterBottom>
                            Payment Detail
                        </Typography>
                        <DialogContentText id="alert-dialog-description">
                            <div className={classes.dialogContentContainer}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus
                                non enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
                                imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
                                Convallis convallis tellus id interdum velit laoreet id donec ultrices.
                            </div>
                        </DialogContentText>
                        <Divider />
                        {detailDialogTransactionStatus === 'Authorised' && (
                            <React.Fragment>
                                <div className={classes.dialogContentContainer}>
                                    <Typography variant="h6" gutterBottom>
                                        Refund Payment
                                    </Typography>
                                    <DialogContentText>
                                        This will refund this payment to the original payment card.
                                    </DialogContentText>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <TextField
                                                className={classes.formControl}
                                                onChange={this.updateRefundData}
                                                name="reason"
                                                id="reason"
                                                label="Reason"
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                error={!!errors.surname}
                                                helperText={errors.surname}
                                                value=""
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button variant="contained" className={classes.dialogButton}>
                                        Refund
                                    </Button>
                                </div>
                                <Divider />
                                <div className={classes.dialogContentContainer}>
                                    <Typography variant="h6" gutterBottom>
                                        Claimback Payment
                                    </Typography>
                                    <DialogContentText>
                                        This will record a customer Claimback against this payment. You may optionally post an admin charge.
                                    </DialogContentText>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <TextField
                                                className={classes.formControl}
                                                onChange={this.updateRefundData}
                                                name="reason"
                                                id="reason"
                                                label="Reason"
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                error={!!errors.surname}
                                                helperText={errors.surname}
                                                value=""
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="claimbackAdminChargeAmount"
                                                name="claimbackAdminChargeAmount"
                                                variant="outlined"
                                                label="Admin Charge"
                                                margin="normal"
                                                value=""
                                                onChange={this.updateRefundData}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button variant="contained" className={classes.dialogButton}>
                                        Claimback
                                    </Button>
                                </div>
                                <Divider />
                            </React.Fragment>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClosePaymentDetailClick} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(AgreementPayments);
