import React from 'react';
import {
    Card,
    CardActionArea,
    CardMedia,
    CircularProgress,
    Paper,
    Grid,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import moment from 'moment-timezone';
import Slider from 'react-slick';
import HybridTextField from '../../hybridTextField';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../slick-carousel/slick-theme.css';

const styles = (theme) => ({
    activeIcon: {
        color: '#4caf50',
    },
    alert: {
        color: '#f1180b',
    },
    card: {
        height: 150,
        width: 150,
    },
    hybridTextIconRight: {
        fontSize: 16,
        marginLeft: theme.spacing.unit * 1,
        transform: 'translate(0%, 13%)',
    },
    media: {
        height: 150,
    },
    notSetIcon: {
        color: '#d7d7d7',
    },
    paper: {
        alignItems: 'center',
        border: '1px solid #e0e0e0',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        width: '100%',
    },
    image: {
        height: 400,
        // width: 700,
    },
    imagesContainer: {
        marginBottom: theme.spacing.unit * 5,
        width: '100%',
    },
    progress: {
        opacity: '.5',
    },
    progressContainer: {
        marginBottom: theme.spacing.unit * 3,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        width: 60,
    },
    slickContainer: {
        background: '#419be0',
        padding: 40,
    },
    slickSlide: {
        img: {
            margin: 'auto',
        },
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        // marginBottom: 4,
    },
    imageDiv: {
        // backgroundImage: 'url("https://pwc-images.s3-eu-west-1.amazonaws.com/room1.jpeg")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        height: 400,
    },
});

class ContactVisitImages extends React.Component {
    state = {
    };

    render() {
        const {
            classes,
            visitImages,
        } = this.props;

        const settings = {
            // centerMode: true,
            dots: true,
            lazyLoad: true,
            // variableWidth: true,
        };

        return (
            <div className={classes.imagesContainer}>
                <div className={classes.textFieldLabel}>
                    Images
                </div>
                {visitImages !== null
                    ? (
                        <>
                            {(visitImages || []).length !== 0
                                ? (
                                    <div className="slickContainer">
                                        <Slider {...settings}>
                                            {visitImages.sort((a, b) => a.createdDateTime - b.createdDateTime).map((visitImage) => (
                                                <div key={visitImage.code}>
                                                    <section
                                                        className={`${classes.imageDiv}`}
                                                        style={{ backgroundImage: `url(${visitImage.link})` }}
                                                    />
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                )
                                : (
                                    <Typography variant="body1">
                                        No visit images found
                                    </Typography>
                                )}
                        </>
                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )}
            </div>
        );
    }
}

export default withSnackbar(withStyles(styles)(ContactVisitImages));
