import React from 'react';
import {
    CircularProgress,
    Paper,
    Grid,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import moment from 'moment-timezone';
import HybridTextField from './hybridTextField';

const styles = theme => ({
    activeIcon: {
        color: '#4caf50',
    },
    alert: {
        color: '#f1180b',
    },
    hybridTextIconRight: {
        fontSize: 16,
        marginLeft: theme.spacing.unit * 1,
        transform: 'translate(0%, 13%)',
    },
    notesContainer: {
        align: 'left',
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
    },
    notesPaper: {
        backgroundColor: theme.palette.background.default,
        border: '1px solid #e0e0e0',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing.unit * 2,
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 1}px`,
        width: '100%',
    },
    notSetIcon: {
        color: '#d7d7d7',
    },
    paper: {
        alignItems: 'center',
        border: '1px solid #e0e0e0',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        width: '100%',
    },
    progress: {
        opacity: '.5',
    },
    progressContainer: {
        marginBottom: theme.spacing.unit * 3,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        width: 60,
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        marginBottom: 4,
    },
});

class CustomerNotes extends React.Component {
    state = {
    }

    render() {
        const {
            classes,
            visitNotes,
            visitNotesTitle,
        } = this.props;

        // console.log('>>>To Deliver', visitItemsToDeliver);

        return (
            <div className={classes.notesContainer}>
                <div className={classes.textFieldLabel}>
                    {visitNotesTitle}
                </div>
                {visitNotes !== null
                    ? (
                        <React.Fragment>
                            {visitNotes.length !== 0
                                ? (
                                    <React.Fragment>
                                        {visitNotes.sort((a, b) => a.createdDateTime - b.createdDateTime).map(visitNote => (
                                            <Paper
                                                className={classes.notesPaper}
                                                elevation={0}
                                            >
                                                <Grid
                                                    container
                                                    spacing={0}
                                                >
                                                    <Grid item xs={2}>
                                                        <HybridTextField
                                                            label="Date Created"
                                                            text={moment(visitNote.createdDateTime).format('DD-MM-YYYY')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <HybridTextField
                                                            label="Time Created"
                                                            text={moment(visitNote.createdDateTime).format('HH:mm')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <HybridTextField
                                                            label="Created By"
                                                            text={visitNote.createdByContactFullName}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                >
                                                    <Grid item xs={12}>
                                                        <HybridTextField
                                                            label="Note"
                                                            text={visitNote.note}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        ))}
                                    </React.Fragment>
                                )
                                : (
                                    <Typography variant="body1">
                                        No notes found
                                    </Typography>
                                )
                            }
                        </React.Fragment>
                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )
                }
            </div>
        );
    }
}

const visitItemsShape = {
    barcode: PropTypes.string,
    brandModelNo: PropTypes.string,
    colour: PropTypes.string,
    deliveryStatus: PropTypes.number,
    productTypeDescription: PropTypes.string,
    reference: PropTypes.string,
    shortDescription: PropTypes.string,
    visitItemCode: PropTypes.number,
};

CustomerNotes.propTypes = {
    classes: PropTypes.object.isRequired,
    visitItems: PropTypes.shape(visitItemsShape).isRequired,
};

export default withSnackbar(withStyles(styles)(CustomerNotes));
