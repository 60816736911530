import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import GoogleMapReact from 'google-map-react';

const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;
const stylesx = require('../../constants/googleMapsStyle.json');

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        marginBottom: theme.spacing.unit * 3,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    authorisedPaymentChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '87px',
    },
    declinedPaymentChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '87px',
    },
    unknownPaymentChip: {
        minWidth: '87px',
    },
    chip: {
        height: 21,
        borderRadius: 5,
    },
    paper: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 6,
    },
    graph: {
        width: 800,
    },
});

class canvassMap extends React.Component {
    state = {
        googleMapReactKey: Math.floor(Math.random() * 1000) + 1,
    };

    componentDidUpdate(prevProps) {
        if (this.props.currentMapData !== prevProps.currentMapData) {
            this.setState({
                googleMapReactKey: Math.floor(Math.random() * 1000) + 1,
            });
        }
    }

    renderStartEndMarkers = (map, maps, roadMarkers) => {
        const labelOriginFilled = new google.maps.Point(12, 9);
        const pinSVGFilled = 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z';

        const markerImage = {
            anchor: new google.maps.Point(12, 17),
            fillColor: this.props.currentMapData[0].colour,
            fillOpacity: 1,
            labelOrigin: labelOriginFilled,
            path: pinSVGFilled,
            scale: 2,
            strokeColor: 'white',
            strokeWeight: 2,
        };

        const startMarker = new maps.Marker({
            animation: google.maps.Animation.DROP,
            icon: markerImage,
            label: 'S',
            map,
            position: { lat: roadMarkers[0].lat, lng: roadMarkers[0].lng },
        });
        const endMarker = new maps.Marker({
            animation: google.maps.Animation.DROP,
            icon: markerImage,
            label: 'E',
            map,
            position: { lat: roadMarkers[roadMarkers.length - 1].lat, lng: roadMarkers[roadMarkers.length - 1].lng },
        });
    };

    snapToRoads = async (path) => {
        const stepSize = path.length > 100 ? Math.ceil(path.length / 100) : 1;
        const steppedPath = path.filter((drop, i) => i % stepSize === stepSize - 1);
        const pathString = steppedPath.map((p) => `${p.lat},${p.lng}`).join('|');
        const response = await fetch(`https://roads.googleapis.com/v1/snapToRoads?path=${pathString}&key=${GOOGLE_MAPS_KEY}&interpolate=true`);
        const data = await response.json();
        return data.snappedPoints.map((p) => ({ lat: p.location.latitude, lng: p.location.longitude }));
    };

    calculateIdealZoom = (map, maps, coordinates) => {
        const zoomBounds = new maps.LatLngBounds();
        coordinates.forEach((point) => {
            zoomBounds.extend(new maps.LatLng(point.lat, point.lng));
        });

        const mapWidth = map.getDiv().offsetWidth;
        const mapHeight = map.getDiv().offsetHeight;
        const ne = zoomBounds.getNorthEast();
        const sw = zoomBounds.getSouthWest();
        const maxZoom = 21;
        const lngDiff = ne.lng() - sw.lng();
        const latDiff = ne.lat() - sw.lat();
        const latZoom = Math.log(mapHeight / latDiff) / Math.LN2 - 1;
        const lngZoom = Math.log(mapWidth / lngDiff) / Math.LN2 - 1;
        const idealZoom = Math.min(latZoom, lngZoom, maxZoom);
        map.setZoom(Math.floor(idealZoom) + 1);
    };

    calculateCentre = (map, maps, coordinates) => {
        let latTotal = 0;
        let lngTotal = 0;

        coordinates.forEach((coord) => {
            latTotal += coord.lat;
            lngTotal += coord.lng;
        });

        const centerLat = latTotal / coordinates.length;
        const centerLng = lngTotal / coordinates.length;
        // return { lat: centerLat, lng: centerLng };
        map.setCenter({ lat: centerLat, lng: centerLng });
    };

    renderRoadRoute = (map, maps, pathCoordinates, runColour) => {
        const snappedPolyline = new maps.Polyline({
            geodesic: true,
            path: pathCoordinates,
            strokeColor: runColour,
            strokeOpacity: 1.0,
            strokeWeight: 6,
        });

        snappedPolyline.setMap(map);
    };

    renderAllRoadRoutes = async (map, maps, formattedMapData) => {
        await Promise.all(formattedMapData.map(async (run) => {
            const roadMarkers = await this.snapToRoads(run);
            this.renderRoadRoute(map, maps, roadMarkers, run[0].colour);
        }));
    };

    renderStyles = (map, maps) => {
        const customMapStyle = [
            {
                elementType: 'labels',
                featureType: 'administrative.land_parcel',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                elementType: 'labels.text',
                featureType: 'poi',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'poi.business',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                elementType: 'labels.icon',
                featureType: 'road',
                stylers: [
                    {
                        visibility: 'on',
                    },
                ],
            },
            {
                elementType: 'labels',
                featureType: 'road.local',
                stylers: [
                    {
                        visibility: 'on',
                    },
                ],
            },
            {
                featureType: 'transit',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
        ];

        map.setOptions({ styles: customMapStyle });
    };

    render() {
        const {
            classes,
            currentMapData,
            formattedMapData,
        } = this.props;

        const {
            googleMapReactKey,
        } = this.state;

        const handleApiLoaded = async (map, maps, coordinates) => {
            this.renderStyles(map, maps);
            this.renderAllRoadRoutes(map, maps, formattedMapData);
            const roadMarkers = await this.snapToRoads(coordinates);
            this.renderRoadRoute(map, maps, roadMarkers, currentMapData[0].colour);
            this.renderStartEndMarkers(map, maps, roadMarkers);
            await this.calculateCentre(map, maps, roadMarkers);
            this.calculateIdealZoom(map, maps, roadMarkers);
        };

        return (
            <>
                <GoogleMapReact
                    key={googleMapReactKey}
                    bootstrapURLKeys={
                        {
                            key: GOOGLE_MAPS_KEY,
                        }
                    }
                    defaultCenter={{
                        lat: 54.00,
                        lng: -4.00,
                    }}
                    options={{
                        disableDefaultUI: true,
                        draggable: true,
                        keyboardShortcuts: false,
                        scaleControl: true,
                        scrollwheel: true,
                        styles: stylesx,
                        fullscreenControl: true,
                        streetViewControl: true,
                    }}
                    defaultZoom={5.8}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, currentMapData)}
                />
            </>
        );
    }
}

export default withSnackbar(withStyles(styles)(canvassMap));
