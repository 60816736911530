import React from 'react';
import {
    CssBaseline,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Layout from '../Layout';
import EngineerSchedule from '../../components/v2/engineerSchedule';

const styles = (theme) => ({
});

class AgentSchedule extends React.Component {
    state = {
    };

    async componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        const {
            errors,
        } = this.state;

        return (
            <Layout pageTitle="Agent Schedule">
                <>
                    <CssBaseline />
                    <EngineerSchedule />
                </>
            </Layout>
        );
    }
}

export default withStyles(styles)(AgentSchedule);
