import React from 'react';
import {
    Button,
    CssBaseline,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import findIndex from 'lodash/findIndex';

import { ContactsOutlined } from '@material-ui/icons';
import moment from 'moment-timezone';
import Layout from '../Layout';
import PageHeader from '../../components/pageHeader';
import ContactTabs from '../../components/v2/contactTabs';
import ContactDetailTabs from '../../components/v2/contactDetailTabs';
import ContactBadges from '../../components/v2/contactBadges';
import fetch, { fetchJson2 } from '../../utilities/fetch';
import sleep from '../../utilities/sleep';

const AMPLIFY5 = 1002;
const { REACT_APP_API_SERVER } = process.env;
const currentDateTime = moment.tz(moment(), 'Europe/London');

const removeFalseyValuesFromObject = (obj) => pickBy(obj, identity);

const styles = (theme) => ({
});

class Contact extends React.Component {
    state = {
        contactAgents: null,
        contactAgentsLoading: true,
        contactAgreementsLoading: true,
        contactContactAgents: null,
        contactContactAgentsLoading: true,
        contactCreditReportLoading: true,
        contactDuplicatesLoading: true,
        contactHistoriesLoading: true,
        contactLeads: null,
        contactLeadsLoading: true,
        contactLoading: true,
        contactSalesLoading: true,
        contactSecurityAnswersLoading: true,
        contactVisitsLoading: true,
        contactVulnerabilitiesLoading: true,
        paymentCardsLoading: true,
        user: null,
        userLoading: true,
        users: null,
        usersLoading: true,

        addressLongLat: null,
        agreementsAlerts: null,
        branches: null,
        campaigns: [],
        contactCode: null,
        convertLeadProducts: null,
        contact: null,
        duplicates: null,
        engineers: null,
        engineersLoading: true,
        errors: {},
        histories: null,
        leads: null,
        paymentCards: null,
        requestedLeadTab: null,
        visits: null,
        vulnerabilityTypesLoading: true,
    };

    static getDerivedStateFromProps(props) {
        const { contactCode } = props.match.params;
        return {
            contactCode,
        };
    }

    componentDidMount() {
        const { contactCode } = this.state;
        this.fetchContactViewData(contactCode);
        // const { contactCode } = this.state;
        // const contactData = this.fetchContactData();
        // fetchJson2('branches')
        //     .then((branches) => {
        //         this.setState({ branches: branches.payload });
        //     });
        // fetchJson2('v2/engineers')
        //     .then((engineers) => {
        //         this.setState({ engineers: engineers.payload });
        //     });
        // fetchJson2(`v2/contact/${this.state.contactCode}/paymentCards`)
        //     .then((paymentCards) => {
        //         this.setState({
        //             paymentCards: paymentCards.payload,
        //             paymentCardsLoading: false,
        //         });
        //     });
        // contactData.then(() => { // Dependent on the contact
        //     this.fetchAgreementsData();
        // });
        // contactData.then(() => { // Dependent on the contact
        //     this.fetchVisitsData();
        // });
        // contactData.then(() => { // Dependent on the contact
        //     this.fetchLeadsData();
        // });
        // contactData.then(() => { // Dependent on the contact
        //     this.fetchHistoriesData();
        // });
        // this.setState({ contactCode });
    }

    componentDidUpdate = async (prevProps) => {
        if (this.props.match.params.contactCode !== prevProps.match.params.contactCode) {
            await this.setDefaults();
            this.fetchContactViewData(this.props.match.params.contactCode);
            window.scrollTo(0, 0);
        }
    };

    setDefaults = async () => {
        this.setState({
            contactAgents: null,
            contactAgentsLoading: true,
            contactContactAgents: null,
            contactContactAgentsLoading: true,
            contactAgreementsLoading: true,
            contactSalesLoading: true,
            contactHistoriesLoading: true,
            contactVisitsLoading: true,
            contactLeadsLoading: true,
            contactLoading: true,
            contactSecurityAnswersLoading: true,
            paymentCardsLoading: true,
            addressLongLat: null,
            agreementsAlerts: null,
            branches: null,
            campaigns: [],
            contactCode: null,
            convertLeadProducts: null,
            contact: null,
            duplicates: null,
            engineers: null,
            engineersLoading: true,
            errors: {},
            histories: null,
            leads: null,
            paymentCards: null,
            requestedLeadTab: null,
            visits: null,
            vulnerabilityTypesLoading: true,
        });
    };

    fetchContactViewData = async (contactCode) => {
        await fetchJson2('v2/user')
            .then((user) => {
                this.setState({
                    user: user.payload,
                    userLoading: false,
                });
            });
        await fetchJson2('v2/users')
            .then((users) => {
                this.setState({
                    users: users.payload,
                    usersLoading: false,
                });
            });
        const contactData = this.fetchContactData();
        fetchJson2('branches')
            .then((branches) => {
                this.setState({ branches: branches.payload });
            });
        fetchJson2('v2/debtStatuses')
            .then((debtStatuses) => {
                this.setState({ debtStatuses: debtStatuses.payload });
            });
        fetchJson2('v2/agreementStatuses')
            .then((agreementStatuses) => {
                this.setState({ agreementStatuses: agreementStatuses.payload });
            });
        fetchJson2('v2/lead/statuses')
            .then((leadStatuses) => {
                const orderedLeadStatuses = leadStatuses.payload.sort();
                this.setState({ leadStatuses: orderedLeadStatuses });
            });
        fetchJson2('v2/complaint/referenceTypes')
            .then((complaintReferenceTypes) => {
                this.setState({ complaintReferenceTypes: complaintReferenceTypes.payload });
            });
        fetchJson2('v2/complaint/statuses')
            .then((complaintStatuses) => {
                this.setState({ complaintStatuses: complaintStatuses.payload });
            });
        fetchJson2('v2/complaint/types')
            .then((complaintTypes) => {
                this.setState({ complaintTypes: complaintTypes.payload });
            });
        fetchJson2('v2/complaint/sources')
            .then((complaintSources) => {
                this.setState({ complaintSources: complaintSources.payload });
            });
        fetchJson2('v2/debtCompanies')
            .then((debtCompanies) => {
                this.setState({ debtCompanies: debtCompanies.payload });
            });
        fetchJson2('v2/engineers')
            .then((engineers) => {
                this.setState({
                    engineers: engineers.payload,
                    engineersLoading: false,
                });
            });
        fetchJson2('v2/agents')
            .then((agents) => {
                this.setState({
                    contactAgents: agents.payload,
                    contactAgentsLoading: false,
                });
            });
        fetchJson2('v2/product/types')
            .then((productTypes) => {
                this.setState({ productTypes: productTypes.payload });
            });
        fetchJson2(`v2/contact/${contactCode}/paymentCards`)
            .then((paymentCards) => {
                this.setState({
                    paymentCards: paymentCards.payload,
                    paymentCardsLoading: false,
                });
            });
        fetchJson2('v2/vulnerabilityTypes')
            .then((vulnerabilityTypes) => {
                this.setState({
                    vulnerabilityTypes: vulnerabilityTypes.payload,
                    vulnerabilityTypesLoading: false,
                });
            });
        contactData.then(() => { // Dependent on the contact
            this.fetchAgreementsData();
        });
        contactData.then(() => { // Dependent on the contact
            this.fetchSalesData();
        });
        contactData.then(() => { // Dependent on the contact
            this.fetchVisitsData();
        });
        contactData.then(() => { // Dependent on the contact
            this.fetchLeadsData();
        });
        contactData.then(() => { // Dependent on the contact
            this.fetchHistoriesData();
        });
        contactData.then(() => { // Dependent on the contact
            this.fetchDuplicatesData();
        });
        this.setState({ contactCode });
    };

    fetchContactData = async () => {
        const { contactCode } = this.state;
        this.setState({
            contactLoading: true,
        });

        try {
            // const [contact] = await Promise.all([fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/v2/contact/${contactCode}`), sleep(50)]);
            const [contact] = await Promise.all([fetchJson2(`v2/contact/${contactCode}`), sleep(50)]);

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    ...contact.payload,
                },
            }));
        } finally {
            this.setState({
                contactLoading: false,
            });
        }
    };

    fetchLeadsAgreementsData = async () => {
        const { contactCode } = this.state;
        this.setState({
            contactAgreementsLoading: true,
            contactLeadsLoading: true,
        });

        try {
            await Promise.all([
                this.fetchAgreementsData(),
                this.fetchLeadsData()]);
        } finally {
            this.setState({
                contactAgreementsLoading: false,
                contactLeadsLoading: false,
            });
        }
    };

    fetchLeadsSalesData = async () => {
        this.setState({
            contactLeadsLoading: true,
            contactSalesLoading: true,
        });

        try {
            await Promise.all([
                this.fetchSalesData(),
                this.fetchLeadsData()]);
        } finally {
            this.setState({
                contactLeadsLoading: false,
                contactSalesLoading: false,
            });
        }
    };

    fetchAgreementsData = async () => {
        const { contactCode } = this.state;
        this.setState({
            contactAgreementsLoading: true,
        });

        try {
            // const [agreements] = await Promise.all([fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/v2/contact/${contactCode}/agreements`), sleep(50)]);
            const [agreements] = await Promise.all([fetchJson2(`v2/contact/${contactCode}/agreements`), sleep(50)]);

            const { agreementCode } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
            const requestedAgreementTab = findIndex(agreements, { code: parseInt(agreementCode, 10) });

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    agreements: agreements.payload,
                },
                requestedAgreementTab,
            }));
            // this.checkAlertsWarnings(agreements);
        } finally {
            this.setState({
                contactAgreementsLoading: false,
            });
        }
    };

    fetchSalesData = async () => {
        const { contactCode } = this.state;
        this.setState({
            contactSalesLoading: true,
        });

        try {
            const [sales] = await Promise.all([fetchJson2(`v2/contact/${contactCode}/sales`), sleep(50)]);

            const { saleCode } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
            const requestedSaleTab = findIndex(sales, { code: parseInt(saleCode, 10) });

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    sales: sales.payload,
                },
                requestedSaleTab,
            }));
            // this.checkAlertsWarnings(sales);
        } finally {
            this.setState({
                contactSalesLoading: false,
            });
        }
    };

    fetchLeadsData = async () => {
        const { contactCode } = this.state;
        this.setState({
            contactLeadsLoading: true,
        });

        try {
            // const [leads] = await Promise.all([fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/v2/contact/${contactCode}/leads`), sleep(50)]);
            const [leads] = await Promise.all([fetchJson2(`v2/contact/${contactCode}/leads`), sleep(50)]);

            const { leadCode } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
            const requestedLeadTab = findIndex(leads, { code: parseInt(leadCode, 10) });

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    leads: leads.payload,
                },
                requestedLeadTab,
            }));
            // this.checkAlertsWarnings(agreements);
        } finally {
            this.setState({
                contactLeadsLoading: false,
            });
        }
    };

    fetchDuplicatesData = async () => {
        const { contactCode } = this.state;
        this.setState({
            contactduplicatesLoading: true,
        });

        try {
            const [duplicates] = await Promise.all([fetchJson2(`v2/contact/${contactCode}/duplicates`), sleep(50)]);

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    duplicates: duplicates.payload,
                },
            }));
        } finally {
            this.setState({
                contactDuplicatesLoading: false,
            });
        }
    };

    fetchVisitsData = async () => {
        const { contactCode } = this.state;
        this.setState({
            contactVisitsLoading: true,
        });

        try {
            const [visits] = await Promise.all([fetchJson2(`v2/contact/${contactCode}/visits`), sleep(50)]);
            const { visitCode } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
            const requestedVisitTab = findIndex(visits, { code: parseInt(visitCode, 10) });

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    visits: visits.payload,
                },
                requestedVisitTab,
            }));
            // this.checkAlertsWarnings(agreements);
        } finally {
            this.setState({
                contactVisitsLoading: false,
            });
        }
    };

    fetchNextCall = async () => {
        fetchJson2('v2/contacts/nextCall')
            .then((nextCall) => {
                window.location.href = nextCall.payload.link;
            });
    };

    fetchHistoriesData = async () => {
        const { contactCode } = this.state;
        this.setState({
            contactHistoriesLoading: true,
        });

        try {
            const [histories] = await Promise.all([fetchJson2(`v2/contact/${contactCode}/histories`), sleep(50)]);

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: histories.payload,
                },
            }));
            // this.checkAlertsWarnings(agreements);
        } finally {
            this.setState({
                contactHistoriesLoading: false,
            });
        }
    };

    fetchContactSecurityAnswers = async () => {
        const { contactCode } = this.state;
        this.setState({
            contactSecurityAnswersLoading: true,
        });

        try {
            // const [securityAnswers] = await Promise.all([fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/v2/contact/${contactCode}/securityAnswers`), sleep(50)]);
            const [securityAnswers] = await Promise.all([fetchJson2(`v2/contact/${contactCode}/securityAnswers`), sleep(50)]);

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    securityAnswers: securityAnswers.payload,
                },
            }));
            console.log('>>> securityAnswers:', securityAnswers);
        } finally {
            this.setState({
                contactSecurityAnswersLoading: false,
            });
        }
    };

    fetchContactVulnerabilities = async () => {
        const { contactCode } = this.state;
        this.setState({
            contactVulnerabilitiesLoading: true,
        });

        try {
            const [vulnerabilities] = await Promise.all([fetchJson2(`v2/contact/${contactCode}/vulnerabilities`), sleep(50)]);

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    vulnerabilities: vulnerabilities.payload,
                },
            }));
            console.log('>>> Vulnerabilities:', vulnerabilities);
        } finally {
            this.setState({
                contactVulnerabilitiesLoading: false,
            });
        }
    };

    fetchContactCreditReport = async () => {
        const { contactCode } = this.state;
        this.setState({
            contactCreditReportLoading: true,
        });

        try {
            const [creditReport] = await Promise.all([fetchJson2(`v2/contact/${contactCode}/creditReports`), sleep(50)]);

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    creditReport: creditReport.payload,
                },
            }));
            console.log('>>> CreditReport:', creditReport);
        } finally {
            this.setState({
                contactCreditReportLoading: false,
            });
        }
    };

    fetchContactContactAgents = async () => {
        const { contactCode } = this.state;
        this.setState({
            contactContactAgentsLoading: true,
        });

        try {
            const [agents] = await Promise.all([fetchJson2(`v2/contact/${contactCode}/agent`), sleep(50)]);

            this.setState({
                contactContactAgents: agents.payload,
            });
            console.log('>>> contactContactAgents:', agents.payload);
        } finally {
            this.setState({
                contactContactAgentsLoading: false,
            });
        }
    };

    // checkAlertsWarnings = async (agreements) => {
    //     let agreementsAlerts;
    //     let agreementAlerts = {};
    //     let newAlerts = [];
    //     let newWarnings = [];
    //     let newBadges = [];
    //     await agreements.map((agreement) => {
    //         if (agreement.arrearsAmount > 0
    //             && agreement.Status === 'ACTIVE') {
    //             newAlerts = [
    //                 ...newAlerts,
    //                 'Agreement in arrears.',
    //             ];
    //             newBadges = [
    //                 ...newBadges,
    //                 {
    //                     label: 'Arrears Amount',
    //                     style: 'alert',
    //                     tooltip: 'Agreement in arrears',
    //                 },
    //             ];
    //         }
    //         if (agreement.items.find(item => item.controlDeviceCode)
    //         && agreement.items.find(item => item.controlDeviceState !== 'ON')) {
    //             newAlerts = [
    //                 ...newAlerts,
    //                 'All controllable items on the agreement have been switched off.',
    //             ];
    //         }
    //         if (agreement.agreementStatus === 'ACTIVE'
    //         && (agreement.Status === 'CANCELLED'
    //         || agreement.Status === 'None')) {
    //             newAlerts = [
    //                 ...newAlerts,
    //                 'No payment plan.',
    //             ];
    //             newBadges = [
    //                 ...newBadges,
    //                 {
    //                     label: 'Payment Plan Status',
    //                     style: 'alert',
    //                     tooltip: 'No payment plan',
    //                 },
    //             ];
    //         }
    //         if (agreement.agreementStatus === 'CANCELLED'
    //         && agreement.Status === 'ACTIVE') {
    //             newAlerts = [
    //                 ...newAlerts,
    //                 'Agreement cancelled but plan still active.',
    //             ];
    //             newBadges = [
    //                 ...newBadges,
    //                 {
    //                     label: 'Payment Plan Status',
    //                     style: 'alert',
    //                     tooltip: 'Agreement cancelled but plan still active',
    //                 },
    //             ];
    //         }
    //         if (agreement.items.length <= 0
    //             && agreement.agreementStatus === 'ACTIVE') {
    //             newAlerts = [
    //                 ...newAlerts,
    //                 'No rental agreement items.',
    //             ];
    //             newBadges = [
    //                 ...newBadges,
    //                 {
    //                     label: 'Items',
    //                     style: 'alert',
    //                     tooltip: 'No rental agreement items',
    //                 },
    //             ];
    //         }
    //         if (agreement.Card === 'NO'
    //             && agreement.Status === 'ACTIVE') {
    //             newAlerts = [
    //                 ...newAlerts,
    //                 'No active card on payment plan.',
    //             ];
    //             newBadges = [
    //                 ...newBadges,
    //                 {
    //                     label: 'Tokenised Card',
    //                     style: 'alert',
    //                     tooltip: 'No active card on payment plan',
    //                 },
    //             ];
    //         }
    //         if (agreement.agreementStatus !== 'CANCELLED'
    //             && agreement.items.find(item => !item.itemCode && item.stockControlType === 'Tracked')) {
    //             newAlerts = [
    //                 ...newAlerts,
    //                 'Tracked item not assigned to agreement.',
    //             ];
    //         }

    //         if (agreement.agreementStatus !== 'CANCELLED'
    //             && (agreement.portalPaidAmount < (agreement.agentCollectedFee === 1 ? 0 : agreement.agentFee))) {
    //             newAlerts = [
    //                 ...newAlerts,
    //                 'Agent Fee not fully paid.',
    //             ];
    //             newBadges = [
    //                 ...newBadges,
    //                 {
    //                     label: 'Agent Fee',
    //                     style: 'alert',
    //                     tooltip: 'Agent Fee not fully paid',
    //                 },
    //             ];
    //         }

    //         if (agreement.agreementStatus !== 'CANCELLED'
    //             && ((agreement.portalPaidAmount - (agreement.agentCollectedFee === 1 ? 0 : agreement.agentFee) < agreement.deliveryAmount))
    //             && agreement.deliveryAmount > 0) {
    //             newAlerts = [
    //                 ...newAlerts,
    //                 'Delivery Charge not fully paid.',
    //             ];
    //             newBadges = [
    //                 ...newBadges,
    //                 {
    //                     label: 'Delivery Charge',
    //                     style: 'alert',
    //                     tooltip: 'Delivery Charge not fully paid',
    //                 },
    //             ];
    //         }

    //         if (agreement.agreementStatus !== 'CANCELLED'
    //             && ((agreement.portalPaidAmount - (agreement.agentCollectedFee === 1 ? 0 : agreement.agentFee) - agreement.deliveryAmount < agreement.depositAmount))
    //             && agreement.depositAmount > 0) {
    //             newAlerts = [
    //                 ...newAlerts,
    //                 'Advance Payment not fully paid.',
    //             ];
    //             newBadges = [
    //                 ...newBadges,
    //                 {
    //                     label: 'Advance Payment',
    //                     style: 'alert',
    //                     tooltip: 'Advance Payment not fully paid',
    //                 },
    //             ];
    //         }

    //         // / warnings
    //         if (agreement.items.length > 0
    //             && !agreement.items.find(item => item.deliveryStatus === 1)
    //             && agreement.agreementStatus !== 'CANCELLED') {
    //             newWarnings = [
    //                 ...newWarnings,
    //                 'Agreement items not fully delivered.',
    //             ];
    //         }

    //         agreementAlerts = {
    //             agreementCode: agreement.code,
    //             alerts: newAlerts,
    //             warnings: newWarnings,
    //             badges: newBadges,
    //         };

    //         agreementsAlerts = [
    //             ...agreementsAlerts || [],
    //             agreementAlerts,
    //         ];
    //         newAlerts = [];
    //         newWarnings = [];
    //         newBadges = [];
    //     });
    //     // this.setState(state => ({
    //     //     agreementsAlerts: [
    //     //         ...state.agreementsAlerts || [],
    //     //         agreementAlerts,
    //     //     ],
    //     // }));
    //     this.setState({
    //         agreementsAlerts,
    //     });
    //     // console.log('-->', agreementsAlerts);
    //     // return (agreementsAlerts);
    // }

    cancelVisit = async (visitCode, payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/visit/${visitCode}`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'DELETE',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newVisits = this.state.contact.visits.map((visit) => (visit.code === body.visit.code ? {
                ...body.visit,
            } : visit));

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    visits: newVisits,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            console.log('>>> Throwing error');
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    rescheduleVisit = async (visitCode, payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/visit/${visitCode}/reschedule`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newVisits = this.state.contact.visits.map((visit) => (visit.code === body.visit.code ? {
                ...body.visit,
            } : visit));

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    visits: newVisits,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            console.log('>>> Throwing error');
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    addCompany = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/debtCompany`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                debtCompanies: [
                    ...state.debtCompanies,
                    body,
                ],
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    saveContactDebtDetails = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/contact/${this.state.contactCode}/debt`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    debt: {
                        ...body.debt,
                    },
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    saveContactDetails = async (payload) => {
        if (this.validateContactDetails(payload) === false) throw new Error({ error: 'Generic API error' });
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/contact/${this.state.contactCode}`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    ...body,
                    history: undefined,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    updateContactDetails = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/contact/${this.state.contactCode}`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    ...body,
                    history: undefined,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    expireContactVulnerability = async (code) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/contactVulnerability/${code}`, {
                body: JSON.stringify({ expired: true }),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            const newVulnerabilities = this.state.contact.vulnerabilities.map((vulnerability) => {
                if (vulnerability.code === code) return body.vulnerability;
                return vulnerability;
            });
            const newVulnerabilityCodes = newVulnerabilities.map((newVulnerability) => newVulnerability.code);
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                    history: undefined,
                    vulnerabilities: newVulnerabilities,
                    vulnerabilityCodes: newVulnerabilityCodes,
                    vulnerable: false,
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    insertCreditReport = async (payload) => {
        let response;
        let body;
        // const formattedPayload = {
        //     ...JSON.stringify(payload),
        //     updateRecentAgreements: true,
        // };

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/contact/${this.state.contactCode}/creditReport`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    creditReport: {
                        score: body.creditReport.score,
                    },
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }

        const recentContactAgreementCodes = this.state.contact.agreements.reduce((acc, agreement) => {
            if (moment(currentDateTime).diff(agreement.createdDateTime, 'days') < 3) return [...acc, agreement.code];
            return acc;
        }, []);

        if (recentContactAgreementCodes.length > 0) {
            const updateAgreementsPayload = {
                agreementCodes: recentContactAgreementCodes,
                transUnionCreditScore: body.creditReport.score,
                transUnionCreditScoreReference: body.creditReport.reference,
                transUnionCreditScoreType: body.creditReport.type,
                transUnionCreditScoreDateTime: body.creditReport.date,
                transUnionCreditScoreCreatedByContactCode: body.creditReport.createdByContactCode,
            };
            const formattedPayload = JSON.stringify(updateAgreementsPayload);
            try {
                response = await fetch(`${REACT_APP_API_SERVER}v3/agreements/`, {
                    body: formattedPayload,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'PATCH',
                });
            } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
                console.log(e);
            }

            if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
                body = await response.json();
            }

            switch (response.status) {
            case 200: {
                const newAgreements = this.state.contact.agreements.map((agreement) => (body.agreements.find((responseAgreement) => responseAgreement.code === agreement.code) ? {
                    ...body.agreements.find((responseAgreement) => responseAgreement.code === agreement.code),
                } : agreement));

                this.setState((state) => ({
                    contact: {
                        ...state.contact,
                        agreements: newAgreements,
                        histories: [
                            ...body.histories,
                            ...state.contact.histories,
                        ],
                    },
                // contactAgreementsLoading: false,
                }));
                break;
            }
            default: {
                throw new Error({ error: 'Generic API error' });
            }
            }
        }
    };

    validateContactDetails = (payload) => {
        const fieldOrder = [
            'title',
            'firstname',
            'surname',
            'mobileTelephoneNumber',
            'emailAddress',
        ];

        const title = (() => {
            switch (true) {
            case !payload.title: return 'Title required';
            default: return '';
            }
        })();
        const firstname = (() => {
            switch (true) {
            case !payload.firstname: return 'Firstname required';
            case payload.firstname.length < 2: return 'Firstname too short';
            case payload.firstname.length > 100: return 'Firstname too long';
            default: return '';
            }
        })();
        const surname = (() => {
            switch (true) {
            case !payload.surname: return 'Surname required';
            case payload.surname.length < 2: return 'Surname too short';
            case payload.surname.length > 100: return 'Surname too long';
            default: return '';
            }
        })();
        const mobileTelephoneNumber = (() => {
            switch (true) {
            case !payload.mobileTelephoneNumber: return 'Mobile phone required';
            case payload.mobileTelephoneNumber.length < 2: return 'Mobile phone too short';
            case payload.mobileTelephoneNumber.length > 100: return 'Mobile phone too long';
            default: return '';
            }
        })();
        const emailAddress = (() => {
            switch (true) {
            case !payload.emailAddress: return 'Email address required';
            case payload.emailAddress.length < 2: return 'Email address too short';
            case payload.emailAddress.length > 100: return 'Email address too long';
            default: return '';
            }
        })();

        const errors = removeFalseyValuesFromObject({
            title,
            firstname,
            surname,
            mobileTelephoneNumber,
            emailAddress,
        });

        const firstError = fieldOrder.find((field) => !!errors[field]);

        this.setState({
            errors,
            firstError,
        });

        if (isEmpty(errors)) return true;

        return false;
    };

    syncDetails = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/contact/${this.state.contactCode}/syncPlanContact`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    saveContactSecurityQuestions = async (payload) => {
        if (this.validateCustomerSecurityQuestions(payload) === false) throw new Error({ error: 'Generic API error' });
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/contact/${this.state.contactCode}/securityQuestions`, {
                method: 'PATCH',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    securityQuestions: body.contactSecurityQuestions,
                    histories: [
                        ...body.histories,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    validateCustomerSecurityQuestions = (payload) => {
        const fieldOrder = [
            'mothersMaidenName',
            'favouritePet',
            'memorableWord',
        ];

        const mothersMaidenName = (() => {
            switch (true) {
            case !payload.mothersMaidenName: return 'Mothers Maiden Name required';
            case payload.mothersMaidenName.length < 2: return 'Mothers Maiden Name too short';
            default: return '';
            }
        })();
        const favouritePet = (() => {
            switch (true) {
            case !payload.favouritePet: return 'Favourite Pet required';
            case payload.favouritePet.length < 2: return 'Favourite Pet too short';
            default: return '';
            }
        })();
        const memorableWord = (() => {
            switch (true) {
            case !payload.memorableWord: return 'Memorable Word required';
            case payload.memorableWord.length < 2: return 'Memorable Word too short';
            default: return '';
            }
        })();
        const errors = removeFalseyValuesFromObject({
            mothersMaidenName,
            favouritePet,
            memorableWord,
        });

        const firstError = fieldOrder.find((field) => !!errors[field]);

        this.setState({
            errors,
            firstError,
        });

        if (isEmpty(errors)) return true;

        return false;
    };

    saveContactAddress = async (payload) => {
        if (this.validateContactAddress(payload) === false) throw new Error({ error: 'Generic API error' });
        let response;
        let body;

        if (!payload.newAddress) {
            console.log('>>> HERE!! SaveContactAddress');
            try {
                response = await fetch(`${REACT_APP_API_SERVER}v2/contact/${this.state.contact.code}/address`, {
                    body: JSON.stringify(payload),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'PATCH',
                });
            } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
                console.log(e);
            }
        } else {
            try {
                response = await fetch(`${REACT_APP_API_SERVER}v2/contact/${this.state.contact.code}/address`, {
                    body: JSON.stringify(payload),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                });
            } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
                console.log(e);
            }
        }
        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    address: {
                        ...body,
                        history: undefined,
                    },
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    validateContactAddress = (payload) => {
        const fieldOrder = [
            'address1',
            'townCity',
            'county',
            'postcode',
            'country',
        ];

        const address1 = (() => {
            switch (true) {
            case !payload.address1: return 'Address required';
            case payload.address1.length < 2: return 'Address too short';
            case payload.address1.length > 100: return 'Address too long';
            default: return '';
            }
        })();
        const townCity = (() => {
            switch (true) {
            case !payload.townCity: return 'Town/City required';
            case payload.townCity.length < 2: return 'Town/City too short';
            case payload.townCity.length > 100: return 'Town/City too long';
            default: return '';
            }
        })();
        const postcode = (() => {
            switch (true) {
            case !payload.postcode: return 'Postcode required';
            case payload.postcode.length < 2: return 'Postcode too short';
            case payload.postcode.length > 100: return 'Postcode too long';
            default: return '';
            }
        })();
        const county = (() => {
            switch (true) {
            case !payload.county: return 'County required';
            default: return '';
            }
        })();
        const country = (() => {
            switch (true) {
            case !payload.country: return 'Country required';
            default: return '';
            }
        })();

        const errors = removeFalseyValuesFromObject({
            address1,
            townCity,
            postcode,
            county,
            country,
        });

        const firstError = fieldOrder.find((field) => !!errors[field]);

        this.setState({
            errors,
            firstError,
        });

        if (isEmpty(errors)) return true;

        return false;
    };

    // validateEmail = (email) => {
    //     const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     return re.test(String(email).toLowerCase());
    // }

    // sendTokenisationLink = async (agreementCode, payload) => {
    //     if (this.validateSendTokenisationLink(payload) === false) throw new Error({ error: 'Generic API error' });
    //     let response;
    //     let body;

    //     try {
    //         response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/agreement/${agreementCode}/tokenisationRequest`, {
    //             method: 'POST',
    //             body: JSON.stringify(payload),
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         });
    //     } catch (e) {
    //         // something went really wrong; timeout/ blocked by client etc
    //         // debugger;
    //         console.log(e);
    //     }

    //     if (response.headers.get('content-type')
    //       && response.headers.get('content-type').search('application/json') >= 0) {
    //         body = await response.json();
    //     }

    //     switch (response.status) {
    //     case 200: {
    //         console.log(body);
    //         this.setState(state => ({
    //             histories: [
    //                 body,
    //                 ...state.histories,
    //             ],
    //         }));
    //         break;
    //     }
    //     default: {
    //         throw new Error({ error: 'Generic API error' });
    //     }
    //     }
    // }

    // sendControlDeviceCommand = async (controlDeviceCode, contactCode, command) => {
    //     let response;
    //     let body;
    //     const payload = {
    //         command,
    //         contactCode,
    //     };

    //     try {
    //         response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/control-device/${controlDeviceCode}/command`, {
    //             method: 'POST',
    //             body: JSON.stringify(payload),
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         });
    //     } catch (e) {
    //         // something went really wrong; timeout/ blocked by client etc
    //         // debugger;
    //         console.log(e);
    //     }

    //     if (response.headers.get('content-type')
    //       && response.headers.get('content-type').search('application/json') >= 0) {
    //         body = await response.json();
    //     }

    //     switch (response.status) {
    //     case 200: {
    //         console.log(body);
    //         this.setState(state => ({
    //             histories: [
    //                 body,
    //                 ...state.histories,
    //             ],
    //         }));
    //         break;
    //     }
    //     default: {
    //         throw new Error({ error: 'Generic API error' });
    //     }
    //     }
    // }

    // validateSendTokenisationLink = (payload) => {
    //     const fieldOrder = [
    //         'to',
    //     ];

    //     const to = (() => {
    //         switch (true) {
    //         case payload.messageType === 'sms'
    //                 && payload.destination === 'new'
    //                 && payload.to.length > 15:
    //         case payload.messageType === 'sms'
    //                 && payload.destination === 'new'
    //                 && payload.to.length < 9
    //             : return 'Invalid mobile number';
    //         case payload.messageType === 'email'
    //                 && payload.destination === 'new'
    //                 && !this.validateEmail(payload.to)
    //             : return 'Invalid email address';
    //         default: return '';
    //         }
    //     })();

    //     const errors = removeFalseyValuesFromObject({
    //         to,
    //     });

    //     const firstError = fieldOrder.find(field => !!errors[field]);

    //     this.setState({
    //         errors,
    //         firstError,
    //     });

    //     if (isEmpty(errors)) return true;

    //     return false;
    // }

    sendSMS = async (payload) => {
        let response;
        let body;
        const url = payload.sendAsAgent
            ? `${REACT_APP_API_SERVER}v4/lead/${payload.leadCode}/sms`
            : `${REACT_APP_API_SERVER}v2/contact/${this.state.contact.code}/sms`;

        try {
            response = await fetch(url, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newHistories = [body, ...this.state.contact.histories];
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: newHistories,
                },
            }));
            return;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    sendSelfMeasureInvite = async (leadCode) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/lead/${leadCode}/selfMeasureInvite`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newLeads = this.state.contact.leads.map((lead) => {
                if (lead.code === leadCode) return body.lead;
                return lead;
            });

            const newHistories = [...body.histories, ...this.state.contact.histories];
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: newHistories,
                    leads: newLeads,
                },
            }));
            return;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    leadToSlowTrack = async (leadCode) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v4/lead/${leadCode}/toSlowTrack`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newLeads = this.state.contact.leads.map((lead) => {
                if (lead.code === leadCode) return body.lead;
                return lead;
            });

            const newHistories = [body.history, ...this.state.contact.histories];
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: newHistories,
                    leads: newLeads,
                },
            }));
            return;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    sendLeadLink = async () => {
        let response;
        let body;

        const payload = {
            content: `Hi ${this.state.contact.firstname}, As discussed please use this link to register your enquiry. - DO NOT REPLY

https://pwkly.com/Customer/Apply`,
        };
        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/contact/${this.state.contact.code}/sms`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newHistories = [{ ...body }, ...this.state.contact.histories];
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: newHistories,
                },
            }));
            return;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    emailContract = async (agreementCode) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/agreement/${agreementCode}/contractTermsAndConditionsEmail`, {
                // body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 204: {
            // this.setState({
            //     histories: [
            //         body,
            //         ...this.state.histories,
            //     ],
            // });
            return;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    sendSMSAgentDetails = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/contact/${this.state.contact.code}/sms`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        console.log('>>> body: ', body);

        switch (response.status) {
        case 200: {
            const newHistories = [body, ...this.state.contact.histories];
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: newHistories,
                },
            }));
            return;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    sendEmail = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/contact/${this.state.contact.code}/email`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 204: {
            // this.setState({
            //     histories: [
            //         body,
            //         ...this.state.histories,
            //     ],
            // });
            return;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    sendDeclineAgreementEmail = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/contact/${this.state.contact.code}/declineAgreementEmail`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: [
                        body,
                        ...state.contact.histories,
                    ],
                },
            }));
            return;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    requestSignature = async (agreementCode, action) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/agreement/${agreementCode}/signatureRequest`, {
                body: JSON.stringify({ action }),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: [
                        body,
                        ...state.contact.histories,
                    ],
                },
            }));
            return;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    addNewHistoryNote = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/contact/${this.state.contactCode}/history`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        console.log(body);
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: [
                        body,
                        ...state.contact.histories,
                    ],
                },
            }));
            return Promise.resolve();
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    addContactVulnerability = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/contact/${this.state.contactCode}/vulnerability`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        console.log(body);
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                    history: undefined,
                    vulnerabilities: body.vulnerabilities,
                    vulnerabilityCodes: body.vulnerabilities.map((vulnerability) => vulnerability.code),
                    vulnerable: true,
                },
            }));
            return Promise.resolve();
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    addComplaint = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/contact/${this.state.contactCode}/complaint`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    complaints: [
                        ...state.contact.complaints,
                        body.complaint,
                    ],
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            return Promise.resolve();
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    scheduleCallBack = async (agreementCode, payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/agreement/${agreementCode}/callBack`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            return Promise.resolve();
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    contactTerminatedCall = async (agreementCode) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/agreement/${agreementCode}/callTerminated`, {
                method: 'POST',
                // body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            return Promise.resolve();
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    escalateCall = async (agreementCode, payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/agreement/${agreementCode}/escalateCall`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            return Promise.resolve();
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    editComplaint = async (complaintCode, payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/complaint/${complaintCode}`, {
                method: 'PATCH',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            const newComplaints = this.state.contact.complaints.map((complaint) => (complaint.code === body.complaint.code ? {
                ...body.complaint,
            } : complaint));

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    complaints: newComplaints,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            return Promise.resolve();
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    editLead = async (leadCode, payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v4/lead/${leadCode}`, {
                method: 'PATCH',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            const newLeads = this.state.contact.leads.map((lead) => (lead.code === body.lead.code ? {
                ...body.lead,
            } : lead));

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    leads: newLeads,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            return Promise.resolve();
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    // saveNewLead = async (payload) => {
    //     let response;
    //     let body;

    //     // Default internal sourceCode
    //     payload.leadSourceCode = 100;

    //     try {
    //         response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${this.state.contactCode}/lead`, {
    //             method: 'POST',
    //             body: JSON.stringify(payload),
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         });
    //     } catch (e) {
    //         // something went really wrong; timeout/ blocked by client etc
    //         // debugger;
    //         console.log(e);
    //     }

    //     if (response.headers.get('content-type')
    //       && response.headers.get('content-type').search('application/json') >= 0) {
    //         body = await response.json();
    //     }
    //     console.log(body);
    //     switch (response.status) {
    //     case 200: {
    //         this.setState({
    //             leads: [
    //                 body,
    //                 ...this.state.leads,
    //             ],
    //         });
    //         const note = `New lead created...\n${body.campaignTitle} - ${body.campaignDescription}`;
    //         await this.addNewHistoriesNote({ note });

    //         return Promise.resolve();
    //     }
    //     default: {
    //         throw new Error({ error: 'Generic API error' });
    //     }
    //     }
    // }

    // updateLead = async (payload) => {
    //     let response;
    //     let body;

    //     try {
    //         response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/lead/${payload.code}`, {
    //             method: 'PATCH',
    //             body: JSON.stringify(payload),
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         });
    //     } catch (e) {
    //         // something went really wrong; timeout/ blocked by client etc
    //         console.log(e);
    //     }

    //     if (response.headers.get('content-type')
    //       && response.headers.get('content-type').search('application/json') >= 0) {
    //         body = await response.json();
    //     }
    //     switch (response.status) {
    //     case 204: {
    //         const updatedLeads = [];
    //         this.state.leads.map((lead) => {
    //             if (lead.code === payload.code) {
    //                 updatedLeads.push({ ...lead, status: payload.status });
    //             } else {
    //                 updatedLeads.push({ ...lead });
    //             }
    //         });
    //         this.setState({
    //             leads: updatedLeads,
    //         });
    //         return Promise.resolve();
    //     }
    //     default: {
    //         throw new Error({ error: 'Generic API error' });
    //     }
    //     }
    // }

    // saveLeadUpdate = async (updateLead) => {
    //     const currentStatus = this.state.leads.find(currentLead => currentLead.code === updateLead.code).status;
    //     if (updateLead.status !== currentStatus) {
    //         // Update Lead Status
    //         await this.updateLead(updateLead);
    //         // Save Histories Note
    //         const updateLeadStatusNote = `Lead status changed from '${currentStatus}' to '${updateLead.status}'.`;
    //         await this.addNewHistoriesNote({ note: updateLeadStatusNote });
    //     }

    //     if ((updateLead.note || '').length > 0) {
    //         // Save histories note
    //         await this.addNewHistoriesNote({ note: updateLead.note });
    //     }
    // }

    // fetchConvertLeadProducts = async (rentalAgreementType) => {
    //     const url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/products');
    //     const params = {
    //         continuousRental: rentalAgreementType === 'Continuous' ? 1 : 0,
    //         repaymentRental: rentalAgreementType === 'Repayment' ? 1 : 0,
    //         sale: rentalAgreementType === 'Sale' ? 1 : 0,
    //     };
    //     Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    //     const data = await fetch(url).then(response => response.json());
    //     this.setState({ convertLeadProducts: data });
    // }

    // // fetchPaymentCards = async () => {
    // //     const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${this.state.contactCode}/cards`);
    // //     const data = await fetch(url).then(response => response.json());
    // //     this.setState({ paymentCards: data });
    // // }

    // fetchCampaigns = async () => {
    //     const url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/campaigns');
    //     const data = await fetch(url).then(response => response.json());
    //     this.setState({ campaigns: data });
    // }

    // convertLead = async (convertForm) => {
    //     // API - Create rentalAgreement record
    //     // API - Create rentalAgreement payment plan
    //     // API - Create adelante customer if required
    //     // API - Create adelante payment plan

    //     // Send deposit request sms if required
    //     // send deposit request email if required
    //     // Change lead status
    //     // Add histories note including agreement code

    //     convertForm.contactCode = this.state.customer.code;
    //     let response;
    //     let body;

    //     try {
    //         response = await fetch('https://api.portal2.payweeklycarpets.co.uk/core/new-rental-agreement', {
    //             method: 'POST',
    //             body: JSON.stringify(convertForm),
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         });
    //     } catch (e) {
    //         // something went really wrong; timeout/ blocked by client etc
    //         console.log(e);
    //     }

    //     if (response.headers.get('content-type')
    //       && response.headers.get('content-type').search('application/json') >= 0) {
    //         body = await response.json();
    //     }
    //     switch (response.status) {
    //     case 202: {
    //         await this.saveLeadUpdate({
    //             code: convertForm.code,
    //             status: 'Converted',
    //         });
    //         const updatedLeads = [];
    //         this.state.leads.map((lead) => {
    //             if (lead.code === convertForm.code) {
    //                 updatedLeads.push({ ...lead, status: 'Converted' });
    //             } else {
    //                 updatedLeads.push({ ...lead });
    //             }
    //         });
    //         this.setState({
    //             leads: updatedLeads,
    //         });
    //         return Promise.resolve(body.rentalAgreementCode);
    //     }
    //     default: {
    //         throw new Error({ error: 'Generic API error' });
    //     }
    //     }
    // }

    // manualPayment = async (payment) => {
    //     let response;
    //     let body;

    //     try {
    //         const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/agreement/${payment.agreementCode}/payment-plan-link`);
    //         const params = {
    //             amount: payment.amount,
    //             paymentExpiry: payment.paymentExpiry,
    //             paymentReturnURL: payment.paymentReturnURL,
    //         };
    //         Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    //         response = await fetch(url);
    //     } catch (e) {
    //         // something went really wrong; timeout/ blocked by client etc
    //         console.log(e);
    //     }

    //     if (response.headers.get('content-type')
    //       && response.headers.get('content-type').search('application/json') >= 0) {
    //         body = await response.json();
    //     }
    //     switch (response.status) {
    //     case 200: {
    //         console.log(body.link);
    //         window.location.href = body.link;
    //         // return Promise.resolve();
    //         break;
    //     }
    //     default: {
    //         throw new Error({ error: 'Generic API error' });
    //     }
    //     }
    // }

    // tokenManualPayment = async (payment) => {
    //     console.log(payment);
    //     let response;
    //     let body;

    //     try {
    //         const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/agreement/${payment.agreementCode}/token-manual-payment`);
    //         // const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/test`);
    //         const params = {
    //             paymentAmount: payment.paymentAmount,
    //             paymentCardToken: payment.paymentCardToken,
    //         };
    //         const options = {
    //             method: 'POST',
    //             body: JSON.stringify(params),
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         };

    //         response = await fetch(url, options);
    //     } catch (e) {
    //         // something went really wrong; timeout/ blocked by client etc
    //         console.log(e);
    //     }

    //     if (response.headers.get('content-type')
    //       && response.headers.get('content-type').search('application/json') >= 0) {
    //         body = await response.json();
    //     }
    //     switch (response.status) {
    //     case 200: {
    //         const note = `Manual payment attempt for £${parseFloat(payment.paymentAmount).toFixed(2)} using tokenised card ${payment.paymentCardNo} was "${body.transactionStatus}".`;
    //         await this.addNewHistoriesNote({ note });
    //         return Promise.resolve(body.transactionStatus);
    //     }
    //     default: {
    //         throw new Error({ error: 'Generic API error' });
    //     }
    //     }
    // }

    // printDeliveryLabels = async (agreementCode) => {
    //     let response;
    //     let body;

    //     try {
    //         const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/agreement/${agreementCode}/label`);
    //         // const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/test`);
    //         const params = {
    //             labelCode: 1000,
    //             printerCode: 1000,
    //         };
    //         const options = {
    //             method: 'POST',
    //             body: JSON.stringify(params),
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         };

    //         response = await fetch(url, options);
    //     } catch (e) {
    //         // something went really wrong; timeout/ blocked by client etc
    //         console.log(e);
    //     }

    //     if (response.headers.get('content-type')
    //       && response.headers.get('content-type').search('application/json') >= 0) {
    //         body = await response.json();
    //     }
    //     switch (response.status) {
    //     case 200: {
    //         this.setState(state => ({
    //             histories: [
    //                 body,
    //                 ...state.histories,
    //             ],
    //         }));
    //         return Promise.resolve(body.transactionStatus);
    //     }
    //     default: {
    //         throw new Error({ error: 'Generic API error' });
    //     }
    //     }
    // }

    // // AGREEMENT UPDATES
    // cancelAgreement = (agreementCode, params) => this.updateAgreement(agreementCode, params);

    // updateAgreement = async (agreementCode) => {
    //     let response;
    //     let body;

    //     try {
    //         const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/agreement/${agreementCode}`);
    //         const params = {
    //             agreementCode,
    //             status: 'CANCELLED',
    //         };
    //         const options = {
    //             method: 'PATCH',
    //             body: JSON.stringify(params),
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         };

    //         response = await fetch(url, options);
    //     } catch (e) {
    //         // something went really wrong; timeout/ blocked by client etc
    //         console.log(e);
    //     }

    //     if (response.headers.get('content-type')
    //       && response.headers.get('content-type').search('application/json') >= 0) {
    //         body = await response.json();
    //     }
    //     switch (response.status) {
    //     case 200: {
    //         // this.setState(state => ({
    //         //     customer: {
    //         //         ...state.customer,
    //         //         agreements: body.agreements,
    //         //     },
    //         //     histories: [
    //         //         body.histories,
    //         //         ...state.histories,
    //         //     ],
    //         // }));
    //         return;
    //     }
    //     default: {
    //         throw new Error({ error: 'Generic API error' });
    //     }
    //     }
    // }

    // // PAYMENT PLAN UPDATES
    // updatePaymentPlanStatus = (agreementCode, params) => this.updatePaymentPlan(agreementCode, params);

    // updatePaymentPlan = async (agreementCode, params) => {
    //     let response;
    //     let body;

    //     try {
    //         const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/paymentPlan/${agreementCode}`);
    //         const options = {
    //             method: 'PATCH',
    //             body: JSON.stringify(params),
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         };

    //         response = await fetch(url, options);
    //     } catch (e) {
    //         // something went really wrong; timeout/ blocked by client etc
    //         console.log(e);
    //     }

    //     if (response.headers.get('content-type')
    //       && response.headers.get('content-type').search('application/json') >= 0) {
    //         body = await response.json();
    //     }
    //     switch (response.status) {
    //     case 200: {
    //         this.setState(state => ({
    //             histories: [
    //                 body.histories,
    //                 ...state.histories,
    //             ],
    //         }));
    //         return;
    //     }
    //     default: {
    //         throw new Error({ error: 'Generic API error' });
    //     }
    //     }
    // }

    // handleOldCustomerPageClick = () => {
    //     this.props.histories.push(`/oldCustomer/${this.state.contactCode}`);
    // }

    // checkTransactionStatus = async (rentalAgreementCode, paymentID) => {
    //     this.setState({
    //         transactionStatusWaiting: true,
    //     });
    //     const url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/payment');
    //     const params = {
    //         rentalAgreementCode,
    //         paymentID,
    //     };
    //     Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    //     const data = await fetch(url).then(response => response.json());
    //     await sleep(1000);
    //     this.setState({
    //         transactionStatusWaiting: false,
    //         transactionStatus: data.transactionStatus,
    //     });
    // }

    // recordDelivery = async (agreementCode, payload) => {
    //     console.log('Record Delivery Payload', JSON.stringify(payload, 3));
    //     let response;
    //     let body;

    //     try {
    //         response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/agreement/${agreementCode}/delivery`, {
    //             method: 'POST',
    //             body: JSON.stringify(payload),
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         });
    //         console.log('Response:', response);
    //     } catch (e) {
    //         // something went really wrong; timeout/ blocked by client etc
    //         // debugger;
    //         console.log(e);
    //     }

    //     if (response.headers.get('content-type')
    //       && response.headers.get('content-type').search('application/json') >= 0) {
    //         body = await response.json();
    //     }

    //     switch (response.status) {
    //     case 200: {
    //         this.setState(state => ({
    //             histories: [
    //                 body,
    //                 ...state.histories,
    //             ],
    //         }));
    //         break;
    //     }
    //     default: {
    //         throw new Error({ error: 'Generic API error' });
    //     }
    //     }
    // }

    scheduleDelivery = async (agreementCode, payload) => {
        const formattedPayload = JSON.stringify({
            ...payload,
            contactCode: this.state.contact.code,
        });
        console.log('>>> Schedule Delivery Payload', JSON.stringify(payload, 3));
        let response;
        // let body;

        try {
            response = await fetchJson2('v2/visit/', {
                body: formattedPayload,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    visits: [
                        response.payload.visit,
                        ...state.contact.visits,
                    ],
                    histories: [
                        response.payload.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    sendDeliveryInvite = async (agreementCode, payload) => {
        const formattedPayload = JSON.stringify({
            ...payload,
            contactCode: this.state.contact.code,
        });
        let response;
        // let body;

        try {
            response = await fetchJson2('v2/visit/invite', {
                body: formattedPayload,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        console.log('>>> response:', response);
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    visits: [
                        response.payload.visit,
                        ...state.contact.visits,
                    ],
                    histories: [
                        response.payload.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    sendRescheduleVisitInvite = async (visitCode, payload) => {
        const formattedPayload = JSON.stringify({
            ...payload,
            contactCode: this.state.contact.code,
        });
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/visit/${visitCode}/rescheduleInvite`, {
                body: formattedPayload,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        console.log('>>> response:', response);
        switch (response.status) {
        case 200: {
            const newVisits = this.state.contact.visits.map((visit) => (visit.code === body.visit.code ? {
                ...body.visit,
            } : visit));

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    visits: newVisits,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        // case 200: {
        //     this.setState(state => ({
        //         contact: {
        //             ...state.contact,
        //             visits: [
        //                 response.payload.visit,
        //                 ...state.contact.visits,
        //             ],
        //             histories: [
        //                 response.payload.history,
        //                 ...state.contact.histories,
        //             ],
        //         },
        //     }));
        //     break;
        // }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    buildSendContract = async (agreementCode, payload) => {
        const formattedPayload = JSON.stringify({
            ...payload,
        });
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/agreement/${agreementCode}/buildSendContract`, {
            // response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/v2/agreement/${agreementCode}/buildSendContract`, {
                body: formattedPayload,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        console.log('>>> response:', response);
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: [
                        body,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    emailStatement = async (agreementCode, payload) => {
        const formattedPayload = JSON.stringify({
            ...payload,
        });
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/agreement/${agreementCode}/statement`, {
                body: formattedPayload,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        console.log('>>> response:', response);
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: [
                        body,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    authoriseLead = async (leadCode, payload) => {
        const formattedPayload = JSON.stringify({
            ...payload,
        });
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/lead/${leadCode}/authorise`, {
                body: formattedPayload,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        const newLeads = this.state.contact.leads.map((lead) => {
            if (lead.code === leadCode) return body.lead;
            return lead;
        });

        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                    leads: newLeads,
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    declineLead = async (leadCode, payload) => {
        const formattedPayload = JSON.stringify({
            ...payload,
        });
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/lead/${leadCode}/decline`, {
                body: formattedPayload,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        const newLeads = this.state.contact.leads.map((lead) => {
            if (lead.code === leadCode) return body.lead;
            return lead;
        });

        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                    leads: newLeads,
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    logCall = async (agreementCode, status) => {
        let response;
        let body;

        const payload = {
            referenceCode: agreementCode,
            referenceType: 'AGREEMENT',
            status,
        };

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/contact/${this.state.contactCode}/call`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        console.log(body);
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            return Promise.resolve();
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    assignStockItem = async (agreementCode, agreementItemCode, stockItemCode) => {
        const formattedPayload = JSON.stringify({
            itemCode: stockItemCode,
        });
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/agreementItem/${agreementItemCode}/assignItem`, {
                body: formattedPayload,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newAgreements = this.state.contact.agreements.map((agreement) => {
                if (agreement.code === parseInt(agreementCode, 10)) {
                    const newAgreementItems = agreement.agreementItems.reduce((acc, agreementItem) => {
                        if (agreementItem.code === body.agreementItem.code) {
                            return [...acc, body.agreementItem];
                        }
                        return [...acc, agreementItem];
                    }, []);
                    return {
                        ...agreement,
                        agreementItems: newAgreementItems,
                    };
                }
                return agreement;
            });
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    agreements: newAgreements,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
                contactAgreementsLoading: false,
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    editAgreement = async (agreementCode, params) => {
        const formattedPayload = JSON.stringify({
            ...params,
        });
        let response;
        let body;

        this.setState({
            contactAgreementsLoading: true,
        });

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v3/agreement/${agreementCode}`, {
                body: formattedPayload,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newAgreements = this.state.contact.agreements.map((agreement) => (agreement.code === body.agreement.code ? {
            // const newAgreements = this.state.contact.agreements.map(agreement => (agreement.code === body.code ? {
                ...body.agreement,
                // ...body,
            } : agreement));

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    agreements: newAgreements,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
                contactAgreementsLoading: false,
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    editPaymentPlan = async (agreementCode, params) => {
        if (this.validateEditPaymentPlan(params) === false) throw new Error({ error: 'Generic API error' });
        const formattedPayload = JSON.stringify({
            ...params,
        });
        let response;
        let body;

        this.setState({
            contactAgreementsLoading: true,
        });

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v3/agreement/${agreementCode}/paymentPlan`, {
                body: formattedPayload,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newAgreements = this.state.contact.agreements.map((agreement) => (agreement.code === body.agreement.code ? {
            // const newAgreements = this.state.contact.agreements.map(agreement => (agreement.code === body.code ? {
                ...body.agreement,
                // ...body,
            } : agreement));

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    agreements: newAgreements,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
                contactAgreementsLoading: false,
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    validateEditPaymentPlan = (payload) => {
        const fieldOrder = [
            'repaymentAmount',
            'recurringAmount',
            'reason',
        ];

        const repaymentAmount = (() => {
            switch (true) {
            case payload.repaymentAmount <= 0: return 'Invalid Repayment Amount';
            default: return '';
            }
        })();
        const recurringPaymentAmount = (() => {
            switch (true) {
            case payload.recurringPaymentAmount <= 0: return 'Invalid Repayment Amount';
            default: return '';
            }
        })();
        const reason = (() => {
            switch (true) {
            case (!payload.reason && payload.type !== 'STANDING_ORDER'): return 'Reason required';
            default: return '';
            }
        })();
        const errors = removeFalseyValuesFromObject({
            reason,
            recurringPaymentAmount,
            repaymentAmount,
        });

        const firstError = fieldOrder.find((field) => !!errors[field]);

        this.setState({
            errors,
            firstError,
        });

        if (isEmpty(errors)) return true;

        return false;
    };

    manualAdjustment = async (agreementCode, params) => {
        const amount = params.adjustmentType === 'CREDIT' ? params.amount : -Math.abs(params.amount);
        const formattedPayload = JSON.stringify({
            ...params,
            amount,
        });
        let response;
        let body;

        this.setState({
            contactAgreementsLoading: true,
        });

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/agreement/${agreementCode}/manualAdjustment`, {
                body: formattedPayload,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newAgreements = this.state.contact.agreements.map((agreement) => (agreement.code === body.agreement.code ? {
            // const newAgreements = this.state.contact.agreements.map(agreement => (agreement.code === body.code ? {
                ...body.agreement,
                // ...body,
            } : agreement));

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    agreements: newAgreements,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
                contactAgreementsLoading: false,
            }));
            return body.result;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    standingOrderPayment = async (agreementCode, params) => {
        const formattedPayload = JSON.stringify({
            ...params,
        });
        let response;
        let body;

        this.setState({
            contactAgreementsLoading: true,
        });

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/agreement/${agreementCode}/standingOrderPayment`, {
                body: formattedPayload,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newAgreements = this.state.contact.agreements.map((agreement) => (agreement.code === body.agreement.code ? {
            // const newAgreements = this.state.contact.agreements.map(agreement => (agreement.code === body.code ? {
                ...body.agreement,
                // ...body,
            } : agreement));

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    agreements: newAgreements,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
                contactAgreementsLoading: false,
            }));
            return body.result;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    manualPayment = async (agreementCode, params) => {
        if (this.validateManualPayment(params) === false) throw new Error({ error: 'Generic API error' });
        const formattedPayload = JSON.stringify({
            ...params,
        });
        let response;
        let body;

        this.setState({
            contactAgreementsLoading: true,
        });

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/agreement/${agreementCode}/manualPayment`, {
                body: formattedPayload,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newAgreements = this.state.contact.agreements.map((agreement) => (agreement.code === body.agreement.code ? {
            // const newAgreements = this.state.contact.agreements.map(agreement => (agreement.code === body.code ? {
                ...body.agreement,
                // ...body,
            } : agreement));

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    agreements: newAgreements,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
                contactAgreementsLoading: false,
            }));
            return body.result;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    autoPhonePaymentSms = async (agreementCode) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/agreement/${agreementCode}/autoPhonePaymentSms`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            return body.result;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    validateManualPayment = (payload) => {
        const fieldOrder = [
            'repaymentAmount',
            'recurringAmount',
            'reason',
        ];

        const repaymentAmount = (() => {
            switch (true) {
            case payload.repaymentAmount <= 0: return 'Invalid Repayment Amount';
            default: return '';
            }
        })();
        const recurringPaymentAmount = (() => {
            switch (true) {
            case payload.recurringPaymentAmount <= 0: return 'Invalid Repayment Amount';
            default: return '';
            }
        })();
        const reason = (() => {
            switch (true) {
            case !payload.reason: return 'Reason required';
            default: return '';
            }
        })();
        const errors = removeFalseyValuesFromObject({
            reason,
            recurringPaymentAmount,
            repaymentAmount,
        });

        const firstError = fieldOrder.find((field) => !!errors[field]);

        this.setState({
            errors,
            firstError,
        });

        if (isEmpty(errors)) return true;

        return false;
    };

    paymentLink = async (agreementCode, params) => {
        if (this.validatePaymentLink(params) === false) throw new Error({ error: 'Generic API error' });
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v3/agreement/${agreementCode}/planPaymentUrl/${params.action}?amount=${params.amount}&reason=${params.reason}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'GET',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    validatePaymentLink = (payload) => {
        const fieldOrder = [
            'repaymentAmount',
            'recurringAmount',
            'reason',
        ];

        const repaymentAmount = (() => {
            switch (true) {
            case payload.repaymentAmount <= 0: return 'Invalid Repayment Amount';
            default: return '';
            }
        })();
        const recurringPaymentAmount = (() => {
            switch (true) {
            case payload.recurringPaymentAmount <= 0: return 'Invalid Repayment Amount';
            default: return '';
            }
        })();
        const reason = (() => {
            switch (true) {
            case !payload.reason: return 'Reason required';
            default: return '';
            }
        })();
        const errors = removeFalseyValuesFromObject({
            reason,
            recurringPaymentAmount,
            repaymentAmount,
        });

        const firstError = fieldOrder.find((field) => !!errors[field]);

        this.setState({
            errors,
            firstError,
        });

        if (isEmpty(errors)) return true;

        return false;
    };

    refundPaymentPlan = async (agreementCode, params) => {
        const formattedPayload = JSON.stringify({
            ...params,
        });
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/agreement/${agreementCode}/refundPaymentPlan`, {
                body: formattedPayload,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    refundPayments = async (agreementCode, params) => {
        if (this.validateRefundPayments(params) === false) throw new Error({ error: 'Generic API error' });
        const formattedPayload = JSON.stringify({
            ...params,
            agreementCode,
            contactCode: this.state.contact.code,
        });
        let response;
        let body;
        try {
            response = await fetch(`${REACT_APP_API_SERVER}v3/payments/refund`, {
                body: formattedPayload,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    validateRefundPayments = (payload) => {
        const fieldOrder = [
            'repaymentAmount',
            'recurringAmount',
            'reason',
        ];

        const repaymentAmount = (() => {
            switch (true) {
            case payload.repaymentAmount <= 0: return 'Invalid Repayment Amount';
            default: return '';
            }
        })();
        const recurringPaymentAmount = (() => {
            switch (true) {
            case payload.recurringPaymentAmount <= 0: return 'Invalid Repayment Amount';
            default: return '';
            }
        })();
        const reason = (() => {
            switch (true) {
            case !payload.reason: return 'Reason required';
            default: return '';
            }
        })();
        const errors = removeFalseyValuesFromObject({
            reason,
            recurringPaymentAmount,
            repaymentAmount,
        });

        const firstError = fieldOrder.find((field) => !!errors[field]);

        this.setState({
            errors,
            firstError,
        });

        if (isEmpty(errors)) return true;

        return false;
    };

    reversePayment = async (agreementCode, params) => {
        if (this.validateManualPayment(params) === false) throw new Error({ error: 'Generic API error' });
        const {
            paymentID,
        } = params;
        const formattedPayload = JSON.stringify({
            ...params,
        });
        let response;
        let body;

        this.setState({
            contactAgreementsLoading: true,
        });

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v3/payment/${paymentID}/reverse`, {
                body: formattedPayload,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            this.setState({
                contactAgreementsLoading: false,
            });
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newAgreements = this.state.contact.agreements.map((agreement) => (agreement.code === body.agreement.code ? {
            // const newAgreements = this.state.contact.agreements.map(agreement => (agreement.code === body.code ? {
                ...body.agreement,
                // ...body,
            } : agreement));

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    agreements: newAgreements,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
                contactAgreementsLoading: false,
            }));
            return body.result;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    deleteAgentPostcodeDistrict = async (postcodeDistrict) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/agent/${this.state.contact.code}/postcodeDistrict/${postcodeDistrict}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'DELETE',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newPostcodeDistricts = this.state.contact.contactAgent.postcodeDistricts.filter((oldPostcodeDistrict) => (oldPostcodeDistrict !== postcodeDistrict));

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    contactAgent: {
                        ...state.contact.contactAgent,
                        postcodeDistricts: newPostcodeDistricts,
                    },
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    addAgentPostcodeDistrict = async (postcodeDistrict) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/agent/${this.state.contact.code}/postcodeDistrict/${postcodeDistrict}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newPostcodeDistricts = [
                ...this.state.contact.contactAgent.postcodeDistricts,
                postcodeDistrict,
            ].sort();

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    contactAgent: {
                        ...state.contact.contactAgent,
                        postcodeDistricts: newPostcodeDistricts,
                    },
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    scheduleRetryPayment = async (agreementCode, params) => {
        if (this.validateRetryPayment(params) === false) throw new Error({ error: 'Generic API error' });
        const formattedPayload = JSON.stringify({
            ...params,
        });
        let response;
        let body;

        this.setState({
            contactAgreementsLoading: true,
        });

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v3/agreement/${agreementCode}/paymentPlan`, {
                body: formattedPayload,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newAgreements = this.state.contact.agreements.map((agreement) => (agreement.code === body.agreement.code ? {
            // const newAgreements = this.state.contact.agreements.map(agreement => (agreement.code === body.code ? {
                ...body.agreement,
                // ...body,
            } : agreement));

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    agreements: newAgreements,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
                contactAgreementsLoading: false,
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    validateRetryPayment = (payload) => {
        const fieldOrder = [
            'retryPaymentAmount',
            'reason',
        ];

        const retryPaymentAmount = (() => {
            switch (true) {
            case payload.retryPaymentAmount <= 0: return 'Invalid Retry Payment Amount';
            default: return '';
            }
        })();
        const reason = (() => {
            switch (true) {
            case !payload.reason: return 'Reason required';
            default: return '';
            }
        })();
        const errors = removeFalseyValuesFromObject({
            reason,
            retryPaymentAmount,
        });

        const firstError = fieldOrder.find((field) => !!errors[field]);

        this.setState({
            errors,
            firstError,
        });

        if (isEmpty(errors)) return true;

        return false;
    };

    scheduleInterimPayment = async (agreementCode, params) => {
        if (this.validateInterimPayment(params) === false) throw new Error({ error: 'Generic API error' });
        const formattedPayload = JSON.stringify({
            ...params,
        });
        let response;
        let body;

        this.setState({
            contactAgreementsLoading: true,
        });

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v3/agreement/${agreementCode}/paymentPlan`, {
                body: formattedPayload,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newAgreements = this.state.contact.agreements.map((agreement) => (agreement.code === body.agreement.code ? {
            // const newAgreements = this.state.contact.agreements.map(agreement => (agreement.code === body.code ? {
                ...body.agreement,
                // ...body,
            } : agreement));

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    agreements: newAgreements,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
                contactAgreementsLoading: false,
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    addLeadNote = async (leadCode, params) => {
        const formattedPayload = JSON.stringify({
            ...params,
        });
        let response;
        let body;

        // this.setState({
        //     contactAgreementsLoading: true,
        // });

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/lead/${leadCode}/note`, {
                body: formattedPayload,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newLeads = this.state.contact.leads.map((lead) => (lead.code === body.lead.code ? {
                ...body.lead,
            } : lead));

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    leads: newLeads,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
                // contactAgreementsLoading: false,
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    validateInterimPayment = (payload) => {
        const fieldOrder = [
            'reason',
        ];
        const reason = (() => {
            switch (true) {
            case !payload.reason: return 'Reason required';
            default: return '';
            }
        })();
        const errors = removeFalseyValuesFromObject({
            reason,
        });

        const firstError = fieldOrder.find((field) => !!errors[field]);

        this.setState({
            errors,
            firstError,
        });

        if (isEmpty(errors)) return true;

        return false;
    };

    // allowAgreementToggle = async () => {
    //     const allowAgreement = this.state.contact.allowAgreement === true ? false : true;
    //     const formattedPayload = JSON.stringify({
    //         allowAgreement,
    //     });
    //     let response;
    //     let body;

    //     this.setState({
    //         contactAgreementsLoading: true,
    //     });

    //     try {
    //         response = await fetch(`${REACT_APP_API_SERVER}v2/contact/${this.state.contact.code}`, {
    //             body: formattedPayload,
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             method: 'PATCH',
    //         });
    //     } catch (e) {
    //         // something went really wrong; timeout/ blocked by client etc
    //         // debugger;
    //         console.log(e);
    //     }

    //     if (response.headers.get('content-type')
    //     && response.headers.get('content-type').search('application/json') >= 0) {
    //         body = await response.json();
    //     }

    //     switch (response.status) {
    //     case 200: {
    //         const newAgreements = this.state.contact.agreements.map((agreement) => (agreement.code === body.agreement.code ? {
    //         // const newAgreements = this.state.contact.agreements.map(agreement => (agreement.code === body.code ? {
    //             ...body.agreement,
    //             // ...body,
    //         } : agreement));

    //         this.setState((state) => ({
    //             contact: {
    //                 ...state.contact,
    //                 agreements: newAgreements,
    //                 histories: [
    //                     body.history,
    //                     ...state.contact.histories,
    //                 ],
    //             },
    //             contactAgreementsLoading: false,
    //         }));
    //         break;
    //     }
    //     default: {
    //         throw new Error({ error: 'Generic API error' });
    //     }
    //     }
    // };

    // allowAgreementToggle = async () => {
    //     this.setState(() => ({
    //         disableAllowAgreementSwitch: true,
    //     }));
    //     const command = this.props.contact.allowAgreement === true ? false : true;
    //     const response = await fetch('http://api.portal2.payweeklycarpets.localhost:3001/core/contact', {
    //         method: 'PATCH',
    //         body: JSON.stringify({
    //             controlDeviceCode: this.props.contact.code,
    //             command: command,
    //         }),
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //     }).then(res => res);// .json());
    //     this.setState(() => ({
    //         controlDeviceStatus: 'SYNCHING',
    //     }));
    //     setTimeout(() => this.checkControlDeviceState(), 8000);
    // }

    handleOldCustomerPageClick = () => {
        this.props.history.push(`/customer/${this.state.contact.code}`);
    };

    deleteAgreementItem = async (agreementCode, agreementItemCode, params) => {
        console.log('>>> params:', params);
        let response;
        let body;
        const {
            unassignItem,
            removeFromVisit,
            visitCode,
            cancelVisit,
            visitItemCode,
        } = params;

        if (unassignItem) {
            try {
                response = await fetch(`${REACT_APP_API_SERVER}v2/agreementItem/${agreementItemCode}/unassignItem`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'PATCH',
                });
            } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
                console.log(e);
            }
            if (response.headers.get('content-type')
            && response.headers.get('content-type').search('application/json') >= 0) {
                body = await response.json();
            }

            switch (response.status) {
            case 200: {
                const currentAgreement = this.state.contact.agreements.find((agreement) => agreement.code === agreementCode);
                const newAgreementItems = currentAgreement.agreementItems.map((agreementItem) => (agreementItem.code === agreementItemCode ? {
                    ...body.agreementItem,
                } : agreementItem));
                const newAgreement = {
                    ...currentAgreement,
                    agreementItems: newAgreementItems,
                };
                const newAgreements = this.state.contact.agreements.map((agreement) => (agreement.code === agreementCode
                    ? {
                        ...newAgreement,
                    }
                    : agreement));

                console.log('>>> contact:', {
                    contact: {
                        ...this.state.contact,
                        agreements: newAgreements,
                        histories: [
                            body.history,
                            ...this.state.contact.histories,
                        ],
                    },
                });

                this.setState((state) => ({
                    contact: {
                        ...state.contact,
                        agreements: newAgreements,
                        histories: [
                            body.history,
                            ...state.contact.histories,
                        ],
                    },
                }));
                break;
            }
            default: {
                console.log('>>> Throwing error');
                throw new Error({ error: 'Generic API error' });
            }
            }
        }

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v4/agreementItem/${agreementItemCode}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'DELETE',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }
        if (response.headers.get('content-type')
            && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const currentAgreement = this.state.contact.agreements.find((agreement) => agreement.code === agreementCode);
            const newAgreementItems = currentAgreement.agreementItems.filter((agreementItem) => (agreementItem.code !== agreementItemCode));
            const newAgreement = {
                ...currentAgreement,
                agreementItems: newAgreementItems,
            };
            const newAgreements = this.state.contact.agreements.map((agreement) => (agreement.code === agreementCode
                ? {
                    ...newAgreement,
                }
                : agreement));

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    agreements: newAgreements,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            console.log('>>> Throwing error');
            throw new Error({ error: 'Generic API error' });
        }
        }

        if (removeFromVisit) {
            try {
                response = await fetch(`${REACT_APP_API_SERVER}v2/visitItem/${visitItemCode}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'DELETE',
                });
            } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
                console.log(e);
            }
            if (response.headers.get('content-type')
            && response.headers.get('content-type').search('application/json') >= 0) {
                body = await response.json();
            }

            switch (response.status) {
            case 200: {
                const newVisits = this.state.contact.visits.map((visit) => (visit.code === body.visit.code ? {
                    ...body.visit,
                } : visit));

                this.setState((state) => ({
                    contact: {
                        ...state.contact,
                        visits: newVisits,
                        histories: [
                            body.history,
                            ...state.contact.histories,
                        ],
                    },
                }));
                break;
            }
            default: {
                console.log('>>> Throwing error');
                throw new Error({ error: 'Generic API error' });
            }
            }
        }

        if (cancelVisit) {
            try {
                response = await fetch(`${REACT_APP_API_SERVER}v2/visit/${visitCode}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'DELETE',
                });
            } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
                console.log(e);
            }
            if (response.headers.get('content-type')
            && response.headers.get('content-type').search('application/json') >= 0) {
                body = await response.json();
            }

            switch (response.status) {
            case 200: {
                const newVisits = this.state.contact.visits.map((visit) => (visit.code === body.visit.code ? {
                    ...body.visit,
                } : visit));

                console.log('>>> contact (visit):', {
                    contact: {
                        ...this.state.contact,
                        visits: newVisits,
                        histories: [
                            body.history,
                            ...this.state.contact.histories,
                        ],
                    },
                });

                this.setState((state) => ({
                    contact: {
                        ...state.contact,
                        visits: newVisits,
                        histories: [
                            body.history,
                            ...state.contact.histories,
                        ],
                    },
                }));
                break;
            }
            default: {
                console.log('>>> Throwing error');
                throw new Error({ error: 'Generic API error' });
            }
            }
        }
    };

    unassignAgreementItem = async (agreementCode, agreementItemCode, params) => {
        console.log('>>> params:', params);
        let response;
        let body;
        const {
            unassignItem,
        } = params;

        if (unassignItem) {
            try {
                response = await fetch(`${REACT_APP_API_SERVER}v2/agreementItem/${agreementItemCode}/unassignItem`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'PATCH',
                });
            } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
                console.log(e);
            }
            if (response.headers.get('content-type')
            && response.headers.get('content-type').search('application/json') >= 0) {
                body = await response.json();
            }

            switch (response.status) {
            case 200: {
                const currentAgreement = this.state.contact.agreements.find((agreement) => agreement.code === agreementCode);
                const newAgreementItems = currentAgreement.agreementItems.map((agreementItem) => (agreementItem.code === agreementItemCode ? {
                    ...body.agreementItem,
                } : agreementItem));
                const newAgreement = {
                    ...currentAgreement,
                    agreementItems: newAgreementItems,
                };
                const newAgreements = this.state.contact.agreements.map((agreement) => (agreement.code === agreementCode
                    ? {
                        ...newAgreement,
                    }
                    : agreement));

                console.log('>>> contact:', {
                    contact: {
                        ...this.state.contact,
                        agreements: newAgreements,
                        histories: [
                            body.history,
                            ...this.state.contact.histories,
                        ],
                    },
                });

                this.setState((state) => ({
                    contact: {
                        ...state.contact,
                        agreements: newAgreements,
                        histories: [
                            body.history,
                            ...state.contact.histories,
                        ],
                    },
                }));
                break;
            }
            default: {
                console.log('>>> Throwing error');
                throw new Error({ error: 'Generic API error' });
            }
            }
        }
    };

    addAgreementItem = async (agreementCode, payload) => {
        console.log('>>> payload:', payload);
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v4/agreement/${agreementCode}/agreementItem`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }
        if (response.headers.get('content-type')
            && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const currentAgreement = this.state.contact.agreements.find((agreement) => agreement.code === agreementCode);
            console.log('>>> currentAgreement:', currentAgreement);
            const newAgreement = {
                ...currentAgreement,
                agreementItems: [
                    ...currentAgreement.agreementItems,
                    body.agreementItem,
                ],
            };
            console.log('>>> newAgreement:', newAgreement);
            const newAgreements = this.state.contact.agreements.map((agreement) => (agreement.code === agreementCode
                ? {
                    ...newAgreement,
                }
                : agreement));

            console.log('>>> newAgreements:', newAgreements);

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    agreements: newAgreements,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            console.log('>>> Throwing error');
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    exchangeAgreementItem = async (
        agreementCode,
        agreementItemCode,
        params,
        payload,
    ) => {
        console.log('>>> exchangeAgreementItem variables:', {
            agreementCode,
            agreementItemCode,
            params,
            payload,
        });

        // REMOVE OLD ITEM

        const {
            unassignItem,
            removeFromVisit,
            visitCode,
            cancelVisit,
            visitItemCode,
            exchangeVisit,
        } = params;

        let response;
        let body;
        if (unassignItem) {
            try {
                response = await fetch(`${REACT_APP_API_SERVER}v2/agreementItem/${agreementItemCode}/unassignItem`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'PATCH',
                });
            } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
                console.log(e);
            }
            if (response.headers.get('content-type')
            && response.headers.get('content-type').search('application/json') >= 0) {
                body = await response.json();
            }

            switch (response.status) {
            case 200: {
                const currentAgreement = this.state.contact.agreements.find((agreement) => agreement.code === agreementCode);
                const newAgreementItems = currentAgreement.agreementItems.map((agreementItem) => (agreementItem.code === agreementItemCode ? {
                    ...body.agreementItem,
                } : agreementItem));
                const newAgreement = {
                    ...currentAgreement,
                    agreementItems: newAgreementItems,
                };
                const newAgreements = this.state.contact.agreements.map((agreement) => (agreement.code === agreementCode
                    ? {
                        ...newAgreement,
                    }
                    : agreement));

                console.log('>>> contact:', {
                    contact: {
                        ...this.state.contact,
                        agreements: newAgreements,
                        histories: [
                            body.history,
                            ...this.state.contact.histories,
                        ],
                    },
                });

                this.setState((state) => ({
                    contact: {
                        ...state.contact,
                        agreements: newAgreements,
                        histories: [
                            body.history,
                            ...state.contact.histories,
                        ],
                    },
                }));
                break;
            }
            default: {
                console.log('>>> Throwing error');
                throw new Error({ error: 'Generic API error' });
            }
            }
        }

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v4/agreementItem/${agreementItemCode}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'DELETE',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }
        if (response.headers.get('content-type')
            && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const currentAgreement = this.state.contact.agreements.find((agreement) => agreement.code === agreementCode);
            const newAgreementItems = currentAgreement.agreementItems.filter((agreementItem) => (agreementItem.code !== agreementItemCode));
            const newAgreement = {
                ...currentAgreement,
                agreementItems: newAgreementItems,
            };
            const newAgreements = this.state.contact.agreements.map((agreement) => (agreement.code === agreementCode
                ? {
                    ...newAgreement,
                }
                : agreement));

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    agreements: newAgreements,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            console.log('>>> Throwing error');
            throw new Error({ error: 'Generic API error' });
        }
        }

        if (removeFromVisit) {
            try {
                response = await fetch(`${REACT_APP_API_SERVER}v2/visitItem/${visitItemCode}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'DELETE',
                });
            } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
                console.log(e);
            }
            if (response.headers.get('content-type')
            && response.headers.get('content-type').search('application/json') >= 0) {
                body = await response.json();
            }

            switch (response.status) {
            case 200: {
                const newVisits = this.state.contact.visits.map((visit) => (visit.code === body.visit.code ? {
                    ...body.visit,
                } : visit));

                this.setState((state) => ({
                    contact: {
                        ...state.contact,
                        visits: newVisits,
                        histories: [
                            body.history,
                            ...state.contact.histories,
                        ],
                    },
                }));
                break;
            }
            default: {
                console.log('>>> Throwing error');
                throw new Error({ error: 'Generic API error' });
            }
            }
        }

        if (cancelVisit) {
            try {
                response = await fetch(`${REACT_APP_API_SERVER}v2/visit/${visitCode}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'DELETE',
                });
            } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
                console.log(e);
            }
            if (response.headers.get('content-type')
            && response.headers.get('content-type').search('application/json') >= 0) {
                body = await response.json();
            }

            switch (response.status) {
            case 200: {
                const newVisits = this.state.contact.visits.map((visit) => (visit.code === body.visit.code ? {
                    ...body.visit,
                } : visit));

                console.log('>>> contact (visit):', {
                    contact: {
                        ...this.state.contact,
                        visits: newVisits,
                        histories: [
                            body.history,
                            ...this.state.contact.histories,
                        ],
                    },
                });

                this.setState((state) => ({
                    contact: {
                        ...state.contact,
                        visits: newVisits,
                        histories: [
                            body.history,
                            ...state.contact.histories,
                        ],
                    },
                }));
                break;
            }
            default: {
                console.log('>>> Throwing error');
                throw new Error({ error: 'Generic API error' });
            }
            }
        }

        // ADD NEW ITEM

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v4/agreement/${agreementCode}/agreementItem`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }
        if (response.headers.get('content-type')
            && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        const newAgreementItem = body.agreementItem;

        switch (response.status) {
        case 200: {
            const currentAgreement = this.state.contact.agreements.find((agreement) => agreement.code === agreementCode);
            console.log('>>> currentAgreement:', currentAgreement);
            const newAgreement = {
                ...currentAgreement,
                agreementItems: [
                    ...currentAgreement.agreementItems,
                    newAgreementItem,
                ],
            };
            console.log('>>> newAgreement:', newAgreement);
            const newAgreements = this.state.contact.agreements.map((agreement) => (agreement.code === agreementCode
                ? {
                    ...newAgreement,
                }
                : agreement));

            console.log('>>> newAgreements:', newAgreements);

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    agreements: newAgreements,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            console.log('>>> Throwing error');
            throw new Error({ error: 'Generic API error' });
        }
        }

        if (exchangeVisit) {
            try {
                response = await fetch(`${REACT_APP_API_SERVER}v2/visit/${visitCode}/visitItem`, {
                    body: JSON.stringify({
                        action: 'DELIVER',
                        rentalAgreementItemCode: newAgreementItem.code,
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                });
            } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
                console.log(e);
            }
            if (response.headers.get('content-type')
            && response.headers.get('content-type').search('application/json') >= 0) {
                body = await response.json();
            }

            switch (response.status) {
            case 200: {
                const oldVisit = this.state.contact.visits.find((visit) => visit.code === visitCode);
                console.log('>>> oldVisit: ', oldVisit);

                const newVisitItems = [
                    ...oldVisit.visitItems,
                    body.visitItem,
                ];
                console.log('>>> newVisitItems: ', newVisitItems);

                const newVisit = {
                    ...oldVisit,
                    visitItems: newVisitItems,
                };
                console.log('>>> newVisit: ', newVisit);

                const newVisits = this.state.contact.visits.map((visit) => (visit.code === visitCode
                    ? newVisit
                    : visit
                ));

                console.log('>>> newVisits: ', newVisits);

                // console.l'og('>>> contact (visit):', {
                //     contact: {
                //         ...this.state.contact,
                //         visits: newVisits,
                //         histories: [
                //             body.history,
                //             ...this.state.contact.histories,
                //         ],
                //     },
                // });

                this.setState((state) => ({
                    contact: {
                        ...state.contact,
                        visits: newVisits,
                        histories: [
                            body.history,
                            ...state.contact.histories,
                        ],
                    },
                }));
                break;
            }
            default: {
                console.log('>>> Throwing error');
                throw new Error({ error: 'Generic API error' });
            }
            }
        }
    };

    noResponseRequired = async (leadCode) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v4/lead/${leadCode}/noResponseRequired`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        // console.log('>>> body: ', body);

        switch (response.status) {
        case 200: {
            const newLeads = this.state.contact.leads.map((lead) => {
                if (lead.code === leadCode) return body.lead;
                return lead;
            });

            const newHistories = [body.history, ...this.state.contact.histories];
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: newHistories,
                    leads: newLeads,
                },
            }));
            return;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    registerCall = async (leadCode) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v4/lead/${leadCode}/telephoneCall`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        // console.log('>>> body: ', body);

        switch (response.status) {
        case 200: {
            const newLeads = this.state.contact.leads.map((lead) => {
                if (lead.code === leadCode) return body.lead;
                return lead;
            });

            const newHistories = [body.history, ...this.state.contact.histories];
            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    histories: newHistories,
                    leads: newLeads,
                },
            }));
            return;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    selfMeasurementChecked = async (agreementCode) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/agreement/${agreementCode}/selfMeasurementChecked`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        // console.log('>>> body: ', body);

        switch (response.status) {
        case 200: {
            const newAgreements = this.state.contact.agreements.map((agreement) => {
                if (agreement.code === agreementCode) return body.agreement;
                return agreement;
            });

            // const newAgreements = this.state.contact.agreements.map((agreement) => (body.agreement.find((responseAgreement) => responseAgreement.code === agreement.code) ? {
            //     ...body.agreement.find((responseAgreement) => responseAgreement.code === agreement.code),
            // } : agreement));

            this.setState((state) => ({
                contact: {
                    ...state.contact,
                    agreements: newAgreements,
                    histories: [
                        body.history,
                        ...state.contact.histories,
                    ],
                },
            }));
            return;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    render() {
        const {
        } = this.props;
        const {
            // agreementsAlerts,
            agreementStatuses,
            branches,
            complaintReferenceTypes,
            complaintSources,
            complaintStatuses,
            complaintTypes,
            contact,
            contactAgents,
            contactAgentsLoading,
            contactContactAgents,
            contactContactAgentsLoading,
            contactAgreementsLoading,
            contactSalesLoading,
            contactCreditReportLoading,
            contactDuplicatesLoading,
            contactHistoriesLoading,
            contactLeadsLoading,
            contactLoading,
            contactSecurityAnswersLoading,
            contactVulnerabilitiesLoading,
            contactVisitsLoading,
            debtCompanies,
            debtStatuses,
            engineers,
            errors,
            leadStatuses,
            paymentCards,
            paymentCardsLoading,
            productTypes,
            engineersLoading,
            // manualPaymentTransctionStatus,
            // paymentCards,
            // requestedAgreementTab,
            // user,
            securityAnswers,
            user,
            userLoading,
            users,
            usersLoading,
            vulnerabilityTypes,
            vulnerabilityTypesLoading,
        } = this.state;

        const pageHeaderContent = contactLoading
            ? {
                leftText: ' ',
                leftTitle: 'Loading...',
            } : {
                leftText: `${[contact.address.address1, contact.address.address2, contact.address.townCity, contact.address.county, contact.address.postcode].filter((e) => e === 0 || e).join(', ')}`,
                leftTitle: `${contact.title === null ? '' : contact.title} ${contact.firstname} ${contact.surname}`,
                rightTitle: `C${contact.code}`,
            };

        // const customerVisitAddDialogLoading = (engineersLoading);

        return (
            <Layout pageTitle="Contact">
                <CssBaseline />
                <PageHeader
                    content={pageHeaderContent}
                    history={this.props.history}
                />
                {!userLoading && !(user.groups || []).find((group) => group.code === AMPLIFY5)
                    ? (
                        <Button
                            onClick={this.handleOldCustomerPageClick}
                        >
                            CLICK FOR OLD CUSTOMER PAGE
                        </Button>
                    ) : null}

                {/*
                <ActionCards />
                <Tile tileTitle="Agreement Payment Chart">
                    { customer !== null
                        ? (
                            <AgreementPaymentChart />
                        )
                        : (
                            <div className={classes.progressContainer}>
                                <CircularProgress className={classes.progress} variant="indeterminate" />
                            </div>
                        )
                    }
                </Tile>
                */}
                <ContactBadges
                    contact={contact}
                    contactAgreementsLoading={contactAgreementsLoading}
                    contactSalesLoading={contactSalesLoading}
                    contactLeadsLoading={contactLeadsLoading}
                    contactLoading={contactLoading}
                />
                <ContactDetailTabs
                    addComplaint={this.addComplaint}
                    addCompany={this.addCompany}
                    addContactVulnerability={this.addContactVulnerability}
                    addNewHistoryNote={this.addNewHistoryNote}
                    addAgentPostcodeDistrict={this.addAgentPostcodeDistrict}
                    branches={branches}
                    complaintReferenceTypes={complaintReferenceTypes}
                    complaintSources={complaintSources}
                    complaintStatuses={complaintStatuses}
                    complaintTypes={complaintTypes}
                    contact={contact}
                    contactContactAgents={contactContactAgents}
                    contactContactAgentsLoading={contactContactAgentsLoading}
                    contactAgreementsLoading={contactAgreementsLoading}
                    contactSalesLoading={contactSalesLoading}
                    contactCreditReportLoading={contactCreditReportLoading}
                    contactDuplicatesLoading={contactDuplicatesLoading}
                    contactHistoriesLoading={contactHistoriesLoading}
                    contactLoading={contactLoading}
                    contactSecurityAnswersLoading={contactSecurityAnswersLoading}
                    contactVulnerabilitiesLoading={contactVulnerabilitiesLoading}
                    debtCompanies={debtCompanies}
                    debtStatuses={debtStatuses}
                    editComplaint={this.editComplaint}
                    errors={errors}
                    expireContactVulnerability={this.expireContactVulnerability}
                    fetchContactContactAgents={this.fetchContactContactAgents}
                    fetchContactCreditReport={this.fetchContactCreditReport}
                    fetchContactData={this.fetchContactData}
                    fetchContactVulnerabilities={this.fetchContactVulnerabilities}
                    fetchHistoriesData={this.fetchHistoriesData}
                    deleteAgentPostcodeDistrict={this.deleteAgentPostcodeDistrict}
                    saveContactAddress={this.saveContactAddress}
                    saveContactDetails={this.saveContactDetails}
                    saveContactSecurityQuestions={this.saveContactSecurityQuestions}
                    fetchContactSecurityAnswers={this.fetchContactSecurityAnswers}
                    saveContactDebtDetails={this.saveContactDebtDetails}
                    securityAnswers={securityAnswers}
                    sendSMS={this.sendSMS}
                    sendSMSAgentDetails={this.sendSMSAgentDetails}
                    sendEmail={this.sendEmail}
                    sendDeclineAgreementEmail={this.sendDeclineAgreementEmail}
                    syncDetails={this.syncDetails}
                    updateContactDetails={this.updateContactDetails}
                    insertCreditReport={this.insertCreditReport}
                    user={user}
                    userLoading={userLoading}
                    users={users}
                    usersLoading={usersLoading}
                    vulnerabilityTypes={vulnerabilityTypes}
                    vulnerabilityTypesLoading={vulnerabilityTypesLoading}
                />
                {/* <NewCustomerDuplicates
                    customer={customer}
                    duplicates={duplicates}
                /> */}
                <ContactTabs
                    addAgreementItem={this.addAgreementItem}
                    addLeadNote={this.addLeadNote}
                    addNewHistoryNote={this.addNewHistoryNote}
                    agreementStatuses={agreementStatuses}
                    assignStockItem={this.assignStockItem}
                    authoriseLead={this.authoriseLead}
                    autoPhonePaymentSms={this.autoPhonePaymentSms}
                    branches={branches}
                    buildSendContract={this.buildSendContract}
                    cancelVisit={this.cancelVisit}
                    contact={contact}
                    contactAgents={contactAgents}
                    contactAgreementsLoading={contactAgreementsLoading}
                    contactSalesLoading={contactSalesLoading}
                    contactHistoriesLoading={contactHistoriesLoading}
                    contactVisitsLoading={contactVisitsLoading}
                    contactLeadsLoading={contactLeadsLoading}
                    contactTerminatedCall={this.contactTerminatedCall}
                    declineLead={this.declineLead}
                    deleteAgreementItem={this.deleteAgreementItem}
                    editAgreement={this.editAgreement}
                    editPaymentPlan={this.editPaymentPlan}
                    editLead={this.editLead}
                    emailContract={this.emailContract}
                    emailStatement={this.emailStatement}
                    engineers={engineers}
                    engineersLoading={engineersLoading}
                    errors={errors}
                    escalateCall={this.escalateCall}
                    exchangeAgreementItem={this.exchangeAgreementItem}
                    fetchAgreementsData={this.fetchAgreementsData}
                    fetchSalesData={this.fetchSalesData}
                    fetchHistoriesData={this.fetchHistoriesData}
                    fetchVisitsData={this.fetchVisitsData}
                    fetchLeadsAgreementsData={this.fetchLeadsAgreementsData}
                    fetchLeadsSalesData={this.fetchLeadsSalesData}
                    fetchNextCall={this.fetchNextCall}
                    leadStatuses={leadStatuses}
                    leadToSlowTrack={this.leadToSlowTrack}
                    logCall={this.logCall}
                    paymentCards={paymentCards}
                    paymentCardsLoading={paymentCardsLoading}
                    paymentLink={this.paymentLink}
                    productTypes={productTypes}
                    manualAdjustment={this.manualAdjustment}
                    manualPayment={this.manualPayment}
                    noResponseRequired={this.noResponseRequired}
                    refundPaymentPlan={this.refundPaymentPlan}
                    refundPayments={this.refundPayments}
                    registerCall={this.registerCall}
                    requestSignature={this.requestSignature}
                    rescheduleVisit={this.rescheduleVisit}
                    reversePayment={this.reversePayment}
                    scheduleCallBack={this.scheduleCallBack}
                    scheduleDelivery={this.scheduleDelivery}
                    scheduleRetryPayment={this.scheduleRetryPayment}
                    scheduleInterimPayment={this.scheduleInterimPayment}
                    sendDeliveryInvite={this.sendDeliveryInvite}
                    sendLeadLink={this.sendLeadLink}
                    sendRescheduleVisitInvite={this.sendRescheduleVisitInvite}
                    sendSelfMeasureInvite={this.sendSelfMeasureInvite}
                    sendSMS={this.sendSMS}
                    selfMeasurementChecked={this.selfMeasurementChecked}
                    standingOrderPayment={this.standingOrderPayment}
                    unassignAgreementItem={this.unassignAgreementItem}
                    user={user}
                    userLoading={userLoading}
                    users={users}
                    usersLoading={usersLoading}
                />
                {/* <NewCustomerLeads
                    customer={customer}
                    leads={leads}
                    errors={errors}
                    saveLeadUpdate={this.saveLeadUpdate}
                    convertLeadProducts={convertLeadProducts}
                    fetchConvertLeadProducts={this.fetchConvertLeadProducts}
                    requestedLeadTab={requestedLeadTab}
                    convertLead={this.convertLead}
                    manualPayment={this.manualPayment}
                    fetchCampaigns={this.fetchCampaigns}
                    campaigns={campaigns}
                    saveNewLead={this.saveNewLead}
                />
                <NewCustomerVisits
                    customer={customer}
                    customerVisitAddDialogLoading={customerVisitAddDialogLoading}
                    engineers={engineers}
                    errors={errors}
                    requestedVisitTab={requestedVisitTab}
                    visits={visits}
                /> */}
                {/* <ContactAgreements
                    agreementsAlerts={agreementsAlerts}
                    cancelAgreement={this.cancelAgreement}
                    checkTransactionStatus={this.checkTransactionStatus}
                    contact={contact}
                    contactAgreementsLoading={contactAgreementsLoading}
                    engineers={engineers}
                    errors={errors}
                    manualPayment={this.manualPayment}
                    manualPaymentTransctionStatus={manualPaymentTransctionStatus}
                    paymentCards={paymentCards}
                    printDeliveryLabels={this.printDeliveryLabels}
                    recordDelivery={this.recordDelivery}
                    requestedAgreementTab={requestedAgreementTab}
                    sendControlDeviceCommand={this.sendControlDeviceCommand}
                    sendTokenisationLink={this.sendTokenisationLink}
                    tokenManualPayment={this.tokenManualPayment}
                    updatePaymentPlanStatus={this.updatePaymentPlanStatus}
                    user={user}
                /> */}
                {/* <CustomerSales
                    customer={customer}
                    errors={errors}
                    requestedSaleTab={requestedSaleTab}
                    paymentCards={paymentCards}
                    manualPayment={this.manualPayment}
                    tokenManualPayment={this.tokenManualPayment}
                    checkTransactionStatus={this.checkTransactionStatus}
                    manualPaymentTransctionStatus={manualPaymentTransctionStatus}
                    agreementsAlerts={agreementsAlerts}
                    sendTokenisationLink={this.sendTokenisationLink}
                    printDeliveryLabels={this.printDeliveryLabels}
                    cancelAgreement={this.cancelAgreement}
                />
                <NewContactHistories
                    histories={histories}
                    addNewHistoriesNote={this.addNewHistoriesNote}
                    errors={errors}
                /> */}
            </Layout>
        );
    }
}

export default withStyles(styles)(Contact);
