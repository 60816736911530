import React from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    MenuItem,
    OutlinedInput,
    InputLabel,
    Select,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import SignatureCanvas from 'react-signature-canvas';
import './deliverySign.css';
import rotatePhoneIcon from './images/rotate_phone.png';

import Signature from './Signature';

const styles = theme => ({
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    formHelperText: {
        color: '#f44336',
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    formInstructions: {
        marginTop: theme.spacing.unit * 5,
        marginBottom: theme.spacing.unit * 2,
    },
    signaturePadContainer: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.12)',
        borderRadius: 4,
        background: 'rgb(255, 255, 255)',
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogContent: {
        paddingBottom: 0,
    },
    dialogActionsDivider: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    rotatePhoneIcon: {
        width: 80,
        opacity: '.7',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 30,
    },
    rotatePhoneText: {
        fontSize: 16,
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.60)',
        minHeight: 19,
        whiteSpace: 'pre-line',
        marginTop: 12,
        marginBottom: 12,
    },
    signatureImageContainer: {
        backgroundSize: '300px 100px',
        width: 290,
        height: 142,
        backgroundColor: 'white',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.12)',
        borderRadius: 4,
        alignItems: 'center,',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    signatureImage: {
        width: 270,
        height: 122,
        margin: 10,
    },
    portraitContainer: {
        alignItems: 'center',
    },
    nextButton: {
        width: '90%',
    },
    nextButtonContainer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100vw',
        marginBottom: theme.spacing.unit * 2,
        textAlign: 'center',
    },
    dialog: {
        backgroundColor: 'rgb(250, 250, 250)',
    },
});

class DeliverySign extends React.Component {
    state = {
        disableSaveButton: false,
        disableNextButton: false,
        trimmedDataURL: null,
        screenOrientation: null,
    }

    sigPad = {};

    componentDidMount() {
        window.addEventListener('orientationchange', () => {
            this.setScreenOrientation();
        }, false);
    }

    componentWillUnmount() {
        window.removeEventListener('orientationchange', () => {
            this.setScreenOrientation();
        }, false);
    }

    setScreenOrientation = () => {
        const orientation = window.screen.orientation.type.substring(0, window.screen.orientation.type.lastIndexOf('-'));
        this.setState({
            screenOrientation: orientation,
        });
        if (orientation === 'portrait') this.handleGrabSignature();
    }

    handleClearButtonClick = () => {
        this.sigPad.clear();
    }

    handleGrabSignature = () => {
        this.setState({ trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL('image/png') });
    }

    handleNextButtonClick = () => {
        this.props.setSignature(this.state.trimmedDataURL).then(() => {
        }).catch(() => {
            console.log('Error setting signature');
        });
    }

    render() {
        const {
            classes,
            delivery,
        } = this.props;
        const {
            disableSaveButton,
            disableNextButton,
            trimmedDataURL,
            screenOrientation,
        } = this.state;

        return (
            <React.Fragment>
                <Typography variant="h6">
                    Customer Signature
                </Typography>
                <Grid
                    container
                    spacing={0}
                >
                    <Grid
                        item
                        xs={12}
                        className={classes.portraitContainer}
                    >
                        <img
                            alt="Rotate Phone Icon"
                            className={classes.rotatePhoneIcon}
                            src={rotatePhoneIcon}
                        />
                        <Typography
                            align="center"
                            className={classes.rotatePhoneText}
                        >
                            Rotate phone to sign
                        </Typography>
                        <div className={classes.signatureImageContainer}>
                            {trimmedDataURL
                                ? (
                                    <img
                                        alt="Signature Image"
                                        className={classes.signatureImage}
                                        src={trimmedDataURL}
                                    />
                                )
                                : null}
                        </div>
                    </Grid>
                </Grid>
                {(trimmedDataURL || '').length > 114
                    ? (
                        <div className={classes.nextButtonContainer}>
                            <Button
                                onClick={this.handleNextButtonClick}
                                variant="contained"
                                className={classes.nextButton}
                                disabled={disableNextButton}
                            >
                                NEXT
                            </Button>
                        </div>
                    )
                    : null
                }

                <Dialog
                    open={screenOrientation === 'landscape'}
                    fullScreen
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{ paper: classes.dialog }}
                >
                    <DialogContent className={[classes.dialog, classes.dialogContent]}>
                        <div className={classes.signaturePadContainer}>
                            <Signature
                                canvasRef={(ref) => { this.sigPad = ref; }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleClearButtonClick}
                            color="primary"
                            autoFocus
                        >
                            Clear
                        </Button>
                    </DialogActions>
                </Dialog>

            </React.Fragment>
        );
    }
}

DeliverySign.propTypes = {
    classes: PropTypes.object.isRequired,
    delivery: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(DeliverySign));
