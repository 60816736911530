import React from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogActions,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import FilterListIcon from '@material-ui/icons/FilterList';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import MenuIcon from '@material-ui/icons/MenuOutlined';
import EditIcon from '@material-ui/icons/Create';
import { saveAs } from 'file-saver';
import moment from 'moment-timezone';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import AddIcon from '@material-ui/icons/Add';
import commaFormattedDecimal from '../../utilities/commaFormattedDecimal';
import QuoteAddItemDialog from './quoteAddItemDialog';

const json2csv = require('json2csv').parse;

const styles = theme => ({
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
        minHeight: 100,
        paddingTop: theme.spacing.unit * 3,
    },
    progress: {
        opacity: '.5',
    },
    tableTitleLeft: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        alignItems: 'left',
        paddingTop: theme.spacing.unit * 4,
        fontWeight: 500,
        textTransform: 'uppercase',
        paddingLeft: theme.spacing.unit * 2,
    },
    tableTitleRight: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        alignItems: 'right',
        textAlign: 'right',
        paddingTop: theme.spacing.unit * 2,
        fontWeight: 500,
        textTransform: 'uppercase',
        paddingRight: theme.spacing.unit * 2,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        // cursor: 'pointer',
    },
    tableHead: {
        // fontWeight: 'bold',
        fontSize: 16,
    },
    shortDescription: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
    noWrap: {
        whiteSpace: 'nowrap',
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    filterIcon: {
        width: 16,
    },
    filterButton: {
        marginLeft: theme.spacing.unit * 3,
    },
    orderDirectionIcon: {
        marginBottom: -10,
    },
    pageDetail: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        paddingTop: 13,
        fontWeight: 500,
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        textTransform: 'uppercase',
        paddingBottom: theme.spacing.unit * 2,
    },
    pageSizeMenuButton: {
        marginTop: 3,
        color: 'rgba(0, 0, 0, 0.54)',
    },
    pageSizeMenuButtonIcon: {
        marginLeft: theme.spacing.unit * 0.5,
    },
    hidden: {
        display: 'none',
    },
    orderColumn: {
        color: theme.palette.secondary.main,
    },
    expired: {
        color: 'rgba(0, 0, 0, 0.35)',
    },
    totalsCell: {
        fontWeight: 500,
    },
});

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        paddingRight: 8,
    },
    body: {
        fontSize: 16,
        verticalAlign: 'top',
        paddingTop: 16,
        paddingBottom: 8,
        paddingRight: 8,
    },
}))(TableCell);

class QuoteItemsTable extends React.Component {
    state = {
        showAddItemDialog: false,
    }

    componentDidMount() {
    }

    handleAddItemClick = () => {
        this.setState({
            showAddItemDialog: true,
        });
    }

    handleAddItemCloseDialog = () => {
        this.setState({
            showAddItemDialog: false,
        });
    }

    render() {
        const {
            classes,
            errors,
            productTypes,
            productTypesLoading,
            products,
            productsLoading,
            quoteItems,
        } = this.props;
        const {
            showAddItemDialog,
        } = this.state;

        return (
            <React.Fragment>
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <div className={classes.tableTitleLeft}>Blinds Quote</div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.tableTitleRight}>
                            <IconButton
                                className={classes.button}
                                onClick={this.handleAddItemClick}
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>
                <Paper>
                    <React.Fragment>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell className={classes.tableHead}>
                                        Manufacturer
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead}>
                                        Model Number
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead}>
                                        Width
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead}>
                                        Length
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead}>
                                        Repayment Amount
                                    </CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {quoteItems.map(quoteItem => (
                                    <TableRow className={classes.row} key={quoteItem.key}>
                                        <CustomTableCell className={`${classes.customTableCell}`} align="left">{quoteItem.manufacturer}</CustomTableCell>
                                        <CustomTableCell className={`${classes.customTableCell}`} align="left">{quoteItem.modelNo}</CustomTableCell>
                                        <CustomTableCell className={`${classes.customTableCell}`} align="left">{quoteItem.width}</CustomTableCell>
                                        <CustomTableCell className={`${classes.noWrap} ${classes.customTableCell}`} align="left">{quoteItem.length}</CustomTableCell>
                                        <CustomTableCell className={`${classes.customTableCell}`} align="right">{quoteItem.repaymentAmount ? `£${quoteItem.repaymentAmount.toFixed(2)}` : ''}</CustomTableCell>
                                    </TableRow>
                                ))}
                                <TableRow className={classes.row} key="totals">
                                    <CustomTableCell />
                                    <CustomTableCell />
                                    <CustomTableCell />
                                    <CustomTableCell className={`${classes.noWrap} ${classes.customTableCell} ${classes.totalsCell}`} align="left">Estimated Total</CustomTableCell>
                                    <CustomTableCell className={`${classes.customTableCell}  ${classes.totalsCell}`} align="right">
                                        £
                                        {commaFormattedDecimal(quoteItems.reduce((acc, b) => acc + b.repaymentAmount, 0).toFixed(2))}
                                    </CustomTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </React.Fragment>
                </Paper>

                {!productsLoading && !productTypesLoading && (
                    <QuoteAddItemDialog
                        errors={errors}
                        handleAddItemCloseDialog={this.handleAddItemCloseDialog}
                        products={products}
                        productsLoading={productsLoading}
                        productTypes={productTypes}
                        productTypesLoading={productTypesLoading}
                        showAddItemDialog={showAddItemDialog}
                    />
                )}
            </React.Fragment>
        );
    }
}

QuoteItemsTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(QuoteItemsTable);
