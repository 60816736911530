import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

const styles = theme => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
});

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

class DeliveryNoteSummaryTable extends React.Component {
    state = {

    }

    render() {
        const {
            classes,
            deliveryNoteItems,
        } = this.props;

        return (
            <Table className={classes.table} padding="dense">
                <TableHead>
                    <TableRow>
                        <CustomTableCell className={classes.tableHead}>Manufacturer</CustomTableCell>
                        <CustomTableCell className={classes.tableHead}>Model Number</CustomTableCell>
                        <CustomTableCell align="right" className={classes.tableHead}>Qty</CustomTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderBy(uniqBy(deliveryNoteItems, v => [v.manufacturerCode, v.modelNo].join()), 'manufacturer').map(deliveryNoteProduct => (
                        <TableRow className={classes.row} key={deliveryNoteProduct.productCode}>
                            <CustomTableCell className={classes.customTableCell} align="left">{deliveryNoteProduct.manufacturerName}</CustomTableCell>
                            <CustomTableCell className={classes.customTableCell} align="left">{deliveryNoteProduct.modelNo}</CustomTableCell>
                            <CustomTableCell className={classes.customTableCell} align="right">
                                {(() => {
                                  switch (deliveryNoteProduct.stockControlTypeDescription) {
                                    case 'Tracked': {
                                      return deliveryNoteItems.filter(deliveryNoteItem => deliveryNoteItem.manufacturerCode === deliveryNoteProduct.manufacturerCode && deliveryNoteItem.modelNo === deliveryNoteProduct.modelNo).length;
                                    }
                                    default: return deliveryNoteProduct.quantity;
                                  }
                                })()}
                            </CustomTableCell>
                        </TableRow>
                    ))}
                    <TableRow className={classes.row} key="tableTotal">
                        <CustomTableCell className={classes.customTableCell} align="left"></CustomTableCell>
                        <CustomTableCell className={classes.customTableCell} align="right">Total</CustomTableCell>
                        <CustomTableCell className={classes.customTableCell} align="right">
                            {deliveryNoteItems.reduce((acc, deliveryNoteItem) => (acc += (deliveryNoteItem.stockControlTypeDescription === 'Count' ? deliveryNoteItem.quantity : 1)), 0)}
                        </CustomTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }
}

const deliveryNoteItemsShape = {
    code: PropTypes.number,
    colour: PropTypes.string,
    length: PropTypes.number,
    manufacturerName: PropTypes.string,
    modelNo: PropTypes.string,
    shortDescription: PropTypes.string,
    width: PropTypes.number,
};
DeliveryNoteSummaryTable.propTypes = {
    classes: PropTypes.object.isRequired,
    deliveryNoteItems: PropTypes.arrayOf(PropTypes.shape(deliveryNoteItemsShape)).isRequired,
};

export default withStyles(styles)(DeliveryNoteSummaryTable);
