import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    rightSide: {
        textAlign: 'right',
    },
    root: {
        ...theme.mixins.gutters(),
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        marginBottom: theme.spacing.unit * 2,
        marginLeft: -(theme.spacing.unit * 3),
        marginRight: -(theme.spacing.unit * 3),
        paddingTop: theme.spacing.unit * 2,
    },
});

// eslint-disable-next-line react/prefer-stateless-function
class PageHeader extends React.Component {
    render() {
        const { classes, content } = this.props;

        // console.log('rendering..');

        return (
            <div>
                <Paper color="secondary" className={classes.root} square elevation={0}>
                    <Grid container spacing={0}>
                        <Grid item xs={content.rightTitle || content.rightText ? 6 : 12}>
                            <Typography variant="h5" component="h3" color="inherit">
                                {content.leftTitle}
                            </Typography>
                            {content.leftText
                                ? (
                                    <Typography component="p" color="inherit" paragraph>
                                        {content.leftText}
                                    </Typography>
                                )
                                : null}
                        </Grid>
                        <Grid item xs={content.leftTitle || content.leftText ? 6 : 12} className={classes.rightSide}>
                            <Typography variant="h6" component="h3" color="inherit">
                                {content.rightTitle
                                    ? (
                                        <>
                                            {content.rightTitle}
                                        </>
                                    )
                                    : null}
                            </Typography>
                            {content.rightText
                                ? (
                                    <Typography component="p" color="inherit" paragraph>
                                        {content.rightText}
                                    </Typography>
                                )
                                : null}
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

PageHeader.propTypes = {
    // Interal
    classes: PropTypes.object.isRequired,

    // External
    leftTitle: PropTypes.string,
    leftText: PropTypes.string,
    rightTitle: PropTypes.string,
    rightText: PropTypes.string,
};

PageHeader.defaultProps = {
    content: {},
};

export default withStyles(styles)(PageHeader);
