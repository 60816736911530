import React from 'react';
import {
    AppBar,
    Button,
    Checkbox,
    CircularProgress,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import GaugeChart from 'react-gauge-chart';
import moment from 'moment-timezone';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
    Link,
    DirectLink,
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller,
} from 'react-scroll';
import CustomerVisitNotes from './customerVisitNotes';
import CustomerVisitItemsToDeliver from './customerVisitItemsToDeliver';
import CustomerVisitItemsToCollect from './customerVisitItemsToCollect';
import CustomerVisitTasks from './customerVisitTasks';
import CustomerVisitSignature from './customerVisitSignature';
import CustomerVisitImages from './customerVisitImages';
import HybridTextField from './hybridTextField';
import titalise from '../utilities/titalise';
import { visitStatusLookUp } from '../utilities/lookUpTables';
import {
    titles,
    counties,
    countries,
} from '../utilities/menuOptions';
import Tile from '../containers/tile';
import VisitAddDialog from './customerVisitAddDialog';

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        whiteSpace: 'nowrap',
        verticalAlign: 'top',
        paddingTop: 16,
        paddingBottom: 16,
    },
}))(TableCell);

const styles = theme => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        // display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 2.5,
        marginBottom: theme.spacing.unit * 2,
    },
    emptyContent: {
        padding: 32,
    },
    emptyHybridTextContent: {
        paddingBottom: 32,
    },
    bottomBarContainer: {
        width: '100%',
        textAlign: 'right',
    },
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    nameField: {
        width: 350,
    },
    addressField: {
        width: 250,
    },
    postcodeField: {
        width: 120,
    },
    countryField: {
        width: 200,
        marginLeft: theme.spacing.unit * 3,
    },
    emailField: {
        width: 350,
    },
    rightOfFieldButton: {
        marginTop: 10,
        marginLeft: theme.spacing.unit * 3,
        color: 'rgba(0, 121, 255, 0.87)',
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
    },
    dialogActionsDivider: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    formHelperText: {
        color: '#f44336',
    },
    contactLocationIcon: {
        color: 'red',
        fontSize: 30,
        position: 'absolute',
        transform: 'translate(-50%, -100%)',
    },
    branchLocationIcon: {
        color: 'blue',
        fontSize: 30,
        position: 'absolute',
        transform: 'translate(-50%, -100%)',
    },
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    passChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '87px',
    },
    failChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '87px',
    },
    lightGrey: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
    table: {
        marginTop: theme.spacing.unit * 2,
    },
    gaugeContainer: {
        width: 100,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    sectionContainer: {
        // borderBottom: '1px solid #eaeaea',
        // marginBottom: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 1,
    },
    statusCell: {
        maxWidth: 250,
        whiteSpace: 'normal',
    },
    tabsPaper: {
        paddingTop: 32,
        paddingLeft: 32,
        paddingRight: 32,
        paddingBottom: 24,

        width: '100%',
        // display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },
    tabsAppBar: {
        backgroundColor: theme.palette.primary.lighter,
        // borderTopLeftRadius: 4,
        // borderTopRightRadius: 4,
    },
    tab: {
        color: 'white',
        fontSize: 15,
        fontWeight: 500,
        height: 38,
        minHeight: 38,
    },
    tabs: {
        padding: 0,
        minHeight: 38,
        height: 38,
        // backgroundColor: 'red',
    },
    tabButton: {
        padding: 0,
        minHeight: 38,
        height: 38,
        backgroundColor: 'red',
    },
    tileBottomDivider: {
        marginTop: theme.spacing.unit * 3,
        // marginBottom: theme.spacing.unit * 2,
    },
    tileDivider: {
        borderBottom: '1px solid #eaeaea',
        height: 1,
        marginBottom: theme.spacing.unit * 4,
        marginLeft: -32,
        marginRight: -32,
        marginTop: theme.spacing.unit * 1,
        // width: '100%',
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        marginBottom: 4,
    },
});

class CustomerVisits extends React.Component {
    state = {
        showVisitAddDialog: true,
        value: 0,
    }

    async componentDidMount() {
        await this.changeVisitTab();
    }

    componentDidUpdate(prevProps) {
        if (this.props.requestedVisitTab !== prevProps.requestedVisitTab) {
            this.changeVisitTab();
        }
    }

    changeVisitTab = async () => {
        if (this.props.requestedVisitTab >= 0) {
            await this.scrollToVisitsTop();
            this.setState({
                value: this.props.requestedVisitTab,
            });
        }
    }

    scrollToVisitsTop = () => {
        scroller.scrollTo('visitsTop', {
            duration: 800,
            delay: 100,
            smooth: 'easeInOutQuart',
            offset: -90,
        });
    }

    handleVisitChange = (event, newValue) => {
        this.setState({ value: newValue });
    }

    handleShowNewVisitClick = () => {
        this.setState({
            showCustomerVisitAddDialog: true,
        });
    }

    resetShowCustomerVisitAddDialog = () => {
        this.setState({
            showCustomerVisitAddDialog: false,
        });
    }

    render() {
        const {
            classes,
            customerVisitAddDialogLoading,
            engineers,
            errors,
            requestedVisitTab,
            visits,
        } = this.props;
        const {
            showCustomerVisitAddDialog,
            value,
        } = this.state;

        const currentVisit = get(visits, `[${value}]`, null);
        const now = moment.tz(moment(), 'Europe/London');

        const loading = visits == null;
        const TileDivider = (
            <div className={classes.tileDivider} />
        );

        return (
            <React.Fragment>
                <Tile
                    allowCollapse
                    initialCollapsedTile={requestedVisitTab < 0}
                    tileTitle="Visits"
                    scrollToMarker="visitsTop"
                    tileMenuItems={[
                        {
                            action: this.handleShowNewVisitClick,
                            label: 'New Visit',
                            section: 1,
                        },
                        {
                            action: this.handleRescheduleVisitClick,
                            disabled: !currentVisit || currentVisit.status === 'COMPLETE',
                            label: 'Reschedule Visit',
                            section: 2,
                        },

                        {
                            action: this.handleCancelVisitClick,
                            disabled: !currentVisit || currentVisit.status === 'COMPLETE',
                            label: 'Cancel Visit',
                            section: 2,
                        },
                    ]}
                >
                    {loading && (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )}

                    {!loading && (
                        <React.Fragment>
                            {visits.length > 0
                                ? (
                                    <React.Fragment>
                                        <AppBar
                                            position="static"
                                            className={classes.tabsAppBar}
                                        >
                                            <Tabs
                                                className={classes.tabs}
                                                value={value}
                                                onChange={this.handleVisitChange}
                                            >
                                                {visits.map(visit => (
                                                    <Tab
                                                        className={classes.tab}
                                                        key={visit.code}
                                                        label={
                                                            (
                                                                <React.Fragment>
                                                                    {`V${visit.code}`}
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    />
                                                ))}
                                            </Tabs>

                                        </AppBar>
                                        <Paper className={classes.tabsPaper}>
                                            <Grid
                                                container
                                                spacing={0}
                                                className={classes.sectionContainer}
                                            >
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        alert={currentVisit.scheduledVisitDateTime > now}
                                                        label="Status"
                                                        text={visitStatusLookUp[currentVisit.status]}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {TileDivider}
                                            <Grid
                                                container
                                                spacing={0}
                                                className={classes.sectionContainer}
                                            >
                                                <Grid item xs={2}>
                                                    <HybridTextField
                                                        label="Booked On Date"
                                                        text={moment(currentVisit.createdDateTime).format('DD-MM-YYYY') || ''}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <HybridTextField
                                                        label="Booked On Time"
                                                        text={moment(currentVisit.createdDateTime).format('HH:mm') || ''}
                                                    />
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <HybridTextField
                                                        label="Booked By"
                                                        text={currentVisit.createdByContactFullName || ''}
                                                    />
                                                </Grid>

                                                <Grid item xs={2}>
                                                    <HybridTextField
                                                        label="Scheduled Date"
                                                        text={moment(currentVisit.scheduledVisitDateTime).format('DD-MM-YYYY') || ''}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <HybridTextField
                                                        label="Scheduled Time"
                                                        text={moment(currentVisit.scheduledVisitDateTime).format('HH:mm') || ''}
                                                    />
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <HybridTextField
                                                        label="Scheduled Operative"
                                                        text={currentVisit.scheduledEngineerFullName || ''}
                                                    />
                                                </Grid>
                                                <CustomerVisitItemsToDeliver
                                                    visitItems={currentVisit.items}
                                                    scheduledDeliveryDateTime={currentVisit.scheduledDeliveryDateTime}
                                                />
                                                <CustomerVisitItemsToCollect
                                                    visitItems={currentVisit.items}
                                                    scheduledDeliveryDateTime={currentVisit.scheduledDeliveryDateTime}
                                                />
                                                <CustomerVisitTasks
                                                    visitTasks={currentVisit.tasks}
                                                    scheduledDeliveryDateTime={currentVisit.scheduledDeliveryDateTime}
                                                />
                                                <CustomerVisitNotes
                                                    visitNotesTitle="Schedule Notes"
                                                    visitNotes={currentVisit.notes.filter(note => note.visitNoteTypeDescription === 'Schedule')}
                                                />
                                            </Grid>
                                            {TileDivider}
                                            <Grid
                                                container
                                                spacing={0}
                                            >
                                                {currentVisit.status === 'COMPLETE'
                                                    ? (
                                                        <>
                                                            <Grid item xs={2}>
                                                                <HybridTextField
                                                                    label="Completed Date"
                                                                    text={moment(currentVisit.completedVisitDateTime).format('DD-MM-YYYY') || ''}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <HybridTextField
                                                                    label="Completed Time"
                                                                    text={moment(currentVisit.completedVisitDateTime).format('HH:mm') || ''}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <HybridTextField
                                                                    label="Completed by Operative"
                                                                    text={currentVisit.completedByEngineerFullName || ''}
                                                                />
                                                            </Grid>
                                                        </>
                                                    )
                                                    : null
                                                }
                                                <CustomerVisitImages
                                                    visitImages={currentVisit.images}
                                                />
                                                <CustomerVisitNotes
                                                    visitNotesTitle="Visit Notes"
                                                    visitNotes={currentVisit.notes.filter(note => note.visitNoteTypeDescription === 'Visit')}
                                                />
                                                {currentVisit.signatureLink === null
                                                    ? (
                                                        <div style={{ flex: 'none' }}>
                                                            <div className={classes.textFieldLabel}>Signature</div>
                                                            <div className={classes.emptyHybridTextContent}>Signature not found.</div>
                                                        </div>
                                                    )
                                                    : (
                                                        <CustomerVisitSignature
                                                            visitSignatureLink={currentVisit.signatureLink}
                                                        />
                                                    )
                                                }
                                            </Grid>
                                        </Paper>
                                    </React.Fragment>
                                )
                                : <div className={classes.emptyContent}>No visits found</div>
                            }
                        </React.Fragment>
                    )}
                </Tile>
                <VisitAddDialog
                    customerVisitAddDialogLoading={customerVisitAddDialogLoading}
                    engineers={engineers}
                    errors={errors}
                    resetShowCustomerVisitAddDialog={this.resetShowCustomerVisitAddDialog}
                    showCustomerVisitAddDialog={showCustomerVisitAddDialog}
                />
            </React.Fragment>
        );
    }
}


CustomerVisits.propTypes = {
    classes: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(CustomerVisits));
