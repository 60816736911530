import React from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import moment from 'moment-timezone';
import Tile from '../../containers/tile';
import HybridTextField from '../hybridTextField';
import { historyEntryTypesArray } from '../../constants/arrays';
// import newContactHistory from '../newContactHistory';

const styles = (theme) => ({
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    divider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 2.5,
        marginBottom: theme.spacing.unit * 2,
    },
    bottomBarContainer: {
        width: '100%',
        textAlign: 'right',
    },
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '8px 24px',
        margin: '0px 0px 0px 0px !important',
    },
    createdByFullNameField: {
        width: 350,
        marginLeft: theme.spacing.unit * 3,
    },
    createdDateField: {
        width: 120,
    },
    createdTimeField: {
        width: 120,
        marginLeft: theme.spacing.unit * 3,
    },
    tileBottomDivider: {
        marginTop: theme.spacing.unit * 3,
    },
    emailField: {
        width: 350,
    },
    rightOfFieldButton: {
        marginTop: 25,
        marginLeft: theme.spacing.unit * 3,
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
    },
    dialogActionsDivider: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    formHelperText: {
        color: '#f44336',
    },
    historyContainer: {
        // maxHeight: 300,
        overflow: 'auto',
    },
    historyPaper: {
        backgroundColor: theme.palette.background.default,
        border: '1px solid #e0e0e0',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing.unit * 2,
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 1}px`,
        width: '100%',
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    bumpTop3: {
        marginTop: theme.spacing.unit * 3,
    },
    bumpTop2: {
        marginTop: theme.spacing.unit * 2,
    },
    leftField: {
        paddingRight: theme.spacing.unit * 1.5,
    },
    rightField: {
        paddingLeft: theme.spacing.unit * 1.5,
    },
    inputAdornment: {
        marginRight: 0,
    },
});

class ManufacturerSupplierHistory extends React.Component {
    state = {
        // disableNewHistoryNoteSaveButton: false,
        // showNewHistoryNoteDialog: false,
    };

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         ...this.state,
    //         historyForm: {
    //             ...this.state.historyForm,
    //             entryType: props.entryType || '',
    //             entryTypeReferenceCode: props.entryTypeReferenceCode || '',
    //             note: '',
    //         },
    //     };
    // }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.entryTypeReferenceCode !== this.props.entryTypeReferenceCode) {
    //         this.setState((state) => ({
    //             historyForm: {
    //                 ...state.historyForm,
    //                 entryTypeReferenceCode: this.props.entryTypeReferenceCode || '',
    //             },
    //         }));
    //     }
    //     if (prevProps.entryType !== this.props.entryType) {
    //         this.setState((state) => ({
    //             historyForm: {
    //                 ...state.historyForm,
    //                 entryType: this.props.entryType || '',
    //             },
    //         }));
    //     }
    // }

    // handleNewHistoryNoteClick = () => {
    //     this.setCurrentEntryTypeReferenceCodes(this.state.historyForm.entryType);
    //     this.setState({
    //         showNewHistoryNoteDialog: true,
    //     });
    // };

    // setCurrentEntryTypeReferenceCodes = (entryType) => {
    //     let currentEntryTypeReferenceCodes = [];
    //     switch (entryType) {
    //     case 'AGREEMENT': currentEntryTypeReferenceCodes = this.props.contact.agreements.map((agreement) => `RA${agreement.code}`);
    //         break;
    //     case 'VISIT': currentEntryTypeReferenceCodes = this.props.contact.visits.map((visit) => `V${visit.code}`);
    //         break;
    //     case 'LEAD': currentEntryTypeReferenceCodes = this.props.contact.leads.map((lead) => `L${lead.code}`);
    //         break;
    //     case 'CONTACT': currentEntryTypeReferenceCodes = [`C${this.props.contact.code}`];
    //         break;
    //     default: currentEntryTypeReferenceCodes = '';
    //     }

    //     const newEntryTypeReferenceCode = entryType ? '' : this.state.historyForm.entryTypeReferenceCode;

    //     this.setState((state) => ({
    //         currentEntryTypeReferenceCodes,
    //         historyForm: {
    //             ...state.historyForm,
    //             entryTypeReferenceCode: currentEntryTypeReferenceCodes.length === 1 ? currentEntryTypeReferenceCodes[0] : newEntryTypeReferenceCode,
    //         },
    //     }));
    // };

    // handleNewHistoryNoteCloseDialogClick = () => {
    //     this.setState({
    //         showNewHistoryNoteDialog: false,
    //     });
    // };

    // titalise = (string) => string.toLowerCase()
    //     .split(' ')
    //     .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    //     .join(' ');

    // capitaliseFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

    // updateNewHistoryNoteData = (event) => {
    //     const { name, value } = event.target;
    //     let formattedValue;
    //     switch (name) {
    //     case 'note':
    //         formattedValue = this.capitaliseFirstLetter(value);
    //         break;
    //     case 'entryType':
    //         this.setCurrentEntryTypeReferenceCodes(value);
    //         formattedValue = value;
    //         break;
    //     case 'entryTypeReferenceCode':
    //         formattedValue = value;
    //         break;
    //     default: formattedValue = value;
    //         break;
    //     }
    //     event.target.value = formattedValue;
    //     this.setState((state) => ({
    //         historyForm: {
    //             ...state.historyForm,
    //             [name]: formattedValue,
    //         },
    //     }));
    // };

    // handleSaveNewHistoryNote = (event) => {
    //     event.preventDefault();
    //     this.setState({
    //         disableNewHistoryNoteSaveButton: true,
    //     });
    //     this.props.addNewHistoryNote(this.state.historyForm).then(() => {
    //         this.setState({
    //             disableNewHistoryNoteSaveButton: false,
    //             showNewHistoryNoteDialog: false,
    //         });
    //         this.props.enqueueSnackbar('History note saved', { variant: 'success' });
    //     }).catch(() => {
    //         this.props.enqueueSnackbar('History note save failed', { variant: 'error' });
    //         this.setState({
    //             disableNewHistoryNoteSaveButton: false,
    //         });
    //     });
    // };

    render() {
        const {
            classes,
            manufacturerSupplier,
            manufacturerSupplierHistoriesLoading,
            manufacturerSupplierLoading,
            errors,
            fetchHistoriesData,
            histories,
        } = this.props;
        const {
            // disableNewHistoryNoteSaveButton,
            // historyForm,
            // showNewHistoryNoteDialog,
        } = this.state;

        return (
            <Tile
                tileTitle="History"
                refreshAction={fetchHistoriesData}
                refreshing={manufacturerSupplierHistoriesLoading}
                tileMenuItems={[
                    {
                        action: this.handleNewHistoryNoteClick,
                        diabled: (manufacturerSupplierLoading && !manufacturerSupplier),
                        label: 'New Entry',
                    },
                ]}
                style
            >
                { !manufacturerSupplierHistoriesLoading
                    ? (

                        <div className={classes.historyContainer}>
                            <Grid container spacing={0}>
                                {histories.length === 0 && (
                                    <Typography variant="body1">
                                        No history found
                                    </Typography>
                                )}

                                {histories.sort((a, b) => b.createdDateTime.localeCompare(a.createdDateTime)).map((entry) => (
                                    <Paper
                                        className={classes.historyPaper}
                                        elevation={0}
                                        key={entry.code}
                                    >
                                        <Grid
                                            container
                                            spacing={0}
                                        >
                                            <Grid item xs={12}>
                                                <HybridTextField
                                                    label="Note"
                                                    text={entry.note}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={0}
                                        >
                                            <Grid item xs={2}>
                                                <HybridTextField
                                                    label="Date"
                                                    text={moment(entry.createdDateTime).format('DD/MM/YYYY')}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <HybridTextField
                                                    label="Time"
                                                    text={moment(entry.createdDateTime).format('HH:mm')}
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <HybridTextField
                                                    label="Created By"
                                                    text={entry.createdByContact}
                                                    link={`/contact/${entry.createdByContactCode}`}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                ))}
                            </Grid>

                            {/* <Dialog
                                open={showNewHistoryNoteDialog}
                                onClose={this.handleNewHistoryNoteCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <>
                                            <Typography variant="h6" gutterBottom>
                                                New Contact History Note
                                            </Typography>
                                            <DialogContentText>
                                                Enter the note text below and click [SAVE].
                                                The current time, date, and your name will be
                                                recorded automatically.
                                            </DialogContentText>
                                            <Grid
                                                container
                                                spacing={0}
                                                className={classes.bumpTop2}
                                            >
                                                <Grid
                                                    item
                                                    xs={6}
                                                    className={classes.leftField}
                                                >
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.entryType}
                                                    >
                                                        <InputLabel htmlFor="entryType" className={classes.inputLabel}>Entry Type</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateNewHistoryNoteData}
                                                                    name="entryType"
                                                                    id="entryType"
                                                                />
                                                            )}
                                                            value={historyForm.entryType || ''}
                                                        >
                                                            <MenuItem value=""><em>None</em></MenuItem>
                                                            {historyEntryTypesArray.map((entryType) => (
                                                                <MenuItem
                                                                    key={entryType}
                                                                    value={entryType}
                                                                >
                                                                    {entryType}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.entryType && (
                                                            <FormHelperText>{errors.entryType}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                {historyForm.entryType
                                                    ? (
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            className={classes.rightField}
                                                        >
                                                            <FormControl
                                                                className={classes.menuFormControl}
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.entryTypeReferenceCode}
                                                            >
                                                                <InputLabel htmlFor="entryTypeReferenceCode" className={classes.inputLabel}>Reference Code</InputLabel>
                                                                <Select
                                                                    keyboard="true"
                                                                    input={(
                                                                        <OutlinedInput
                                                                            onChange={this.updateNewHistoryNoteData}
                                                                            name="entryTypeReferenceCode"
                                                                            id="entryTypeReferenceCode"
                                                                        />
                                                                    )}
                                                                    value={historyForm.entryTypeReferenceCode}
                                                                >
                                                                    {(currentEntryTypeReferenceCodes || []).map((entryTypeReferenceCode) => (
                                                                        <MenuItem
                                                                            key={entryTypeReferenceCode}
                                                                            value={entryTypeReferenceCode}
                                                                        >
                                                                            {entryTypeReferenceCode}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {errors.entryTypeReferenceCode && (
                                                                    <FormHelperText>{errors.entryTypeReferenceCode}</FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Grid>
                                                    )
                                                    : ''}

                                            </Grid>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        onChange={this.updateNewHistoryNoteData}
                                                        className={classes.formControl}
                                                        name="note"
                                                        id="note"
                                                        label="Note"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        multiline
                                                        rows="6"
                                                        errors={!!errors.note}
                                                        helperText={errors.note}
                                                        autoFocus
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                spacing={0}
                                                direction="column"
                                                alignItems="center"
                                                justify="center"
                                            >
                                                <Grid item xs={12}>
                                                    <Button
                                                        className={classes.dialogButton}
                                                        disabled={disableNewHistoryNoteSaveButton || !historyForm.entryType || !historyForm.entryTypeReferenceCode || !historyForm.note}
                                                        onClick={this.handleSaveNewHistoryNote}
                                                    >
                                                        SAVE
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    </div>
                                </DialogContent>
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleNewHistoryNoteCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog> */}
                        </div>
                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )}
            </Tile>
        );
    }
}

export default withSnackbar(withStyles(styles)(ManufacturerSupplierHistory));
