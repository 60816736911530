import React from 'react';
import {
    CssBaseline,
    Chip,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import InfoIcon from '@material-ui/icons/InfoOutlined';

import Layout from './Layout';
import fetch from '../utilities/fetch';

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        marginBottom: theme.spacing.unit * 3,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    authorisedPaymentChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '87px',
    },
    declinedPaymentChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '87px',
    },
    unknownPaymentChip: {
        minWidth: '87px',
    },
    chip: {
        height: 21,
        borderRadius: 5,
    },
});

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

class Customers extends React.Component {
    state = {
        customers: [],
    };

    async componentDidMount() {
        const data = await fetch('https://api.portal2.payweeklycarpets.co.uk/core/customers').then((response) => response.json());
        this.setState({ customers: data });
        console.log(data);
    }

    handleLeadClick = (customerCode) => {
        this.props.history.push(`/contact/${customerCode}`);
    };

    render() {
        const { classes } = this.props;
        const { customers } = this.state;

        const someOtherJsx = (
            <div>some jsx</div>
        );

        return (
            <Layout pageTitle="Customers">
                <CssBaseline />
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>#</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Name</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Address</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Creation Date/Time</CustomTableCell>
                                {/* <CustomTableCell align="center" className={classes.tableHead}>Last Transaction</CustomTableCell> */}
                                <CustomTableCell align="center" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customers.map((customer, index) => (
                                <TableRow onClick={() => this.handleLeadClick(customer.code)} className={classes.row} key={customer.code}>
                                    <CustomTableCell component="th" scope="row">{index + 1}</CustomTableCell>
                                    <CustomTableCell align="left">{customer.fullName}</CustomTableCell>
                                    <CustomTableCell align="left">{customer.shortAddress}</CustomTableCell>
                                    <CustomTableCell align="right">{customer.creationDateTime}</CustomTableCell>
                                    {/* <CustomTableCell align="center">
                                          {
                                            (() => {
                                              switch(customer.lastPaymentTransactionStatus) {
                                                case 'Authorised': {
                                                  return <Chip label="Authorised" className={[classes.chip, classes.authorisedPaymentChip]} />
                                                }
                                                case 'Declined': {
                                                    return <Chip label="Declined" className={[classes.chip, classes.declinedPaymentChip]} />
                                                }
                                                default: {
                                                    return <Chip label="?" className={[classes.chip, classes.unknownPaymentChip]} />
                                                }
                                              }
                                            })()
                                          }

                                        </CustomTableCell> */}
                                    <CustomTableCell align="right"><IconButton className={classes.button} aria-label="More info"><InfoIcon /></IconButton></CustomTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Layout>
        );
    }
}

export default withStyles(styles)(Customers);
