import React from 'react';
import {
    CircularProgress,
    CssBaseline,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSimCard } from '@fortawesome/free-solid-svg-icons';
import qs from 'qs';

import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import Layout from './Layout';
import fetch from '../utilities/fetch';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        marginBottom: theme.spacing.unit * 3,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
        // whiteSpace: 'nowrap',
    },
    customTableCell: {
        whiteSpace: 'nowrap',
        padding: theme.spacing.unit * 2,
    },
    simCard: {
        fontSize: 20,
    },
    searchResultType: {
        fontSize: 16,
    },
    searchResultTitle: {
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.54)',
    },
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
});

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

class SearchResults extends React.Component {
    state = {
        results: [],
        searching: true,
    };

    async componentDidMount() {
        this.fetchSearchResults();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            const { type } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || '';
            if (type === 'ADVANCED') {
                this.fetchAdvancedSearchResults();
                this.setState({
                    searching: true,
                    results: [],
                });
            } else {
                this.fetchSearchResults();
                this.setState({
                    searching: true,
                    results: [],
                });
            }
        }
    }

    fetchSearchResults = async () => {
        const { type } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || '';
        const { searchString } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || '';
        let url;
        let params;
        if (type === 'ADVANCED') {
            url = new URL(`${REACT_APP_API_SERVER}v2/advancedSearch`);
            params = {
                searchString,
            };
        } else {
            console.log('>>> NORMAL');
            url = new URL(`${REACT_APP_API_SERVER}search`);
            params = {
                searchString,
            };
        }
        Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
        const data = await fetch(url).then((response) => response.json());
        this.setState({
            searching: false,
            results: data,
        });
    };

    fetchAdvancedSearchResults = async () => {
        const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || '';
        const url = new URL(`${REACT_APP_API_SERVER}v2/advancedSearch`);
        Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
        const data = await fetch(url).then((response) => response.json());
        this.setState({
            searching: false,
            results: data,
        });
    };

    handleResultClick = (url) => {
        this.props.history.push(url);
    };

    render() {
        const { classes } = this.props;
        const { results, searching } = this.state;

        return (
            <Layout pageTitle="Search Results">
                <CssBaseline />
                {searching
                    ? (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )
                    : null}
                {!searching && results.length === 0
                    ? (
                        <>
                            No matching results found.
                        </>
                    )
                    : (
                        <Paper className={classes.root}>
                            <Table className={classes.table} padding="dense">
                                <TableBody>
                                    {results.map((result, index) => (
                                        <TableRow onClick={() => this.handleResultClick(result.url)} className={classes.row} key={index}>
                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                <div className={classes.searchResultType}>
                                                    {result.searchResultType}
                                                </div>
                                                <div className={classes.searchResultTitle}>
                                                    {result.searchResultTitle1}
                                                </div>
                                                <div className={classes.searchResultTitle}>
                                                    {result.searchResultTitle3}
                                                </div>
                                                <div className={classes.searchResultTitle}>
                                                    {result.searchResultTitle2}
                                                </div>
                                            </CustomTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                    )}
            </Layout>
        );
    }
}

export default withStyles(styles)(SearchResults);
