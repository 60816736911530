import React from 'react';
import {
    CssBaseline,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Dialog,
    Button,
    DialogContent,
    Grid,
    Typography,
    FormControl,
    InputLabel,
    TextField,
    DialogActions,
    Select,
    Menu,
    MenuItem,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import EditIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/AddOutlined';

import { template } from 'lodash';
import Cookie from 'js-cookie';
import Layout from './Layout';
import fetch from '../utilities/fetch';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        marginBottom: theme.spacing.unit * 3,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    authorisedPaymentChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '87px',
    },
    declinedPaymentChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '87px',
    },
    unknownPaymentChip: {
        minWidth: '87px',
    },
    chip: {
        height: 21,
        borderRadius: 5,
    },
});

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

class Templates extends React.Component {
    state = {
        templates: [],
        fieldsAnchor: null,
        message: '',
    };

    async componentDidMount() {
        try {
            const data = await fetch(`${process.env.REACT_APP_API_SERVER_SYD}core/v2/templates`).then(
                response => response.json(),
            );
            this.setState({ templates: data });
        } catch (err) {
            console.log(err);
        }
    }

    editClick = async (templateCode, index) => {
        try {
            let data;
            if (templateCode > 0) {
                data = await fetch(`${process.env.REACT_APP_API_SERVER_SYD}core/v2/templates/${templateCode}`).then(
                    response => response.json(),
                );
            }
            this.setState({
                open: true,
                index,
                code: templateCode,
                description: templateCode > 0 ? data.description : '',
                type: templateCode > 0 ? data.type : '',
                subject: templateCode > 0 ? data.subject : '',
                message: templateCode > 0 ? data.message : '',
                preview: '',
            });
        } catch (err) {
            console.log(err);
        }
    }

    editUpdate = (event) => {
        const { name, value } = event.target;
        this.setState(state => ({
            [name]: value,
        }));
    }

    editTypeUpdate = (event) => {
        this.editUpdate(event);
        this.setState(state => ({
            preview: '',
        }));
    }

    editClose = () => {
        this.setState({ open: false });
    };

    editPreview = async () => {
        try {
            const template = {
                message: this.state.message,
            };
            if (this.state.type == 'LETTER') {
                const query = { message: this.state.message, token: Cookie.get('authToken') };
                const url = `${process.env.REACT_APP_API_SERVER_SYD}core/v2/templates/preview/pdf?${(new URLSearchParams(query)).toString()}`;
                console.log(url);
                this.setState(state => ({
                    preview: '',
                }));
                const tab = window.open(url, '_blank', 'noopener,noreferrer');
                if (tab) {
                    tab.opener = null;
                }
            } else {
                const data = await fetch(`${process.env.REACT_APP_API_SERVER_SYD}core/v2/templates/preview`, {
                    method: 'POST',
                    body: JSON.stringify(template),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }).then(
                    response => response.json(),
                );
                this.setState(state => ({
                    preview: data.message,
                }));
            }
        } catch (e) {
            console.log(e);
        }
    }

    editSave = async () => {
        try {
            const template = {
                code: this.state.code,
                description: this.state.description,
                type: this.state.type,
                subject: this.state.subject,
                message: this.state.message,
            };
            const data = await fetch(`${process.env.REACT_APP_API_SERVER_SYD}core/v2/templates/${template.code == 0 ? '' : template.code}`, {
                method: template.code == 0 ? 'POST' : 'PATCH',
                body: JSON.stringify(template),
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(
                response => response.json(),
            );
            if (template.code == 0) {
                this.state.templates.push(data);
            } else {
                this.state.templates[this.state.index] = data;
            }
            this.setState({ open: false });
        } catch (err) {
            console.log(err);
        }
    };

    fieldsClick = (event) => {
        this.setState({ fieldsAnchor: event.currentTarget });
    };

    fieldsClose = (event) => {
        this.setState({ fieldsAnchor: null });
    };

    fieldsSelect = (event) => {
        try {
            let { value } = event.currentTarget.dataset;
            if(!/^#.+#$/gm.test(value)) {
                value = `<%= ${value} %>`;
            }
            navigator.clipboard.writeText(value);
            this.setState({ fieldsAnchor: null });
        } catch (err) {
            console.log(err);
        }
    };

    htmlClick = async (event) => {
        try {
            const data = await fetch(`${process.env.REACT_APP_API_SERVER_SYD}core/v2/templates/default/html`).then(
                response => response.json(),
            );
            this.setState({ message: data.html.replace('{{message}}', this.state.message) });
        } catch (err) {
            console.log(err);
        }
    };

    render() {
        const { classes } = this.props;
        const { templates } = this.state;
        const { fieldsAnchor } = this.state;
        return (

            <Layout pageTitle="Templates">
                <CssBaseline />
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>#</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Type</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Description</CustomTableCell>
                                <CustomTableCell align="right"><IconButton onClick={() => this.editClick(0, 0)} className={classes.button} aria-label="Edit"><AddIcon /></IconButton></CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {templates.map((template, index) => (
                                <TableRow className={classes.row} key={template.code}>
                                    <CustomTableCell component="th" scope="row">{template.code}</CustomTableCell>
                                    <CustomTableCell align="left">{template.type}</CustomTableCell>
                                    <CustomTableCell align="left">{template.description}</CustomTableCell>
                                    <CustomTableCell align="right"><IconButton onClick={() => this.editClick(template.code, index)} className={classes.button} aria-label="Edit"><EditIcon /></IconButton></CustomTableCell>
                                </TableRow>

                            ))}
                        </TableBody>
                    </Table>
                    <Dialog
                        open={this.state.open}
                        className={classes.dialogContent}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogContent>
                            <div className={classes.dialogContentContainer} style={{ position: 'relative' }}>
                                <div style={{ position: 'absolute', right: '0px' }}>
                                    <Button
                                        onClick={this.htmlClick}
                                        style={{ display: this.state.message.match(/<html.*>/gmi) == null && this.state.type == 'LETTER' ? '' : 'none' }}
                                    >
                                        HTML
                                    </Button>
                                    <Button
                                        aria-owns={fieldsAnchor ? 'simple-menu' : undefined}
                                        aria-haspopup="true"
                                        onClick={this.fieldsClick}
                                    >
                                        Fields
                                    </Button>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={fieldsAnchor}
                                        open={Boolean(fieldsAnchor)}
                                        onClose={this.fieldsClose}
                                    >
                                        <MenuItem onClick={this.fieldsSelect} data-value="contact.title">Title</MenuItem>
                                        <MenuItem onClick={this.fieldsSelect} data-value="contact.firstname">First Name</MenuItem>
                                        <MenuItem onClick={this.fieldsSelect} data-value="contact.surname">Surname</MenuItem>
                                        <MenuItem onClick={this.fieldsSelect} data-value="contact.address.address1">Address Line 1</MenuItem>
                                        <MenuItem onClick={this.fieldsSelect} data-value="contact.address.address2">Address Line 2</MenuItem>
                                        <MenuItem onClick={this.fieldsSelect} data-value="contact.address.townCity">Town / City</MenuItem>
                                        <MenuItem onClick={this.fieldsSelect} data-value="contact.address.county">County</MenuItem>
                                        <MenuItem onClick={this.fieldsSelect} data-value="contact.address.postcode">Postcode</MenuItem>
                                        <MenuItem onClick={this.fieldsSelect} data-value="'RA' + agreement.code">RA No</MenuItem>
                                        <MenuItem onClick={this.fieldsSelect} data-value='agreement.agreementItems.map(i => i.product.shortDescription).join(", ")'>Product(s)</MenuItem>
                                        <MenuItem onClick={this.fieldsSelect} data-value="(new Date()).toLocaleDateString()">Date</MenuItem>
                                        <MenuItem onClick={this.fieldsSelect} data-value='#PAYMENT_LINK#'>Payment Link</MenuItem>
                                    </Menu>
                                </div>
                                <React.Fragment>
                                    <Grid container spacing={0}>
                                        <Grid item container xs={6}>
                                            <Typography variant="h6" gutterBottom>
                                                {this.state.code == 0 ? 'Add Template' : 'Edit Template'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <form>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <FormControl
                                                    required
                                                    className={`${classes.menuFormControl} ${classes.filterSet}`}
                                                    variant="outlined"
                                                    fullWidth
                                                >
                                                    <TextField
                                                        required
                                                        className={classes.formControl}
                                                        name="description"
                                                        id="description"
                                                        value={this.state.description}
                                                        onChange={this.editUpdate}
                                                        label="Description"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!this.state.description}
                                                    />
                                                    <TextField
                                                        id="type"
                                                        className={classes.formControl}
                                                        name="type"
                                                        value={this.state.type}
                                                        onChange={this.editTypeUpdate}
                                                        select
                                                        label="Type"
                                                        variant="outlined"
                                                        required
                                                        error={!this.state.type}
                                                    >
                                                        <MenuItem value="SMS">SMS</MenuItem>
                                                        <MenuItem value="EMAIL">Email</MenuItem>
                                                        <MenuItem value="LETTER">Letter</MenuItem>
                                                        <MenuItem value="VOICEMAIL">Voicemail</MenuItem>
                                                    </TextField>
                                                    {this.state.type == 'EMAIL'
                                                        ? (
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="subject"
                                                                id="subject"
                                                                value={this.state.subject}
                                                                onChange={this.editUpdate}
                                                                label="Subject"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!this.state.subject}
                                                            />
                                                        )
                                                        : null
                                                    }
                                                    <TextField
                                                        required
                                                        className={classes.formControl}
                                                        name="message"
                                                        id="message"
                                                        value={this.state.message}
                                                        onChange={this.editUpdate}
                                                        label="Message"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        multiline
                                                        inputProps={{ style: { fontFamily: 'monospace' } }}
                                                        error={!this.state.message || (this.state.type == 'SMS' && this.state.message.length > 140) || (this.state.type == 'VOICEMAIL' && this.state.message.length > 300)}
                                                        helperText={this.state.type == 'SMS' && this.state.message.length > 140 ? 'WARNING: SMS Message > 140' : this.state.type == 'VOICEMAIL' && this.state.message.length > 300 ? 'WARNING: Voicemail Message > 300' : ''}
                                                    />
                                                    {this.state.preview != ''
                                                        ? (
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="preview"
                                                                id="preview"
                                                                value={this.state.preview}
                                                                label="Preview"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                readOnly
                                                                multiline
                                                            />
                                                        )
                                                        : null
                                                    }
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </React.Fragment>
                            </div>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                onClick={this.editPreview}
                                color="primary"
                                autoFocus
                                style={{ position: 'absolute', left: '15px' }}
                            >
                                Preview
                            </Button>
                            <Button
                                onClick={this.editSave}
                                color="primary"
                                autoFocus
                                disabled={!this.state.description || !this.state.type || (this.state.type == 'EMAIL' && !this.state.subject) || !this.state.message}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={this.editClose}
                                color="primary"
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Layout>
        );
    }
}

export default withStyles(styles)(Templates);
