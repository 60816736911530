import React, { isValidElement } from 'react';
import {
    CircularProgress,
    CssBaseline,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import Layout from '../Layout';
import fetch, { fetchJson2 } from '../../utilities/fetch';
import CuttingTable from '../../components/v2/cuttingTable';
// import ProductCreateDialog from '../../components/v2/manufacturerSupplierCreateDialog';
// import ProductEditDialog from '../../components/v2/manufacturerSupplierEditDialog';
// import ProductExpireDialog from '../../components/v2/manufacturerSupplierExpireDialog';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    inputLabel: {
        backgroundColor: '#fff',
        marginLeft: -5,
        paddingLeft: 8,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    newPurchaseOrderDialog: {
        maxWidth: 'none',
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
        minWidth: 400,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    itemsContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    itemsAddButtonContainer: {
        textAlign: 'right',
    },
    itemsTitleContainer: {
        marginTop: 13,
    },
    fieldAddButton: {
        marginLeft: 24,
        marginTop: 24,
    },
    radioGroup: {
        marginLeft: 24,
    },
    radioFormControl: {
        marginTop: 16,
    },
});

const currentDate = moment.tz(moment(), 'Europe/London');

class Cutting extends React.Component {
    state = {
        branchesLoading: true,
        endDate: moment(currentDate).format('YYYY/MM/DD'),
        errors: [],
        startDate: moment(currentDate).format('YYYY/MM/DD'),
        userLoading: true,
        visitItemsLoading: true,
    };

    componentDidMount() {
        document.addEventListener('visibilitychange', this.onVisibilitychange);
        this.loadMenuData();
        this.loadVisitItemsData(this.state.startDate, this.state.endDate);
    }

    componentWillUnmount() {
        document.removeEventListener('visibilitychange', this.onVisibilitychange);
    }

    onVisibilitychange = () => {
        if (document.hidden === true) return;
        this.refreshVisitItemsData();
    };

    refreshVisitItemsData = () => {
        this.loadVisitItemsData(this.state.startDate, this.state.endDate).then(() => {
            this.props.enqueueSnackbar('UPDATED', { variant: 'success', autoHideDuration: 750 });
        }).catch(() => {
            this.props.enqueueSnackbar('UPDATE FAILED', { variant: 'error', autoHideDuration: 750 });
        });
    };

    loadMenuData = async () => {
        fetchJson2('v2/branches?branchType=BUILDING')
            .then((branches) => {
                this.setState({
                    branches: branches.payload,
                    branchesLoading: false,
                });
            });
        fetchJson2('user')
            .then((user) => {
                this.setState({
                    user: user.payload,
                    userLoading: false,
                });
            });
    };

    loadVisitItemsData = async (startDate, endDate) => {
        fetchJson2(`v2/visitItems/cutting?startDate=${startDate}&endDate=${endDate}`)
            .then((visitItems) => {
                this.setState({
                    visitItems: visitItems.payload,
                    visitItemsLoading: false,
                });
            });
    };

    handleStartDateChange = (chosenDate) => {
        this.setState({
            startDate: moment(chosenDate).format('YYYY-MM-DD'),
        });
        this.loadVisitItemsData(moment(chosenDate).format('YYYY-MM-DD'), this.state.endDate);
    };

    handleEndDateChange = (chosenDate) => {
        this.setState({
            endDate: moment(chosenDate).format('YYYY-MM-DD'),
        });
        this.loadVisitItemsData(this.state.startDate, moment(chosenDate).format('YYYY-MM-DD'));
    };

    render() {
        const {
            classes,
        } = this.props;

        const {
            branches,
            branchesLoading,
            startDate,
            endDate,
            visitItems,
            visitItemsLoading,
            errors,
            user,
            userLoading,
        } = this.state;

        return (
            <Layout pageTitle="Cutting">
                <CssBaseline />
                {
                    !visitItemsLoading
                        ? (
                            <>
                                <CuttingTable
                                    branches={branches}
                                    branchesLoading={branchesLoading}
                                    editProduct={this.editProduct}
                                    errors={errors}
                                    expireProduct={this.expireProduct}
                                    handleCreateProductClick={this.handleCreateProductClick}
                                    handleExpireProductClick={this.handleExpireProductClick}
                                    handleEditProductClick={this.handleEditProductClick}
                                    handleCloseEditProductDialogClick={this.handleCloseEditProductDialogClick}
                                    handleCloseExpireProductDialogClick={this.handleCloseExpireProductDialogClick}
                                    visitItems={visitItems}
                                    visitItemsLoading={visitItemsLoading}
                                    passNewProduct={this.passNewProduct}
                                    refreshVisitItemsData={this.refreshVisitItemsData}
                                    unexpireProduct={this.unexpireProduct}
                                    startDate={startDate}
                                    endDate={endDate}
                                    handleStartDateChange={this.handleStartDateChange}
                                    handleEndDateChange={this.handleEndDateChange}
                                    user={user}
                                    userLoading={userLoading}
                                />
                            </>
                        )
                        : (
                            <div className={classes.progressContainer}>
                                <CircularProgress className={classes.progress} variant="indeterminate" />
                            </div>
                        )
                }

            </Layout>
        );
    }
}

export default withSnackbar(withStyles(styles)(Cutting));
