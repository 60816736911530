import React from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogActions,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import FilterListIcon from '@material-ui/icons/FilterList';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import MenuIcon from '@material-ui/icons/MenuOutlined';
import EditIcon from '@material-ui/icons/Create';
import { saveAs } from 'file-saver';
import moment from 'moment-timezone';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import AddIcon from '@material-ui/icons/Add';
import commaFormattedDecimal from '../../utilities/commaFormattedDecimal';

const json2csv = require('json2csv').parse;

const styles = theme => ({
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 2.5,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
        minWidth: 300,
    },
    inputLabel: {
        backgroundColor: '#fff',
        marginLeft: -5,
        paddingLeft: 8,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
        minHeight: 100,
        paddingTop: theme.spacing.unit * 3,
    },
    progress: {
        opacity: '.5',
    },
});

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        paddingRight: 8,
    },
    body: {
        fontSize: 16,
        verticalAlign: 'top',
        paddingTop: 16,
        paddingBottom: 8,
        paddingRight: 8,
    },
}))(TableCell);

class QuoteAddItemDialog extends React.Component {
    state = {
        formData: {},
        showAddItemDialog: false,
    }

    componentDidMount() {
    }

    handleAddItemClick = () => {
        this.setState({
            showAddItemDialog: true,
        });
    }

    handleAddItemCloseDialog = () => {
        this.setState({
            showAddItemDialog: false,
        });
    }

    updateFormData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        case 'emailAddress':
            formattedValue = value.toLowerCase();
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState(state => ({
            formData: {
                ...state.formData,
                [name]: formattedValue,
            },
        }));
    }

    render() {
        const {
            classes,
            errors,
            handleAddItemCloseDialog,
            productTypes,
            productTypesLoading,
            products,
            productsLoading,
            quoteItems,
            showAddItemDialog,
        } = this.props;
        const {
            formData,
        } = this.state;

        let currentManufacturers = [];
        let currentProducts = [];
        let currentSubCategories = [];

        if (!productsLoading && !productTypesLoading) {
            currentProducts = products.filter(product => product.type.code === formData.productTypeCode && product.productTypeSubCategoryCode === formData.productTypeSubCategoryCode);
            console.log('>>> currentProducts ->:', currentProducts);
            if (formData.productTypeCode) {
                currentSubCategories = currentProducts.reduce((acc, product) => {
                    if (acc.find(productSubCategory => (productSubCategory.code === product.productTypeSubCategoryCode))) return [...acc];
                    return [...acc, { code: product.productTypeSubCategoryCode, description: product.productTypeSubCategory }];
                }, []);
            }
            console.log('>>> currentSubCategories ->:', currentSubCategories);
            currentManufacturers = currentProducts.reduce((acc, product) => {
                if (acc.find(currentProduct => currentProduct.code === product.manufacturer.code)) return acc;
                return ([...acc, product.manufacturer]);
            }, []);
            // console.log('>>> CurrentManufacturers ->:', currentManufacturers);
        }

        return (
            <React.Fragment>
                <React.Fragment>
                    <Dialog
                        open={showAddItemDialog}
                        onClose={handleAddItemCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent className={classes.dialogContent}>
                            <div className={classes.dialogContentContainer}>
                                <React.Fragment>
                                    <Typography variant="h6" gutterBottom>
                                        Add Quote Item
                                    </Typography>

                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <FormControl
                                                required
                                                className={`${classes.menuFormControl} ${classes.filterSet}`}
                                                variant="outlined"
                                                fullWidth
                                                error={!!errors.productTypeCode}
                                            >
                                                <InputLabel htmlFor="productTypeCode" className={classes.inputLabel}>Product Type</InputLabel>
                                                <Select
                                                    keyboard="true"
                                                    input={(
                                                        <OutlinedInput
                                                            onChange={this.updateFormData}
                                                            name="productTypeCode"
                                                            id="productTypeCode"
                                                        />
                                                    )}
                                                    value={formData.productTypeCode || ''}
                                                >
                                                    {productTypes.map(productType => (
                                                        <MenuItem
                                                            key={productType.code}
                                                            value={productType.code}
                                                        >
                                                            {productType.description}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errors.productTypeCode && (
                                                    <FormHelperText>{errors.productTypeCode}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControl
                                                required
                                                className={`${classes.menuFormControl} ${classes.filterSet}`}
                                                variant="outlined"
                                                fullWidth
                                                error={!!errors.productTypeSubCategoryCode}
                                            >
                                                <InputLabel htmlFor="productTypeSubCategoryCode" className={classes.inputLabel}>Product Type Sub-Category</InputLabel>
                                                <Select
                                                    keyboard="true"
                                                    input={(
                                                        <OutlinedInput
                                                            onChange={this.updateFormData}
                                                            name="productTypeSubCategoryCode"
                                                            id="productTypeSubCategoryCode"
                                                        />
                                                    )}
                                                    value={formData.productTypeSubCategoryCode || ''}
                                                >
                                                    {currentSubCategories.map(currentSubCategory => (
                                                        <MenuItem
                                                            key={currentSubCategory.code}
                                                            value={currentSubCategory.code}
                                                        >
                                                            {currentSubCategory.description}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errors.currentSubCategory && (
                                                    <FormHelperText>{errors.currentSubCategory}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControl
                                                required
                                                className={`${classes.menuFormControl} ${classes.filterSet}`}
                                                variant="outlined"
                                                fullWidth
                                                error={!!errors.modelNo}
                                            >
                                                <InputLabel
                                                    htmlFor="modelNo"
                                                    className={classes.inputLabel}
                                                >
                                                    Model Number

                                                </InputLabel>
                                                <Select
                                                    keyboard="true"
                                                    input={(
                                                        <OutlinedInput
                                                            onChange={this.updateFormData}
                                                            name="modelNo"
                                                            id="modelNo"
                                                        />
                                                    )}
                                                    value={formData.modelNo || ''}
                                                >
                                                    {currentProducts.map(currentProduct => (
                                                        <MenuItem
                                                            key={currentProduct.code}
                                                            value={currentProduct.code}
                                                        >
                                                            {currentProduct.modelNo}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errors.currentProduct && (
                                                    <FormHelperText>{errors.currentProduct}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>

                                        {/* <Grid item xs={12}>
                                            <FormControl
                                                required
                                                className={`${classes.menuFormControl} ${classes.filterSet}`}
                                                variant="outlined"
                                                fullWidth
                                                error={!!errors.manufacturer}
                                            >
                                                <InputLabel
                                                    htmlFor="Manufacturer"
                                                    className={classes.inputLabel}
                                                >
                                                    Manufacturer

                                                </InputLabel>
                                                <Select
                                                    keyboard="true"
                                                    input={(
                                                        <OutlinedInput
                                                            onChange={this.updateFormData}
                                                            name="manufacturer"
                                                            id="manufacturer"
                                                        />
                                                    )}
                                                    value={formData.manufacturer || ''}
                                                >
                                                    {currentManufacturers.map(manufacturer => (
                                                        <MenuItem
                                                            key={manufacturer.code}
                                                            value={manufacturer.code}
                                                        >
                                                            {manufacturer.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errors.manufacturer && (
                                                    <FormHelperText>{errors.manufacturer}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
 */}

                                        {/* <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <TextField
                                                className={classes.formControl}
                                                name="mobileTelephoneNumber"
                                                id="mobileTelephoneNumber"
                                                onChange={this.updateSendAgentDetailsData}
                                                value={sendAgentDetailsForm.mobileTelephoneNumber}
                                                label="Mobile Telephone Number"
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                error={!!errors.mobileTelephoneNumber}
                                                helperText={errors.mobileTelephoneNumber}
                                            />
                                        </Grid>
                                    </Grid> */}
                                        <Grid
                                            container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center"
                                        >
                                            <Grid item xs={12}>
                                                <Button
                                                    className={classes.dialogButton}
                                                    onClick={this.handleSendAgentDetails}
                                                    disabled={false}
                                                >
                                                    SEND
                                                </Button>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            </div>
                        </DialogContent>
                        <Divider />
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                onClick={handleAddItemCloseDialog}
                                color="primary"
                                autoFocus
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            </React.Fragment>

        );
    }
}

QuoteAddItemDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(QuoteAddItemDialog);
