export const SEATING_PRODUCT_CODE = 1001;
export const FLOORING_PRODUCT_CODE = 1003;
export const BLIND_PRODUCT_CODE = 1004;
export const BED_PRODUCT_CODE = 1000;

export const CARPET_SUB_CATEGORY_CODE = 1003;
export const VINYL_SUB_CATEGORY_CODE = 1004;
export const LAMINATE_SUB_CATEGORY_CODE = 1005;

export const ROLLER_BLIND_SUB_CATEGORY_CODE = 1001;
export const VENETIAN_BLIND_SUB_CATEGORY_CODE = 1002;
export const VERTICAL_BLIND_SUB_CATEGORY_CODE = 1000;
export const MIRAGE_BLIND_SUB_CATEGORY_CODE = 1007;
export const PERFECT_FIT_BLIND_SUB_CATEGORY_CODE = 1010;
