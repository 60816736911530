import React from 'react';
import {
    Divider,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

import fetch from '../utilities/fetch';
import Tile from '../containers/tile.js';

const removeFalseyValuesFromObject = obj => pickBy(obj, identity);

const styles = theme => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    divider: {
        marginTop: theme.spacing.unit * 2,
        // marginBottom: theme.spacing.unit * 2,
    }
});

class CustomerDetail extends React.Component {
    state = {
        error: false,
        loading: false,
        snackbarOpen: false,
        customer: {
            title: '',
            firstname: '',
            surname: '',
            address1: '',
            address2: '',
            townCity: '',
            county: '',
            postcode: '',
            country: '',
            homeTelephoneNumber: '',
            mobileTelephoneNumber: '',
            emailAddress: '',
            gdpr: '',
        },
        saved: false,
        errors: {},
    }

    async componentDidMount() {
        const url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/customer');
        const params = {
            contactCode: this.props.contactCode,
        };
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        const data = await fetch(url).then(response => response.json());
        this.setState({ customer: data });
    }

    handleNewAgreementClick = (contactCode) => {
        this.props.history.push(`/agreement/new?contactCode=${contactCode}`);
    }

    render() {
        const { classes, contactCode } = this.props;
        const { customer } = this.state;

        return (
            <React.Fragment>
                <Typography>
                    {customer.fullName}<br />
                    {customer.address1}<br />
                    {customer.address2 ? customer.address2+'<br />' :null}
                    {customer.townCity}<br />
                    {customer.county}<br />
                    {customer.postcode}<br />
                    {customer.country}<br />
                    <Divider className={classes.divider}/>
                    {customer.homeTelephoneNumber}<br />
                    {customer.mobileTelephoneNumber}<br />
                    {customer.emailAddress}<br />
                    {customer.gdpr}
                </Typography>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(CustomerDetail);
