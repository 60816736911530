import React, { isValidElement } from 'react';
import {
    Button,
    CssBaseline,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import findIndex from 'lodash/findIndex';
import { parse, isValid } from 'postcode';

import Layout from '../Layout';
import PageHeader from '../../components/pageHeader';
import PostcodeLookupDetail from '../../components/v2/postcodeLookupDetail';
import PostcodeLookupBranch from '../../components/v2/postcodeLookupBranch';
import PostcodeLookupAgent from '../../components/v2/postcodeLookupAgent';
import fetch, { fetchJson2 } from '../../utilities/fetch';
import sleep from '../../utilities/sleep';

const removeFalseyValuesFromObject = obj => pickBy(obj, identity);

const styles = theme => ({
});

class PostcodeLookup extends React.Component {
    state = {
        agents: null,
        agentsLoading: true,
        branches: null,
        branchesLoading: true,
        errors: {},
        foundAgents: null,
        foundBranches: null,
    };

    componentDidMount() {
        fetchJson2('v2/branches')
            .then((branches) => {
                console.log('>>> Branches:', branches.payload);
                this.setState({
                    branches: branches.payload,
                    branchesLoading: false,
                });
            });
        fetchJson2('v2/agents')
            .then((agents) => {
                console.log('>>> Agents: ', agents.payload);
                this.setState({
                    agents: agents.payload,
                    agentsLoading: false,
                });
            });
    }

    postcodeSearch = async (postcode) => {
        if (!isValid(postcode)) {
            this.setState({
                errors: {
                    postcode: 'Invalid Postcode',
                },
            });
            return;
        }
        const parsedPostcode = parse(postcode);
        const searchArea = parsedPostcode.area;
        const searchDistrict = parsedPostcode.district;

        // console.log('>>> ', searchArea, searchDistrict);
        // console.log('>>> agent:', this.state.agents.filter(agent => (
        //     agent.contactAgent.postcodeDistricts.find(postcodeDistrict => postcodeDistrict === searchArea))));

        this.setState(state => ({
            foundAgents: state.agents.filter(agent => (
                agent.contactAgent.postcodeDistricts.find(postcodeDistrict => postcodeDistrict === searchArea)
                || agent.contactAgent.postcodeDistricts.find(postcodeDistrict => postcodeDistrict === searchDistrict)
            )),
            foundBranches: state.branches.filter(branch => (
                branch.postcodeAreas.find(postcodeArea => postcodeArea === searchArea)
                || branch.postcodeAreas.find(postcodeArea => postcodeArea === searchDistrict)
            )),
        }));
    }

    sendAgentDetails = async (payload) => {
        let response;
        let body;

        // const content = `BEGIN:VCARD
        // VERSION:3.0
        // N:Spoor;Andrew
        // FN:Andrew Spoor
        // ORG:Pay Weekly Ltd
        // TITLE:Sales Agent
        // PHOTO;VALUE=URL;TYPE=PNG:https://pwc-email-images.s3-eu-west-1.amazonaws.com/logo-main-small.png
        // TEL;TYPE=WORK,VOICE:07508483027
        // EMAIL;TYPE=PREF,INTERNET:andrew.spoor@payweeklycarpets.co.uk
        // REV:001
        // END:VCARD`;
        const content = `Your local Pay Weekly sales agent is ${payload.agent.firstname} ${payload.agent.surname} - ${payload.agent.mobileTelephoneNumber}. Do not reply.`;
        const smsPayload = {
            content,
            to: payload.to,
        };

        try {
            response = await fetch('https://api.portal2.payweeklycarpets.co.uk/core/v2/sms', {
                body: JSON.stringify(smsPayload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            // this.setState({
            //     history: [
            //         body,
            //         ...this.state.history,
            //     ],
            // });
            return;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    }

    render() {
        const {
            agentsLoading,
            branchesLoading,
            errors,
            foundAgents,
            foundBranches,
        } = this.state;

        return (
            <Layout pageTitle="Postcode Lookup">
                <CssBaseline />
                <PostcodeLookupDetail
                    errors={errors}
                    postcodeSearch={this.postcodeSearch}
                />
                <PostcodeLookupAgent
                    foundAgents={foundAgents}
                    agentsLoading={agentsLoading}
                    errors={errors}
                    sendAgentDetails={this.sendAgentDetails}
                />
                <PostcodeLookupBranch
                    foundBranches={foundBranches}
                    branchesLoading={branchesLoading}
                    errors={errors}
                />
            </Layout>
        );
    }
}

export default withStyles(styles)(PostcodeLookup);
