import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import fetch, { fetchJson2 } from '../../utilities/fetch';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    dialogContentContainer: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    dialogContent: {
        minWidth: 600,
        paddingBottom: 16,
    },
    dialogButton: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 4,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        margin: '0px 0px 0px 0px !important',
        padding: '8px 24px',
    },
    dialogDivider: {
        marginBottom: theme.spacing.unit * 2,
        marginLeft: -24,
        marginRight: -24,
        marginTop: theme.spacing.unit * 2,
    },
    disabled: {
        opacity: 0.26,
    },
    dialogExpiredCheckBox: {
        textAlign: 'right',
    },
    checkBox: {
        fontSize: 16,
    },
    dialogTitle: {
        marginBottom: theme.spacing.unit * 2,
    },
});

class ManufacturerSupplierEditDialog extends React.Component {
    state = {
        disableEditManufacturerSupplierSaveButton: false,
        editManufacturerSupplierData: {},
        errors: [],
    };

    componentDidMount() {
        this.loadFormData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.manufacturerSupplier !== prevProps.manufacturerSupplier) {
            this.loadFormData();
        }
    }

    loadFormData = () => {
        const {
            code,
            name,
            manufacturer,
            supplier,
            telephoneNumber,
            faxNumber,
            emailAddress,
            accountReference,
            ourAccountReference,
            paymentTerms,
            address = {},
            expired,
        } = this.props.manufacturerSupplier;

        this.setState({
            editManufacturerSupplierData: {
                accountReference,
                address,
                code,
                emailAddress,
                expired,
                faxNumber,
                manufacturer,
                name,
                ourAccountReference,
                paymentTerms,
                supplier,
                telephoneNumber,
            },
        });
    };

    updateEditManufacturerSupplierData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        let addressData;
        switch (name) {
        case 'address1':
        case 'address2':
        case 'townCity':
        case 'county':
        case 'postcode':
        case 'country':
            addressData = {
                ...this.state.editManufacturerSupplierData.address,
                [name]: value,
            };
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            editManufacturerSupplierData: {
                ...state.editManufacturerSupplierData,
                [name]: formattedValue,
                address: {
                    ...state.editManufacturerSupplierData.address,
                    ...addressData,
                },
            },
        }));
    };

    handleEditManufacturerSupplier = () => {
        this.setState({
            disableEditManufacturerSupplierSaveButton: true,
        });
        const payload = this.state.editManufacturerSupplierData;
        this.editManufacturerSupplier(payload).then(() => {
            this.setState({
                disableEditManufacturerSupplierSaveButton: false,
            });
            this.props.handleCloseEditManufacturerSupplierDialogClick();
            this.props.enqueueSnackbar('Manufacturer/Supplier Save', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Save Manufacturer/Supplier Failed', { variant: 'error' });
            this.setState({
                disableEditManufacturerSupplierSaveButton: false,
            });
        });
    };

    updateEditManufacturerSupplierDataCheckbox = (name) => (event) => {
        const newState = !!event.target.checked;
        this.setState((state) => ({
            editManufacturerSupplierData: {
                ...state.editManufacturerSupplierData,
                [name]: newState,
            },
        }));
    };

    editManufacturerSupplier = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/manufacturerSupplier/${payload.code}`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
              && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newManufacturerSupplier = {
                ...body.manufacturerSupplier,
                histories: [
                    body.history,
                    ...this.props.manufacturerSupplier.histories,
                ],
            };
            this.props.passNewManufacturerSupplier(newManufacturerSupplier);
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    render() {
        const {
            classes,
            handleCloseEditManufacturerSupplierDialogClick,
            manufacturerSupplierLoading,
            showEditManufacturerSupplierDialog,
        } = this.props;

        const {
            disableEditManufacturerSupplierSaveButton,
            editManufacturerSupplierData,
            errors,
        } = this.state;

        return (
            <>
                {!manufacturerSupplierLoading
                    ? (
                        <Dialog
                            open={showEditManufacturerSupplierDialog}
                            onClose={() => handleCloseEditManufacturerSupplierDialogClick()}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            className={classes.newManufacturerDialog}
                        >
                            <DialogContent className={classes.dialogContent}>
                                <div className={classes.dialogContentContainer}>
                                    <>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            className={classes.dialogTitle}
                                        >
                                            Edit Manufacturer Supplier
                                        </Typography>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    className={classes.formControl}
                                                    name="name"
                                                    id="name"
                                                    onChange={this.updateEditManufacturerSupplierData}
                                                    value={editManufacturerSupplierData.name}
                                                    label="Company Name"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    error={!!errors.name}
                                                    helperText={errors.name}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl component="fieldset" className={classes.formControl}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={(
                                                                <Checkbox
                                                                    name="manufacturer"
                                                                    id="manufacturer"
                                                                    onChange={this.updateEditManufacturerSupplierDataCheckbox('manufacturer')}
                                                                    checked={editManufacturerSupplierData.manufacturer}
                                                                    value={editManufacturerSupplierData.manufacturer}
                                                                />
                                                            )}
                                                            classes={{ label: classes.checkBox }}
                                                            label="Manufacturer"
                                                        />
                                                        <FormControlLabel
                                                            control={(
                                                                <Checkbox
                                                                    name="supplier"
                                                                    id="supplier"
                                                                    onChange={this.updateEditManufacturerSupplierDataCheckbox('supplier')}
                                                                    checked={editManufacturerSupplierData.supplier}
                                                                    value={editManufacturerSupplierData.supplier}
                                                                />
                                                            )}
                                                            classes={{ label: classes.checkBox }}
                                                            label="Supplier"
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        {editManufacturerSupplierData.supplier
                                            ? (
                                                <>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="address1"
                                                                id="address1"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={(editManufacturerSupplierData.address || {}).address1}
                                                                label="Address Line 1"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.address1}
                                                                helperText={errors.address1}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="address2"
                                                                id="address2"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={(editManufacturerSupplierData.address || {}).address2}
                                                                label="Address Line 2"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                error={!!errors.address2}
                                                                helperText={errors.address2}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="townCity"
                                                                id="townCity"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={(editManufacturerSupplierData.address || {}).townCity}
                                                                label="Town/City"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.townCity}
                                                                helperText={errors.townCity}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="county"
                                                                id="county"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={(editManufacturerSupplierData.address || {}).county}
                                                                label="County"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.county}
                                                                helperText={errors.county}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="postcode"
                                                                id="postcode"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={(editManufacturerSupplierData.address || {}).postcode}
                                                                label="Postcode"
                                                                margin="normal"
                                                                variant="outlined"
                                                                required
                                                                error={!!errors.postcode}
                                                                helperText={errors.postcode}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="country"
                                                                id="country"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={(editManufacturerSupplierData.address || {}).country}
                                                                label="Country"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.country}
                                                                helperText={errors.country}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Divider className={classes.dialogDivider} />
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="telephoneNumber"
                                                                id="telephoneNumber"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={editManufacturerSupplierData.telephoneNumber}
                                                                label="Telephone Number"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.telephoneNumber}
                                                                helperText={errors.telephoneNumber}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="faxNumber"
                                                                id="faxNumber"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={editManufacturerSupplierData.faxNumber}
                                                                label="Fax Number"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                error={!!errors.faxNumber}
                                                                helperText={errors.faxNumber}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="emailAddress"
                                                                id="emailAddress"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={editManufacturerSupplierData.emailAddress}
                                                                label="Email Address"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.emailAddress}
                                                                helperText={errors.emailAddress}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="accountReference"
                                                                id="accountReference"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={editManufacturerSupplierData.accountReference}
                                                                label="Account Reference"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.accountReference}
                                                                helperText={errors.accountReference}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="ourAccountReference"
                                                                id="ourAccountReference"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={editManufacturerSupplierData.ourAccountReference}
                                                                label="Our Account Reference"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.ourAccountReference}
                                                                helperText={errors.ourAccountReference}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="paymentTerms"
                                                                id="paymentTerms"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={editManufacturerSupplierData.paymentTerms}
                                                                label="Payment Terms"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.paymentTerms}
                                                                helperText={errors.paymentTerms}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                </>
                                            )
                                            : ''}
                                        <Grid
                                            container
                                            spacing={0}
                                            direction="column"
                                            className={classes.dialogExpiredCheckBox}
                                        >
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={(
                                                        <Checkbox
                                                            name="expired"
                                                            id="expired"
                                                            onChange={this.updateEditManufacturerSupplierDataCheckbox('expired')}
                                                            checked={editManufacturerSupplierData.expired}
                                                            value={editManufacturerSupplierData.expired}
                                                        />
                                                    )}
                                                    classes={{ label: classes.checkBox }}
                                                    label="Expired"
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center"
                                        >
                                            <Grid item xs={12}>
                                                <Button
                                                    className={classes.dialogButton}
                                                    type="submit"
                                                    onClick={this.handleEditManufacturerSupplier}
                                                    disabled={disableEditManufacturerSupplierSaveButton
                                                || (!editManufacturerSupplierData.manufacturer && !editManufacturerSupplierData.supplier)}
                                                >
                                                    SAVE
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </>
                                </div>
                            </DialogContent>
                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    onClick={() => handleCloseEditManufacturerSupplierDialogClick()}
                                    color="primary"
                                    autoFocus
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                    : ''}
            </>

        );
    }
}

export default withSnackbar(withStyles(styles)(ManufacturerSupplierEditDialog));
