import React, { isValidElement } from 'react';
import {
    Button,
    CssBaseline,
    Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import findIndex from 'lodash/findIndex';
import { parse, isValid } from 'postcode';
import HeatMap from '../../components/v2/heatMap';

import Layout from '../Layout';
import fetch, { fetchJson2 } from '../../utilities/fetch';
import sleep from '../../utilities/sleep';

const removeFalseyValuesFromObject = (obj) => pickBy(obj, identity);

const styles = (theme) => ({
});

class SalesMap extends React.Component {
    state = {
        reportData: null,
        reportDataLoading: true,
    };

    componentDidMount() {
        const options = {
            radius: 17,
            opacity: 0.6,
            dissipating: true,
            maxIntensity: 15,
        };

        fetchJson2('v2/agreements/report?startDate=2023-03-01&endDate=2023-03-31')
            .then((reportData) => {
                this.setState({
                    reportData: {
                        positions: reportData.payload,
                        options,
                    },
                    reportDataLoading: false,
                });
            });
    }

    render() {
        const {
            reportData,
            reportDataLoading,
        } = this.state;

        console.log('>>> reportData:', reportData);

        return (
            <Layout pageTitle="New Agreements Heat Map">
                <CssBaseline />
                <Paper className={classes.paper} style={{ height: '800px', width: '1000px' }}>
                    <HeatMap
                        reportData={reportData}
                        reportDataLoading={reportDataLoading}
                    />
                </Paper>
            </Layout>
        );
    }
}

export default withStyles(styles)(SalesMap);
