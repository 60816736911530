import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
    isEmpty,
} from 'lodash';
import Barcode from 'react-barcode';
import moment from 'moment-timezone';
import fetch, { fetchJson2 } from '../../utilities/fetch';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    dialogContentContainer: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    dialogContent: {
        minWidth: 600,
        paddingBottom: 16,
    },
    dialogButton: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 4,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        margin: '0px 0px 0px 0px !important',
        padding: '8px 24px',
    },
    dialogDivider: {
        marginBottom: theme.spacing.unit * 2,
        marginLeft: -24,
        marginRight: -24,
        marginTop: theme.spacing.unit * 2,
    },
    disabled: {
        opacity: 0.26,
    },
    dialogExpiredCheckBox: {
        textAlign: 'right',
    },
    checkBox: {
        fontSize: 16,
    },
    dialogTitle: {
        marginBottom: theme.spacing.unit * 2,
    },
    barcode: {
        marginLeft: 150,
    },
    dimensionX: {
        paddingLeft: 43,
        paddingTop: 33,
    },
    bumpLeft2: {
        marginLeft: theme.spacing.unit * 2,
    },
    alreadyAssigned: {
        boxShadow: '0 0 0 3px #FF0000, 0 0 0 2px #FF0000 inset',
        border: '2px solid transparent',
        borderRadius: 4,
        display: 'inline-block',
        padding: '5px 2px',
        color: '#FF0000',
        fontSize: 48,
        textTransform: 'uppercase',
        textAlign: 'center',
        opacity: 0.6,
        fontWeight: 900,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'rotate(-5deg) translate(-50%,-50%)',
        minWidth: '80%',
        lineHeight: 1.2,
    },
});

class CuttingItemDetailDialog extends React.Component {
    state = {
        disableEditManufacturerSupplierSaveButton: false,
        editManufacturerSupplierData: {},
        errors: [],
    };

    componentDidMount() {
    }

    render() {
        const {
            classes,
            handleCloseCuttingItemDetailDialogClick,
            visitItem,
            visitItemLoading,
            showCuttingItemDetailDialog,
        } = this.props;

        const {
        } = this.state;

        return (
            <>
                {!visitItemLoading && !isEmpty(visitItem)
                    ? (
                        <Dialog
                            open={showCuttingItemDetailDialog}
                            onClose={() => handleCloseCuttingItemDetailDialogClick()}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            className={classes.newManufacturerDialog}
                        >
                            <DialogContent className={classes.dialogContent}>
                                <div className={classes.dialogContentContainer}>
                                    <>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            className={classes.dialogTitle}
                                        >
                                            Visit Item Detail
                                        </Typography>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    className={classes.formControl}
                                                    name="manufacturer"
                                                    id="manufacturer"
                                                    value={visitItem.agreementItem.product.manufacturer}
                                                    label="Manufacturer"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    className={classes.formControl}
                                                    name="modelNo"
                                                    id="modelNo"
                                                    value={visitItem.agreementItem.product.modelNo}
                                                    label="Model"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <TextField
                                                    className={classes.formControl}
                                                    name="width"
                                                    id="width"
                                                    value={`${visitItem.agreementItem.width.toFixed(1)}m`}
                                                    label="Width"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={2} className={classes.dimensionX}>x</Grid>
                                            <Grid item xs={5}>
                                                <TextField
                                                    className={classes.formControl}
                                                    name="length"
                                                    id="length"
                                                    value={`${visitItem.agreementItem.length.toFixed(1)}m`}
                                                    label="Length"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    className={classes.formControl}
                                                    name="agreementItemCode"
                                                    id="agreementItemCode"
                                                    label="Agreement Item Code"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    rows={8}
                                                    InputProps={{
                                                        startAdornment:
                            <InputAdornment
                                disableTypography
                                className={classes.inputAdornment}
                                // position="middle"
                            >
                                <div className={classes.barcode}>
                                    {visitItem.agreementItem.itemCode
                                        ? (
                                            <div className={classes.alreadyAssignedWrapper}>
                                                <div className={classes.alreadyAssigned}>
                                                    ALREADY ASSIGNED
                                                </div>
                                            </div>
                                        )
                                        : (
                                            <Barcode value={`RAI${visitItem.agreementItem.code}`} />
                                        )}
                                </div>
                            </InputAdornment>,
                                                    }}
                                                />
                                            </Grid>
                                            {visitItem.agreementItem.item
                                                ? (
                                                    <>
                                                        <Divider />
                                                        <Grid item xs={5}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="itemCode"
                                                                id="itemCode"
                                                                value={`SC${visitItem.agreementItem.item.code}`}
                                                                label="Stock Code"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="cutBy"
                                                                id="cutBy"
                                                                value={visitItem.agreementItem.item.createdBy}
                                                                label="Cut By"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="createdDateTime"
                                                                id="createdDateTime"
                                                                value={moment(visitItem.agreementItem.item.createdDateTime).format('HH:mm DD/MM/YYYY')}
                                                                label="Cut Time/Date"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="branch"
                                                                id="branch"
                                                                value={visitItem.agreementItem.item.branch}
                                                                label="Branch"
                                                                margin="normal"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3} className={classes.bumpLeft2}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="stockroom"
                                                                id="stockroom"
                                                                value={visitItem.agreementItem.item.stockroom}
                                                                label="Stockroom"
                                                                margin="normal"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3} className={classes.bumpLeft2}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="bin"
                                                                id="bin"
                                                                value={visitItem.agreementItem.item.bin}
                                                                label="BIN"
                                                                margin="normal"
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                    </>
                                                ) : ''}
                                            {/* <Grid item xs={12}>
                                                <FormControl component="fieldset" className={classes.formControl}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={(
                                                                <Checkbox
                                                                    name="manufacturer"
                                                                    id="manufacturer"
                                                                    onChange={this.updateEditManufacturerSupplierDataCheckbox('manufacturer')}
                                                                    checked={editManufacturerSupplierData.manufacturer}
                                                                    value={editManufacturerSupplierData.manufacturer}
                                                                />
                                                            )}
                                                            classes={{ label: classes.checkBox }}
                                                            label="Manufacturer"
                                                        />
                                                        <FormControlLabel
                                                            control={(
                                                                <Checkbox
                                                                    name="supplier"
                                                                    id="supplier"
                                                                    onChange={this.updateEditManufacturerSupplierDataCheckbox('supplier')}
                                                                    checked={editManufacturerSupplierData.supplier}
                                                                    value={editManufacturerSupplierData.supplier}
                                                                />
                                                            )}
                                                            classes={{ label: classes.checkBox }}
                                                            label="Supplier"
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        {editManufacturerSupplierData.supplier
                                            ? (
                                                <>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="address1"
                                                                id="address1"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={(editManufacturerSupplierData.address || {}).address1}
                                                                label="Address Line 1"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.address1}
                                                                helperText={errors.address1}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="address2"
                                                                id="address2"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={(editManufacturerSupplierData.address || {}).address2}
                                                                label="Address Line 2"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                error={!!errors.address2}
                                                                helperText={errors.address2}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="townCity"
                                                                id="townCity"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={(editManufacturerSupplierData.address || {}).townCity}
                                                                label="Town/City"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.townCity}
                                                                helperText={errors.townCity}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="county"
                                                                id="county"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={(editManufacturerSupplierData.address || {}).county}
                                                                label="County"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.county}
                                                                helperText={errors.county}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="postcode"
                                                                id="postcode"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={(editManufacturerSupplierData.address || {}).postcode}
                                                                label="Postcode"
                                                                margin="normal"
                                                                variant="outlined"
                                                                required
                                                                error={!!errors.postcode}
                                                                helperText={errors.postcode}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="country"
                                                                id="country"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={(editManufacturerSupplierData.address || {}).country}
                                                                label="Country"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.country}
                                                                helperText={errors.country}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Divider className={classes.dialogDivider} />
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="telephoneNumber"
                                                                id="telephoneNumber"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={editManufacturerSupplierData.telephoneNumber}
                                                                label="Telephone Number"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.telephoneNumber}
                                                                helperText={errors.telephoneNumber}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="faxNumber"
                                                                id="faxNumber"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={editManufacturerSupplierData.faxNumber}
                                                                label="Fax Number"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                error={!!errors.faxNumber}
                                                                helperText={errors.faxNumber}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="emailAddress"
                                                                id="emailAddress"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={editManufacturerSupplierData.emailAddress}
                                                                label="Email Address"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.emailAddress}
                                                                helperText={errors.emailAddress}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="accountReference"
                                                                id="accountReference"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={editManufacturerSupplierData.accountReference}
                                                                label="Account Reference"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.accountReference}
                                                                helperText={errors.accountReference}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="ourAccountReference"
                                                                id="ourAccountReference"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={editManufacturerSupplierData.ourAccountReference}
                                                                label="Our Account Reference"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.ourAccountReference}
                                                                helperText={errors.ourAccountReference}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="paymentTerms"
                                                                id="paymentTerms"
                                                                onChange={this.updateEditManufacturerSupplierData}
                                                                value={editManufacturerSupplierData.paymentTerms}
                                                                label="Payment Terms"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.paymentTerms}
                                                                helperText={errors.paymentTerms}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                </>
                                            )
                                            : ''}
                                        <Grid
                                            container
                                            spacing={0}
                                            direction="column"
                                            className={classes.dialogExpiredCheckBox}
                                        >
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={(
                                                        <Checkbox
                                                            name="expired"
                                                            id="expired"
                                                            onChange={this.updateEditManufacturerSupplierDataCheckbox('expired')}
                                                            checked={editManufacturerSupplierData.expired}
                                                            value={editManufacturerSupplierData.expired}
                                                        />
                                                    )}
                                                    classes={{ label: classes.checkBox }}
                                                    label="Expired"
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center"
                                        >
                                            <Grid item xs={12}>
                                                <Button
                                                    className={classes.dialogButton}
                                                    type="submit"
                                                    onClick={this.handleEditManufacturerSupplier}
                                                    disabled={disableEditManufacturerSupplierSaveButton
                                                || (!editManufacturerSupplierData.manufacturer && !editManufacturerSupplierData.supplier)}
                                                >
                                                    SAVE
                                                </Button>
                                            </Grid>
                                        </Grid> */}
                                        </Grid>
                                    </>
                                </div>
                            </DialogContent>
                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    onClick={() => handleCloseCuttingItemDetailDialogClick()}
                                    color="primary"
                                    autoFocus
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                    : ''}
            </>

        );
    }
}

export default withSnackbar(withStyles(styles)(CuttingItemDetailDialog));
