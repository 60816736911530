import React from 'react';
import {
    Button,
    CssBaseline,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import findIndex from 'lodash/findIndex';

import { ContactsOutlined } from '@material-ui/icons';
import moment from 'moment-timezone';
import Layout from '../Layout';
import PageHeader from '../../components/pageHeader';
import PurchaseOrderDetails from '../../components/v2/purchaseOrderDetails';
// import ManufacturerSupplierTabs from '../../components/v2/manufacturerSupplierTabs';
import PurchaseOrderBadges from '../../components/v2/purchaseOrderBadges';
import fetch, { fetchJson2 } from '../../utilities/fetch';
import sleep from '../../utilities/sleep';

const { REACT_APP_API_SERVER } = process.env;

const removeFalseyValuesFromObject = (obj) => pickBy(obj, identity);
const now = moment.tz(moment(), 'Europe/London');
const currentDateTime = moment(now).format('YYYY-MM-DD HH:mm');

const styles = (theme) => ({
});

class PurchaseOrder extends React.Component {
    state = {
        errors: {},
        purchaseOrder: {},
        purchaseOrderCode: null,
        purchaseOrderHistoriesLoading: true,
        purchaseOrderLoading: true,
    };

    static getDerivedStateFromProps(props) {
        const { purchaseOrderCode } = props.match.params;
        return {
            purchaseOrderCode,
        };
    }

    componentDidMount() {
        const { purchaseOrderCode } = this.state;
        this.fetchPurchaseOrderViewData(purchaseOrderCode);
    }

    componentDidUpdate = async (prevProps) => {
        if (this.props.match.params.purchaseOrderCode !== prevProps.match.params.purchaseOrderCode) {
            await this.setDefaults();
            this.fetchPurchaseOrderViewData(this.props.match.params.purchaseOrderCode);
            window.scrollTo(0, 0);
        }
    };

    setDefaults = async () => {
        this.setState({
            errors: {},
            purchaseOrder: null,
            purchaseOrderCode: null,
            purchaseOrderLoading: true,
        });
    };

    fetchPurchaseOrderViewData = async (purchaseOrderCode) => {
        const purchaseOrderData = this.fetchPurchaseOrderData();
        purchaseOrderData.then(() => { // Dependent on the purchaseOrder
            this.fetchHistoriesData();
        });

        this.setState({ purchaseOrderCode });
    };

    fetchPurchaseOrderData = async () => {
        const { purchaseOrderCode } = this.state;
        this.setState({
            purchaseOrderLoading: true,
        });

        try {
            const [purchaseOrder] = await Promise.all([fetchJson2(`v2/purchaseOrder/${purchaseOrderCode}`), sleep(500)]);

            this.setState((state) => ({
                purchaseOrder: {
                    ...state.purchaseOrder,
                    ...purchaseOrder.payload,
                },
            }));
        } finally {
            this.setState({
                purchaseOrderLoading: false,
            });
        }
    };

    fetchHistoriesData = async () => {
        const { purchaseOrderCode } = this.state;
        this.setState({
            purchaseOrderHistoriesLoading: true,
        });

        try {
            const [histories] = await Promise.all([fetchJson2(`v2/purchaseOrder/${purchaseOrderCode}/histories`), sleep(500)]);

            this.setState((state) => ({
                purchaseOrder: {
                    ...state.purchaseOrder,
                    histories: histories.payload,
                },
            }));
        } finally {
            this.setState({
                purchaseOrderHistoriesLoading: false,
            });
        }
    };

    passNewPurchaseOrder = (newPurchaseOrder) => {
        console.log('>>> newPurchaseOrder:', newPurchaseOrder);
        this.setState({
            purchaseOrder: newPurchaseOrder,
        });
    };

    sendPurchaseOrder = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/purchaseOrder/${this.state.purchaseOrder.code}/send`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                purchaseOrder: {
                    ...body.purchaseOrder,
                    histories: [
                        body.history,
                        ...state.purchaseOrder.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    authorisePurchaseOrder = async () => {
        let response;
        let body;
        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/purchaseOrder/${this.state.purchaseOrder.code}/authorise`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            this.setState((state) => ({
                purchaseOrder: {
                    ...body.purchaseOrder,
                    histories: [
                        body.history,
                        ...state.purchaseOrder.histories,
                    ],
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    render() {
        const {
        } = this.props;
        const {
            errors,
            purchaseOrder,
            purchaseOrderHistoriesLoading,
            purchaseOrderLoading,
        } = this.state;

        const pageHeaderContent = purchaseOrderLoading
            ? {
                leftText: ' ',
                leftTitle: 'Loading...',
            } : {
                leftText: `${purchaseOrder.deliveryBranch.name} (${purchaseOrder.createdBy})`,
                leftTitle: `${purchaseOrder.supplier.name === null ? '' : purchaseOrder.supplier.name}`,
                rightTitle: `PO${purchaseOrder.code}`,
            };

        return (
            <Layout pageTitle="Purchase Order">
                <CssBaseline />
                <PageHeader
                    content={pageHeaderContent}
                    // history={this.props.history}
                />
                <PurchaseOrderBadges
                    purchaseOrder={purchaseOrder}
                    purchaseOrderLoading={purchaseOrderLoading}
                />
                {/* <ManufacturerSupplierTabs
                    errors={errors}
                    fetchPurchaseOrderData={this.fetchPurchaseOrderData}
                    manufacturerSupplier={manufacturerSupplier}
                    purchaseOrderHistoriesLoading={purchaseOrderHistoriesLoading}
                    purchaseOrderLoading={purchaseOrderLoading}
                    passNewManufacturerSupplier={this.passNewManufacturerSupplier}
                /> */}
                <PurchaseOrderDetails
                    authorisePurchaseOrder={this.authorisePurchaseOrder}
                    errors={errors}
                    fetchHistoriesData={this.fetchHistoriesData}
                    fetchPurchaseOrderData={this.fetchPurchaseOrderData}
                    purchaseOrder={purchaseOrder}
                    purchaseOrderHistoriesLoading={purchaseOrderHistoriesLoading}
                    purchaseOrderLoading={purchaseOrderLoading}
                    passNewPurchaseOrder={this.passNewPurchaseOrder}
                    sendPurchaseOrder={this.sendPurchaseOrder}
                />

            </Layout>
        );
    }
}

export default withStyles(styles)(PurchaseOrder);
