import React from 'react';
import {
    Button,
    CssBaseline,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

import Layout from './Layout';
import PageHeader from '../components/pageHeader';
import ContactForm from '../components/contactForm';
import CustomerDetail from '../components/customerDetail';
import CustomerAgreements from '../components/customerAgreements';
import fetch from '../utilities/fetch';
import Tile from '../containers/tile.js';

const removeFalseyValuesFromObject = obj => pickBy(obj, identity);

const styles = theme => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
});

class Customer extends React.Component {
    state = {
        error: false,
        loading: false,
        snackbarOpen: false,
        contactCode: null,
        customer: {
            title: '',
            firstname: '',
            surname: '',
            address1: '',
            address2: '',
            townCity: '',
            county: '',
            postcode: '',
            country: '',
            homeTelephoneNumber: '',
            mobileTelephoneNumber: '',
            emailAddress: '',
            gdpr: '',
        },
        saved: false,
        errors: {},
    }

    static getDerivedStateFromProps(props, state) {
      const contactCode = props.match.params.contactCode;
      return {
        contactCode,
      }
    }

    async componentDidMount() {
        const url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/customer');
        const contactCode = this.state.contactCode;
        const params = {
            contactCode: contactCode,
        };
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        const data = await fetch(url).then(response => response.json());
        console.log(data);
        this.setState({ contactCode: contactCode });
        this.setState({ customer: data });
    }

    handleNewAgreementClick = (contactCode) => {
        this.props.history.push(`/agreement/new?contactCode=${contactCode}`);
    }

    handleSnackbarClose = (event, reason) => {
        this.setState({
            snackbarOpen: false,
        });
    }

    updateLeadData = (event) => {
        const { value, name } = event.target;

        this.setState({
            lead: {
                ...this.state.lead,
                [name]: value,
            },
        });
    }

    handleNewCustomerPageClick = () => {
        this.props.history.push(`/customer/${this.state.contactCode}`);
    }

    render() {
        const { classes } = this.props;
        const { customer, saved, contactCode } = this.state;

        const pageHeaderContent = {
            leftTitle: `${customer.title} ${customer.firstname} ${customer.surname}`,
            leftText: customer.fullAddress,
        };

        return (
            <Layout pageTitle="Customer">
                <CssBaseline />
                <PageHeader content={pageHeaderContent} />
                    <Button
                        variant="contained"
                        onClick={this.handleNewCustomerPageClick}
                    >
                        NEW CUSTOMER PAGE
                    </Button>
                    <Tile tileTitle="Customer Details">
                        <CustomerDetail contactCode={contactCode}/>
                    </Tile>
                    <CustomerAgreements contactCode={contactCode}/>

            </Layout>
        );
    }
}

export default withStyles(styles)(Customer);
