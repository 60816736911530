import React from 'react';
import {
    CssBaseline,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Layout from './Layout';
import fetch, { fetchJson } from '../utilities/fetch';
import ItemChildren from '../components/newItem/itemChildren';

const styles = theme => ({
//
});

class Item extends React.Component {
    state = {
        item: null,
        itemChildren: null,
        errors: {},
    }

    static getDerivedStateFromProps(props) {
        const itemCode = props.match.params.itemCode;
        return {
            itemCode,
        };
    }

    async componentDidMount() {
        const { itemCode } = this.state;

        fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/item/${itemCode}`)
            .then((response) => {
                this.setState({
                    item: response,
                });
            });

        fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/item/${itemCode}/children`)
            .then((response) => {
                this.setState({
                    itemChildren: response,
                });
            });

        // await this.fetchMenus();
    }

    // fetchMenus = () => {
    //     const fetchProducts = fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/products?continuousRental=1&repaymentRental=1&sale=1')
    //         .then((products) => {
    //             this.setState({
    //                 products,
    //             });
    //         });
    //     return Promise.all([fetchProducts]);
    // }

    // addDeliveryNoteItem = async (payload) => {
    //     let response;
    //     let body;
    //
    //     try {
    //         response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/deliveryNote/${this.state.deliveryNoteCode}/item`, {
    //             method: 'POST',
    //             body: JSON.stringify(payload),
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         });
    //     } catch (e) {
    //         // something went really wrong; timeout/ blocked by client etc
    //         // debugger;
    //     }
    //
    //     if (response.headers.get('content-type')
    //       && response.headers.get('content-type').search('application/json') >= 0) {
    //         body = await response.json();
    //     }
    //
    //     switch (response.status) {
    //     case 200: {
    //         this.setState(state => ({
    //             deliveryNoteItems: [
    //                 ...body,
    //                 ...state.deliveryNoteItems,
    //             ],
    //         }));
    //         break;
    //     }
    //     default: {
    //         throw new Error({ error: 'Generic API error' });
    //     }
    //     }
    // }
    //
    createChildItem = async (payload) => {
        console.log('Handling Child Item Create');
        let response;
        let body;

        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/item/${this.state.itemCode}/birth`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState(state => ({
                itemChildren: [
                    body.itemChild,
                    ...state.itemChildren,
                ],
            }));
            console.log(body);
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    }

    render() {
        const {
            item,
            itemChildren,
            errors,
        } = this.state;

        return (
            <Layout pageTitle="Item">
                <CssBaseline />
                <ItemChildren
                    errors={errors}
                    item={item}
                    itemChildren={itemChildren}
                    createChildItem={this.createChildItem}
                />
            </Layout>
        );
    }
}

export default withStyles(styles)(Item);
