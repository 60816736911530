import React from 'react';
import {
    // Button,
    // Checkbox,
    CircularProgress,
    // Dialog,
    // DialogActions,
    // DialogContent,
    // DialogContentText,
    Divider,
    // FormControl,
    // FormControlLabel,
    // FormHelperText,
    // FormLabel,
    Grid,
    // InputLabel,
    // MenuItem,
    // OutlinedInput,
    // Paper,
    // Radio,
    // RadioGroup,
    // Select,
    // TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import moment from 'moment-timezone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import HybridTextField from '../hybridTextField';
import titalise from '../../utilities/titalise';
import Tile from '../../containers/tile';

const styles = theme => ({
    // row: {
    //     '&:nth-of-type(odd)': {
    //         backgroundColor: theme.palette.background.default,
    //     },
    // },
    // tableHead: {
    //     fontWeight: 'bold',
    //     fontSize: 14,
    // },
    // paper: {
    //     width: '100%',
    //     marginTop: theme.spacing.unit * 3,
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     overflowX: 'auto',
    //     padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    // },
    // tileBottomDivider: {
    //     marginTop: theme.spacing.unit * 3,
    //     // marginBottom: theme.spacing.unit * 2,
    // },
    // dialogDivider: {
    //     marginTop: theme.spacing.unit * 2.5,
    //     marginBottom: theme.spacing.unit * 2,
    // },
    // bottomBarContainer: {
    //     width: '100%',
    //     textAlign: 'right',
    // },
    // textButton: {
    //     marginTop: theme.spacing.unit * 1.5,
    //     marginBottom: 0,
    // },
    // dialogActions: {
    //     marginRight: theme.spacing.unit * 2.5,
    // },
    // nameField: {
    //     width: 350,
    // },
    // addressField: {
    //     width: 250,
    // },
    // postcodeField: {
    //     width: 120,
    // },
    // countryField: {
    //     width: 200,
    //     marginLeft: theme.spacing.unit * 3,
    // },
    // emailField: {
    //     width: 350,
    // },
    // rightOfFieldButton: {
    //     marginTop: 10,
    //     marginLeft: theme.spacing.unit * 3,
    //     color: 'rgba(0, 121, 255, 0.87)',
    // },
    // dialogContentContainer: {
    //     marginTop: theme.spacing.unit * 2,
    //     marginBottom: theme.spacing.unit * 2,
    // },
    // dialogButton: {
    //     marginTop: theme.spacing.unit * 2,
    //     marginBottom: theme.spacing.unit * 1,
    // },
    // dialogContent: {
    //     paddingBottom: 0,
    //     minWidth: 300,
    // },
    // formHelperText: {
    //     color: '#f44336',
    // },
    // contactLocationIcon: {
    //     color: 'red',
    //     fontSize: 30,
    //     position: 'absolute',
    //     transform: 'translate(-50%, -100%)',
    // },
    // branchLocationIcon: {
    //     color: 'blue',
    //     fontSize: 30,
    //     position: 'absolute',
    //     transform: 'translate(-50%, -100%)',
    // },
    // progressContainer: {
    //     marginLeft: 'auto',
    //     marginRight: 'auto',
    //     marginTop: theme.spacing.unit * 3,
    //     marginBottom: theme.spacing.unit * 3,
    //     width: 60,
    // },
    // progress: {
    //     opacity: '.5',
    // },
    // inputLabel: {
    //     backgroundColor: '#fff',
    //     paddingLeft: 8,
    //     marginLeft: -5,
    //     paddingRight: 8,
    // },
    // menuFormControl: {
    //     marginBottom: theme.spacing.unit * 1,
    //     marginTop: theme.spacing.unit * 2,
    // },
    // agents: {
    //     marginRight: theme.spacing.unit * 3,
    // },
    // 'agents:not(:first-child)': {
    //     marginLeft: 0,
    //     backgroundColor: 'red',
    // },
    tileDivider: {
        marginBottom: theme.spacing.unit * 2.5,
        marginLeft: theme.spacing.unit * -4,
        marginRight: theme.spacing.unit * -4,
        marginTop: theme.spacing.unit * 2,
    },
});

class PostcodeLookupBranch extends React.Component {
    state = {
    }


    render() {
        const {
            branchesLoading,
            classes,
            errors,
            foundBranches,
        } = this.props;
        const {
        } = this.state;

        return (
            <Tile
                tileTitle="Branch"
                style
            >
                { !branchesLoading
                    ? (
                        <React.Fragment>
                            {foundBranches !== null
                                ? (
                                    <React.Fragment>
                                        {foundBranches.map((foundBranch, i) => (
                                            <React.Fragment>
                                                {i > 0
                                                    ? (
                                                        <Divider className={classes.tileDivider} />
                                                    )
                                                    : ''
                                                }
                                                <Grid container spacing={0}>
                                                    <Grid item xs={4}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField label="Name" text={foundBranch.name} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={4}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField label="Manager" text={`${foundBranch.manager.firstname} ${foundBranch.manager.surname}`} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField label="Manager Mobile Telephone Number" text={foundBranch.manager.mobileTelephoneNumber} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField label="Manager Email Address" text={foundBranch.manager.emailAddress} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <HybridTextField label="Postcode Districts" text={foundBranch.postcodeAreas.join(', ')} />
                                                    </Grid>
                                                </Grid>
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                )
                                : (
                                    <Typography variant="body1">
                                        No branch found
                                    </Typography>
                                )
                            }
                        </React.Fragment>
                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )
                }
            </Tile>
        );
    }
}

const formShape = {
};

PostcodeLookupBranch.propTypes = {
    classes: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(PostcodeLookupBranch));
