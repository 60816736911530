import React from 'react';
import {
    CssBaseline,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

import Layout from '../Layout';
import PageHeader from '../../components/pageHeader';
import ManufacturerSupplierTabs from '../../components/v2/manufacturerSupplierTabs';
import ManufacturerSupplierBadges from '../../components/v2/manufacturerSupplierBadges';
import fetch, { fetchJson2 } from '../../utilities/fetch';
import sleep from '../../utilities/sleep';

const { REACT_APP_API_SERVER } = process.env;

const removeFalseyValuesFromObject = (obj) => pickBy(obj, identity);

const styles = (theme) => ({
});

class ManufacturerSupplier extends React.Component {
    state = {
        errors: {},
        manufacturerSupplier: {},
        manufacturerSupplierCode: null,
        manufacturerSupplierHistoriesLoading: true,
        manufacturerSupplierLoading: true,
    };

    static getDerivedStateFromProps(props) {
        const { manufacturerSupplierCode } = props.match.params;
        return {
            manufacturerSupplierCode,
        };
    }

    componentDidMount() {
        const { manufacturerSupplierCode } = this.state;
        this.fetchManufacturerSupplierViewData(manufacturerSupplierCode);
    }

    componentDidUpdate = async (prevProps) => {
        if (this.props.match.params.manufacturerSupplierCode !== prevProps.match.params.manufacturerSupplierCode) {
            await this.setDefaults();
            this.fetchManufacturerSupplierViewData(this.props.match.params.manufacturerSupplierCode);
            window.scrollTo(0, 0);
        }
    };

    setDefaults = async () => {
        this.setState({
            errors: {},
            manufacturerSupplier: null,
            manufacturerSupplierCode: null,
            manufacturerSupplierLoading: true,
        });
    };

    fetchManufacturerSupplierViewData = async (manufacturerSupplierCode) => {
        const manufacturerSupplierData = this.fetchManufacturerSupplierData();
        // fetchJson2('user')
        //     .then((user) => {
        //         this.setState({ user: user.payload });
        //     });
        // fetchJson2('branches')
        //     .then((branches) => {
        //         this.setState({ branches: branches.payload });
        //     });
        // fetchJson2('v2/debtStatuses')
        //     .then((debtStatuses) => {
        //         this.setState({ debtStatuses: debtStatuses.payload });
        //     });
        // fetchJson2('v2/agreementStatuses')
        //     .then((agreementStatuses) => {
        //         this.setState({ agreementStatuses: agreementStatuses.payload });
        //     });
        // fetchJson2('v2/debtCompanies')
        //     .then((debtCompanies) => {
        //         this.setState({ debtCompanies: debtCompanies.payload });
        //     });
        // fetchJson2('v2/engineers')
        //     .then((engineers) => {
        //         this.setState({ engineers: engineers.payload });
        //     });
        // fetchJson2(`v2/contact/${contactCode}/paymentCards`)
        //     .then((paymentCards) => {
        //         this.setState({
        //             paymentCards: paymentCards.payload,
        //             paymentCardsLoading: false,
        //         });
        //     });
        // contactData.then(() => { // Dependent on the contact
        //     this.fetchAgreementsData();
        // });
        // contactData.then(() => { // Dependent on the contact
        //     this.fetchVisitsData();
        // });
        // contactData.then(() => { // Dependent on the contact
        //     this.fetchLeadsData();
        // });
        manufacturerSupplierData.then(() => { // Dependent on the contact
            this.fetchHistoriesData();
        });
        this.setState({ manufacturerSupplierCode });
    };

    fetchManufacturerSupplierData = async () => {
        const { manufacturerSupplierCode } = this.state;
        this.setState({
            manufacturerSupplierLoading: true,
        });

        try {
            const [manufacturerSupplier] = await Promise.all([fetchJson2(`v2/manufacturerSupplier/${manufacturerSupplierCode}`), sleep(500)]);

            this.setState((state) => ({
                manufacturerSupplier: {
                    ...state.manufacturerSupplier,
                    ...manufacturerSupplier.payload,
                },
            }));
        } finally {
            this.setState({
                manufacturerSupplierLoading: false,
            });
        }
    };

    fetchHistoriesData = async () => {
        const { manufacturerSupplierCode } = this.state;
        this.setState({
            manufacturerSupplierHistoriesLoading: true,
        });

        try {
            const [histories] = await Promise.all([fetchJson2(`v2/manufacturerSupplier/${manufacturerSupplierCode}/histories`), sleep(500)]);

            this.setState((state) => ({
                manufacturerSupplier: {
                    ...state.manufacturerSupplier,
                    histories: histories.payload,
                },
            }));
        } finally {
            this.setState({
                manufacturerSupplierHistoriesLoading: false,
            });
        }
    };

    passNewManufacturerSupplier = (newManufacturerSupplier) => {
        console.log('>>> newManufacturerSupplier:', newManufacturerSupplier);
        this.setState({
            manufacturerSupplier: newManufacturerSupplier,
        });
    };

    render() {
        const {
        } = this.props;
        const {
            errors,
            manufacturerSupplier,
            manufacturerSupplierHistoriesLoading,
            manufacturerSupplierLoading,
        } = this.state;

        const pageHeaderContent = manufacturerSupplierLoading
            ? {
                leftText: ' ',
                leftTitle: 'Loading...',
            } : {
                leftText: ' ',
                leftTitle: `${manufacturerSupplier.name === null ? '' : manufacturerSupplier.name}`,
                rightTitle: `MS${manufacturerSupplier.code}`,
            };

        return (
            <Layout pageTitle="Manufacturer/Supplier">
                <CssBaseline />
                <PageHeader
                    content={pageHeaderContent}
                    history={this.props.history}
                />
                <ManufacturerSupplierBadges
                    manufacturerSupplier={manufacturerSupplier}
                    manufacturerSupplierLoading={manufacturerSupplierLoading}
                />
                <ManufacturerSupplierTabs
                    errors={errors}
                    fetchManufacturerSupplierData={this.fetchManufacturerSupplierData}
                    manufacturerSupplier={manufacturerSupplier}
                    manufacturerSupplierHistoriesLoading={manufacturerSupplierHistoriesLoading}
                    manufacturerSupplierLoading={manufacturerSupplierLoading}
                    passNewManufacturerSupplier={this.passNewManufacturerSupplier}
                />
            </Layout>
        );
    }
}

export default withStyles(styles)(ManufacturerSupplier);
