import React from 'react';
import {
    Button,
    CssBaseline,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSimCard } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import { withSnackbar } from 'notistack';

import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import HybridTextField from '../components/hybridTextField';
import Layout from './Layout';
import fetch, { fetchJson } from '../utilities/fetch';
import titalise from '../utilities/titalise';

const removeFalseyValuesFromObject = obj => pickBy(obj, identity);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        marginBottom: theme.spacing.unit * 3,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
        // whiteSpace: 'nowrap',
    },
    customTableCell: {
        whiteSpace: 'nowrap',
    },
    simCard: {
        fontSize: 20,
    },
    shortDescription: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
    button: {
        fontSize: 18,
        marginBottom: 10,
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
    },
    dialogActionsDivider: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    formHelperText: {
        color: '#f44336',
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 2.5,
        marginBottom: theme.spacing.unit * 2,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    marginTop3: {
        marginTop: theme.spacing.unit * 3,
    },
    inputAdornment: {
        fontSize: 16,
        marginTop: 1,
        color: theme.palette.text.secondary,
    },
});

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        verticalAlign: 'top',
        paddingTop: 16,
        paddingBottom: 8,
    },
}))(TableCell);

class Products extends React.Component {
    state = {
        departments: [],
        error: false,
        disableProductDetailSaveButton: false,
        showProductDetailDialog: false,
        loading: false,
        manufacturers: [],
        products: [],
        productTypes: [],
        snackbarOpen: false,
        productData: {
            code: '',
            productTypeCode: '',
            manufacturerCode: '',
            moodelNo: '',
            colour: '',
            shortDescription: '',
            departmentCode: '',
            componentQty: '',
            stockControlTypeCode: '',
            sale: '',
            saleAmount: '',
            continuousRental: '',
            weeklyRentalAmount: '',
            repaymentRental: '',
            weeklyRepaymentAmount: '',
            newDefaultRepaymentAmount: '',
            // advancePaymentAmount: '',
            // deliveryAmount: '',
            // agentFee: '',
            // repaymentAmount: '',
            // agreementWeeks: '',
            controlDeviceTypeCode: '',
            expired: '',
        },
        saved: false,
        stockControlTypes: [],
        errors: {},
    }

    async componentDidMount() {
        const url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/products?continuousRental=1&repaymentRental=1');
        const data = await fetch(url).then(response => response.json());
        this.setState({ products: data });

        await this.fetchProductDetailMenus();
    }

    fetchProductDetailMenus = () => {
        const fetchProductTypes = fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/productTypes')
            .then((productTypes) => {
                this.setState({
                    productTypes,
                });
            });
        const fetchManufacturers = fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/manufacturers')
            .then((manufacturers) => {
                this.setState({
                    manufacturers,
                });
            });
        const fetchDepartments = fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/departments')
            .then((departments) => {
                this.setState({
                    departments,
                });
            });
        const fetchStockControlTypes = fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/stockControlTypes')
            .then((stockControlTypes) => {
                this.setState({
                    stockControlTypes,
                });
            });

        return Promise.all([fetchProductTypes, fetchManufacturers, fetchDepartments, fetchStockControlTypes]);
    }

    // fetchProductDetail = async (productCode) => {
    //     const productData = await fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/product/${productCode}`)
    //
    //     this.setState({
    //         productData,
    //     });
    // }

    handleProductClick = async (productCode) => {
        const selectedProductDetails = this.state.products.find(product => product.code === productCode);
        this.setState({
            showProductDetailDialog: true,
            productData: selectedProductDetails,
        });
    }

    // handleProductClick = async (productCode) => {
    //     this.setState({
    //         showProductDetailDialog: true,
    //         loading: true,
    //     });
    //
    //     try {
    //       await Promise.all([this.fetchProductDetail(productCode), this.fetchProductDetailMenus()]);
    //     }  catch (e) {
    //       this.setState({
    //         error: 'Error fetching product'
    //       });
    //     } finally {
    //       this.setState({
    //         loading: false,
    //       });
    //     }
    // }


    handleProductDetailDialogClose = () => {
        this.setState({
            showProductDetailDialog: false,
        });
    }

    updateProductData = (event) => {
        const { value, name } = event.target;

        let formattedValue;
        switch (name) {
        case 'componentQty':
        case 'continuousRental':
        case 'departmentCode':
        case 'manufacturerCode':
        case 'newDefaultAgentFee':
        case 'newDefaultDelivery':
        case 'newDefaultDeposit':
        case 'newDefaultRepaymentAmount':
        case 'newDefaultSaleAmount':
        case 'productTypeCode':
        case 'repaymentRental':
        case 'sale':
        case 'stockControlTypeCode':
        case 'expired':
            formattedValue = parseInt(value);
            break;
        case 'modelNo':
            formattedValue = value.toUpperCase();
            break;
        default:
            formattedValue = titalise(value);
            break;
        }
        event.target.value = formattedValue;

        this.setState(state => ({
            productData: {
                ...state.productData,
                [name]: formattedValue,
            },
        }));
    }

    updateProductDataCheckbox = (name) => (event) => {
        const newState = event.target.checked ? 1 : 0;
        this.setState(state => ({
            productData: {
                ...state.productData,
                [name]: newState,
            },
        }));
    }

    handleSaveProductDetails = (event) => {
        event.preventDefault();
        this.setState({
            disableProductDetailSaveButton: true,
        });
        this.saveProductDetails(this.state.productData).then(() => {
            this.setState({
                showProductDetailDialog: false,
                disableProductDetailSaveButton: false,
            });
            this.props.enqueueSnackbar('Product details saved', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Product details save failed', { variant: 'error' })
            this.setState({
                disableProductDetailSaveButton: false,
            });
        });
    }

    saveProductDetails = async (payload) => {
        if (this.validateProductDetails(payload) === false) throw new Error({ error: 'Generic API error' });
        let response;
        let body;

        // try {
        //     response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${this.state.contactCode}/details`, {
        //         method: 'PATCH',
        //         body: JSON.stringify(payload),
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //     });
        //     console.log('Response:', response);
        // } catch (e) {
        //     // something went really wrong; timeout/ blocked by client etc
        //     // debugger;
        //     console.log(e);
        // }
        //
        // if (response.headers.get('content-type')
        //   && response.headers.get('content-type').search('application/json') >= 0) {
        //     body = await response.json();
        // }

        switch (response.status) {
        case 200: {
            this.setState(state => ({
                customer: {
                    ...state.customer,
                    ...body,
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    }

    validateProductDetails = (payload) => {
        console.log('payload.code>>',payload.code);
        console.log('>>',this.state.products.find(product => ((product.modelNo === payload.modelNo) && (product.code != payload.Code))));

        const fieldOrder = [
            'productTypeCode',
            'manufacturerCode',
            'modelNo',
            'colour',
            'shortDescription',
            'componentQty',
            'departmentCode',
            'stockControlTypeCode',
            'newDefaultSaleAmount',
            'defaultWeeklyRentalAmount',
            'defaultWeeklyRepaymentAmount',
            'defaultRepaymentAmount',

            // 'controlDeviceTypeCode',
            // 'defaultDelivery',
            // 'defaultDeposit',
            // 'newDefaultAgentFee',
            // 'newDefaultDelivery',
            // 'newDefaultDeposit',
            // 'newDefaultRepaymentAmount',
            // 'repaymentRental',
        ];

        const productTypeCode = (() => {
            switch (true) {
            case !payload.productTypeCode: return 'Product Type required';
            default: return '';
            }
        })();
        const manufacturerCode = (() => {
            switch (true) {
            case !payload.manufacturerCode: return 'Manufacturer required';
            default: return '';
            }
        })();
        const modelNo = (() => {
            switch (true) {
            case !payload.modelNo: return 'Model Number required';
            case payload.modelNo.length < 2: return 'Model Number too short';
            case payload.modelNo.length > 100: return 'Model Number too long';
            case this.state.products.find(product => product.modelNo === payload.modelNo): return 'Model Number already in use';
            default: return '';
            }
        })();
        const colour = (() => {
            switch (true) {
            case !payload.colour: return 'Colour required';
            case payload.colour.length < 2: return 'Colour too short';
            case payload.colour.length > 100: return 'Colour too long';
            default: return '';
            }
        })();
        const shortDescription = (() => {
            switch (true) {
            case !payload.shortDescription: return 'Short Description required';
            case payload.shortDescription.length < 2: return 'Short Description too short';
            case payload.shortDescription.length > 100: return 'Short Description too long';
            default: return '';
            }
        })();
        const componentQty = (() => {
            switch (true) {
            case !payload.componentQty: return 'Component Quantity required';
            case payload.componentQty.length <= 0: return 'Component Quantity required';
            case payload.componentQty > 15: return 'Component Quantity too large';
            default: return '';
            }
        })();
        const departmentCode = (() => {
            switch (true) {
            case !payload.departmentCode: return 'Department required';
            case payload.departmentName === 'Unknown': return 'Department required';
            default: return '';
            }
        })();
        const stockControlTypeCode = (() => {
            switch (true) {
            case !payload.stockControlTypeCode: return 'Stock Control Type required';
            default: return '';
            }
        })();
        const newDefaultSaleAmount = (() => {
            switch (true) {
            case (payload.sale === 1) && (payload.newDefaultSaleAmount <= 0): return 'Sale Amount required';
            default: return '';
            }
        })();
        const defaultWeeklyRentalAmount = (() => {
            switch (true) {
            case (payload.continuousRental === 1) && (payload.defaultWeeklyRentalAmount <= 0): return 'Weekly Rental Amount required';
            default: return '';
            }
        })();
        const defaultWeeklyRepaymentAmount = (() => {
            switch (true) {
            case (payload.repaymentRental === 1) && (payload.defaultWeeklyRepaymentAmount <= 0): return 'Weekly Repayment Rental Amount required';
            default: return '';
            }
        })();
        const newDefaultRepaymentAmount = (() => {
            switch (true) {
            case (payload.repaymentRental === 1) && (payload.newDefaultRepaymentAmount <= 0): return 'Repayment Amount required';
            default: return '';
            }
        })();

        const errors = removeFalseyValuesFromObject({
            productTypeCode,
            manufacturerCode,
            modelNo,
            colour,
            shortDescription,
            componentQty,
            departmentCode,
            stockControlTypeCode,
            newDefaultSaleAmount,
            defaultWeeklyRentalAmount,
            defaultWeeklyRepaymentAmount,
            newDefaultRepaymentAmount,

            // continuousRental,
            // controlDeviceTypeCode,
            // defaultDelivery,
            // defaultDeposit,
            // defaultRepaymentAmount,
            // newDefaultAgentFee,
            // newDefaultDelivery,
            // newDefaultDeposit,
            // repaymentRental,
        });

        const firstError = fieldOrder.find(field => !!errors[field]);

        this.setState({
            errors,
            firstError,
        });

        if (isEmpty(errors)) return true;

        return false;
    }

    render() {
        const {
            classes
        } = this.props;
        const {
            departments,
            disableProductDetailSaveButton,
            errors,
            manufacturers,
            products,
            productData,
            productTypes,
            showProductDetailDialog,
            stockControlTypes,
        } = this.state;

        console.log('>>Rendering');

        return (
            <React.Fragment>
                { products !== []
                    ? (
                        <Layout pageTitle="Products">
                            <CssBaseline />
                            <Paper className={classes.root}>
                                <Table className={classes.table} padding="dense">
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell className={classes.tableHead}>Stock Code</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead}>Type</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead}>Manufacturer</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead}>Model</CustomTableCell>

                                            <CustomTableCell className={classes.tableHead}>Sale Amount</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead}>Weekly Rental</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead}>Weekly Repayment</CustomTableCell>

                                            <CustomTableCell className={classes.tableHead}>Advance Payment</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead}>Delivery</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead}>Agent Fee</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead}>Repayment Amount</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead}>Repayment Weeks</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead} />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {products.map(product => (
                                            <TableRow onClick={() => this.handleProductClick(product.code)} className={classes.row} key={product.code}>
                                                <CustomTableCell className={classes.customTableCell} align="center">{product.code}</CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} align="left">{product.type}</CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} align="left">{product.manufacturer}</CustomTableCell>
                                                <CustomTableCell align="left">
                                                    <div>
                                                        {product.modelNo}
                                                        {product.colour ? ` (${product.colour})` : ''}
                                                    </div>
                                                    <div className={classes.shortDescription}>{product.shortDescription}</div>
                                                </CustomTableCell>

                                                <CustomTableCell className={classes.customTableCell} align="right">{product.sale === 1 ? `£${product.newDefaultSaleAmount.toFixed(2)}` : '-'}</CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} align="right">{product.continuousRental === 1 ? `£${product.defaultWeeklyRentalAmount.toFixed(2)}` : '-'}</CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} align="right">{product.repaymentRental === 1 ? `£${product.defaultWeeklyRepaymentAmount.toFixed(2)}` : '-'}</CustomTableCell>

                                                <CustomTableCell className={classes.customTableCell} align="right">{product.newDefaultDeposit ? `£${product.newDefaultDeposit.toFixed(2)}` : '-'}</CustomTableCell>

                                                <CustomTableCell className={classes.customTableCell} align="right">{`£${product.newDefaultDelivery.toFixed(2)}`}</CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} align="right">{`£${product.newDefaultAgentFee.toFixed(2)}`}</CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} align="right">{`£${product.newDefaultRepaymentAmount.toFixed(2)}`}</CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} align="center">{(product.newDefaultRepaymentAmount / product.defaultWeeklyRepaymentAmount)}</CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} align="center"><IconButton className={classes.button} aria-label="More info"><FontAwesomeIcon icon={faEdit} /></IconButton></CustomTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>

                            <Dialog
                                open={showProductDetailDialog}
                                onClose={this.handleProductDetailDialogClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <React.Fragment>
                                            <Typography variant="h6" gutterBottom>
                                        Product Detail
                                            </Typography>
                                            <form>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <FormControl
                                                            className={classes.menuFormControl}
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.productTypeCode}
                                                        >
                                                            <InputLabel htmlFor="productTypeCode" className={classes.inputLabel}>Type</InputLabel>
                                                            <Select
                                                                disabled={productData.expired}
                                                                keyboard="true"
                                                                input={(
                                                                    <OutlinedInput
                                                                        onChange={this.updateProductData}
                                                                        name="productTypeCode"
                                                                        id="productTypeCode"
                                                                    />
                                                                )}
                                                                value={productData.productTypeCode || ''}
                                                            >
                                                                <MenuItem value=""><em>None</em></MenuItem>
                                                                {productTypes.map((productType, i) => (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={productType.code}
                                                                    >
                                                                        {productType.description}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {errors.productTypeCode && (
                                                                <FormHelperText>{errors.productTypeCode}</FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl
                                                            className={classes.menuFormControl}
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.manufacturerCode}
                                                        >
                                                            <InputLabel htmlFor="manufacturerCode" className={classes.inputLabel}>Manufacturer</InputLabel>
                                                            <Select
                                                                disabled={productData.expired}
                                                                keyboard="true"
                                                                input={(
                                                                    <OutlinedInput
                                                                        onChange={this.updateProductData}
                                                                        name="manufacturerCode"
                                                                        id="manufacturerCode"
                                                                    />
                                                                )}
                                                                value={productData.manufacturerCode || ''}
                                                            >
                                                                <MenuItem value=""><em>None</em></MenuItem>
                                                                {manufacturers.map((manufacturer, i) => (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={manufacturer.code}
                                                                    >
                                                                        {manufacturer.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {errors.manufacturerCode && (
                                                                <FormHelperText>{errors.manufacturerCode}</FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            disabled={productData.expired}
                                                            className={classes.formControl}
                                                            name="modelNo"
                                                            id="modelNo"
                                                            onChange={this.updateProductData}
                                                            value={productData.modelNo || ''}
                                                            label="Model Number"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.modelNo}
                                                            helperText={errors.modelNo}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            disabled={productData.expired}
                                                            className={classes.formControl}
                                                            name="colour"
                                                            id="colour"
                                                            onChange={this.updateProductData}
                                                            value={productData.colour}
                                                            label="Colour"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.colour}
                                                            helperText={errors.colour}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            disabled={productData.expired}
                                                            className={classes.formControl}
                                                            name="shortDescription"
                                                            id="shortDescription"
                                                            onChange={this.updateProductData}
                                                            value={productData.shortDescription}
                                                            label="Short Description"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.shortDescription}
                                                            helperText={errors.shortDescription}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl
                                                            className={classes.menuFormControl}
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.componentQty}
                                                        >
                                                            <InputLabel htmlFor="departmentCode" className={classes.inputLabel}>Component Quantity</InputLabel>
                                                            <Select
                                                                disabled={productData.expired}
                                                                keyboard="true"
                                                                input={(
                                                                    <OutlinedInput
                                                                        onChange={this.updateProductData}
                                                                        name="componentQty"
                                                                        id="componentQty"
                                                                    />
                                                                )}
                                                                value={productData.componentQty || ''}
                                                            >
                                                                <MenuItem value=""><em>None</em></MenuItem>
                                                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((count, i) => (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={count}
                                                                    >
                                                                        {count}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {errors.componentQty && (
                                                                <FormHelperText>{errors.componentQty}</FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl
                                                            className={classes.menuFormControl}
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.departmentCode}
                                                        >
                                                            <InputLabel htmlFor="departmentCode" className={classes.inputLabel}>Department</InputLabel>
                                                            <Select
                                                                disabled={productData.expired}
                                                                keyboard="true"
                                                                input={(
                                                                    <OutlinedInput
                                                                        onChange={this.updateProductData}
                                                                        name="departmentCode"
                                                                        id="departmentCode"
                                                                    />
                                                                )}
                                                                value={productData.departmentCode || ''}
                                                            >
                                                                <MenuItem value=""><em>None</em></MenuItem>
                                                                {departments.map((department, i) => (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={department.code}
                                                                    >
                                                                        {department.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {errors.departmentCode && (
                                                                <FormHelperText>{errors.departmentCode}</FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl
                                                            className={classes.menuFormControl}
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.stockControlTypeCode}
                                                        >
                                                            <InputLabel htmlFor="stockControlTypeCode" className={classes.inputLabel}>Stock Control Type</InputLabel>
                                                            <Select
                                                                disabled={productData.expired}
                                                                keyboard="true"
                                                                input={(
                                                                    <OutlinedInput
                                                                        onChange={this.updateProductData}
                                                                        name="stockControlTypeCode"
                                                                        id="stockControlTypeCode"
                                                                    />
                                                                )}
                                                                value={productData.stockControlTypeCode || ''}
                                                            >
                                                                <MenuItem value=""><em>None</em></MenuItem>
                                                                {stockControlTypes.map((stockControlType, i) => (
                                                                    <MenuItem
                                                                        key={i}
                                                                        value={stockControlType.code}
                                                                    >
                                                                        {stockControlType.description}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {errors.stockControlTypeCode && (
                                                                <FormHelperText>{errors.stockControlTypeCode}</FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Divider className={classes.dialogDivider} />
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <FormControlLabel
                                                                control={(
                                                                    <Checkbox
                                                                        disabled={productData.expired}
                                                                        name="sale"
                                                                        id="sale"
                                                                        checked={productData.sale === 1}
                                                                        onChange={this.updateProductDataCheckbox('sale')}
                                                                    />
                                                                )}
                                                                label="Available for Sale"
                                                            />
                                                        </Grid>
                                                        {productData.sale === 1
                                                        ? (
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    disabled={productData.expired}
                                                                    className={`${classes.formControl} ${classes.marginTop3}`}
                                                                    name="newDefaultSaleAmount"
                                                                    id="newDefaultSaleAmount"
                                                                    value={productData.newDefaultSaleAmount}
                                                                    onChange={this.updateProductData}
                                                                    label="Total Sale Amount"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    error={!!errors.newDefaultSaleAmount}
                                                                    helperText={errors.newDefaultSaleAmount}
                                                                    InputProps={{
                                                                        startAdornment:
                                                                <InputAdornment
                                                                    disableTypography
                                                                    className={classes.inputAdornment}
                                                                    position="start"
                                                                >
                                                                    £
                                                                </InputAdornment>,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        )
                                                        : null
                                                    }
                                                    </Grid>

                                                <Divider className={classes.dialogDivider} />
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <FormControlLabel
                                                                control={(
                                                                    <Checkbox
                                                                        disabled={productData.expired}
                                                                        name="continuousRental"
                                                                        id="continuousRental"
                                                                        checked={productData.continuousRental === 1}
                                                                        onChange={this.updateProductDataCheckbox('continuousRental')}
                                                                    />
                                                                )}
                                                                label="Available for Continuous Rental"
                                                            />
                                                        </Grid>
                                                        {productData.continuousRental === 1
                                                        ? (
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    disabled={productData.expired}
                                                                    className={`${classes.formControl} ${classes.marginTop3}`}
                                                                    name="defaultWeeklyRentalAmount"
                                                                    id="defaultWeeklyRentalAmount"
                                                                    value={productData.defaultWeeklyRentalAmount}
                                                                    onChange={this.updateProductData}
                                                                    label="Weekly Continuous Rental Amount"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    error={!!errors.defaultWeeklyRentalAmount}
                                                                    helperText={errors.defaultWeeklyRentalAmount}
                                                                    InputProps={{
                                                                        startAdornment:
                                                                <InputAdornment
                                                                    disableTypography
                                                                    className={classes.inputAdornment}
                                                                    position="start"
                                                                >
                                                                    £
                                                                </InputAdornment>,
                                                                    }}
                                                                />
                                                            </Grid>
                                                        )
                                                        : null
                                                    }
                                                    </Grid>

                                                    <Divider className={classes.dialogDivider} />
                                                        <Grid container spacing={0}>
                                                            <Grid item xs={12}>
                                                                <FormControlLabel
                                                                    control={(
                                                                        <Checkbox
                                                                            disabled={productData.expired}
                                                                            name="repaymentRental"
                                                                            id="repaymentRental"
                                                                            checked={productData.repaymentRental === 1}
                                                                            onChange={this.updateProductDataCheckbox('repaymentRental')}
                                                                        />
                                                                    )}
                                                                    label="Available for Repayment Rental"
                                                                />
                                                            </Grid>
                                                            {productData.repaymentRental === 1
                                                            ? (
                                                                <React.Fragment>
                                                                    <Grid item xs={12}>
                                                                        <TextField
                                                                            disabled={productData.expired}
                                                                            className={`${classes.formControl} ${classes.marginTop3}`}
                                                                            name="defaultWeeklyRepaymentAmount"
                                                                            id="defaultWeeklyRepaymentAmount"
                                                                            value={productData.defaultWeeklyRepaymentAmount}
                                                                            onChange={this.updateProductData}
                                                                            label="Weekly Repayment Rental Amount"
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                            error={!!errors.defaultWeeklyRepaymentAmount}
                                                                            helperText={errors.defaultWeeklyRepaymentAmount}
                                                                            InputProps={{
                                                                                startAdornment:
                                                                        <InputAdornment
                                                                            disableTypography
                                                                            className={classes.inputAdornment}
                                                                            position="start"
                                                                        >
                                                                            £
                                                                        </InputAdornment>,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <TextField
                                                                            disabled={productData.expired}
                                                                            className={`${classes.formControl} ${classes.marginTop3}`}
                                                                            name="newDefaultRepaymentAmount"
                                                                            id="newDefaultRepaymentAmount"
                                                                            value={productData.newDefaultRepaymentAmount}
                                                                            onChange={this.updateProductData}
                                                                            label="Product Repayment Amount"
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                            error={!!errors.newDefaultRepaymentAmount}
                                                                            helperText={errors.newDefaultRepaymentAmount}
                                                                            InputProps={{
                                                                                startAdornment:
                                                                        <InputAdornment
                                                                            disableTypography
                                                                            className={classes.inputAdornment}
                                                                            position="start"
                                                                        >
                                                                            £
                                                                        </InputAdornment>,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </React.Fragment>
                                                            )
                                                            : null
                                                        }
                                                        </Grid>
                                                    <Divider className={classes.dialogDivider} />
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                disabled={productData.expired}
                                                                className={`${classes.formControl} ${classes.marginTop3}`}
                                                                name="newDefaultDeposit"
                                                                id="newDefaultDeposit"
                                                                value={productData.newDefaultDeposit}
                                                                onChange={this.updateProductData}
                                                                label="Advance Payment"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.newDefaultDeposit}
                                                                helperText={errors.newDefaultDeposit}
                                                                InputProps={{
                                                                    startAdornment:
                                                            <InputAdornment
                                                                disableTypography
                                                                className={classes.inputAdornment}
                                                                position="start"
                                                            >
                                                                £
                                                            </InputAdornment>,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                disabled={productData.expired}
                                                                className={`${classes.formControl} ${classes.marginTop3}`}
                                                                name="newDefaultDelivery"
                                                                id="newDefaultDelivery"
                                                                value={productData.newDefaultDelivery}
                                                                onChange={this.updateProductData}
                                                                label="Delivery Charge"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.newDefaultDelivery}
                                                                helperText={errors.newDefaultDelivery}
                                                                InputProps={{
                                                                    startAdornment:
                                                            <InputAdornment
                                                                disableTypography
                                                                className={classes.inputAdornment}
                                                                position="start"
                                                            >
                                                                £
                                                            </InputAdornment>,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                disabled={productData.expired}
                                                                className={`${classes.formControl} ${classes.marginTop3}`}
                                                                name="newDefaultAgentFee"
                                                                id="newDefaultAgentFee"
                                                                value={productData.newDefaultAgentFee}
                                                                onChange={this.updateProductData}
                                                                label="Agent Fee"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.newDefaultAgentFee}
                                                                helperText={errors.newDefaultAgentFee}
                                                                InputProps={{
                                                                    startAdornment:
                                                            <InputAdornment
                                                                disableTypography
                                                                className={classes.inputAdornment}
                                                                position="start"
                                                            >
                                                                £
                                                            </InputAdornment>,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Divider className={classes.dialogDivider} />
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <FormControlLabel
                                                                control={(
                                                                    <Checkbox
                                                                        name="expired"
                                                                        id="expired"
                                                                        checked={productData.expired === 1}
                                                                        onChange={this.updateProductDataCheckbox('expired')}
                                                                    />
                                                                )}
                                                                label="Expired"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                <Button
                                                    onClick={this.handleSaveProductDetails}
                                                    variant="contained"
                                                    className={classes.dialogButton}
                                                    disabled={disableProductDetailSaveButton}
                                                >
                                            SAVE
                                                </Button>
                                            </form>
                                        </React.Fragment>
                                    </div>
                                </DialogContent>
                                <Divider className={classes.dialogActionsDivider} />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleProductDetailDialogClose}
                                        color="primary"
                                        autoFocus
                                    >
                                Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                        </Layout>
                    )
                    : null}
            </React.Fragment>
        );
    }
}

export default withSnackbar(withStyles(styles)(Products));
