import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

const styles = theme => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        borderRight: '1px solid #e0e0e0',
        borderLeft: '1px solid #e0e0e0',
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    totalCell: {
        borderBottom: 0,
    },
});

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

class ItemsSummaryTable extends React.Component {
    state = {

    }

    handleBranchClick = (newBranch) => {
        const newFilters = {
            ...this.props.filters,
            branch: newBranch,
        };
        this.props.setFilters(newFilters);
    }

    render() {
        const {
            classes,
            itemsBranchSummary,
            totalItemQty,
        } = this.props;

        return (
            <Table className={classes.table} padding="dense">
                <TableHead>
                    <TableRow>
                        <CustomTableCell className={classes.tableHead}>Branch</CustomTableCell>
                        <CustomTableCell align="right" className={classes.tableHead}>Qty</CustomTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderBy(uniqBy(itemsBranchSummary, 'branch'), 'branch').map(branch => (
                        <TableRow className={classes.row} key={branch.branch} onClick={() => this.handleBranchClick(branch.branch)}>
                            <CustomTableCell className={classes.customTableCell} align="left">{branch.branch}</CustomTableCell>
                            <CustomTableCell className={classes.customTableCell} align="right">{branch.itemQty}</CustomTableCell>
                        </TableRow>
                    ))}
                    <TableRow key="tableTotal">
                        <CustomTableCell className={`${classes.customTableCell} ${classes.totalCell}`} align="right">Total</CustomTableCell>
                        <CustomTableCell className={`${classes.customTableCell} ${classes.totalCell}`} align="right">
                            {totalItemQty}
                        </CustomTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }
}

const itemsBranchSummaryShape = {
    branch: PropTypes.string,
    totalItemQty: PropTypes.number,
};

ItemsSummaryTable.propTypes = {
    classes: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    itemsBranchSummary: PropTypes.arrayOf(PropTypes.shape(itemsBranchSummaryShape)).isRequired,
    totalItemQty: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
};

export default withStyles(styles)(ItemsSummaryTable);
