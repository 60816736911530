import React from 'react';

import {
    Button,
    List,
    ListItem,
    ListItemText,
    FormControl,
    Grid,
    Paper,
    TextField,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import qs from 'qs';
import SignatureCanvas from 'react-signature-canvas';
import { red } from '@material-ui/core/colors';
import { faAlignCenter } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import fetch from '../../utilities/fetch';

const { REACT_APP_API_SERVER } = process.env;

const styles = theme => ({
    buttonBar: {
        position: 'fixed',
        paddingTop: 11,
        paddingBottom: 18,
        bottom: 0,
        width: '100%',
        height: 30,
        boxShadow: '0px 1px 8px 0px rgb(0 0 0 / 80%)',
        backgroundColor: '#f2f1f1',
        textAlign: 'center',
    },
    topButtonBar: {
        position: 'fixed',
        paddingTop: 11,
        paddingBottom: 18,
        top: 0,
        width: '100%',
        height: 30,
        // boxShadow: '0px 1px 8px 0px rgb(0 0 0 / 80%)',
        backgroundColor: 'aliceblue',
        textAlign: 'left',
    },
    clearButton: {
        textAlign: 'right',
        paddingRight: theme.spacing.unit * 2,
    },
    signatureText: {
        textAlign: 'left',
        paddingLeft: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit * 1,
    },
    contentContainer: {
        paddingBottom: 40,
        maxWidth: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    logo: {
        margin: theme.spacing.unit * 7,
        width: 128,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    logoContainer: {
        textAlign: 'center',
    },
    startContainer: {
        paddingRight: theme.spacing.unit * 4,
        paddingLeft: theme.spacing.unit * 4,
    },
    text: {
        fontSize: 16,
    },
    buttonContainer: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
    centre: {
        textAlign: 'center',
    },
    signOff: {
        paddingTop: theme.spacing.unit * 2,
    },
    dateTime: {
        fontWieght: 700,
        fontSize: '2em',
    },
});

class VisitInviteReply extends React.Component {
    state = {
        stage: 'UPDATE',
    }

    static getDerivedStateFromProps(props, state) {
        const { guid } = props.match.params;
        const { answer } = qs.parse(props.location.search, { ignoreQueryPrefix: true }) || '';
        return {
            guid,
            answer,
        };
    }

    componentDidMount = async () => {
        await this.updateVisit();
    }

    updateVisit = async () => {
        let response;
        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/p/visitInviteReply/${this.state.guid}?answer=${this.state.answer}`, {
                method: 'GET',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        let body;
        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        console.log('>>> response:', response);

        if (response.status !== 200) {
            this.setState({
                showGuidErrorDialog: true,
                stage: 'ERROR',
            });
            return;
        }

        this.setState(state => (
            {
                stage: state.answer.toUpperCase(),
                visit: {
                    ...body,
                },
            }
        ));
    }


    handleConfirmDialogClose = () => {
        this.setState({
            showConfirmDialog: false,
        });
    }

    render = () => {
        const { classes } = this.props;
        const {
            showConfirmDialog,
            showGuidErrorDialog,
            stage,
            visit,
        } = this.state;

        const imageUrl = window.location.host === 'portal2.payweeklycarpets.co.uk' ? 'https://pwc-email-images.s3.eu-west-1.amazonaws.com/logo-main-small.png' : 'https://pwc-email-images.s3.eu-west-1.amazonaws.com/ass_logo_main_small.jpg';

        return (
            <>
                <div className={classes.logoContainer}>
                    <img
                        className={classes.logo}
                        src={imageUrl}
                        alt="Pay Weekly Logo"
                    />
                </div>

                {stage === 'NO'
                    ? (
                        <>
                            <div className={classes.contentContainer}>
                                <div className={classes.startContainer}>
                                    <Typography paragraph className={[classes.text, classes.centre]}>
                                        Your visit invite has been cancelled.
                                    </Typography>
                                    <Typography paragraph className={[classes.text, classes.centre]}>
                                        You will be contacted shortly to arrange a new date.
                                    </Typography>
                                    <Typography paragraph className={[classes.text, classes.centre, classes.signOff]}>
                                        The Pay Weekly team
                                    </Typography>

                                </div>
                            </div>
                        </>
                    )
                    : ''}
                {stage === 'YES'
                    ? (
                        <>
                            <div className={classes.contentContainer}>
                                <div className={classes.startContainer}>
                                    <Typography paragraph className={[classes.text, classes.centre]}>
                                        Your visit has been booked.
                                    </Typography>
                                    <Typography paragraph className={[classes.text, classes.centre]}>
                                        This is an all-day appointment 8am - 6pm
                                    </Typography>
                                    <Typography
                                        paragraph
                                        className={[classes.text, classes.centre, classes.dateTime]}
                                    >
                                        {moment(visit.scheduledVisitDateTime).format('dddd Do MMMM')}
                                    </Typography>
                                    <Typography paragraph className={[classes.text, classes.centre, classes.signOff]}>
                                        The Pay Weekly team
                                    </Typography>
                                </div>
                            </div>
                        </>
                    )
                    : ''}
                <Dialog
                    open={showGuidErrorDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Invalid Visit Invite</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <DialogContentText id="alert-dialog-description">
                            This invite has already been used or has expired.
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            You will be contacted shortly to arrange a new date.
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={showConfirmDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Cancel Visit</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to cancel your visit?
                        </DialogContentText>
                        <Grid container spacing={0} className={classes.buttonContainer}>
                            <Grid item xs={6} style={{ textAlign: 'center' }}>
                                <Button
                                    onClick={() => this.handleYesButton()}
                                    variant="outline"
                                    className={classes.dialogButton}
                                >
                                    YES
                                </Button>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'center' }}>
                                <Button
                                    onClick={() => this.handleNoButton()}
                                    variant="outline"
                                    className={classes.dialogButton}
                                >
                                    NO
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>

        );
    }
}

VisitInviteReply.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(withRouter(VisitInviteReply)));
