import React from 'react';
import {
    InputBase,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import SearchIcon from '@material-ui/icons/Search';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withRouter } from 'react-router-dom';
import fetch from '../../utilities/fetch';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing.unit * 2,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit * 3,
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
});

class Search extends React.Component {
    state = {
    };

    componentDidMount() {
    }

    handleFormSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const searchString = formData.get('searchString');
        const link = await this.searchLink(searchString);
        this.props.history.push(link);
    };

    searchLink = async (searchString) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/quickSearch/${searchString}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'GET',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
              && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            return body.link;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    render() {
        const {
            classes,

        } = this.props;

        const {
        } = this.state;

        return (
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <form onSubmit={this.handleFormSubmit}>
                    <InputBase
                        id="searchString"
                        name="searchString"
                        placeholder="Search…"
                        classes={{
                            input: classes.inputInput,
                            root: classes.inputRoot,
                        }}
                    />
                </form>
            </div>

        );
    }
}

export default withSnackbar(withStyles(styles)(withRouter(Search)));
