import React from 'react';
import {
    CircularProgress,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/DoneOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import PersonIcon from '@material-ui/icons/Person';
import ImageIcon from '@material-ui/icons/Image';
import SearchIcon from '@material-ui/icons/Search';
import UndeleteIcon from '@material-ui/icons/RestoreFromTrash';
import classNames from 'classnames';
// import { sortBy } from 'lodash';
import { withSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/Add';
import {
    compact,
    orderBy,
    sortBy,
    uniq,
    uniqBy,
} from 'lodash';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment-timezone';
import CuttingItemDetailDialog from './cuttingItemDetailDialog';
import CuttingItemImageDialog from './cuttingItemImageDialog';
// import ProductExpireDialog from './productExpireDialog';
import AgreementPaymentSummary from './agreementPaymentSummary';

const styles = (theme) => ({
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
        minHeight: 100,
        paddingTop: theme.spacing.unit * 3,
    },
    progress: {
        opacity: '.5',
    },
    tableTitleLeft: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        alignItems: 'left',
        paddingTop: theme.spacing.unit * 2,
        fontWeight: 500,
        textTransform: 'uppercase',
        paddingBottom: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 2,
    },
    tableTitleRight: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        alignItems: 'right',
        paddingTop: theme.spacing.unit * 2,
        fontWeight: 500,
        textTransform: 'uppercase',
        paddingBottom: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    shortDescription: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
    noWrap: {
        whiteSpace: 'nowrap',
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    noWrapPlain: {
        whiteSpace: 'nowrap',
    },
    filterIcon: {
        width: 16,
    },
    filterButton: {
        marginLeft: theme.spacing.unit * 3,
    },
    orderDirectionIcon: {
        marginBottom: -10,
    },
    pageDetail: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        paddingTop: 13,
        fontWeight: 500,
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        textTransform: 'uppercase',
        paddingBottom: theme.spacing.unit * 2,
    },
    hidden: {
        display: 'none',
    },
    downloadIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 20,
        marginLeft: 'auto',
        marginTop: 16,
        marginRight: 16,
    },
    topControls: {
        marginTop: theme.spacing.unit * 3,
    },
    noDataText: {
        padding: theme.spacing.unit * 3,
    },
    greenChip: {
        backgroundColor: 'green',
        color: 'white',
        height: theme.spacing.unit * 3,
        marginTop: -4,
    },
    blackTableIcons: {
        color: '#000000',
        fontSize: 22,
        opacity: 0.6,
    },
    colourTableIcons: {
        fontSize: 22,
        opacity: 1,
    },
    pass: {
        color: theme.palette.primary.testPass,
    },
    fail: {
        color: theme.palette.primary.testFail,
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogContent: {
        paddingBottom: 16,
        minWidth: 600,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 4,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '8px 24px',
        margin: '0px 0px 0px 0px !important',
    },
    dialogText: {
        marginTop: theme.spacing.unit * 2,
    },
    dialogDivider: {
        marginBottom: theme.spacing.unit * 2,
        marginLeft: -24,
        marginRight: -24,
        marginTop: theme.spacing.unit * 2,
    },
    disabled: {
        opacity: 0.26,
    },
    tableExpiredCheckBox: {
        textAlign: 'left',
    },
    addProductButton: {
        textAlign: 'right',
    },
    dialogExpiredCheckBox: {
        textAlign: 'right',
    },
    checkBox: {
        fontSize: 14,
    },
    searchAdornment: {
        // marginTop: 4,
        // marginBottom: 4,
        margin: 'none',
        padding: 'none',
    },
    searchBox: {
        marginBottom: 16,
    },
    searchContainer: {
        paddingLeft: 48,
        textAlign: 'center',
    },
    clearButton: {
        marginTop: 8,
    },
    newButton: {
        marginTop: 8,
    },
    expiredCheckbox: {
        marginTop: 8,
    },
    searchMenuFormControl: {
        marginRight: theme.spacing.unit * 4,
    },
    searchMenu: {
        marginBottom: 15,
        marginTop: theme.spacing.unit * 1,
    },
    searchInput: {
        paddingBottom: 15,
    },
    branchCheckboxesContainer: {
        margin: 16,
        textAlign: 'center',
    },
    customTableCell: {
        paddingRight: 0,
    },
});

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
}))(TableCell);

class CuttingTable extends React.Component {
    state = {
        branchFilter: [],
        currentVisitItem: {},
        hideAssignedVisitItems: true,
        searchBoxData: {
            deliveryBranchCode: '',
            searchString: '',
        },
        showCuttingItemDetailDialog: false,
        showCuttingItemImageDialog: false,
    };

    componentDidMount() {
        this.setupBranchFilter();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.branches !== prevProps.branches) this.setupBranchFilter();
    };

    setupBranchFilter = () => {
        // const branchFilter = this.props.branches.map((branch) => ({
        //     checked: !!(branch.code === this.props.user.branchCode),
        //     code: branch.code,
        //     name: branch.name,
        // }));
        const newBranchFilter = [];
        newBranchFilter.push(this.props.user.branchCode);
        this.setState({
            branchFilter: newBranchFilter,
        });
    };

    handleRowClick = (visitItemCode) => {
        const currentVisitItem = this.props.visitItems.find((visitItem) => visitItem.code === visitItemCode);
        this.setState({
            currentVisitItem,
            showCuttingItemDetailDialog: true,
        });
    };

    // handleExpireProductClick = (productCode, event) => {
    //     event.stopPropagation();
    //     this.setState({
    //         currentVisitItem: this.props.products.find((product) => visitItem.code === productCode),
    //         showExpireProductDialog: true,
    //     });
    // };

    // handleEditProductClick = (productCode, event) => {
    //     event.stopPropagation();
    //     const currentVisitItem = this.props.products.find((product) => visitItem.code === productCode);
    //     this.setState({
    //         currentVisitItem,
    //         showEditProductDialog: true,
    //     });
    // };

    // handleCloseEditProductDialogClick = () => {
    //     this.setState({
    //         showEditProductDialog: false,
    //     });
    // };

    handleCloseCuttingItemDetailDialogClick = () => {
        this.props.refreshVisitItemsData();
        this.setState({
            showCuttingItemDetailDialog: false,
        });
    };

    handleCloseCuttingItemImageDialogClick = () => {
        this.props.refreshVisitItemsData();
        this.setState({
            showCuttingItemImageDialog: false,
        });
    };

    // handleExpireProductCloseDialogClick = () => {
    //     this.setState({
    //         showExpireProductDialog: false,
    //     });
    // };

    // handleEditProductCloseDialogClick = () => {
    //     this.setState({
    //         showEditProductDialog: false,
    //     });
    // };

    // handleExpireProduct = () => {
    //     this.setState({
    //         disableExpireProductSaveButton: true,
    //     });
    //     const payload = this.state.newPurchaseOrderData;
    //     this.props.expireProduct(this.state.currentvisitItem.code).then(() => {
    //         this.setState({
    //             disableExpireProductSaveButton: false,
    //             showExpireProductDialog: false,
    //         });
    //         this.props.enqueueSnackbar('Expire Manufacturer/Supplier Success', { variant: 'success' });
    //     }).catch(() => {
    //         this.props.enqueueSnackbar('Expire Manufacturer/Supplier Failed', { variant: 'error' });
    //         this.setState({
    //             disableExpireProductSaveButton: false,
    //         });
    //     });
    // };

    // updateEditProductData = (event) => {
    //     const { name, value } = event.target;

    //     let formattedValue;
    //     let addressData;
    //     switch (name) {
    //     case 'address1':
    //     case 'address2':
    //     case 'townCity':
    //     case 'county':
    //     case 'postcode':
    //     case 'country':
    //         addressData = {
    //             ...this.state.editProductData.address,
    //             [name]: value,
    //         };
    //         break;
    //     default:
    //         formattedValue = value;
    //         break;
    //     }
    //     event.target.value = formattedValue;

    //     this.setState((state) => ({
    //         editProductData: {
    //             ...state.editProductData,
    //             [name]: formattedValue,
    //             address: {
    //                 ...state.editProductData.address,
    //                 ...addressData,
    //             },
    //         },
    //     }));
    // };

    // handleEditProduct = () => {
    //     this.setState({
    //         disableEditProductSaveButton: true,
    //     });
    //     const payload = this.state.editProductData;
    //     this.props.editProduct(payload).then(() => {
    //         this.setState({
    //             disableEditProductSaveButton: false,
    //             showEditProductDialog: false,
    //         });
    //         this.props.enqueueSnackbar('Manufacturer/Supplier Save', { variant: 'success' });
    //     }).catch(() => {
    //         this.props.enqueueSnackbar('Save Manufacturer/Supplier Failed', { variant: 'error' });
    //         this.setState({
    //             disableEditProductSaveButton: false,
    //         });
    //     });
    // };

    // updateEditProductDataCheckbox = (name) => (event) => {
    //     const newState = !!event.target.checked;
    //     this.setState((state) => ({
    //         editProductData: {
    //             ...state.editProductData,
    //             [name]: newState,
    //         },
    //     }));
    // };

    updateHideAssignedVisitItemsCheckbox = () => (event) => {
        const newState = !!event.target.checked;
        this.setState({
            hideAssignedVisitItems: newState,
        });
    };

    updateSearchboxData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            searchBoxData: {
                ...state.searchBoxData,
                [name]: formattedValue,
            },
        }));
    };

    handleClearSearchStringClick = () => {
        this.setState((state) => ({
            searchBoxData: {
                ...state.searchBoxData,
                searchString: '',
            },
        }));
    };

    handleImageClick = (visitItemCode, event) => {
        event.stopPropagation();
        const currentVisitItem = this.props.visitItems.find((visitItem) => visitItem.code === visitItemCode);
        const { link } = currentVisitItem.agreement.lead.images[0];
        this.setState({
            currentVisitItem,
            showCuttingItemImageDialog: true,
        });
    };

    handleContactAccountClick = (contactCode, agreementCode, event) => {
        event.stopPropagation();
        window.open(`/contact/${contactCode}?agreementCode=${agreementCode}`);
    };

    updateBranchFilter = () => (event) => {
        const newState = !!event.target.checked;
        const branchCode = parseInt(event.target.id, 10);
        let newBranchFilter = [];
        if (!newState) {
            newBranchFilter = this.state.branchFilter.filter((branch) => branch !== branchCode);
        } else {
            newBranchFilter = this.state.branchFilter;
            newBranchFilter.push(branchCode);
        }
        this.setState({
            branchFilter: newBranchFilter,
        });
        event.target.value = newState;
    };

    render() {
        const {
            branches,
            branchesLoading,
            classes,
            startDate,
            endDate,
            visitItems,
            visitItemsLoading,
        } = this.props;
        const {
            branchFilter,
            currentVisitItem,
            hideAssignedVisitItems,
            searchBoxData,
            showCuttingItemDetailDialog,
            showCuttingItemImageDialog,
        } = this.state;

        const displayVisitItems = searchBoxData.searchString.length < 3
        // ? visitItems.reduce((acc, visitItem) => {
            ? visitItems.filter((visitItem) => branchFilter.includes(visitItem.agreement.contact.branchCode)).reduce((acc, visitItem) => {
                if (searchBoxData.deliveryBranchCode === '') return [...acc, visitItem];
                if ((visitItem.deliveryBranchCode || null) === searchBoxData.deliveryBranchCode) return [...acc, visitItem];
                return [...acc];
            }, [])
            // : visitItems.filter((visitItem) => (visitItem.agreementItem.product.manufacturer.toLowerCase().includes(searchBoxData.searchString.toLowerCase()))
            : visitItems.filter((visitItem) => (branchFilter.includes(visitItem.agreement.contact.branchCode) && (visitItem.agreementItem.product.manufacturer.toLowerCase().includes(searchBoxData.searchString.toLowerCase())))
            // || ((visitItem.visitItemType || '').toLowerCase().includes(searchBoxData.searchString.toLowerCase()))
            // || ((visitItem.visitItemTypeSubCategory || '').toLowerCase().includes(searchBoxData.searchString.toLowerCase()))
            // || ((visitItem.barcode || '').toLowerCase().includes(searchBoxData.searchString.toLowerCase()))
            || (branchFilter.includes(visitItem.agreement.contact.branchCode) && (visitItem.agreementItem.product.modelNo.toLowerCase().includes(searchBoxData.searchString.toLowerCase()))));
            // || (visitItem.agreementItem.product.modelNo.toLowerCase().includes(searchBoxData.searchString.toLowerCase())));

        // const deliveryBranches = visitItems.map((visitItem) => (visitItem.branchCode ? { code: visitItem.deliveryBranchCode, name: visitItem.visitItemTypeSubCategory } : null));
        // const displayDeliveryBranches = uniqBy(visitItemTypeSubCategories.filter((visitItemTypeSubCategory) => visitItemTypeSubCategory), 'code');

        // console.log('>>> displayProductTypeSubCategory:', displayDeliveryBranches);
        // console.log('>>> deliveryBranchCode:', searchBoxData.deliveryBranchCode);

        // console.log('>>> Branch Filter:', branchFilter);
        return (
            <>
                {branchFilter.length > 0
                    ? (
                        <>
                            <Grid
                                container
                                spacing={0}
                            >
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.tableExpiredCheckBox}
                                >
                                    <FormControlLabel
                                        control={(
                                            <Checkbox
                                                name="hideAssignedVisitItems"
                                                id="hideAssignedVisitItems"
                                                onChange={this.updateHideAssignedVisitItemsCheckbox()}
                                                checked={hideAssignedVisitItems}
                                                value={hideAssignedVisitItems}
                                            />
                                        )}
                                        classes={{ label: classes.checkBox }}
                                        label="Hide Assigned"
                                        className={classes.expiredCheckbox}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                    className={classes.searchContainer}
                                >
                                    <DatePicker
                                        name="startDate"
                                        id="startDate"
                                        margin="normal"
                                        label="Start Date"
                                        value={startDate}
                                        onChange={this.props.handleStartDateChange}
                                        fullWidth
                                        variant="outlined"
                                        format="DD/MM/YYYY"
                                        showTodayButton
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                    className={classes.searchContainer}
                                >
                                    <DatePicker
                                        name="endDate"
                                        id="endDate"
                                        margin="normal"
                                        label="End Date"
                                        value={endDate}
                                        onChange={this.props.handleEndDateChange}
                                        fullWidth
                                        variant="outlined"
                                        format="DD/MM/YYYY"
                                        showTodayButton
                                        className={classes.searchDataPickers}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    className={classes.searchContainer}
                                >
                                    {/* <FormControl
                            className={classes.searchMenuFormControl}
                            variant="outlined"
                        >
                            <InputLabel htmlFor="deliveryBranchCode" className={classes.inputLabel}>Branch</InputLabel>
                            <Select
                                keyboard="true"
                                // inputProps={classes.searchInput}
                                // native
                                className={classes.searchMenu}
                                renderValue={(selected) => selected.map((x) => x.name).join(', ')}
                                input={(
                                    <OutlinedInput
                                        onChange={this.updateSearchBranchMenu}
                                        name="deliveryBranchCode"
                                        id="deliveryBranchCode"
                                    />
                                )}
                                value={searchBoxData.deliveryBranchCode || ''}
                            >
                                {branches.map((branch) => (
                                    <MenuItem
                                        key={branch.code}
                                        value={branch.code}
                                    >
                                        <Checkbox
                                            checked
                                            //     variantName.findIndex((item) => item.id === variant.id) >= 0
                                            // }
                                        />
                                        <ListItemText primary={branch.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}

                                    <TextField
                                        autoFocus
                                        className={classes.searchBox}
                                        name="searchString"
                                        id="searchString"
                                        onChange={this.updateSearchboxData}
                                        margin="dense"
                                        variant="outlined"
                                        value={searchBoxData.searchString}
                                        label="Filter"
                                        // inputProps={{
                                        //     paddingBottom: 8,
                                        //     paddingLeft: 14,
                                        //     paddingRight: 14,
                                        //     paddingTop: 8,
                                        // }}
                                        InputProps={{
                                            startAdornment:
                            <InputAdornment
                                disableTypography
                                className={classes.searchAdornment}
                                position="start"
                            >
                                <SearchIcon />
                            </InputAdornment>,
                                        }}

                                    />
                                    <IconButton
                                        className={classes.clearButton}
                                        onClick={this.handleClearSearchStringClick}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    className={classes.branchCheckboxesContainer}
                                >

                                    {branches.map((branch) => (
                                        <FormControlLabel
                                            key={branch.code}
                                            control={(
                                                <Checkbox
                                                    name={branch.code}
                                                    id={branch.code}
                                                    onChange={this.updateBranchFilter()}
                                                    checked={branchFilter.includes(branch.code)}
                                                    value={!!branchFilter.includes(branch.code)}
                                                />
                                            )}
                                            classes={{ label: classes.checkBox }}
                                            label={branch.name}
                                        />
                                    ))}
                                </Grid>

                                {/* <Grid
                                    item
                                    xs={12}
                                    className={classes.tableExpiredCheckBox}
                                >

                                    <FormControl sx={{ m: 1, width: 300 }}>
                                        <InputLabel id="demo-multiple-checkbox-label">Branch Filter</InputLabel>
                                        <Select
                                            id="branchFilter"
                                            multiple
                                            value={variantName}
                                            onChange={this.updateBranchFilter()}
                                            input={<OutlinedInput label="Tag" />}
                                            //   renderValue={(selected) => selected.map((x) => x.name).join(', ')}
                                            //   MenuProps={MenuProps}
                                        >
                                            {branches.map((branch) => (
                                                <MenuItem key={branch.code} value={branch.code}>
                                                    <Checkbox
                                                        name={branch.code}
                                                        id={branch.code}
                                                        onChange={this.updateBranchFilter()}
                                                        checked={branchFilter.includes(branch.code)}
                                                        value={!!branchFilter.includes(branch.code)}
                                                    />
                                                    <ListItemText primary={branch.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid> */}

                            </Grid>
                            <Paper>
                                { visitItemsLoading
                                    ? (
                                        <div className={classes.progressContainer}>
                                            <CircularProgress className={classes.progress} variant="indeterminate" />
                                        </div>
                                    )
                                    : (
                                        <>
                                            { visitItems.length === 0
                                                ? (
                                                    <Typography className={classes.noDataText}>
                                                        No Visit Items Found
                                                    </Typography>
                                                )
                                                : (
                                                    <>
                                                        <Table className={classes.table} padding="dense">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <CustomTableCell className={classNames(classes.tableHead, classes.customTableCell)}>
                                                                        Visit Code
                                                                    </CustomTableCell>
                                                                    <CustomTableCell className={classNames(classes.tableHead, classes.customTableCell)}>
                                                                        Customer
                                                                    </CustomTableCell>
                                                                    <CustomTableCell className={classNames(classes.tableHead, classes.customTableCell)}>
                                                                        Payments
                                                                    </CustomTableCell>
                                                                    <CustomTableCell className={classNames(classes.tableHead, classes.customTableCell)}>
                                                                        Postcode
                                                                    </CustomTableCell>
                                                                    <CustomTableCell className={classNames(classes.tableHead, classes.customTableCell)}>
                                                                        Delivery Branch
                                                                    </CustomTableCell>
                                                                    <CustomTableCell className={classNames(classes.tableHead, classes.customTableCell)}>
                                                                        Delivery Date
                                                                    </CustomTableCell>
                                                                    <CustomTableCell className={classNames(classes.tableHead, classes.customTableCell)}>
                                                                        Manufacturer
                                                                    </CustomTableCell>
                                                                    <CustomTableCell className={classNames(classes.tableHead, classes.customTableCell)} align="left">
                                                                        Model Number
                                                                    </CustomTableCell>
                                                                    <CustomTableCell className={classNames(classes.tableHead, classes.customTableCell)} align="center">
                                                                        Dimension/Boxes
                                                                    </CustomTableCell>
                                                                    <CustomTableCell className={classNames(classes.tableHead, classes.customTableCell)} align="left">
                                                                        Operative
                                                                    </CustomTableCell>
                                                                    <CustomTableCell className={classNames(classes.tableHead, classes.customTableCell)} align="center">
                                                                        Stock Location
                                                                    </CustomTableCell>
                                                                    <CustomTableCell className={classNames(classes.tableHead, classes.customTableCell)}>&nbsp;</CustomTableCell>
                                                                    <CustomTableCell className={classNames(classes.tableHead, classes.customTableCell)}>&nbsp;</CustomTableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {orderBy(displayVisitItems, ['agreementItem.product.modelNo', 'agreementyItem.width', 'agreementItem.length'], ['asc', 'desc', 'desc']).map((visitItem) => (!hideAssignedVisitItems || (hideAssignedVisitItems && !visitItem.agreementItem.itemCode)
                                                                    ? (
                                                                        <TableRow onClick={() => this.handleRowClick(visitItem.code)} className={classes.row} key={visitItem.code}>
                                                                            <CustomTableCell
                                                                                className={classNames(
                                                                                    classes.customTableCell,
                                                                                    {
                                                                                        [classes.disabled]: !!visitItem.agreementItem.itemCode,
                                                                                    },
                                                                                )}
                                                                                align="left"
                                                                            >
                                                                                V
                                                                                {visitItem.visitCode}
                                                                            </CustomTableCell>
                                                                            <CustomTableCell
                                                                                className={classNames(
                                                                                    classes.customTableCell,
                                                                                    {
                                                                                        [classes.disabled]: !!visitItem.agreementItem.itemCode,
                                                                                    },
                                                                                )}
                                                                                align="left"
                                                                            >
                                                                                {`${visitItem.agreement.contact.firstname} ${visitItem.agreement.contact.surname}`}
                                                                            </CustomTableCell>

                                                                            <CustomTableCell
                                                                                className={classNames(
                                                                                    classes.customTableCell,
                                                                                    {
                                                                                        [classes.disabled]: !!visitItem.agreementItem.itemCode,
                                                                                    },
                                                                                )}
                                                                                align="left"
                                                                            >
                                                                                <AgreementPaymentSummary
                                                                                    agreementPaymentSummary={visitItem.agreement.authorisedPaymentSummary}
                                                                                    agreementPaymentSummaryLoading={visitItemsLoading}
                                                                                    agreementCode={visitItem.agreement.code}
                                                                                />
                                                                            </CustomTableCell>
                                                                            <CustomTableCell
                                                                                className={classNames(
                                                                                    classes.customTableCell,
                                                                                    {
                                                                                        [classes.disabled]: !!visitItem.agreementItem.itemCode,
                                                                                    },
                                                                                )}
                                                                                align="left"
                                                                            >
                                                                                {visitItem.agreement.contact.address.postcode}
                                                                            </CustomTableCell>
                                                                            <CustomTableCell
                                                                                className={classNames(
                                                                                    classes.customTableCell,
                                                                                    {
                                                                                        [classes.disabled]: !!visitItem.agreementItem.itemCode,
                                                                                    },
                                                                                )}
                                                                                align="left"
                                                                            >
                                                                                {(visitItem.agreement.contact || {}).branch}
                                                                            </CustomTableCell>
                                                                            <CustomTableCell
                                                                                className={classNames(
                                                                                    classes.customTableCell,
                                                                                    {
                                                                                        [classes.disabled]: !!visitItem.agreementItem.itemCode,
                                                                                    },
                                                                                )}
                                                                                align="left"
                                                                            >
                                                                                {moment((visitItem.visit || {}).scheduledVisitDateTime).format('DD/MM/YYYY')}
                                                                            </CustomTableCell>
                                                                            <CustomTableCell
                                                                                className={classNames(
                                                                                    classes.customTableCell,
                                                                                    {
                                                                                        [classes.disabled]: !!visitItem.agreementItem.itemCode,
                                                                                    },
                                                                                )}
                                                                                align="left"
                                                                            >
                                                                                {((visitItem.agreementItem || {}).product || {}).manufacturer}
                                                                            </CustomTableCell>
                                                                            <CustomTableCell
                                                                                className={classNames(
                                                                                    classes.customTableCell,
                                                                                    {
                                                                                        [classes.disabled]: !!visitItem.agreementItem.itemCode,
                                                                                    },
                                                                                )}
                                                                                align="left"
                                                                            >
                                                                                {visitItem.agreementItem.product.modelNo}
                                                                            </CustomTableCell>
                                                                            <CustomTableCell
                                                                                className={classNames(
                                                                                    classes.customTableCell,
                                                                                    {
                                                                                        [classes.disabled]: !!visitItem.agreementItem.itemCode,
                                                                                    },
                                                                                )}
                                                                                align="left"
                                                                            >
                                                                                {visitItem.agreementItem.product.productTypeSubCategory == 'Laminate'
                                                                                    ? (
                                                                                        <>
                                                                                            {visitItem.agreementItem.quantity}
                                                                                            {' '}
                                                                                            Boxes
                                                                                        </>
                                                                                    )
                                                                                    : (
                                                                                        <>
                                                                                            {visitItem.agreementItem.length ? visitItem.agreementItem.length.toFixed(1) : 'n/a'}
                                                                                            m
                                                                                            x
                                                                                            {' '}
                                                                                            {visitItem.agreementItem.length ? visitItem.agreementItem.width.toFixed(1) : 'n/a'}
                                                                                            m
                                                                                        </>
                                                                                    )}
                                                                            </CustomTableCell>
                                                                            <CustomTableCell
                                                                                className={classNames(
                                                                                    classes.customTableCell,
                                                                                    {
                                                                                        [classes.disabled]: !!visitItem.agreementItem.itemCode,
                                                                                    },
                                                                                )}
                                                                                align="left"
                                                                            >
                                                                                {visitItem.visit.scheduledEngineer}
                                                                            </CustomTableCell>
                                                                            <CustomTableCell
                                                                                className={classNames(
                                                                                    classes.customTableCell,
                                                                                    {
                                                                                        [classes.disabled]: !!visitItem.agreementItem.itemCode,
                                                                                    },
                                                                                )}
                                                                                align="center"
                                                                            >
                                                                                {visitItem.agreementItem.item
                                                                                    ? (
                                                                                        <>
                                                                                            {`
                                                                                ${visitItem.agreementItem.item.branch}
                                                                                ${visitItem.agreementItem.item.stockroom ? `/${visitItem.agreementItem.item.stockroom}` : ''}
                                                                                ${visitItem.agreementItem.item.bin ? `/${visitItem.agreementItem.item.bin}` : ''}
                                                                                `}
                                                                                        </>
                                                                                    )
                                                                                    : (<>&nbsp;</>)}
                                                                            </CustomTableCell>
                                                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                                                <IconButton id={visitItem.code} onClick={(event) => this.handleImageClick(visitItem.code, event)}>
                                                                                    {visitItem.agreement.lead.images.length > 0
                                                                                        ? (
                                                                                            <ImageIcon
                                                                                                className={classNames(
                                                                                                    classes.customTableCell,
                                                                                                    classes.blackTableIcons,
                                                                                                    {
                                                                                                        [classes.disabled]: !!visitItem.agreementItem.itemCode,
                                                                                                    },
                                                                                                )}
                                                                                            />
                                                                                        )
                                                                                        : (<>&nbsp;</>)}
                                                                                </IconButton>
                                                                            </CustomTableCell>
                                                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                                                <IconButton id={visitItem.code} onClick={(event) => this.handleContactAccountClick(visitItem.agreement.contactCode, visitItem.agreement.code, event)}>
                                                                                    <PersonIcon
                                                                                        className={classNames(
                                                                                            classes.customTableCell,
                                                                                            classes.blackTableIcons,
                                                                                            {
                                                                                                [classes.disabled]: !!visitItem.agreementItem.itemCode,
                                                                                            },
                                                                                        )}
                                                                                    />
                                                                                </IconButton>
                                                                            </CustomTableCell>
                                                                        </TableRow>
                                                                    )
                                                                    : ''))}
                                                            </TableBody>
                                                        </Table>
                                                    </>
                                                )}
                                        </>
                                    )}
                            </Paper>

                            <CuttingItemDetailDialog
                                handleCloseCuttingItemDetailDialogClick={this.handleCloseCuttingItemDetailDialogClick}
                                visitItem={currentVisitItem}
                                visitItemLoading={visitItemsLoading}
                                showCuttingItemDetailDialog={showCuttingItemDetailDialog}
                            />
                            <CuttingItemImageDialog
                                handleCloseCuttingItemImageDialogClick={this.handleCloseCuttingItemImageDialogClick}
                                visitItem={currentVisitItem}
                                visitItemLoading={visitItemsLoading}
                                showCuttingItemImageDialog={showCuttingItemImageDialog}
                            />
                        </>

                    ) : ''}
            </>
        );
    }
}

export default withSnackbar(withStyles(styles)(CuttingTable));
