// const titalise = string => string.toLowerCase()
//     .split(' ')
//     .map(s => s.charAt(0).toUpperCase() + s.substring(1))
//     .join(' ');

const titalise = (string) => {
    const formattedString = string.replace(/-/g, ' ').replace(/_/g, ' ');
    if (!formattedString) return null;
    return formattedString.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
};

export default titalise;
