import React from 'react';
import {
    Button,
    CssBaseline,
    Chip,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import qs from 'qs';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Layout from './Layout';
import fetch from '../utilities/fetch';

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        marginBottom: theme.spacing.unit * 3,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    authorisedPaymentChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '87px',
    },
    declinedPaymentChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '87px',
    },
    unknownPaymentChip: {
        minWidth: '87px',
    },
    noMissedPaymentsChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '40px',
    },
    missedPaymentsChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '40px',
    },
    unknownMissedPaymentsChip: {
        minWidth: '40px',
    },
    chip: {
        height: 21,
        borderRadius: 5,
    },
});

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

class Sales extends React.Component {
    state = {
        anchorEl: null,
        reportType: 'All',
        sales: [],
        subset: '',
    };

    async componentDidMount() {
        const { subset } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || 0;
        this.setState({ subset });
        this.getAgreements(subset);
    }

    componentDidUpdate(prevProps) {
        const { subset: prevSubset } = qs.parse(prevProps.location.search, { ignoreQueryPrefix: true }) || 0;
        const { subset } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || 0;

        if (subset !== prevSubset) {
            this.getAgreements(subset);
        }
    }

    getAgreements = async (subset) => {
        const data = await fetch('https://api.portal2.payweeklycarpets.co.uk/core/sales').then((response) => response.json());
        this.setState({ sales: data });
    };

    handleLeadClick = (customerCode, saleCode) => {
        this.props.history.push(`/contact/${customerCode}?saleCode=${saleCode}`);
    };

    handleReportTypeClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleReportTypeChange = (selectedReportType) => {
        this.setState({
            reportType: selectedReportType,
            anchorEl: null,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    render() {
        const { classes } = this.props;
        const {
            anchorEl,
            reportType,
            sales,
            subset,
        } = this.state;

        return (
            <Layout pageTitle="Sales">
                <CssBaseline />
                Report Type :
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={this.handleReportTypeClick}
                    variant="outlined"
                >
                    {reportType}
                    <ArrowDropDownIcon />
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >
                    <MenuItem onClick={() => this.handleReportTypeChange('All')}>All</MenuItem>
                    <MenuItem onClick={() => this.handleReportTypeChange('Pending')}>Pending</MenuItem>
                    <MenuItem onClick={() => this.handleReportTypeChange('Undelivered')}>Undelivered</MenuItem>
                    <MenuItem onClick={() => this.handleReportTypeChange('Complete')}>Complete</MenuItem>
                    <MenuItem onClick={() => this.handleReportTypeChange('Cancelled')}>Cancelled</MenuItem>
                </Menu>
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>Sale Code</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Customer</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Address</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Date</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Status</CustomTableCell>
                                <CustomTableCell align="center" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sales.map((sale, index) => (
                                <TableRow onClick={() => this.handleLeadClick(sale.contactCode, sale.code)} className={classes.row} key={sale.code}>
                                    <CustomTableCell align="left">{`S${sale.code}`}</CustomTableCell>
                                    <CustomTableCell align="left">{sale.fullName}</CustomTableCell>
                                    <CustomTableCell align="left">{sale.longAddress}</CustomTableCell>
                                    <CustomTableCell align="right">{sale.saleDate}</CustomTableCell>
                                    <CustomTableCell align="right">{sale.planStatus === 'None' ? 'Pending' : sale.planStatus}</CustomTableCell>
                                    <CustomTableCell align="right"><IconButton className={classes.button} aria-label="More info"><InfoIcon /></IconButton></CustomTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Layout>
        );
    }
}

export default withStyles(styles)(Sales);
