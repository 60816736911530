import React from 'react';
import {
    Button,
    Grid,
    TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import Tile from '../../containers/nakedTile';

const styles = theme => ({
    // row: {
    //     '&:nth-of-type(odd)': {
    //         backgroundColor: theme.palette.background.default,
    //     },
    // },
    // tableHead: {
    //     fontWeight: 'bold',
    //     fontSize: 14,
    // },
    // paper: {
    //     width: '100%',
    //     marginTop: theme.spacing.unit * 3,
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     overflowX: 'auto',
    //     padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    // },
    // tileBottomDivider: {
    //     marginTop: theme.spacing.unit * 3,
    //     // marginBottom: theme.spacing.unit * 2,
    // },
    // dialogDivider: {
    //     marginTop: theme.spacing.unit * 2.5,
    //     marginBottom: theme.spacing.unit * 2,
    // },
    // bottomBarContainer: {
    //     width: '100%',
    //     textAlign: 'right',
    // },
    // textButton: {
    //     marginTop: theme.spacing.unit * 1.5,
    //     marginBottom: 0,
    // },
    // dialogActions: {
    //     marginRight: theme.spacing.unit * 2.5,
    // },
    // nameField: {
    //     width: 350,
    // },
    // addressField: {
    //     width: 250,
    // },
    // postcodeField: {
    //     width: 120,
    // },
    // countryField: {
    //     width: 200,
    //     marginLeft: theme.spacing.unit * 3,
    // },
    // emailField: {
    //     width: 350,
    // },
    // rightOfFieldButton: {
    //     marginTop: 10,
    //     marginLeft: theme.spacing.unit * 3,
    //     color: 'rgba(0, 121, 255, 0.87)',
    // },
    // dialogContentContainer: {
    //     marginTop: theme.spacing.unit * 2,
    //     marginBottom: theme.spacing.unit * 2,
    // },
    // dialogButton: {
    //     marginTop: theme.spacing.unit * 2,
    //     marginBottom: theme.spacing.unit * 1,
    // },
    // dialogContent: {
    //     paddingBottom: 0,
    //     minWidth: 300,
    // },
    // formHelperText: {
    //     color: '#f44336',
    // },
    // contactLocationIcon: {
    //     color: 'red',
    //     fontSize: 30,
    //     position: 'absolute',
    //     transform: 'translate(-50%, -100%)',
    // },
    // branchLocationIcon: {
    //     color: 'blue',
    //     fontSize: 30,
    //     position: 'absolute',
    //     transform: 'translate(-50%, -100%)',
    // },
    // progressContainer: {
    //     marginLeft: 'auto',
    //     marginRight: 'auto',
    //     marginTop: theme.spacing.unit * 3,
    //     marginBottom: theme.spacing.unit * 3,
    //     width: 60,
    // },
    // progress: {
    //     opacity: '.5',
    // },
    // inputLabel: {
    //     backgroundColor: '#fff',
    //     paddingLeft: 8,
    //     marginLeft: -5,
    //     paddingRight: 8,
    // },
    // menuFormControl: {
    //     marginBottom: theme.spacing.unit * 1,
    //     marginTop: theme.spacing.unit * 2,
    // },
    // agents: {
    //     marginRight: theme.spacing.unit * 3,
    // },
    // 'agents:not(:first-child)': {
    //     marginLeft: 0,
    //     backgroundColor: 'red',
    // },
    postcodeField: {
        backgroundColor: '#fff',
    },
});

class PostcodeLookupDetail extends React.Component {
    state = {
        disablePostcodeSearchButton: false,
        postcodeForm: {
            postcode: '',
        },
    };

    updatePostcodeData = (event) => {
        const { name, value } = event.target;

        const formattedValue = value.toUpperCase().replace(/[^a-z0-9\s]/gi, '');
        event.target.value = formattedValue;

        this.setState(state => ({
            postcodeForm: {
                ...state.postcodeForm,
                [name]: formattedValue,
            },
        }));
    }

    handlePostcodeSearch = (event) => {
        event.preventDefault();
        this.setState({
            disablePostcodeSearchButton: true,
        });
        this.props.postcodeSearch(this.state.postcodeForm.postcode).then(() => {
            this.setState({
                disablePostcodeSearchButton: false,
            });
        }).catch(() => {
            this.setState({
                disablePostcodeSearchButton: false,
            });
        });
    }

    render() {
        const {
            classes,
            errors,
        } = this.props;
        const {
            disablePostcodeSearchButton,
            postcodeForm,
        } = this.state;

        return (
            // <Tile
            //     style
            // >
            <Grid container spacing={0} style={{ textAlign: 'center' }}>
                <Grid item xs={12}>
                    <TextField
                        className={classes.formControl}
                        name="postcode"
                        id="postcode"
                        onChange={this.updatePostcodeData}
                        value={postcodeForm.postcode}
                        label="Postcode"
                        margin="normal"
                        variant="outlined"
                        required
                        error={!!errors.postcode}
                        helperText={errors.postcode}
                        InputProps={{ className: classes.postcodeField }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        className={classes.dialogButton}
                        onClick={this.handlePostcodeSearch}
                        disabled={disablePostcodeSearchButton}
                    >
                        SEARCH
                    </Button>
                </Grid>
            </Grid>
            // </Tile>
        );
    }
}

PostcodeLookupDetail.propTypes = {
    classes: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(PostcodeLookupDetail));
