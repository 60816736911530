import React from 'react';

import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment-timezone';
import { sortBy } from 'lodash';
import fetch, { fetchJson2 } from '../../utilities/fetch';
import titalise from '../../utilities/titalise';
import camelCaseToTitleCase from '../../utilities/camelCaseToTitleCase';
import { andrewScottSuppliesLogo, payWeeklyCarpetsLogo } from '../../components/v2/images';
import { paymentSourceLookUp } from '../../utilities/lookUpTables';
import commaFormattedDecimal from '../../utilities/commaFormattedDecimal';

const styles = (theme) => ({
    contentContainer: {
        margin: theme.spacing.unit * 4,
        fontSize: 12,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
    logo: {
        width: 128,
        marginBottom: 24,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
        height: 32,
    },
    table: {
        // borderCollapse: 'collapse',
        border: 'thin',
        marginTop: '25px',
        fontSize: '0.9em',
        fontFamily: 'sans-serif',
        // minWidth: '400px',
    },
    wideTable: {
        // borderCollapse: 'collapse',
        border: 'thin',
        margin: '25px 0',
        fontSize: '0.9em',
        fontFamily: 'sans-serif',
        width: '100%',
        // minWidth: '400px',
        marginBottom: 0,
    },
    tableHead: {
        '&:first-of-type': {
            borderTopLeftRadius: 4,
        },
        '&:last-of-type': {
            borderTopRightRadius: 4,
        },
        fontWeight: 'bold',
        fontSize: 12,
        // whiteSpace: 'nowrap',
        backgroundColor: '#5c6d77',
        paddingLeft: 16,
    },
    itemTableHead: {
        '&:first-of-type': {
            borderTopLeftRadius: 4,
        },
        '&:last-of-type': {
            borderTopRightRadius: 4,
        },
        fontWeight: 'bold',
        fontSize: 12,
        backgroundColor: '#839aa8',
        paddingRight: 0,
        paddingLeft: 16,
    },
    tableFooterCell: {
        '&:first-of-type': {
            borderBottomLeftRadius: 4,
        },
        '&:last-of-type': {
            borderBottomRightRadius: 4,
        },
        fontWeight: 'bold',
        fontSize: 12,
        // whiteSpace: 'nowrap',
        backgroundColor: '#839aa8',
        color: '#fff',
        marginTop: 16,
        textAlign: 'right',
    },
    customTableCell: {
        whiteSpace: 'nowrap',
        // paddingLeft: 16,
        paddingRight: 0,
        paddingLeft: 16,
    },
    amount: {
        textAlign: 'right',
        width: 40,
    },
    total: {
        textAlign: 'right',
        width: 40,
    },
    unitPrice: {
        textAlign: 'right',
        width: 40,
    },
    total: {
        textAlign: 'right',
        width: 40,
    },
    index: {
        textAlign: 'left',
        width: 20,
    },
    manufacturer: {
        textAlign: 'left',
        width: 150,
    },
    modelNo: {
        textAlign: 'left',
        width: '100%',
    },
    reference: {
        textAlign: 'left',
        width: 100,
    },
    quantity: {
        textAlign: 'right',
        width: 20,
    },
    name: {
        fontWeight: 'bold',
        lineHeight: 1.3,
        fontSize: 12,
    },
    companyName: {
        fontWeight: 'bold',
        lineHeight: 1.3,
        fontSize: 14,
    },
    itemSummary: {
        fontWeight: 'bold',
        lineHeight: 1.6,
        fontSize: 12,
    },
    addressSummary: {
        lineHeight: 1.2,
        fontSize: 12,
    },
    addressSummaryContainer: {
        padding: 16,
    },
    summaryRightCol: {
        textAlign: 'right',
        lineHeight: 1.2,
    },
    plainTable: {
        fontSize: 12,
        lineHeight: 1.6,
        borderBottom: 'none',
    },
    vatFooter: {
        fontSize: 12,
        lineHeight: 1.6,
        borderBottom: 'none',
        marginTop: theme.spacing.unit * 4,
    },
    compact: {
        lineHeight: 1.2,
    },
    headFootRow: {
        height: 32,
    },
    declined: {
        color: '#ababab',
    },
    bumpBottom4U: {
        marginBottom: theme.spacing.unit * 4,
    },
    bumpBottom3U: {
        marginBottom: theme.spacing.unit * 3,
    },
    bumpBottom2U: {
        marginBottom: theme.spacing.unit * 2,
    },
    bumpBottom1U: {
        marginBottom: theme.spacing.unit * 1,
    },
    bumpTop1U: {
        marginTop: theme.spacing.unit * 1,
    },
    bumpTop2U: {
        marginTop: theme.spacing.unit * 2,
    },
    bumpTop4U: {
        marginTop: theme.spacing.unit * 4,
    },
    addressContainer: {
        pageBreakInside: 'avoid',
        breakInside: 'avoid',
    },
    specialInstructionsTitle: {
        fontWeight: 600,
        height: 32,
    },
    specialInstructionsContainer: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: 25,
    },
    specialInstructionsBox: {
        border: '1px solid',
        borderRadius: 4,
    },
    specialInstructionsContent: {
        padding: 24,
    },
    right: {
        textAlign: 'right',
    },
    centre: {
        textAlign: 'center',
    },
    detailTable: {
        fontSize: 12,
    },
});

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 12,
    },
}))(TableCell);

class PurchaseOrderPdf extends React.Component {
    state = {
        purchaseOrderLoading: true,
    };

    static getDerivedStateFromProps(props, state) {
        const { purchaseOrderCode } = props.match.params;
        return {
            purchaseOrderCode,
        };
    }

    componentDidMount = async () => {
        const now = moment.tz(moment(), 'Europe/London');
        const currentDate = moment(now).format('YYYY-MM-DD');
        await this.fetchPurchaseOrderData();
    };

    fetchPurchaseOrderData = async () => {
        const { purchaseOrderCode } = this.state;
        this.setState({
            purchaseOrderLoading: true,
        });

        try {
            const purchaseOrder = await fetchJson2(`v2/purchaseOrder/${purchaseOrderCode}`);
            this.setState({
                purchaseOrder: purchaseOrder.payload,
            });
            // this.checkAlertsWarnings(agreements);
        } finally {
            this.setState({
                purchaseOrderLoading: false,
            });
        }
    };

    render = () => {
        const { classes } = this.props;
        const {
            currentDate,
            purchaseOrder,
            purchaseOrderLoading,
        } = this.state;

        // const imageUrl = window.location.host === 'portal2.payweeklycarpets.co.uk' ? payWeeklyCarpetsLogo : andrewScottSuppliesLogo;
        const imageUrl = payWeeklyCarpetsLogo;

        // let agreementBalance = '';
        // let frequency = '';
        // let runningBalance = [];
        // let estimatedCompletionDate = '';
        // if (!purchaseOrderLoading) {
        //     agreementBalance = agreement.plan.repaymentAmount - agreement.plan.paidAmount;
        //     const frequencyInterval = agreement.plan.scheduleInterval > 1 ? `${agreement.plan.scheduleInterval} ` : '';
        //     frequency = `${frequencyInterval}${agreement.plan.scheduleFrequency.toLowerCase()}`;
        //     // runningBalance = agreement.payments.reduce((acc, payment) => [...acc, { code: payment.code, balance: acc[acc.length - 1].balance - payment.amount }], [{ balance: agreement.plan.repaymentAmount }]);
        //     let currentBalance = -Math.abs(agreement.plan.repaymentAmount);
        //     runningBalance = sortBy(agreement.payments, 'paymentDateTime').reduce((acc, payment) => {
        //         currentBalance = payment.transactionStatus === 'AUTHORISED' ? currentBalance + payment.amount : currentBalance;
        //         return [...acc, { code: payment.code, balance: currentBalance }];
        //     }, []);
        //     const numberOfOutstandingPayments = (Math.ceil((agreement.plan.repaymentAmount - agreement.plan.paidAmount) / agreement.plan.recurringPaymentAmount)) - 1;
        //     estimatedCompletionDate = moment(agreement.plan.nextPaymentDate).add(numberOfOutstandingPayments, agreement.plan.scheduleFrequency === 'MONTHLY' ? 'months' : 'weeks');
        // }
        let nonNormalUnitTypeItemsOnPurchaseOrder = false;
        if (!purchaseOrderLoading) {
            nonNormalUnitTypeItemsOnPurchaseOrder = !!this.state.purchaseOrder.purchaseOrderItems.filter((item) => item.unitType !== 'NORMAL').length;
            console.log('>>> nonNormalUnitTypeItemsOnPurchaseOrder:', nonNormalUnitTypeItemsOnPurchaseOrder);
        }

        return (
            <>
                {!purchaseOrderLoading
                    ? (
                        <div className={classes.contentContainer}>
                            <Grid
                                container
                                spacing={0}
                            >
                                <Grid item xs={4}>
                                    <img
                                        className={classes.logo}
                                        src={imageUrl}
                                        alt="Pay Weekly Logo"
                                    />
                                    <Grid
                                        container
                                        spacing={0}
                                        className={`${classes.addressContainer}, ${classes.bumpBottom1U}`}
                                    >
                                        <Grid item xs={12} className={classes.companyName}>
                                            Buoyant Group Ltd t/a Pay Weekly Carpets
                                        </Grid>
                                        <Grid item xs={12} className={classes.plainTable}>
                                            <Typography className={classes.compact}>4 Mandale</Typography>
                                            <Typography className={classes.compact}>South Street</Typography>
                                            <Typography className={classes.compact}>Keighley</Typography>
                                            <Typography className={classes.compact}>BD21 1DB</Typography>
                                        </Grid>
                                        <Grid item xs={12} className={`${classes.plainTable} ${classes.bumpTop1U}`}>
                                            <Typography className={classes.compact}>Tel: 01535 609200</Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item xs={4} />

                                <Grid item xs={4}>
                                    <Typography
                                        variant="h4"
                                        align="right"
                                        gutterBottom
                                    >
                                        Purchase Order
                                    </Typography>
                                    <div className={classes.detailTable}>
                                        {/* <Table aria-label="simple table">
                                            <TableBody>
                                                <TableRow key="Date">
                                                    <TableCell scope="row" className={classes.plainTable}> */}
                                        <Grid
                                            container
                                            spacing={0}
                                        >
                                            <Grid item xs={6} className={classes.name}>
                                                Date
                                            </Grid>
                                            <Grid item xs={6} className={classes.summaryRightCol}>
                                                {moment(purchaseOrder.createdDateTime).format('DD/MM/YYYY')}
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={0}
                                        >
                                            <Grid item xs={6} className={classes.name}>
                                                PO Ref
                                            </Grid>
                                            <Grid item xs={6} className={classes.summaryRightCol}>
                                                PO
                                                {purchaseOrder.code}
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={0}
                                        >
                                            <Grid item xs={6} className={classes.name}>
                                                Cost Centre
                                            </Grid>
                                            <Grid item xs={6} className={classes.summaryRightCol}>
                                                {purchaseOrder.costCentre}
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={0}
                                        >
                                            <Grid item xs={6} className={classes.name}>
                                                Our Account Ref
                                            </Grid>
                                            <Grid item xs={6} className={classes.summaryRightCol}>
                                                {purchaseOrder.supplier.ourAccountReference}
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={0}
                                        >
                                            <Grid item xs={6} className={classes.name}>
                                                Your Account Ref
                                            </Grid>
                                            <Grid item xs={6} className={classes.summaryRightCol}>
                                                {purchaseOrder.supplier.accountReference}
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={0}
                                        >
                                            <Grid item xs={6} className={classes.name}>
                                                Supplier Contact
                                            </Grid>
                                            <Grid item xs={6} className={classes.summaryRightCol}>
                                                {purchaseOrder.supplierContact}
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={0}
                                        >
                                            <Grid item xs={6} className={classes.name}>
                                                Your Quote Ref
                                            </Grid>
                                            <Grid item xs={6} className={classes.summaryRightCol}>
                                                {purchaseOrder.quoteReference}
                                            </Grid>
                                        </Grid>
                                        {/* </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table> */}
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={0}
                            >
                                <Grid item xs={5}>
                                    <Table className={classes.table} size="small" padding="dense">
                                        <TableHead>
                                            <TableRow className={classes.headFootRow}>
                                                <CustomTableCell className={classes.tableHead}>Supplier</CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow className={classes.row}>
                                                <CustomTableCell className={`${classes.customTableCell}, ${classes.addressSummaryContainer}`} component="th" scope="row">
                                                    <Typography className={classes.name}>{purchaseOrder.supplier.name}</Typography>
                                                    <Typography className={classes.addressSummary}>{purchaseOrder.supplier.address.address1}</Typography>
                                                    {purchaseOrder.supplier.address.address2
                                                        ? (
                                                            <Typography className={classes.addressSummary}>{purchaseOrder.supplier.address.address2}</Typography>
                                                        )
                                                        : ''}
                                                    <Typography className={classes.addressSummary}>{purchaseOrder.supplier.address.townCity}</Typography>
                                                    <Typography className={`${classes.addressSummary} ${classes.bumpBottom2U}`}>{purchaseOrder.supplier.address.postcode}</Typography>
                                                    <Typography>{purchaseOrder.supplier.telephoneNumber}</Typography>
                                                    <Typography>{purchaseOrder.supplier.emailAddress}</Typography>
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={5}>
                                    <Table className={classes.table} size="small" padding="dense">
                                        <TableHead>
                                            <TableRow className={classes.headFootRow}>
                                                <CustomTableCell className={classes.tableHead}>Delivery Address</CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow className={classes.row}>
                                                <CustomTableCell className={`${classes.customTableCell}, ${classes.addressSummaryContainer}`} component="th" scope="row">
                                                    <Typography className={classes.name}>Pay Weekly Carpets Ltd</Typography>
                                                    <Typography className={classes.addressSummary}>{purchaseOrder.deliveryBranch.address.address1}</Typography>
                                                    {purchaseOrder.deliveryBranch.address.address2
                                                        ? (
                                                            <Typography className={classes.addressSummary}>{purchaseOrder.deliveryBranch.address.address2}</Typography>
                                                        )
                                                        : ''}
                                                    <Typography className={classes.addressSummary}>{purchaseOrder.deliveryBranch.address.townCity}</Typography>
                                                    <Typography className={`${classes.addressSummary} ${classes.bumpBottom2U}`}>{purchaseOrder.deliveryBranch.address.postcode}</Typography>
                                                    <Typography>{purchaseOrder.deliveryBranch.telephoneNumber}</Typography>
                                                    <Typography>{purchaseOrder.deliveryBranch.salesEmailAddress}</Typography>
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>

                                <Grid
                                    container
                                    spacing={0}
                                >
                                    <Grid item xs={12}>
                                        <Table className={classes.wideTable} size="small" padding="dense">
                                            <TableHead>
                                                <TableRow className={classes.headFootRow}>
                                                    <CustomTableCell className={classes.tableHead}>Delivery Date</CustomTableCell>
                                                    <CustomTableCell className={classes.tableHead}>Payment Terms</CustomTableCell>
                                                    <CustomTableCell className={classes.tableHead}>Requested By</CustomTableCell>
                                                    <CustomTableCell className={classes.tableHead}>Branch</CustomTableCell>
                                                    <CustomTableCell className={classes.tableHead}>Authorised By</CustomTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow className={classes.row}>
                                                    <CustomTableCell className={classes.customTableCell} component="th" scope="row">{purchaseOrder.requestedDeliveryDate ? moment(purchaseOrder.requestedDeliveryDate).format('DD/MM/YYYY') : 'ASAP'}</CustomTableCell>
                                                    <CustomTableCell className={classes.customTableCell} component="th" scope="row">{purchaseOrder.supplier.paymentTerms}</CustomTableCell>
                                                    <CustomTableCell className={classes.customTableCell} component="th" scope="row">{purchaseOrder.createdBy}</CustomTableCell>
                                                    <CustomTableCell className={classes.customTableCell} component="th" scope="row">{purchaseOrder.deliveryBranch.name}</CustomTableCell>
                                                    <CustomTableCell className={classes.customTableCell} component="th" scope="row">{purchaseOrder.authorisedBy}</CustomTableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    spacing={0}
                                >
                                    <Grid item xs={12}>
                                        <Table className={`${classes.table} ${classes.bumpTop4U}`} size="small" padding="dense">
                                            <TableHead>
                                                <TableRow className={classes.headFootRow}>
                                                    <CustomTableCell className={classes.itemTableHead}>#</CustomTableCell>
                                                    <CustomTableCell className={classes.itemTableHead}>Manufacturer</CustomTableCell>
                                                    <CustomTableCell className={classes.itemTableHead}>Model</CustomTableCell>
                                                    <CustomTableCell className={`${classes.itemTableHead} ${classes.right}`}>Dimension</CustomTableCell>
                                                    <CustomTableCell className={classes.itemTableHead}>Reference</CustomTableCell>
                                                    <CustomTableCell className={`${classes.itemTableHead} ${classes.right}`}>Unit Cost</CustomTableCell>
                                                    <CustomTableCell className={`${classes.itemTableHead} ${classes.right}`}>Qty</CustomTableCell>
                                                    <CustomTableCell className={`${classes.itemTableHead} ${classes.right}`}>Line</CustomTableCell>
                                                    <CustomTableCell className={`${classes.itemTableHead} ${classes.right}`}>Total</CustomTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {purchaseOrder.purchaseOrderItems.map((purchaseOrderItem, i) => {
                                                    const isFlooring = purchaseOrderItem.product.productType === 'Flooring';
                                                    const isNonNormalUnitType = purchaseOrderItem.purchaseOrderUnitType !== 'NORMAL';
                                                    const unit = isFlooring ? 'm' : 'mm';
                                                    return (
                                                        <TableRow className={classes.row}>
                                                            <CustomTableCell className={[classes.customTableCell, classes.index]} component="th" scope="row">
                                                                {i + 1}
                                                            </CustomTableCell>
                                                            <CustomTableCell className={[classes.customTableCell, classes.manufacturer]} component="th" scope="row">
                                                                {purchaseOrderItem.product.manufacturer.name}
                                                            </CustomTableCell>
                                                            <CustomTableCell className={[classes.customTableCell, classes.modelNo]} component="th" scope="row">
                                                                {purchaseOrderItem.product.modelNo}
                                                            </CustomTableCell>
                                                            <CustomTableCell className={`${classes.customTableCell} ${classes.right}`} component="th" scope="row">
                                                                <>
                                                                    {isFlooring
                                                                        ? (
                                                                            <>
                                                                                {`${purchaseOrderItem.width}${unit}${isFlooring && isNonNormalUnitType ? '' : ` x ${purchaseOrderItem.length}${unit}`}`}
                                                                            </>
                                                                        )
                                                                        : (
                                                                            <>
                                                                                ''
                                                                            </>
                                                                        )}
                                                                </>
                                                            </CustomTableCell>
                                                            <CustomTableCell className={`${classes.customTableCell} ${classes.reference}`} component="th" scope="row">
                                                                {purchaseOrderItem.reference}
                                                            </CustomTableCell>
                                                            <CustomTableCell className={`${classes.customTableCell} ${classes.unitPrice}`} component="th" scope="row">
                                                                £
                                                                {purchaseOrderItem.costPrice.toFixed(2)}
                                                            </CustomTableCell>
                                                            <CustomTableCell className={`${classes.customTableCell} ${classes.quantity}`} component="th" scope="row">
                                                                {`${purchaseOrderItem.quantity}${isNonNormalUnitType ? ` x ${purchaseOrderItem.purchaseOrderUnitType}` : ''}`}
                                                            </CustomTableCell>
                                                            <CustomTableCell className={`${classes.customTableCell} ${classes.unitPrice}`} component="th" scope="row">
                                                                {isNonNormalUnitType
                                                                    ? (
                                                                        <>
                                                                            n/a
                                                                        </>
                                                                    )
                                                                    : (
                                                                        <>
                                                                            £
                                                                            {(purchaseOrderItem.quantity * purchaseOrderItem.costPrice).toFixed(2)}
                                                                        </>
                                                                    )}
                                                                {/* £
                                                            {purchaseOrderItem.costPrice.toFixed(2)} */}
                                                            </CustomTableCell>
                                                            <CustomTableCell className={`${classes.customTableCell} ${classes.total}`} component="th" scope="row">
                                                                {purchaseOrderItem.purchaseOrderUnitType === 'ROLL'
                                                                    ? (
                                                                        <>
                                                                            n/a
                                                                        </>
                                                                    )
                                                                    : (
                                                                        <>
                                                                            £
                                                                            {(purchaseOrderItem.quantity * purchaseOrderItem.costPrice).toFixed(2)}
                                                                        </>
                                                                    )}
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    spacing={0}
                                >
                                    <Grid item xs={7}>
                                        <div className={classes.specialInstructionsContainer}>
                                            <Typography className={classes.specialInstructionsTitle}>Special Instructions</Typography>
                                            <div className={classes.specialInstructionsBox}>
                                                <Typography className={classes.specialInstructionsContent}>{purchaseOrder.specialInstructions}</Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={2} />
                                    <Grid item xs={3} className={classes.bumpTop2U}>
                                        <Table aria-label="simple table">
                                            <TableBody>
                                                <TableRow key="Date">
                                                    <TableCell scope="row" className={classes.plainTable}>
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                        >
                                                            <Grid item xs={6} className={classes.itemSummary}>
                                                                TOTAL NET
                                                            </Grid>
                                                            <Grid item xs={6} className={classes.summaryRightCol}>
                                                                {nonNormalUnitTypeItemsOnPurchaseOrder
                                                                    ? (
                                                                        <>
                                                                            n/a
                                                                        </>
                                                                    )
                                                                    : (
                                                                        <>
                                                                            £
                                                                            {commaFormattedDecimal(purchaseOrder.totalNet.toFixed(2))}
                                                                        </>
                                                                    )}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                        >
                                                            <Grid item xs={6} className={classes.itemSummary}>
                                                                DELIVERY
                                                            </Grid>
                                                            <Grid item xs={6} className={classes.summaryRightCol}>
                                                                {nonNormalUnitTypeItemsOnPurchaseOrder
                                                                    ? (
                                                                        <>
                                                                            n/a
                                                                        </>
                                                                    )
                                                                    : (
                                                                        <>
                                                                            £
                                                                            {commaFormattedDecimal(purchaseOrder.deliveryFee.toFixed(2))}
                                                                        </>
                                                                    )}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                        >
                                                            <Grid item xs={6} className={classes.itemSummary}>
                                                                VAT
                                                            </Grid>
                                                            <Grid item xs={6} className={classes.summaryRightCol}>
                                                                {nonNormalUnitTypeItemsOnPurchaseOrder
                                                                    ? (
                                                                        <>
                                                                            n/a
                                                                        </>
                                                                    )
                                                                    : (
                                                                        <>
                                                                            £
                                                                            {commaFormattedDecimal(purchaseOrder.vat.toFixed(2))}
                                                                        </>
                                                                    )}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                        >
                                                            <Grid item xs={6} className={classes.itemSummary}>
                                                                TOTAL
                                                            </Grid>
                                                            <Grid item xs={6} className={classes.summaryRightCol}>
                                                                {nonNormalUnitTypeItemsOnPurchaseOrder
                                                                    ? (
                                                                        <>
                                                                            n/a
                                                                        </>
                                                                    )
                                                                    : (
                                                                        <>
                                                                            £
                                                                            {commaFormattedDecimal(purchaseOrder.total.toFixed(2))}
                                                                        </>
                                                                    )}
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                className={classes.addressContainer}
                            >
                                <Grid item xs={12} className={classes.vatFooter}>
                                    <Typography align="center" />
                                </Grid>
                            </Grid>

                        </div>
                    )
                    : ''}
            </>

        );
    };
}

export default withStyles(styles)(withRouter(PurchaseOrderPdf));
