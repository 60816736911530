import React from 'react';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment-timezone';

import titalise from '../utilities/titalise';

const now = moment.tz(moment(), 'Europe/London');
const currentDateTime = moment(now).format('YYYY-MM-DD HH:mm');

const styles = (theme) => ({
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
        width: 500,
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    filterSet: {
        colour: 'red',
    },
    minTextField: {
        paddingRight: theme.spacing.unit * 1.5,
    },
    maxTextField: {
        paddingLeft: theme.spacing.unit * 1.5,
    },
    progressContainer: {
        marginBottom: theme.spacing.unit * 3,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 200,
        width: 60,
    },
    filterNotSet: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
});

const currentDate = moment.tz(moment(), 'Europe/London');
const lastMonthStartDate = moment(currentDate).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
const lastMonthEndDate = moment(currentDate).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');

// const thisWeekStartDate = moment(currentDate).startOf('week').format('YYYY-MM-DD');
// const thisWeekEndDate = moment(currentDate).endOf('week').format('YYYY-MM-DD');

class reportCriteriaDialog extends React.Component {
    state = {
        disableSubmitButton: false,
        errors: [],
        formData: {
        },
        formDataDefault: {
            atDate: lastMonthEndDate,
            branchCode: 'null',
            endDate: lastMonthEndDate,
            report: 'Agreement Without Card',
            startDate: lastMonthStartDate,
            visitStatus: 'null',
            status: 'null',
            type: 'null',
            productType: 'null',
            productTypeCode: 'null',
            day: 'YESTERDAY',
            fullyDelivered: 'null',
            assignedToContactCode: 'null',
            createdByContactCode: 'null',
            productTypeCode: 'null',
            vulnerabilityTypeCode: 'null',
            groupCode: 'null',
            postcode: null,
            leadSourceCode: 'null',
            campaignCode: 'null',
            agentContactCode: 'null',
            includeChecked: 0,
        },
    };

    componentDidMount = () => {
        this.presetFormData();
    };

    presetFormData = () => {
        this.setState((state) => ({
            formData: {
                ...state.formDataDefault,
            },
        }));
    };

    handleCloseDialog = () => {
        this.props.resetShowCriteriaDialog();
    };

    updateData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        let date;
        switch (name) {
        case 'report':
            if (value === 'paymentDeclined') {
                if (this.state.formData.day === 'YESTERDAY') {
                    date = moment(currentDate).subtract(1, 'day').format('YYYY-MM-DD');
                } else {
                    date = moment(currentDate).format('YYYY-MM-DD');
                }
                formattedValue = value;
                this.setState((state) => ({
                    formData: {
                        ...state.formData,
                        atDate: date,
                    },
                }));
            }
            formattedValue = value;
            break;
        case 'day':
            if (value === 'YESTERDAY') {
                date = moment(currentDate).subtract(1, 'day').format('YYYY-MM-DD');
            } else {
                date = moment(currentDate).format('YYYY-MM-DD');
            }
            formattedValue = value;
            this.setState((state) => ({
                formData: {
                    ...state.formData,
                    atDate: date,
                },
            }));
            break;
        case 'postcode': formattedValue = value.replace(/[^a-zA-Z0-9\s+]/g, '').toUpperCase();
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;
        this.setState((state) => ({
            formData: {
                ...state.formData,
                [name]: formattedValue,
            },
        }));
    };

    handleSubmitButtonClick = () => {
        this.setState({
            disableSubmitButton: true,
        });
        // const formattedFormData = {};
        // Object.keys(this.state.formData).forEach((k, i) => {
        //     if (this.state.formData[k] === '') {
        //         formattedFormData[k] = null;
        //         return;
        //     }
        //     formattedFormData[k] = this.state.formData[k];
        // });

        const startDate = (this.state.formData.visitStatus === 'REBOOK' || this.state.formData.visitStatus === 'INVITE_EXPIRED') ? null : this.state.formData.startDate;
        const endDate = (this.state.formData.visitStatus === 'REBOOK' || this.state.formData.visitStatus === 'INVITE_EXPIRED') ? null : this.state.formData.endDate;
        const formattedFormData = {
            ...this.state.formData,
            agentContactCode: (!this.state.formData.agentContactCode || this.state.formData.agentContactCode === 'null') ? null : this.state.formData.agentContactCode,
            endDate,
            productTypeCode: (!this.state.formData.productTypeCode || this.state.formData.productTypeCode === 'null') ? null : this.state.formData.productTypeCode,
            startDate,
        };

        this.props.updateCriteria(formattedFormData).then(() => {
            this.setState({
                disableSubmitButton: false,
            });
            this.handleCloseDialog();
        }).catch(() => {
            this.setState({
                disableSubmitButton: false,
            });
        });
    };

    handleStartDateChange = (chosenDate) => {
        this.setState((state) => ({
            formData: {
                ...state.formData,
                startDate: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    handleEndDateChange = (chosenDate) => {
        this.setState((state) => ({
            formData: {
                ...state.formData,
                endDate: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    handleAtDateChange = (chosenDate) => {
        this.setState((state) => ({
            formData: {
                ...state.formData,
                atDate: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    updateCheckbox = (name) => (event) => {
        const newState = event.target.checked ? 1 : 0;
        this.setState((state) => ({
            formData: {
                ...state.formData,
                [name]: newState,
            },
        }));
    };

    render() {
        const {
            classes,
            reportDataLoading,
            reportSummaryLoading,
            reports,
            showCriteriaDialog,
            user,
            userLoading,
        } = this.props;

        const {
            disableSubmitButton,
            errors,
            formData,
        } = this.state;

        // console.log('>>> criteria:', formData);
        // !userLoading && reports.map((report) => {
        //     console.log('>>> ', user.userScopes);
        //     console.log('>>> ', [`report:${report.name}:*`, `report:${report.name}:read`].some(scope => user.userScopes.includes(scope)));
        //     console.log('>>> ', [`report:${report.name}:*`, `report:${report.name}:read`]);
        // });

        let criteriaContent;
        // if ((formData || []).length !== 0) {
        switch (formData.report) {
        case 'fastTrackLeads': criteriaContent = (
            <>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                    minDate={new Date(formData.startDate)}
                />
            </>
        );
            break;
        case 'fastTrackSalesAgreements': criteriaContent = (
            <>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                    minDate={new Date(formData.startDate)}
                />
            </>
        );
            break;
        case 'furnitureLeads': criteriaContent = (
            <>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                    minDate={new Date(formData.startDate)}
                />
            </>
        );
            break;
        case 'externalDebtRecoveryCalls': criteriaContent = (
            <>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                    minDate={new Date(formData.startDate)}
                />
            </>
        );
            break;
        case 'visitItemsUndelivered': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.branchCode}
                    >
                        <InputLabel htmlFor="branchCode" className={classes.inputLabel}>Branch</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="branchCode"
                                    id="branchCode"
                                />
                            )}
                            value={formData.branchCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.branches.filter((branch) => branch.branchTypeName === 'Building').map((branch) => (
                                <MenuItem
                                    key={branch.code}
                                    value={branch.code || ''}
                                >
                                    {branch.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.branchCode && (
                            <FormHelperText>{errors.branchCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.productTypeCode}
                    >
                        <InputLabel htmlFor="productTypeCode" className={classes.inputLabel}>Product Type</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="productTypeCode"
                                    id="productTypeCode"
                                />
                            )}
                            value={formData.productTypeCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.productTypes.map((productType) => (
                                <MenuItem
                                    key={productType.code}
                                    value={productType.code || ''}
                                >
                                    {productType.description}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.productTypeCode && (
                            <FormHelperText>{errors.productTypeCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                {formData.productTypeCode !== 'REBOOK' && formData.productTypeCode !== 'INVITE_EXPIRED'
                    ? (
                        <>
                            <DatePicker
                                name="startDate"
                                id="startDate"
                                margin="normal"
                                label="Start Date"
                                value={moment(formData.startDate).format('YYYY-MM-DD')}
                                onChange={this.handleStartDateChange}
                                fullWidth
                                variant="outlined"
                                format="DD-MM-YYYY"
                                showTodayButton
                                disableFuture
                                invalidLabel="No limit"
                                autoOk
                            />
                            <DatePicker
                                name="endDate"
                                id="endDate"
                                margin="normal"
                                label="End Date"
                                value={formData.endDate}
                                onChange={this.handleEndDateChange}
                                fullWidth
                                variant="outlined"
                                format="DD-MM-YYYY"
                                showTodayButton
                                invalidLabel="No limit"
                                autoOk
                                minDate={new Date(formData.startDate)}
                            />
                        </>
                    )
                    : ''}
            </>
        );
            break;
        case 'canvassing': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <TextField
                        onChange={this.updateData}
                        className={classes.formControl}
                        name="postcode"
                        id="postcode"
                        label="postcode"
                        margin="normal"
                        variant="outlined"
                        value={formData.postcode}
                        fullWidth
                        required
                        errors={!!errors.postcode}
                        helperText={errors.postcode}
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.createdByContactCode}
                    >
                        <InputLabel htmlFor="createdByContactCode" className={classes.inputLabel}>Created By</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="createdByContactCode"
                                    id="createdByContactCode"
                                />
                            )}
                            value={formData.createdByContactCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.users.map((assignedToUser) => (
                                <MenuItem
                                    key={assignedToUser.contact.code}
                                    value={assignedToUser.contact.code || ''}
                                >
                                    {`${assignedToUser.contact.firstname} ${assignedToUser.contact.surname}`}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.createdByContactCode && (
                            <FormHelperText>{errors.createdByContactCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    invalidLabel="No limit"
                    autoOk
                />
            </>
        );
            break;
        case 'leadsByPostcode': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <TextField
                        onChange={this.updateData}
                        className={classes.formControl}
                        name="postcode"
                        id="postcode"
                        label="postcode"
                        margin="normal"
                        variant="outlined"
                        value={formData.postcode}
                        fullWidth
                        required
                        errors={!!errors.postcode}
                        helperText={errors.postcode}
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.productTypeCode}
                    >
                        <InputLabel htmlFor="productTypeCode" className={classes.inputLabel}>Product Type</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="productTypeCode"
                                    id="productTypeCode"
                                />
                            )}
                            value={formData.productTypeCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.productTypes.map((productType) => (
                                <MenuItem
                                    key={productType.code}
                                    value={productType.code || ''}
                                >
                                    {productType.description}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.productTypeCode && (
                            <FormHelperText>{errors.productTypeCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.createdByContactCode}
                    >
                        <InputLabel htmlFor="createdByContactCode" className={classes.inputLabel}>Created By</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="createdByContactCode"
                                    id="createdByContactCode"
                                />
                            )}
                            value={formData.createdByContactCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.users.map((assignedToUser) => (
                                <MenuItem
                                    key={assignedToUser.contact.code}
                                    value={assignedToUser.contact.code || ''}
                                >
                                    {`${assignedToUser.contact.firstname} ${assignedToUser.contact.surname}`}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.createdByContactCode && (
                            <FormHelperText>{errors.createdByContactCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    invalidLabel="No limit"
                    autoOk
                />
            </>
        );
            break;
        case 'leadsByCampaign': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.campaignCode}
                    >
                        <InputLabel htmlFor="campaignCode" className={classes.inputLabel}>Lead Campaign</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="campaignCode"
                                    id="campaignCode"
                                />
                            )}
                            value={formData.campaignCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.leadCampaigns.map((leadCampaign) => (
                                <MenuItem
                                    key={leadCampaign.code}
                                    value={leadCampaign.code || ''}
                                >
                                    {leadCampaign.title}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.campaignCode && (
                            <FormHelperText>{errors.campaignCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    invalidLabel="No limit"
                    autoOk
                />
            </>
        );
            break;
        case 'installationIncludedAgreements': criteriaContent = (
            <>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    invalidLabel="No limit"
                    autoOk
                />
            </>
        );
            break;
        case 'manufacturedItems': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.branchCode}
                    >
                        <InputLabel htmlFor="groupCode" className={classes.inputLabel}>Group</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="groupCode"
                                    id="groupCode"
                                />
                            )}
                            value={formData.groupCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.groups.map((group) => (
                                <MenuItem
                                    key={group.code}
                                    value={group.code || ''}
                                >
                                    {group.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.groupCode && (
                            <FormHelperText>{errors.groupCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.productTypeCode}
                    >
                        <InputLabel htmlFor="productTypeCode" className={classes.inputLabel}>Product Type</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="productTypeCode"
                                    id="productTypeCode"
                                />
                            )}
                            value={formData.productTypeCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.productTypes.map((productType) => (
                                <MenuItem
                                    key={productType.code}
                                    value={productType.code || ''}
                                >
                                    {productType.description}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.productTypeCode && (
                            <FormHelperText>{errors.productTypeCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.createdByContactCode}
                    >
                        <InputLabel htmlFor="createdByContactCode" className={classes.inputLabel}>Created By</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="createdByContactCode"
                                    id="createdByContactCode"
                                />
                            )}
                            value={formData.createdByContactCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.users.map((assignedToUser) => (
                                <MenuItem
                                    key={assignedToUser.contact.code}
                                    value={assignedToUser.contact.code || ''}
                                >
                                    {`${assignedToUser.contact.firstname} ${assignedToUser.contact.surname}`}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.createdByContactCode && (
                            <FormHelperText>{errors.createdByContactCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    invalidLabel="No limit"
                    autoOk
                />
            </>
        );
            break;
        case 'complaints': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.branchCode}
                    >
                        <InputLabel htmlFor="branchCode" className={classes.inputLabel}>Customer Branch</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="branchCode"
                                    id="branchCode"
                                />
                            )}
                            value={formData.branchCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.branches.filter((branch) => branch.branchTypeName === 'Building').map((branch) => (
                                <MenuItem
                                    key={branch.code}
                                    value={branch.code || ''}
                                >
                                    {branch.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.branchCode && (
                            <FormHelperText>{errors.branchCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.status}
                    >
                        <InputLabel htmlFor="status" className={classes.inputLabel}>Complaint Status</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="status"
                                    id="status"
                                />
                            )}
                            value={formData.status}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.complaintStatuses.map((status) => (
                                <MenuItem
                                    key={status}
                                    value={status || ''}
                                >
                                    {titalise(status.replace(/_/g, ' '))}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.status && (
                            <FormHelperText>{errors.status}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.type}
                    >
                        <InputLabel htmlFor="type" className={classes.inputLabel}>Complaint Type</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="type"
                                    id="type"
                                />
                            )}
                            value={formData.type}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.complaintTypes.map((type) => (
                                <MenuItem
                                    key={type}
                                    value={type || ''}
                                >
                                    {titalise(type.replace(/_/g, ' '))}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.type && (
                            <FormHelperText>{errors.type}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.assignedToContactCode}
                    >
                        <InputLabel htmlFor="assignedToContactCode" className={classes.inputLabel}>Assigned To</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="assignedToContactCode"
                                    id="assignedToContactCode"
                                />
                            )}
                            value={formData.assignedToContactCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.users.map((assignedToUser) => (
                                <MenuItem
                                    key={assignedToUser.contact.code}
                                    value={assignedToUser.contact.code || ''}
                                >
                                    {`${assignedToUser.contact.firstname} ${assignedToUser.contact.surname}`}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.assignedToContactCode && (
                            <FormHelperText>{errors.assignedToContactCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </>
        );
            break;
        case 'deliveredAgreementIncomeToDate': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.productTypeCode}
                    >
                        <InputLabel htmlFor="productTypeCode" className={classes.inputLabel}>Product Type</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="productTypeCode"
                                    id="productTypeCode"
                                />
                            )}
                            value={formData.productTypeCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.productTypes.map((productType) => (
                                <MenuItem
                                    key={productType.code}
                                    value={productType.code || ''}
                                >
                                    {productType.description}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.productTypeCode && (
                            <FormHelperText>{errors.productTypeCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    invalidLabel="No limit"
                    autoOk
                />

            </>
        );
            break;
        case 'agreementIncomeToDate': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.productTypeCode}
                    >
                        <InputLabel htmlFor="productTypeCode" className={classes.inputLabel}>Product Type</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="productTypeCode"
                                    id="productTypeCode"
                                />
                            )}
                            value={formData.productTypeCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.productTypes.map((productType) => (
                                <MenuItem
                                    key={productType.code}
                                    value={productType.code || ''}
                                >
                                    {productType.description}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.productTypeCode && (
                            <FormHelperText>{errors.productTypeCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.fullyDelivered}
                    >
                        <InputLabel htmlFor="fullyDelivered" className={classes.inputLabel}>Furniture Delivery</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="fullyDelivered"
                                    id="fullyDelivered"
                                />
                            )}
                            value={formData.fullyDelivered}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            <MenuItem
                                key="1"
                                value="1"
                            >
                                Fully Delivered
                            </MenuItem>
                            <MenuItem
                                key="0"
                                value="0"
                            >
                                Not Fully Delivered
                            </MenuItem>
                        </Select>
                        {errors.fullyDelivered && (
                            <FormHelperText>{errors.fullyDelivered}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    invalidLabel="No limit"
                    autoOk
                />

            </>
        );
            break;
        case 'referredLeads': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.productTypeCode}
                    >
                        <InputLabel htmlFor="productTypeCode" className={classes.inputLabel}>Product Type</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="productTypeCode"
                                    id="productTypeCode"
                                />
                            )}
                            value={formData.productTypeCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.productTypes.map((productType) => (
                                <MenuItem
                                    key={productType.code}
                                    value={productType.code || ''}
                                >
                                    {productType.description}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.productTypeCode && (
                            <FormHelperText>{errors.productTypeCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    invalidLabel="No limit"
                    autoOk
                />

            </>
        );
            break;
        case 'contactVulnerabilities': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.vulnerabilityTypeCode}
                    >
                        <InputLabel htmlFor="vulnerabilityTypeCode" className={classes.inputLabel}>Vulnerability Type</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="vulnerabilityTypeCode"
                                    id="vulnerabilityTypeCode"
                                />
                            )}
                            value={formData.vulnerabilityTypeCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.vulnerabilityTypes.map((vulnerabilityType) => (
                                <MenuItem
                                    key={vulnerabilityType.code}
                                    value={vulnerabilityType.code || ''}
                                >
                                    {vulnerabilityType.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.vulnerabilityTypeCode && (
                            <FormHelperText>{errors.vulnerabilityTypeCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    invalidLabel="No limit"
                    autoOk
                />

            </>
        );
            break;
        case 'sales': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.productTypeCode}
                    >
                        <InputLabel htmlFor="productTypeCode" className={classes.inputLabel}>Product Type</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="productTypeCode"
                                    id="productTypeCode"
                                />
                            )}
                            value={formData.productTypeCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.productTypes.map((productType) => (
                                <MenuItem
                                    key={productType.code}
                                    value={productType.code || ''}
                                >
                                    {productType.description}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.productTypeCode && (
                            <FormHelperText>{errors.productTypeCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    invalidLabel="No limit"
                    autoOk
                />

            </>
        );
            break;
        case 'salesByPostcodeDistrict': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.branchCode}
                    >
                        <InputLabel htmlFor="branchCode" className={classes.inputLabel}>Branch</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="branchCode"
                                    id="branchCode"
                                />
                            )}
                            value={formData.branchCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.branches.filter((branch) => branch.branchTypeName === 'Building').map((branch) => (
                                <MenuItem
                                    key={branch.code}
                                    value={branch.code || ''}
                                >
                                    {branch.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.branchCode && (
                            <FormHelperText>{errors.branchCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    invalidLabel="No limit"
                    autoOk
                />

            </>
        );
            break;
        case 'selfMeasurementChecks': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.branchCode}
                    >
                        <InputLabel htmlFor="branchCode" className={classes.inputLabel}>Branch</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="branchCode"
                                    id="branchCode"
                                />
                            )}
                            value={formData.branchCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.branches.filter((branch) => branch.branchTypeName === 'Building').map((branch) => (
                                <MenuItem
                                    key={branch.code}
                                    value={branch.code || ''}
                                >
                                    {branch.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.branchCode && (
                            <FormHelperText>{errors.branchCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                {/* <FormControlLabel
                    control={(
                        <Checkbox
                            onChange={this.updateCheckbox('includeChecked')}
                            name="includeChecked"
                            id="includeChecked"
                            value={formData.includeChecked}
                            color="primary"
                            checked={formData.includeChecked}
                        />
                    )}
                    label="Include Checked"
                /> */}
            </>
        );
            break;
        case 'stockBirth': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.branchCode}
                    >
                        <InputLabel htmlFor="branchCode" className={classes.inputLabel}>Branch</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="branchCode"
                                    id="branchCode"
                                />
                            )}
                            value={formData.branchCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.branches.filter((branch) => branch.branchTypeName === 'Building').map((branch) => (
                                <MenuItem
                                    key={branch.code}
                                    value={branch.code || ''}
                                >
                                    {branch.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.branchCode && (
                            <FormHelperText>{errors.branchCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    invalidLabel="No limit"
                    autoOk
                />

            </>
        );
            break;
        case 'paymentManualLinkStatus': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.branchCode}
                    >
                        <InputLabel htmlFor="branchCode" className={classes.inputLabel}>Branch</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="branchCode"
                                    id="branchCode"
                                />
                            )}
                            value={formData.branchCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.branches.filter((branch) => branch.branchTypeName === 'Building').map((branch) => (
                                <MenuItem
                                    key={branch.code}
                                    value={branch.code || ''}
                                >
                                    {branch.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.branchCode && (
                            <FormHelperText>{errors.branchCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    invalidLabel="No limit"
                    autoOk
                />

            </>
        );
            break;
        case 'agreementItemsVisitUnbooked': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.branchCode}
                    >
                        <InputLabel htmlFor="branchCode" className={classes.inputLabel}>Branch</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="branchCode"
                                    id="branchCode"
                                />
                            )}
                            value={formData.branchCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.branches.filter((branch) => branch.branchTypeName === 'Building').map((branch) => (
                                <MenuItem
                                    key={branch.code}
                                    value={branch.code || ''}
                                >
                                    {branch.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.branchCode && (
                            <FormHelperText>{errors.branchCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.productTypeCode}
                    >
                        <InputLabel htmlFor="productTypeCode" className={classes.inputLabel}>Product Type</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="productTypeCode"
                                    id="productTypeCode"
                                />
                            )}
                            value={formData.productTypeCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.productTypes.map((productType) => (
                                <MenuItem
                                    key={productType.code}
                                    value={productType.code || ''}
                                >
                                    {productType.description}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.productTypeCode && (
                            <FormHelperText>{errors.productTypeCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    invalidLabel="No limit"
                    autoOk
                    minDate={new Date(formData.startDate)}
                />

            </>
        );
            break;
        case 'visits': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.branchCode}
                    >
                        <InputLabel htmlFor="branchCode" className={classes.inputLabel}>Branch</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="branchCode"
                                    id="branchCode"
                                />
                            )}
                            value={formData.branchCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.branches.filter((branch) => branch.branchTypeName === 'Building').map((branch) => (
                                <MenuItem
                                    key={branch.code}
                                    value={branch.code || ''}
                                >
                                    {branch.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.branchCode && (
                            <FormHelperText>{errors.branchCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.visitStatus}
                    >
                        <InputLabel htmlFor="visitStatus" className={classes.inputLabel}>Status</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="visitStatus"
                                    id="visitStatus"
                                />
                            )}
                            value={formData.visitStatus}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.visitStatuses.map((visitStatus) => (
                                <MenuItem
                                    key={visitStatus}
                                    value={visitStatus || ''}
                                >
                                    {titalise(visitStatus.replace(/_/g, ' '))}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.visitStatus && (
                            <FormHelperText>{errors.visitStatus}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                {formData.visitStatus !== 'REBOOK' && formData.visitStatus !== 'INVITE_EXPIRED'
                    ? (
                        <>
                            <DatePicker
                                name="startDate"
                                id="startDate"
                                margin="normal"
                                label="Start Date"
                                value={moment(formData.startDate).format('YYYY-MM-DD')}
                                onChange={this.handleStartDateChange}
                                fullWidth
                                variant="outlined"
                                format="DD-MM-YYYY"
                                showTodayButton
                                disableFuture
                                invalidLabel="No limit"
                                autoOk
                            />
                            <DatePicker
                                name="endDate"
                                id="endDate"
                                margin="normal"
                                label="End Date"
                                value={formData.endDate}
                                onChange={this.handleEndDateChange}
                                fullWidth
                                variant="outlined"
                                format="DD-MM-YYYY"
                                showTodayButton
                                invalidLabel="No limit"
                                autoOk
                                minDate={new Date(formData.startDate)}
                            />
                        </>
                    )
                    : ''}
            </>
        );
            break;
        case 'incompleteAgreementWithActivePlan': criteriaContent = (
            <>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                    minDate={new Date(formData.startDate)}
                />
            </>
        );
            break;
        case 'salesAgentPostcodeAreas': criteriaContent = (
            <></>
        );
            break;
            // case 'salesAgentSales': criteriaContent = (
            //     <>
            //         <Grid item xs={12}>
            //             <FormControl
            //                 className={classes.menuFormControl}
            //                 variant="outlined"
            //                 fullWidth
            //                 error={!!errors.branchCode}
            //             >
            //                 <InputLabel htmlFor="branchCode" className={classes.inputLabel}>Branch</InputLabel>
            //                 <Select
            //                     keyboard="true"
            //                     input={(
            //                         <OutlinedInput
            //                             onChange={this.updateData}
            //                             name="branchCode"
            //                             id="branchCode"
            //                         />
            //                     )}
            //                     value={formData.branchCode}
            //                 >
            //                     <MenuItem key="null" value="null"><em>All</em></MenuItem>
            //                     {this.props.branches.map((branch) => (
            //                         <MenuItem
            //                             key={branch.code}
            //                             value={branch.code || ''}
            //                         >
            //                             {branch.name}
            //                         </MenuItem>
            //                     ))}
            //                 </Select>
            //                 {errors.branchCode && (
            //                     <FormHelperText>{errors.branchCode}</FormHelperText>
            //                 )}
            //             </FormControl>
            //         </Grid>

        //         <DatePicker
        //             name="startDate"
        //             id="startDate"
        //             margin="normal"
        //             label="Start Date"
        //             value={moment(formData.startDate).format('YYYY-MM-DD')}
        //             onChange={this.handleStartDateChange}
        //             fullWidth
        //             variant="outlined"
        //             format="DD-MM-YYYY"
        //             showTodayButton
        //             disableFuture
        //             invalidLabel="No limit"
        //             autoOk
        //         />
        //         <DatePicker
        //             name="endDate"
        //             id="endDate"
        //             margin="normal"
        //             label="End Date"
        //             value={formData.endDate}
        //             onChange={this.handleEndDateChange}
        //             fullWidth
        //             variant="outlined"
        //             format="DD-MM-YYYY"
        //             showTodayButton
        //             disableFuture
        //             invalidLabel="No limit"
        //             autoOk
        //             minDate={new Date(formData.startDate)}
        //         />
        //     </>
        // );
        //     break;
        case 'salesAgentSales': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.branchCode}
                    >
                        <InputLabel htmlFor="branchCode" className={classes.inputLabel}>Branch</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="branchCode"
                                    id="branchCode"
                                />
                            )}
                            value={formData.branchCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.branches.map((branch) => (
                                <MenuItem
                                    key={branch.code}
                                    value={branch.code || ''}
                                >
                                    {branch.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.branchCode && (
                            <FormHelperText>{errors.branchCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                    minDate={new Date(formData.startDate)}
                />
            </>
        );
            break;
        case 'undeliveredBlindAgreements': criteriaContent = (
            <>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                    minDate={new Date(formData.startDate)}
                />
            </>
        );
            break;
        case 'salesAgentAppointments': criteriaContent = (
            <>
            </>
        );
            break;
        case 'salesAgentLeads': criteriaContent = (
            <>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                    minDate={new Date(formData.startDate)}
                />
            </>
        );
            break;
            case 'debtRecoveryPayments': criteriaContent = (
                <>
                    <DatePicker
                        name="startDate"
                        id="startDate"
                        margin="normal"
                        label="Start Date"
                        value={moment(formData.startDate).format('YYYY-MM-DD')}
                        onChange={this.handleStartDateChange}
                        fullWidth
                        variant="outlined"
                        format="DD-MM-YYYY"
                        showTodayButton
                        disableFuture
                        invalidLabel="No limit"
                        autoOk
                    />
                    <DatePicker
                        name="endDate"
                        id="endDate"
                        margin="normal"
                        label="End Date"
                        value={formData.endDate}
                        onChange={this.handleEndDateChange}
                        fullWidth
                        variant="outlined"
                        format="DD-MM-YYYY"
                        showTodayButton
                        disableFuture
                        invalidLabel="No limit"
                        autoOk
                        minDate={new Date(formData.startDate)}
                    />
                </>
            );
                break;
                case 'externalDebtRecoveryPayments': criteriaContent = (
                    <>
                        <DatePicker
                            name="startDate"
                            id="startDate"
                            margin="normal"
                            label="Start Date"
                            value={moment(formData.startDate).format('YYYY-MM-DD')}
                            onChange={this.handleStartDateChange}
                            fullWidth
                            variant="outlined"
                            format="DD-MM-YYYY"
                            showTodayButton
                            disableFuture
                            invalidLabel="No limit"
                            autoOk
                        />
                        <DatePicker
                            name="endDate"
                            id="endDate"
                            margin="normal"
                            label="End Date"
                            value={formData.endDate}
                            onChange={this.handleEndDateChange}
                            fullWidth
                            variant="outlined"
                            format="DD-MM-YYYY"
                            showTodayButton
                            disableFuture
                            invalidLabel="No limit"
                            autoOk
                            minDate={new Date(formData.startDate)}
                        />
                    </>
                );
                    break;
                    case 'salesAgentLeadsResponse': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.createdByContactCode}
                    >
                        <InputLabel htmlFor="agentContactCode" className={classes.inputLabel}>Agents</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="agentContactCode"
                                    id="agentContactCode"
                                />
                            )}
                            value={formData.agentContactCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {sortBy(this.props.users.filter((agentUser) => agentUser.contact.contactAgentCode), 'contact.firstname').map((agent) => (
                                <MenuItem
                                    key={agent.contact.code}
                                    value={agent.contact.code || ''}
                                >
                                    {`${agent.contact.firstname} ${agent.contact.surname}`}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.createdByContactCode && (
                            <FormHelperText>{errors.createdByContactCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                    minDate={new Date(formData.startDate)}
                />
            </>
        );
            break;
        case 'vat': criteriaContent = (
            <>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                    minDate={new Date(formData.startDate)}
                />
            </>
        );
            break;
        case 'paymentCardExpireSoon': criteriaContent = (
            <></>
        );
            break;
        case 'agreementWithoutCard': criteriaContent = (
            <></>
        );
            break;
        case 'newAgreementProducts': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.productTypeCode}
                    >
                        <InputLabel htmlFor="productTypeCode" className={classes.inputLabel}>Product Type</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="productTypeCode"
                                    id="productTypeCode"
                                />
                            )}
                            value={formData.productTypeCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.productTypes.map((productType) => (
                                <MenuItem
                                    key={productType.code}
                                    value={productType.code || ''}
                                >
                                    {productType.description}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.productTypeCode && (
                            <FormHelperText>{errors.productTypeCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                    minDate={new Date(formData.startDate)}
                />
            </>
        );
            break;
        case 'newAgreements': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.productTypeCode}
                    >
                        <InputLabel htmlFor="productTypeCode" className={classes.inputLabel}>Product Type</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="productTypeCode"
                                    id="productTypeCode"
                                />
                            )}
                            value={formData.productTypeCode}
                        >
                            <MenuItem value="null"><em>All</em></MenuItem>
                            {this.props.productTypes.map((productType) => (
                                <MenuItem
                                    key={productType.code}
                                    value={productType.code || ''}
                                >
                                    {productType.description}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.productTypeCode && (
                            <FormHelperText>{errors.productTypeCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                    minDate={new Date(formData.startDate)}
                />
            </>
        );
            break;
        case 'stock': criteriaContent = (
            <Grid item xs={12}>
                <FormControl
                    className={classes.menuFormControl}
                    variant="outlined"
                    fullWidth
                    error={!!errors.branchCode}
                >
                    <InputLabel htmlFor="branchCode" className={classes.inputLabel}>Branch</InputLabel>
                    <Select
                        keyboard="true"
                        input={(
                            <OutlinedInput
                                onChange={this.updateData}
                                name="branchCode"
                                id="branchCode"
                            />
                        )}
                        value={formData.branchCode}
                    >
                        <MenuItem key="null" value="null"><em>All</em></MenuItem>
                        {this.props.branches.map((branch) => (
                            <MenuItem
                                key={branch.code}
                                value={branch.code || ''}
                            >
                                {branch.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.branchCode && (
                        <FormHelperText>{errors.branchCode}</FormHelperText>
                    )}
                </FormControl>
            </Grid>
        );
            break;
        case 'debtors': criteriaContent = (
            <>
                <DatePicker
                    name="atDate"
                    id="atDate"
                    margin="normal"
                    label="At Date"
                    value={formData.atDate}
                    onChange={this.handleAtDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
            </>
        );
            break;
        case 'paymentDeclined': criteriaContent = (
            <>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.productTypeCode}
                    >
                        <InputLabel htmlFor="productTypeCode" className={classes.inputLabel}>Product Type</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="productTypeCode"
                                    id="productTypeCode"
                                />
                            )}
                            value={formData.productTypeCode}
                        >
                            <MenuItem key="null" value="null"><em>All</em></MenuItem>
                            {this.props.productTypes.map((productType) => (
                                <MenuItem
                                    key={productType.code}
                                    value={productType.code || ''}
                                >
                                    {productType.description}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.productTypeCode && (
                            <FormHelperText>{errors.productTypeCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        className={classes.menuFormControl}
                        variant="outlined"
                        fullWidth
                        error={!!errors.productTypeCode}
                    >
                        <InputLabel htmlFor="productTypeCode" className={classes.inputLabel}>Day</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateData}
                                    name="day"
                                    id="day"
                                />
                            )}
                            value={formData.day}
                        >
                            <MenuItem
                                key="YESTERDAY"
                                value="YESTERDAY"
                            >
                                Yesterday
                            </MenuItem>
                            <MenuItem
                                key="TODAY"
                                value="TODAY"
                            >
                                Today
                            </MenuItem>
                        </Select>
                        {errors.productTypeCode && (
                            <FormHelperText>{errors.productTypeCode}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </>
        );
            break;
        case 'portal1-DeferredDebt(Audit)': criteriaContent = (
            <>
                <DatePicker
                    name="startDate"
                    id="startDate"
                    margin="normal"
                    label="Agreement Created Start Date"
                    value={moment(formData.startDate).format('YYYY-MM-DD')}
                    onChange={this.handleStartDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                />
                <DatePicker
                    name="endDate"
                    id="endDate"
                    margin="normal"
                    label="Agreement Created End Date"
                    value={formData.endDate}
                    onChange={this.handleEndDateChange}
                    fullWidth
                    variant="outlined"
                    format="DD-MM-YYYY"
                    showTodayButton
                    disableFuture
                    invalidLabel="No limit"
                    autoOk
                    minDate={new Date(formData.startDate)}
                />
            </>
        );
            break;
        default: criteriaContent = '';
        }
        // }

        return (
            <>
                {!reportSummaryLoading && !reportDataLoading && !userLoading
                    ? (
                        <Dialog
                            open={showCriteriaDialog}
                            onClose={this.handleCloseDialog}
                        >
                            <DialogContent className={classes.dialogContent}>
                                <div className={classes.dialogContentContainer}>
                                    <>
                                        <Grid container spacing={0}>
                                            <Grid item container xs={6}>
                                                <Typography variant="h6" gutterBottom>
                                                    Report Criteria
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <form>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.report}
                                                    >
                                                        <InputLabel htmlFor="report" className={classes.inputLabel}>Report Type</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateData}
                                                                    name="report"
                                                                    id="report"
                                                                />
                                                            )}
                                                            value={formData.report}
                                                        >
                                                            {reports.map((report) => (
                                                                <MenuItem
                                                                    key={report.name}
                                                                    value={report.name || ''}
                                                                    disabled={(!['report:*', `report:${report.name}:*`, `report:${report.name}:read`].some((scope) => user.userScopes.includes(scope)))
                                                                    || (report.name === 'debtors' && moment(currentDateTime).format('hh:mm:ss') >= '08:30')}
                                                                >
                                                                    {report.description}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.report && (
                                                            <FormHelperText>{errors.report}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                {criteriaContent}
                                                <Grid item xs={12} align="center">
                                                    <Button
                                                        onClick={this.handleSubmitButtonClick}
                                                        className={classes.dialogButton}
                                                        disabled={disableSubmitButton}
                                                    >
                                                        SUBMIT
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </>
                                </div>
                            </DialogContent>
                            <Divider className={classes.dialogActionsDivider} />
                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    onClick={this.handleCloseDialog}
                                    color="primary"
                                    autoFocus
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    ) : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )}
            </>
        );
    }
}

reportCriteriaDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(reportCriteriaDialog));
