import React from 'react';
import * as ReactDOM from 'react-dom';

import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    Typography,
    TableCell,
} from '@material-ui/core';
import moment from 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';
import TickIcon from '@material-ui/icons/Done';
import Barcode from 'react-barcode';

const styles = (theme) => ({
    row: {
        borderLeft: '1px solid #e0e0e0',
        borderRight: '1px solid #e0e0e0',
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        pageBreakInside: 'avoid',
    },
    cell: {
        fontSize: 12,
        verticalAlign: 'top',
        paddingTop: 16,
        paddingBottom: 16,
        paddingRight: 14,
        whiteSpace: 'nowrap',
    },
    tickBox: {
        height: 32,
        width: 32,
        border: `2px solid ${theme.palette.primary.light}`,
        borderRadius: 4,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 16,
    },
    bold: {
        fontWeight: 600,
    },
    tickContainer: {
        width: '100%',
        textAlign: 'center',
        marginTop: 2,
    },
    table: {
        // borderRadius: 4,
        border: `1px solid ${theme.palette.primary.light}`,
    },
    // tableHead: {
    //     fontWeight: 'bold',
    //     fontSize: 14,
    // },
    // paper: {
    //     width: '100%',
    //     marginTop: theme.spacing.unit * 3,
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     overflowX: 'auto',
    //     padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    // },
    // tileBottomDivider: {
    //     marginTop: theme.spacing.unit * 3,
    //     // marginBottom: theme.spacing.unit * 2,
    // },
    // dialogDivider: {
    //     marginTop: theme.spacing.unit * 2.5,
    //     marginBottom: theme.spacing.unit * 2,
    // },
    // bottomBarContainer: {
    //     width: '100%',
    //     textAlign: 'right',
    // },
    // textButton: {
    //     marginTop: theme.spacing.unit * 1.5,
    //     marginBottom: 0,
    // },
    // dialogActions: {
    //     marginRight: theme.spacing.unit * 2.5,
    // },
    // nameField: {
    //     width: 350,
    // },
    // addressField: {
    //     width: 250,
    // },
    // postcodeField: {
    //     width: 120,
    // },
    // countryField: {
    //     width: 200,
    //     marginLeft: theme.spacing.unit * 3,
    // },
    // emailField: {
    //     width: 350,
    // },
    // rightOfFieldButton: {
    //     marginTop: 10,
    //     marginLeft: theme.spacing.unit * 3,
    //     color: 'rgba(0, 121, 255, 0.87)',
    // },
    // dialogContentContainer: {
    //     marginTop: theme.spacing.unit * 2,
    //     marginBottom: theme.spacing.unit * 2,
    // },
    // dialogButton: {
    //     marginTop: theme.spacing.unit * 2,
    //     marginBottom: theme.spacing.unit * 1,
    // },
    // dialogContent: {
    //     paddingBottom: 0,
    //     minWidth: 300,
    // },
    // formHelperText: {
    //     color: '#f44336',
    // },
    // contactLocationIcon: {
    //     color: 'red',
    //     fontSize: 30,
    //     position: 'absolute',
    //     transform: 'translate(-50%, -100%)',
    // },
    // branchLocationIcon: {
    //     color: 'blue',
    //     fontSize: 30,
    //     position: 'absolute',
    //     transform: 'translate(-50%, -100%)',
    // },
    // progressContainer: {
    //     marginLeft: 'auto',
    //     marginRight: 'auto',
    //     marginTop: theme.spacing.unit * 3,
    //     marginBottom: theme.spacing.unit * 3,
    //     width: 60,
    // },
    // progress: {
    //     opacity: '.5',
    // },
    // inputLabel: {
    //     backgroundColor: '#fff',
    //     paddingLeft: 8,
    //     marginLeft: -5,
    //     paddingRight: 8,
    // },
    // menuFormControl: {
    //     marginBottom: theme.spacing.unit * 1,
    //     marginTop: theme.spacing.unit * 2,
    // },
    // agents: {
    //     marginRight: theme.spacing.unit * 3,
    // },
    // 'agents:not(:first-child)': {
    //     marginLeft: 0,
    //     backgroundColor: 'red',
    // },
    'row:not(:last-child)': {
        borderRight: '5px solid red',
    },
    bumpTop1: {
        marginTop: theme.spacing.unit * 1,
    },
    bumpTop4: {
        marginTop: theme.spacing.unit * 4,
    },
    signatureRow: {
        borderBottom: '3px solid #e0e0e0 !important',
        height: 120,
    },
});

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        paddingRight: 8,
        fontWeight: 500,
    },
    body: {
        fontSize: 12,
        verticalAlign: 'top',
        paddingTop: 16,
        paddingBottom: 8,
        paddingRight: 8,
    },
}))(TableCell);

const CuttingSheetRoot = document.getElementById('cutting-sheet-root');

class CuttingSheet extends React.Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount() {
        CuttingSheetRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        CuttingSheetRoot.removeChild(this.el);
    }

    state = {
    };

    render() {
        const {
            classes,
            visits,
            visitsLoading,
        } = this.props;
        const {
        } = this.state;

        return ReactDOM.createPortal(<>
            {!visitsLoading && visits.length > 0
                ? (
                    <div className="print-only">
                        <Typography variant="h5" gutterBottom>
                            Assignment Sheet
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            {`${visits[0].scheduledEngineer} ${moment(visits[0].scheduledVisitDateTime).format('DD/MM/YYYY')}`}
                        </Typography>
                        <Table className={classes.table} padding="dense">
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell className={classes.tableHead}>
                                        Agreement Code
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="left">
                                        Customer
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="left">
                                        Address
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="left">
                                        Quantity
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="left">
                                        Product
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="left">
                                        Size
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="left">
                                        Reference
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="left">
                                        Agreement Item
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="left">
                                        Visit Code
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="left">
                                        Visit Date
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="center">
                                        Assigned
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="center">
                                        Loaded
                                    </CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {visits.map((visit) => (visit.visitItems.map((visitItem) => (
                                    <>
                                        <TableRow className={classes.row} key={visitItem.code}>
                                            <TableCell className={classes.cell} align="left">
                                                {`RA${visitItem.agreementItem.agreementCode}`}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                <div>
                                                    {`${visit.contact.firstname} ${visit.contact.surname}`}
                                                </div>
                                                <div className={classes.bumpTop1}>
                                                    {visit.contact.mobileTelephoneNumber}
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                <div>
                                                    {visit.contact.address.address1}
                                                </div>
                                                <div>
                                                    {visit.contact.address.address2}
                                                </div>
                                                <div>
                                                    {visit.contact.address.townCity}
                                                </div>
                                                <div>
                                                    {visit.contact.address.county}
                                                </div>
                                                <div>
                                                    {visit.contact.address.postcode}
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                {visitItem.agreementItem.quantity}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                <div>
                                                    {`${visitItem.agreementItem.product.productType}/${visitItem.agreementItem.product.productTypeSubCategory}`}
                                                </div>
                                                <div>
                                                    {visitItem.agreementItem.product.manufacturer.name}
                                                </div>
                                                <div>
                                                    {visitItem.agreementItem.product.modelNo}
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                {(visitItem.agreementItem.length && visitItem.agreementItem.width) ? `${visitItem.agreementItem.length.toFixed(3)}m x ${visitItem.agreementItem.width.toFixed(3)}m` : ''}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                {`${visitItem.agreementItem.reference || ''}`}
                                            </TableCell>
                                            <TableCell className={`${classes.cell} ${classes.bold}`} align="left">
                                                <Barcode value={`RAI${visitItem.agreementItem.code}`} />
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                {`V${visit.code}`}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                {moment(visit.scheduledVisitDateTime).format('DD/MM/YYYY')}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="center">
                                                <div className={classes.tickBox}>
                                                    <div className={classes.tickContainer}>
                                                        {visitItem.agreementItem.itemCode ? (<TickIcon />) : ''}
                                                    </div>
                                                </div>
                                                {visitItem.agreementItem.itemCode
                                                    ? (
                                                        <>
                                                            <div className={classes.bold}>
                                                                {`SC${visitItem.agreementItem.item.code}`}
                                                            </div>
                                                            <div>
                                                                {visitItem.agreementItem.item.branch}
                                                            </div>
                                                            <div>
                                                                {visitItem.agreementItem.item.stockroom}
                                                            </div>
                                                            <div>
                                                                {visitItem.agreementItem.item.bin}
                                                            </div>
                                                        </>
                                                    ) : ''}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="center">
                                                <div className={classes.tickBox} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className={`${classes.row} ${classes.signatureRow}`} key={`${visit.code}_sig`}>
                                            <TableCell className={classes.cell} align="left" colSpan={6} width="100%">
                                                <div className={classes.signatureBox}>
                                                    Customer Signature
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left" colSpan={6} width="100%">
                                                <div className={classes.signatureBox}>
                                                    Date
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ))))}
                            </TableBody>
                        </Table>

                        <Typography variant="h5" gutterBottom className={classes.bumpTop4}>
                            Ad-Hoc Sheet
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            {`${visits[0].scheduledEngineer} ${moment(visits[0].scheduledVisitDateTime).format('DD/MM/YYYY')}`}
                        </Typography>
                        <Table className={classes.table} padding="dense">
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell className={classes.tableHead}>
                                        Company
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="left">
                                        Customer
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="left">
                                        Address
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="left">
                                        Payment Method
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="left">
                                        Note
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="left">
                                        Visit Code
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="left">
                                        Visit Date
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="center">
                                        Assigned
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.tableHead} align="center">
                                        Loaded
                                    </CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {visits.filter((visit) => visit.type === 'AD_HOC').map((visit) => (
                                    <>
                                        <TableRow className={classes.row} key={visit.code}>
                                            <TableCell className={classes.cell} align="left">
                                                {visit.adHocCompany}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                <div>
                                                    {`${visit.contact.firstname} ${visit.contact.surname}`}
                                                </div>
                                                <div className={classes.bumpTop1}>
                                                    {visit.contact.mobileTelephoneNumber}
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                <div>
                                                    {visit.contact.address.address1}
                                                </div>
                                                <div>
                                                    {visit.contact.address.address2}
                                                </div>
                                                <div>
                                                    {visit.contact.address.townCity}
                                                </div>
                                                <div>
                                                    {visit.contact.address.county}
                                                </div>
                                                <div>
                                                    {visit.contact.address.postcode}
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                {visit.adHocPaymentMethod}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                {visit.adHocNote}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                {`V${visit.code}`}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left">
                                                {moment(visit.scheduledVisitDateTime).format('DD/MM/YYYY')}
                                            </TableCell>
                                            <TableCell className={classes.cell} align="center">
                                                <div className={classes.tickBox} />
                                            </TableCell>
                                            <TableCell className={classes.cell} align="center">
                                                <div className={classes.tickBox} />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className={`${classes.row} ${classes.signatureRow}`} key={`${visit.code}_sig`}>
                                            <TableCell className={classes.cell} align="left" colSpan={6} width="100%">
                                                <div className={classes.signatureBox}>
                                                    Customer Signature
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.cell} align="left" colSpan={6} width="100%">
                                                <div className={classes.signatureBox}>
                                                    Date
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ))}
                            </TableBody>
                        </Table>

                    </div>
                )
                : ''}
                                     </>, this.el);
    }
}

export default withStyles(styles)(CuttingSheet);
