import React from 'react';
import {
    AppBar,
    Button,
    CssBaseline,
    Divider,
    IconButton,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

import fetch from '../utilities/fetch';
import Tile from '../containers/tile.js';
import AgreementPayments from './agreementPayments.js';
import AgreementItems from './agreementItems.js';
import AgreementDelivery from './agreementDelivery';
import AgreementDetail from './agreementDetail';

const removeFalseyValuesFromObject = obj => pickBy(obj, identity);

const styles = theme => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    tabsPaper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },
    tabsAppBar: {
        backgroundColor: theme.palette.primary.light,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
    },
    tab: {
        color: 'white',
        fontSize: 15,
    },
    divider: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
});

class CustomerAgreements extends React.Component {
    state = {
        error: false,
        loading: false,
        snackbarOpen: false,
        customer: {
            title: '',
            firstname: '',
            surname: '',
            address1: '',
            address2: '',
            townCity: '',
            county: '',
            postcode: '',
            country: '',
            homeTelephoneNumber: '',
            mobileTelephoneNumber: '',
            emailAddress: '',
            gdpr: '',
            agreements: [{code:null}],
        },
        saved: false,
        errors: {},
        value: 0,
    }

    async componentDidMount() {
        const url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/customer');
        const params = {
            contactCode: this.props.contactCode,
        };
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        const data = await fetch(url).then(response => response.json());
        this.setState({ customer: data });
    }

    handleRentalAgreementChange = (event, newValue) => {
        this.setState({ value: newValue });
    }

    render() {
        const { classes, contactCode } = this.props;
        const { customer, value } = this.state;

        return (
            <React.Fragment>
                <AppBar position="static" className={classes.tabsAppBar}>
                    <Tabs
                        value={value}
                        onChange={this.handleRentalAgreementChange
                        }
                    >
                        {customer.agreements.map((agreement, i) => (<Tab key={i} label={'RA'+agreement.code} className={classes.tab} />))}
                    </Tabs>
                </AppBar>
                <Paper className={classes.tabsPaper}>
                    <Tile tileTitle="Agreement Detail">
                        <AgreementDetail
                            rentalAgreementCode={customer.agreements[value].code}
                            />
                    </Tile>
                        { customer.agreements[value].code !== null
                            ? (
                                <AgreementItems
                                    rentalAgreementCode={customer.agreements[value].code}
                                    />
                            )
                            : <div>loading...</div>
                        }
                    <Tile tileTitle="Agreement Items Delivery">
                        <AgreementDelivery
                            rentalAgreementCode={customer.agreements[value].code}
                            deliveryStatus={customer.agreements[value].deliveryStatus}
                            deliveryDate={customer.agreements[value].deliveryDate}
                            contactCode={customer.code}
                            />
                    </Tile>
                    <AgreementPayments key={customer.agreements[value].code} rentalAgreementCode={customer.agreements[value].code} />
                </Paper>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(CustomerAgreements);
