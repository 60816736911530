import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    Menu,
    OutlinedInput,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { DateTimePicker, DatePicker } from 'material-ui-pickers';
import fetch, { fetchJson2 } from '../../utilities/fetch';
import currentDateTime from '../../utilities/currentDateTime';
import {
    titles,
    counties,
    countries,
} from '../../utilities/menuOptions';
import formatPostcode from '../../utilities/formatPostcode';
import contactDuplicates from './contactDuplicates';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    bumpBottom3: {
        marginBottom: theme.spacing.unit * 3,
    },
    dialogContentContainer: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    dialogContent: {
        minWidth: 600,
        paddingBottom: 16,
    },
    duplicateDialogContent: {
        minWidth: 800,
        paddingBottom: 16,
    },
    dialogDivider: {
        marginBottom: theme.spacing.unit * 3,
        marginLeft: -24,
        marginRight: -24,
        marginTop: theme.spacing.unit * 3,
    },
    dialogButton: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 4,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        margin: '0px 0px 0px 0px !important',
        padding: '8px 24px',
    },
    dialogText: {
        marginTop: theme.spacing.unit * 2,
    },
    disabled: {
        opacity: 0.26,
    },
    dialogTitle: {
        marginBottom: theme.spacing.unit * 2,
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    tableCell: {
        whiteSpace: 'nowrap',
    },
    contactDuplicateTableRow: {
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
});

class AddContactDialog extends React.Component {
    state = {
        addContactData: {
            title: '',
            firstname: '',
            surname: '',
            dateOfBirth: null,
            address1: '',
            address2: '',
            townCity: '',
            county: '',
            postcode: '',
            country: '',
        },
        anchorEl: null,
        contactDuplicates: [],
        disableSubmitContactButton: false,
        postcodeAddresses: [],
        showAddContactDuplicatesDialog: false,
        // disableTransferButton: false,
        // transferEngineerVisitsData: {
        //     transferDateTime: currentDateTime().dateTime,
        //     transferToEngineerCode: '',
        // },
    };

    // handleTransferVisits = () => {
    //     const transferDateTime = moment(this.state.transferEngineerVisitsData.transferDateTime).format('YYYY-MM-DD HH:i');
    //     this.setState({
    //         disableTransferButton: true,
    //     });
    //     this.transferEngineerVisits(this.props.engineer.engineer.code, this.state.transferEngineerVisitsData.transferToEngineerCode, transferDateTime).then(() => {
    //         this.setState({
    //             disableTransferButton: false,
    //         });
    //         this.props.handleCloseTransferEngineerVisitsDialogClick();
    //         this.props.enqueueSnackbar('Transfer Engineer Visits Success', { variant: 'success' });
    //     }).catch(() => {
    //         this.props.enqueueSnackbar('Transfer Engineer Visits Failed', { variant: 'error' });
    //         this.setState({
    //             disableTransferButton: false,
    //         });
    //     });
    // };

    // transferEngineerVisits = async (
    //     engineerCode,
    //     transferToEngineerCode,
    //     transferDateTime,
    // ) => {
    //     let response;
    //     let body;

    //     try {
    //         response = await fetch(`${REACT_APP_API_SERVER}v2/engineer/${engineerCode}/transferVisits`, {
    //             body: JSON.stringify({
    //                 transferDateTime,
    //                 transferToEngineerCode,
    //             }),
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             method: 'POST',
    //         });
    //     } catch (e) {
    //         // something went really wrong; timeout/ blocked by client etc
    //         // debugger;
    //         console.log(e);
    //     }

    //     if (response.headers.get('content-type')
    //         && response.headers.get('content-type').search('application/json') >= 0) {
    //         body = await response.json();
    //     }
    //     if (response.status !== 200) throw new Error({ error: 'Generic API error' });
    //     switch (response.status) {
    //     case 200: {
    //         const newEngineer = {
    //             ...body.engineer,
    //             histories: [
    //                 body.history,
    //                 ...this.props.engineer.histories,
    //             ],
    //         };
    //         this.props.passNewEngineer(newEngineer);
    //         break;
    //     }
    //     default: {
    //         throw new Error({ error: 'Generic API error' });
    //     }
    //     }
    // };

    updateAddContactData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'postcode':
            formattedValue = value.replace(/[\W_]+\s/g, '').toUpperCase();
            break;
        case 'emailAddress':
            formattedValue = value.toLowerCase();
            break;
        case 'mobileTelephoneNumber':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;
        this.setState((state) => ({
            addContactData: {
                ...state.addContactData,
                [name]: formattedValue,
            },
        }));
    };

    handleFindAddressClick = async (event) => {
        const { currentTarget } = event;
        const { postcode } = this.state.addContactData;
        console.log(this.state.addContactData.postcode);
        console.log(postcode);
        const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/postcodeAddresses/${postcode}`);
        const data = await fetch(url).then((response) => response.json());
        if (!data.Message) {
            this.setState({
                anchorEl: currentTarget,
                postcodeAddresses: data.addresses,
            });
        }
    };

    handleAdressesMenuClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleAddressClick = (i) => {
        let selectedCountry = '';
        let selectedAddress2;
        const selectedAddress = this.state.postcodeAddresses[i];
        switch (selectedAddress.country) {
        case 'England':
        case 'Scotland':
        case 'Wales':
        case 'Northern Ireland':
            selectedCountry = 'United Kingdom';
            break;
        default:
            selectedCountry = '';
        }
        selectedAddress2 = [selectedAddress.line_2, selectedAddress.line_3, selectedAddress.line_4, selectedAddress.locality].filter((el) => (!!el)).join(', ');
        selectedAddress2 = Array.isArray(selectedAddress2) && selectedAddress2.length === 0 ? '' : selectedAddress2;
        this.setState((state) => ({
            addContactData: {
                ...state.addContactData,
                address1: selectedAddress.line_1,
                address2: selectedAddress2,
                townCity: selectedAddress.town_or_city,
                county: selectedAddress.county,
                country: selectedCountry,
            },
            anchorEl: null,
        }));
    };

    handleDateOfBirthChange = (chosenDate) => {
        this.setState((state) => ({
            addContactData: {
                ...state.addContactData,
                dateOfBirth: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    handleAddContact = () => {
        this.setState({
            disableSubmitContactButton: true,
        });
        this.fetchContactDuplicates(this.state.addContactData).then(() => {
            this.setState({
                disableSubmitContactButton: false,
            });
            if (this.state.contactDuplicates.length > 0) {
                this.setState({
                    showAddContactDuplicatesDialog: true,
                });
            }
            // this.props.handleAddContactDialogCloseClick();
            this.props.enqueueSnackbar('Contact Duplicate Check Success', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Contact Duplicate Check Failed', { variant: 'error' });
            this.setState({
                disableSubmitContactButton: false,
            });
        });
    };

    fetchContactDuplicates = async (contactData) => {
        const {
            firstname,
            surname,
        } = contactData;

        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/contact/duplicates?${new URLSearchParams({
                firstname,
                surname,
            })}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'GET',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
            && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        if (response.status !== 200) throw new Error({ error: 'Generic API error' });
        switch (response.status) {
        case 200: {
            const contactDuplicates = body;
            this.setState({
                contactDuplicates,
            });
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    handleAddContactDuplicatesDialogCloseClick = () => {
        this.setState({
            showAddContactDuplicatesDialog: false,
        });
    };

    render() {
        const {
            classes,
            handleAddContactDialogCloseClick,
            showAddContactDialog,
        } = this.props;
        const {
            addContactData,
            anchorEl,
            contactDuplicates,
            disableSubmitContactButton,
            postcodeAddresses,
            showAddContactDuplicatesDialog,
            // disableTransferButton,
            // transferEngineerVisitsData,
        } = this.state;

        return (
            <>
                <Dialog
                    open={showAddContactDialog}
                    onClose={handleAddContactDialogCloseClick}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.dialogContentContainer}>
                            <>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className={classes.dialogTitle}
                                >
                                    Add Contact
                                </Typography>
                                <Grid
                                    container
                                    spacing={0}
                                    // direction="column"
                                    alignItems="center"
                                    justify="center"
                                >
                                    <Grid item xs={12}>
                                        <FormControl
                                            className={classes.menuFormControl}
                                            variant="outlined"
                                            fullWidth
                                            required
                                            // error={!!errors.title}
                                        >
                                            <InputLabel htmlFor="title" className={classes.inputLabel}>Title</InputLabel>
                                            <Select
                                                keyboard="true"
                                                input={(
                                                    <OutlinedInput
                                                        onChange={this.updateAddContactData}
                                                        name="title"
                                                        id="title"
                                                    />
                                                )}
                                                value={addContactData.title || ''}
                                            >
                                                <MenuItem value=""><em>None</em></MenuItem>
                                                {titles.map((title) => (
                                                    <MenuItem
                                                        key={title}
                                                        value={title}
                                                    >
                                                        {title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {/* {errors.title && (
                                                <FormHelperText>{errors.title}</FormHelperText>
                                            )} */}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="firstname"
                                            id="firstname"
                                            value={addContactData.firstname}
                                            onChange={this.updateAddContactData}
                                            label="Firstname"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            // error={!!errors.firstname}
                                            // helperText={errors.firstname}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="surname"
                                            id="surname"
                                            value={addContactData.surname}
                                            onChange={this.updateAddContactData}
                                            label="Surname"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            // error={!!errors.surname}
                                            // helperText={errors.surname}
                                        />
                                    </Grid>
                                </Grid>
                                <DatePicker
                                    name="dateOfBirth"
                                    id="dateOfBirth"
                                    margin="normal"
                                    label="Date of Birth"
                                    value={addContactData.dateOfBirth}
                                    onChange={this.handleDateOfBirthChange}
                                    fullWidth
                                    variant="outlined"
                                    format="DD/MM/YYYY"
                                    invalidLabel="No limit"
                                    autoOk
                                />

                                <Divider className={classes.dialogDivider} />
                                <Grid
                                    container
                                    spacing={0}
                                    // direction="column"
                                    alignItems="center"
                                    justify="center"
                                >
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="postcode"
                                            id="postcode"
                                            value={addContactData.postcode}
                                            onChange={this.updateAddContactData}
                                            label="Postcode"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            required
                                        />
                                        <Button
                                            className={classes.findAddressButton}
                                            variant="contained"
                                            onClick={this.handleFindAddressClick}
                                        >
                                            FIND ADDRESS
                                        </Button>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={this.handleAdressesMenuClose}
                                        >
                                            {
                                                postcodeAddresses.map((address, i) => (
                                                    <MenuItem
                                                        key={i}
                                                        onClick={() => this.handleAddressClick(i)}
                                                    >
                                                        {address.line_1}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Menu>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="address1"
                                            id="address1"
                                            value={addContactData.address1}
                                            onChange={this.updateAddContactData}
                                            label="Address 1"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            // error={!!errors.address1}
                                            // helperText={errors.address1}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="address2"
                                            id="address2"
                                            value={addContactData.address2}
                                            onChange={this.updateAddContactData}
                                            label="Address 2"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            // error={!!errors.address2}
                                            // helperText={errors.address2}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="townCity"
                                            id="townCity"
                                            value={addContactData.townCity}
                                            onChange={this.updateAddContactData}
                                            label="Town/City"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            // error={!!errors.townCity}
                                            // helperText={errors.townCity}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl
                                            className={classes.menuFormControl}
                                            variant="outlined"
                                            fullWidth
                                            required
                                            // error={!!errors.county}
                                        >
                                            <InputLabel htmlFor="county" className={classes.inputLabel}>County</InputLabel>
                                            <Select
                                                keyboard="true"
                                                input={(
                                                    <OutlinedInput
                                                        onChange={this.updateAddContactData}
                                                        name="county"
                                                        id="county"
                                                    />
                                                )}
                                                value={addContactData.county || ''}
                                            >
                                                <MenuItem value=""><em>None</em></MenuItem>
                                                {counties.map((county) => (
                                                    <MenuItem
                                                        key={county}
                                                        value={county}
                                                    >
                                                        {county}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {/* {errors.county && (
                                                <FormHelperText>{errors.county}</FormHelperText>
                                            )} */}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="postcode"
                                            id="postcode"
                                            value={addContactData.postcode}
                                            onChange={this.updateAddContactData}
                                            label="Postcode"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            // error={!!errors.postcode}
                                            // helperText={errors.postcode}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl
                                            className={classes.menuFormControl}
                                            variant="outlined"
                                            fullWidth
                                            required
                                            // error={!!errors.country}
                                        >
                                            <InputLabel htmlFor="country" className={classes.inputLabel}>Country</InputLabel>
                                            <Select
                                                keyboard="true"
                                                input={(
                                                    <OutlinedInput
                                                        onChange={this.updateAddContactData}
                                                        name="country"
                                                        id="country"
                                                    />
                                                )}
                                                value={addContactData.country || ''}
                                            >
                                                <MenuItem value=""><em>None</em></MenuItem>
                                                {countries.map((country) => (
                                                    <MenuItem
                                                        key={country}
                                                        value={country}
                                                    >
                                                        {country}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {/* {errors.country && (
                                                <FormHelperText>{errors.country}</FormHelperText>
                                            )} */}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Divider className={classes.dialogDivider} />
                                <Grid
                                    container
                                    spacing={0}
                                    // direction="column"
                                    alignItems="center"
                                    justify="center"
                                >
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="mobileTelephoneNumber"
                                            id="mobileTelephoneNumber"
                                            value={addContactData.mobileTelephoneNumber}
                                            onChange={this.updateAddContactData}
                                            label="Mobile Telephone Number"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="homeTelephoneNumber"
                                            id="homeTelephoneNumber"
                                            value={addContactData.homeTelephoneNumber}
                                            onChange={this.updateAddContactData}
                                            label="Home Telephone Number"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            // error={!!errors.homeTelephoneNumber}
                                            // helperText={errors.homeTelephoneNumber}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="emailAddress"
                                            id="emailAddress"
                                            value={addContactData.emailAddress}
                                            onChange={this.updateAddContactData}
                                            label="emailAddress"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            // error={!!errors.emailAddress}
                                            // helperText={errors.emailAddress}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={12}>
                                            <Button
                                                className={classes.dialogButton}
                                                disabled={disableSubmitContactButton}
                                                onClick={this.handleAddContact}
                                            >
                                                SUBMIT
                                            </Button>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            onClick={handleAddContactDialogCloseClick}
                            color="primary"
                            autoFocus
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={showAddContactDuplicatesDialog}
                    onClose={this.handleAddContactDuplicatesDialogCloseClick}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={classes.duplicateDialogContent}>
                        <div className={classes.dialogContentContainer}>
                            <>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className={classes.dialogTitle}
                                >
                                    Existing Contact/Customer?
                                </Typography>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Table className={classes.editAgreementItemTable} padding="dense">
                                            <TableBody>
                                                {contactDuplicates.map((contactDuplicate, i) => (
                                                    <TableRow className={classes.contactDuplicateTableRow} key={contactDuplicate.code}>
                                                        <TableCell className={classes.tableCell} align="left">
                                                            {`${contactDuplicate.title} ${contactDuplicate.firstname} ${contactDuplicate.surname}`}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell} align="left">
                                                            {`${contactDuplicate.address1}, ${contactDuplicate.postcode}`}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell} align="left">
                                                            {`${contactDuplicate.mobileTelephoneNumber}`}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell} align="left">
                                                            {`${contactDuplicate.emailAddress}`}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justify="center"
                                >
                                    <Grid item xs={12}>
                                        <Button
                                            className={classes.dialogButton}
                                            disabled={disableSubmitContactButton}
                                            onClick={this.handleAddContact}
                                        >
                                            NEW CONTACT
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            onClick={this.handleAddContactDuplicatesDialogCloseClick}
                            color="primary"
                            autoFocus
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

            </>
        );
    }
}

export default withSnackbar(withStyles(styles)(AddContactDialog));
