import React, { isValidElement } from 'react';
import {
    CircularProgress,
    CssBaseline,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import Layout from '../Layout';
import PurchaseOrdersTable from '../../components/v2/purchaseOrdersTable';
import fetch, { fetchJson2 } from '../../utilities/fetch';
import PurchaseOrderCreateDialog from '../../components/v2/purchaseOrderCreateDialog';
import PurchaseOrderEditDialog from '../../components/v2/purchaseOrderEditDialog';
import ManufacturerSupplierExpireDialog from '../../components/v2/manufacturerSupplierExpireDialog';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    inputLabel: {
        backgroundColor: '#fff',
        marginLeft: -5,
        paddingLeft: 8,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    newPurchaseOrderDialog: {
        maxWidth: 'none',
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
        minWidth: 400,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    itemsContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    itemsAddButtonContainer: {
        textAlign: 'right',
    },
    itemsTitleContainer: {
        marginTop: 13,
    },
    fieldAddButton: {
        marginLeft: 24,
        marginTop: 24,
    },
    radioGroup: {
        marginLeft: 24,
    },
    radioFormControl: {
        marginTop: 16,
    },
});

class PurchaseOrders extends React.Component {
    state = {
        currentPurchaseOrder: {},
        errors: [],
        purchaseOrders: [],
        purchaseOrdersLoading: true,
        showCreatePurchaseOrderDialog: false,
        showEditPurchaseOrderDialog: false,
        showExpireManufacturerSupplierDialog: false,
    };

    componentDidMount() {
        document.addEventListener('visibilitychange', this.onVisibilitychange);
        this.loadPurchaseOrdersData();
    }

    componentWillUnmount() {
        document.removeEventListener('visibilitychange', this.onVisibilitychange);
    }

    onVisibilitychange = () => {
        if (document.hidden === true) return;
        this.refreshVisitItemsData();
    };

    refreshPurchaseOrdersData = () => {
        this.loadPurchaseOrdersData().then(() => {
            this.props.enqueueSnackbar('UPDATED', { variant: 'success', autoHideDuration: 750 });
        }).catch(() => {
            this.props.enqueueSnackbar('UPDATE FAILED', { variant: 'error', autoHideDuration: 750 });
        });
    };

    loadPurchaseOrdersData = async () => {
        fetchJson2('v2/purchaseOrders')
            .then((purchaseOrders) => {
                this.setState({
                    purchaseOrders: purchaseOrders.payload,
                    purchaseOrdersLoading: false,
                });
            });
    };

    passNewPurchaseOrder = (newPurchaseOrder) => {
        let newPurchaseOrders;
        if (!this.state.purchaseOrders.find((purchaseOrder) => purchaseOrder.code === newPurchaseOrder.code)) {
            newPurchaseOrders = [
                ...this.state.purchaseOrders,
                newPurchaseOrder,
            ];
        } else {
            newPurchaseOrders = this.state.purchaseOrders.map((purchaseOrder) => ((purchaseOrder.code === newPurchaseOrder.code) ? newPurchaseOrder : purchaseOrder));
        }
        this.setState({
            purchaseOrders: newPurchaseOrders,
        });
    };

    handleExpireManufacturerSupplierClick = (manufacturerSupplierCode, event) => {
        event.stopPropagation();
        const newCurrentPurchaseOrder = this.state.manufacturerSuppliers.find((manufacturerSupplier) => manufacturerSupplier.code === manufacturerSupplierCode);

        this.setState({
            currentPurchaseOrder: {
                ...newCurrentPurchaseOrder,
                histories: [],
            },
            showExpireManufacturerSupplierDialog: true,
        });
    };

    handleCreatePurchaseOrderClick = () => {
        this.setState({
            showCreatePurchaseOrderDialog: true,
        });
    };

    handleEditPurchaseOrderClick = (purchaseOrderCode, event) => {
        event.stopPropagation();
        const newCurrentPurchaseOrder = this.state.purchaseOrders.find((purchaseOrder) => purchaseOrder.code === purchaseOrderCode);
        this.setState({
            currentPurchaseOrder: {
                ...newCurrentPurchaseOrder,
                histories: [],
            },
            showEditPurchaseOrderDialog: true,
        });
    };

    handleCloseEditPurchaseOrderDialogClick = () => {
        this.setState({
            showEditPurchaseOrderDialog: false,
        });
    };

    handleCloseCreatePurchaseOrderDialogClick = () => {
        this.setState({
            showCreatePurchaseOrderDialog: false,
        });
    };

    handleCloseExpireManufacturerSupplierDialogClick = () => {
        this.setState({
            showExpireManufacturerSupplierDialog: false,
        });
    };

    unexpireManufacturerSupplier = async (manufacturerSupplierCode) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/manufacturerSupplier/${manufacturerSupplierCode}`, {
                body: JSON.stringify({ expired: 0 }),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
              && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            this.passNewPurchaseOrder(body.manufacturerSupplier);
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    render() {
        const {
            classes,
        } = this.props;

        const {
            currentPurchaseOrder,
            errors,
            purchaseOrders,
            purchaseOrdersLoading,
            showCreatePurchaseOrderDialog,
            showEditPurchaseOrderDialog,
            showExpireManufacturerSupplierDialog,
        } = this.state;

        return (
            <Layout pageTitle="Purchase Orders">
                <CssBaseline />
                {
                    !purchaseOrdersLoading
                        ? (
                            <>
                                <PurchaseOrdersTable
                                    editManufacturerSupplier={this.editManufacturerSupplier}
                                    errors={errors}
                                    expireManufacturerSupplier={this.expireManufacturerSupplier}
                                    handleCreatePurchaseOrderClick={this.handleCreatePurchaseOrderClick}
                                    handleExpireManufacturerSupplierClick={this.handleExpireManufacturerSupplierClick}
                                    handleEditPurchaseOrderClick={this.handleEditPurchaseOrderClick}
                                    handleCloseEditPurchaseOrderDialogClick={this.handleCloseEditPurchaseOrderDialogClick}
                                    handleCloseExpireManufacturerSupplierDialogClick={this.handleCloseExpireManufacturerSupplierDialogClick}
                                    purchaseOrders={purchaseOrders}
                                    purchaseOrdersLoading={purchaseOrdersLoading}
                                    passNewPurchaseOrder={this.passNewPurchaseOrder}
                                    unexpireManufacturerSupplier={this.unexpireManufacturerSupplier}
                                />
                                <PurchaseOrderCreateDialog
                                    handleCloseCreatePurchaseOrderDialogClick={this.handleCloseCreatePurchaseOrderDialogClick}
                                    purchaseOrders={purchaseOrders}
                                    purchaseOrdersLoading={purchaseOrdersLoading}
                                    showCreatePurchaseOrderDialog={showCreatePurchaseOrderDialog}
                                    passNewPurchaseOrder={this.passNewPurchaseOrder}
                                />
                                <PurchaseOrderEditDialog
                                    handleCloseEditPurchaseOrderDialogClick={this.handleCloseEditPurchaseOrderDialogClick}
                                    purchaseOrder={currentPurchaseOrder}
                                    purchaseOrderLoading={purchaseOrdersLoading}
                                    showEditPurchaseOrderDialog={showEditPurchaseOrderDialog}
                                    passNewPurchaseOrder={this.passNewPurchaseOrder}
                                />
                                {/* <ManufacturerSupplierExpireDialog
                                    handleCloseExpireManufacturerSupplierDialogClick={this.handleCloseExpireManufacturerSupplierDialogClick}
                                    handleExpireManufacturerSupplierCloseDialogClick={this.handleExpireManufacturerSupplierCloseDialogClick}
                                    manufacturerSupplier={currentPurchaseOrder}
                                    manufacturerSupplierLoading={purchaseOrdersLoading}
                                    passNewPurchaseOrder={this.passNewPurchaseOrder}
                                    showExpireManufacturerSupplierDialog={showExpireManufacturerSupplierDialog}
                                /> */}
                            </>
                        )
                        : (
                            <div className={classes.progressContainer}>
                                <CircularProgress className={classes.progress} variant="indeterminate" />
                            </div>
                        )
                }

            </Layout>
        );
    }
}

export default withSnackbar(withStyles(styles)(PurchaseOrders));
