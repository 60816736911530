import React, { isValidElement } from 'react';
import {
    Button,
    CssBaseline,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import findIndex from 'lodash/findIndex';
import { parse, isValid } from 'postcode';

import Layout from '../Layout';
import PageHeader from '../../components/pageHeader';
import QuoteItemsTable from '../../components/v2/quoteItemsTable';
import fetch, { fetchJson2 } from '../../utilities/fetch';
import sleep from '../../utilities/sleep';

const removeFalseyValuesFromObject = obj => pickBy(obj, identity);

const styles = theme => ({
});

class Quote extends React.Component {
    state = {
        errors: [],
        products: [],
        productsLoading: true,
        productTypesLoading: true,
        quoteItems: [
            {
                manufacturer: 'Microsoft',
                modelNo: 'Xbox X',
                width: 1.4,
                length: 2.5,
                repaymentAmount: 123.45,
                key: '123',
            },
            {
                manufacturer: 'Sony',
                modelNo: 'PS5',
                width: 3.4,
                length: 1.5,
                repaymentAmount: 321.00,
                key: '456',
            },
            {
                manufacturer: 'Nintendo',
                modelNo: 'Switch',
                width: 1.75,
                length: 1.75,
                repaymentAmount: 95.99,
                key: '789',
            },
        ],
    };

    componentDidMount() {
        fetchJson2('v2/products')
            .then((products) => {
                this.setState({
                    products: products.payload,
                    productsLoading: false,
                });
            });
        fetchJson2('v2/product/types')
            .then((productTypes) => {
                console.log('>>> productTypes: ', productTypes.payload);
                this.setState({
                    productTypes: productTypes.payload,
                    productTypesLoading: false,
                });
            });
    }

    render() {
        const {
            errors,
            productTypes,
            productTypesLoading,
            products,
            productsLoading,
            quoteItems,

            // agentsLoading,
            // branchesLoading,
            // foundAgents,
            // foundBranches,
        } = this.state;

        return (
            <Layout pageTitle="Quote">
                <CssBaseline />
                <QuoteItemsTable
                    errors={errors}
                    products={products}
                    productsLoading={productsLoading}
                    productTypes={productTypes}
                    productTypesLoading={productTypesLoading}
                    quoteItems={quoteItems}
                    // agentsLoading={agentsLoading}
                    // sendAgentDetails={this.sendAgentDetails}
                />
            </Layout>
        );
    }
}

export default withStyles(styles)(Quote);
