import React from 'react';
import {
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import orderBy from 'lodash/orderBy';
import AddIcon from '@material-ui/icons/Add';
import ItemChildrenCreateDialog from './itemChildrenCreateDialog';

const styles = theme => ({
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    tableTitleLeft: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        alignItems: 'left',
        paddingTop: theme.spacing.unit * 2,
        fontWeight: 500,
        textTransform: 'uppercase',
        paddingBottom: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 2,
    },
    tableTitleRight: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        alignItems: 'right',
        paddingTop: theme.spacing.unit * 2,
        fontWeight: 500,
        textTransform: 'uppercase',
        paddingBottom: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    shortDescription: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
});

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        verticalAlign: 'top',
        paddingTop: 16,
        paddingBottom: 8,
    },
}))(TableCell);

class ItemChildren extends React.Component {
    state = {
        showCreateChildItemDialog: false,
    }

    handleCreateChildItemClick = () => {
        this.setState({
            showCreateChildItemDialog: true,
        });
    }

    resetCreateChildItemDialog = () => {
        this.setState({
            showCreateChildItemDialog: false,
        });
    }

    render() {
        const {
            classes,
            item,
            itemChildren,
            errors,
        } = this.props;

        const {
            showCreateChildItemDialog,
        } = this.state;

        return (
            <React.Fragment>
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <div className={classes.tableTitleLeft}>CHILD ITEMS</div>
                    </Grid>
                    <Grid container xs={6} alignItems="flex-start" justify="flex-end" direction="row">
                        <IconButton
                            className={classes.button}
                            onClick={this.handleCreateChildItemClick}
                        >
                            <AddIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Paper>
                    { item !== null
                        && itemChildren !== null
                        ? (
                            <React.Fragment>
                                <Table className={classes.table} padding="dense">
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell className={classes.tableHead}>Stock Code</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead}>Customer Ref</CustomTableCell>
                                            <CustomTableCell align="right" className={classes.tableHead}>Length (m)</CustomTableCell>
                                            <CustomTableCell align="right" className={classes.tableHead}>Width (m)</CustomTableCell>
                                            <CustomTableCell align="right" className={classes.tableHead}>Created Time & Date</CustomTableCell>
                                            <CustomTableCell align="left" className={classes.tableHead}>Created By</CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orderBy(itemChildren, 'createdDateTime', 'desc').map(child => (
                                            <TableRow className={classes.row} key={child.code} onClick={() => this.handleItemChildClick(child.code)}>
                                                <CustomTableCell className={classes.customTableCell} align="left">SC{child.code}</CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} align="left">{child.supplierOrderCustomerReference}</CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} align="right">{child.length}m</CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} align="right">{child.width}m</CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} align="right">{child.createdDateTimeFormatted}</CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} align="left">{child.createdByFullName}</CustomTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <ItemChildrenCreateDialog
                                    createChildItem={this.props.createChildItem}
                                    errors={errors}
                                    resetCreateChildItemDialog={this.resetCreateChildItemDialog}
                                    showCreateChildItemDialog={showCreateChildItemDialog}
                                />
                            </React.Fragment>
                        )
                        : (
                            <div className={classes.progressContainer}>
                                <CircularProgress className={classes.progress} variant="indeterminate" />
                            </div>
                        )
                    }
                </Paper>
            </React.Fragment>
        );
    }
}

ItemChildren.propTypes = {
    classes: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    itemChildren: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(ItemChildren);
