import React from 'react';
import {
    CssBaseline,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Layout from './Layout';
import fetch, { fetchJson } from '../utilities/fetch';
import DeliveryNoteDetail from '../components/deliveryNoteDetail';
import DeliveryNoteItems from '../components/deliveryNoteItems';

const styles = theme => ({
//
});

class DeliveryNote extends React.Component {
    state = {
        deliveryNote: null,
        deliveryNoteItems: null,
        products: null,
        stockrooms: null,
        bins: null,
        errors: {},
    }

    static getDerivedStateFromProps(props) {
        const deliveryNoteCode = props.match.params.deliveryNoteCode;
        return {
            deliveryNoteCode,
        };
    }

    async componentDidMount() {
        const { deliveryNoteCode } = this.state;

        fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/deliveryNote/${deliveryNoteCode}`)
            .then((response) => {
                this.setState({
                    deliveryNote: response,
                });
            });

        fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/deliveryNote/${deliveryNoteCode}/__items`)
            .then((response) => {
                this.setState({
                    deliveryNoteItems: response,
                });
            });

        await this.fetchMenus();
    }

    fetchMenus = () => {
        const fetchProducts = fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/products?continuousRental=1&repaymentRental=1&sale=1')
            .then((products) => {
                this.setState({
                    products,
                });
            });
        const stockrooms = fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/stockrooms')
            .then((stockrooms) => {
                this.setState({
                    stockrooms,
                });
            });
        const bins = fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/bins')
            .then((bins) => {
                this.setState({
                    bins,
                });
            });
        return Promise.all([fetchProducts, stockrooms, bins]);
    }

    addDeliveryNoteItem = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/deliveryNote/${this.state.deliveryNoteCode}/__item`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            console.log('>>> STOCKCONTROLTYPE:', body[0].stockControlTypeDescription);
            console.log('>>> STOCKROOMS:', body[0].stockroomCode);
            console.log('>>> STOCKCONTROLTYPE:', body[0].stockControlTypeDescription);
            const stockControlTypeDescription = body[0].stockControlTypeDescription;
            if (stockControlTypeDescription === 'Tracked') {
                this.setState(state => ({
                    deliveryNoteItems: [
                        ...body,
                        ...state.deliveryNoteItems,
                    ],
                }));
            } else {
                const responseCountItem = body[0];
                const existingCountItem = this.state.deliveryNoteItems.find(deliveryNoteItem => (deliveryNoteItem.barcode === responseCountItem.barcode && deliveryNoteItem.stockControlTypeDescription === 'Count'));
                console.log('>>> EXISTING COUNT ITEM:', existingCountItem);
                if (existingCountItem) {
                    console.log('>>> EXISTING COUNT ITEM');
                    const newDeliveryNoteItems = this.state.deliveryNoteItems.map((deliveryNoteItem) => {
                        if (deliveryNoteItem.barcode === existingCountItem.barcode && deliveryNoteItem.stockControlTypeDescription === 'Count') return responseCountItem;
                        return deliveryNoteItem;
                    });
                    this.setState({
                        deliveryNoteItems: [
                            ...newDeliveryNoteItems,
                        ],
                    });
                } else {
                    this.setState(state => ({
                        deliveryNoteItems: [
                            ...body,
                            ...state.deliveryNoteItems,
                        ],
                    }));
                }
            }
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    }

    printLabel = async (barcode, quantity) => {
        console.log('>>>Handling Print Label');
        const barcodePrefix = barcode.substring(0, barcode.length - 6);
        const barcodeCode = barcode.substring(barcode.length - 6, barcode.length);

        let response;
        let body;
        const payload = {
            itemCodes: [
                barcodeCode,
            ],
            productCode: barcodeCode,
            branchCode: this.state.deliveryNote.deliveryBranchCode,
            quantity,
        };
        let barcodePrefixLabel;
        switch (barcodePrefix) {
        case 'SC': barcodePrefixLabel = 'items';
            break;
        case 'P': barcodePrefixLabel = 'product';
            break;
        default: throw new Error({ error: 'Invalid barcode error' });
        }

        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/${barcodePrefixLabel}/label`, {
                method: 'PUT',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 202: {
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    }

    // printStockLabel = async (itemCode) => {
    //     console.log('Handling Print Stock Label');
    //     let response;
    //     let body;
    //     const payload = {
    //         itemCodes: [
    //             itemCode,
    //         ],
    //     };
    //     try {
    //         console.log('Trying print');
    //         response = await fetch('https://api.portal2.payweeklycarpets.co.uk/core/items/label', {
    //             method: 'PUT',
    //             body: JSON.stringify(payload),
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         });
    //     } catch (e) {
    //         // something went really wrong; timeout/ blocked by client etc
    //         // debugger;
    //     }
    //
    //     if (response.headers.get('content-type')
    //       && response.headers.get('content-type').search('application/json') >= 0) {
    //         body = await response.json();
    //     }
    //
    //     switch (response.status) {
    //     case 202: {
    //         break;
    //     }
    //     default: {
    //         throw new Error({ error: 'Generic API error' });
    //     }
    //     }
    // }
    //
    // printProductLabel = async (productCode, quantity) => {
    //     console.log('>>>Handling Print Product Label');
    //     let response;
    //     let body;
    //     const payload = {
    //         productCode,
    //         branchCode: this.state.deliveryNote.branchCode,
    //         quantity,
    //     };
    //     try {
    //         console.log('Trying print');
    //         response = await fetch('https://api.portal2.payweeklycarpets.co.uk/core/product/label', {
    //             method: 'PUT',
    //             body: JSON.stringify(payload),
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         });
    //     } catch (e) {
    //         // something went really wrong; timeout/ blocked by client etc
    //         // debugger;
    //     }
    //
    //     if (response.headers.get('content-type')
    //       && response.headers.get('content-type').search('application/json') >= 0) {
    //         body = await response.json();
    //     }
    //
    //     switch (response.status) {
    //     case 202: {
    //         break;
    //     }
    //     default: {
    //         throw new Error({ error: 'Generic API error' });
    //     }
    //     }
    // }

    render() {
        const {
            bins,
            deliveryNote,
            deliveryNoteItems,
            errors,
            products,
            stockrooms,
        } = this.state;

        return (
            <Layout pageTitle="Delivery Note">
                <CssBaseline />
                <DeliveryNoteDetail
                    deliveryNote={deliveryNote}
                    deliveryNoteItems={deliveryNoteItems}
                />
                <DeliveryNoteItems
                    addDeliveryNoteItem={this.addDeliveryNoteItem}
                    bins={bins}
                    deliveryNote={deliveryNote}
                    deliveryNoteItems={deliveryNoteItems}
                    errors={errors}
                    printLabel={this.printLabel}
                    products={products}
                    stockrooms={stockrooms}
                />
            </Layout>
        );
    }
}

export default withStyles(styles)(DeliveryNote);
