import React, { isValidElement } from 'react';
import {
    Button,
    CssBaseline,
    Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import findIndex from 'lodash/findIndex';
import { parse, isValid } from 'postcode';

import classNames from 'classnames';
import Layout from '../Layout';
import PageHeader from '../../components/pageHeader';
import SimpleTile from '../../components/v2/dashboard/simpleTile';
import fetch, { fetchJson2 } from '../../utilities/fetch';
import sleep from '../../utilities/sleep';

const removeFalseyValuesFromObject = (obj) => pickBy(obj, identity);

const styles = (theme) => ({
    simpleRow: {
        height: 200,
    },
});

class PostcodeLookup extends React.Component {
    state = {
        agents: null,
        agentsLoading: true,
        branches: null,
        branchesLoading: true,
        errors: {},
        foundAgents: null,
        foundBranches: null,
    };

    componentDidMount() {
        fetchJson2('v2/branches')
            .then((branches) => {
                console.log('>>> Branches:', branches.payload);
                this.setState({
                    branches: branches.payload,
                    branchesLoading: false,
                });
            });
        fetchJson2('v2/agents')
            .then((agents) => {
                console.log('>>> Agents: ', agents.payload);
                this.setState({
                    agents: agents.payload,
                    agentsLoading: false,
                });
            });
    }

    render() {
        const {
            agentsLoading,
            branchesLoading,
            errors,
            foundAgents,
            foundBranches,
        } = this.state;

        return (
            <Layout pageTitle="Dashboard">
                <CssBaseline />
                <Grid container spacing={0}>
                    <Grid item xs={4} className={classNames.simpleRow}>
                        <SimpleTile
                            label="Incomplete"
                        />
                    </Grid>
                </Grid>
            </Layout>
        );
    }
}

export default withStyles(styles)(PostcodeLookup);
