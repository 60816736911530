import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
    AppBar,
    Badge,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    InputBase,
    Menu,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Toolbar,
    Typography,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import MoreIcon from '@material-ui/icons/MoreVert';
import Cookie from 'js-cookie';
import { DatePicker, DateTimePicker } from 'material-ui-pickers';
import moment from 'moment-timezone';
import { withSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import fetch, { fetchJson, fetchJson2 } from '../../utilities/fetch';
import { version } from '../../version';
import Search from './search';
import amplify5Logo from '../../amplify5Logo.png';

const AMPLIFY5 = 1002;
const currentDate = moment.tz(moment(), 'Europe/London');

const styles = (theme) => ({
    root: {
        // width: '100%',
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        flexDirection: 'row',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        marginLeft: theme.spacing.unit,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing.unit * 2,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit * 3,
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    companyButtonText: {
        color: theme.palette.secondary.main,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 2.5,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
        width: 400,
    },
    dialogActionsDivider: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    dialogAppVersion: {
        fontWeight: 500,
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    logo: {
        height: 40,
        float: 'inline-start',
        paddingLeft: 8,
    },
    nextCallButton: {
        marginLeft: theme.spacing.unit * 2.5,
        backgroundColor: '#6d7b83',
    },
    buttons: {
        marginLeft: theme.spacing.unit * 2.5,
        backgroundColor: '#6d7b83',
    },
    radioFormControl: {
        marginTop: 16,
    },
    radioGroup: {
        marginLeft: 24,
    },
    dialogScript: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 14,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 600,
        lineHeight: 1.5,
        // letterSpacing: 0.01071em,
        fontStyle: 'italic',
        marginTop: theme.spacing.unit * 2,
    },
});

class AppBarBottom extends React.Component {
    state = {
        callPaymentStatus: null,
        callStatus: null,
        disableCallStatusButtons: false,
        disableContactTerminatedCallButton: false,
        disableEscalateCallButton: false,
        disableScheduleCallBackButton: false,
        disableScheduleCallBackSaveButton: false,
        escalateCallData: {
            reason: null,
        },
        hideCallActionButtons: false,
        logCallData: {
            status: null,
        },
        scheduleCallBackData: {
            reason: null,
            scheduleCallBackDate: null,
        },
        showCallUnansweredDialog: false,
        showScheduleCallBackDialog: false,
    };

    // handleChangeCallStatus = (event, callStatus) => {
    //     event.preventDefault();
    //     this.setState({
    //         disableCallStatusButtons: true,
    //     });
    //     this.props.changeCallStatus(this.props.agreementCode, callStatus).then(() => {
    //         this.setState({
    //             callStatus,
    //             disableCallStatusButtons: false,
    //         });
    //         this.props.enqueueSnackbar('Call status updated', { variant: 'success' });
    //     }).catch(() => {
    //         console.log(this.props.errors);
    //         this.props.enqueueSnackbar('Update call status failed', { variant: 'error' });
    //         this.setState({
    //             disableCallStatusButtons: false,
    //         });
    //     });
    // };

    handleLogCall = (event, status) => {
        event.preventDefault();
        this.setState({
            disableCallStatusButtons: true,
        });

        const callStatus = status || this.state.logCallData.status;
        this.props.logCall(this.props.agreementCode, callStatus).then(() => {
            this.setState((state) => ({
                callPaymentStatus: callStatus !== 'CONNECTED' ? 'NEXT' : null,
                callStatus: callStatus !== 'CONNECTED' ? callStatus : 'CONNECTED',
                disableCallStatusButtons: false,
                showCallUnansweredDialog: false,
            }));
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Update call status failed', { variant: 'error' });
            this.setState({
                disableCallStatusButtons: false,
            });
        });
    };

    handleScheduleCallBackSave = (event) => {
        event.preventDefault();
        this.setState({
            disableScheduleCallBackButton: true,
        });

        const {
            callBackDateTime,
            reason,
        } = this.state.scheduleCallBackData;

        const payload = {
            callBackDateTime,
            reason,
        };

        this.props.scheduleCallBack(this.props.agreementCode, payload).then(() => {
            this.setState((state) => ({
                disableScheduleCallBackButton: false,
                showScheduleCallBackDialog: false,
                hideCallActionButtons: true,
            }));
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Save schedule callBack failed', { variant: 'error' });
            this.setState({
                disableScheduleCallBackButton: false,
            });
        });
    };

    handleScheduleCallBackClick = () => {
        this.setState({
            disableScheduleCallBackButton: true,
            callStatus: 'NEXT',
            scheduleCallBackData: {
                callBackDateTime: currentDate.format('YYYY-MM-DD HH:mm'),
                reason: '',
            },
            showScheduleCallBackDialog: true,
        });
    };

    handleCloseScheduleCallBackDialog = () => {
        this.setState((state) => ({
            disableScheduleCallBackButton: false,
            showScheduleCallBackDialog: false,
        }));
    };

    handleCallUnansweredClick = () => {
        this.setState({
            disableCallStatusButtons: true,
            logCallData: {
                status: null,
            },
            showCallUnansweredDialog: true,
        });
    };

    handleCloseCallUnansweredDialog = () => {
        this.setState((state) => ({
            disableCallStatusButtons: false,
            showCallUnansweredDialog: false,
        }));
    };

    handleEscalateCallClick = () => {
        this.setState({
            escalateCallData: {
                reason: '',
            },
            showEscalateCallDialog: true,
        });
    };

    handleCloseEscalateCallDialog = () => {
        this.setState((state) => ({
            disableEscalateCallButton: false,
            showEscalateCallDialog: false,
        }));
    };

    updateLogCallData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            logCallData: {
                ...state.logCallData,
                [name]: formattedValue,
            },
        }));
    };

    updateScheduleCallBackData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        case 'reason':
            formattedValue = value === ' ' ? '' : value;
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            scheduleCallBackData: {
                ...state.scheduleCallBackData,
                [name]: formattedValue,
            },
        }));
    };

    updateEscalateCallData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            escalateCallData: {
                ...state.escalateCallData,
                [name]: formattedValue,
            },
        }));
    };

    handleCallBackDateTimeChange = (callBackDateTime) => {
        this.setState((state) => ({
            scheduleCallBackData: {
                ...state.scheduleCallBackData,
                callBackDateTime: moment(callBackDateTime).format('YYYY-MM-DD HH:mm'),
            },
        }));
    };

    handleEscalateCallSave = (event) => {
        event.preventDefault();
        this.setState({
            disableEscalateCallButton: true,
        });
        const payload = {
            reason: this.state.escalateCallData.reason,
        };

        this.props.escalateCall(this.props.agreementCode, payload).then(() => {
            this.setState((state) => ({
                disableEscalateCallButton: false,
                hideCallActionButtons: true,
                showEscalateCallDialog: false,
            }));
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Escalate call failed', { variant: 'error' });
            this.setState({
                disableEscalateCallButton: false,
            });
        });
    };

    handleContactTerminatedCallClick = (event) => {
        event.preventDefault();
        this.setState({
            disableContactTerminatedCallButton: true,
        });

        this.props.contactTerminatedCall(this.props.agreementCode).then(() => {
            this.setState({
                disableContactTerminatedCallButton: false,
                hideCallActionButtons: true,
            });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Customer terminated call failed', { variant: 'error' });
            this.setState({
                disableContactTerminatedCallButton: false,
            });
        });
    };

    render() {
        const {
            callPaymentStatus,
            callStatus,
            disableCallStatusButtons,
            disableContactTerminatedCallButton,
            disableEscalateCallButton,
            disableScheduleCallBackButton,
            disableScheduleCallBackSaveButton,
            escalateCallData,
            hideCallActionButtons,
            loadingNext,
            logCallData,
            scheduleCallBackData,
            showCallUnansweredDialog,
            showEscalateCallDialog,
            showScheduleCallBackDialog,
        } = this.state;
        const {
            agreementCode,
            classes,
            currentAgreement,
            fetchNextCall,
            handleManualPaymentClick,
        } = this.props;

        return (
            <>
                <div className={classes.root}>
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar>
                            <img className={classes.logo} src={amplify5Logo} alt="logo" />
                            {callStatus === null
                                ? (
                                    <>
                                        <Button
                                            type="button"
                                            onClick={this.handleCallUnansweredClick}
                                            variant="text"
                                            color="secondary"
                                            className={classes.buttons}
                                            disabled={disableCallStatusButtons}
                                        >
                                            CALL UNANSWERED
                                        </Button>
                                        <Button
                                            type="button"
                                            onClick={(e) => this.handleLogCall(e, 'CONNECTED')}
                                            variant="text"
                                            color="secondary"
                                            className={classes.buttons}
                                            disabled={disableCallStatusButtons}
                                        >
                                            CALL CONNECTED
                                        </Button>
                                        <Button
                                            type="button"
                                            onClick={this.handleScheduleCallBackClick}
                                            // fullWidth
                                            variant="text"
                                            color="secondary"
                                            className={classes.buttons}
                                            disabled={disableScheduleCallBackButton || loadingNext || hideCallActionButtons}
                                        >
                                            SCHEDULE CALL BACK
                                        </Button>
                                    </>
                                ) : null}
                            {callStatus === 'CONNECTED'
                                ? (
                                    <>
                                        {!hideCallActionButtons
                                            ? (
                                                <>
                                                    <Button
                                                        type="button"
                                                        onClick={handleManualPaymentClick}
                                                        // fullWidth
                                                        variant="text"
                                                        color="secondary"
                                                        className={classes.buttons}
                                                        disabled={loadingNext
                                                            || hideCallActionButtons
                                                            || isEmpty(currentAgreement)
                                                            || currentAgreement.plan.source === 'LOCAL'
                                                            || ['COMPLETE'].some((status) => currentAgreement.plan.status === status)
                                                            || ['COMPLETE'].some((status) => currentAgreement.status === status)}
                                                    >
                                                        Request Payment
                                                    </Button>
                                                    {/* <Button
                                            type="button"
                                            // onClick={saveInstallation}
                                            // fullWidth
                                            variant="text"
                                            color="secondary"
                                            className={classes.buttons}
                                            // disabled={loading}
                                        >
                                            UNABLE TO MAKE PAYMENT
                                        </Button> */}
                                                    {/* <Button
                                                        type="button"
                                                        onClick={this.handleScheduleCallBackClick}
                                                        // fullWidth
                                                        variant="text"
                                                        color="secondary"
                                                        className={classes.buttons}
                                                        disabled={disableScheduleCallBackButton || loadingNext || hideCallActionButtons}
                                                    >
                                                        SCHEDULE CALL BACK
                                                    </Button> */}
                                                    <Button
                                                        type="button"
                                                        onClick={this.handleContactTerminatedCallClick}
                                                        // fullWidth
                                                        variant="text"
                                                        color="secondary"
                                                        className={classes.buttons}
                                                        disabled={disableContactTerminatedCallButton || loadingNext || hideCallActionButtons}
                                                    >
                                                        CUSTOMER TERMINATED CALL
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        onClick={this.handleEscalateCallClick}
                                                        // fullWidth
                                                        variant="text"
                                                        color="secondary"
                                                        className={classes.buttons}
                                                        disabled={disableScheduleCallBackButton || loadingNext || hideCallActionButtons}
                                                    >
                                                        ESCALATE CALL
                                                    </Button>
                                                </>
                                            ) : null}

                                    </>
                                ) : null}
                            <div className={classes.grow} />
                            {callStatus
                                ? (
                                    <Button
                                        type="button"
                                        onClick={fetchNextCall}
                                        // fullWidth
                                        variant="text"
                                        color="secondary"
                                        className={classes.nextCallButton}
                                        disabled={loadingNext}
                                    >
                                        NEXT CALL
                                    </Button>
                                ) : null}
                        </Toolbar>
                    </AppBar>
                </div>

                <Dialog
                    open={showCallUnansweredDialog}
                    onClose={this.handleCloseCallUnansweredDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.dialogContentContainer}>
                            <>
                                <Typography variant="h6" gutterBottom>
                                    Call Unanswered
                                </Typography>
                                <form>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <FormControl component="fieldset" className={`${classes.formControl} ${classes.radioFormControl}`}>
                                                {/* <FormLabel component="legend">Reason</FormLabel> */}
                                                <RadioGroup
                                                    aria-label="call unanswered reason"
                                                    name="status"
                                                    value={logCallData.reason}
                                                    onChange={this.updateLogCallData}
                                                    className={classes.radioGroup}
                                                >
                                                    <FormControlLabel
                                                        value="UNANSWERED"
                                                        control={<Radio color="primary" />}
                                                        label="No Answer"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="ENGAGED"
                                                        control={<Radio color="primary" />}
                                                        label="Engaged"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        // eslint-disable-next-line react/jsx-boolean-value
                                                        value="UNOBTAINABLE"
                                                        control={<Radio color="primary" />}
                                                        label="Unobtainable"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        // eslint-disable-next-line react/jsx-boolean-value
                                                        value="VOICEMAIL"
                                                        control={<Radio color="primary" />}
                                                        label="Left Voicemail"
                                                        labelPlacement="end"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </form>
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justify="center"
                                >
                                    <Grid item xs={12}>
                                        <Button
                                            className={classes.dialogButton}
                                            disabled={disableScheduleCallBackSaveButton || !logCallData.status}
                                            onClick={(e) => this.handleLogCall(e, null)}
                                        >
                                            SAVE
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        </div>
                    </DialogContent>
                    <Divider />
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            onClick={this.handleCloseCallUnansweredDialog}
                            color="primary"
                            autoFocus
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={showScheduleCallBackDialog}
                    onClose={this.handleCloseScheduleCallBackDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.dialogContentContainer}>
                            <>
                                <Typography variant="h6" gutterBottom>
                                    Schedule CallBack
                                </Typography>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <DateTimePicker
                                            name="callBackDateTime"
                                            id="callBackDateTime"
                                            margin="normal"
                                            label="CallBack Time/Date"
                                            value={scheduleCallBackData.callBackDateTime}
                                            onChange={this.handleCallBackDateTimeChange}
                                            fullWidth
                                            variant="outlined"
                                            format="HH:mm DD/MM/YYYY"
                                            showTodayButton
                                            minDate={new Date(moment(currentDate).format('YYYY-MM-DD'))}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            onChange={this.updateScheduleCallBackData}
                                            className={classes.formControl}
                                            name="reason"
                                            id="reason"
                                            label="Reason"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            multiline
                                            rows="6"
                                            // error={!!errors.reason}
                                            // helperText={errors.reason}
                                            autoFocus
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justify="center"
                                >
                                    <Grid item xs={12}>
                                        <Button
                                            className={classes.dialogButton}
                                            disabled={disableScheduleCallBackSaveButton || !agreementCode || !scheduleCallBackData.reason}
                                            onClick={this.handleScheduleCallBackSave}
                                        >
                                            SAVE
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        </div>
                    </DialogContent>
                    <Divider />
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            onClick={this.handleCloseScheduleCallBackDialog}
                            color="primary"
                            autoFocus
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={showEscalateCallDialog}
                    onClose={this.handleCloseEscalateCallDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.dialogContentContainer}>
                            <>
                                <Typography variant="h6" gutterBottom>
                                    Escalate Call
                                </Typography>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <TextField
                                            onChange={this.updateEscalateCallData}
                                            className={classes.formControl}
                                            name="reason"
                                            id="reason"
                                            label="Reason"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            multiline
                                            rows="6"
                                            value={escalateCallData.reason}
                                            // error={!!errors.reason}
                                            // helperText={errors.reason}
                                            autoFocus
                                        />
                                    </Grid>
                                </Grid>
                                <div className={classes.dialogScript}>
                                    "YOU WILL BE CONTACTED BY A MEMBER OF OUR CUSTOMER SERVICE TEAM IN THE NEXT 2 WORKING DAYS"
                                </div>
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justify="center"
                                >
                                    <Grid item xs={12}>
                                        <Button
                                            className={classes.dialogButton}
                                            disabled={disableEscalateCallButton || !escalateCallData.reason}
                                            onClick={this.handleEscalateCallSave}
                                        >
                                            SAVE
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        </div>
                    </DialogContent>
                    <Divider />
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            onClick={this.handleCloseEscalateCallDialog}
                            color="primary"
                            autoFocus
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

AppBarBottom.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(withRouter(AppBarBottom)));
