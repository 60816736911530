import React from 'react';

import {
    Button,
    List,
    ListItem,
    ListItemText,
    FormControl,
    Grid,
    Paper,
    TextField,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';
import { red } from '@material-ui/core/colors';
import { faAlignCenter } from '@fortawesome/free-solid-svg-icons';
import fetch from '../../utilities/fetch';

const styles = (theme) => ({
    buttonBar: {
        position: 'fixed',
        paddingTop: 11,
        paddingBottom: 18,
        bottom: 0,
        width: '100%',
        height: 30,
        boxShadow: '0px 1px 8px 0px rgb(0 0 0 / 80%)',
        backgroundColor: '#f2f1f1',
        textAlign: 'center',
    },
    topButtonBar: {
        position: 'fixed',
        paddingTop: 11,
        paddingBottom: 18,
        top: 0,
        width: '100%',
        height: 30,
        // boxShadow: '0px 1px 8px 0px rgb(0 0 0 / 80%)',
        backgroundColor: 'aliceblue',
        textAlign: 'left',
    },
    clearButton: {
        textAlign: 'right',
        paddingRight: theme.spacing.unit * 2,
    },
    signatureText: {
        textAlign: 'left',
        paddingLeft: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit * 1,
    },
    contentContainer: {
        paddingBottom: 40,
        maxWidth: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    logo: {
        margin: theme.spacing.unit * 5,
        width: 128,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    logoContainer: {
        textAlign: 'center',
    },
    startContainer: {
        paddingRight: theme.spacing.unit * 4,
        paddingLeft: theme.spacing.unit * 4,
    },
    signatureMain: {
        width: '100%',
        height: '100vh',
        top: '10%',
        left: '10%',
    },
    signatureContainer: {
        width: '100%',
        // height: 200,
        // margin: '0 auto',
        paddingTop: 59,
    },
    signaturePad: {
        // minHeight: 300,
        height: '100vh',
        width: '100%',
        // opacity: 0.5,
        '&::after': {
            position: 'absolute',
            bottom: 100,
            width: '100%',
            borderBottom: 'dashed 1px red',
            content: '\'\'',
        },
    },
    text: {
        fontSize: 16,
    },
    // oldMain: {
    //     maxWidth: 300,
    //     textAlign: 'center',
    //     display: 'block', // Fix IE 11 issue.
    //     marginLeft: 'auto',
    //     marginRight: 'auto',
    //     [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
    //         width: 400,
    //         marginLeft: 'auto',
    //         marginRight: 'auto',
    //     },
    // },
    // main: {
    //     width: '100%',
    //     height: '100%',
    //     textAlign: 'center',
    //     display: 'block', // Fix IE 11 issue.
    //     paddingBottom: 30,
    //     backgroundColor: '#fafafa',
    //     paddingLeft: theme.spacing.unit * 3,
    //     paddingRight: theme.spacing.unit * 3,
    //     overflow: 'hidden',
    // },
    // // contentContainer: {
    // //     height: '100%',
    // //     paddingBottom: 30,
    // //     backgroundColor: 'red',
    // // },
    // accceptAndContinueContainer: {
    //     boxShadow: '0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%);',
    //     backgroundColor: '#fff',
    //     height: 60,
    //     position: 'absolute',
    //     left: 0,
    //     right: 0,
    //     bottom: 0,
    //     paddingTop: 11,
    // },
    // paper: {
    //     marginTop: theme.spacing.unit * 8,
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    // },
    // contentPaper: {
    //     // marginTop: theme.spacing.unit * 3,
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'left',
    //     padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    //     marginTop: theme.spacing.unit * 3,
    // },

    // scrollContent: {
    //     // maxHeight: 400,
    //     overflowY: 'scroll',
    // },
});

class AgreementSignature extends React.Component {
    state = {
        trimmedDataURL: null,
        stageNo: 0,
        stages: [
            'start',
            'termsAndConditions',
            'contract',
            'signature',
            'end',
        ],
        showEmptyDialog: false,
        showGuidErrorDialog: false,
    };

    sigPad = {};

    static getDerivedStateFromProps(props, state) {
        const { guid } = props.match.params;
        return {
            guid,
        };
    }

    componentDidMount = async () => {
        await this.fetchTermsAndConditions();
        await this.fetchContract();
        await this.fetchStartNotes();
    };

    fetchTermsAndConditions = async () => {
        let termsAndConditions;
        try {
            termsAndConditions = await fetch('https://api.portal2.payweeklycarpets.co.uk/core/v2/agreement/termsAndConditions', {
            // termsAndConditions = await fetch('http://api.portal2.payweeklycarpets.localhost:3001/v2/agreement/termsAndConditions', {
                method: 'GET',
            }).then((response) => response.text());
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }
        this.setState({
            termsAndConditions,
        });
    };

    handleContinueButton = () => {
        const newStageNo = this.state.stageNo + 1;
        this.setState({
            stageNo: newStageNo,
        });
    };

    fetchContract = async () => {
        // let contract;
        // try {
        //     contract = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/v2/agreement/${this.state.guid}/contract`, {
        //         method: 'GET',
        //     }).then(response => response.text());
        // } catch (e) {
        //     // something went really wrong; timeout/ blocked by client etc
        //     // debugger;
        //     console.log(e);
        // }
        let response;
        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/v2/agreement/${this.state.guid}/contract`, {
            // response = await fetch(`http://api.portal2.payweeklycarpets.localhost:3001/v2/agreement/${this.state.guid}/contract`, {
                method: 'GET',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        console.log('>>> response:', response);

        if (response.status !== 200) {
            this.setState({
                showGuidErrorDialog: true,
            });
        }

        const contract = await response.text();
        this.setState({
            contract,
        });
    };

    fetchStartNotes = async () => {
        let startNotes;
        try {
            startNotes = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/v2/agreement/${this.state.guid}/startNotes`, {
            // startNotes = await fetch(`http://api.portal2.payweeklycarpets.localhost:3001/v2/agreement/${this.state.guid}/startNotes`, {
                method: 'GET',
            }).then((response) => response.text());
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }
        this.setState({
            startNotes,
        });
    };

    handleSignatureClear = () => {
        this.sigPad.clear();
    };

    handleEmptyDialogClose = () => {
        this.setState({
            showEmptyDialog: false,
        });
    };

    handleSubmitSignature = async () => {
        if (this.sigPad.isEmpty()) {
            this.setState({
                showEmptyDialog: true,
            });
            return;
        }
        const formData = new FormData();
        formData.append('termsConditionsCode', 1000);
        const signature = this.sigPad.getTrimmedCanvas();
        const blob = await new Promise((resolve) => {
            signature.toBlob(resolve);
        });
        formData.append('signature', blob, 'signature.png');
        try {
            await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/v2/test/agreement/${this.state.guid}/start`, {
            // await fetch('http://api.portal2.payweeklycarpets.localhost:3001/v2/test/agreement/71201/start', {
                body: formData,
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }
        const newStageNo = this.state.stageNo + 1;
        this.setState({
            stageNo: newStageNo,
        });
    };

    render = () => {
        const { classes } = this.props;
        const {
            contract,
            showEmptyDialog,
            showGuidErrorDialog,
            stageNo,
            stages,
            startNotes,
            termsAndConditions,
        } = this.state;

        let logoUrl;
        const imageUrl = window.location.host === 'portal2.payweeklycarpets.co.uk' ? 'https://pwc-email-images.s3.eu-west-1.amazonaws.com/logo-main-small.png' : 'https://pwc-email-images.s3.eu-west-1.amazonaws.com/ass_logo_main_small.jpg';

        return (
            <>
                {stages[stageNo] === 'start'
                    ? (
                        <>
                            <div className={classes.logoContainer}>
                                <img
                                    className={classes.logo}
                                    src={imageUrl}
                                    alt="Pay Weekly Logo"
                                />
                            </div>
                            {!showGuidErrorDialog
                                ? (
                                    <>
                                        <div className={classes.contentContainer}>
                                            <div className={classes.startContainer}>
                                                <Typography
                                                    className={classes.text}
                                                    paragraph
                                                >
                                                    Hello,
                                                </Typography>
                                                <Typography className={classes.text}>To complete your recent order you must first:</Typography>
                                                <List component="nav">
                                                    <ListItem>
                                                        <ListItemText primary="Read and accept our Terms and Conditions" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary="Read and accept your contract" />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary="Add your signature" />
                                                    </ListItem>
                                                </List>
                                                <Typography className={classes.text}>You will receive a copy of the signed Terms and Conditions, and Contract by email when complete.</Typography>
                                            </div>
                                        </div>
                                        <div className={classes.buttonBar}>
                                            <Button
                                                onClick={this.handleContinueButton}
                                                color="primary"
                                            >
                                                CONTINUE
                                            </Button>
                                        </div>
                                    </>
                                )
                                : ''}
                            <Dialog
                                open={showGuidErrorDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">Invalid Link</DialogTitle>
                                <DialogContent className={classes.dialogContent}>
                                    <DialogContentText id="alert-dialog-description">
                                        This link has already been used or has expired.
                                    </DialogContentText>
                                </DialogContent>
                            </Dialog>

                        </>
                    )
                    : ''}
                {stages[stageNo] === 'termsAndConditions'
                    ? (
                        <>
                            <div
                                className={classes.contentContainer}
                                dangerouslySetInnerHTML={{ __html: termsAndConditions }}
                            />
                            <div className={classes.buttonBar}>
                                <Button
                                    onClick={this.handleContinueButton}
                                    color="primary"
                                >
                                    ACCEPT AND CONTINUE
                                </Button>

                            </div>
                        </>
                    )
                    : ''}
                {stages[stageNo] === 'contract'
                    ? (
                        <>
                            <div
                                className={classes.contentContainer}
                                dangerouslySetInnerHTML={{ __html: contract }}
                            />
                            <div className={classes.buttonBar}>
                                <Button
                                    onClick={this.handleContinueButton}
                                    color="primary"
                                >
                                    ACCEPT AND CONTINUE
                                </Button>

                            </div>
                        </>
                    )
                    : ''}
                {stages[stageNo] === 'signature'
                    ? (
                        <>
                            <div className={classes.signatureMain}>
                                <div className={classes.signatureContainer}>
                                    <SignatureCanvas
                                        penColor="black"
                                        canvasProps={{ className: classes.signaturePad }}
                                        ref={(ref) => { this.sigPad = ref; }}
                                    />
                                    <div className={classes.topButtonBar}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={6}>
                                                <Typography className={classes.signatureText}> Please sign below</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div className={classes.clearButton}>
                                                    <Button
                                                        onClick={this.handleSignatureClear}
                                                        color="primary"
                                                    >
                                                        CLEAR
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className={classes.buttonBar}>
                                        <Button
                                            onClick={this.handleSubmitSignature}
                                            color="primary"
                                        >
                                            CONTINUE
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <Dialog
                                open={showEmptyDialog}
                                onClose={this.handleEmptyDialogClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">Missing Signature</DialogTitle>
                                <DialogContent className={classes.dialogContent}>
                                    <DialogContentText id="alert-dialog-description">
                                        You must sign to continue.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleEmptyDialogClose} color="primary" autoFocus>
                                        OK
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    )
                    : '' }
                {stages[stageNo] === 'end'
                    ? (
                        <div
                            className={classes.contentContainer}
                            dangerouslySetInnerHTML={{ __html: startNotes }}
                        />
                    )
                    : ''}
            </>
        );
    };
}

AgreementSignature.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(withRouter(AgreementSignature)));
