import React from 'react';
import {
    CssBaseline,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeliveryArea from '../components/deliveryArea';
import DeliveryCustomer from '../components/deliveryCustomer';
import DeliveryItem from '../components/deliveryItem';
import DeliverySign from '../components/deliverySign';
import PageHeader from '../components/pageHeader';

import Layout from './Layout';
import fetch from '../utilities/fetch';
import sleep from '../utilities/sleep';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        marginBottom: theme.spacing.unit * 3,
    },
});

class Delivery extends React.Component {
    state = {
        customers: null,
        delivery: {
            errors: {},
        },
        deliveryStage: 'area',
        disableNextButton: false,
        items: [],
        autoPostcode: null,
        postcodeLoading: true,
        addItemError: null,
        postcodeError: null,
    }

    async componentDidMount() {
        // const url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/customers');
        // const params = {
        //     subset: 'delivery',
        // };
        // Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        // const data = await fetch(url).then(response => response.json());
        // this.setState({ customers: data });
    }

    setCustomer = (contactCode) => new Promise((resolve, reject) => {
        this.setState(state => ({
            delivery: {
                ...state.delivery,
                contactCode: contactCode,
            },
            deliveryStage: 'item',
        }));
        resolve();
    });

    setItems = () => new Promise((resolve, reject) => {
        this.setState(state => ({
            delivery: {
                ...state.delivery,
                items: state.items,
            },
            deliveryStage: 'sign',
        }));
        resolve();
    });

    setPostcode = async (postcode) => {
        const url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/customers');
        const params = {
            subset: 'delivery',
            postcode: postcode,
        };
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        const data = await fetch(url).then(response => response.json());

        if (data.length > 0) {
            this.setState({ customers: data });
            this.setState(state => ({
                delivery: {
                    ...state.delivery,
                    postcode: postcode,
                },
                deliveryStage: 'customer',
            }));
        } else {
            this.setState({ postcodeError: 'No delivery customers found.' });
        }
    };

    setSignature = (signature) => new Promise((resolve, reject) => {
        this.setState(state => ({
            delivery: {
                ...state.delivery,
                signature: signature,
            },
            deliveryStage: 'confirm',
        }), () => {
            this.saveDelivery()
        });
    });

    saveDelivery = () => {
        console.log(this.state.delivery);
    }

    fetchItem = async (itemCode) => {
        let response;
        const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/item/${itemCode}`);
        response = await fetch(url);
        return (response);
    }

    addItem = async (itemCode) => {
        // Reset Error
        this.setState({
            addItemError: null,
        });
        // Check item not in Items
        if (this.state.items.find(item => item.code === itemCode)) {
            this.setState({
                addItemError: 'This item has already been selected',
            });
            return Promise.reject();
        }
        const response = await this.fetchItem(itemCode);
        console.log(response.status);
        // Check item exists
        if (response.status === 204) {
            this.setState({
                addItemError: 'Item Stock Code not found',
            });
            return Promise.reject();
        }
        let data;
        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            data = await response.json();
        }
        // Check item available
        if (data.itemAssignmentDescription !== 'In Stock') {
            this.setState({
                addItemError: `Item not available. Currently ${data.itemAssignmentDescription}`,
            });
            return Promise.reject();
        }
        // Add item
        data.testStatus = data.controlDeviceIccid ? 'Waiting' : 'n/a';
        this.setState(state => ({
            items: [
                ...state.items,
                data,
            ],
        }));
        return Promise.resolve();
    }

    setTestStatus = async (itemCode, testStatus) => {
        const mappedItems = this.state.items.map((item) => {
            if (item.code === itemCode) {
                item = { ...item, testStatus: testStatus };
            }
            return item;
        });
        this.setState(state => ({
            items: mappedItems,
        }));
    }

    removeItem = async (itemCode) => {
        const filteredItems = this.state.items.filter((item) => item.code !== itemCode);
        this.setState(state => ({
            items: filteredItems,
        }));
        console.log(filteredItems);
    }

    sendControlDeviceCommand = async (controlDeviceIccid, command, controlDeviceTypeCode) => {
        const response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/control-device-iccid/${controlDeviceIccid}/command`, {
            method: 'POST',
            body: JSON.stringify({
                command: command,
                controlDeviceTypeCode: controlDeviceTypeCode,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return (response);
    }

    testControlDevice = async (controlDeviceIccid, controlDeviceTypeCode) => {
        let response;
        response = await this.sendControlDeviceCommand(controlDeviceIccid, 'SET_PRODUCT_TYPE', controlDeviceTypeCode);
        await sleep(2000);
    }

    handleSkipTestButtonClick = async (itemCode) => {
        await this.setTestStatus(itemCode, 'Skipped');
    }

    handleStartTestButtonClick = async (itemCode, controlDeviceIccid, controlDeviceTypeCode) => {
        await this.setTestStatus(itemCode, 'wait');
        await this.testControlDevice(controlDeviceIccid, controlDeviceTypeCode);
        await this.setTestStatus(itemCode, 'result');
    }

    handlePassTestButtonClick = async (itemCode) => {
        await this.setTestStatus(itemCode, 'Pass');
    }

    handleFailTestButtonClick = async (itemCode) => {
        await this.setTestStatus(itemCode, 'Fail');
    }

    fetchLocationPostcode = async (position) => {
        const url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/geocode/latLng');
        const params = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        };
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        const data = await fetch(url).then(response => response.json());
        return Promise.resolve(data.shortPostcode);
    }

    render() {
        const {
            addItemError,
            customers,
            delivery,
            deliveryStage,
            disableNextButton,
            items,
            postcodeError,
        } = this.state;

        const pageHeaderContent = {
            leftTitle: 'Delivery & Installation',
        };

        return (
            <Layout pageTitle="Delivery Customers">
                <CssBaseline />
                <PageHeader content={pageHeaderContent} />
                    { deliveryStage === 'area'
                        ? (
                            <DeliveryArea
                                delivery={delivery}
                                setPostcode={this.setPostcode}
                                disableNextButton={disableNextButton}
                                fetchLocationPostcode={this.fetchLocationPostcode}
                                postcodeError={postcodeError}
                            />
                        )
                        : null
                    }
                    { deliveryStage === 'customer' & customers !== null
                        ? (
                            <DeliveryCustomer
                                customers={customers}
                                delivery={delivery}
                                setCustomer={this.setCustomer}
                                disableNextButton={disableNextButton}
                            />
                        )
                        : null
                    }
                { deliveryStage === 'item'
                    ? (
                        <DeliveryItem
                            delivery={delivery}
                            items={items}
                            setItems={this.setItems}
                            addItem={this.addItem}
                            disableNextButton={disableNextButton}
                            addItemError={addItemError}
                            handleSkipTestButtonClick={this.handleSkipTestButtonClick}
                            removeItem={this.removeItem}
                            testControlDevice={this.testControlDevice}
                            handleStartTestButtonClick={this.handleStartTestButtonClick}
                            handlePassTestButtonClick={this.handlePassTestButtonClick}
                            handleFailTestButtonClick={this.handleFailTestButtonClick}
                        />
                    )
                    : null
                }
                { deliveryStage === 'sign'
                    ? (
                        <DeliverySign
                            delivery={delivery}
                            setSignature={this.setSignature}
                            disableNextButton={disableNextButton}
                        />
                    )
                    : null
                }
                { deliveryStage === 'confirm'
                    ? (
                        <div>Done!</div>
                    )
                    : null
                }

            </Layout>
        );
    }
}

export default withStyles(styles)(Delivery);
