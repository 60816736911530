const titles = [
    'Mr',
    'Mrs',
    'Miss',
    'Ms',
    'Mx',
];

const counties = [
    'Aberdeenshire',
    'Angus',
    'Antrim',
    'Argyll & Bute',
    'Armagh',
    'Ayrshire',
    'Banffshire',
    'Bedfordshire',
    'Berkshire',
    'Berwickshire',
    'Blaenau Gwent',
    'Borders',
    'Bridgend',
    'Bristol',
    'Buckinghamshire',
    'Caerphilly',
    'Caithness',
    'Cambridgeshire',
    'Cardiff',
    'Carmarthenshire',
    'Ceredigion',
    'Cheshire',
    'Clackmannanshire',
    'Conwy',
    'Cornwall',
    'County Durham',
    'Cumbria',
    'Denbighshire',
    'Derbyshire',
    'Devon',
    'Dorset',
    'Down',
    'Dumfries & Galloway',
    'Dunbartonshire',
    'East Ayrshire',
    'East Dunbartonshire',
    'East Lothian',
    'East Renfrewshire',
    'East Riding of Yorkshire',
    'East Sussex',
    'Essex',
    'Fermanagh',
    'Fife',
    'Flintshire',
    'Gloucestershire',
    'Greater London',
    'Greater Manchester',
    'Gwynedd',
    'Hampshire',
    'Herefordshire',
    'Hertfordshire',
    'Highland',
    'Inverclyde',
    'Isle of Anglesey',
    'Isle of Wight',
    'Isles of Scilly',
    'Kent',
    'Kincardineshire',
    'Lanarkshire',
    'Lancashire',
    'Leicestershire',
    'Lincolnshire',
    'Londonderry',
    'Merseyside',
    'Merthyr Tydfil',
    'Midlothian',
    'Monmouthshire',
    'Moray',
    'Neath Port Talbot',
    'Newport',
    'Norfolk',
    'North Ayrshire',
    'North Lanarkshire',
    'North Somerset',
    'North Yorkshire',
    'Northamptonshire',
    'Northumberland',
    'Nottinghamshire',
    'Orkney',
    'Oxfordshire',
    'Pembrokeshire',
    'Perth & Kinross',
    'Powys',
    'Renfrewshire',
    'Rhondda Cynon Taff',
    'Rutland',
    'Shetland',
    'Shropshire',
    'Somerset',
    'South Ayrshire',
    'South Gloucestershire',
    'South Lanarkshire',
    'South Yorkshire',
    'Staffordshire',
    'Stirlingshire',
    'Suffolk',
    'Surrey',
    'Swansea',
    'Torfaen',
    'Tyne & Wear',
    'Tyrone',
    'Vale of Glamorgan',
    'Warwickshire',
    'West Dunbartonshire',
    'West Lothian',
    'West Midlands',
    'West Sussex',
    'West Yorkshire',
    'Western Isles',
    'Wiltshire',
    'Worcestershire',
    'Wrexham',
];

const countries = [
    'England',
    'Northern Ireland',
    'Scotland',
    'United Kingdom',
    'United States of America',
    'Wales',
];

const paymentPlanStatus = [
    'ACTIVE',
    'CANCELLED',
    'COMPLETE',
    'PENDING',
    'SUSPENDED',
];

const adHocCompanies = [
    'Carpet and Floors Online',
    'Carpet Monster',
    'PWC Portal 1',
    'PWC Portal 2',
    'The GB Sofa Company',
];

const adHocPaymentMethods = [
    'Cash',
    'Divide By',
    'Service Call',
    'Snap Finance',
    'PWC Portal 1',
];

const scheduleFrequencies = [
    // 'DAILY',
    'WEEKLY',
    'FORTNIGHTLY',
    'MONTHLY',
    'TWICEMONTHLY',
    // 'QUARTERLY',
];

export {
    titles,
    counties,
    countries,
    paymentPlanStatus,
    adHocCompanies,
    adHocPaymentMethods,
    scheduleFrequencies,
};
