import React, { isValidElement } from 'react';
import {
    CircularProgress,
    CssBaseline,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import Layout from '../Layout';
import fetch, { fetchJson2 } from '../../utilities/fetch';
import EngineersTable from '../../components/v2/engineersTable';

// import ProductCreateDialog from '../../components/v2/manufacturerSupplierCreateDialog';
// import ProductEditDialog from '../../components/v2/manufacturerSupplierEditDialog';
// import ProductExpireDialog from '../../components/v2/manufacturerSupplierExpireDialog';
import EngineerExpireDialog from '../../components/v2/engineerExpireDialog';
import TransferEngineerVisitsDialog from '../../components/v2/transferEngineerVisitsDialog';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    inputLabel: {
        backgroundColor: '#fff',
        marginLeft: -5,
        paddingLeft: 8,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    newPurchaseOrderDialog: {
        maxWidth: 'none',
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
        minWidth: 400,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    itemsContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    itemsAddButtonContainer: {
        textAlign: 'right',
    },
    itemsTitleContainer: {
        marginTop: 13,
    },
    fieldAddButton: {
        marginLeft: 24,
        marginTop: 24,
    },
    radioGroup: {
        marginLeft: 24,
    },
    radioFormControl: {
        marginTop: 16,
    },
});

class Engineers extends React.Component {
    state = {
        currentEngineer: {},
        branchesLoading: true,
        engineersLoading: true,
        errors: [],
        // showCreateProductDialog: false,
        // showEditProductDialog: false,
        showExpireEngineerDialog: false,
        showTransferEngineerVisitsDialog: true,
    };

    componentDidMount() {
        document.addEventListener('visibilitychange', this.onVisibilitychange);
        this.loadEngineersData();
        this.loadBranchesData();
    }

    componentWillUnmount() {
        document.removeEventListener('visibilitychange', this.onVisibilitychange);
    }

    onVisibilitychange = () => {
        if (document.hidden === true) return;
        this.refreshEngineersData();
    };

    refreshEngineersData = () => {
        this.loadEngineersData().then(() => {
            this.props.enqueueSnackbar('UPDATED', { variant: 'success', autoHideDuration: 750 });
        }).catch(() => {
            this.props.enqueueSnackbar('UPDATE FAILED', { variant: 'error', autoHideDuration: 750 });
        });
    };

    loadEngineersData = async () => {
        fetchJson2('v4/engineers')
            .then((engineers) => {
                this.setState({
                    engineers: engineers.payload,
                    engineersLoading: false,
                });
            });
    };

    loadBranchesData = async () => {
        fetchJson2('v2/branches')
            .then((branches) => {
                this.setState({
                    branches: branches.payload,
                    branchesLoading: false,
                });
            });
    };

    passNewEngineer = (newEngineer) => {
        console.log('>>> passNewEngineer!!');
        let newEngineers;
        if (!this.state.engineers.find((manufacturerSupplier) => manufacturerSupplier.code === newEngineer.code)) {
            newEngineers = [
                ...this.state.engineers,
                newEngineer,
            ];
        } else {
            newEngineers = this.state.engineers.map((manufacturerSupplier) => ((manufacturerSupplier.code === newEngineer.code) ? newEngineer : manufacturerSupplier));
        }
        this.setState({
            engineers: newEngineers,
        });
    };

    handleExpireEngineerClick = async (engineerCode, event) => {
        event.stopPropagation();
        const newCurrentEngineer = this.state.engineers.find((engineer) => engineer.engineer.code === engineerCode);

        this.setState({
            currentEngineer: {
                ...newCurrentEngineer,
                histories: [],
            },
            showExpireEngineerDialog: true,
        });
    };

    handleTransferEngineerVisitsClick = async (engineerCode, event) => {
        event.stopPropagation();
        const newCurrentEngineer = this.state.engineers.find((engineer) => engineer.engineer.code === engineerCode);

        this.setState({
            currentEngineer: {
                ...newCurrentEngineer,
                histories: [],
            },
            showTransferEngineerVisitsDialog: true,
        });
    };

    // handleCreateProductClick = () => {
    //     this.setState({
    //         showCreateProductDialog: true,
    //     });
    // };

    // handleEditProductClick = (manufacturerSupplierCode, event) => {
    //     event.stopPropagation();
    //     const newCurrentEngineer = this.state.products.find((manufacturerSupplier) => manufacturerSupplier.code === manufacturerSupplierCode);
    //     this.setState({
    //         currentEngineer: {
    //             ...newCurrentEngineer,
    //             histories: [],
    //         },
    //         showEditProductDialog: true,
    //     });
    // };

    // handleCloseEditProductDialogClick = () => {
    //     this.setState({
    //         showEditProductDialog: false,
    //     });
    // };

    // handleCloseCreateProductDialogClick = () => {
    //     this.setState({
    //         showCreateProductDialog: false,
    //     });
    // };

    handleCloseExpireEngineerDialogClick = () => {
        this.setState({
            showExpireEngineerDialog: false,
        });
    };

    handleCloseTransferEngineerVisitsDialogClick = () => {
        this.setState({
            showTransferEngineerVisitsDialog: false,
        });
    };

    unexpireEngineer = async (engineerCode) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/engineer/${engineerCode}`, {
                body: JSON.stringify({ expired: 0 }),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
              && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            this.passNewEngineer(body.engineer);
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    render() {
        const {
            classes,
        } = this.props;

        const {
            branches,
            branchesLoading,
            currentEngineer,
            engineersLoading,
            // showCreateProductDialog,
            // showEditProductDialog,
            showExpireEngineerDialog,
            showTransferEngineerVisitsDialog,
            errors,
            engineers,
        } = this.state;

        return (
            <Layout pageTitle="Operatives">
                <CssBaseline />
                {
                    !engineersLoading
                    && !branchesLoading
                        ? (
                            <>
                                <EngineersTable
                                    errors={errors}
                                    branches={branches}
                                    handleExpireEngineerClick={this.handleExpireEngineerClick}
                                    handleTransferEngineerVisitsClick={this.handleTransferEngineerVisitsClick}
                                    handleCloseExpireEngineerDialogClick={this.handleCloseExpireEngineerDialogClick}
                                    engineers={engineers}
                                    branchesLoading={branchesLoading}
                                    engineersLoading={engineersLoading}
                                    passNewEngineer={this.passNewEngineer}
                                    unexpireEngineer={this.unexpireEngineer}
                                    // editEngineers={this.editEngineers}
                                    // expireEngineers={this.expireEngineers}
                                    // handleCreateEngineersClick={this.handleCreateEngineersClick}
                                    // handleEditEngineersClick={this.handleEditEngineersClick}
                                    // handleCloseEditEngineersDialogClick={this.handleCloseEditEngineersDialogClick}
                                />

                                {/* <ProductCreateDialog
                                    handleCloseCreateProductDialogClick={this.handleCloseCreateProductDialogClick}
                                    engineers={engineers}
                                    manufacturerSupplierLoading={engineersLoading}
                                    showCreateProductDialog={showCreateProductDialog}
                                    passNewEngineer={this.passNewEngineer}
                                />
                                <ProductEditDialog
                                    handleCloseEditProductDialogClick={this.handleCloseEditProductDialogClick}
                                    manufacturerSupplier={currentEngineer}
                                    manufacturerSupplierLoading={engineersLoading}
                                    showEditProductDialog={showEditProductDialog}
                                    passNewEngineer={this.passNewEngineer}
                                /> */}
                                <EngineerExpireDialog
                                    handleCloseExpireEngineerDialogClick={this.handleCloseExpireEngineerDialogClick}
                                    engineer={currentEngineer}
                                    engineerLoading={engineersLoading}
                                    passNewEngineer={this.passNewEngineer}
                                    showExpireEngineerDialog={showExpireEngineerDialog}
                                />
                                <TransferEngineerVisitsDialog
                                    handleCloseTransferEngineerVisitsDialogClick={this.handleCloseTransferEngineerVisitsDialogClick}
                                    engineer={currentEngineer}
                                    engineerLoading={engineersLoading}
                                    engineers={engineers}
                                    passNewEngineer={this.passNewEngineer}
                                    showTransferEngineerVisitsDialog={showTransferEngineerVisitsDialog}
                                />
                            </>
                        )
                        : (
                            <div className={classes.progressContainer}>
                                <CircularProgress className={classes.progress} variant="indeterminate" />
                            </div>
                        )
                }

            </Layout>
        );
    }
}

export default withSnackbar(withStyles(styles)(Engineers));
