const visitPointsArray = [
    1,
    2,
    3,
    4,
    5,
    6,
];

const historyEntryTypesArray = [
    'AGREEMENT',
    'COMPLAINT',
    'CONTACT',
    'LEAD',
    'SALE',
    'VISIT',
];

const paymentSourcesArray = [
    {
        source: 'REFUND',
        displaySource: 'Refund',
    },
    {
        source: 'RETRYPAYMENT',
        displaySource: 'Retry Payment',
    },
    {
        source: 'RECURRINGPAYMENT',
        displaySource: 'Recurring Payment',
    },
    {
        source: 'MANUALPAYMENT',
        displaySource: 'Manual Payment',
    },
    {
        source: 'LINK',
        displaySource: 'Link',
    },
    {
        source: 'REVERSAL',
        displaySource: 'Reversal',
    },
    {
        source: 'HOLIDAYPAYMENT',
        displaySource: 'Holiday Payment',
    },
    {
        source: 'INTERIMPAYMENT',
        displaySource: 'Interim Payment',
    },
    {
        source: 'MANUALADJUSTMENT',
        displaySource: 'Manual Adjustment',
    },
    {
        source: 'STANDINGORDER',
        displaySource: 'Standing Order',
    },
    {
        source: 'CREDITCHARGE',
        displaySource: 'Credit Charge',
    },
    {
        source: 'VIRTUALTERMINAL',
        displaySource: 'Virtual Terminal',
    },
    {
        source: 'ATP',
        displaySource: 'Automated Telephone Payment',
    },
];

module.exports = {
    historyEntryTypesArray,
    paymentSourcesArray,
    visitPointsArray,
};
