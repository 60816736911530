import React from 'react';
import {
    CssBaseline,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { fetchJson } from '../utilities/fetch';
import ItemsPrintTable from '../components/itemsPrintTable';

const cookies = new Cookies();

const styles = theme => ({
    newDeliveryNoteButton: {
        marginBottom: theme.spacing.unit * 3,
    },
});

class ItemsPrint extends React.Component {
    state = {
        branches: null,
        controls: {},
        filters: {},
        items: [],
        itemsConditions: null,
        itemsSummary: null,
        keyLookup: {
            code: 'code',
            manufacturer: 'manufacturerName',
            model: 'modelNo',
            colour: 'colour',
            type: 'productTypeDescription',
            branch: 'branchName',
            condition: 'itemConditionDescription',
            length: 'length',
            width: 'width',
        },
        products: null,
    }

    componentDidMount = async () => {
        await this.setDefaultFilters();
        await this.setDefaultControls();
        const newControls = {
            ...this.state.controls,
            pageSize: 10000000,
        };
        this.fetchItems(newControls, this.state.filters).then((items) => {
            this.setState({
                items,
            });
        });
    }

    setDefaultFilters = async () => {
        const itemsFiltersCookie = cookies.get('itemsFilters') || {};
        const statePromise = new Promise((resolve) => {
            this.setState({
                filters: itemsFiltersCookie,
            }, resolve);
        });
        return statePromise;
    }

    setDefaultControls = async () => {
        const itemsControlsCookie = cookies.get('itemsControls') || {};
        const statePromise = new Promise((resolve) => {
            this.setState({
                controls: itemsControlsCookie,
            }, resolve);
        });
        return statePromise;
    }

    fetchItems = async (controls, filters) => {
        this.setState({
            items: [],
        });
        const itemsURL = new URL('https://api.portal2.payweeklycarpets.co.uk/core/itemsList');
        const params = {
            ...controls,
            ...filters,
        };
        Object.keys(params).forEach(key => itemsURL.searchParams.append(key, params[key]));
        const items = await fetchJson(itemsURL);
        return (items);
    }

    render() {
        const {
            controls,
            filters,
            items,
            keyLookup,
        } = this.state;

        return (
            <React.Fragment>
                <CssBaseline />
                <ItemsPrintTable
                    controls={controls}
                    filters={filters}
                    items={items}
                    keyLookup={keyLookup}
                />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(ItemsPrint);
