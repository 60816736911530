import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import fetch, { fetchJson2 } from '../../utilities/fetch';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    dialogContentContainer: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    dialogContent: {
        minWidth: 600,
        paddingBottom: 16,
    },
    dialogButton: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 4,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        margin: '0px 0px 0px 0px !important',
        padding: '8px 24px',
    },
    dialogDivider: {
        marginBottom: theme.spacing.unit * 2,
        marginLeft: -24,
        marginRight: -24,
        marginTop: theme.spacing.unit * 2,
    },
    disabled: {
        opacity: 0.26,
    },
    dialogExpiredCheckBox: {
        textAlign: 'right',
    },
    checkBox: {
        fontSize: 16,
    },
    dialogTitle: {
        marginBottom: theme.spacing.unit * 2,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
});

const CARPET_WIDTHS = [4, 5];
const VINYL_WIDTHS = [2, 3, 4];

class ExchangeAgreementItemDialog extends React.Component {
    state = {
        exchangeAgreementItemData: {},
        disableExchangeAgreementItemSaveButton: false,
        errors: [],
        products: null,
        productsLoading: true,
    };

    componentDidMount() {
        // console.log('>>> MOUNTED');
        this.setFormDefaults();
        if (!isEmpty(this.props.currentAgreement)) {
            this.fetchProducts(this.props.currentAgreement.productTypeCode);
        }
    }

    componentDidUpdate = async (prevProps) => {
        // console.log('>>> DID UPDATE');
        // console.log(`>>> ${this.props.currentAgreement.productTypeCode} : ${prevProps.currentAgreement.productTypeCode}`);
        if ((this.props.currentAgreement.productTypeCode !== prevProps.currentAgreement.productTypeCode) && !isEmpty(this.props.currentAgreement)) {
            await this.fetchProducts(this.props.currentAgreement.productTypeCode);
        }
    };

    fetchProducts = async (productTypeCode) => {
        // console.log('>>> FETCHING PRODUCTS');
        this.setState({
            productsLoading: true,
        });
        fetchJson2(`v2/products?productTypeCode=${productTypeCode}`)
            .then((products) => {
                this.setState({ products: products.payload });
            });
        this.setState({
            productsLoading: false,
        });
    };

    setFormDefaults = () => {
        this.setState({
            exchangeAgreementItemData: {
                ...this.props.currentEditAgreementItem,
                productCode: this.props.currentEditAgreementItem.product.code,
                categoryCode: this.props.currentEditAgreementItem.product.productTypeSubCategoryCode,
                quantity: 1,
            },
        });
    };

    updateExchangeAgreementItemData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        let addressData;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            exchangeAgreementItemData: {
                ...state.exchangeAgreementItemData,
                [name]: formattedValue,
            },
        }));
    };

    handleExchangeAgreementItem = () => {
        console.log('>>> Exchanging Started');
        console.log('>>> currentEditAgreementItem:', this.props.currentEditAgreementItem);
        console.log('>>> currentEditAgreementItemVisit:', this.props.currentEditAgreementItemVisit);
        const cancelVisit = (this.props.currentEditAgreementItem && (this.props.currentEditAgreementItemVisit || {}).visitItemQuantity === 1) ? !this.state.exchangeAgreementItemData.exchangeVisit : false;
        const removeFromVisit = !!(this.props.currentEditAgreementItem && (this.props.currentEditAgreementItemVisit || {}).status && (this.props.currentEditAgreementItemVisit || {}).status !== 'CANCELLED');
        const unassignItem = !!(this.props.currentEditAgreementItem || {}).item;
        const visitCode = this.props.currentEditAgreementItemVisit.code || null;
        const visitItemCode = this.props.currentEditAgreementItemVisit.visitItemCode || null;
        const exchangeVisit = !!visitCode && this.state.exchangeAgreementItemData.exchangeVisit;

        const params = {
            cancelVisit,
            exchangeVisit,
            removeFromVisit,
            unassignItem,
            visitCode,
            visitItemCode,
        };

        console.log('>>> params:', params);

        this.setState({
            disableExchangeAgreementItemSaveButton: true,
        });
        const payload = this.state.exchangeAgreementItemData;
        this.props.exchangeAgreementItem(
            this.props.currentAgreement.code,
            this.props.currentEditAgreementItem.code,
            params,
            payload,
        ).then(() => {
            this.setState({
                disableExchangeAgreementItemSaveButton: false,
            });
            this.props.handleCloseExchangeAgreementItemDialogClick();
            this.props.enqueueSnackbar('Manufacturer/Supplier Created', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Create Manufacturer/Supplier Failed', { variant: 'error' });
            this.setState({
                disableExchangeAgreementItemSaveButton: false,
            });
        });
    };

    // addAgreementItem = async (payload) => {
    //     let response;
    //     let body;

    //     try {
    //         response = await fetch(`${REACT_APP_API_SERVER}v2/manufacturerSupplier/`, {
    //             body: JSON.stringify(payload),
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             method: 'POST',
    //         });
    //     } catch (e) {
    //         // something went really wrong; timeout/ blocked by client etc
    //         // debugger;
    //         console.log(e);
    //     }

    //     if (response.headers.get('content-type')
    //           && response.headers.get('content-type').search('application/json') >= 0) {
    //         body = await response.json();
    //     }

    //     switch (response.status) {
    //     case 200: {
    //         const newManufacturerSupplier = {
    //             ...body.manufacturerSupplier,
    //             histories: [
    //                 body.history,
    //             ],
    //         };
    //         this.props.passNewManufacturerSupplier(newManufacturerSupplier);
    //         break;
    //     }
    //     default: {
    //         throw new Error({ error: 'Generic API error' });
    //     }
    //     }
    // };

    updateExchangeVisitCheckbox = (event) => {
        const { name } = event.target;
        const newState = !!event.target.checked;
        this.setState((state) => ({
            exchangeAgreementItemData: {
                ...state.exchangeAgreementItemData,
                [name]: newState,
            },
        }));
    };

    render() {
        const {
            classes,
            currentAgreement,
            currentEditAgreementItem,
            currentEditAgreementItemVisit,
            handleCloseExchangeAgreementItemDialogClick,
            showExchangeAgreementItemDialog,
        } = this.props;

        const {
            disableExchangeAgreementItemSaveButton,
            exchangeAgreementItemData,
            errors,
            products,
            productsLoading,
        } = this.state;

        // console.log('>>> currentAgreement:', currentAgreement);
        // let manufacturers;
        // if (!productsLoading && products) {
        //     manufacturers = uniqBy(products.map((product) => (product.manufacturer)), 'code');
        // }
        let categories;
        let currentProduct;
        if (!productsLoading && products) {
            categories = uniqBy(products.map((product) => ({
                code: product.productTypeSubCategoryCode,
                name: product.productTypeSubCategory,
            })), 'code');
            currentProduct = products.find((product) => product.code === exchangeAgreementItemData.productCode);
        }

        // console.log('>>> currentProduct:', currentProduct);

        return (
            <>
                {!productsLoading && products
                    ? (
                        <Dialog
                            open={showExchangeAgreementItemDialog}
                            onClose={handleCloseExchangeAgreementItemDialogClick}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogContent className={classes.dialogContent}>
                                <div className={classes.dialogContentContainer}>
                                    <>
                                        <Typography variant="h6" className={classes.dialogTitle}>
                                            Exchange Agreement Item
                                        </Typography>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <FormControl
                                                    className={classes.menuFormControl}
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    // error={!!errors.categoryCode}
                                                >
                                                    <InputLabel htmlFor="categoryCode" className={classes.inputLabel}>Category</InputLabel>
                                                    <Select
                                                        keyboard="true"
                                                        input={(
                                                            <OutlinedInput
                                                                onChange={this.updateExchangeAgreementItemData}
                                                                name="categoryCode"
                                                                id="categoryCode"
                                                            />
                                                        )}
                                                        value={exchangeAgreementItemData.categoryCode || ''}
                                                    >
                                                        {orderBy(categories, 'name').map((category) => (
                                                            [
                                                                <MenuItem
                                                                    key={category.code}
                                                                    value={category.code}
                                                                >
                                                                    {category.name}
                                                                </MenuItem>,
                                                            ]
                                                        ))}
                                                    </Select>
                                                    {errors.categoryCode && (
                                                        <FormHelperText>{errors.categoryCode}</FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl
                                                    className={classes.menuFormControl}
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    // error={!!errors.productCode}
                                                >
                                                    <InputLabel htmlFor="productCode" className={classes.inputLabel}>Model</InputLabel>
                                                    <Select
                                                        keyboard="true"
                                                        input={(
                                                            <OutlinedInput
                                                                onChange={this.updateExchangeAgreementItemData}
                                                                name="productCode"
                                                                id="productCode"
                                                            />
                                                        )}
                                                        disabled={!exchangeAgreementItemData.categoryCode}
                                                        value={exchangeAgreementItemData.productCode || ''}
                                                    >
                                                        {orderBy(products.filter((product) => product.productTypeSubCategoryCode === exchangeAgreementItemData.categoryCode), 'modelNo').map((product) => (
                                                            [
                                                                <MenuItem
                                                                    key={product.code}
                                                                    value={product.code}
                                                                >
                                                                    {product.modelNo}
                                                                </MenuItem>,
                                                            ]
                                                        ))}
                                                    </Select>
                                                    {errors.productCode && (
                                                        <FormHelperText>{errors.productCode}</FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField
                                                    className={classes.formControl}
                                                    name="reference"
                                                    id="reference"
                                                    onChange={this.updateExchangeAgreementItemData}
                                                    value={exchangeAgreementItemData.reference}
                                                    label="Reference"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    error={!!errors.reference}
                                                    helperText={errors.reference}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    className={classes.formControl}
                                                    name="installationAmount"
                                                    id="installationAmount"
                                                    onChange={this.updateExchangeAgreementItemData}
                                                    value={exchangeAgreementItemData.installationAmount}
                                                    label="Installation Amount"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    error={!!errors.installationAmount}
                                                    helperText={errors.installationAmount}
                                                    InputProps={{
                                                        startAdornment:
                            <InputAdornment
                                disableTypography
                                className={classes.inputAdornment}
                                position="start"
                            >
                                £
                            </InputAdornment>,
                                                    }}
                                                />
                                            </Grid>

                                            {exchangeAgreementItemData.categoryCode === 1003 || exchangeAgreementItemData.categoryCode === 1004
                                                ? (
                                                    <Grid item xs={12}>
                                                        <FormControl
                                                            className={classes.menuFormControl}
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            // error={!!errors.width}
                                                        >
                                                            <InputLabel htmlFor="width" className={classes.inputLabel}>Width</InputLabel>
                                                            <Select
                                                                keyboard="true"
                                                                input={(
                                                                    <OutlinedInput
                                                                        onChange={this.updateExchangeAgreementItemData}
                                                                        name="width"
                                                                        id="width"
                                                                    />
                                                                )}
                                                                disabled={!exchangeAgreementItemData.categoryCode}
                                                                value={exchangeAgreementItemData.width || ''}
                                                            >
                                                                {exchangeAgreementItemData.categoryCode === 1003 && CARPET_WIDTHS.map((width) => (
                                                                    [
                                                                        <MenuItem
                                                                            key={width}
                                                                            value={width}
                                                                        >
                                                                            {width}
                                                                            m
                                                                        </MenuItem>,
                                                                    ]
                                                                ))}
                                                                {exchangeAgreementItemData.categoryCode === 1004 && VINYL_WIDTHS.map((width) => (
                                                                    [
                                                                        <MenuItem
                                                                            key={width}
                                                                            value={width}
                                                                        >
                                                                            {width}
                                                                            m
                                                                        </MenuItem>,
                                                                    ]
                                                                ))}

                                                            </Select>
                                                            {errors.width && (
                                                                <FormHelperText>{errors.width}</FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                ) : ''}

                                            {exchangeAgreementItemData.categoryCode !== 1003 && exchangeAgreementItemData.categoryCode !== 1004
                                                ? (
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={`${classes.formControl} ${classes.marginTop3x}`}
                                                            name="width"
                                                            id="width"
                                                            value={exchangeAgreementItemData.width}
                                                            onChange={this.updateExchangeAgreementItemData}
                                                            label="Width"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            // error={!!errors.width}
                                                            // helperText={errors.width}
                                                            InputProps={{
                                                                endAdornment:
                                                        <InputAdornment
                                                            disableTypography
                                                            className={classes.inputAdornment}
                                                            position="start"
                                                        >
                                                            m
                                                        </InputAdornment>,
                                                            }}
                                                        />
                                                    </Grid>
                                                ) : ''}

                                            <Grid item xs={12}>
                                                <TextField
                                                    className={`${classes.formControl} ${classes.marginTop3x}`}
                                                    name="length"
                                                    id="length"
                                                    value={exchangeAgreementItemData.length}
                                                    onChange={this.updateExchangeAgreementItemData}
                                                    label="Length"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    // error={!!errors.length}
                                                    // helperText={errors.length}
                                                    InputProps={{
                                                        endAdornment:
                            <InputAdornment
                                disableTypography
                                className={classes.inputAdornment}
                                position="start"
                            >
                                m
                            </InputAdornment>,
                                                    }}
                                                />
                                            </Grid>

                                            {currentProduct && exchangeAgreementItemData.categoryCode === 1005
                                                ? (
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="wastagePercent"
                                                            id="wastagePercent"
                                                            value={currentProduct.wastagePercent}
                                                            label="Wastage"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.wastagePercent}
                                                            helperText={errors.wastagePercent}
                                                            disabled
                                                            InputProps={{
                                                                endAdornment:
                                                        <InputAdornment
                                                            disableTypography
                                                            className={classes.inputAdornment}
                                                            position="start"
                                                        >
                                                            %
                                                        </InputAdornment>,
                                                            }}

                                                        />
                                                    </Grid>
                                                ) : ''}

                                            <Grid item xs={12}>
                                                <TextField
                                                    className={classes.formControl}
                                                    name="quantity"
                                                    id="quantity"
                                                    onChange={this.updateExchangeAgreementItemData}
                                                    value={exchangeAgreementItemData.quantity}
                                                    label="Quantity"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    // helperText="Quantity is calculated by adding wasteage and rounding up to nearest package size"
                                                    disabled={
                                                        (!(products.find((product) => product.code === exchangeAgreementItemData.productCode) || {}).stockControlType)
                                                        || ((products.find((product) => product.code === exchangeAgreementItemData.productCode) || {}).stockControlType === 'TRACKED')
                                                    }
                                                />
                                            </Grid>

                                            {currentProduct && exchangeAgreementItemData.categoryCode === 1005
                                                ? (
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={classes.formControl}
                                                            name="packageSize"
                                                            id="packageSize"
                                                            value={currentProduct.packageSize}
                                                            label="Package Size"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.packageSize}
                                                            helperText={errors.packageSize}
                                                            disabled
                                                            InputProps={{
                                                                endAdornment:
                                                        <InputAdornment
                                                            disableTypography
                                                            className={classes.inputAdornment}
                                                            position="start"
                                                        >
                                                            sqm
                                                        </InputAdornment>,
                                                            }}

                                                        />
                                                    </Grid>
                                                ) : ''}
                                        </Grid>
                                        {(currentEditAgreementItemVisit || {}).code
                                            ? (
                                                <FormControlLabel
                                                    control={(
                                                        <Checkbox
                                                            name="exchangeVisit"
                                                            id="exchangeVisit"
                                                            onChange={this.updateExchangeVisitCheckbox}
                                                            checked={exchangeAgreementItemData.exchangeVisit}
                                                            value={exchangeAgreementItemData.exchangeVisit}
                                                        />
                                                    )}
                                                    classes={{ label: classes.checkBox }}
                                                    label="Add to exisiting visit"
                                                    className={classes.expiredCheckbox}
                                                />
                                            ) : ''}
                                        <Grid
                                            container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center"
                                        >
                                            <Grid item xs={12}>
                                                <Button
                                                    className={classes.dialogButton}
                                                    disabled={disableExchangeAgreementItemSaveButton}
                                                    onClick={this.handleExchangeAgreementItem}
                                                >
                                                    EXCHANGE
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </>
                                </div>
                            </DialogContent>
                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    onClick={handleCloseExchangeAgreementItemDialogClick}
                                    color="primary"
                                    autoFocus
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    ) : ''}

            </>

        );
    }
}

export default withSnackbar(withStyles(styles)(ExchangeAgreementItemDialog));
