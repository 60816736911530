import React from 'react';
import {
    Button,
    CssBaseline,
    Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Layout from './Layout';
import fetch, { fetchJson } from '../utilities/fetch';
import DeliveryNotesTable from '../components/deliveryNotesTable';
import DeliveryNotesDialog from '../components/deliveryNotesDialog';


const styles = theme => ({
    newDeliveryNoteButton: {
        marginBottom: theme.spacing.unit * 3,
    },
});

class DeliveryNotes extends React.Component {
    state = {
        branches: null,
        deliveryNotes: [],
        errors: [],
        newDeliveryNoteCode: null,
        showNewDeliveryNoteDialog: false,
        suppliers: null,
        users: [],
    }

    async componentDidMount() {
        fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/deliveryNotes')
            .then((deliveryNotes) => {
                this.setState({
                    deliveryNotes,
                });
            });

        fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/user')
            .then((user) => {
                this.setState({
                    user,
                });
            });

        fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/users')
            .then((users) => {
                this.setState({
                    users,
                });
            });

        await this.fetchMenus();
    }

    fetchMenus = () => {
        const fetchBranches = fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/branches')
            .then((branches) => {
                this.setState({
                    branches,
                });
            });
        const fetchSuppliers = fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/suppliers')
            .then((suppliers) => {
                this.setState({
                    suppliers,
                });
            });
        return Promise.all([fetchBranches, fetchSuppliers]);
    }

    handleNewDeliveryNoteClick = () => {
        this.setState({
            showNewDeliveryNoteDialog: true,
        });
    }

    resetShowDeliveryDialog = () => {
        this.setState({
            showNewDeliveryNoteDialog: false,
        });
    }

    createDeliveryNote = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch('https://api.portal2.payweeklycarpets.co.uk/core/deliveryNote/', {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState(state => ({
                deliveryNotes: [
                    body,
                    ...state.deliveryNotes,
                ],
                newDeliveryNoteCode: body.code,
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    }

    moveToDeliveryNote = (deliveryNoteCode) => {
        this.props.history.push(`/deliveryNote/${deliveryNoteCode}`);
    }

    render() {
        const {
            classes,
        } = this.props;
        const {
            branches,
            deliveryNotes,
            errors,
            newDeliveryNoteCode,
            showNewDeliveryNoteDialog,
            suppliers,
            user,
            users,
        } = this.state;

        return (
            <React.Fragment>
                { deliveryNotes !== []
                    ? (
                        <Layout pageTitle="Delivery Notes">
                            <CssBaseline />
                            <Button
                                className={classes.newDeliveryNoteButton}
                                variant="contained"
                                onClick={this.handleNewDeliveryNoteClick}
                            >
                                NEW DELIVERY NOTE
                            </Button>
                            <Paper className={classes.root}>
                                <DeliveryNotesTable
                                    deliveryNotes={deliveryNotes}
                                    moveToDeliveryNote={this.moveToDeliveryNote}
                                />
                            </Paper>
                            <DeliveryNotesDialog
                                branches={branches}
                                createDeliveryNote={this.createDeliveryNote}
                                errors={errors}
                                resetShowDeliveryDialog={this.resetShowDeliveryDialog}
                                showNewDeliveryNoteDialog={showNewDeliveryNoteDialog}
                                suppliers={suppliers}
                                user={user}
                                users={users}
                                moveToDeliveryNote={this.moveToDeliveryNote}
                                newDeliveryNoteCode={newDeliveryNoteCode}
                            />
                        </Layout>
                    )
                    : null}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(DeliveryNotes);
