function winsorized(rawArray, percent) {
    const orderedRawArray = rawArray.sort((a, b) => (a - b));
    console.log('>>> orderedRawArray', orderedRawArray);
    const rawArrayLength = rawArray.length;
    console.log('>>> rawArrayLength', rawArrayLength);
    const cutOffPosition = (rawArrayLength - (Math.floor(rawArrayLength / 100 * percent)) - 1);
    console.log('>>> cutOffPosition', cutOffPosition);
    const cutOffValue = orderedRawArray[cutOffPosition];
    console.log('>>> cutOffValue', cutOffValue);
    const winsorizedArray = orderedRawArray.map((value) => (value > cutOffValue ? cutOffValue : value));
    console.log('>>> winsorizedArray', winsorizedArray);
    return winsorizedArray;
}

export default winsorized;
