import React from 'react';
import {
    Chip,
    CssBaseline,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';

import InfoIcon from '@material-ui/icons/InfoOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LensIcon from '@material-ui/icons/Lens';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';

import Layout from './Layout';
import fetch from '../utilities/fetch';

const cookies = new Cookies();

const styles = (theme) => ({
    noWrap: {
        whiteSpace: 'nowrap',
    },
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        marginBottom: theme.spacing.unit * 3,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
        height: 48,
    },
    tableHeadRow: {
        height: 48,
    },
    hotIcon: {
        color: '#f1180b',
    },
    warmIcon: {
        color: '#E7C251',
    },
    coldIcon: {
        color: '#BBFFFF',
    },
    notSetIcon: {
        color: '#d7d7d7',
    },
    convertedIcon: {
        color: '#4caf50',
    },
    hybridTextIconLeft: {
        transform: 'translate(0%, 13%)',
        marginRight: theme.spacing.unit * 1,
        fontSize: 16,
    },
    hybridTextIconRight: {
        transform: 'translate(0%, 13%)',
        marginLeft: theme.spacing.unit * 1,
        fontSize: 16,
    },
    pageText: {
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeight,
        color: theme.palette.text.primary,
        marginLeft: theme.spacing.unit * 1,
        marginRight: theme.spacing.unit * 1,
    },
    pageControlsContaner: {
        display: 'flex',
    },
    pageControls: {
        marginLeft: 'auto',
    },
    chip: {
        height: 21,
        borderRadius: 5,
    },
    authorisedPaymentChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '30px',
    },
});

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 16,
        height: 48,
    },
    body: {
        fontSize: 14,
        paddingLeft: 16,
    },
}))(TableCell);

class AgreementsUndelivered extends React.Component {
    state = {
        expanded: null,
        agreements: {
        },
        branches: [],
        pageSize: 10,
    };

    async componentDidMount() {
        // let url;
        // let data;

        const url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/agreements/undelivered/branches');
        const data = await fetch(url).then((response) => response.json());
        this.setState({ branches: data });

        const agreementsUndeliveredBranchName = cookies.get('agreementsUndeliveredBranchName') || 'Bradford';
        const agreementsUndeliveredPage = parseInt(cookies.get('agreementsUndeliveredPage') || 1, 10);
        const agreementsUndeliveredPageSize = cookies.get('agreementsUndeliveredPageSize') || 10;

        this.setState({ pageSize: agreementsUndeliveredPageSize });

        await this.handleNewPageClick(agreementsUndeliveredBranchName, agreementsUndeliveredPage);

        this.setState({
            expanded: agreementsUndeliveredBranchName,
        });
    }

    handleAgreementClick = (contactCode, agreementCode) => {
        this.props.history.push(`/contact/${contactCode}?agreementCode=${agreementCode}`);
    };

    fetchAgreements = async (branchName, page, pageSize) => {
        const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/agreements/undelivered/${branchName}`);
        // const contactCode = this.state.contactCode;
        const params = {
            page,
            pageSize,
        };
        Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
        const data = await fetch(url).then((response) => response.json());

        cookies.set('agreementsUndeliveredBranchName', branchName, { path: '/' });
        cookies.set('agreementsUndeliveredPage', page, { path: '/' });
        cookies.set('agreementsUndeliveredPageSize', pageSize, { path: '/' });

        this.setState((state) => ({
            agreements: {
                ...state.agreements,
                [branchName]: data,
            },
        }));
    };

    handleChange = async (branchName) => {
        if ((this.state.agreements[branchName] || []).length === 0) {
            await this.fetchAgreements(branchName, 1, this.state.pageSize);
            const newAgreementBranch = this.state.branches.find((branch) => branch.name === branchName);
            newAgreementBranch.pages = Math.ceil(newAgreementBranch.qty / this.state.pageSize);
            newAgreementBranch.page = 1;
            const newbranches = [];
            this.state.branches.map((branch) => {
                if (this.state.branches.name !== branchName) {
                    newbranches.push(branch);
                } else {
                    newbranches.push(newAgreementBranch);
                }
            });
            this.setState({
                branchName: newbranches,
            });
        }
        this.setState({
            expanded: branchName,
        });
    };

    handleNewPageClick = async (branchName, page) => {
        await this.fetchAgreements(branchName, page, this.state.pageSize);
        const newAgreementBranch = this.state.branches.find((branch) => branch.name === branchName) || {};
        newAgreementBranch.pages = Math.ceil(newAgreementBranch.qty / this.state.pageSize);
        newAgreementBranch.page = page;
        const newbranches = [];
        this.state.branches.map((branch) => {
            if (this.state.branches.name !== branchName) {
                newbranches.push(branch);
            } else {
                newbranches.push(newAgreementBranch);
            }
        });
        this.setState({
            branches: newbranches,
        });
    };

    iconStatus = (statusString) => {
        switch (statusString) {
        case 'New': {
            return (this.props.classes.notSetIcon);
        }
        case 'Hot': {
            return (this.props.classes.hotIcon);
        }
        case 'Warm': {
            return (this.props.classes.warmIcon);
        }
        case 'Cold': {
            return (this.props.classes.coldIcon);
        }
        default: {
            return (this.props.classes.notSetIcon);
        }
        }
    };

    render() {
        const { classes } = this.props;
        const {
            expanded,
            agreements,
            branches,
        } = this.state;

        return (
            <Layout pageTitle="Agreements Undelivered">
                <CssBaseline />

                {branches.length > 0
                    ? (
                        <>
                            {branches.map((branch) => (
                                <ExpansionPanel
                                    key={branch.name}
                                    expanded={expanded === branch.name}
                                    onChange={() => this.handleChange(branch.name)}
                                >
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`${branch.name}-content`}
                                        id={`${branch.name}-header`}
                                    >
                                        <Grid container spacing={0}>
                                            <Grid item xs={6}>
                                                <Typography className={classes.heading}>
                                                    {`${branch.name} (${branch.qty})`}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {expanded === branch.name && (
                                                    <div className={classes.pageControlsContaner}>
                                                        <div className={classes.pageControls}>
                                                            <IconButton
                                                                onClick={() => this.handleNewPageClick(branch.name, 1)}
                                                                size="small"
                                                                disabled={branch.page === 1}
                                                            >
                                                                <FirstPageIcon fontSize="small" />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => this.handleNewPageClick(branch.name, branch.page - 1)}
                                                                size="small"
                                                                disabled={branch.page === 1}
                                                            >
                                                                <ChevronLeftIcon fontSize="small" />
                                                            </IconButton>
                                                            <span className={classes.pageText}>
                                                                Page
                                                                {' '}
                                                                {branch.page}
                                                                {' '}
                                                                of
                                                                {' '}
                                                                {branch.pages}
                                                            </span>
                                                            <IconButton
                                                                onClick={() => this.handleNewPageClick(branch.name, branch.page + 1)}
                                                                size="small"
                                                                disabled={branch.pages === 1 || (branch.page === branch.pages)}
                                                            >
                                                                <ChevronRightIcon fontSize="small" />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => this.handleNewPageClick(branch.name, branch.pages)}
                                                                size="small"
                                                                disabled={branch.pages === 1 || (branch.page === branch.pages)}
                                                            >
                                                                <LastPageIcon fontSize="small" />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Table className={classes.table} padding="none">
                                            <TableHead className={classes.tableHead}>
                                                <TableRow className={classes.tableHeadRow}>
                                                    <CustomTableCell className={classes.tableHead}>#</CustomTableCell>
                                                    <CustomTableCell className={classes.tableHead}>Agreement</CustomTableCell>
                                                    <CustomTableCell className={classes.tableHead}>Name</CustomTableCell>
                                                    <CustomTableCell className={classes.tableHead}>Address</CustomTableCell>
                                                    <CustomTableCell className={classes.tableHead}>Items</CustomTableCell>
                                                    <CustomTableCell align="center" className={classes.tableHead}>Start Date</CustomTableCell>
                                                    <CustomTableCell align="center" className={classes.tableHead}>Recurring Payments</CustomTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(agreements[branch.name] || []).map((agreement) => (
                                                    <TableRow onClick={() => this.handleAgreementClick(agreement.contactCode, agreement.code)} className={classes.row} key={agreement.code}>
                                                        <CustomTableCell component="th" scope="row">{agreement.rowNumber}</CustomTableCell>
                                                        <CustomTableCell align="left">{`RA${agreement.code}`}</CustomTableCell>
                                                        <CustomTableCell align="left" className={classes.noWrap}>{agreement.fullName}</CustomTableCell>
                                                        <CustomTableCell align="left" className={classes.noWrap}>{agreement.shortAddress}</CustomTableCell>
                                                        <CustomTableCell align="left" className={classes.noWrap}>{agreement.items}</CustomTableCell>
                                                        <CustomTableCell align="center" className={classes.noWrap}>{agreement.startDate}</CustomTableCell>
                                                        <CustomTableCell align="center" className={classes.noWrap}>
                                                            {agreement.recurringPaymentsQty >= 1
                                                                ? (<Chip label={agreement.recurringPaymentsQty} className={`${classes.chip} ${classes.authorisedPaymentChip}`} />)
                                                                : agreement.recurringPaymentsQty}
                                                        </CustomTableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            ))}
                        </>
                    )
                    : (<div>No agreements found</div>)}
            </Layout>
        );
    }
}

export default withStyles(styles)(AgreementsUndelivered);
