import React from 'react';
import {
    withStyles,
} from '@material-ui/core/styles';
import {
    AppBar,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    Paper,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@material-ui/core';
import AuthorisedIcon from '@material-ui/icons/CheckCircleOutline';
import DeclinedIcon from '@material-ui/icons/HighlightOff';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import {
    Link,
    DirectLink,
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller,
} from 'react-scroll';
import { withSnackbar } from 'notistack';
import LensIcon from '@material-ui/icons/Lens';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/Error';
import CrossCircleIcon from '@material-ui/icons/Cancel';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import get from 'lodash/get';
import moment from 'moment-timezone';
import HybridTextField from '../hybridTextField';
import CustomerSalesItems from './customerSalesItems';
import CustomerSalesPayments from './customerSalesPayments';
import titalise from '../../utilities/titalise';
import Tile from '../../containers/tile';

const styles = theme => ({
    label: {
        fontSize: 11,
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: 7,
        width: '100%',
        textAlign: 'left',
    },
    tileBottomDivider: {
        marginTop: theme.spacing.unit * 3,
        width: '100%',
    },
    bottomBarContainer: {
        width: '100%',
        textAlign: 'right',
    },
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    hybridTextIconLeft: {
        transform: 'translate(0%, 13%)',
        marginRight: theme.spacing.unit * 1,
        fontSize: 16,
    },
    hybridTextIconRight: {
        transform: 'translate(0%, 13%)',
        marginLeft: theme.spacing.unit * 1,
        fontSize: 16,
    },
    cancelledIcon: {
        color: '#d7d7d7',
        // color: '#f1180b',
    },
    warmIcon: {
        color: '#E7C251',
    },
    coldIcon: {
        color: '#BBFFFF',
    },
    notSetIcon: {
        color: '#d7d7d7',
    },
    activeIcon: {
        color: '#4caf50',
    },
    warningIcon: {
        color: '#fac518',
    },
    alert: {
        color: '#f1180b',
    },
    alertContainer: {
        /* text-align: left; */
        width: '100%',
        border: '1px solid',
        borderRadius: 4,
        marginBottom: theme.spacing.unit * 4,
        padding: theme.spacing.unit * 2,
        fontWeight: 400,
    },
    warning: {
        color: '#ff9800',
    },

    tabsPaper: {
        paddingTop: 32,
        paddingLeft: 32,
        paddingRight: 32,
        paddingBottom: 24,

        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },
    tabsAppBar: {
        backgroundColor: theme.palette.primary.lighter,
        // borderTopLeftRadius: 4,
        // borderTopRightRadius: 4,
    },
    tab: {
        color: 'white',
        fontSize: 15,
        fontWeight: 500,
        height: 38,
        minHeight: 38,
    },
    tabs: {
        padding: 0,
        minHeight: 38,
        height: 38,
        // backgroundColor: 'red',
    },
    tabButton: {
        padding: 0,
        minHeight: 38,
        height: 38,
        backgroundColor: 'red',
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    formInstructions: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    },
    statusSelect: {
        width: 200,
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        width: '100%',
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
        width: 390,
    },
    dialogActionsDivider: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    radioDescription: {
        marginLeft: 34,
        marginTop: -8,
        marginRight: theme.spacing.unit * 3,
    },
    issuerRadioDescription: {
        margin: 0,
        color: theme.palette.text.secondary,
    },
    disabledText: {
        color: 'rgba(0, 0, 0, 0.38)',
    },
    radioButtonsContainer: {
        marginLeft: theme.spacing.unit * 3,
    },
    menuItemText: {
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeight,
        color: theme.palette.text.primary,
    },
    menuItemSubtext: {
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeight,
        color: theme.palette.text.secondary,
    },
    selectMultiline: {
        height: 75,
    },
    convertDialogContent: {
        paddingBottom: 0,
        width: 540,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
        height: 48,
    },
    tableHeadRow: {
        height: 48,
    },
    tableIndex: {
        verticalAlign: 'top',
        paddingTop: theme.spacing.unit,
    },
    marginTop3: {
        marginTop: theme.spacing.unit * 3,
    },
    inputAdornment: {
        fontSize: 16,
        marginTop: 1,
        color: theme.palette.text.secondary,
    },
    sectionContainer: {
        borderBottom: '1px solid #eaeaea',
        marginBottom: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 1,
    },
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    declinedStatusIcon: {
        fontSize: 100,
        color: red[400],
        margin: theme.spacing.unit * 2,
    },
    authorisedStatusIcon: {
        fontSize: 100,
        color: green[400],
        margin: theme.spacing.unit * 2,
    },
    ErrorStatusIcon: {
        fontSize: 100,
        color: amber[400],
        margin: theme.spacing.unit * 2,
    },
    cardRadio: {
        paddingTop: '0px',
    },
    issuerRadioLabel: {
        marginTop: theme.spacing.unit * 1,
    },
    emptyContent: {
        padding: 32,
    },
    textField: {
        width: 260,
    },
});

class CustomerSales extends React.Component {
    state = {
        value: 0,
        showManualPaymentDialog: false,
        showSendTokenisationLinkDialog: false,
        disableSubmitManualPaymentButton: false,
        disableManualPaymentNextButton: true,
        manualPaymentForm: {
            amount: '',
            paymentCard: '',
        },
        manualPaymentStage: 'amount',
        manualPaymentStages: [
            'amount',
            'cards',
            'payment',
            'result',
        ],
        manualPaymentTransctionStatus: '',
        sendTokenisationLinkForm: {
            messageType: 'sms',
            to: '',
            destination: 'current',
        },
        sendTokenisationLinkStage: 'method',
        sendTokenisationLinkStages: [
            'method',
            'detail',
        ],
    }

    async componentDidMount() {
        await this.changeSaleTab();
    }

    componentDidUpdate(prevProps) {
        if (this.props.requestedSaleTab !== prevProps.requestedSaleTab) {
            this.changeSaleTab();
        }
    }

    changeSaleTab = async () => {
        if (this.props.requestedSaleTab >= 0) {
            await this.scrollToSalesTop();
            this.setState({
                value: this.props.requestedSaleTab,
            });
        }
    }

    scrollToSalesTop = () => {
        scroller.scrollTo('salesTop', {
            duration: 800,
            delay: 100,
            smooth: 'easeInOutQuart',
            offset: -90,
        });
    }

    capitaliseFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

    saleStatusIcon = (alertStatus, warningStatus, cancelledStatus) => {
        console.log(alertStatus, warningStatus, cancelledStatus);
        switch (true) {
        case (alertStatus): {
            return (<WarningIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.alert}`} />);
        }
        case (warningStatus): {
            return (<WarningIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.warningIcon}`} />);
        }
        case (cancelledStatus): {
            return (<CrossCircleIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.cancelledIcon}`} />);
        }
        default: {
            return (<CheckCircleIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.activeIcon}`} />);
        }
        // case 'Warm': {
        //     return (this.props.classes.warmIcon);
        // }
        // case 'Cold': {
        //     return (this.props.classes.coldIcon);
        // }
        // case 'Finance': {
        //     return (this.props.classes.financeIcon);
        // }
        // default: {
        //     return (this.props.classes.notSetIcon);
        // }
        }
    }

    handleManualPaymentClick = () => {
        this.setState(state => ({
            showManualPaymentDialog: true,
        }));
    }

    handleSendTokenisationLinkClick = () => {
        this.setState(state => ({
            showSendTokenisationLinkDialog: true,
        }));
    }

    handleCloseManualPaymentDialogClick = () => {
        this.setState(state => ({
            showManualPaymentDialog: false,
            manualPaymentStage: 'amount',
        }));
    }

    handleCloseSendTokenisationLinkDialogClick = () => {
        this.setState(state => ({
            showSendTokenisationLinkDialog: false,
            sendTokenisationLinkStage: 'method',
            sendTokenisationLinkForm: {
                messageType: 'sms',
                destination: 'current',
            },
        }));
    }

    handleManualPaymentNextClick = async () => {
        let manualPaymentStageEnum;
        manualPaymentStageEnum = this.state.manualPaymentStages.indexOf(this.state.manualPaymentStage);
        manualPaymentStageEnum += 1;
        switch (this.state.manualPaymentStages[manualPaymentStageEnum]) {
        case 'cards':
            this.setState({ disableManualPaymentNextButton: true });
            this.setState({
                disableManualPaymentNextButton: false,
            });
            break;
        case 'payment':
            this.setState({
                transactionStatusWaiting: true,
                disableManualPaymentNextButton: true,
            });
            const { amount } = this.state.manualPaymentForm;
            const agreementCode = this.props.customer.sales[this.state.value].PlanReference;
            const { paymentCard } = this.state.manualPaymentForm;
            if (paymentCard === 'new') {
                manualPaymentStageEnum += 1; // Change manualPaymentStage to 'result'
                this.newCardManualPayment(agreementCode, amount);
            } else {
                const { cardNo } = this.props.paymentCards.find(card => card.token === paymentCard);
                manualPaymentStageEnum += 1; // Change manualPaymentStage to 'result'
                await this.tokenManualPayment(agreementCode, amount, paymentCard, cardNo).then((paymentTransactionStatus) => {
                    this.setState({
                        transactionStatusWaiting: false,
                        manualPaymentTransctionStatus: paymentTransactionStatus,
                    });
                });
            }
            break;
        }
        this.setState({
            disableManualPaymentNextButton: false,
            manualPaymentStage: this.state.manualPaymentStages[manualPaymentStageEnum],
        });
    }

    handleSendTokenisationLinkNextClick = async () => {
        let sendTokenisationLinkStageEnum;
        sendTokenisationLinkStageEnum = this.state.sendTokenisationLinkStages.indexOf(this.state.sendTokenisationLinkStage);
        sendTokenisationLinkStageEnum += 1;
        this.setState(state => ({
            sendTokenisationLinkStage: state.sendTokenisationLinkStages[sendTokenisationLinkStageEnum],
        }));
    }

    updateManualPaymentData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'amount':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            this.setState({
                disableManualPaymentNextButton: !parseInt(formattedValue) > 0,
            });
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState({
            manualPaymentForm: {
                ...this.state.manualPaymentForm,
                [name]: formattedValue,
            },
        });
    }

    updateSendTokenisationLinkData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (true) {
        case (this.state.sendTokenisationLinkForm.messageType === 'sms' && name === 'to'):
            console.log('sms & to');
            formattedValue = value.replace(/[^0-9$.]/g, '');
            this.setState({
                disableSendTokenisationLinkSendButton: formattedValue.length === 0,
            });
            break;
        case (this.state.sendTokenisationLinkForm.messageType === 'email' && name === 'to'):
            console.log('email & to');
            formattedValue = value;
            this.setState({
                disableSendTokenisationLinkSendButton: formattedValue.length === 0,
            });
            break;
        case (value === 'current'):
            formattedValue = value;
            this.setState({
                disableSendTokenisationLinkSendButton: false,
            });
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState({
            sendTokenisationLinkForm: {
                ...this.state.sendTokenisationLinkForm,
                [name]: formattedValue,
            },
        });
    }

    handleSendTokenisationLink = (event) => {
        event.preventDefault();
        this.setState({
            disableSendTokenisationLinkSendButton: true,
        });
        const agreementCode = this.props.customer.sales[this.state.value].PlanReference;
        this.props.sendTokenisationLink(agreementCode, this.state.sendTokenisationLinkForm).then(() => {
            this.setState(state => ({
                showSendTokenisationLinkDialog: false,
                disableSendTokenisationLinkSendButton: false,
                sendTokenisationLinkStage: 'method',
                sendTokenisationLinkForm: {
                    messageType: 'sms',
                    destination: 'current',
                },
            }));
            this.props.enqueueSnackbar('Tokenisation Link Sent', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Send Tokenisation Link failed', { variant: 'error' });
        });
    }

    handlePrintDeliveryLabelsClick = () => {
        const agreementCode = this.props.customer.sales[this.state.value].PlanReference;
        this.props.printDeliveryLabels(agreementCode).then(() => {
            this.props.enqueueSnackbar('Delivery Labels Printed', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Delivery Labels Print failed', { variant: 'error' });
        });
    }

    handleCancelAgreementClick = () => {
        const agreementCode = this.props.customer.sales[this.state.value].PlanReference;
        const params = {
            status: 'CANCELLED',
        };
        this.props.cancelAgreement(agreementCode, params).then(() => {
            this.props.enqueueSnackbar('Agreement Cancelled', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Agreement Cancel failed', { variant: 'error' });
        });
    }

    newCardManualPayment = (agreementCode, amount) => {
        this.props.manualPayment({
            agreementCode,
            amount,
            paymentExpiry: 90,
            paymentReturnURL: `https://${window.location.hostname}/customer/${this.props.customer.code}?paymentType=manual&agreementCode=${agreementCode}`,
        });
    }

    tokenManualPayment = (agreementCode, amount, token, cardNo) => this.props.tokenManualPayment({
        agreementCode,
        paymentAmount: amount,
        paymentCardToken: token,
        paymentCardNo: cardNo,
    })

    handleTryAgainClick = () => {
        this.setState({
            manualPaymentStage: 'amount',
        });
    }

    handleAgreementChange = (event, newValue) => {
        this.setState({ value: newValue });
    }

    render() {
        const {
            classes,
            customer,
            errors,
            paymentCards,
            requestedSaleTab,
            salesAlerts,
            transactionStatusWaiting,
        } = this.props;
        const {
            disableManualPaymentNextButton,
            disableSendTokenisationLinkSendButton,
            manualPaymentForm,
            manualPaymentStage,
            manualPaymentTransctionStatus,
            sendTokenisationLinkForm,
            sendTokenisationLinkStage,
            showManualPaymentDialog,
            showSendTokenisationLinkDialog,
            value,
        } = this.state;

        return (
            <Tile
                allowCollapse
                initialCollapsedTile={(requestedSaleTab < 0)}
                tileTitle="Sales"
                tileMenuItems={[
                    ...(customer != null
                        && customer.sales != null
                        && customer.sales[value] != null
                        && ((customer.sales[value] || {}).saleStatus !== 'CANCELLED')
                        && ((customer.sales[value] || {}).Status === 'CANCELLED')
                        ? [
                            {
                                label: 'Cancel Agreement',
                                action: this.handleCancelAgreementClick,
                                section: 1,
                                disabled: true,
                            },
                        ]
                        : []
                    ),
                    ...(customer != null
                        && customer.sales != null
                        && customer.sales[value] != null
                        && ((customer.sales[value] || {}).Status !== 'CANCELLED')
                        ? [
                            {
                                label: 'Manual Payment',
                                action: this.handleManualPaymentClick,
                                section: 2,
                                disabled: true,
                            },
                        ]
                        : []
                    ),
                    ...(customer != null
                        && customer.sales != null
                        && customer.sales[value] != null
                        && ((customer.sales[value] || {}).Status !== 'CANCELLED')
                        ? [{
                            label: 'Request New Tokenisation',
                            action: this.handleSendTokenisationLinkClick,
                            section: 2,
                            disabled: true,
                        }]
                        : []
                    ),
                    // ...(get(customer, `agreements[${value}].items`, []).filter(item => item.deliveryStatus === 0).length > 0
                    //     ? [
                    //         {
                    //             label: 'Record Delivery',
                    //             action: this.handleRecordDeliveryClick,
                    //             section: 3,
                    //             disabled: true,
                    //         },
                    //     ]
                    //     : []
                    // ),
                    ...(customer != null
                        && customer.sales != null
                        && customer.sales[value] != null
                        && ((customer.sales[value] || {}).items != null)
                        && ((customer.sales[value] || {}).saleStatus !== 'CANCELLED')
                        && ((customer.sales[value] || {}).items || []).filter(item => item.deliveryStatus === 0).length > 0
                        ? [
                            {
                                label: 'Record Delivery',
                                action: this.handleRecordDeliveryClick,
                                section: 3,
                                disabled: true,
                            },
                        ]
                        : []
                    ),
                    ...(customer != null
                        && customer.sales != null
                        && ((customer.sales[value] || {}).items != null)
                        && ((customer.sales[value] || {}).saleStatus !== 'CANCELLED')
                        && ((customer.sales[value] || {}).items || []).filter(item => item.controlDevice !== null).length > 0
                        ? [
                            {
                                label: 'Control Device',
                                action: this.handleRecordDeliveryClick,
                                section: 3,
                                disabled: true,
                            },
                        ]
                        : []
                    ),
                    ...(customer != null
                        && customer.sales != null
                        && customer.sales[value] != null
                        && ((customer.sales[value] || {}).saleStatus !== 'CANCELLED')
                        && ((customer.sales[value] || {}).items || []).filter(item => item.deliveryStatus === 0).length > 0
                        ? [
                            {
                                label: 'Print delivery labels',
                                action: this.handlePrintDeliveryLabelsClick,
                                section: 3,
                                disabled: true,
                            },
                        ]
                        : []
                    ),
                ]}
                scrollToMarker="salesTop"
            >
                { customer !== null && requestedSaleTab != null && paymentCards !== null && salesAlerts !== null
                    ? (
                        <React.Fragment>
                            {customer.sales.length > 0
                                ? (
                                    <React.Fragment>
                                        <AppBar
                                            position="static"
                                            className={classes.tabsAppBar}
                                        >
                                            <Tabs
                                                className={classes.tabs}
                                                value={value}
                                                onChange={this.handleAgreementChange}
                                            >
                                                {customer.sales.map(sale => (
                                                    <Tab
                                                        className={classes.tab}
                                                        key={sale.PlanReference}
                                                        label={
                                                            (
                                                                <React.Fragment>
                                                                    {`S${sale.saleCode}`}
                                                                    {/* this.saleStatusIcon(
                                                                        get(salesAlerts.find(saleAlerts => saleAlerts.agreementCode === sale.code), 'alerts', []).length > 0,
                                                                        get(salesAlerts.find(saleAlerts => saleAlerts.agreementCode === sale.code), 'warnings', []).length > 0,
                                                                        sale.saleStatus === 'CANCELLED',
                                                                    ) */}

                                                                </React.Fragment>
                                                            )
                                                        }
                                                    />
                                                ))}
                                            </Tabs>
                                        </AppBar>
                                        <Paper className={classes.tabsPaper}>
                                            {/* salesAlerts.find(saleAlerts => saleAlerts.agreementCode === customer.sales[value].code).alerts.length > 0
                                                ? (
                                                    <React.Fragment>
                                                        <div
                                                            className={`${classes.label} ${classes.alert}`}
                                                        >
                                                    Alerts
                                                        </div>
                                                        <div className={`${classes.alertContainer} ${classes.alert}`}>
                                                            {salesAlerts.find(saleAlerts => saleAlerts.agreementCode === customer.sales[value].code).alerts.map(alert => (
                                                                <div key={alert}>
                                                                    {alert}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </React.Fragment>
                                                )
                                                : null
                                            }
                                            {salesAlerts.find(saleAlerts => saleAlerts.agreementCode === customer.sales[value].code).warnings.length > 0
                                                ? (
                                                    <React.Fragment>
                                                        <div
                                                            className={`${classes.label} ${classes.warning}`}
                                                        >
                                                    Warnings
                                                        </div>
                                                        <div className={`${classes.alertContainer} ${classes.warning}`}>
                                                            {salesAlerts.find(saleAlerts => saleAlerts.agreementCode === customer.sales[value].code).warnings.map(warning => (
                                                                <div
                                                                    key={warning}
                                                                >
                                                                    {warning}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </React.Fragment>
                                                )
                                                : null
                                            */}

                                            <Grid
                                                container
                                                spacing={0}
                                                className={classes.sectionContainer}
                                            >
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        alert={customer.sales[value].saleStatus === 'CANCELLED'}
                                                        green={customer.sales[value].saleStatus === 'ACTIVE'}
                                                        label="Sale Status"
                                                        text={customer.sales[value].saleStatus || ' '}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        cancelled={customer.sales[value].saleStatus === 'CANCELLED'}
                                                        label="Sale Time/Date"
                                                        text={customer.sales[value].saleDateTime || ' '}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        cancelled={customer.sales[value].saleStatus === 'CANCELLED'}
                                                        label="Sale Created By"
                                                        text={customer.sales[value].saleCreatedByFullname}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        cancelled={customer.sales[value].saleStatus === 'CANCELLED'}
                                                        label="Delivery Charge"
                                                        text={'£'.concat(customer.sales[value].deliveryAmount.toFixed(2))}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        cancelled={customer.sales[value].saleStatus === 'CANCELLED'}
                                                        label="Agent Fee"
                                                        text={'£'.concat(customer.sales[value].agentFee.toFixed(2))}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        cancelled={customer.sales[value].saleStatus === 'CANCELLED'}
                                                        label="Agent Collected Fee"
                                                        text={customer.sales[value].agentCollectedFee === 1 ? 'Yes' : 'No'}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        cancelled={customer.sales[value].saleStatus === 'CANCELLED'}
                                                        label="Total Sale Amount"
                                                        text={'£'.concat((customer.sales[value].totalSaleAmount || 0).toFixed(2))}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        cancelled={customer.sales[value].saleStatus === 'CANCELLED'}
                                                        label="Total Paid Amount"
                                                        text={'£'.concat((customer.sales[value].portalPaidAmount || 0).toFixed(2))}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        cancelled={customer.sales[value].saleStatus === 'CANCELLED'}
                                                        label="Outstanding Amount"
                                                        text={'£'.concat((customer.sales[value].portalPlanAmount - customer.sales[value].portalPaidAmount).toFixed(2))}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                spacing={0}
                                                className={classes.sectionContainer}
                                            >
                                                <Grid item xs={12}>
                                                    <Grid item xs={12}>
                                                        <HybridTextField
                                                            alert={
                                                                customer.sales[value].Status === 'CANCELLED'
                                                                || customer.sales[value].Status === 'None'}
                                                            green={customer.sales[value].Status === 'ACTIVE'}
                                                            label="Sale Account Status"
                                                            text={customer.sales[value].Status || ' '}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        cancelled={customer.sales[value].Status === 'CANCELLED' || customer.sales[value].Status === 'None'}
                                                        label="Tokenised Card"
                                                        text={customer.sales[value].Status !== 'None' ? titalise(customer.sales[value].Card) : ''}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {customer.sales[value].Card === 'YES'
                                                        ? (

                                                            <HybridTextField
                                                                cancelled={customer.sales[value].Status === 'CANCELLED' || customer.sales[value].Status === 'None'}
                                                                label="Card issuer"
                                                                text={customer.sales[value].Status !== 'None'
                                                                    ? `${(paymentCards.find(paymentCard => paymentCard.token === customer.sales[value].CardToken) || {}).issuer
                                                                    } ${(paymentCards.find(paymentCard => paymentCard.token === customer.sales[value].CardToken) || {}).brand
                                                                    } ${(paymentCards.find(paymentCard => paymentCard.token === customer.sales[value].CardToken) || {}).type}`
                                                                    : ''}
                                                            />
                                                        )
                                                        : (<React.Fragment />)
                                                    }
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {customer.sales[value].Card === 'YES'
                                                        ? (
                                                            <HybridTextField
                                                                cancelled={customer.sales[value].Status === 'CANCELLED' || customer.sales[value].Status === 'None'}
                                                                label="Card"
                                                                text={`${customer.sales[value].CardNo} exp ${customer.sales[value].CardExpiry}`}
                                                            />
                                                        )
                                                        : (<React.Fragment />)
                                                    }
                                                </Grid>
                                            </Grid>

                                            {customer.sales[value].items.length > 0
                                                ? (
                                                    <CustomerSalesItems
                                                        items={customer.sales[value].items}
                                                        error={errors}
                                                    />
                                                )
                                                : (
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        className={classes.sectionContainer}
                                                    >
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                cancelled={customer.sales[value].Status === 'CANCELLED'}
                                                                alert
                                                                label="Items"
                                                                text="None"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }

                                            <CustomerSalesPayments
                                                payments={customer.sales[value].payments}
                                                error={errors}
                                            />

                                            {/* customer.sales[value].Status !== 'CANCELLED' && customer.sales[value].Status !== 'None'
                                                ? (
                                                    <Button
                                                        onClick={this.handleManualPaymentClick}
                                                        variant="contained"
                                                    >
                                            MANUAL PAYMENT
                                                    </Button>
                                                )
                                                : null
                                            */}
                                        </Paper>

                                        <Dialog
                                            open={showManualPaymentDialog}
                                            onClose={this.handleCloseManualPaymentDialogClick}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogContent className={classes.dialogContent}>
                                                <div className={classes.dialogContentContainer}>
                                                    <React.Fragment>
                                                        <Typography variant="h6" gutterBottom>
                                                            Manual Payment
                                                        </Typography>
                                                        <form onSubmit={this.handleSubmitManualPayment}>
                                                            {manualPaymentStage === 'amount'
                                                                ? (
                                                                    <React.Fragment>
                                                                        <DialogContentText>
                                                                            Enter the payment amount
                                                                        </DialogContentText>
                                                                        <TextField
                                                                            className={`${classes.formControl} ${classes.marginTop3}`}
                                                                            name="amount"
                                                                            id="amount"
                                                                            value={manualPaymentForm.amount}
                                                                            onChange={this.updateManualPaymentData}
                                                                            label="Amount"
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                            error={!!errors.weeklyRentalAmount}
                                                                            helperText={errors.weeklyRentalAmount}
                                                                            InputProps={{
                                                                                startAdornment:
                                                                        <InputAdornment
                                                                            disableTypography
                                                                            className={classes.inputAdornment}
                                                                            position="start"
                                                                        >
                                                                            £
                                                                        </InputAdornment>,
                                                                            }}
                                                                        />
                                                                        <Button
                                                                            variant="contained"
                                                                            className={classes.dialogButton}
                                                                            onClick={this.handleManualPaymentNextClick}
                                                                            disabled={disableManualPaymentNextButton}
                                                                        >
                                                                            NEXT
                                                                        </Button>
                                                                    </React.Fragment>
                                                                )
                                                                : null
                                                            }
                                                            {manualPaymentStage === 'cards'
                                                                ? (
                                                                    <React.Fragment>
                                                                        <FormControl
                                                                            component="fieldset"
                                                                            className={`${classes.formControl} ${classes.radioButtonsContainer}`}
                                                                        >
                                                                            <RadioGroup
                                                                                aria-label="paymentCard"
                                                                                name="paymentCard"
                                                                                className={classes.group}
                                                                                value={manualPaymentForm.paymentCard}
                                                                                onChange={this.updateManualPaymentData}
                                                                            >
                                                                                {paymentCards.map(paymentCard => (
                                                                                    <FormControlLabel
                                                                                        key={paymentCard.token}
                                                                                        value={paymentCard.token}
                                                                                        control={<Radio className={classes.cardRadio} />}
                                                                                        label={(
                                                                                            <div className={classes.issuerRadioLabel}>
                                                                                                Card
                                                                                                {' '}
                                                                                                {paymentCard.cardNo}
                                                                                                {' '}
                                                                                                Exp
                                                                                                {' '}
                                                                                                {paymentCard.expiry}
                                                                                                <div
                                                                                                    className={classes.issuerRadioDescription}
                                                                                                >
                                                                                                    {paymentCard.brand}
                                                                                                    {' '}
                                                                                                    {paymentCard.type}
                                                                                                    {' '}
                                                                                                    -
                                                                                                    {' '}
                                                                                                    {paymentCard.issuer}
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    />
                                                                                ))}
                                                                                <FormControlLabel
                                                                                    key="new"
                                                                                    value="new"
                                                                                    control={<Radio />}
                                                                                    label="New payment card"
                                                                                />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                        <Button
                                                                            variant="contained"
                                                                            className={classes.dialogButton}
                                                                            onClick={this.handleManualPaymentNextClick}
                                                                            disabled={disableManualPaymentNextButton}
                                                                        >
                                                                            NEXT
                                                                        </Button>
                                                                    </React.Fragment>
                                                                )
                                                                : null
                                                            }
                                                            {manualPaymentStage === 'result'
                                                                ? (
                                                                    <React.Fragment>
                                                                        <Grid
                                                                            container
                                                                            spacing={0}
                                                                            direction="column"
                                                                            alignItems="center"
                                                                            justify="center"
                                                                            style={{ minHeight: '22vh' }}
                                                                        >
                                                                            <Grid
                                                                                items
                                                                                spacing={0}
                                                                                direction="column"
                                                                                alignItems="center"
                                                                                justify="center"
                                                                                style={{ width: 240, display: 'inherit' }}
                                                                            >

                                                                                {
                                                                                    (() => {
                                                                                        switch (true) {
                                                                                        case transactionStatusWaiting: {
                                                                                            return (
                                                                                                <React.Fragment>
                                                                                                    <CircularProgress
                                                                                                        className={classes.progress}
                                                                                                        color="secondary"
                                                                                                    />
                                                                                                    <Typography variant="h6" className={classes.title}>
                                                                                                        Please Wait
                                                                                                    </Typography>
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        }
                                                                                        case manualPaymentTransctionStatus === 'Declined': {
                                                                                            return (
                                                                                                <React.Fragment>
                                                                                                    <DeclinedIcon className={classes.declinedStatusIcon} />
                                                                                                    <Typography paragraph>
                                                                                                        Payment Declined
                                                                                                    </Typography>
                                                                                                    <div>
                                                                                                        <Button
                                                                                                            onClick={this.handleTryAgainClick}
                                                                                                            type="button"
                                                                                                            variant="contained"
                                                                                                            color="primary"
                                                                                                            className={classes.actionButton}
                                                                                                        >
                                                                                                            Try Again
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        }
                                                                                        case manualPaymentTransctionStatus === 'Authorised': {
                                                                                            return (
                                                                                                <React.Fragment>
                                                                                                    <AuthorisedIcon className={classes.authorisedStatusIcon} />
                                                                                                    <Typography paragraph>
                                                                                                        Payment Authorised
                                                                                                    </Typography>
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        }
                                                                                        default: {
                                                                                            return (
                                                                                                <React.Fragment>
                                                                                                    <ErrorIcon className={classes.ErrorStatusIcon} />
                                                                                                    <Typography paragraph>
                                                                                                        Payment Error
                                                                                                    </Typography>
                                                                                                    <Typography
                                                                                                        paragraph
                                                                                                        align="center"
                                                                                                    >
                                                                                                        The payment services provider is taking too long to respond. It is not possible to determine if the transaction was Authorised or Declined.
                                                                                                    </Typography>
                                                                                                    <Button
                                                                                                        onClick={() => this.checkTransactionStatus()}
                                                                                                        type="button"
                                                                                                        variant="contained"
                                                                                                        color="primary"
                                                                                                        className={classes.actionButton}
                                                                                                    >
                                                                                                        Check Again
                                                                                                    </Button>
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        }
                                                                                        }
                                                                                    })()
                                                                                }


                                                                            </Grid>
                                                                        </Grid>
                                                                    </React.Fragment>
                                                                )
                                                                : null
                                                            }
                                                        </form>
                                                    </React.Fragment>
                                                </div>
                                                <Divider />
                                            </DialogContent>
                                            <DialogActions className={classes.dialogActions}>
                                                <Button
                                                    onClick={this.handleCloseManualPaymentDialogClick}
                                                    color="primary"
                                                    autoFocus
                                                >
                                                    Close
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                        <Dialog
                                            open={showSendTokenisationLinkDialog}
                                            onClose={this.handleCloseSendTokenisationLinkDialogClick}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogContent className={classes.dialogContent}>
                                                <div className={classes.dialogContentContainer}>
                                                    <React.Fragment>
                                                        <Typography variant="h6" gutterBottom>
                                                            Send Tokenisation Request
                                                        </Typography>
                                                        <form onSubmit={this.handleSendTokenisationLink}>
                                                            {sendTokenisationLinkStage === 'method'
                                                                ? (
                                                                    <React.Fragment>
                                                                        <DialogContentText>
                                                                            Select delivery method
                                                                        </DialogContentText>
                                                                        <FormControl
                                                                            component="fieldset"
                                                                            className={`${classes.formControl} ${classes.radioButtonsContainer}`}
                                                                        >
                                                                            <RadioGroup
                                                                                aria-label="messageType"
                                                                                name="messageType"
                                                                                className={classes.group}
                                                                                value={sendTokenisationLinkForm.messageType}
                                                                                onChange={this.updateSendTokenisationLinkData}
                                                                            >
                                                                                <FormControlLabel
                                                                                    key="sms"
                                                                                    value="sms"
                                                                                    control={<Radio />}
                                                                                    label="SMS text"
                                                                                />
                                                                                <FormControlLabel
                                                                                    key="email"
                                                                                    value="email"
                                                                                    control={<Radio />}
                                                                                    label="Email"
                                                                                />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                        <Button
                                                                            variant="contained"
                                                                            className={classes.dialogButton}
                                                                            onClick={this.handleSendTokenisationLinkNextClick}
                                                                        >
                                                                            NEXT
                                                                        </Button>
                                                                    </React.Fragment>
                                                                )
                                                                : null
                                                            }
                                                            {sendTokenisationLinkStage === 'detail'
                                                                ? (
                                                                    <React.Fragment>
                                                                        <DialogContentText>
                                                                            Select destination
                                                                        </DialogContentText>
                                                                        <FormControl
                                                                            component="fieldset"
                                                                            className={`${classes.formControl} ${classes.radioButtonsContainer}`}
                                                                        >
                                                                            <RadioGroup
                                                                                aria-label="destination"
                                                                                name="destination"
                                                                                className={classes.group}
                                                                                value={sendTokenisationLinkForm.destination}
                                                                                onChange={this.updateSendTokenisationLinkData}
                                                                            >
                                                                                <FormControlLabel
                                                                                    key="current"
                                                                                    value="current"
                                                                                    control={<Radio />}
                                                                                    label={sendTokenisationLinkForm.messageType === 'sms'
                                                                                        ? customer.mobileTelephoneNumber
                                                                                        : customer.emailAddress
                                                                                    }
                                                                                />
                                                                                <FormControlLabel
                                                                                    key="new"
                                                                                    value="new"
                                                                                    control={<Radio />}
                                                                                    label={sendTokenisationLinkForm.messageType === 'sms'
                                                                                        ? 'New mobile number'
                                                                                        : 'New email address'
                                                                                    }
                                                                                />
                                                                            </RadioGroup>
                                                                            {sendTokenisationLinkForm.destination === 'new' && (
                                                                                <TextField
                                                                                    className={`${classes.formControl} ${classes.marginTop3} ${classes.textField}`}
                                                                                    name="to"
                                                                                    id="to"
                                                                                    value={sendTokenisationLinkForm.to}
                                                                                    onChange={this.updateSendTokenisationLinkData}
                                                                                    label={sendTokenisationLinkForm.messageType === 'sms' ? 'New mobile number' : 'New email address'}
                                                                                    margin="normal"
                                                                                    variant="outlined"
                                                                                    required
                                                                                    error={!!errors.to}
                                                                                    helperText={errors.to}
                                                                                />
                                                                            )}
                                                                        </FormControl>
                                                                        <Button
                                                                            variant="contained"
                                                                            className={classes.dialogButton}
                                                                            disabled={disableSendTokenisationLinkSendButton}
                                                                            type="submit"
                                                                        >
                                                                            SEND
                                                                        </Button>
                                                                    </React.Fragment>
                                                                )
                                                                : null
                                                            }
                                                        </form>
                                                    </React.Fragment>
                                                </div>
                                            </DialogContent>
                                            <Divider className={classes.dialogActionsDivider} />
                                            <DialogActions className={classes.dialogActions}>
                                                <Button
                                                    onClick={this.handleCloseSendTokenisationLinkDialogClick}
                                                    color="primary"
                                                    autoFocus
                                                >
                                                    Close
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                    </React.Fragment>
                                )
                                : <div className={classes.emptyContent}>No Sales found.</div>
                            }
                        </React.Fragment>
                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )
                }
            </Tile>
        );
    }
}

export default withSnackbar(withStyles(styles)(CustomerSales));
