import React from 'react';
import {
    Button,
    CssBaseline,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import { withSnackbar } from 'notistack';

import InfoIcon from '@material-ui/icons/InfoOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LensIcon from '@material-ui/icons/Lens';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import identity from 'lodash/identity';

import Layout from './Layout';
import fetch from '../utilities/fetch';
import titalise from '../utilities/titalise';
import {
    titles,
    counties,
    countries,
} from '../utilities/menuOptions';

const cookies = new Cookies();

const removeFalseyValuesFromObject = (obj) => pickBy(obj, identity);

const styles = (theme) => ({
    noWrap: {
        whiteSpace: 'nowrap',
    },
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        marginBottom: theme.spacing.unit * 3,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
        height: 48,
    },
    tableHeadRow: {
        height: 48,
    },
    hotIcon: {
        color: '#f1180b',
    },
    warmIcon: {
        color: '#E7C251',
    },
    coldIcon: {
        color: '#BBFFFF',
    },
    notSetIcon: {
        color: '#d7d7d7',
    },
    convertedIcon: {
        color: '#4caf50',
    },
    hybridTextIconLeft: {
        transform: 'translate(0%, 13%)',
        marginRight: theme.spacing.unit * 1,
        fontSize: 16,
    },
    hybridTextIconRight: {
        transform: 'translate(0%, 13%)',
        marginLeft: theme.spacing.unit * 1,
        fontSize: 16,
    },
    pageText: {
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeight,
        color: theme.palette.text.primary,
        marginLeft: theme.spacing.unit * 1,
        marginRight: theme.spacing.unit * 1,
    },
    pageControlsContaner: {
        display: 'flex',
    },
    pageControls: {
        marginLeft: 'auto',
    },

    newLeadButton: {
        marginBottom: theme.spacing.unit * 3,
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogContent: {
        paddingBottom: 0,
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 2.5,
        marginBottom: theme.spacing.unit * 2,
    },
    findAddressButton: {
        marginTop: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit * 3,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
});

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 16,
        height: 48,
    },
    body: {
        fontSize: 14,
        paddingLeft: 16,
    },
}))(TableCell);

class LeadsList extends React.Component {
    state = {
        anchorEl: null,
        expanded: null,
        campaigns: [],
        disableNewLeadSaveButton: false,
        errors: [],
        leads: {
            // Hot: [],
        },
        leadSources: [],
        leadsStatus: [],
        newLeadForm: {
            title: '',
            firstname: '',
            surname: '',
            homeTelephoneNumber: '',
            mobileTelephoneNumber: '',
            emailAddress: '',
            gdpr: '',
            address1: '',
            address2: '',
            townCity: '',
            county: '',
            postcode: '',
            country: '',
            campaignCode: '',
            leadSourceCode: '',
        },
        pageSize: 10,
        postcodeAddresses: [],
        showNewLeadDialog: false,
    };

    async componentDidMount() {
        let url;
        let data;

        url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/leads/status');
        data = await fetch(url).then((response) => response.json());
        this.setState({ leadsStatus: data });

        const leadsStatus = cookies.get('leadsStatus') || 'New';
        const leadsPage = parseInt(cookies.get('leadsPage') || 1);
        const leadsPageSize = cookies.get('leadsPageSize') || 10;

        this.setState({ pageSize: leadsPageSize });

        await this.handleNewPageClick(leadsStatus, leadsPage);

        this.setState({
            expanded: leadsStatus,
        });

        url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/campaigns');
        data = await fetch(url).then((response) => response.json());
        this.setState({ campaigns: data });

        url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/lead/sources');
        data = await fetch(url).then((response) => response.json());
        this.setState({ leadSources: data });
    }

    handleLeadClick = (contactCode, leadCode) => {
        this.props.history.push(`/contact/${contactCode}?leadCode=${leadCode}`);
    };

    fetchLeads = async (status, page, pageSize) => {
        const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/leads/status/${status}`);
        const { contactCode } = this.state;
        const params = {
            page,
            pageSize,
        };
        Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
        const data = await fetch(url).then((response) => response.json());

        cookies.set('leadsStatus', status, { path: '/' });
        cookies.set('leadsPage', page, { path: '/' });
        cookies.set('leadsPageSize', pageSize, { path: '/' });

        this.setState({
            leads: {
                ...this.state.leads,
                [status]: data,
            },
        });
    };

    handleNewLeadClick = () => {
        const newLeadFormValues = { ...this.state.newLeadForm };
        Object.keys(newLeadFormValues).forEach((k) => newLeadFormValues[k] = '');
        newLeadFormValues.gdpr = '0';
        this.setState({
            newLeadForm: newLeadFormValues,
            showNewLeadDialog: true,
        });
    };

    handleNewLeadDialogCloseClick = () => {
        this.setState({
            showNewLeadDialog: false,
        });
    };

    handleChange = async (status) => {
        if ((this.state.leads[status] || []).length === 0) {
            await this.fetchLeads(status, 1, this.state.pageSize);
            const newLeadStatus = this.state.leadsStatus.find((leadStatus) => leadStatus.status === status);
            newLeadStatus.pages = Math.ceil(newLeadStatus.qty / this.state.pageSize);
            newLeadStatus.page = 1;
            const newLeadsStatus = [];
            this.state.leadsStatus.map((leadStatus) => {
                if (this.state.leadsStatus.status !== status) {
                    newLeadsStatus.push(leadStatus);
                } else {
                    newLeadsStatus.push(newLeadStatus);
                }
            });
            this.setState({
                leadsStatus: newLeadsStatus,
            });
        }
        this.setState({
            expanded: status,
        });
    };

    handleNewPageClick = async (status, page) => {
        await this.fetchLeads(status, page, this.state.pageSize);
        const newLeadStatus = this.state.leadsStatus.find((leadStatus) => leadStatus.status === status) || {};
        newLeadStatus.pages = Math.ceil(newLeadStatus.qty / this.state.pageSize);
        newLeadStatus.page = page;
        const newLeadsStatus = [];
        this.state.leadsStatus.map((leadStatus) => {
            if (this.state.leadsStatus.status !== status) {
                newLeadsStatus.push(leadStatus);
            } else {
                newLeadsStatus.push(newLeadStatus);
            }
        });
        this.setState({
            leadsStatus: newLeadsStatus,
        });
    };

    iconStatus = (statusString) => {
        switch (statusString) {
        case 'New': {
            return (this.props.classes.notSetIcon);
        }
        case 'Hot': {
            return (this.props.classes.hotIcon);
        }
        case 'Warm': {
            return (this.props.classes.warmIcon);
        }
        case 'Cold': {
            return (this.props.classes.coldIcon);
        }
        default: {
            return (this.props.classes.notSetIcon);
        }
        }
    };

    handleNewLeadCheckBoxClick = (name) => (event) => {
        const newValue = this.state.newLeadForm[name] === '1' ? '0' : '1';
        console.log(this.state.newLeadForm[name], newValue);
        this.setState((state) => ({
            newLeadForm: {
                ...state.newLeadForm,
                [name]: newValue,
            },
        }));
    };

    handleFindAddressClick = async (event) => {
        const { currentTarget } = event;
        const { postcode } = this.state.newLeadForm;
        console.log(this.state.newLeadForm.postcode);
        console.log(postcode);
        const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/postcodeAddresses/${postcode}`);
        const data = await fetch(url).then((response) => response.json());
        if (!data.Message) {
            this.setState({
                postcodeAddresses: data.addresses,
                anchorEl: currentTarget,
            });
        }
    };

    handleAdressesMenuClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    updateNewLeadData = (event) => {
        const { value, name } = event.target;
        let formattedValue;
        switch (name) {
        case 'emailAddress':
            formattedValue = value.toLowerCase();
            break;
        case 'postcode':
            formattedValue = value.toUpperCase();
            break;
        case 'county':
        case 'country':
        case 'leadSourceCode':
        case 'campaignCode':
            formattedValue = value;
            break;
        default:
            formattedValue = titalise(value);
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            newLeadForm: {
                ...state.newLeadForm,
                [name]: formattedValue,
            },
        }));
    };

    handleAddressClick = (i) => {
        let selectedCountry = '';
        let selectedAddress2;
        const selectedAddress = this.state.postcodeAddresses[i];
        switch (selectedAddress.country) {
        case 'England':
        case 'Scotland':
        case 'Wales':
        case 'Northern Ireland':
            selectedCountry = 'United Kingdom';
            break;
        default:
            selectedCountry = '';
        }
        selectedAddress2 = [selectedAddress.line_2, selectedAddress.line_3, selectedAddress.line_4, selectedAddress.locality].filter((el) => (!!el)).join(', ');
        selectedAddress2 = Array.isArray(selectedAddress2) && selectedAddress2.length === 0 ? '' : selectedAddress2;
        this.setState((state) => ({
            newLeadForm: {
                ...state.newLeadForm,
                address1: selectedAddress.line_1,
                address2: selectedAddress2,
                townCity: selectedAddress.town_or_city,
                county: selectedAddress.county,
                country: selectedCountry,
            },
            anchorEl: null,
        }));
    };

    handleSaveNewLead = (event) => {
        event.preventDefault();
        this.setState({
            disableNewLeadSaveButton: true,
        });
        this.saveNewLead(this.state.newLeadForm).then(() => {
            this.setState({
                showNewLeadDialog: false,
                disableNewLeadSaveButton: false,
            });
            this.props.enqueueSnackbar('New lead saved', { variant: 'success' });
        }).catch(() => {
            console.log(this.state.errors);
            this.props.enqueueSnackbar('New lead save failed', { variant: 'error' });
            this.setState({
                disableNewLeadSaveButton: false,
            });
        });
    };

    saveNewLead = async (payload) => {
        if (this.validateNewLead(payload) === false) throw new Error({ error: 'Generic API error' });
        let response;
        let body;

        try {
            response = await fetch('https://api.portal2.payweeklycarpets.co.uk/core/lead?', {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.props.history.push(`/contact/${body.contactCode}?leadCode=${body.leadCode}`);
            // this.setState(state => ({
            //     customer: {
            //         ...state.customer,
            //         ...body.address,
            //     },
            //     history: [
            //         body.history,
            //         ...state.history,
            //     ],
            // }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    validateNewLead = (payload) => {
        console.log('validating...');
        const fieldOrder = [
            'title',
            'firstname',
            'surname',
            'mobileTelephoneNumber',
            'emailAddress',
            'address1',
            'townCity',
            'county',
            'postcode',
            'country',
            'campaignCode',
            'leadSourceCode',
        ];

        const title = (() => {
            switch (true) {
            case !payload.title: return 'Title required';
            default: return '';
            }
        })();
        const firstname = (() => {
            switch (true) {
            case !payload.firstname: return 'Firstname required';
            case payload.firstname.length < 2: return 'Firstname too short';
            case payload.firstname.length > 100: return 'Firstname too long';
            default: return '';
            }
        })();
        const surname = (() => {
            switch (true) {
            case !payload.surname: return 'Surname required';
            case payload.surname.length < 2: return 'Surname too short';
            case payload.surname.length > 100: return 'Surname too long';
            default: return '';
            }
        })();
        const mobileTelephoneNumber = (() => {
            switch (true) {
            case !payload.mobileTelephoneNumber: return 'Mobile phone required';
            case payload.mobileTelephoneNumber.length < 2: return 'Mobile phone too short';
            case payload.mobileTelephoneNumber.length > 100: return 'Mobile phone too long';
            default: return '';
            }
        })();
        const emailAddress = (() => {
            switch (true) {
            case !payload.emailAddress: return 'Email address required';
            case payload.emailAddress.length < 2: return 'Email address too short';
            case payload.emailAddress.length > 100: return 'Email address too long';
            default: return '';
            }
        })();
        const address1 = (() => {
            switch (true) {
            case !payload.address1: return 'Address required';
            case payload.address1.length < 2: return 'Address too short';
            case payload.address1.length > 100: return 'Address too long';
            default: return '';
            }
        })();
        const townCity = (() => {
            switch (true) {
            case !payload.townCity: return 'Town/City required';
            case payload.townCity.length < 2: return 'Town/City too short';
            case payload.townCity.length > 100: return 'Town/City too long';
            default: return '';
            }
        })();
        const postcode = (() => {
            switch (true) {
            case !payload.postcode: return 'Postcode required';
            case payload.postcode.length < 2: return 'Postcode too short';
            case payload.postcode.length > 100: return 'Postcode too long';
            default: return '';
            }
        })();
        const county = (() => {
            switch (true) {
            case !payload.county: return 'County required';
            default: return '';
            }
        })();
        const country = (() => {
            switch (true) {
            case !payload.country: return 'Country required';
            default: return '';
            }
        })();
        const campaignCode = (() => {
            switch (true) {
            case !payload.campaignCode: return 'Campaign required';
            default: return '';
            }
        })();
        const leadSourceCode = (() => {
            switch (true) {
            case !payload.leadSourceCode: return 'Lead source required';
            default: return '';
            }
        })();

        const errors = removeFalseyValuesFromObject({
            title,
            firstname,
            surname,
            mobileTelephoneNumber,
            emailAddress,
            address1,
            townCity,
            postcode,
            county,
            country,
            campaignCode,
            leadSourceCode,
        });

        const firstError = fieldOrder.find((field) => !!errors[field]);

        this.setState({
            errors,
            firstError,
        });

        if (isEmpty(errors)) return true;

        return false;
    };

    validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    render() {
        const { classes } = this.props;
        const {
            anchorEl,
            campaigns,
            disableNewLeadSaveButton,
            errors,
            expanded,
            leadSources,
            leads,
            leadsStatus,
            newLeadForm,
            postcodeAddresses,
            showNewLeadDialog,
        } = this.state;

        return (
            <Layout pageTitle="Leads">
                <CssBaseline />
                <Button
                    className={classes.newLeadButton}
                    variant="contained"
                    onClick={this.handleNewLeadClick}
                >
                    NEW LEAD
                </Button>
                {leadsStatus.length > 0
                    ? (
                        <>
                            {leadsStatus.map((leadStatus) => (
                                <ExpansionPanel
                                    key={leadStatus.status}
                                    expanded={expanded === leadStatus.status}
                                    onChange={() => this.handleChange(leadStatus.status)}
                                >
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`${leadStatus.status}-content`}
                                        id={`${leadStatus.status}-header`}
                                    >
                                        <Grid container spacing={0}>
                                            <Grid item xs={6}>
                                                <Typography className={classes.heading}>
                                                    {leadStatus.status === 'Converted'
                                                        ? (
                                                            <CheckCircleIcon
                                                                className={[classes.hybridTextIconLeft, classes.convertedIcon]}
                                                            />
                                                        )
                                                        : (
                                                            <LensIcon
                                                                className={`${classes.hybridTextIconLeft} ${this.iconStatus(leadStatus.status)}`}
                                                            />
                                                        )}
                                                    {leadStatus.status}
                                                    {' '}
                                                    (
                                                    {leadStatus.qty}
                                                    )
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {expanded === leadStatus.status && (
                                                    <div className={classes.pageControlsContaner}>
                                                        <div className={classes.pageControls}>
                                                            <IconButton
                                                                onClick={() => this.handleNewPageClick(leadStatus.status, 1)}
                                                                size="small"
                                                                disabled={leadStatus.page === 1}
                                                            >
                                                                <FirstPageIcon fontSize="small" />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => this.handleNewPageClick(leadStatus.status, leadStatus.page - 1)}
                                                                size="small"
                                                                disabled={leadStatus.page === 1}
                                                            >
                                                                <ChevronLeftIcon fontSize="small" />
                                                            </IconButton>
                                                            <span className={classes.pageText}>
                                                                Page
                                                                {' '}
                                                                {leadStatus.page}
                                                                {' '}
                                                                of
                                                                {' '}
                                                                {leadStatus.pages}
                                                            </span>
                                                            <IconButton
                                                                onClick={() => this.handleNewPageClick(leadStatus.status, leadStatus.page + 1)}
                                                                size="small"
                                                                disabled={leadStatus.pages === 1 || (leadStatus.page === leadStatus.pages)}
                                                            >
                                                                <ChevronRightIcon fontSize="small" />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => this.handleNewPageClick(leadStatus.status, leadStatus.pages)}
                                                                size="small"
                                                                disabled={leadStatus.pages === 1 || (leadStatus.page === leadStatus.pages)}
                                                            >
                                                                <LastPageIcon fontSize="small" />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Table className={classes.table} padding="none">
                                            <TableHead className={classes.tableHead}>
                                                <TableRow className={classes.tableHeadRow}>
                                                    <CustomTableCell className={classes.tableHead}>#</CustomTableCell>
                                                    <CustomTableCell className={classes.tableHead}>Type</CustomTableCell>
                                                    <CustomTableCell className={classes.tableHead}>Name</CustomTableCell>
                                                    <CustomTableCell className={classes.tableHead}>Address</CustomTableCell>
                                                    <CustomTableCell className={classes.tableHead}>Branch</CustomTableCell>
                                                    <CustomTableCell align="center" className={classes.tableHead}>Start Date</CustomTableCell>
                                                    <CustomTableCell align="left" className={classes.tableHead}>Status</CustomTableCell>
                                                    <CustomTableCell align="center" />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(leads[leadStatus.status] || []).map((lead) => (
                                                    <TableRow onClick={() => this.handleLeadClick(lead.contactCode, lead.code)} className={classes.row} key={lead.code}>
                                                        <CustomTableCell component="th" scope="row">{lead.rowNumber}</CustomTableCell>
                                                        <CustomTableCell align="left">{lead.campaignShortDescription}</CustomTableCell>
                                                        <CustomTableCell align="left" className={classes.noWrap}>{lead.fullName}</CustomTableCell>
                                                        <CustomTableCell align="left" className={classes.noWrap}>{lead.shortAddress}</CustomTableCell>
                                                        <CustomTableCell align="left" className={classes.noWrap}>{lead.branchName}</CustomTableCell>
                                                        <CustomTableCell align="center" className={classes.noWrap}>{lead.startDate}</CustomTableCell>
                                                        <CustomTableCell align="left" className={classes.noWrap}>
                                                            {leadStatus.status === 'Converted'
                                                                ? (
                                                                    <CheckCircleIcon
                                                                        className={[classes.hybridTextIconLeft, classes.convertedIcon]}
                                                                    />
                                                                )
                                                                : (
                                                                    <LensIcon
                                                                        className={`${classes.hybridTextIconLeft} ${this.iconStatus(leadStatus.status)}`}
                                                                    />
                                                                )}
                                                            {lead.status}
                                                        </CustomTableCell>
                                                        <CustomTableCell align="right"><IconButton className={classes.button} aria-label="More info"><InfoIcon /></IconButton></CustomTableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            ))}
                        </>
                    )
                    : (<div>No leads found</div>)}

                <Dialog
                    open={showNewLeadDialog}
                    onClose={this.handleNewLeadDialogCloseClick}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.dialogContentContainer}>
                            <>
                                <Typography variant="h6" gutterBottom>
                                    New Lead
                                </Typography>
                                {/* <form onSubmit={this.handleSaveNewLead}> */}

                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <FormControl
                                            className={classes.menuFormControl}
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={!!errors.title}
                                        >
                                            <InputLabel htmlFor="title" className={classes.inputLabel}>Title</InputLabel>
                                            <Select
                                                keyboard="true"
                                                input={(
                                                    <OutlinedInput
                                                        onChange={this.updateNewLeadData}
                                                        name="title"
                                                        id="title"
                                                    />
                                                )}
                                                value={newLeadForm.title || ''}
                                            >
                                                <MenuItem value=""><em>None</em></MenuItem>
                                                {titles.map((title, i) => (
                                                    <MenuItem
                                                        key={i}
                                                        value={title}
                                                    >
                                                        {title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.title && (
                                                <FormHelperText>{errors.title}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="firstname"
                                            id="firstname"
                                            onChange={this.updateNewLeadData}
                                            value={newLeadForm.firstname}
                                            label="Firstname"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={!!errors.firstname}
                                            helperText={errors.firstname}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="surname"
                                            id="surname"
                                            onChange={this.updateNewLeadData}
                                            value={newLeadForm.surname}
                                            label="Surname"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={!!errors.surname}
                                            helperText={errors.surname}
                                        />
                                    </Grid>
                                </Grid>
                                <Divider className={classes.dialogDivider} />
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="postcode"
                                            id="postcode"
                                            onChange={this.updateNewLeadData}
                                            value={newLeadForm.postcode}
                                            label="Postcode"
                                            margin="normal"
                                            variant="outlined"
                                            required
                                            error={!!errors.postcode}
                                            helperText={errors.postcode}
                                        />
                                        <Button
                                            className={classes.findAddressButton}
                                            variant="contained"
                                            onClick={this.handleFindAddressClick}
                                        >
                                            FIND ADDRESS
                                        </Button>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={this.handleAdressesMenuClose}
                                        >
                                            {
                                                postcodeAddresses.map((address, i) => (
                                                    <MenuItem
                                                        key={i}
                                                        onClick={() => this.handleAddressClick(i)}
                                                    >
                                                        {address.line_1}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Menu>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="address1"
                                            id="address1"
                                            value={newLeadForm.address1}
                                            onChange={this.updateNewLeadData}
                                            label="Address"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={!!errors.address1}
                                            helperText={errors.address1}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="address2"
                                            id="address2"
                                            value={newLeadForm.address2}
                                            onChange={this.updateNewLeadData}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="townCity"
                                            id="townCity"
                                            value={newLeadForm.townCity}
                                            onChange={this.updateNewLeadData}
                                            label="Town/City"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={!!errors.townCity}
                                            helperText={errors.townCity}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl
                                            className={classes.menuFormControl}
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={!!errors.county}
                                        >
                                            <InputLabel htmlFor="county" className={classes.inputLabel}>County</InputLabel>
                                            <Select
                                                keyboard="true"
                                                input={(
                                                    <OutlinedInput
                                                        onChange={this.updateNewLeadData}
                                                        name="county"
                                                        id="county"
                                                    />
                                                )}
                                                value={newLeadForm.county || ''}
                                            >
                                                <MenuItem value=""><em>None</em></MenuItem>
                                                {counties.map((county, i) => (
                                                    <MenuItem
                                                        key={i}
                                                        value={county}
                                                    >
                                                        {county}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.county && (
                                                <FormHelperText>{errors.county}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl
                                            className={classes.menuFormControl}
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={!!errors.country}
                                        >
                                            <InputLabel htmlFor="country" className={classes.inputLabel}>Country</InputLabel>
                                            <Select
                                                keyboard="true"
                                                input={(
                                                    <OutlinedInput
                                                        onChange={this.updateNewLeadData}
                                                        name="country"
                                                        id="country"
                                                    />
                                                )}
                                                value={newLeadForm.country || ''}
                                            >
                                                <MenuItem value=""><em>None</em></MenuItem>
                                                {countries.map((country, i) => (
                                                    <MenuItem
                                                        key={i}
                                                        value={country}
                                                    >
                                                        {country}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.country && (
                                                <FormHelperText>{errors.country}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>

                                </Grid>
                                <Divider className={classes.dialogDivider} />
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="emailAddress"
                                            id="emailAddress"
                                            value={newLeadForm.emailAddress}
                                            onChange={this.updateNewLeadData}
                                            label="Email Address"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={!!errors.emailAddress}
                                            helperText={errors.emailAddress}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="homeTelephoneNumber"
                                            id="homeTelephoneNumber"
                                            value={newLeadForm.homeTelephoneNumber}
                                            onChange={this.updateNewLeadData}
                                            label="Home Telephone"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="mobileTelephoneNumber"
                                            id="mobileTelephoneNumber"
                                            value={newLeadForm.mobileTelephoneNumber}
                                            onChange={this.updateNewLeadData}
                                            label="Mobile Telephone"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={!!errors.mobileTelephoneNumber}
                                            helperText={errors.mobileTelephoneNumber}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={(
                                                <Checkbox
                                                    name="gdpr"
                                                    id="gdpr"
                                                    checked={newLeadForm.gdpr === '1'}
                                                    onChange={this.handleNewLeadCheckBoxClick('gdpr')}
                                                    value="1"
                                                />
                                            )}
                                            label="GDPR Permission"
                                        />

                                    </Grid>
                                </Grid>

                                <Divider className={classes.dialogDivider} />
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <FormControl
                                            className={classes.menuFormControl}
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={!!errors.campaignCode}
                                        >
                                            <InputLabel htmlFor="campaignCode" className={classes.inputLabel}>Lead Campaign</InputLabel>
                                            <Select
                                                keyboard="true"
                                                input={(
                                                    <OutlinedInput
                                                        onChange={this.updateNewLeadData}
                                                        name="campaignCode"
                                                        id="campaignCode"
                                                    />
                                                )}
                                                value={newLeadForm.campaignCode || ''}
                                            >
                                                <MenuItem value=""><em>None</em></MenuItem>
                                                {campaigns.map((campaign, i) => (
                                                    <MenuItem
                                                        key={i}
                                                        value={campaign.code}
                                                    >
                                                        {campaign.description}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.campaignCode && (
                                                <FormHelperText>{errors.campaignCode}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl
                                            className={classes.menuFormControl}
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={!!errors.leadSourceCode}
                                        >
                                            <InputLabel htmlFor="leadSource" className={classes.inputLabel}>Lead Source</InputLabel>
                                            <Select
                                                keyboard="true"
                                                input={(
                                                    <OutlinedInput
                                                        onChange={this.updateNewLeadData}
                                                        name="leadSourceCode"
                                                        id="leadSourceCode"
                                                    />
                                                )}
                                                value={newLeadForm.leadSourceCode || ''}
                                            >
                                                <MenuItem value=""><em>None</em></MenuItem>
                                                {leadSources.map((leadSource, i) => (
                                                    <MenuItem
                                                        key={i}
                                                        value={leadSource.code}
                                                    >
                                                        {leadSource.description}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.leadSourceCode && (
                                                <FormHelperText>{errors.leadSourceCode}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Button
                                    variant="contained"
                                    className={classes.dialogButton}
                                    type="submit"
                                    disabled={disableNewLeadSaveButton}
                                    onClick={this.handleSaveNewLead}
                                >
                                    SAVE
                                </Button>
                                {/* </form> */}
                            </>
                        </div>
                    </DialogContent>
                    <Divider className={classes.dialogActionsDivider} />
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            onClick={this.handleNewLeadDialogCloseClick}
                            color="primary"
                            autoFocus
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

            </Layout>
        );
    }
}

export default withSnackbar(withStyles(styles)(LeadsList));
