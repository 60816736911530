import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
        minWidth: 300,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
});

class ItemChildrenCreateDialog extends React.Component {
    state = {
        disableSubmitButton: false,
        showDialog: false,
        formData: {
            supplierOrderCustomerReference: '',
            length: '',
            printLabel: 1,
        },
    }

    componentDidUpdate(prevProps) {
        if (this.props.showCreateChildItemDialog !== prevProps.showCreateChildItemDialog) {
            if (this.props.showCreateChildItemDialog) this.handleOpenDialog();
            this.props.resetCreateChildItemDialog();
        }
    }

    handleOpenDialog = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                length: '',
                printLabel: 1,
            },
            showDialog: true,
        });
    }

    handleCloseDialog = () => {
        this.setState({
            showDialog: false,
        });
    }

    updateData = (event) => {
        const { value, name } = event.target;
        let formattedValue;
        switch (name) {
        case 'supplierOrderCustomerReference':
            formattedValue = value.toUpperCase();
            break;
        case 'length':
            if (value !== '') {
                formattedValue = value.replace(/[^0-9$.]/g, '');
            } else {
                formattedValue = value;
            }
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState(state => ({
            formData: {
                ...state.formData,
                [name]: formattedValue,
            },
        }));
    }

    handleSubmitButtonClick = () => {
        const formData = {
            ...this.state.formData,
            length: parseFloat(this.state.formData.length),
        };
        this.setState({
            disableSubmitButton: true,
            formData,
        });
        this.props.createChildItem(formData).then(() => {
            this.setState({
                showDialog: false,
                disableSubmitButton: false,
            });
            this.props.enqueueSnackbar('Child item created', { variant: 'success' });
        }).catch(() => {
            this.setState({
                disableSubmitButton: false,
            });
            this.props.enqueueSnackbar('Child item create failed', { variant: 'error' });
        });
    }

    updateCheckbox = name => (event) => {
        const newState = event.target.checked ? 1 : 0;
        this.setState(state => ({
            formData: {
                ...state.formData,
                [name]: newState,
            },
        }));
    }

    render() {
        const {
            classes,
            errors,
        } = this.props;

        const {
            disableSubmitButton,
            formData,
            showDialog,
        } = this.state;

        return (
            <Dialog
                open={showDialog}
                onClose={this.handleCloseDialog}
            >
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.dialogContentContainer}>
                        <React.Fragment>
                            <Typography variant="h6" gutterBottom>
                                Create Child Item
                            </Typography>
                            <form>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="supplierOrderCustomerReference"
                                            id="supplierOrderCustomerReference"
                                            onChange={this.updateData}
                                            value={formData.supplierOrderCustomerReference}
                                            label="Customer Reference"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors.supplierOrderCustomerReference}
                                            helperText={errors.supplierOrderCustomerReference}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="length"
                                            id="length"
                                            onChange={this.updateData}
                                            value={formData.length}
                                            label="Length (m)"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors.length}
                                            helperText={errors.length}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={(
                                                <Checkbox
                                                    name="printLabel"
                                                    id="printLabel"
                                                    checked={formData.printLabel === 1}
                                                    onChange={this.updateCheckbox('printLabel')}
                                                />
                                            )}
                                            label="Print Labels"
                                        />
                                    </Grid>
                                </Grid>
                                <Button
                                    onClick={this.handleSubmitButtonClick}
                                    variant="contained"
                                    className={classes.dialogButton}
                                    disabled={
                                        disableSubmitButton
                                        || formData.length === ''
                                        || !formData.length > 0
                                    }
                                >
                            CREATE
                                </Button>
                            </form>
                        </React.Fragment>
                    </div>
                </DialogContent>
                <Divider className={classes.dialogActionsDivider} />
                <DialogActions className={classes.dialogActions}>
                    <Button
                        onClick={this.handleCloseDialog}
                        color="primary"
                        autoFocus
                    >
                Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ItemChildrenCreateDialog.propTypes = {
    createChildItem: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    resetCreateChildItemDialog: PropTypes.func.isRequired,
    showCreateChildItemDialog: PropTypes.bool.isRequired,
};

export default withSnackbar(withStyles(styles)(ItemChildrenCreateDialog));
