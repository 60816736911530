import Cookie from 'js-cookie';

const {
    REACT_APP_API_SERVER,
} = process.env;

const ourFetch = (path, args = {}) => {
    const headers = args.headers || {};

    // if(path.search(^/http)

    return fetch.apply(null, [
        // `api.${window.location.host}${path}`, {
        path, {
            ...args,
            headers: {
                ...headers,
                authorization: headers.authorization || Cookie.get('authToken'),
            },
        },
    ]).then((response) => {
        if (response.status === 401) {
            window.location = '/login';
            return Promise.reject(response);
        }
        return response;
    });
};

const apiFetch = (path, args = {}) => {
    const headers = args.headers || {};
    return fetch.apply(null, [
        `${REACT_APP_API_SERVER}${path}`, {
            ...args,
            headers: {
                ...headers,
                authorization: headers.authorization || Cookie.get('authToken'),
            },
        },
    ]).then((response) => {
        if (response.status === 401) {
            window.location = '/login';
            return Promise.reject(response);
        }
        return response;
    });
};

export const fetchJson = (...args) => ourFetch.apply(this, args).then(response => response.json());
export const fetchJson2 = (...args) => apiFetch.apply(this, args).then(async response => (
    {
        payload: await response.json(),
        status: response.status,
    }
));
// export const apiFetchJson = (...args) => apiFetch.apply(this, args).then(response => response.json());

export default ourFetch;
