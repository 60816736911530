import React from 'react';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Menu,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import TickIcon from '@material-ui/icons/Done';
import { withSnackbar } from 'notistack';
import HybridTextField from '../../hybridTextField';
import titalise from '../../../utilities/titalise';
import { paymentSourcesArray } from '../../../constants/arrays';
import { paymentSourceLookUp } from '../../../utilities/lookUpTables';

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
}))(TableCell);

const styles = (theme) => ({
    label: {
        fontSize: 11,
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: 7,
        marginTop: theme.spacing.unit * 2,
        width: '100%',
        textAlign: 'left',
    },
    componentContainer: {
        width: '100%',
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: theme.spacing.unit * 1.5,
    },

    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    divider: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    authorisedPaymentChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '102px',
    },
    declinedPaymentChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '102px',
    },
    chip: {
        height: 21,
        borderRadius: 5,
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    table: {
        // width: '50%',
    },
    menuIcon: {
        color: '#737373',
    },
    currentPayment: {
        backgroundColor: '#c6edff !important',
    },
    dialogContent: {
        paddingBottom: 16,
        minWidth: 390,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '8px 24px',
        margin: '0px 0px 0px 0px !important',
    },
    bumpTop1U: {
        marginTop: theme.spacing.unit * 1,
    },
    bumpTop2U: {
        marginTop: theme.spacing.unit * 2,
    },
    bumpTop3U: {
        marginTop: theme.spacing.unit * 3,
    },
    bumpBottom1U: {
        marginBottom: theme.spacing.unit * 1,
    },
    bumpBottom2U: {
        marginBottom: theme.spacing.unit * 2,
    },
    bumpBottom3U: {
        marginBottom: theme.spacing.unit * 3,
    },
    refundIconContainer: {
        display: 'flex',
        width: 40,
        height: 21,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    refundIconLeft: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        width: '100%',
    },
    refundIconRight: {
        color: 'white',
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        width: '100%',
    },
    refundLetter: {
        textAlign: 'center',
        position: 'relative',
        left: 20,
        top: 2,
        color: '#fff',
    },
    backgroundPass: {
        backgroundColor: theme.palette.primary.testPass,
    },
    backgroundFail: {
        backgroundColor: theme.palette.primary.testFail,
    },
    backgroundDisabled: {
        backgroundColor: '#e0e0e0',
    },
    backgroundWarning: {
        backgroundColor: '#ffbf00',
    },
    dialogContentAlertText: {
        color: '#c80000',
        fontSize: 16,
        opacity: 0.54,
    },
    dialogContentTitle: {
        color: '#c80000',
        fontSize: 16,
        opacity: 0.54,
        fontWeight: 700,
    },
});

class AgreementPayments extends React.Component {
    state = {
        anchorEl: null,
        currentPayment: {},
        disableRefundSubmitButton: false,
        disableReversalSubmitButton: false,
        errors: false,
        refundData: {},
        reversalData: {},
        showPaymentDetailDialog: false,
        showRefundDialog: false,
        showReversalDialog: false,
    };

    async componentDidMount() {
    }

    handleReversalClick = () => {
        this.setState({
            reversalData: {
                reason: '',
            },
            showReversalDialog: true,
        });
    };

    handleCloseReversalDialogClick = () => {
        this.setState({
            disableReversalSubmitButton: false,
            showReversalDialog: false,
        });
    };

    updateReversalData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            reversalData: {
                ...state.reversalData,
                [name]: formattedValue,
            },
        }));
    };

    handleReversalSubmitClick = () => {
        this.setState({
            disableReversalSubmitButton: true,
        });
        const agreementCode = this.props.payments[0].planReference;
        const { paymentID } = this.state.currentPayment;
        const params = {
            ...this.state.reversalData,
            amount: parseFloat(this.state.reversalData.amount),
            paymentID,
        };
        this.props.reversePayment(agreementCode, params).then(() => {
            this.setState({
                disableReversalSubmitButton: false,
                showReversalDialog: false,
            });
            this.props.enqueueSnackbar('Payment Reversal Successful', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableReversalSubmitButton: false,
            });
            this.props.enqueueSnackbar('Payment Reversal Failed', { variant: 'error' });
        });
    };

    handleRefundClick = () => {
        this.setState((state) => ({
            refundData: {
                amount: (state.currentPayment.amount - state.currentPayment.refundedAmount).toFixed(2),
                reason: '',
            },
            showRefundDialog: true,
        }));
    };

    handleCloseRefundDialogClick = () => {
        this.setState({
            disableRefundSubmitButton: false,
            showRefundDialog: false,
        });
    };

    updateRefundData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'amount':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            refundData: {
                ...state.refundData,
                [name]: formattedValue,
            },
        }));
    };

    handleRefundSubmitClick = () => {
        this.setState({
            disableRefundSubmitButton: true,
        });
        const agreementCode = this.props.payments[0].planReference;
        const params = {
            ...this.state.refundData,
            contactCode: this.props.contact.code,
            refundPayments: [{
                amount: parseFloat(this.state.refundData.amount),
                paymentDateTime: this.state.currentPayment.paymentDateTime,
                paymentID: this.state.currentPayment.paymentID,
                source: paymentSourceLookUp[this.state.currentPayment.source],
            }],
        };
        this.props.refundPayments(agreementCode, params).then(() => {
            this.setState({
                disableRefundSubmitButton: false,
                showRefundDialog: false,
            });
            this.props.enqueueSnackbar('Payment Refund Successful', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableRefundSubmitButton: false,
            });
            this.props.enqueueSnackbar('Payment Refund Failed', { variant: 'error' });
        });
    };

    handleOpenPaymentMenu = (event) => {
        // const { anchorEl, id } = event.currentTarget;
        const anchorEl = event.currentTarget;
        const { id } = anchorEl;
        this.setState({
            anchorEl,
            currentPayment: this.props.payments.find((payment) => payment.paymentID === parseInt(id, 10)),
        });
    };

    handleClosePaymentMenu = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handlePaymentDetailClick = (code) => {
        const currentPayment = this.props.payments.find((payment) => payment.code === code);
        this.setState({
            currentPayment,
            showPaymentDetailDialog: true,
        });
    };

    handleClosePaymentDetailDialogClick = () => {
        this.setState({
            showPaymentDetailDialog: false,
        });
    };

    render() {
        const {
            classes,
            payments,
            rentalAgreementCode,
            user,
        } = this.props;
        const {
            anchorEl,
            currentPayment,
            disableReversalSubmitButton,
            disableRefundSubmitButton,
            errors,
            showPaymentDetailDialog,
            showReversalDialog,
            showRefundDialog,
            reversalData,
            refundData,
        } = this.state;

        return (
            <div className={classes.componentContainer}>
                { payments.length === 0
                    ? (
                        <HybridTextField
                            alert
                            label="Payments"
                            text="None"
                            fieldStyle={{ style: 'alert', tooltip: 'No payments' }}
                        />
                    )
                    : (
                        <>
                            <div
                                className={classes.label}
                            >
                                Payments
                            </div>
                            <Paper>
                                <Table className={classes.table} padding="dense">
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell>#</CustomTableCell>
                                            <CustomTableCell>Source</CustomTableCell>
                                            <CustomTableCell align="center">Payment ID</CustomTableCell>
                                            <CustomTableCell align="center">Card Number</CustomTableCell>
                                            <CustomTableCell align="center">Payment Date/Time</CustomTableCell>
                                            <CustomTableCell align="center">Auth Code</CustomTableCell>
                                            <CustomTableCell align="center">Amount</CustomTableCell>
                                            <CustomTableCell align="center">Status</CustomTableCell>
                                            <CustomTableCell align="center">Refundable</CustomTableCell>
                                            <CustomTableCell align="center" />
                                            <CustomTableCell align="center" />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* {payments.sort((a, b) => a.paymentDate - b.paymentDate).reverse().map((payment, i) => { */}
                                        {payments.map((payment, i) => {
                                            i++;
                                            return (
                                                <TableRow
                                                    className={`${classes.row} ${anchorEl && currentPayment.paymentID === payment.paymentID ? classes.currentPayment : ''}`}
                                                    key={payment.paymentID}
                                                >
                                                    <CustomTableCell component="th" scope="row">{payments.length + 1 - i}</CustomTableCell>
                                                    <CustomTableCell align="left">{paymentSourcesArray.find((paymentSource) => paymentSource.source === payment.source).displaySource}</CustomTableCell>
                                                    <CustomTableCell align="center">
                                                        {payment.paymentID}
                                                    </CustomTableCell>
                                                    <CustomTableCell align="center">{payment.cardNo}</CustomTableCell>
                                                    <CustomTableCell align="center">
                                                        <div>
                                                            {moment(payment.paymentDateTime).format('DD/MM/YYYY')}
                                                        </div>
                                                        <div>
                                                            {moment(payment.paymentDateTime).format('HH:mm')}
                                                        </div>
                                                    </CustomTableCell>
                                                    {/* <CustomTableCell align="center">{moment(payment.paymentDateTime).format('HH:mm')}</CustomTableCell> */}
                                                    <CustomTableCell align="left">{payment.authCode}</CustomTableCell>
                                                    <CustomTableCell align="right">{`${payment.amount < 0 ? '-' : ''}£${(Math.abs(payment.amount)).toFixed(2)}`}</CustomTableCell>
                                                    <CustomTableCell align="center">{payment.transactionStatus === 'AUTHORISED' ? <Chip label="AUTHORISED" className={`${classes.chip} ${classes.authorisedPaymentChip}`} /> : <Chip label={payment.transactionStatus} className={`${classes.chip} ${classes.declinedPaymentChip}`} /> }</CustomTableCell>
                                                    <CustomTableCell align="center">
                                                        {payment.refundedAmount > 0
                                                            ? (
                                                                <div className={classes.refundIconContainer}>
                                                                    <div className={classes.refundLetter} />
                                                                    <div className={classNames(
                                                                        classes.refundIconLeft,
                                                                        classes.backgroundFail,
                                                                    )}
                                                                    />
                                                                    <div className={classNames(
                                                                        classes.refundIconRight,
                                                                        {
                                                                            [classes.backgroundFail]: payment.refundedAmount === payment.amount,
                                                                        },
                                                                        {
                                                                            [classes.backgroundPass]: payment.refundedAmount < payment.amount,
                                                                        },
                                                                    )}
                                                                    />
                                                                </div>
                                                            )
                                                            : (
                                                                <>
                                                                    {['MANUALADJUSTMENT', 'STANDINGORDER', 'REVERSAL', 'REFUND'].includes(payment.source)
                                                                        || payment.transactionStatus !== 'AUTHORISED'
                                                                        || payment.reversalPaymentID
                                                                        ? (
                                                                            <>
                                                                                <div className={classes.refundIconContainer}>
                                                                                    <div className={classes.refundLetter} />
                                                                                    <div className={classNames(
                                                                                        classes.refundIconLeft,
                                                                                        {
                                                                                            [classes.backgroundDisabled]: !payment.reversalPaymentID,
                                                                                        },
                                                                                        {
                                                                                            [classes.backgroundWarning]: payment.reversalPaymentID,
                                                                                        },
                                                                                    )}
                                                                                    />
                                                                                    <div className={classNames(
                                                                                        classes.refundIconRight,
                                                                                        {
                                                                                            [classes.backgroundDisabled]: !payment.reversalPaymentID,
                                                                                        },
                                                                                        {
                                                                                            [classes.backgroundWarning]: payment.reversalPaymentID,
                                                                                        },
                                                                                    )}
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )
                                                                        : (
                                                                            <div className={classes.refundIconContainer}>
                                                                                <div className={classes.refundLetter} />
                                                                                <div className={classNames(
                                                                                    classes.refundIconLeft,
                                                                                    classes.backgroundPass,
                                                                                )}
                                                                                />
                                                                                <div className={classNames(
                                                                                    classes.refundIconRight,
                                                                                    classes.backgroundPass,
                                                                                )}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                </>
                                                            )}

                                                    </CustomTableCell>
                                                    <CustomTableCell align="right">
                                                        <IconButton id={payment.paymentID} onClick={() => this.handlePaymentDetailClick(payment.code)} className={classes.button}>
                                                            <InfoIcon className={classes.menuIcon} />
                                                        </IconButton>
                                                    </CustomTableCell>
                                                    <CustomTableCell align="right">
                                                        {['refundReversal:*', 'refundReversal:write'].some((scope) => user.userScopes.includes(scope))
                                                            ? (
                                                                <IconButton id={payment.paymentID} onClick={this.handleOpenPaymentMenu.bind(this)} className={classes.button}>
                                                                    <MoreVertIcon className={classes.menuIcon} />
                                                                </IconButton>
                                                            )
                                                            : ''}
                                                    </CustomTableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </>
                    )}

                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={this.handleClosePaymentMenu}
                    classes={{ paper: classes.menu }}
                    disableAutoFocusItem
                >
                    {/* <Divider className={classes.dialogDivider} /> */}
                    <MenuItem
                        disabled={
                            currentPayment.transactionStatus !== 'AUTHORISED'
                            || currentPayment.source === 'REFUND'
                            || currentPayment.source === 'MANUALADJUSTMENT'
                            || currentPayment.source === 'STANDINGORDER'
                            || currentPayment.source === 'REVERSAL'
                            || currentPayment.refundedAmount === currentPayment.amount
                            || currentPayment.reversalPaymentID
                        }
                        key="refundPayment"
                        onClick={() => { this.handleClosePaymentMenu(); this.handleRefundClick(); }}
                    >
                        Refund Payment
                    </MenuItem>
                    <MenuItem
                        disabled={
                            currentPayment.transactionStatus !== 'AUTHORISED'
                            || currentPayment.source === 'REFUND'
                            || currentPayment.source === 'MANUALADJUSTMENT'
                            || currentPayment.source === 'STANDINGORDER'
                            || currentPayment.source === 'REVERSAL'
                            || currentPayment.refundedAmount === currentPayment.amount
                            || currentPayment.reversalPaymentID
                        }
                        key="chargebackPayment"
                        onClick={() => { this.handleClosePaymentMenu(); this.handleReversalClick(); }}
                    >
                        Reverse Payment
                    </MenuItem>
                </Menu>

                {!isEmpty(currentPayment)
                    ? (
                        <>
                            <Dialog
                                open={showPaymentDetailDialog}
                                onClose={this.handleClosePaymentDetailDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <Typography variant="h6" gutterBottom>
                                            Payment Detail
                                        </Typography>
                                        {currentPayment.reversalPaymentID
                                            ? (
                                                <Typography className={classNames(
                                                    classes.bumpBottom2U,
                                                    classes.dialogContentAlertText,
                                                )}
                                                >
                                                    This payment has been reversed by paymentID
                                                    {' '}
                                                    {currentPayment.reversalPaymentID}
                                                    .
                                                </Typography>
                                            )
                                            : ''}
                                        <Grid
                                            container
                                            spacing={0}
                                            className={classNames(
                                                classes.sectionContainer,
                                                classes.bumpTop3U,
                                            )}
                                        >
                                            <Grid
                                                container
                                                spacing={0}
                                            >

                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Type"
                                                        text={paymentSourceLookUp[currentPayment.source]}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Payment Reference"
                                                        text={currentPayment.paymentReference}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Parent ID"
                                                        text={currentPayment.source === 'REFUND'
                                                            || currentPayment.source === 'REVERSAL'
                                                            ? currentPayment.parentID
                                                            : 'n/a'}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Created By"
                                                        text={currentPayment.createdByContact}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Reason"
                                                        text={currentPayment.reason}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Notes"
                                                        text={currentPayment.notes}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Info"
                                                        text={currentPayment.info}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HybridTextField
                                                        label="Refunded Amount"
                                                        text={!['MANUALADJUSTMENT', 'STANDINGORDER', 'REVERSAL', 'REFUND'].includes(currentPayment.source)
                                                        && !currentPayment.reversalPaymentID
                                                            ? `£${currentPayment.refundedAmount.toFixed(2)}  (${(currentPayment.refundedAmount / currentPayment.amount) * 100}%)`
                                                            : 'n/a'}
                                                        alert={currentPayment.refundedAmount > 0}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </div>
                                </DialogContent>
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleClosePaymentDetailDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showReversalDialog}
                                onClose={this.handleCloseReversalDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <Typography variant="h6" className={classes.bumpBottom3U}>
                                            Reverse Payment
                                        </Typography>
                                        <DialogContentText className={classNames(
                                            classes.bumpTop2U,
                                            classes.dialogContentAlertText,
                                            classes.dialogContentTitle,
                                        )}
                                        >
                                            PLEASE NOTE:
                                        </DialogContentText>

                                        <Typography className={classNames(
                                            classes.bumpBottom2U,
                                            classes.dialogContentAlertText,
                                        )}
                                        >
                                            A payment reversal is only used to process a customer chargeback.
                                        </Typography>

                                        <form>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        onChange={this.updateReversalData}
                                                        className={classes.formControl}
                                                        name="caseReference"
                                                        id="caseReference"
                                                        label="Case Reference"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.caseReference}
                                                        helperText={errors.caseReference}
                                                        autoFocus
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        onChange={this.updateReversalData}
                                                        className={`${classes.formControl} ${classes.marginTop3x}`}
                                                        name="amount"
                                                        id="amount"
                                                        value={currentPayment.amount}
                                                        label="Amount"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled
                                                        error={!!errors.amount}
                                                        helperText={errors.amount}
                                                        InputProps={{
                                                            startAdornment:
                            <InputAdornment
                                disableTypography
                                className={classes.inputAdornment}
                                position="start"
                            >
                                £
                            </InputAdornment>,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        onChange={this.updateReversalData}
                                                        className={classes.formControl}
                                                        name="reason"
                                                        id="reason"
                                                        label="Reason"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        multiline
                                                        rows="6"
                                                        error={!!errors.reason}
                                                        helperText={errors.reason}
                                                        autoFocus
                                                    />
                                                </Grid>
                                            </Grid>
                                        </form>
                                        <Grid
                                            container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center"
                                        >
                                            <Grid item xs={12}>
                                                <Button
                                                    className={classes.dialogButton}
                                                    disabled={
                                                        disableReversalSubmitButton
                                                        || !reversalData.reason
                                                    }
                                                    onClick={this.handleReversalSubmitClick}
                                                >
                                                    SUBMIT
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </DialogContent>
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleCloseReversalDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showRefundDialog}
                                onClose={this.handleCloseRefundDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <Typography variant="h6" className={classes.bumpBottom3U}>
                                            Refund Payment
                                        </Typography>

                                        <form>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        onChange={this.updateRefundData}
                                                        className={`${classes.formControl} ${classes.marginTop3x}`}
                                                        name="amount"
                                                        id="amount"
                                                        label="Amount"
                                                        margin="normal"
                                                        value={refundData.amount}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.amount}
                                                        helperText={errors.amount}
                                                        InputProps={{
                                                            startAdornment:
                            <InputAdornment
                                disableTypography
                                className={classes.inputAdornment}
                                position="start"
                            >
                                £
                            </InputAdornment>,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        onChange={this.updateRefundData}
                                                        className={classes.formControl}
                                                        name="reason"
                                                        id="reason"
                                                        label="Reason"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        multiline
                                                        rows="6"
                                                        error={!!errors.reason}
                                                        helperText={errors.reason}
                                                        autoFocus
                                                    />
                                                </Grid>
                                            </Grid>
                                        </form>
                                        <Grid
                                            container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center"
                                        >
                                            <Grid item xs={12}>
                                                <Button
                                                    className={classes.dialogButton}
                                                    disabled={
                                                        disableRefundSubmitButton
                                                        || !refundData.reason
                                                        || refundData.amount <= 0
                                                    }
                                                    onClick={this.handleRefundSubmitClick}
                                                >
                                                    SUBMIT
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </DialogContent>
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleCloseRefundDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                        </>
                    )
                    : ''}

                {/* <Dialog
                    open={_howPaymentDetailDialog}
                    onClose={this.handleClosePaymentDetailDialogClick}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <Typography variant="h6" gutterBottom>
                            Payment Detail
                        </Typography>
                        <DialogContentText id="alert-dialog-description">
                            <div className={classes.dialogContentContainer}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus
                                non enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
                                imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
                                Convallis convallis tellus id interdum velit laoreet id donec ultrices.
                            </div>
                        </DialogContentText>
                        <Divider />
                        {detailDialogTransactionStatus === 'Authorised' && (
                            <>
                                <div className={classes.dialogContentContainer}>
                                    <Typography variant="h6" gutterBottom>
                                        Refund Payment
                                    </Typography>
                                    <DialogContentText>
                                        This will refund this payment to the original payment card.
                                    </DialogContentText>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <TextField
                                                className={classes.formControl}
                                                onChange={this.updateRefundData}
                                                name="reason"
                                                id="reason"
                                                label="Reason"
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                error={!!errors.surname}
                                                helperText={errors.surname}
                                                value=""
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button variant="contained" className={classes.dialogButton}>
                                        Refund
                                    </Button>
                                </div>
                                <Divider />
                                <div className={classes.dialogContentContainer}>
                                    <Typography variant="h6" gutterBottom>
                                        Claimback Payment
                                    </Typography>
                                    <DialogContentText>
                                        This will record a customer Claimback against this payment. You may optionally post an admin charge.
                                    </DialogContentText>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <TextField
                                                className={classes.formControl}
                                                onChange={this.updateRefundData}
                                                name="reason"
                                                id="reason"
                                                label="Reason"
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                error={!!errors.surname}
                                                helperText={errors.surname}
                                                value=""
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="claimbackAdminChargeAmount"
                                                name="claimbackAdminChargeAmount"
                                                variant="outlined"
                                                label="Admin Charge"
                                                margin="normal"
                                                value=""
                                                onChange={this.updateRefundData}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button variant="contained" className={classes.dialogButton}>
                                        Claimback
                                    </Button>
                                </div>
                                <Divider />
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClosePaymentDetailDialogClick} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog> */}
            </div>
        );
    }
}

export default withSnackbar(withStyles(styles)(AgreementPayments));
