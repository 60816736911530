import moment from 'moment-timezone';

const currentDateTime = () => {
    const now = moment.tz(moment(), 'Europe/London');
    const dateTime = moment(now).format('YYYY-MM-DD HH:mm:ss');
    const dateTimeLong = moment(now).format('YYYY-MM-DD HH:mm:ss.SSS');
    const dateTimeFilename = moment(now).format('_DDMMYYYY_HHmm');
    const date = moment(now).format('YYYY-MM-DD');
    const time = moment(now).format('HH:mm:ss');
    const UTCTime = now.utc();
    return {
        dateTime,
        dateTimeLong,
        date,
        time,
        UTCTime,
        dateTimeFilename,
    };
};

export default currentDateTime;
