import React from 'react';
import {
    Button,
    Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';
import ContactAgreements from './contactAgreements';

const AMPLIFY5 = 1002;

const styles = (theme) => ({
    tag: {
        backgroundColor: '#00bcd4',
        borderRadius: 4,
        boxSizing: 'border-box',
        color: '#fff',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.4,
        lineHeight: 4.75,
        marginRight: theme.spacing.unit * 3,
        minWidth: 64,
        padding: '10px 16px',
        textTransform: 'uppercase',
        verticalAlign: 'middle',
    },
    alert: {
        backgroundColor: '#ff0000',
        borderRadius: 4,
        boxSizing: 'border-box',
        color: '#fff',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.4,
        lineHeight: 4.75,
        marginLeft: theme.spacing.unit * 3,
        minWidth: 64,
        padding: '10px 16px',
        textTransform: 'uppercase',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
    },
    badgeContainer: {
        marginTop: theme.spacing.unit * 4,
        marginBottom: theme.spacing.unit * 2,
    },
    alertContainer: {
        marginTop: theme.spacing.unit * 4,
        marginBottom: theme.spacing.unit * 2,
        textAlign: 'right',
    },
    alertIcon: {
        marginRight: 8,
    },
});

class ContactBadges extends React.Component {
    state = {
    };

    render() {
        const {
            classes,
            contact,
            contactAgreementsLoading,
            contactLeadsLoading,
            contactLoading,
            engineers,
        } = this.props;

        const badges = [
            {
                show: !contactLoading && contact.contactAgent,
                label: '#AGENT',
            },
            {
                show: !contactLoading && contact.contactUser,
                label: '#USER',
            },
            {
                show: !contactLoading && contact.contactEngineer,
                label: '#OPERATIVE',
            },
            {
                show: !contactLoading && !contactAgreementsLoading && (contact.agreements || []).length > 0,
                label: '#CUSTOMER',
            },
            {
                show: !contactLoading && !contactAgreementsLoading && !!contact.groups.find((group) => group.code === AMPLIFY5),
                // !userLoading && !!user.groups.find((group) => group.code === AMPLIFY5
                label: '#AMPLIFY5',
            },
        ];

        const alerts = [
            {
                show: !contactLoading && contact.duplicates.length > 0,
                label: 'DUPLICATE ACCOUNT',
            },
            {
                show: !contactLoading && contact.vulnerable,
                label: 'VULNERABLE',
            },
            {
                show: !contactLoading && contact.debt && contact.debt.status !== 'NORMAL',
                label: 'DEBT',
            },
            {
                show: !contactLoading && !contactAgreementsLoading && contact.agreements.filter((agreement) => agreement.plan.status === 'ACTIVE' && !agreement.plan.card).length > 0,
                label: 'PAYMENT CARD',
            },
            {
                show: !contactLoading && contact.invalidAddress,
                label: 'INVALID ADDRESS',
            },
            {
                show: !contactLoading && contact.invalidMobileTelephoneNumber,
                label: 'INVALID MOBILE TELEPHONE NUMBER',
            },
            {
                show: !contactLoading && contact.forbidSale,
                label: 'SALES PROHIBITED',
            },
            {
                show: !contactLoading && contact.forbidAgreement,
                label: 'AGREEMENTS PROHIBITED',
            },
            {
                show: !contactLoading && contact.complaints.filter((complaint) => complaint.status === 'OPEN').length > 0,
                label: 'UNRESOLVED COMPLAINT',
            },
            {
                show: !contactLoading && isEmpty(contact.address),
                label: 'NO ADDRESS',
            },
            {
                show: !contactLoading && !contactAgreementsLoading && contact.agreements.filter((agreement) => agreement.plan.status !== 'CANCELLED' && agreement.plan.status !== 'INCOMPLETE' && agreement.plan.arrearsDay).length > 0,
                label: 'PLAN ON ARREARS DAY',
            },
            {
                show: !contactLoading && !contactLeadsLoading && contact.leads.filter((lead) => lead.agentFirstContactSmsResponseWaiting || lead.agentLastContactSmsResponseWaiting).length > 0,
                label: 'LEAD AWAITING RESPONSE',
            },
            {
                show: !contactLoading && !contactAgreementsLoading && contact.agreements.filter((agreement) => ['ACTIVE', 'COMPLETE'].some((status) => agreement.status === status) && agreement.selfMeasure && !agreement.selfMeasureCheckedByContactCode).length > 0,
                label: 'SELF MEASURE AGREEMENT NOT CHECKED',
            },
            {
                show: !contactLoading && !contactAgreementsLoading && contact.agreements.filter((agreement) => agreement.fastTrack).length > 0,
                label: 'FAST TRACK',
            },
        ];

        return (
            <>
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        {badges.filter((badge) => badge.show).length > 0
                            ? (
                                <div className={classes.badgeContainer}>
                                    {badges.filter((badge) => badge.show).map((badge) => (
                                        <span className={classes.tag} key={badge.label}>
                                            {badge.label}
                                        </span>
                                    ))}
                                </div>
                            )
                            : null}
                    </Grid>
                    <Grid item xs={6}>
                        {alerts.filter((alert) => alert.show).length > 0
                            ? (
                                <div className={classes.alertContainer}>
                                    {alerts.filter((alert) => alert.show).map((alert) => (
                                        <span className={classes.alert} key={alert.label}>
                                            <FontAwesomeIcon icon={faExclamationTriangle} inverse color="red" className={classes.alertIcon} />
                                            {alert.label}
                                        </span>
                                    ))}
                                </div>
                            )
                            : null}
                    </Grid>

                    {/* <Grid item xs={6}>
                        {badges.filter((badge) => badge.show).length > 0
                            ? (
                                <div className={classes.badgeContainer}>
                                    {badges.filter((badge) => badge.show).map((badge) => (
                                        <React.Fragment key={badge.label}>
                                            <span className={classes.tag}>{badge.label}</span>
                                        </React.Fragment>
                                    ))}
                                </div>
                            )
                            : null}
                    </Grid>
                    <Grid item xs={6}>
                        {alerts.filter((alert) => alert.show).length > 0
                            ? (
                                <div className={classes.alertContainer}>
                                    {alerts.filter((alert) => alert.show).map((alert) => (
                                        <span className={classes.alert} key={alert.label}>
                                            <FontAwesomeIcon icon={faExclamationTriangle} inverse color="red" className={classes.alertIcon} />
                                            {alert.label}
                                        </span>
                                    ))}
                                </div>
                            )
                            : null}
                    </Grid>
 */}

                </Grid>
            </>
        );
    }
}

export default withStyles(styles)(ContactBadges);
