import React from 'react';
import {
    CssBaseline,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import fetch, { fetchJson } from '../utilities/fetch';
import BranchesTable from '../components/branchesTable';
import BranchesSummary from '../components/branchesSummary';
import Layout from './Layout';

const cookies = new Cookies();

const removeFalseyValuesFromObject = (obj) => pickBy(obj, identity);

const styles = (theme) => ({
    newDeliveryNoteButton: {
        marginBottom: theme.spacing.unit * 3,
    },
});

class Branches extends React.Component {
    state = {
        allBranches: null,
        controls: null,
        defaultControls: {
            pageSize: 25,
            pageNumber: 1,
            orderColumn: 'name',
            orderDirection: 'asc',
        },
        defaultFilters: {
        },
        errors: [],
        filters: {},
        branches: null,
        branchesParentSummary: null,

        keyLookup: {
            assignment: 'assignment',
            bin: 'bin',
            branch: 'branchName',
            code: 'code',
            colour: 'colour',
            condition: 'itemConditionDescription',
            length: 'length',
            manufacturer: 'manufacturerName',
            model: 'modelNo',
            stockRoom: 'stockRoom',
            type: 'branchTypeDescription',
            width: 'width',
        },
        selectData: [],
        totalBranchesQty: null,
    };

    componentDidMount = async () => {
        await this.setDefaultFilters();
        await this.setDefaultControls();
        this.fetchBranches(this.state.controls, this.state.filters, false).then((branches) => {
            this.setState({
                branches,
            });
        });
        this.fetchMenus();
    };

    setDefaultFilters = async () => {
        const branchesFiltersCookie = cookies.get('branchesFilters') || {};
        let newFilters = {
            ...this.state.defaultFilters,
            ...branchesFiltersCookie,
        };
        const branchesFiltersCookieQty = Object.keys(branchesFiltersCookie).length;
        const user = await fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/user');
        if (branchesFiltersCookie === 0) {
            newFilters = {
                ...newFilters,
                branch: user.branchName,
            };
        }
        const statePromise = new Promise((resolve) => {
            this.setState({
                filters: newFilters,
            }, resolve);
        });
        cookies.set('branchesFilters', newFilters, { path: '/' });
        return statePromise;
    };

    setDefaultControls = async () => {
        const branchesControlsCookie = cookies.get('branchesControls') || {};
        const newControls = {
            ...this.state.defaultControls,
            ...branchesControlsCookie,
        };

        const statePromise = new Promise((resolve) => {
            this.setState({
                controls: newControls,
            }, resolve);
        });
        cookies.set('branchesControls', newControls, { path: '/' });
        return statePromise;
    };

    fetchBranches = async (controls, filters, download) => {
        if (!download) {
            this.setState({
                branches: null,
            });
        }
        const branchesURL = new URL('https://api.portal2.payweeklycarpets.co.uk/core/branchesList');
        const branchesTypeSummaryURL = new URL('https://api.portal2.payweeklycarpets.co.uk/core/branchesList/summary');
        const params = {
            ...controls,
            ...filters,
        };
        Object.keys(params).forEach((key) => branchesURL.searchParams.append(key, params[key]));
        Object.keys(params).forEach((key) => branchesTypeSummaryURL.searchParams.append(key, params[key]));
        const branches = await fetchJson(branchesURL);
        if (!download) {
            const branchesParentSummary = await fetchJson(branchesTypeSummaryURL);
            const totalBranchesQty = branchesParentSummary.reduce((acc, parentBranch) => (parentBranch.branchQty + acc), 0);
            this.setState({
                branchesParentSummary,
                totalBranchesQty,
            });
        }
        return (branches);
    };

    fetchMenus = () => {
        const fetchAllBranches = fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/branches')
            .then((allBranches) => {
                this.setState({
                    allBranches,
                });
            });
        return Promise.all([fetchAllBranches]);
    };

    removeFilter = (keyToRemove) => {
        const newFiltersState = {};
        Object.keys(this.state.filters).map((key) => {
            if (key === keyToRemove) return;
            newFiltersState[key] = this.state.filters[key];
        });
        const newControlsState = {
            ...this.state.controls,
            pageNumber: 1,
        };
        this.fetchBranches(newControlsState, newFiltersState, false).then((branches) => {
            this.setState({
                branches,
            });
        });
        this.setState({
            controls: newControlsState,
            filters: newFiltersState,
        });
        cookies.set('branchesFilters', newFiltersState, { path: '/' });
        cookies.set('branchesControls', newControlsState, { path: '/' });
    };

    setFilters = async (newFilters) => {
        const newFiltersState = {
            ...this.state.filters,
            ...newFilters,
        };
        const newControlsState = {
            ...this.state.controls,
            pageNumber: 1,
        };
        this.fetchBranches(newControlsState, newFiltersState, false).then((branches) => {
            this.setState({
                branches,
            });
        });
        const statePromise = new Promise((resolve) => {
            this.setState({
                controls: newControlsState,
                filters: newFilters,
            }, resolve);
        });
        cookies.set('branchesFilters', newFilters, { path: '/' });
        cookies.set('branchesControls', newControlsState, { path: '/' });
        return statePromise;
    };

    setControls = async (newControls) => {
        this.fetchBranches(newControls, this.state.filters, false).then((branches) => {
            this.setState({
                branches,
            });
        });
        const statePromise = new Promise((resolve) => {
            this.setState({
                controls: newControls,
            }, resolve);
        });
        cookies.set('branchesControls', newControls, { path: '/' });
        return statePromise;
    };

    editBranch = async (payload) => {
        console.log('>>> Payload', payload);
        if (this.validateBranch(payload) === false) throw new Error({ error: 'Generic API error' });
        let response;
        let body;
        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/branch/${payload.branchCode}`, {
                method: 'PATCH',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState({
                selectData: [],
            });
            const currentControls = this.state.controls;
            const currentFilters = this.state.filters;
            this.fetchBranches(currentControls, currentFilters, false).then((branches) => {
                this.setState({
                    branches,
                });
            });
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    addBranch = async (payload) => {
        if (this.validateBranch(payload) === false) throw new Error({ error: 'Generic API error' });
        let response;
        let body;
        console.log('>>>Add Branch Payload', payload);
        try {
            response = await fetch('https://api.portal2.payweeklycarpets.co.uk/core/branch', {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState({
                selectData: [],
            });
            const currentControls = this.state.controls;
            const currentFilters = this.state.filters;
            this.fetchBranches(currentControls, currentFilters, false).then((branches) => {
                this.setState({
                    branches,
                });
            });
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    validateBranch = (payload) => {
        console.log('>>> Validating Branch');
        const fieldOrder = [
            'name',
            'shortName',
        ];

        const name = (() => {
            switch (true) {
            case !payload.name: return 'Branch Name required';
            case payload.name.length < 2: return 'Branch Name too short';
            case payload.shortName.length > 96: return 'Branch Name too long';
            default: return '';
            }
        })();
        const shortName = (() => {
            switch (true) {
            case !payload.shortName: return 'Branch Short Name required';
            case payload.shortName.length < 2: return 'Branch Short Name too short';
            case payload.shortName.length > 6: return 'Branch Short Name too long';
            default: return '';
            }
        })();

        const errors = removeFalseyValuesFromObject({
            name,
            shortName,
        });

        const firstError = fieldOrder.find((field) => !!errors[field]);

        this.setState({
            errors,
            firstError,
        });

        console.log('>>> End Validating Branch');

        if (isEmpty(errors)) return true;

        return false;
    };

    updateItemSelectCheckbox = (name) => (event) => {
        const newState = event.target.checked ? 1 : 0;
        let newSelectData = [];
        if (newState === 1) {
            newSelectData = [
                ...this.state.selectData,
                ...[name],
            ];
        } else {
            newSelectData = this.state.selectData.filter((itemCode) => itemCode !== name);
        }

        this.setState({
            selectData: [
                ...newSelectData,
            ],
        });
    };

    render() {
        const {
            history,
        } = this.props;
        const {
            allBranches,
            columnLookup,
            controls,
            errors,
            filters,
            keyLookup,
            branches,
            branchesParentSummary,
            selectData,
            totalBranchesQty,
        } = this.state;

        return (
            <>
                <Layout pageTitle="Branches">
                    <CssBaseline />
                    <BranchesSummary
                        filters={filters}
                        branchesParentSummary={branchesParentSummary}
                        removeFilter={this.removeFilter}
                        setFilters={this.setFilters}
                        totalBranchesQty={totalBranchesQty}
                    />
                    <BranchesTable
                        addBranch={this.addBranch}
                        allBranches={allBranches}
                        columnLookup={columnLookup}
                        controls={controls}
                        editItems={this.editItems}
                        editBranch={this.editBranch}
                        errors={errors}
                        fetchItems={this.fetchItems}
                        filters={filters}
                        keyLookup={keyLookup}
                        branches={branches}
                        removeFilter={this.removeFilter}
                        selectData={selectData}
                        setControls={this.setControls}
                        setFilters={this.setFilters}
                        totalBranchesQty={totalBranchesQty}
                        updateItemSelectCheckbox={this.updateItemSelectCheckbox}
                    />
                </Layout>
            </>
        );
    }
}

Branches.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withStyles(styles)(Branches);
