// src/ui/theme/index.js

import { createMuiTheme } from '@material-ui/core/styles';
import Cookie from 'js-cookie';

const companyCode = parseInt(Cookie.get('companyCode') || 1);
let palette;
let themeName;

switch (companyCode) {
case 1:
    palette = {
        primary: {
            main: '#344955', lighter: '#7e919d', testFail: '#f44336', testPass: '#4caf50', warm: '#ffc107',
        },
        secondary: { main: '#F9AA33' },
        staff: {
            light: '#2196f3',
            main: '#2196f3',
            dark: '#2196f3',
            contrastText: '#2196f3',
        },
    };

    themeName = 'Oxford Blue Sea Buckthorn Addax';
    break;
case 2:
    palette = {
        primary: {
            main: '#026aa7',
            light: '#0079bf',
            lighter: '#5098c1',
            dark: '#172b4d',
            testFail: '#f44336',
            testPass: '#4caf50',
            warm: '#ffc107',
        },
        secondary: { main: '#F9AA33', light: '#ffa733', dark: '#b26500' },
        staff: {
            light: '#7986cb',
            main: '#3f51b5',
            dark: '#303f9f',
            contrastText: '#fff',
        },
    };

    themeName = 'Oxford Blue Sea Buckthorn Addax';
    break;
case 3:
    palette = {
        primary: {
            main: '#9c27b0',
            light: '#af52bf',
            lighter: '#c786d3',
            dark: '#6d1b7b',
            testFail: '#f44336',
            testPass: '#4caf50',
            warm: '#ffc107',
        },
        secondary: { main: '#F9AA33', light: '#ffa733', dark: '#b26500' },
        staff: {
            light: '#7986cb',
            main: '#3f51b5',
            dark: '#303f9f',
            contrastText: '#fff',
        },
    };

    themeName = 'Oxford Blue Sea Buckthorn Addax';
    break;
}

export default createMuiTheme({
    palette,
    themeName,
    typography: {
        useNextVariants: true,
  	},
});
