import React from 'react';
import {
    CssBaseline,
    Chip,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import GoogleMapReact from 'google-map-react';
import {Bar} from 'react-chartjs-2';

import Layout from './Layout';
import fetch from '../utilities/fetch';

const stylesx = require('../constants/googleMapsStyle.json');

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        marginBottom: theme.spacing.unit * 3,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    authorisedPaymentChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '87px',
    },
    declinedPaymentChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '87px',
    },
    unknownPaymentChip: {
        minWidth: '87px',
    },
    chip: {
        height: 21,
        borderRadius: 5,
    },
    paper: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
    },
    graph: {
        width: 800,
    },
});

const barData = {
    // labels: ['Red', 'Blue'],
    datasets: [{
        label: 'Washers',
        // data: [
        //     {x:'2016-12-21', y:20},
        //     {x:'2016-12-22', y:10},
        //     {x:'2016-12-23', y:23},
        //     {x:'2016-12-24', y:5},
        //     {x:'2016-12-25', y:43},
        //     {x:'2016-12-26', y:32},
        // ],
        borderWidth: 1,
        backgroundColor: '#F9AA33',
        borderColor: 'rgba(249, 170, 51, 1)',
    }]
};

const barOptions = {
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            }
        }],
        xAxes: [{
            type: 'time',
            time: {
                unit: 'day'
            }
        }]
    }
};

class LeadsMap extends React.Component {
    state = {
        leadsGraphData: [],
        leadsGeometry: null,
    }

    async componentDidMount() {
        let data;
        data = await fetch('https://api.portal2.payweeklycarpets.co.uk/core/campaign/1000/leads/graph').then(response => response.json());
        this.setState({ leadsGraphData: data });
        barData.datasets[0].data = data;
        data = await fetch('https://api.portal2.payweeklycarpets.co.uk/core/leads/geometry').then(response => response.json());
        const options = {
            radius: 17,
            opacity: .6,
            dissipating:true,
            maxIntensity:15,

        };
        data.options = options;
        console.log('leadsGeometry:', data);
        this.setState({ leadsGeometry: data });
    }

    render() {
        const { classes } = this.props;
        const {
            leadsGeometry,
        } = this.state;

        return (
            <Layout pageTitle="Leads Heatmap">
                <CssBaseline />

                <div className={classes.graph}>
                    <Bar
                        data={barData}
                        options={barOptions}
                        />
                </div>
                <Grid container spacing={0}>
                    <Grid item xs={12}>

                        <Paper className={classes.paper} style={{ height: '800px', width: '1000px' }}>
                            {leadsGeometry && (
                                <GoogleMapReact

                                    bootstrapURLKeys={
                                        {
                                            key: 'AIzaSyA6ab1HSjU6dWSWQGevVUZrq61ynXzTujk',
                                        }
                                    }
                                    defaultCenter={{
                                        lat: 54.66,
                                        lng: -4.95,
                                    }}
                                    options={{
                                        disableDefaultUI: true, // disable default map UI
                                        draggable: true, // make map draggable
                                        keyboardShortcuts: false, // disable keyboard shortcuts
                                        scaleControl: false, // allow scale controle
                                        scrollwheel: true, // allow scroll wheel
                                        styles: stylesx, // change default map styles
                                    }}

                                    defaultZoom={6}
                                    heatmapLibrary
                                    heatmap={
                                        this.state.leadsGeometry
                                    }
                                />
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Layout>
        );
    }
}

export default withStyles(styles)(LeadsMap);
