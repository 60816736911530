import React from 'react';
import {
    Button,
    Card,
    CardActionArea,
    CardMedia,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Paper,
    Grid,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import moment from 'moment-timezone';
import Slider from 'react-slick';
import HybridTextField from '../../hybridTextField';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../slick-carousel/slick-theme.css';

const styles = theme => ({
    activeIcon: {
        color: '#4caf50',
    },
    alert: {
        color: '#f1180b',
    },
    card: {
        height: 150,
        width: 150,
    },
    dialogButton: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    hybridTextIconRight: {
        fontSize: 16,
        marginLeft: theme.spacing.unit * 1,
        transform: 'translate(0%, 13%)',
    },
    media: {
        height: 150,
    },
    notSetIcon: {
        color: '#d7d7d7',
    },
    paper: {
        alignItems: 'center',
        border: '1px solid #e0e0e0',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        width: '100%',
    },
    image: {
        height: 400,
        // width: 700,
        borderStyle: 'dotted',
    },
    imagesContainer: {
        borderBottom: '1px solid #eaeaea',
        marginBottom: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 5,
        width: '100%',
    },
    progress: {
        opacity: '.5',
    },
    progressContainer: {
        marginBottom: theme.spacing.unit * 3,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        width: 60,
    },
    slickContainer: {
        background: '#419be0',
        padding: 40,
    },
    slickSlide: {
        img: {
            margin: 'auto',
        },
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        // marginBottom: 4,
    },
    imageDiv: {
        // backgroundImage: 'url("https://pwc-images.s3-eu-west-1.amazonaws.com/room1.jpeg")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        height: 400,
        cursor: 'zoom-in',
    },
    imageZoom: {
        maxHeight: 1550,
    },
    dialogDownloadButton: {
        color: 'rgba(0, 0, 0, 0.87)',
        padding: '8px',
        fontSize: '0.875rem',
        minWidth: '64px',
        boxSizing: 'border-box',
        minHeight: '36px',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '500',
        lineHeight: '1.5',
        borderRadius: '4px',
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        border: '0',
        margin: '0',
        cursor: 'pointer',
        display: 'inline-flex',
        outline: 'none',
        position: 'relative',
        alignItems: 'center',
        userSelect: 'none',
        verticalAlign: 'middle',
        justifyContent: 'center',
        textDecoration: 'none',
        backgroundColor: 'transparent',
        webkitAppearance: 'none',
        webkitTapHighlightColor: 'transparent',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '8px 24px',
        margin: '0px 0px 0px 0px !important',
    },
    dialogActionsRight: {
        textAlign: 'right',
    },
});

class ContactAgreementImages extends React.Component {
    state = {
        showImageZoomDialog: false,
    }

    handleImageZoomDialogClose = () => {
        this.setState({
            showImageZoomDialog: false,
        });
    }

    handleImageZoomDialogOpen = (url) => {
        this.setState({
            currentImageUrl: url,
            showImageZoomDialog: true,
        });
    }


    render() {
        const {
            classes,
            images,
        } = this.props;
        const {
            currentImageUrl,
            showImageZoomDialog,
        } = this.state;

        const settings = {
            // centerMode: true,
            dots: true,
            lazyLoad: true,
            // variableWidth: true,
        };

        return (
            <div className={classes.imagesContainer}>
                <div className={classes.textFieldLabel}>
                    Images
                </div>
                {images !== null
                    ? (
                        <React.Fragment>
                            {(images || []).length !== 0
                                ? (
                                    <div className="slickContainer">
                                        <Slider {...settings}>
                                            {images.sort((a, b) => a.createdDateTime - b.createdDateTime).map(image => (
                                                <div
                                                    key={image.code}
                                                >
                                                    <section
                                                        onClick={() => this.handleImageZoomDialogOpen(image.link)}
                                                        className={`${classes.imageDiv}`}
                                                        style={{ backgroundImage: `url(${image.link})` }}
                                                    />
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                )
                                : (
                                    <Typography variant="body1">
                                        None
                                    </Typography>
                                )
                            }

                            <Dialog
                                open={showImageZoomDialog}
                                onClose={this.handleImageZoomDialogClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                maxWidth="md"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <React.Fragment>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <img
                                                        className={classes.imageZoom}
                                                        alt=""
                                                        src={currentImageUrl}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    </div>
                                </DialogContent>
                                <DialogActions className={classes.dialogActions}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={6}>
                                            {currentImageUrl
                                                ? (
                                                    <a
                                                        href={`https://api.portal2.payweeklycarpets.co.uk/core/v2/files/image/${currentImageUrl.substring(currentImageUrl.lastIndexOf('/') + 1)}`}
                                                        className={classes.dialogDownloadButton}
                                                        download
                                                    >
                                                        DOWNLOAD
                                                    </a>
                                                ) : ''
                                            }
                                        </Grid>
                                        <Grid item xs={6} className={classes.dialogActionsRight}>
                                            <Button
                                                onClick={this.handleImageZoomDialogClose}
                                                color="primary"
                                                autoFocus
                                            >
                                        Close
                                            </Button>
                                        </Grid>
                                    </Grid>

                                </DialogActions>
                            </Dialog>


                        </React.Fragment>
                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )
                }
            </div>
        );
    }
}

ContactAgreementImages.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(ContactAgreementImages));
