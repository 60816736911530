import React from 'react';
import {
    Button,
    // Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    // DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    // FormHelperText,
    FormLabel,
    Grid,
    Typography,
    // InputLabel,
    // MenuItem,
    // OutlinedInput,
    // Paper,
    Radio,
    RadioGroup,
    // Select,
    TextField,
    // Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import moment from 'moment-timezone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import HybridTextField from '../hybridTextField';
import titalise from '../../utilities/titalise';
import Tile from '../../containers/tile';

const styles = theme => ({
    // row: {
    //     '&:nth-of-type(odd)': {
    //         backgroundColor: theme.palette.background.default,
    //     },
    // },
    // tableHead: {
    //     fontWeight: 'bold',
    //     fontSize: 14,
    // },
    // paper: {
    //     width: '100%',
    //     marginTop: theme.spacing.unit * 3,
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     overflowX: 'auto',
    //     padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    // },
    // tileBottomDivider: {
    //     marginTop: theme.spacing.unit * 3,
    //     // marginBottom: theme.spacing.unit * 2,
    // },
    tileDivider: {
        marginBottom: theme.spacing.unit * 2.5,
        marginLeft: theme.spacing.unit * -4,
        marginRight: theme.spacing.unit * -4,
        marginTop: theme.spacing.unit * 2,
    },
    // bottomBarContainer: {
    //     width: '100%',
    //     textAlign: 'right',
    // },
    // textButton: {
    //     marginTop: theme.spacing.unit * 1.5,
    //     marginBottom: 0,
    // },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    // nameField: {
    //     width: 350,
    // },
    // addressField: {
    //     width: 250,
    // },
    // postcodeField: {
    //     width: 120,
    // },
    // countryField: {
    //     width: 200,
    //     marginLeft: theme.spacing.unit * 3,
    // },
    // emailField: {
    //     width: 350,
    // },
    // rightOfFieldButton: {
    //     marginTop: 10,
    //     marginLeft: theme.spacing.unit * 3,
    //     color: 'rgba(0, 121, 255, 0.87)',
    // },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
        minWidth: 300,
    },
    dialogRadioGroup: {
        marginLeft: theme.spacing.unit * 2,
    },
    // formHelperText: {
    //     color: '#f44336',
    // },
    // contactLocationIcon: {
    //     color: 'red',
    //     fontSize: 30,
    //     position: 'absolute',
    //     transform: 'translate(-50%, -100%)',
    // },
    // branchLocationIcon: {
    //     color: 'blue',
    //     fontSize: 30,
    //     position: 'absolute',
    //     transform: 'translate(-50%, -100%)',
    // },
    // progressContainer: {
    //     marginLeft: 'auto',
    //     marginRight: 'auto',
    //     marginTop: theme.spacing.unit * 3,
    //     marginBottom: theme.spacing.unit * 3,
    //     width: 60,
    // },
    // progress: {
    //     opacity: '.5',
    // },
    // inputLabel: {
    //     backgroundColor: '#fff',
    //     paddingLeft: 8,
    //     marginLeft: -5,
    //     paddingRight: 8,
    // },
    // menuFormControl: {
    //     marginBottom: theme.spacing.unit * 1,
    //     marginTop: theme.spacing.unit * 2,
    // },
    // agents: {
    //     marginRight: theme.spacing.unit * 3,
    // },
    // 'agents:not(:first-child)': {
    //     marginLeft: 0,
    //     backgroundColor: 'red',
    // },
});

class PostcodeLookupAgent extends React.Component {
    state = {
        disableSendAgentDetailsButton: false,
        sendAgentDetailsForm: {
            agentContactCode: null,
        },
        showSendAgentDetailsDialog: false,
    }

    handleSendAgentDetailsClick = () => {
        this.setState(state => ({
            showSendAgentDetailsDialog: true,
            sendAgentDetailsForm: {
                ...state.sendAgentDetailsForm,
                agentContactCode: `${this.props.foundAgents[0].code}`,
            },
        }));
    }

    handleSendAgentDetailsCloseDialogClick = () => {
        this.setState({
            showSendAgentDetailsDialog: false,
        });
    }

    updateSendAgentDetailsData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        switch (name) {
        case 'mobileTelephoneNumber':
            formattedValue = value.replace(/[^0-9]/gi, '');
            break;
        default:
            formattedValue = value;
            break;
        }

        event.target.value = formattedValue;

        this.setState(state => ({
            sendAgentDetailsForm: {
                ...state.sendAgentDetailsForm,
                [name]: formattedValue,
            },
        }));
    }

    handleSendAgentDetails = () => {
        this.setState({
            disableSendAgentDetailsButton: true,
        });
        const payload = {
            to: this.state.sendAgentDetailsForm.mobileTelephoneNumber,
            agent: this.props.foundAgents.find(foundAgent => foundAgent.code === parseInt(this.state.sendAgentDetailsForm.agentContactCode, 10)),
        };

        this.props.sendAgentDetails(payload).then(() => {
            this.setState({
                showSendAgentDetailsDialog: false,
                disableSendAgentDetailsButton: false,
            });
            this.props.enqueueSnackbar('Agent details sent', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Send agent details failed', { variant: 'error' });
            this.setState({
                disableSendAgentDetailsButton: false,
            });
        });
    }

    render() {
        const {
            agentsLoading,
            classes,
            errors,
            foundAgents,
        } = this.props;
        const {
            disableSendAgentDetailsButton,
            sendAgentDetailsForm,
            showSendAgentDetailsDialog,
        } = this.state;

        return (
            <Tile
                tileTitle="Agent"
                tileMenuItems={[
                    {
                        action: this.handleSendAgentDetailsClick,
                        label: 'SMS Agent Details',
                        section: 1,
                        disabled: agentsLoading || !foundAgents,
                    },
                ]}
                style
            >
                { !agentsLoading
                    ? (
                        <React.Fragment>
                            {foundAgents !== null
                                ? (
                                    <React.Fragment>
                                        {foundAgents.map((foundAgent, i) => (
                                            <React.Fragment>
                                                {i > 0
                                                    ? (
                                                        <Divider className={classes.tileDivider} />
                                                    )
                                                    : ''
                                                }
                                                <Grid container spacing={0}>
                                                    <Grid item xs={4}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField label="Name" text={`${foundAgent.firstname} ${foundAgent.surname}`} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField label="Notes" text={foundAgent.contactAgent.notes} alert />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={4}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField label="Mobile Telephone Number" text={foundAgent.mobileTelephoneNumber} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField label="Email Address" text={foundAgent.emailAddress} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <HybridTextField label="Postcode Districts" text={foundAgent.contactAgent.postcodeDistricts.join(', ')} />
                                                    </Grid>
                                                </Grid>
                                            </React.Fragment>
                                        ))}

                                    </React.Fragment>
                                )
                                : (
                                    <Typography variant="body1">
                                        No agent found
                                    </Typography>
                                )
                            }

                            <Dialog
                                open={showSendAgentDetailsDialog}
                                onClose={this.handleSendAgentDetailsCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                {foundAgents !== null
                                    ? (

                                        <DialogContent className={classes.dialogContent}>
                                            <div className={classes.dialogContentContainer}>
                                                <React.Fragment>
                                                    <Typography variant="h6" gutterBottom>
                                                        Send Agent Details
                                                    </Typography>

                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12} className={classes.agents}>
                                                            <FormControl component="fieldset" className={classes.formControl}>
                                                                <RadioGroup
                                                                    name="agentContactCode"
                                                                    className={classes.dialogRadioGroup}
                                                                    value={sendAgentDetailsForm.agentContactCode}
                                                                    onChange={this.updateSendAgentDetailsData}
                                                                >
                                                                    {foundAgents.map(foundAgent => (
                                                                        <FormControlLabel
                                                                            value={`${foundAgent.code}`}
                                                                            control={<Radio />}
                                                                            label={`${foundAgent.firstname} ${foundAgent.surname}`}
                                                                        />
                                                                    ))}
                                                                </RadioGroup>
                                                            </FormControl>
                                                            <Grid container spacing={0}>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        className={classes.formControl}
                                                                        name="mobileTelephoneNumber"
                                                                        id="mobileTelephoneNumber"
                                                                        onChange={this.updateSendAgentDetailsData}
                                                                        value={sendAgentDetailsForm.mobileTelephoneNumber}
                                                                        label="Mobile Telephone Number"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                        error={!!errors.mobileTelephoneNumber}
                                                                        helperText={errors.mobileTelephoneNumber}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                spacing={0}
                                                                direction="column"
                                                                alignItems="center"
                                                                justify="center"
                                                            >
                                                                <Grid item xs={12}>
                                                                    <Button
                                                                        className={classes.dialogButton}
                                                                        onClick={this.handleSendAgentDetails}
                                                                        disabled={!sendAgentDetailsForm.mobileTelephoneNumber || disableSendAgentDetailsButton || !sendAgentDetailsForm.agentContactCode}
                                                                    >
                                                                        SEND
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>
                                                </React.Fragment>
                                            </div>
                                        </DialogContent>
                                    )
                                    : (
                                        <Typography variant="body1">
                                            No agent found
                                        </Typography>
                                    )
                                }
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleSendAgentDetailsCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                        </React.Fragment>
                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )
                }
            </Tile>


        );
    }
}


PostcodeLookupAgent.propTypes = {
    classes: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(PostcodeLookupAgent));
