import React from 'react';
import {
    Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const styles = (theme) => ({
    tag: {
        backgroundColor: '#00bcd4',
        borderRadius: 4,
        boxSizing: 'border-box',
        color: '#fff',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.4,
        lineHeight: 4.75,
        marginRight: theme.spacing.unit * 3,
        minWidth: 64,
        padding: '10px 16px',
        textTransform: 'uppercase',
        verticalAlign: 'middle',
    },
    alert: {
        backgroundColor: '#ff0000',
        borderRadius: 4,
        boxSizing: 'border-box',
        color: '#fff',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.4,
        lineHeight: 4.75,
        marginLeft: theme.spacing.unit * 3,
        minWidth: 64,
        padding: '10px 16px',
        textTransform: 'uppercase',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
    },
    expired: {
        backgroundColor: '#F9AA33',
        borderRadius: 4,
        boxSizing: 'border-box',
        color: '#fff',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.4,
        lineHeight: 4.75,
        // marginLeft: theme.spacing.unit * 3,
        minWidth: 64,
        padding: '10px 16px',
        textTransform: 'uppercase',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
    },
    badgeContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    alertContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
        textAlign: 'right',
    },
    expiredContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
        textAlign: 'center',
    },
    alertIcon: {
        marginRight: 8,
    },
    expiredIcon: {
        marginRight: 8,
    },
});

class ManufacturerSupplierBadges extends React.Component {
    state = {
    };

    render() {
        const {
            classes,
            manufacturerSupplier,
            manufacturerSupplierLoading,
        } = this.props;

        const badges = [
            {
                show: !manufacturerSupplierLoading && manufacturerSupplier.manufacturer,
                label: '#MANUFACTURER',
            },
            {
                show: !manufacturerSupplierLoading && manufacturerSupplier.supplier,
                label: '#SUPPLIER',
            },
        ];

        const alerts = [
            {
                show: !manufacturerSupplierLoading && !manufacturerSupplier.recordComplete,
                label: 'RECORD INCOMPLETE',
            },
        ];

        const expireds = [
            {
                show: !manufacturerSupplierLoading && manufacturerSupplier.expired,
                label: 'EXPIRED',
            },
        ];

        return (
            <>
                <Grid container spacing={0}>
                    <Grid item xs={5}>

                        {badges.filter((badge) => badge.show).length > 0
                            ? (
                                <div className={classes.badgeContainer}>
                                    {badges.filter((badge) => badge.show).map((badge) => (
                                        <React.Fragment key={badge.label}>
                                            <span className={classes.tag}>{badge.label}</span>
                                        </React.Fragment>
                                    ))}
                                </div>
                            )
                            : null}
                    </Grid>
                    <Grid item xs={2}>
                        {expireds.filter((expired) => expired.show).length > 0
                            ? (
                                <div className={classes.expiredContainer}>
                                    {expireds.filter((expired) => expired.show).map((expired) => (
                                        <span className={classes.expired} key={expired.label}>
                                            <FontAwesomeIcon icon={faExclamationTriangle} inverse color="red" className={classes.expiredIcon} />
                                            {expired.label}
                                        </span>
                                    ))}
                                </div>
                            )
                            : null}
                    </Grid>
                    <Grid item xs={5}>
                        {alerts.filter((alert) => alert.show).length > 0
                            ? (
                                <div className={classes.alertContainer}>
                                    {alerts.filter((alert) => alert.show).map((alert) => (
                                        <span className={classes.alert} key={alert.label}>
                                            <FontAwesomeIcon icon={faExclamationTriangle} inverse color="red" className={classes.alertIcon} />
                                            {alert.label}
                                        </span>
                                    ))}
                                </div>
                            )
                            : null}
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default withStyles(styles)(ManufacturerSupplierBadges);
