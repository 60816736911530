import React from 'react';
import {
    Chip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import DeliveryNoteItemsAddDialog from './deliveryNoteItemsAddDialog';

const styles = theme => ({
    chip: {
        marginRight: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
});

class ItemsTableFilters extends React.Component {
    state = {

    }

    render() {
        const {
            classes,
            filters,
            removeFilter,
        } = this.props;

        const chips = Object.keys(filters || {}).map(key => (
            <Chip
                key={key}
                label={`${key}: ${filters[key]}`}
                onDelete={() => removeFilter(`${key}`)}
                className={classes.chip}
                color="secondary"
            />
        ));

        return (
            <React.Fragment>
                {filters !== null && (
                    <React.Fragment>
                        {chips}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

ItemsTableFilters.propTypes = {
    classes: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    removeFilter: PropTypes.func.isRequired,
};

export default withStyles(styles)(ItemsTableFilters);
