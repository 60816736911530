import React from 'react';
import {
    Button,
    Card,
    CardContent,
    CardActions,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';


const styles = theme => ({
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    formHelperText: {
        color: '#f44336',
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    formInstructions: {
        marginTop: theme.spacing.unit * 3,
        // marginBottom: theme.spacing.unit * 2,
    },
    nextButton: {
        width: '90%',
    },
    nextButtonContainer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100vw',
        marginBottom: theme.spacing.unit * 2,
        textAlign: 'center',
    },
    formActionButton: {
        marginTop: theme.spacing.unit * 2,
    },
    tableCell: {
        padding: 0,
    },
    rightOfFieldButton: {
        marginTop: 25,
        marginLeft: theme.spacing.unit * 2,
    },
    textField: {
        width: 140,
    },
    label: {
        fontSize: 12,
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: 2,
    },
    text: {
        fontSize: 19,
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.87)',
        minHeight: 19,
        whiteSpace: 'pre-line',
    },
    itemBlock: {
        padding: theme.spacing.unit * 2,
    },
    itemBlockCard: {
        paddingTop: theme.spacing.unit * 1,
    },
    hybridTextField: {
        marginBottom: theme.spacing.unit * 1,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 1,
    },
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    dialogContentContainer: {
        minWidth: 230,
    },
    card: {
        marginBottom: theme.spacing.unit * 3,
    },
    cardPlus: {
        marginBottom: theme.spacing.unit * 10,
    },
    cardAddItemContent: {
        paddingTop: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3,
        paddingBottom: 0,
        paddingLeft: theme.spacing.unit * 3,
    },
    cardAddItemActions: {
        paddingTop: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 1,
        paddingLeft: theme.spacing.unit * 1,
    },
    cardAddItemButtonText: {
        fontWeight: 400,
        fontSize: 16,
        color: 'rgba(0, 0, 0, 0.54)',
    },
    cardAddItemTitle: {
        fontWeight: 500,
    },
    cardAddItemPlus: {
        fontWeight: 100,
        fontSize: 70,
        color: 'rgba(0, 0, 0, 0.54)',
        lineHeight: 0.5,
        marginTop: 10,
        marginBottom: 8,
    },
    pageTitle: {
        marginBottom: theme.spacing.unit * 3,
    },
    trashIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 20,
        marginLeft: 'auto',
    },
    stockCodePrefix: {
        color: theme.palette.secondary.main,
    },
    passButton: {
        backgroundColor: '#56c148',
    },
    failButton: {
        backgroundColor: '#ce2929',
    },
    controlDeviceTestDialogContent: {
        height: 275,
    },
});

class DeliveryItem extends React.Component {
    state = {
        showAddItemDialog: false,
        disableSubmitAddItemButton: true,
        disableControlDeviceTestSkipButton: false,
        addItemCode: null,
        testStage: 1,
    }

    handleAddItemCloseButton = () => {
        this.setState({
            addItemCode: null,
            showAddItemDialog: false,
        });
    }

    handleAddItemButton = () => {
        this.setState({
            showAddItemDialog: true,
        });
    }

    updateAddItemData = (event) => {
        const { value, name } = event.target;
        const formattedValue = value.replace(/\D/g, '');
        this.setState(state => ({
            addItemCode: formattedValue,
            disableSubmitAddItemButton: formattedValue.length < 4,
        }));
    }

    handleSubmitAddItem = async (event) => {
        event.preventDefault();
        this.setState({
            disableSubmitAddItemButton: true,
        });

        await this.props.addItem(this.state.addItemCode).then(() => {
            this.setState({
                showAddItemDialog: false,
                disableSubmitAddItemButton: false,
            });
        }).catch(() => {
            this.setState({
                disableSubmitAddItemButton: false,
            });
        });
    }

    handleNextButtonClick = (event) => {
        this.props.setItems(this.state.items).then(() => {
        }).catch(() => {
            console.log('Error setting customer');
        });
    }

    render() {
        const {
            classes,
            delivery,
            disableNextButton,
        } = this.props;
        const {
            showAddItemDialog,
            addItemCode,
            items,
            disableSubmitAddItemButton,
            disableControlDeviceTestSkipButton,
        } = this.state;

        const untestedItems = this.props.items.filter(item => (item.testStatus !== 'Pass' && item.testStatus !== 'Fail' && item.testStatus !== 'Skipped'));

        const HybridTextField = ({ label, text }) => (
            <div className={classes.hybridTextField}>
                <div
                    className={classes.label}
                >
                    {label}
                </div>
                <div
                    className={classes.text}
                >
                    {text}
                </div>
            </div>
        );

        return (
            <React.Fragment>
                <Typography variant="h6" className={classes.pageTitle}>
                    Items
                </Typography>

                {this.props.items.map((item, index) => (
                    <Card
                        key={item.code}
                        className={classes.card}
                    >
                        <CardContent className={classes.cardAddItemContent}>
                            <Typography variant="h5" component="h2" className={classes.cardAddItemTitle}>
                                <span className={classes.stockCodePrefix}>SC</span>
                                {item.code}
                            </Typography>
                            <Grid container spacing={0} className={classes.itemBlockCard}>
                                <Grid item xs={6}>
                                    <HybridTextField label="Manufacturer" text={item.manufacturer} />
                                </Grid>
                                <Grid item xs={6}>
                                    <HybridTextField label="Model Number" text={item.modelNo} />
                                </Grid>
                                <Grid item xs={6}>
                                    <HybridTextField label="Control Device" text={item.controlDeviceIccid ? 'Yes' : 'No'} />
                                </Grid>
                                <Grid item xs={6}>
                                    <HybridTextField label="Test Status" text={item.testStatus} />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions className={classes.cardAddItemActions}>
                            <IconButton
                                className={classes.trashIcon}
                                onClick={() => this.props.removeItem(item.code)}
                            >
                                <FontAwesomeIcon
                                    icon={faTrashAlt}
                                />
                            </IconButton>
                        </CardActions>
                    </Card>
                ))}

                <Card
                    onClick={this.handleAddItemButton}
                    className={classes.cardPlus}
                >
                    <CardContent>
                        <Typography
                            className={classes.cardAddItemPlus}
                            align="center"
                        >
                        +
                        </Typography>
                        <Typography
                            className={classes.cardAddItemButtonText}
                            align="center"
                        >
                        Add item to delivery
                        </Typography>
                    </CardContent>
                </Card>

                {this.props.items.length > 0
                    ? (
                        <div className={classes.nextButtonContainer}>
                            <Button
                                onClick={this.handleNextButtonClick}
                                variant="contained"
                                className={classes.nextButton}
                                disabled={disableNextButton}
                            >
                                NEXT
                            </Button>
                        </div>
                    )
                    : null
                }

                <Dialog
                    open={showAddItemDialog}
                    onClose={this.handleCustomerDetailsCloseDialogClick}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.dialogContentContainer}>
                            <React.Fragment>
                                <Typography variant="h6" gutterBottom>
                                Add Item
                                </Typography>
                                <DialogContentText>
                                Enter the item Stock Code and click [SUBMIT].
                                </DialogContentText>
                                <form onSubmit={this.handleSubmitAddItem}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <TextField
                                                onChange={this.updateAddItemData}
                                                className={classes.formControl}
                                                name="addItemCode"
                                                id="addItemCode"
                                                label="Item Stock Code"
                                                margin="normal"
                                                variant="outlined"
                                                value={addItemCode || ''}
                                                fullWidth
                                                required
                                                error={!!this.props.addItemError}
                                                helperText={this.props.addItemError}
                                                autoFocus
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button
                                        variant="contained"
                                        className={classes.dialogButton}
                                        type="submit"
                                        disabled={disableSubmitAddItemButton}
                                    >
                                    SUBMIT
                                    </Button>
                                </form>
                            </React.Fragment>
                        </div>
                    </DialogContent>
                    <Divider className={classes.dialogActionsDivider} />
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            onClick={this.handleAddItemCloseButton}
                            color="primary"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                {untestedItems.length > 0
                && (
                    <Dialog
                        open
                        onClose={this.handleCustomerDetailsCloseDialogClick}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent className={`${classes.dialogContent} ${classes.controlDeviceTestDialogContent}`}>
                            <div className={classes.dialogContentContainer}>
                                <React.Fragment>
                                    <Typography variant="h6" gutterBottom>
                                    Control Device
                                    </Typography>
                                    { untestedItems[0].testStatus === 'wait'
                                        ? (
                                            <React.Fragment>
                                                <div className={classes.progressContainer}>
                                                    <CircularProgress className={classes.progress} variant="indeterminate" />
                                                </div>
                                                <DialogContentText align="center">
                                                Please wait
                                                </DialogContentText>
                                            </React.Fragment>
                                        )
                                        : null
                                    }
                                    { untestedItems[0].testStatus === 'Waiting'
                                        ? (
                                            <React.Fragment>
                                                <DialogContentText>
                                                This item has a control device installed. Ensure the
                                                item is plugged in and switched ON.
                                                </DialogContentText>
                                                <Button
                                                    onClick={() => this.props.handleStartTestButtonClick(untestedItems[0].code, untestedItems[0].controlDeviceIccid, untestedItems[0].controlDeviceTypeCode)}
                                                    variant="contained"
                                                    className={classes.dialogButton}
                                                    disabled={false}
                                                    fullWidth
                                                >
                                                START TEST
                                                </Button>
                                            </React.Fragment>
                                        )
                                        : null
                                    }
                                    { untestedItems[0].testStatus === 'result'
                                        ? (
                                            <React.Fragment>
                                                <DialogContentText>
                                                The item was commanded to switch OFF and back ON.
                                                </DialogContentText>
                                                <Grid container spacing={16} className={classes.itemBlockCard}>
                                                    <Grid item xs={6}>
                                                        <Button
                                                            onClick={() => this.props.handlePassTestButtonClick(untestedItems[0].code)}
                                                            variant="contained"
                                                            className={[classes.dialogButton, classes.passButton]}
                                                            type="submit"
                                                            disabled={false}
                                                            fullWidth
                                                        >
                                                    PASS
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button
                                                            onClick={() => this.props.handleFailTestButtonClick(untestedItems[0].code)}
                                                            variant="contained"
                                                            className={[classes.dialogButton, classes.failButton]}
                                                            type="submit"
                                                            disabled={false}
                                                            fullWidth
                                                        >
                                                    FAIL
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Button
                                                    onClick={() => this.props.handleStartTestButtonClick(untestedItems[0].code, untestedItems[0].controlDeviceIccid, untestedItems[0].controlDeviceTypeCode)}
                                                    variant="contained"
                                                    className={classes.dialogButton}
                                                    disabled={false}
                                                    fullWidth
                                                >
                                            RESTART TEST
                                                </Button>
                                            </React.Fragment>
                                        )
                                        : null
                                    }
                                </React.Fragment>
                            </div>
                        </DialogContent>
                        <Divider className={classes.dialogActionsDivider} />
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                onClick={() => this.props.handleSkipTestButtonClick(untestedItems[0].code)}
                                color="primary"
                                disabled={disableControlDeviceTestSkipButton}
                            >
                            SKIP
                            </Button>
                        </DialogActions>
                    </Dialog>
                )
                }


            </React.Fragment>
        );
    }
}

const customersShape = {
    code: PropTypes.number,
    creationDate: PropTypes.string,
    fullAddress: PropTypes.string,
    fullName: PropTypes.string,
    lastPaymentTransactionStatus: PropTypes.string,
    shortAddress: PropTypes.string,
};

DeliveryItem.propTypes = {
    classes: PropTypes.object.isRequired,
    delivery: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(DeliveryItem));
