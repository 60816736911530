import React from 'react';

import Cookie from 'js-cookie';

import {
    Button,
    CssBaseline,
    FormControl,
    Grid,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import WarningIcon from '@material-ui/icons/Warning';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import fetch from '../utilities/fetch';

const { REACT_APP_API_SERVER } = process.env;

const styles = theme => ({
    main: {
        maxWidth: 300,
        textAlign: 'center',
        display: 'block', // Fix IE 11 issue.
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    snackbar: {
        backgroundColor: theme.palette.error.dark,
    },
    snackbarIcon: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
        fontSize: 20,
        // margin: theme.spacing.unit,
    },
    snackbarMessage: {
        fontSize: 'inherit',
        // marginBottom: theme.spacing.unit,
        display: 'flex',
        alignItems: 'center',
    },
    logo: {
        margin: theme.spacing.unit * 5,
        width: 128,
    },
    inputBox: {
        backgroundColor: '#fff',
    },
    instructions: {
        marginTop: theme.spacing.unit * 2,
    },
});

class Login extends React.Component {
    state = {
        disableSubmitButton: false,
        error: false,
        forgottenPassword: false,
        forgottenPasswordData: {
            username: '',
        },
        loading: false,
        snackbarOpen: false,
        // windowsLocationHref: '',
    }

    // componentDidMount() {
    //     this.setState({ windowsLocationHref: windows.location.href });
    // }

    handleSnackbarClose = (event, reason) => {
        this.setState({
            snackbarOpen: false,
        });
    }

    handleForgottenPasswordClick = () => {
        this.setState({
            forgottenPassword: true,
        });
    }

    updateForgottenPasswordData = (event) => {
        const { name, value } = event.target;

        event.target.value = value;

        const formattedValue = value.replace(' ', '');

        this.setState(state => ({
            forgottenPasswordData: {
                ...state.forgottenPasswordData,
                [name]: formattedValue,
            },
        }));
    }

    handleForgottenPassword = (event) => {
        event.preventDefault();
        this.setState({
            disableSubmitButton: true,
        });
        this.sendForgottenPasswordEmail(this.state.forgottenPasswordData).then(() => {
            this.setState({
                forgottenPassword: false,
                disableSubmitButton: false,
            });
            this.props.enqueueSnackbar('Reset password email sent', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Reset password request failed', { variant: 'error' });
            this.setState({
                disableSubmitButton: false,
            });
        });
    }

    handleCancelClick = (event) => {
        event.preventDefault();
        this.setState({
            forgottenPassword: false,
        });
    }

    sendForgottenPasswordEmail = async (payload) => {
        let response;
        let body;

        body = JSON.stringify(payload);
        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/user/resetPassword/email`, {
                body,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 204: {
            this.setState({
                passwordReset: true,
            });
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    }

    login = async (e) => {
        e.preventDefault();

        const formData = new window.FormData(e.currentTarget);

        const emailAddress = formData.get('email');
        const password = formData.get('password');

        this.setState({
            loading: true,
        });

        const basicToken = window.btoa(`${emailAddress}:${password}`);

        const response = await fetch(`${REACT_APP_API_SERVER}create_session`, {
            method: 'POST',
            headers: {
                authorization: `Basic ${basicToken}`,
            },
        }).then(res => res.json());

        if (response.error) {
            this.setState({
                error: response.error,
                loading: false,
            });
            this.props.enqueueSnackbar('Login failed', { variant: 'error' });
        } else {
            Cookie.set('authToken', response.token);
            const url = new URL(`${REACT_APP_API_SERVER}user-permission-company`);
            const companies = await fetch(url).then(res => res.json());
            const windowLocationHref = new URL(window.location.href);
            const subDomain = windowLocationHref.hostname.split('.')[0];
            const topLevelDomain = windowLocationHref.hostname.split('.')[windowLocationHref.hostname.split.length + 1];
            console.log('>>>', topLevelDomain);
            if (companies.length > 1) {
                // console.log(`>>> ${topLevelDomain === 'local' ? 'http' : 'https'}://${companies[0].portalURL}/gate?companyCode=${companies[0].code}&companyShortName=${companies[0].shortName}&companyLongName=${companies[0].longName}`);
                window.location.href = `${topLevelDomain === 'local' ? 'http' : 'https'}://${companies[0].portalURL}/gate?companyCode=${companies[0].code}&companyShortName=${companies[0].shortName}&companyLongName=${companies[0].longName}`;
            } else {
                // Cookie.set('companyCode', companies[0].code);
                // Cookie.set('companyShortName', companies[0].shortName);
                // Cookie.set('companyLongName', companies[0].longName);
                const windowLocationHref = new URL(window.location.href);
                const subDomain = windowLocationHref.hostname.split('.')[0];
                // console.log(companies);
                // console.log(windowLocationHref);
                // console.log(subDomain);
                // debugger;

                // console.log(`>>> ${topLevelDomain === 'local' ? 'http' : 'https'}://${companies[0].portalURL}/gate?companyCode=${companies[0].code}&companyShortName=${companies[0].shortName}&companyLongName=${companies[0].longName}`);
                window.location.href = `${topLevelDomain === 'local' ? 'http' : 'https'}://${companies[0].portalURL}/gate?companyCode=${companies[0].code}&companyShortName=${companies[0].shortName}&companyLongName=${companies[0].longName}`;
            }
        }
    }

    render = () => {
        const { classes } = this.props;
        const {
            disableSubmitButton,
            forgottenPassword,
            forgottenPasswordData,
        } = this.state;

        const imageUrl = window.location.host === 'portal2.payweeklycarpets.co.uk' ? 'https://pwc-email-images.s3.eu-west-1.amazonaws.com/logo-main-small.png' : 'https://pwc-email-images.s3.eu-west-1.amazonaws.com/ass_logo_main_small.jpg';

        return (
            <main className={classes.main}>
                <CssBaseline />
                <img
                    className={classes.logo}
                    src={imageUrl}
                    alt="Pay Weekly Logo"
                />
                {forgottenPassword
                    ? (
                        <React.Fragment>
                            <Typography component="h1" variant="h6">
                                Forgotten password
                            </Typography>
                            <Typography
                                component="h1"
                                className={classes.instructions}
                            >
                                Enter your username. You will receive an email with instructions on how to continue.
                            </Typography>
                            <form onSubmit={this.login} className={classes.form}>
                                <FormControl margin="normal" required fullWidth>
                                    <TextField
                                        type="username"
                                        id="username"
                                        name="username"
                                        label="Username"
                                        autoComplete="username"
                                        value={forgottenPasswordData.username}
                                        onChange={this.updateForgottenPasswordData}
                                        autoFocus
                                        variant="outlined"
                                        helperText={(
                                            <Button
                                                size="small"
                                                color="primary"
                                                onClick={this.handleCancelClick}
                                            >
                                                CANCEL
                                            </Button>
                                        )}
                                        InputProps={{
                                            className: classes.inputBox,
                                        }}
                                    />
                                </FormControl>
                                <Grid container spacing={0} style={{ textAlign: 'center' }}>
                                    <Grid item xs={12}>

                                        <Button
                                            color="primary"
                                            className={classes.submit}
                                            disabled={disableSubmitButton || forgottenPasswordData.username.length === 0}
                                            onClick={this.handleForgottenPassword}
                                        >
                                            SUBMIT
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </React.Fragment>
                    )
                    : (
                        <React.Fragment>
                            <Typography component="h1" variant="h6">
                                Sign in
                            </Typography>
                            <form onSubmit={this.login} className={classes.form}>
                                <FormControl margin="normal" required fullWidth>
                                    <TextField
                                        type="email"
                                        id="email"
                                        name="email"
                                        label="Username"
                                        autoComplete="email"
                                        autoFocus
                                        variant="outlined"
                                        InputProps={{
                                            className: classes.inputBox,
                                        }}

                                    />
                                </FormControl>
                                <FormControl margin="normal" required fullWidth>
                                    <TextField
                                        name="password"
                                        type="password"
                                        id="password"
                                        label="Password"
                                        autoComplete="current-password"
                                        variant="outlined"
                                        helperText={(
                                            <Button
                                                size="small"
                                                color="primary"
                                                onClick={this.handleForgottenPasswordClick}
                                            >
                                                Forgotten password?
                                            </Button>
                                        )}
                                        InputProps={{
                                            className: classes.inputBox,
                                        }}

                                    />
                                </FormControl>

                                <Grid container spacing={0} style={{ textAlign: 'center' }}>
                                    <Grid item xs={12}>

                                        <Button
                                            type="submit"
                                            color="primary"
                                            className={classes.submit}
                                            disabled={this.state.loading}
                                        >
                                            {
	            	this.state.loading ? 'Logging in...' : 'Login'
	            }
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </React.Fragment>
                    )}
            </main>
        );
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(withRouter(Login)));
