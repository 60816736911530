import React from 'react';
import {
    Button,
    CssBaseline,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import findIndex from 'lodash/findIndex';

import Layout from './Layout';
import PageHeader from '../components/pageHeader';
import NewCustomerDetail2 from '../components/newCustomerDetail2';
import NewContactHistory from '../components/newContactHistory';
import NewCustomerLeads from '../components/newCustomerLeads';
// import NewCustomerAgreements2 from '../components/newCustomerAgreements2';
import fetch, { fetchJson } from '../utilities/fetch';
import sleep from '../utilities/sleep';

const removeFalseyValuesFromObject = obj => pickBy(obj, identity);

const styles = theme => ({
});


class Customer extends React.Component {
    state = {
        contactCode: null,
        contact: null,
        contactAddress: null,
        leads: null,
        agreements: null,
        agreementsItems: null,
        agreementsItemsControlDevices: null,
        agreementsPayments: null,
        agreementsPaymentPlans: null,
        agreementsAlertsWarnings: null,
        contactHistory: null,
        contactPaymentCards: null,
        requestedAgreementTab: null,

        // addressLongLat: null,
        branches: null,
        errors: {},
        convertLeadProducts: null,
        // campaigns: [],
        // requestedLeadTab: null,
        // paymentCards: null,
        transactionStatusWaiting: true,
    }

    static getDerivedStateFromProps(props, state) {
        const { contactCode } = props.match.params;
        return {
            contactCode,
        };
    }

    componentDidMount() {
        const { contactCode } = this.state;

        const contactData = fetchJson(`https://api.portal2.payweeklycarpets.co.uk/xxx/customer/${contactCode}`)
            .then((customer) => {
                this.setState({
                    contact: customer.contact,
                    contactAddress: customer.contactAddress,
                    leads: customer.leads,
                    agreements: customer.agreements,
                    agreementsItems: customer.agreementsItems,
                    agreementsItemsControlDevices: customer.agreementsItemsControlDevices,
                    agreementsPayments: customer.agreementsPayments,
                    agreementsPaymentPlans: customer.agreementsPaymentPlans,
                    agreementsAlertsWarnings: customer.agreementsAlertsWarnings,
                    contactHistory: customer.contactHistory,
                    contactPaymentCards: customer.contactPaymentCards,
                });
            });
        fetchJson('https://api.portal2.payweeklycarpets.co.uk/xxx/branches')
            .then((branches) => {
                this.setState({ branches });
            });
        contactData.then(() => { // Dependent on the customer
            const { agreementCode } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
            const requestedAgreementTab = findIndex(this.state.agreements, { PlanReference: parseInt(agreementCode, 10) });
            this.setState({
                requestedAgreementTab,
            });
        });
    }

    saveCustomerDetails = async (payload) => {
        if (this.validateCustomerDetails(payload) === false) throw new Error({ error: 'Generic API error' });
        console.log('Save Details Payload', payload);
        let response;
        let body;

        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/xxx/contact/${this.state.contactCode}`, {
                method: 'PATCH',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log('Response:', response);
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState(state => ({
                customer: {
                    ...state.customer,
                    ...body,
                },
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    }

    validateCustomerDetails = (payload) => {
        const fieldOrder = [
            'title',
            'firstname',
            'surname',
            'mobileTelephoneNumber',
            'emailAddress',
        ];

        const title = (() => {
            switch (true) {
            case !payload.title: return 'Title required';
            default: return '';
            }
        })();
        const firstname = (() => {
            switch (true) {
            case !payload.firstname: return 'Firstname required';
            case payload.firstname.length < 2: return 'Firstname too short';
            case payload.firstname.length > 100: return 'Firstname too long';
            default: return '';
            }
        })();
        const surname = (() => {
            switch (true) {
            case !payload.surname: return 'Surname required';
            case payload.surname.length < 2: return 'Surname too short';
            case payload.surname.length > 100: return 'Surname too long';
            default: return '';
            }
        })();
        const mobileTelephoneNumber = (() => {
            switch (true) {
            case !payload.mobileTelephoneNumber: return 'Mobile phone required';
            case payload.mobileTelephoneNumber.length < 2: return 'Mobile phone too short';
            case payload.mobileTelephoneNumber.length > 100: return 'Mobile phone too long';
            default: return '';
            }
        })();
        const emailAddress = (() => {
            switch (true) {
            case !payload.emailAddress: return 'Email address required';
            case payload.emailAddress.length < 2: return 'Email address too short';
            case payload.emailAddress.length > 100: return 'Email address too long';
            default: return '';
            }
        })();

        const errors = removeFalseyValuesFromObject({
            title,
            firstname,
            surname,
            mobileTelephoneNumber,
            emailAddress,
        });

        const firstError = fieldOrder.find(field => !!errors[field]);

        this.setState({
            errors,
            firstError,
        });

        if (isEmpty(errors)) return true;

        return false;
    }

    saveCustomerAddress = async (payload) => {
        if (this.validateCustomerAddress(payload) === false) throw new Error({ error: 'Generic API error' });
        console.log(payload);
        let response;
        let body;

        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/xxx/contact/${this.state.contactCode}/address`, {
                method: 'PATCH',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState(state => ({
                customer: {
                    ...state.customer,
                    ...body.address,
                },
                history: [
                    body.history,
                    ...state.history,
                ],
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    }

    validateCustomerAddress = (payload) => {
        const fieldOrder = [
            'address1',
            'townCity',
            'county',
            'postcode',
            'country',
        ];

        const address1 = (() => {
            switch (true) {
            case !payload.address1: return 'Address required';
            case payload.address1.length < 2: return 'Address too short';
            case payload.address1.length > 100: return 'Address too long';
            default: return '';
            }
        })();
        const townCity = (() => {
            switch (true) {
            case !payload.townCity: return 'Town/City required';
            case payload.townCity.length < 2: return 'Town/City too short';
            case payload.townCity.length > 100: return 'Town/City too long';
            default: return '';
            }
        })();
        const postcode = (() => {
            switch (true) {
            case !payload.postcode: return 'Postcode required';
            case payload.postcode.length < 2: return 'Postcode too short';
            case payload.postcode.length > 100: return 'Postcode too long';
            default: return '';
            }
        })();
        const county = (() => {
            switch (true) {
            case !payload.county: return 'County required';
            default: return '';
            }
        })();
        const country = (() => {
            switch (true) {
            case !payload.country: return 'Country required';
            default: return '';
            }
        })();

        const errors = removeFalseyValuesFromObject({
            address1,
            townCity,
            postcode,
            county,
            country,
        });

        const firstError = fieldOrder.find(field => !!errors[field]);

        this.setState({
            errors,
            firstError,
        });

        if (isEmpty(errors)) return true;

        return false;
    }

    validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    sendTokenisationLink = async (agreementCode, payload) => {
        if (this.validateSendTokenisationLink(payload) === false) throw new Error({ error: 'Generic API error' });
        console.log(payload);
        let response;
        let body;

        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/agreement/${agreementCode}/tokenisationRequest`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            console.log(body);
            this.setState(state => ({
                history: [
                    body,
                    ...state.history,
                ],
            }));
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    }

    validateSendTokenisationLink = (payload) => {
        const fieldOrder = [
            'to',
        ];

        const to = (() => {
            switch (true) {
            case payload.messageType === 'sms'
                    && payload.destination === 'new'
                    && payload.to.length > 15:
            case payload.messageType === 'sms'
                    && payload.destination === 'new'
                    && payload.to.length < 9
                : return 'Invalid mobile number';
            case payload.messageType === 'email'
                    && payload.destination === 'new'
                    && !this.validateEmail(payload.to)
                : return 'Invalid email address';
            default: return '';
            }
        })();

        const errors = removeFalseyValuesFromObject({
            to,
        });

        const firstError = fieldOrder.find(field => !!errors[field]);

        this.setState({
            errors,
            firstError,
        });

        if (isEmpty(errors)) return true;

        return false;
    }

    sendSMS = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${this.state.contactCode}/sms`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState({
                history: [
                    body,
                    ...this.state.history,
                ],
            });
            return;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    }

    addNewHistoryNote = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${this.state.contactCode}/history`, {
            // response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/contact/37/sms`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        console.log(body);
        switch (response.status) {
        case 200: {
            this.setState({
                history: [
                    body,
                    ...this.state.history,
                ],
            });
            return Promise.resolve();
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    }

    saveNewLead = async (payload) => {
        let response;
        let body;

        // Default internal sourceCode
        payload.leadSourceCode = 100;

        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${this.state.contactCode}/lead`, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        console.log(body);
        switch (response.status) {
        case 200: {
            this.setState({
                leads: [
                    body,
                    ...this.state.leads,
                ],
            });
            const note = `New lead created...\n${body.campaignTitle} - ${body.campaignDescription}`;
            await this.addNewHistoryNote({ note });

            return Promise.resolve();
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    }

    updateLead = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/lead/${payload.code}`, {
                method: 'PATCH',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 204: {
            const updatedLeads = [];
            this.state.leads.map((lead) => {
                if (lead.code === payload.code) {
                    updatedLeads.push({ ...lead, status: payload.status });
                } else {
                    updatedLeads.push({ ...lead });
                }
            });
            this.setState({
                leads: updatedLeads,
            });
            return Promise.resolve();
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    }

    saveLeadUpdate = async (updateLead) => {
        const currentStatus = this.state.leads.find(currentLead => currentLead.code === updateLead.code).status;
        if (updateLead.status !== currentStatus) {
            // Update Lead Status
            await this.updateLead(updateLead);
            // Save History Note
            const updateLeadStatusNote = `Lead status changed from '${currentStatus}' to '${updateLead.status}'.`;
            await this.addNewHistoryNote({ note: updateLeadStatusNote });
        }

        if ((updateLead.note || '').length > 0) {
            // Save history note
            await this.addNewHistoryNote({ note: updateLead.note });
        }
    }

    fetchConvertLeadProducts = async (rentalAgreementType) => {
        const url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/products');
        const params = {
            continuousRental: rentalAgreementType === 'Continuous' ? 1 : 0,
            repaymentRental: rentalAgreementType === 'Repayment' ? 1 : 0,
        };
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        const data = await fetch(url).then(response => response.json());
        this.setState({ convertLeadProducts: data });
    }

    // fetchPaymentCards = async () => {
    //     const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/contact/${this.state.contactCode}/cards`);
    //     const data = await fetch(url).then(response => response.json());
    //     this.setState({ paymentCards: data });
    // }

    fetchCampaigns = async () => {
        const url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/campaigns');
        const data = await fetch(url).then(response => response.json());
        this.setState({ campaigns: data });
    }

    convertLead = async (convertForm) => {
        // API - Create rentalAgreement record
        // API - Create rentalAgreement payment plan
        // API - Create adelante customer if required
        // API - Create adelante payment plan

        // Send deposit request sms if required
        // send deposit request email if required
        // Change lead status
        // Add history note including agreement code

        convertForm.contactCode = this.state.customer.code;
        let response;
        let body;

        try {
            response = await fetch('https://api.portal2.payweeklycarpets.co.uk/core/new-rental-agreement', {
                method: 'POST',
                body: JSON.stringify(convertForm),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 202: {
            await this.saveLeadUpdate({
                code: convertForm.code,
                status: 'Converted',
            });
            const updatedLeads = [];
            this.state.leads.map((lead) => {
                if (lead.code === convertForm.code) {
                    updatedLeads.push({ ...lead, status: 'Converted' });
                } else {
                    updatedLeads.push({ ...lead });
                }
            });
            this.setState({
                leads: updatedLeads,
            });
            return Promise.resolve(body.rentalAgreementCode);
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    }

    manualPayment = async (payment) => {
        let response;
        let body;

        try {
            const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/agreement/${payment.agreementCode}/payment-plan-link`);
            const params = {
                amount: payment.amount,
                paymentExpiry: payment.paymentExpiry,
                paymentReturnURL: payment.paymentReturnURL,
            };
            Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
            response = await fetch(url);
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            console.log(body.link);
            window.location.href = body.link;
            // return Promise.resolve();
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    }

    tokenManualPayment = async (payment) => {
        console.log(payment);
        let response;
        let body;

        try {
            const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/agreement/${payment.agreementCode}/token-manual-payment`);
            // const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/test`);
            const params = {
                paymentAmount: payment.paymentAmount,
                paymentCardToken: payment.paymentCardToken,
            };
            const options = {
                method: 'POST',
                body: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            response = await fetch(url, options);
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            const note = `Manual payment attempt for £${parseFloat(payment.paymentAmount).toFixed(2)} using tokenised card ${payment.paymentCardNo} was "${body.transactionStatus}".`;
            await this.addNewHistoryNote({ note });
            return Promise.resolve(body.transactionStatus);
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    }

    printDeliveryLabels = async (agreementCode) => {
        let response;
        let body;

        try {
            const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/agreement/${agreementCode}/label`);
            // const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/test`);
            const params = {
                labelCode: 1000,
                printerCode: 1000,
            };
            const options = {
                method: 'POST',
                body: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            response = await fetch(url, options);
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            this.setState(state => ({
                history: [
                    body,
                    ...state.history,
                ],
            }));
            return Promise.resolve(body.transactionStatus);
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    }

    // AGREEMENT UPDATES
    cancelAgreement = (agreementCode, params) => this.updateAgreement(agreementCode, params);

    updateAgreement = async (agreementCode) => {
        let response;
        let body;

        try {
            const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/agreement/${agreementCode}`);
            const params = {
                agreementCode,
                status: 'CANCELLED',
            };
            const options = {
                method: 'PATCH',
                body: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            response = await fetch(url, options);
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            this.setState(state => ({
                customer: {
                    ...state.customer,
                    agreements: body.agreements,
                },
                history: [
                    body.history,
                    ...state.history,
                ],
            }));
            return;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    }

    handleOldCustomerPageClick = () => {
        this.props.history.push(`/oldCustomer/${this.state.contactCode}`);
    }

    checkTransactionStatus = async (rentalAgreementCode, paymentID) => {
        this.setState({
            transactionStatusWaiting: true,
        });
        const url = new URL('https://api.portal2.payweeklycarpets.co.uk/core/payment');
        const params = {
            rentalAgreementCode,
            paymentID,
        };
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        const data = await fetch(url).then(response => response.json());
        await sleep(1000);
        this.setState({
            transactionStatusWaiting: false,
            transactionStatus: data.transactionStatus,
        });
    }

    render() {
        const {
            classes,
        } = this.props;
        const {
            agreements,
            agreementsAlertsWarnings,
            agreementsItems,
            agreementsItemsControlDevices,
            agreementsPaymentPlans,
            agreementsPayments,
            branches,
            campaigns,
            contact,
            contactAddress,
            contactHistory,
            contactPaymentCards,
            convertLeadProducts,
            errors,
            leads,
            // requestedLeadTab,
            manualPaymentTransctionStatus,
            // paymentCards,
            requestedAgreementTab,
            // agreementsAlerts,
        } = this.state;

        const pageHeaderContent = contact
            ? {
                leftTitle: `${contact.title} ${contact.firstname} ${contact.surname}`,
                leftText: contactAddress.fullAddress,
            } : {
                leftTitle: 'Loading...',
                leftText: '',
            };

        return (
            <Layout pageTitle="Customer">
                <CssBaseline />
                <PageHeader content={pageHeaderContent} />
                <Button
                    variant="contained"
                    onClick={this.handleOldCustomerPageClick}
                >
                    OLD CUSTOMER PAGE
                </Button>
                <NewCustomerDetail2
                    contact={contact}
                    contactAddress={contactAddress}
                    branches={branches}
                    sendSMS={this.sendSMS}
                    saveCustomerDetails={this.saveCustomerDetails}
                    saveCustomerAddress={this.saveCustomerAddress}
                    errors={errors}
                />
                {/*
                <NewCustomerAgreements2
                    contact={contact}
                    agreements={agreements}
                    agreementsItems={agreementsItems}
                    agreementsItemsControlDevices={agreementsItemsControlDevices}
                    agreementsPayments={agreementsPayments}
                    agreementsPaymentPlans={agreementsPaymentPlans}
                    agreementsAlertsWarnings={agreementsAlertsWarnings}
                    contactPaymentCards={contactPaymentCards}
                    errors={errors}
                    requestedAgreementTab={requestedAgreementTab}
                    manualPayment={this.manualPayment}
                    tokenManualPayment={this.tokenManualPayment}
                    checkTransactionStatus={this.checkTransactionStatus}
                    manualPaymentTransctionStatus={manualPaymentTransctionStatus}
                    sendTokenisationLink={this.sendTokenisationLink}
                    printDeliveryLabels={this.printDeliveryLabels}
                    cancelAgreement={this.cancelAgreement}
                />
                */}
            </Layout>
        );
    }
}

export default withStyles(styles)(Customer);
