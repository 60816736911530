import React from 'react';
import {
    CircularProgress,
    Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tile from '../containers/tile';
import BranchesSummaryTable from './branchesSummaryTable';
import ProductsTableFilters from './productsTableFilters';

const styles = theme => ({
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    fieldTitle: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        marginBottom: theme.spacing.unit * 1,
    },
});

class BranchesSummary extends React.Component {
    state = {
    }

    render() {
        const {
            classes,
            filters,
            branchesParentSummary,
            totalBranchesQty,
            removeFilter,
            setFilters,
        } = this.props;

        return (
            <Tile
                tileTitle="SUMMARY"
                style
            >
                { branchesParentSummary !== null
                    ? (
                        <React.Fragment>
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <div className={classes.fieldTitle}>
                                        Filters
                                    </div>
                                    <ProductsTableFilters
                                        filters={filters}
                                        removeFilter={removeFilter}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <BranchesSummaryTable
                                        branchesParentSummary={branchesParentSummary}
                                        totalBranchesQty={totalBranchesQty}
                                        setFilters={setFilters}
                                        filters={filters}
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )
                }
            </Tile>
        );
    }
}

const branchesParentSummaryShape = {
    type: PropTypes.string,
    productQty: PropTypes.number,
};

BranchesSummary.propTypes = {
    classes: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    branchesParentSummary: PropTypes.arrayOf(PropTypes.shape(branchesParentSummaryShape)).isRequired,
    totalBranchesQty: PropTypes.object.isRequired,
    removeFilter: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
};

export default withStyles(styles)(BranchesSummary);
