import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
    AppBar,
    Badge,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    Grid,
    IconButton,
    InputBase,
    Menu,
    MenuItem,
    TextField,
    Toolbar,
    Typography,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import MoreIcon from '@material-ui/icons/MoreVert';
import Cookie from 'js-cookie';
import fetch, { fetchJson, fetchJson2 } from '../../utilities/fetch';
import { version } from '../../version';
import Search from '../v2/search';
import amplify5Logo from '../../amplify5Logo.png';

const AMPLIFY5 = 1002;

const styles = (theme) => ({
    root: {
        // width: '100%',
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            minWidth: 240,
        },
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        marginLeft: theme.spacing.unit,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing.unit * 2,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit * 3,
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    companyButtonText: {
        color: theme.palette.secondary.main,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 2.5,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
    },
    dialogActionsDivider: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    dialogAppVersion: {
        fontWeight: 500,
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    userFullName: {
        color: 'rgba(0, 0, 0, 0.87)',
        width: 'auto',
        height: theme.spacing.unit * 3,
        overflow: 'hidden',
        fontSize: 16,
        boxSizing: 'content-box',
        fontWeight: 400,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        lineHeight: '1.5em',
        whiteSpace: 'nowrap',
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        display: 'flex',
        position: 'relative',
        textAlign: 'left',
        alignItems: 'center',
        paddingTop: 11,
        paddingBottom: 11,
        justifyContent: 'flex-start',
        textDecoration: 'none',
    },
    logo: {
        height: 40,
        float: 'inline-start',
        paddingLeft: 8,
    },
    nextCallButton: {
        marginLeft: theme.spacing.unit * 2.5,
        backgroundColor: '#6d7b83',
    },
});

class PrimarySearchAppBar extends React.Component {
    // props = {
    //     title: this.props.title,
    // };

    state = {
        advancedSearchData: {},
        anchorEl: null,
        appVersion: null,
        companyAnchorEl: null,
        companyLongName: '',
        disableAdvancedSearchSearchButton: false,
        mobileMoreAnchorEl: null,
        showAdvancedSearchDialog: false,
        showVersionDialog: false,
        userFullName: '',
        userLoading: true,
        userPermissionCompanies: [],
    };

    async componentDidMount() {
        const companyLongName = Cookie.get('companyLongName') || 'Pay Weekly';
        this.setState({ companyLongName });
        const data = await fetch('https://api.portal2.payweeklycarpets.co.uk/core/user-permission-company').then((response) => response.json());

        fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/appVersion?type=desktop')
            .then((response) => {
                const currentAppVersion = response.version;
                console.log('currentAppVersion:', currentAppVersion);
                console.log('usingAppVersion:', version);
                if (version < currentAppVersion) {
                    this.setState({ showVersionDialog: true });
                }
                this.setState({ currentAppVersion });
            });

        await fetchJson2('v2/user')
            .then((response) => {
                this.setState({
                    user: response.payload,
                    userFullName: `${response.payload.contact.firstname} ${response.payload.contact.surname}`,
                    userLoading: false,
                });
            });

        this.setState({
            userPermissionCompanies: data,
        });
        // console.log('data back:', this.props.userPermissionCompanies);
    }

    handleProfileMenuOpen = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = (event) => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    handleMenuLogoff = () => {
        Cookie.remove('authToken');
        Cookie.remove('companyCode');
        Cookie.remove('companyShortName');
        Cookie.remove('companyLongName');
        this.props.history.push('/login');
    };

    // handleCompanyMenuToggle = (event) => {
    //     this.setState(({ companyAnchorEl }) => ({
    //         companyAnchorEl: companyAnchorEl === null ? event.currentTarget : null,
    //     }));
    // }

    handleCompanyMenuOpen = (event) => {
        this.setState({ companyAnchorEl: event.currentTarget });
    };

    // handleCompanyMenuClick = (companyCode) => {
    //     console.log('Fire -', companyCode);
    //     Cookie.set('companyCode', companyCode);
    // }

    changeCompany = (code) => {
        const targetCompany = this.state.userPermissionCompanies.find((company) => company.code === code);
        const windowLocationHref = new URL(window.location.href);
        const subDomain = windowLocationHref.hostname.split('.')[0];
        const { pathname } = windowLocationHref;
        console.log('>>>', windowLocationHref);
        console.log('>>>', subDomain);
        console.log('>>>', pathname);
        console.log('>>>', targetCompany);
        window.location.href = `https://${subDomain === 'dev' ? 'dev.' : ''}${targetCompany.portalURL}/gate?companyCode=${targetCompany.code}&companyShortName=${targetCompany.shortName}&companyLongName=${targetCompany.longName}&pathname=${pathname}`;
    };

    companyMenuHandler = (code) => () => this.changeCompany(code);

    handleCompanyMenuOpen = (event) => {
        if (this.state.userPermissionCompanies.length > 1) {
            this.setState({ companyAnchorEl: event.currentTarget });
        }
    };

    handleCompanyMenuClose = () => {
        this.setState({ companyAnchorEl: null });
    };

    handleCloseVersionDialogClick = () => {
        this.setState({ showVersionDialog: false });
    };

    handleFormSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        this.props.history.push(`/search-results?searchString=${formData.get('searchString')}`);
    };

    handleAdvancedSearchClick = () => {
        this.setState({ showAdvancedSearchDialog: true });
    };

    handleAdvancedSearchCloseClick = () => {
        this.setState({ showAdvancedSearchDialog: false });
    };

    handleAdvancedSearch = () => {
        const {
            firstname,
            surname,
            postcode,
        } = this.state.advancedSearchData;
        this.setState({
            showAdvancedSearchDialog: false,
        });
        this.props.history.push(`/search-results?type=ADVANCED${firstname ? `&firstname=${firstname}` : ''}${surname ? `&surname=${surname}` : ''}${postcode ? `&postcode=${postcode}` : ''}`);
    };

    updateAdvancedSearchData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            advancedSearchData: {
                ...state.advancedSearchData,
                [name]: formattedValue,
            },
        }));
    };

    render() {
        const {
            advancedSearchData,
            anchorEl,
            companyAnchorEl,
            companyLongName,
            disableAdvancedSearchSearchButton,
            mobileMoreAnchorEl,
            showAdvancedSearchDialog,
            user,
            userLoading,
            userPermissionCompanies,
        } = this.state;
        const {
            classes,
        } = this.props;

        const isMenuOpen = Boolean(anchorEl);
        const isCompanyMenuOpen = Boolean(companyAnchorEl);
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

        const renderCompanyButton = (
            <>
                <Button
                    aria-owns={companyAnchorEl ? 'company-menu' : undefined}
                    aria-haspopup="true"
                    className={classes.companyButtonText}
                    onClick={this.handleCompanyMenuOpen}
                >
                    {companyLongName}
                </Button>
                <Menu
                    id="company-menu"
                    anchorEl={companyAnchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={isCompanyMenuOpen}
                    onClose={this.handleCompanyMenuClose}
                >
                    {this.state.userPermissionCompanies.map((company) => (
                        <MenuItem
                            key={company.code}
                            onClick={this.companyMenuHandler(company.code)}
                            disabled={Cookie.get('companyCode') == company.code}
                        >
                            {company.longName}
                        </MenuItem>
                    ))}
                </Menu>
            </>
        );

        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <div className={classes.userFullName}>
                    {this.state.userFullName}
                </div>
                <Divider light />
                {/* <MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
                <MenuItem onClick={this.handleMenuClose}>My account</MenuItem> */}
                <Divider light />
                <MenuItem onClick={this.handleMenuLogoff}>Logoff</MenuItem>
            </Menu>
        );

        const renderMobileMenu = (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                {/* <MenuItem>
                    }<IconButton color="inherit">
                        <Badge badgeContent={4} color="secondary">
                            <MailIcon />
                        </Badge>
                    </IconButton>
                    <p>Messages</p>
                </MenuItem>
                <MenuItem>
                    <IconButton color="inherit">
                        <Badge badgeContent={11} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <p>Reminders</p>
                </MenuItem> */}
                <MenuItem onClick={this.handleProfileMenuOpen}>
                    <IconButton color="inherit">
                        <AccountCircle />
                    </IconButton>
                    <p>Profile</p>
                </MenuItem>
            </Menu>
        );

        const renderVersionDialog = (
            <Dialog
                open={this.state.showVersionDialog}
                onClose={this.handleCloseVersionDialogClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.dialogContentContainer}>
                        <>
                            <Typography variant="h6" gutterBottom>
                                Application Update Available
                            </Typography>
                            <DialogContentText>
                                A newer version of the Portal application is available.
                            </DialogContentText>
                            <DialogContentText className={classes.dialogAppVersion}>
                                {version}
                                {' '}
                                --&gt;
                                {' '}
                                {this.state.currentAppVersion}
                            </DialogContentText>
                            <DialogContentText>
                                It is recommended you close and restart your browser to complete the update.
                            </DialogContentText>
                        </>
                    </div>
                    <Divider />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        onClick={this.handleCloseVersionDialogClick}
                        color="primary"
                        autoFocus
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );

        return (
            <>
                <div className={classes.root}>
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar>
                            {!userLoading && !(user.groups || []).find((group) => group.code === AMPLIFY5)
                                ? (
                                    <>
                                        <IconButton
                                            color="inherit"
                                            aria-label="Open drawer"
                                            onClick={this.props.onMenuToggleClick}
                                            className={classes.menuButton}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                        { renderCompanyButton }
                                    </>
                                )
                                : null}
                            <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                                {this.props.pageTitle}
                            </Typography>
                            <div className={classes.grow} />
                            {/* {!userLoading && !!(user.groups || []).find((group) => group.code === AMPLIFY5)
                                ? (
                                    <>
                                        <img className={classes.logo} src={amplify5Logo} alt="logo" />
                                        <Button
                                            type="button"
                                            // onClick={saveInstallation}
                                            // fullWidth
                                            variant="text"
                                            color="secondary"
                                            className={classes.nextCallButton}
                                            // disabled={loading}
                                        >
                                            NEXT CALL
                                        </Button>
                                    </>
                                ) : null} */}
                            <Search
                                history={this.props.history}
                            />
                            {/* <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <form onSubmit={this.handleFormSubmit}>
                                <InputBase
                                    id="searchString"
                                    name="searchString"
                                    placeholder="Search…"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                />
                            </form>
                        </div> */}
                            <div className={classes.sectionDesktop}>
                                {/* <IconButton color="inherit">
                                <Badge badgeContent={4} color="secondary">
                                    <MailIcon />
                                </Badge>
                            </IconButton>
                            <IconButton color="inherit">
                                <Badge badgeContent={17} color="secondary">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton> */}

                                {/* <IconButton
                                    aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleAdvancedSearchClick}
                                    color="inherit"
                                >
                                    <SearchIcon />
                                </IconButton> */}

                                <IconButton
                                    aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>
                            </div>
                            <div className={classes.sectionMobile}>
                                <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                                    <MoreIcon />
                                </IconButton>
                            </div>
                        </Toolbar>
                    </AppBar>
                    {renderMenu}
                    {renderMobileMenu}
                    {renderVersionDialog}
                </div>

                <Dialog
                    open={showAdvancedSearchDialog}
                    onClose={this.handleAdvancedSearchCloseClick}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.dialogContentContainer}>
                            <>
                                <Typography variant="h6" gutterBottom>
                                    Advanced Search
                                </Typography>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="firstname"
                                            id="firstname"
                                            value={advancedSearchData.firstname}
                                            onChange={this.updateAdvancedSearchData}
                                            label="Firstname"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="surname"
                                            id="surname"
                                            value={advancedSearchData.surname}
                                            onChange={this.updateAdvancedSearchData}
                                            margin="normal"
                                            variant="outlined"
                                            label="Surname"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="postcode"
                                            id="postcode"
                                            value={advancedSearchData.postcode}
                                            onChange={this.updateAdvancedSearchData}
                                            label="Postcode"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justify="center"
                                >
                                    <Grid item xs={12}>
                                        <Button
                                            className={classes.dialogButton}
                                            onClick={this.handleAdvancedSearch}
                                            disabled={disableAdvancedSearchSearchButton}
                                        >
                                            SEARCH
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        </div>
                    </DialogContent>
                    <Divider />
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            onClick={this.handleAdvancedSearchCloseClick}
                            color="primary"
                            autoFocus
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

PrimarySearchAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(PrimarySearchAppBar));
