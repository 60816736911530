import React from 'react';
import {
    Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import GaugeChart from 'react-gauge-chart';
import classNames from 'classnames';

const styles = (theme) => ({
    gaugeContainer: {
        width: 100,
        marginTop: 8,
    },
    creditScore: {
        fontWeight: 700,
        textAlign: 'center',
        height: 40,
        backgroundColor: '#ff0000',
        borderRadius: 4,
        width: 75,
        paddingTop: 7,
        fontSize: 23,
    },
    gaugeGreen: {
        color: '#ffffff',
        backgroundColor: '#00ff00',
    },
    gaugeAmber: {
        color: '#ffffff',
        backgroundColor: '#FFBF00',
    },
    gaugeRed: {
        color: '#ffffff',
        backgroundColor: '#ff0000',
    },
    creditScoreContainer: {
        width: 200,
    },
    creditScoreValue: {
        float: 'left',
        width: 75,
    },
    creditScoreChild: {
        float: 'left',
        width: 100,
    },
    na: {
        textAlign: 'left',
    },
});

class ContactBadges extends React.Component {
    state = {
    };

    render() {
        const {
            classes,
            creditScore,
            reference,
            type,
        } = this.props;

        console.log('>>> internalCreditScore: ', creditScore);

        return (
            <div className={classes.gaugeContainer}>
                {creditScore === null
                    ? (
                        <div className={classes.na}>
                            n/a
                        </div>
                    )
                    : (
                        <div className={classes.creditScoreContainer}>

                            <div className={classes.creditScoreValue}>

                                <div className={classNames(
                                    classes.creditScore,
                                    {
                                        [classes.gaugeRed]: creditScore < 450,
                                    },
                                    {
                                        [classes.gaugeAmber]: (creditScore >= 450 && creditScore < 600),
                                    },
                                    {
                                        [classes.gaugeGreen]: creditScore >= 600,
                                    },
                                )}
                                >
                                    {creditScore}
                                </div>
                            </div>

                            <div className={classes.creditScoreChild}>
                                <div>
                                    <GaugeChart
                                        id={`gauge-chart-${reference}`}
                                        arcsLength={[45, 15, 40]}
                                        cornerRadius={1}
                                        hideText
                                        nrOfLevels={3}
                                        // percent={(creditScore / 1000)}
                                        percent={(creditScore / (type === 'INTERNAL' ? 1000 : 700))}
                                        colors={[
                                            '#ff0000',
                                            '#FFBF00',
                                            '#00ff00',
                                        ]}
                                    />
                                </div>
                            </div>

                        </div>
                    )}
            </div>
        );
    }
}

export default withStyles(styles)(ContactBadges);
