import React from 'react';
import {
    withStyles,
} from '@material-ui/core/styles';
import {
    AppBar,
    Button,
    CircularProgress,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tabs,
    TextField,
    Typography,
} from '@material-ui/core';
// import PropTypes from 'prop-types';
import { DatePicker, DateTimePicker } from 'material-ui-pickers';
import AuthorisedIcon from '@material-ui/icons/CheckCircleOutline';
import DeclinedIcon from '@material-ui/icons/HighlightOff';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import UnassignIcon from '@material-ui/icons/AssignmentReturn';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import {
    Link,
    DirectLink,
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller,
} from 'react-scroll';
import { withSnackbar } from 'notistack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Error';
import CrossCircleIcon from '@material-ui/icons/Cancel';
import BulletIcon from '@material-ui/icons/FiberManualRecord';
import AddIcon from '@material-ui/icons/Add';
import SwapIcon from '@material-ui/icons/SwapHoriz';
import {
    get,
    isEmpty,
    orderBy,
    sortBy,
} from 'lodash';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { AddAPhoto } from '@material-ui/icons';
import HybridTextField from '../hybridTextField';
import titalise from '../../utilities/titalise';
import Tile from '../../containers/tile';
import ContactHistory from './contactHistory';
import capitalisedSnakeCaseToTitleCase from '../../utilities/capitalisedSnakeCaseToTitleCase';

const currentDateTime = moment.tz(moment(), 'Europe/London');

const styles = (theme) => ({
    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        marginBottom: 7,
        textAlign: 'left',
        width: '100%',
    },
    tileBottomDivider: {
        marginTop: theme.spacing.unit * 3,
        width: '100%',
    },
    bottomBarContainer: {
        textAlign: 'right',
        width: '100%',
    },
    textButton: {
        marginBottom: 0,
        marginTop: theme.spacing.unit * 1.5,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    hybridTextIconLeft: {
        fontSize: 16,
        marginRight: theme.spacing.unit * 1,
        transform: 'translate(0%, 13%)',
    },
    hybridTextIconRight: {
        fontSize: 16,
        marginLeft: theme.spacing.unit * 1,
        transform: 'translate(0%, 13%)',
    },
    cancelledIcon: {
        color: '#d7d7d7',
        // color: '#f1180b',
    },
    warmIcon: {
        color: '#E7C251',
    },
    coldIcon: {
        color: '#BBFFFF',
    },
    notSetIcon: {
        color: '#d7d7d7',
    },
    activeIcon: {
        color: '#4caf50',
    },
    warningIcon: {
        color: '#fac518',
    },
    alert: {
        color: '#f1180b',
    },
    alertContainer: {
        /* text-align: left; */
        border: '1px solid',
        borderRadius: 4,
        fontWeight: 400,
        marginBottom: theme.spacing.unit * 4,
        padding: theme.spacing.unit * 2,
        width: '100%',
    },
    deliveriesAlertContainer: {
        /* text-align: left; */
        backgroundColor: '#f1180b',
        borderRadius: 4,
        color: '#fff',
        fontWeight: 600,
        padding: theme.spacing.unit * 2,
        width: '100%',
    },
    deliveriesOKContainer: {
        /* text-align: left; */
        backgroundColor: '#4caf50',
        borderRadius: 4,
        color: '#fff',
        fontWeight: 600,
        padding: theme.spacing.unit * 2,
        width: '100%',
    },
    bumpBottom4U: {
        marginBottom: theme.spacing.unit * 4,
    },
    bumpBottom3U: {
        marginBottom: theme.spacing.unit * 3,
    },
    bumpBottom2U: {
        marginBottom: theme.spacing.unit * 2,
    },
    bumpBottom1U: {
        marginBottom: theme.spacing.unit * 1,
    },
    warning: {
        color: '#ff9800',
    },

    tabsPaper: {
        alignItems: 'center',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
        paddingBottom: 24,
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 32,
        width: '100%',
    },
    tabsAppBar: {
        backgroundColor: theme.palette.primary.lighter,
    },
    tab: {
        color: 'white',
        fontSize: 15,
        fontWeight: 500,
        // height: 64,
        minHeight: 38,
    },
    tabs: {
        // height: 64,
        minHeight: 38,
        padding: 0,
    },
    tabButton: {
        backgroundColor: 'red',
        height: 64,
        minHeight: 38,
        padding: 0,
    },
    inputLabel: {
        backgroundColor: '#fff',
        marginLeft: -5,
        paddingLeft: 8,
        paddingRight: 8,
    },
    formInstructions: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 3,
    },
    statusSelect: {
        width: 200,
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        width: '100%',
    },
    dialogContentContainer: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
        width: 400,
    },
    dialogButton: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 5,
    },
    dialogContent: {
        paddingBottom: 16,
        minWidth: 390,
    },
    dialogActionsDivider: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    radioDescription: {
        marginLeft: 34,
        marginRight: theme.spacing.unit * 3,
        marginTop: -8,
    },
    issuerRadioDescription: {
        color: theme.palette.text.secondary,
        margin: 0,
    },
    disabledText: {
        color: 'rgba(0, 0, 0, 0.38)',
    },
    radioButtonsContainer: {
        marginLeft: theme.spacing.unit * 3,
    },
    menuItemText: {
        color: theme.palette.text.primary,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeight,
    },
    menuItemSubtext: {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeight,
    },
    selectMultiline: {
        height: 75,
    },
    convertDialogContent: {
        paddingBottom: 0,
        width: 540,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontSize: 14,
        fontWeight: 'bold',
        height: 48,
    },
    tableHeadRow: {
        height: 48,
    },
    tableIndex: {
        paddingTop: theme.spacing.unit,
        verticalAlign: 'top',
    },
    marginTop3: {
        marginTop: theme.spacing.unit * 3,
    },
    inputAdornment: {
        color: theme.palette.text.secondary,
        fontSize: 16,
        marginTop: 1,
    },
    sectionContainer: {
        borderBottom: '1px solid #eaeaea',
        marginBottom: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 1,
    },
    progressContainer: {
        marginBottom: theme.spacing.unit * 3,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        textAlign: 'center',
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    declinedStatusIcon: {
        color: red[400],
        fontSize: 100,
        margin: theme.spacing.unit * 2,
    },
    authorisedStatusIcon: {
        color: green[400],
        fontSize: 100,
        margin: theme.spacing.unit * 2,
    },
    ErrorStatusIcon: {
        color: amber[400],
        fontSize: 100,
        margin: theme.spacing.unit * 2,
    },
    cardRadio: {
        paddingTop: '0px',
    },
    issuerRadioLabel: {
        marginTop: theme.spacing.unit * 1,
    },
    emptyContent: {
        padding: 32,
    },
    textField: {
        width: 260,
    },
    bumpTop1U: {
        marginTop: theme.spacing.unit * 1,
    },
    bumpTop2U: {
        marginTop: theme.spacing.unit * 2,
    },
    bumpTop3U: {
        marginTop: theme.spacing.unit * 2,
    },
    bumpTop3: {
        marginTop: theme.spacing.unit * 3,
    },
    bumpLeft3U: {
        marginLeft: theme.spacing.unit * 3,
    },
    bulletIcon: {
        fontSize: 11,
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 1.5,
    },
    deliveryAlertList: {
        fontWeight: 400,
        marginTop: theme.spacing.unit * 1,
    },
    subTile: {
        marginBottom: theme.spacing.unit * 4,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    gaugeContainer: {
        width: 100,
        // marginLeft: 'auto',
        // marginRight: 'auto',
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        marginBottom: 4,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '8px 24px',
        margin: '0px 0px 0px 0px !important',
    },
    pdfDialogContent: {
        minWidth: 300,
        minHeight: 300,
    },
    signatureImage: {
        border: '1px solid darkgrey',
        boxShadow: '5px 5px 5px 1px #ccc',
        borderRadius: 5,
        maxWidth: 680,
    },
    dialogActionsRight: {
        textAlign: 'right',
    },
    dialogDownloadButton: {
        color: 'rgba(0, 0, 0, 0.87)',
        padding: '8px',
        fontSize: '0.875rem',
        minWidth: '64px',
        boxSizing: 'border-box',
        minHeight: '36px',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '500',
        lineHeight: '1.5',
        borderRadius: '4px',
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        border: '0',
        margin: '0',
        cursor: 'pointer',
        display: 'inline-flex',
        outline: 'none',
        position: 'relative',
        alignItems: 'center',
        userSelect: 'none',
        verticalAlign: 'middle',
        justifyContent: 'center',
        textDecoration: 'none',
        backgroundColor: 'transparent',
        webkitAppearance: 'none',
        webkitTapHighlightColor: 'transparent',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    },
    signatureDialogContent: {
        textAlign: 'center',
    },
    checkboxIndent: {
        marginLeft: theme.spacing.unit * 3,
    },
    buildSendContractDialog: {
        maxWidth: 400,
    },
    emailStatementDialog: {
        maxWidth: 400,
    },
    engineerScheduleDialogContent: {
        textAlign: 'center',
    },
    updatedDateTimes: {
        marginTop: theme.spacing.unit * 4,
    },
    pleaseWait: {
        marginTop: 10,
        fontWeight: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
        opacity: '.5',
        marginBottom: 72,
    },
    pleaseWaitSpinnerContainer: {
        marginTop: 24,
        marginBottom: 24,
    },
    resultIcon: {
        fontSize: 94,
    },
    error: {
        color: '#ff0000',
    },
    warning: {
        color: '#f9aa33',
    },
    success: {
        color: '#4caf50',
    },
    stateContainer: {
        marginBottom: 32,
        marginTop: 32,
        textAlign: 'center',
    },
    resultLabel: {
        marginTop: 10,
        fontWeight: 700,
    },
    resultprogress: {
        opacity: '.5',
        width: '94px !important',
        height: '94px !important',
    },
    dialogContentAlertText: {
        color: '#c80000',
        fontSize: 16,
        opacity: 0.54,
    },
    dialogContentTitle: {
        color: '#c80000',
        fontSize: 16,
        opacity: 0.54,
        fontWeight: 700,
    },
    blackTableIcons: {
        color: '#000000',
        fontSize: 22,
        opacity: 0.6,
    },
    customTableCell: {
        verticalAlign: 'middle',
        marginTop: 16,
    },
    editItemAgreementTableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    editAgreementItemTable: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
        border: '1px solid #e0e0e0',
    },
    editAgreementItemList: {
        marginLeft: theme.spacing.unit * 4,
        marginTop: theme.spacing.unit * 2,
        color: '#c80000',
    },
    dialogTitle: {
        marginBottom: theme.spacing.unit * 4,
    },
    cardIssuer: {
        color: '#a3a3a3',
    },
    // column2Dialog: {
    //     marginLeft:
    // }
    fieldTitle: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        marginBottom: theme.spacing.unit * 1,
    },
    chip: {
        marginRight: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    addPostcodeDistrictField: {
        height: 35,
    },
    addPostcodeDistrictButton: {
        marginTop: 27,
        marginBottom: theme.spacing.unit * 1,
        marginLeft: theme.spacing.unit * 2,
        height: 35,
    },
});

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        paddingRight: 8,
    },
    body: {
        fontSize: 12,
        verticalAlign: 'top',
        paddingTop: 16,
        paddingBottom: 8,
        paddingRight: 8,
    },
}))(TableCell);

const currentDate = moment.tz(moment(), 'Europe/London');

class ContactAgent extends React.Component {
    state = {
        disableAddAgentPostcodeDistrictButton: false,
        addAgentPostcodeDistrictData: {
            postcodeDistrict: null,
        },
        // editComplaintData: {
        //     referenceType: null,
        //     acknowledgementLetterDate: null,
        //     summaryResolutionLetterDate: null,
        //     finalResponseLetterDate: null,
        //     holdingLetterDate: null,
        //     eightWeekLetterDate: null,
        // },
        // currentComplaintTab: 0,
        // disableAddComplaintSaveButton: false,
        // disableEditComplaintSaveButton: false,
        // showAddComplaintDialog: false,
        // showEditComplaintDialog: false,
    };

    async componentDidMount() {
    }

    // scrollToAgreementsTop = () => {
    //     scroller.scrollTo('complaintsTop', {
    //         duration: 800,
    //         delay: 100,
    //         smooth: 'easeInOutQuart',
    //         offset: -90,
    //     });
    // }

    // agreementStatusIcon = (alertStatus, warningStatus, cancelledStatus) => {
    //     switch (true) {
    //     case (alertStatus): {
    //         return (<WarningIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.alert}`} />);
    //     }
    //     case (warningStatus): {
    //         return (<WarningIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.warningIcon}`} />);
    //     }
    //     case (cancelledStatus): {
    //         return (<CrossCircleIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.cancelledIcon}`} />);
    //     }
    //     default: {
    //         return (<CheckCircleIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.activeIcon}`} />);
    //     }
    //     // case 'Warm': {
    //     //     return (this.props.classes.warmIcon);
    //     // }
    //     // case 'Cold': {
    //     //     return (this.props.classes.coldIcon);
    //     // }
    //     // case 'Finance': {
    //     //     return (this.props.classes.financeIcon);
    //     // }
    //     // default: {
    //     //     return (this.props.classes.notSetIcon);
    //     // }
    //     }
    // }

    handleAddComplaintClick = () => {
        this.setState({
            showAddComplaintDialog: true,
        });
    };

    handleAddComplaintCloseClick = () => {
        this.setState({
            showAddComplaintDialog: false,
        });
    };

    handleEditComplaintClick = () => {
        const currentComplaint = this.props.contact.complaints[this.state.currentComplaintTab];
        this.setState({
            editComplaintData: {
                status: currentComplaint.status,
                assignedToContactCode: currentComplaint.assignedToContactCode,
                acknowledgementLetterDate: currentComplaint.acknowledgementLetterDate,
                summaryResolutionLetterDate: currentComplaint.summaryResolutionLetterDate,
                finalResponseLetterDate: currentComplaint.finalResponseLetterDate,
                holdingLetterDate: currentComplaint.holdingLetterDate,
                eightWeekLetterDate: currentComplaint.eightWeekLetterDate,
            },
            showEditComplaintDialog: true,
        });
    };

    handleEditComplaintCloseClick = () => {
        this.setState({
            showEditComplaintDialog: false,
        });
    };

    updateAddAgentPostcodeDistrictData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        let otherValue;
        switch (name) {
        case 'postcodeDistrict':
            formattedValue = value.replace(/[^a-zA-Z0-9+]/g, '').toUpperCase();
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            addAgentPostcodeDistrictData: {
                ...state.addAgentPostcodeDistrictData,
                [name]: formattedValue,
                ...otherValue,
            },
        }));
    };

    updateEditComplaintData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        let otherValue;
        switch (name) {
        case 'referenceType':
            formattedValue = value;
            otherValue = { referenceCode: null };
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState({
            editComplaintData: {
                ...this.state.editComplaintData,
                [name]: formattedValue,
                ...otherValue,
            },
        });
    };

    handleAddComplaint = () => {
        this.setState({
            disableAddComplaintSaveButton: true,
        });
        const params = {
            ...this.state.addComplaintData,
        };
        this.props.addComplaint(params).then(() => {
            this.setState({
                disableAddComplaintSaveButton: false,
                showAddComplaintDialog: false,
            });
            this.props.enqueueSnackbar('Complaint Added', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableAddComplaintSaveButton: false,
            });
            this.props.enqueueSnackbar('Add Complaint Failed', { variant: 'error' });
        });
    };

    handleEditComplaint = () => {
        const currentComplaint = this.props.contact.complaints[this.state.currentComplaintTab];
        this.setState({
            disableEditComplaintSaveButton: true,
        });
        const params = {
            ...this.state.editComplaintData,
        };
        this.props.editComplaint(currentComplaint.code, params).then(() => {
            this.setState({
                disableEditComplaintSaveButton: false,
                showEditComplaintDialog: false,
            });
            this.props.enqueueSnackbar('Complaint Updated', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableEditComplaintSaveButton: false,
            });
            this.props.enqueueSnackbar('Update Complaint Failed', { variant: 'error' });
        });
    };

    handleDeleteAgentPostcodeDistrict = (postcodeDistrict) => {
        this.props.deleteAgentPostcodeDistrict(postcodeDistrict).then(() => {
            this.props.enqueueSnackbar('Postcode District Deleted', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Delete Postcode District Failed', { variant: 'error' });
        });
    };

    handleAddPostcodeDistrict = () => {
        const { postcodeDistrict } = this.state.addAgentPostcodeDistrictData;
        this.setState({
            disableAddAgentPostcodeDistrictButton: true,
        });
        this.props.addAgentPostcodeDistrict(postcodeDistrict).then(() => {
            this.setState({
                disableAddAgentPostcodeDistrictButton: false,
            });
            this.props.enqueueSnackbar('Postcode District Added', { variant: 'success' });
        }).catch(() => {
            this.setState({
                disableAddAgentPostcodeDistrictButton: false,
            });
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Add Postcode District Failed', { variant: 'error' });
        });
    };

    handleComplaintChange = (event, newValue) => {
        this.setState({ currentComplaintTab: newValue });
    };

    handleAcknowledgementLetterDateChange = (chosenDate) => {
        this.setState((state) => ({
            editComplaintData: {
                ...state.editComplaintData,
                acknowledgementLetterDate: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    handleSummaryResolutionLetterDateChange = (chosenDate) => {
        this.setState((state) => ({
            editComplaintData: {
                ...state.editComplaintData,
                summaryResolutionLetterDate: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    handleFinalResponseLetterDateChange = (chosenDate) => {
        this.setState((state) => ({
            editComplaintData: {
                ...state.editComplaintData,
                finalResponseLetterDate: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    handleHoldingLetterDateChange = (chosenDate) => {
        this.setState((state) => ({
            editComplaintData: {
                ...state.editComplaintData,
                holdingLetterDate: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    handleEightWeekLetterDateChange = (chosenDate) => {
        this.setState((state) => ({
            editComplaintData: {
                ...state.editComplaintData,
                eightWeekLetterDate: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    render() {
        const {
            addNewHistoryNote,
            addAgentPostcodeDistrict,
            classes,
            // complaintReferenceTypes,
            // complaintSources,
            // complaintStatuses,
            // complaintTypes,
            contact,
            // contactComplaintsLoading,
            contactHistoriesLoading,
            contactLoading,
            errors,
            fetchHistoriesData,
            // fetchComplaintsData,
            // users,
            deleteAgentPostcodeDistrict,
        } = this.props;
        const {
            addAgentPostcodeDistrictData,
            disableAddAgentPostcodeDistrictButton,
            // addComplaintData,
            // editComplaintData,
            // currentComplaintTab,
            // disableAddComplaintSaveButton,
            // disableEditComplaintSaveButton,
            // showAddComplaintDialog,
            // showEditComplaintDialog,
        } = this.state;

        // let currentComplaint = {};
        // if (get(contact, `complaints[${currentComplaintTab}]`)) {
        //     currentComplaint = contact.complaints[currentComplaintTab];
        // }

        let currentContactHistories = null;
        if (!contactLoading && !contactHistoriesLoading) {
            currentContactHistories = contact.histories.filter((history) => history.entryType === 'AGENT' && history.entryTypeReferenceCode === `AC${contact.contactAgent.code}`);
        }

        // let currentReferenceCodes = [];
        // switch (addComplaintData.referenceType) {
        // case 'AGREEMENT': currentReferenceCodes = contact.agreements.map((agreement) => ({ prefix: 'RA', code: agreement.code }));
        //     break;
        // case 'SALE': currentReferenceCodes = contact.sales.map((sale) => ({ prefix: 'S', code: sale.code }));
        //     break;
        // case 'VISIT': currentReferenceCodes = contact.visits.map((visit) => ({ prefix: 'V', code: visit.code }));
        //     break;
        // case 'LEAD': currentReferenceCodes = contact.leads.map((lead) => ({ prefix: 'L', code: lead.code }));
        //     break;
        // default: currentReferenceCodes = [];
        // }

        const chips = Object.keys(contact.contactAgent.postcodeDistricts || {}).map((key) => (
            <Chip
                key={key}
                label={contact.contactAgent.postcodeDistricts[key]}
                onDelete={this.props.user.userScopes.includes('editAgentPostcodeArea:*') ? () => this.handleDeleteAgentPostcodeDistrict(contact.contactAgent.postcodeDistricts[key]) : null}
                className={classes.chip}
                color="secondary"
            />
        ));

        return (
            <>
                <Tile
                    tileTitle="AGENT"
                    // refreshAction={fetchAgentData}
                    // refreshing={contactAgentLoading}
                    tileMenuItems={[
                        {
                            action: () => this.handleAddComplaintClick(),
                            label: 'Edit Agent  ',
                            section: 1,
                        },
                        // {
                        //     action: () => this.handleEditComplaintClick(),
                        //     label: 'Edit Complaint  ',
                        //     section: 1,
                        //     disabled: isEmpty(currentComplaint),
                        // },
                    ]}
                    scrollToMarker="agentTop"
                >
                    { !contactLoading
                        ? (
                            <>
                                <Paper className={classes.tabsPaper}>
                                    {/* <ContactSaleBadges
                                                    contact={contact}
                                                    contactSalesLoading={contactSalesLoading}
                                                    contactLoading={contactLoading}
                                                    currentSale={currentSale}
                                                        /> */}
                                    <Grid
                                        container
                                        spacing={0}
                                    >
                                        <Grid item xs={3}>
                                            <HybridTextField
                                                cancelled={contact.contactAgent.expired}
                                                label="Status"
                                                text={contact.contactAgent.expired ? 'Expired' : 'Active'}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={0}
                                    >
                                        <Grid item xs={3}>
                                            <HybridTextField
                                                cancelled={contact.contactAgent.expired}
                                                label="Primary Agent"
                                                text={contact.contactAgent.primaryAgent ? 'Yes' : 'No'}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={0}
                                    >
                                        <Grid item xs={3}>
                                            <HybridTextField
                                                cancelled={contact.contactAgent.expired}
                                                label="Notes"
                                                text={contact.contactAgent.notes}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={0}
                                    >
                                        <Grid item xs={12}>
                                            <div className={classes.fieldTitle}>
                                                Postcode Districts
                                            </div>
                                            {chips}
                                        </Grid>
                                    </Grid>

                                    {this.props.user.userScopes.includes('editAgentPostcodeArea:*')
                                        ? (
                                            <Grid
                                                container
                                                spacing={0}
                                            >
                                                <Grid item xs={1} className={classes.addPostcodeGrid}>
                                                    <TextField
                                                        onChange={this.updateAddAgentPostcodeDistrictData}
                                                        // className={classes.addPostcodeDistrictField}
                                                        name="postcodeDistrict"
                                                        id="postcodeDistrict"
                                                        // label="postcodeDistrict"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        errors={!!errors.postcodeDistrict}
                                                        helperText={errors.postcodeDistrict}
                                                        autoFocus
                                                        value={addAgentPostcodeDistrictData.postcodeDistrict}
                                                    />
                                                </Grid>
                                                <Button
                                                    className={classes.addPostcodeDistrictButton}
                                                    onClick={this.handleAddPostcodeDistrict}
                                                    disabled={disableAddAgentPostcodeDistrictButton || !addAgentPostcodeDistrictData.postcodeDistrict || contact.contactAgent.postcodeDistricts.includes(addAgentPostcodeDistrictData.postcodeDistrict)}
                                                >
                                                    ADD
                                                </Button>
                                            </Grid>
                                        ) : null}

                                    {/* <Grid
                                        container
                                        spacing={0}
                                    >
                                        <Grid item xs={3}>
                                            <HybridTextField
                                                cancelled={currentComplaint.status === 'CANCELLED'}
                                                label="Reference Type"
                                                text={titalise(currentComplaint.referenceType)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <HybridTextField
                                                cancelled={currentComplaint.status === 'CANCELLED'}
                                                label="Reference Code"
                                                text={currentComplaint.referenceCode}
                                                // link={`/contact/${currentComplaint.createdByContactCode}`}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={0}
                                    >
                                        <Grid item xs={3}>
                                            <HybridTextField
                                                cancelled={currentComplaint.status === 'CANCELLED'}
                                                label="Created Time/Date"
                                                text={moment(currentComplaint.createdDateTime).format('HH:mm DD/MM/YYYY') || ' '}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <HybridTextField
                                                cancelled={currentComplaint.status === 'CANCELLED'}
                                                label="Created By"
                                                text={currentComplaint.createdBy}
                                                link={`/contact/${currentComplaint.createdByContactCode}`}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <HybridTextField
                                                cancelled={currentComplaint.status === 'CANCELLED'}
                                                label="Source"
                                                text={titalise(currentComplaint.source)}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={0}
                                    >
                                        <Grid item xs={3}>
                                            <HybridTextField
                                                cancelled={currentComplaint.status === 'CANCELLED'}
                                                label="Complaint Type"
                                                text={titalise(currentComplaint.type)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <HybridTextField
                                                cancelled={currentComplaint.status === 'CANCELLED'}
                                                label="Branch"
                                                text={contact.branch}
                                                link={`/contact/${currentComplaint.createdByContactCode}`}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={0}
                                    >
                                        <Grid
                                            container
                                            spacing={0}
                                        >

                                            <Grid item xs={12}>
                                                <HybridTextField
                                                    label="Description"
                                                    text={currentComplaint.description}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={0}
                                    >
                                        <Grid
                                            container
                                            spacing={0}
                                        >

                                            <Grid item xs={12}>
                                                <HybridTextField
                                                    label="Acknowledgement Letter"
                                                    text={currentComplaint.acknowledgementLetterDate ? moment(currentComplaint.acknowledgementLetterDate).format('DD/MM/YY') : 'Not Sent'}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <HybridTextField
                                                    label="Summary Resolution Letter"
                                                    text={currentComplaint.summaryResolutionLetterDate ? moment(currentComplaint.summaryResolutionLetterDate).format('DD/MM/YY') : 'Not sent'}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <HybridTextField
                                                    label="Final Response Letter"
                                                    text={currentComplaint.finalResponseLetterDate ? moment(currentComplaint.finalResponseLetterDate).format('DD/MM/YY') : 'Not sent'}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <HybridTextField
                                                    label="Holding Letter"
                                                    text={currentComplaint.holdingLetterDate ? moment(currentComplaint.holdingLetterDate).format('DD/MM/YY') : 'Not sent'}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <HybridTextField
                                                    label="8 Week Letter"
                                                    text={currentComplaint.eightWeekLetterDate ? moment(currentComplaint.eightWeekLetterDate).format('DD/MM/YY') : 'Not sent'}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid> */}
                                </Paper>
                            </>
                        )
                        : (
                            <div className={classes.progressContainer}>
                                <CircularProgress className={classes.progress} variant="indeterminate" />
                            </div>
                        )}

                    {/* <Dialog
                        open={showAddComplaintDialog}
                        onClose={this.handleAddComplaintCloseClick}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent className={classes.dialogContent}>
                            <div className={classes.dialogContentContainer}>
                                <>
                                    <Typography variant="h6" className={classes.dialogTitle}>
                                        Add Contact Complaint
                                    </Typography>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <FormControl
                                                className={classes.menuFormControl}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                error={!!errors.complaintType}
                                            >
                                                <InputLabel htmlFor="complaintType" className={classes.inputLabel}>Complaint Type</InputLabel>
                                                <Select
                                                    keyboard="true"
                                                    input={(
                                                        <OutlinedInput
                                                            onChange={this.updateAddComplaintData}
                                                            name="complaintType"
                                                            id="complaintType"
                                                        />
                                                    )}
                                                    value={addComplaintData.complaintType || ''}
                                                >
                                                    <MenuItem value=""><em>None</em></MenuItem>
                                                    {complaintTypes.map((complaintType) => (
                                                        <MenuItem
                                                            key={complaintType}
                                                            value={complaintType}
                                                        >
                                                            {titalise(complaintType)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errors.complaintType && (
                                                    <FormHelperText>{errors.complaintType}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <Grid item xs={6}>
                                            <FormControl
                                                className={classes.menuFormControl}
                                                variant="outlined"
                                                fullWidth
                                                error={!!errors.referenceType}
                                            >
                                                <InputLabel htmlFor="referenceType" className={classes.inputLabel}>Reference Type</InputLabel>
                                                <Select
                                                    keyboard="true"
                                                    input={(
                                                        <OutlinedInput
                                                            onChange={this.updateAddComplaintData}
                                                            name="referenceType"
                                                            id="referenceType"
                                                        />
                                                    )}
                                                    value={addComplaintData.referenceType || ''}
                                                >
                                                    <MenuItem value=""><em>None</em></MenuItem>
                                                    {complaintReferenceTypes.map((referenceType) => (
                                                        <MenuItem
                                                            key={referenceType}
                                                            value={referenceType}
                                                        >
                                                            {titalise(referenceType)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errors.referenceType && (
                                                    <FormHelperText>{errors.referenceType}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={1} />
                                        <Grid item xs={5}>
                                            <FormControl
                                                className={classNames(classes.menuFormControl, classes.column2Dialog)}
                                                variant="outlined"
                                                fullWidth
                                                error={!!errors.referenceCode}
                                                disabled={!addComplaintData.referenceType}
                                            >
                                                <InputLabel htmlFor="referenceCode" className={classes.inputLabel}>Ref. Code</InputLabel>
                                                <Select
                                                    keyboard="true"
                                                    input={(
                                                        <OutlinedInput
                                                            onChange={this.updateAddComplaintData}
                                                            name="referenceCode"
                                                            id="referenceCode"
                                                        />
                                                    )}
                                                    value={addComplaintData.referenceCode || ''}
                                                >
                                                    <MenuItem value=""><em>None</em></MenuItem>
                                                    {currentReferenceCodes.map((referenceCode) => (
                                                        <MenuItem
                                                            key={referenceCode.code}
                                                            value={referenceCode.code}
                                                        >
                                                            {`${referenceCode.prefix}${referenceCode.code}`}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errors.referenceCode && (
                                                    <FormHelperText>{errors.referenceCode}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <Grid item xs={7}>
                                            <FormControl
                                                className={classes.menuFormControl}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                error={!!errors.source}
                                            >
                                                <InputLabel htmlFor="source" className={classes.inputLabel}>Complaint Source</InputLabel>
                                                <Select
                                                    keyboard="true"
                                                    input={(
                                                        <OutlinedInput
                                                            onChange={this.updateAddComplaintData}
                                                            name="source"
                                                            id="source"
                                                        />
                                                    )}
                                                    value={addComplaintData.source || ''}
                                                >
                                                    <MenuItem value=""><em>None</em></MenuItem>
                                                    {complaintSources.map((source) => (
                                                        <MenuItem
                                                            key={source}
                                                            value={source}
                                                        >
                                                            {titalise(source)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errors.source && (
                                                    <FormHelperText>{errors.source}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <TextField
                                                onChange={this.updateAddComplaintData}
                                                className={classes.formControl}
                                                name="description"
                                                id="description"
                                                label="description"
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                multiline
                                                rows="6"
                                                errors={!!errors.description}
                                                helperText={errors.description}
                                                autoFocus
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <FormControl
                                                className={classes.menuFormControl}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                error={!!errors.assignedToContactCode}
                                            >
                                                <InputLabel htmlFor="assignedToContactCode" className={classes.inputLabel}>Complaint Assigned To</InputLabel>
                                                <Select
                                                    keyboard="true"
                                                    input={(
                                                        <OutlinedInput
                                                            onChange={this.updateAddComplaintData}
                                                            name="assignedToContactCode"
                                                            id="assignedToContactCode"
                                                        />
                                                    )}
                                                    value={addComplaintData.assignedToContactCode || ''}
                                                >
                                                    <MenuItem value=""><em>None</em></MenuItem>
                                                    {users.map((user) => (
                                                        <MenuItem
                                                            key={user.contactCode}
                                                            value={user.contactCode}
                                                        >
                                                            {`${user.contact.firstname} ${user.contact.surname}`}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errors.assignedToContactCode && (
                                                    <FormHelperText>{errors.assignedToContactCode}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={12}>
                                            <Button
                                                className={classes.dialogButton}
                                                onClick={this.handleAddComplaint}
                                                disabled={
                                                    disableAddComplaintSaveButton
                                                    || !addComplaintData.complaintType
                                                    || !addComplaintData.source
                                                    || !addComplaintData.description
                                                    || !addComplaintData.assignedToContactCode
                                                }
                                            >
                                                SAVE
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            </div>
                        </DialogContent>
                        <Divider />
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                onClick={this.handleAddComplaintCloseClick}
                                color="primary"
                                autoFocus
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={showEditComplaintDialog}
                        onClose={this.handleEditComplaintCloseClick}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent className={classes.dialogContent}>
                            <div className={classes.dialogContentContainer}>
                                <>
                                    <Typography variant="h6" className={classes.dialogTitle}>
                                        Edit Contact Complaint
                                    </Typography>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <FormControl
                                                className={classes.menuFormControl}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                error={!!errors.status}
                                            >
                                                <InputLabel htmlFor="status" className={classes.inputLabel}>Complaint Type</InputLabel>
                                                <Select
                                                    keyboard="true"
                                                    input={(
                                                        <OutlinedInput
                                                            onChange={this.updateEditComplaintData}
                                                            name="status"
                                                            id="status"
                                                        />
                                                    )}
                                                    value={editComplaintData.status || ''}
                                                >
                                                    <MenuItem value=""><em>None</em></MenuItem>
                                                    {complaintStatuses.map((status) => (
                                                        <MenuItem
                                                            key={status}
                                                            value={status}
                                                        >
                                                            {titalise(status)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errors.status && (
                                                    <FormHelperText>{errors.status}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <FormControl
                                                className={classes.menuFormControl}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                error={!!errors.assignedToContactCode}
                                            >
                                                <InputLabel htmlFor="assignedToContactCode" className={classes.inputLabel}>Complaint Assigned To</InputLabel>
                                                <Select
                                                    keyboard="true"
                                                    input={(
                                                        <OutlinedInput
                                                            onChange={this.updateEditComplaintData}
                                                            name="assignedToContactCode"
                                                            id="assignedToContactCode"
                                                        />
                                                    )}
                                                    value={editComplaintData.assignedToContactCode || ''}
                                                >
                                                    <MenuItem value=""><em>None</em></MenuItem>
                                                    {users.map((user) => (
                                                        <MenuItem
                                                            key={user.contactCode}
                                                            value={user.contactCode}
                                                        >
                                                            {`${user.contact.firstname} ${user.contact.surname}`}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errors.assignedToContactCode && (
                                                    <FormHelperText>{errors.assignedToContactCode}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <Grid item xs={8}>
                                            <DatePicker
                                                name="acknowledgementLetterDate"
                                                id="acknowledgementLetterDate"
                                                margin="normal"
                                                label="Acknowledgement Letter Date"
                                                value={editComplaintData.acknowledgementLetterDate}
                                                onChange={this.handleAcknowledgementLetterDateChange}
                                                fullWidth
                                                variant="outlined"
                                                format="DD/MM/YYYY"
                                                showTodayButton
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <Grid item xs={8}>
                                            <DatePicker
                                                name="summaryResolutionLetterDate"
                                                id="summaryResolutionLetterDate"
                                                margin="normal"
                                                label="SummaryResolution Letter Date"
                                                value={editComplaintData.summaryResolutionLetterDate}
                                                onChange={this.handleSummaryResolutionLetterDateChange}
                                                fullWidth
                                                variant="outlined"
                                                format="DD/MM/YYYY"
                                                showTodayButton
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <Grid item xs={8}>
                                            <DatePicker
                                                name="finalResponseLetterDate"
                                                id="finalResponseLetterDate"
                                                margin="normal"
                                                label="Final Response Letter Date"
                                                value={editComplaintData.finalResponseLetterDate}
                                                onChange={this.handleFinalResponseLetterDateChange}
                                                fullWidth
                                                variant="outlined"
                                                format="DD/MM/YYYY"
                                                showTodayButton
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <Grid item xs={8}>
                                            <DatePicker
                                                name="holdingLetterDate"
                                                id="holdingLetterDate"
                                                margin="normal"
                                                label="Holding Letter Date"
                                                value={editComplaintData.holdingLetterDate}
                                                onChange={this.handleHoldingLetterDateChange}
                                                fullWidth
                                                variant="outlined"
                                                format="DD/MM/YYYY"
                                                showTodayButton
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <Grid item xs={8}>
                                            <DatePicker
                                                name="eightWeekLetterDate"
                                                id="eightWeekLetterDate"
                                                margin="normal"
                                                label="Eight Week Letter Date"
                                                value={editComplaintData.eightWeekLetterDate}
                                                onChange={this.handleEightWeekLetterDateChange}
                                                fullWidth
                                                variant="outlined"
                                                format="DD/MM/YYYY"
                                                showTodayButton
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={12}>
                                            <Button
                                                className={classes.dialogButton}
                                                onClick={this.handleEditComplaint}
                                                disabled={
                                                    disableEditComplaintSaveButton
                                                    || !editComplaintData.status
                                                    || !editComplaintData.assignedToContactCode
                                                }
                                            >
                                                SAVE
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            </div>
                        </DialogContent>
                        <Divider />
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                onClick={this.handleEditComplaintCloseClick}
                                color="primary"
                                autoFocus
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog> */}

                </Tile>
                <ContactHistory
                    addNewHistoryNote={addNewHistoryNote}
                    contact={contact}
                    contactHistoriesLoading={contactHistoriesLoading}
                    entryType="COMPLAINT"
                    entryTypeReferenceCode={`AC${contact.contactAgent.code}`}
                    fetchHistoriesData={fetchHistoriesData}
                    histories={currentContactHistories}
                    tileTitlePostfix={` (AC${contact.contactAgent.code})`}
                    errors={errors}
                />
            </>
        );
    }
}

export default withSnackbar(withStyles(styles)(ContactAgent));
