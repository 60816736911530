import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import fetch, { fetchJson2 } from '../../utilities/fetch';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    dialogContentContainer: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    dialogContent: {
        minWidth: 600,
        paddingBottom: 16,
    },
    dialogButton: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 4,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        margin: '0px 0px 0px 0px !important',
        padding: '8px 24px',
    },
    dialogText: {
        marginTop: theme.spacing.unit * 2,
    },
    disabled: {
        opacity: 0.26,
    },
    dialogTitle: {
        marginBottom: theme.spacing.unit * 2,
    },
});

class ManufacturerSupplierExpireDialog extends React.Component {
    state = {
        disableExpireManufacturerSupplierSaveButton: false,
    };

    handleExpireManufacturerSupplier = () => {
        this.setState({
            disableExpireManufacturerSupplierSaveButton: true,
        });
        this.expireManufacturerSupplier(this.props.manufacturerSupplier.code).then(() => {
            this.setState({
                disableExpireManufacturerSupplierSaveButton: false,
            });
            this.props.handleCloseExpireManufacturerSupplierDialogClick();
            this.props.enqueueSnackbar('Expire Manufacturer/Supplier Success', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Expire Manufacturer/Supplier Failed', { variant: 'error' });
            this.setState({
                disableExpireManufacturerSupplierSaveButton: false,
            });
        });
    };

    expireManufacturerSupplier = async (manufacturerSupplierCode) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/manufacturerSupplier/${manufacturerSupplierCode}`, {
                body: JSON.stringify({ expired: 1 }),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
              && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            const newManufacturerSupplier = {
                ...body.manufacturerSupplier,
                histories: [
                    body.history,
                    ...this.props.manufacturerSupplier.histories,
                ],
            };
            this.props.passNewManufacturerSupplier(newManufacturerSupplier);
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    render() {
        const {
            classes,
            handleCloseExpireManufacturerSupplierDialogClick,
            manufacturerSupplier,
            manufacturerSupplierLoading,
            showExpireManufacturerSupplierDialog,
        } = this.props;
        const {
            disableExpireManufacturerSupplierSaveButton,
        } = this.state;

        return (
            <>
                {!manufacturerSupplierLoading
                    ? (
                        <Dialog
                            open={showExpireManufacturerSupplierDialog}
                            onClose={handleCloseExpireManufacturerSupplierDialogClick}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            className={classes.newManufacturerDialog}
                        >
                            <DialogContent className={classes.dialogContent}>
                                <div className={classes.dialogContentContainer}>
                                    <>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            className={classes.dialogTitle}
                                        >
                                            Expire Manufacturer Supplier
                                        </Typography>
                                        <DialogContentText className={classes.dialogText}>
                                            Are you sure you want to expire this Manufacturer/Supplier?
                                        </DialogContentText>
                                        <Grid
                                            container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center"
                                        >
                                            <Grid item xs={12}>
                                                <Button
                                                    className={classes.dialogButton}
                                                    type="submit"
                                                    onClick={this.handleExpireManufacturerSupplier}
                                                    disabled={disableExpireManufacturerSupplierSaveButton}
                                                >
                                                    <div>
                                                        <div>EXPIRE</div>
                                                        <div>{manufacturerSupplier.name}</div>
                                                    </div>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </>
                                </div>
                            </DialogContent>
                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    onClick={handleCloseExpireManufacturerSupplierDialogClick}
                                    color="primary"
                                    autoFocus
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                    )
                    : ''}
            </>
        );
    }
}

export default withSnackbar(withStyles(styles)(ManufacturerSupplierExpireDialog));
