import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    OutlinedInput,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { DateTimePicker } from 'material-ui-pickers';
import fetch, { fetchJson2 } from '../../utilities/fetch';
import currentDateTime from '../../utilities/currentDateTime';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    bumpBottom3: {
        marginBottom: theme.spacing.unit * 3,
    },
    dialogContentContainer: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    dialogContent: {
        minWidth: 600,
        paddingBottom: 16,
    },
    dialogButton: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 4,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        margin: '0px 0px 0px 0px !important',
        padding: '8px 24px',
    },
    dialogText: {
        marginTop: theme.spacing.unit * 2,
    },
    disabled: {
        opacity: 0.26,
    },
    dialogTitle: {
        marginBottom: theme.spacing.unit * 2,
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
});

class TransferEngineerVisitsDialog extends React.Component {
    state = {
        disableTransferButton: false,
        transferEngineerVisitsData: {
            transferDateTime: currentDateTime().dateTime,
            transferToEngineerCode: '',
        },
    };

    handleTransferVisits = () => {
        const transferDateTime = moment(this.state.transferEngineerVisitsData.transferDateTime).format('YYYY-MM-DD HH:i');
        this.setState({
            disableTransferButton: true,
        });
        this.transferEngineerVisits(this.props.engineer.engineer.code, this.state.transferEngineerVisitsData.transferToEngineerCode, transferDateTime).then(() => {
            this.setState({
                disableTransferButton: false,
            });
            this.props.handleCloseTransferEngineerVisitsDialogClick();
            this.props.enqueueSnackbar('Transfer Engineer Visits Success', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Transfer Engineer Visits Failed', { variant: 'error' });
            this.setState({
                disableTransferButton: false,
            });
        });
    };

    transferEngineerVisits = async (
        engineerCode,
        transferToEngineerCode,
        transferDateTime,
    ) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/engineer/${engineerCode}/transferVisits`, {
                body: JSON.stringify({
                    transferDateTime,
                    transferToEngineerCode,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
            && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        if (response.status !== 200) throw new Error({ error: 'Generic API error' });
        switch (response.status) {
        case 200: {
            const newEngineer = {
                ...body.engineer,
                histories: [
                    body.history,
                    ...this.props.engineer.histories,
                ],
            };
            this.props.passNewEngineer(newEngineer);
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    updateTransferEngineerVisitsData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;
        this.setState((state) => ({
            transferEngineerVisitsData: {
                ...state.transferEngineerVisitsData,
                [name]: formattedValue,
            },
        }));
    };

    handleTransferDateTimeChange = (chosenDate) => {
        this.setState((state) => ({
            transferEngineerVisitsData: {
                ...state.transferEngineerVisitsData,
                transferDateTime: moment(chosenDate).format('YYYY-MM-DD HH:mm'),
            },
        }));
    };

    render() {
        const {
            classes,
            handleCloseTransferEngineerVisitsDialogClick,
            engineer,
            engineerLoading,
            engineers,
            showTransferEngineerVisitsDialog,
        } = this.props;
        const {
            disableTransferButton,
            transferEngineerVisitsData,
        } = this.state;

        return (
            <>
                {!engineerLoading
                    ? (
                        <Dialog
                            open={showTransferEngineerVisitsDialog}
                            onClose={handleCloseTransferEngineerVisitsDialogClick}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogContent className={classes.dialogContent}>
                                <div className={classes.dialogContentContainer}>
                                    <>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            className={classes.dialogTitle}
                                        >
                                            Transfer Operative Visits
                                        </Typography>
                                        {/* <DialogContentText className={classNames(classes.dialogText, classes.bumpBottom3)}>
                                            Are you sure you want to expire this operative?
                                        </DialogContentText> */}
                                        <Grid
                                            container
                                            spacing={0}
                                            // direction="column"
                                            alignItems="center"
                                            justify="center"
                                        >
                                            <Grid item xs={12}>
                                                <FormControl
                                                    className={classes.menuFormControl}
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                >
                                                    <InputLabel htmlFor="transferToEngineerCode" className={classes.inputLabel}>Transfer To</InputLabel>
                                                    <Select
                                                        keyboard="true"
                                                        input={(
                                                            <OutlinedInput
                                                                onChange={this.updateTransferEngineerVisitsData}
                                                                name="transferToEngineerCode"
                                                                id="transferToEngineerCode"
                                                            />
                                                        )}
                                                        value={transferEngineerVisitsData.transferToEngineerCode || ''}
                                                    >
                                                        <MenuItem value=""><em>None</em></MenuItem>
                                                        {engineers.map((toEngineer) => (
                                                            <MenuItem
                                                                key={toEngineer.engineer.code}
                                                                value={toEngineer.engineer.code}
                                                            >
                                                                {`${toEngineer.firstname} ${toEngineer.surname}`}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <DateTimePicker
                                                name="scheduledVisitDateTime"
                                                id="scheduledVisitDateTime"
                                                margin="normal"
                                                label="Transfer From Date & Time"
                                                value={transferEngineerVisitsData.transferDateTime}
                                                onChange={this.handleTransferDateTimeChange}
                                                fullWidth
                                                variant="outlined"
                                                format="DD/MM/YYYY HH:mm"
                                                showTodayButton
                                                invalidLabel="No limit"
                                                autoOk
                                                ampm={false}
                                            />
                                            <Grid
                                                item
                                                xs={12}
                                                align="center"
                                            >
                                                <Button
                                                    className={classes.dialogButton}
                                                    type="submit"
                                                    onClick={this.handleTransferVisits}
                                                    disabled={disableTransferButton}
                                                >
                                                    TRANSFER
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </>
                                </div>
                            </DialogContent>
                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    onClick={handleCloseTransferEngineerVisitsDialogClick}
                                    color="primary"
                                    autoFocus
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                    )
                    : ''}
            </>
        );
    }
}

export default withSnackbar(withStyles(styles)(TransferEngineerVisitsDialog));
