import React from 'react';

import {
    Button,
    List,
    ListItem,
    ListItemText,
    FormControl,
    Grid,
    Paper,
    TextField,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import qs from 'qs';
import SignatureCanvas from 'react-signature-canvas';
import { red } from '@material-ui/core/colors';
import { faAlignCenter } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import fetch from '../../utilities/fetch';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    listItem: {
        marginBottom: 16,
    },
    link: {
        paddingTop: 24,
        textAlign: 'center',
    },

    buttonBar: {
        position: 'fixed',
        paddingTop: 11,
        paddingBottom: 18,
        bottom: 0,
        width: '100%',
        height: 30,
        boxShadow: '0px 1px 8px 0px rgb(0 0 0 / 80%)',
        backgroundColor: '#f2f1f1',
        textAlign: 'center',
    },
    topButtonBar: {
        position: 'fixed',
        paddingTop: 11,
        paddingBottom: 18,
        top: 0,
        width: '100%',
        height: 30,
        // boxShadow: '0px 1px 8px 0px rgb(0 0 0 / 80%)',
        backgroundColor: 'aliceblue',
        textAlign: 'left',
    },
    clearButton: {
        textAlign: 'right',
        paddingRight: theme.spacing.unit * 2,
    },
    signatureText: {
        textAlign: 'left',
        paddingLeft: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit * 1,
    },
    contentContainer: {
        paddingBottom: 40,
        maxWidth: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    logo: {
        margin: theme.spacing.unit * 7,
        width: 128,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    logoContainer: {
        textAlign: 'center',
    },
    startContainer: {
        paddingRight: theme.spacing.unit * 4,
        paddingLeft: theme.spacing.unit * 4,
    },
    text: {
        fontSize: 16,
    },
    buttonContainer: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
    centre: {
        textAlign: 'center',
    },
    signOff: {
        paddingTop: theme.spacing.unit * 2,
    },
    dateTime: {
        fontWieght: 700,
        fontSize: '2em',
    },
});

class AndroidAppDownload extends React.Component {
    state = {
        androidAppLink: '',
        androidAppVersion: '',
    };

    componentDidMount = async () => {
        await this.fetchLatestAndroidApp();
    };

    fetchLatestAndroidApp = async () => {
        let response;
        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/p/androidApp/link`, {
                method: 'GET',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        let body;
        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        console.log('>>> response.body:', response.body);

        if (response.status === 200) {
            this.setState({
                androidAppLink: body.link,
                androidAppVersion: body.version,
            });
        }
    };

    render = () => {
        const { classes } = this.props;
        const {
            androidAppLink,
            androidAppVersion,
        } = this.state;
        // const testLink = 'http://192.168.1.29:3000/app-release.apk';
        const imageUrl = window.location.host === 'portal2.payweeklycarpets.co.uk' ? 'https://pwc-email-images.s3.eu-west-1.amazonaws.com/logo-main-small.png' : 'https://pwc-email-images.s3.eu-west-1.amazonaws.com/ass_logo_main_small.jpg';

        return (
            <>
                <div className={classes.logoContainer}>
                    <img
                        className={classes.logo}
                        src={imageUrl}
                        alt="Pay Weekly Logo"
                    />
                </div>

                <div className={classes.contentContainer}>
                    <div className={classes.startContainer}>
                        <Typography variant="h6" className={[classes.text, classes.centre]}>
                            Installing Portal 2 Mobile App for Android
                        </Typography>

                        <ol type="1">
                            <li className={classes.listItem}>Open Settings > Apps & notifications.</li>
                            <li className={classes.listItem}>Expand the Advanced section at the bottom and tap Special app access.</li>
                            <li className={classes.listItem}>On the resulting menu, scroll down and choose Install unknown apps. On Android 12, you'll find this setting under Apps > Special app access; there is no Advanced heading.</li>
                            <li className={classes.listItem}>In this menu, you'll see a list of apps on your device that have the capability to install other apps. Select the browser you will be using to install this App. Then enable the Allow from this source slider.</li>
                            <li className={classes.listItem}>Click the link below and confirm you wish to install.</li>
                        </ol>
                        <div className={classes.link}>
                            {/* <a href={androidAppLink} download> */}
                            <a href="/app-release.apk" download>
                                Portal 2 Android APP version
                                {' '}
                                {androidAppVersion}
                            </a>
                        </div>
                    </div>
                </div>
            </>

        );
    };
}

export default withSnackbar(withStyles(styles)(withRouter(AndroidAppDownload)));
