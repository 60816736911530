import React from 'react';
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Info from '@material-ui/icons/Info';
import orderBy from 'lodash/orderBy';

const styles = theme => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
});

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

class DeliveryNotesTable extends React.Component {
    state = {

    }

    render() {
        const {
            classes,
            deliveryNotes,
        } = this.props;

        return (
            <Table className={classes.table} padding="dense">
                <TableHead>
                    <TableRow>
                        <CustomTableCell className={classes.tableHead}>Code</CustomTableCell>
                        <CustomTableCell className={classes.tableHead}>Reference</CustomTableCell>
                        <CustomTableCell className={classes.tableHead}>Supplier</CustomTableCell>
                        <CustomTableCell className={classes.tableHead}>Delivery Date</CustomTableCell>
                        <CustomTableCell className={classes.tableHead}>Processed By</CustomTableCell>
                        <CustomTableCell className={classes.tableHead}>Delivery Branch</CustomTableCell>
                        <CustomTableCell className={classes.tableHead} />
                    </TableRow>
                </TableHead>
                <TableBody> 
                    {orderBy(deliveryNotes, 'code', 'desc').map(deliveryNote => (
                        <TableRow onClick={() => this.props.moveToDeliveryNote(deliveryNote.code)} className={classes.row} key={deliveryNote.code}>
                            <CustomTableCell className={classes.customTableCell} align="left">{`DN${deliveryNote.code}`}</CustomTableCell>
                            <CustomTableCell className={classes.customTableCell} align="left">{deliveryNote.reference}</CustomTableCell>
                            <CustomTableCell className={classes.customTableCell} align="left">{deliveryNote.supplierName}</CustomTableCell>
                            <CustomTableCell className={classes.customTableCell} align="left">{deliveryNote.deliveryDate}</CustomTableCell>
                            <CustomTableCell className={classes.customTableCell} align="left">{deliveryNote.processedByFullName}</CustomTableCell>
                            <CustomTableCell className={classes.customTableCell} align="left">{deliveryNote.deliveryBranchName}</CustomTableCell>
                            <CustomTableCell className={classes.customTableCell} align="center"><IconButton className={classes.button} aria-label="More info"><Info /></IconButton></CustomTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    }
}

const formShape = {
    code: PropTypes.number,
    reference: PropTypes.string,
    supplierName: PropTypes.string,
    deliveryDate: PropTypes.string,
    processedByFullName: PropTypes.string,
    deliveryBranchName: PropTypes.string,
};

DeliveryNotesTable.propTypes = {
    classes: PropTypes.object.isRequired,
    deliveryNotes: PropTypes.arrayOf(PropTypes.shape(formShape).isRequired).isRequired,
};

export default withStyles(styles)(DeliveryNotesTable);
