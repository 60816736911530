import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import uniqBy from 'lodash/uniqBy';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';


const styles = theme => ({
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
        minWidth: 300,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
});

class deliveryNoteItemsAddDialog extends React.Component {
    state = {
        disableSubmitButton: false,
        showDialog: false,
        formData: {
            orderReference: '',
            customerReference: '',
            deliveryNoteCode: '',
            manufacturerCode: '',
            productCode: '',
            length: '',
            width: '',
            quantity: 1,
            printLabel: 1,
            openingStockParent: 0,
            stockroomCode: '',
            binCode: '',
        },
    }

    componentDidUpdate(prevProps) {
        if (this.props.showAddItemDialog !== prevProps.showAddItemDialog) {
            if (this.props.showAddItemDialog) this.handleOpenDialog();
            this.props.resetShowAddItemDialog();
        }
    }

    handleOpenDialog = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                customerReference: '',
                deliveryNoteCode: '',
                manufacturerCode: '',
                productCode: '',
                length: '',
                width: '',
                quantity: 1,
                printLabel: 1,
                openingStockParent: 0,
                binCode: '',
            },
            showDialog: true,
        });
    }

    handleCloseDialog = () => {
        this.setState({
            showDialog: false,
        });
    }

    handleDateChange = (chosenDate) => {
        this.setState(state => ({
            formData: {
                ...state.formData,
                date: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    }

    updateData = (event) => {
        const { value, name } = event.target;
        let formattedValue;
        let newFields;
        switch (name) {
        case 'manufacturerCode':
            newFields = {
                productCode: '',
            };
            formattedValue = value;
            break;
        case 'stockroomCode':
            newFields = {
                binCode: '',
            };
            formattedValue = value;
            break;
        case 'productCode':
            if (value !== '') {
                newFields = {
                    width: this.props.products.find(product => product.code === value).defaultWidth,
                    length: this.props.products.find(product => product.code === value).defaultLength,
                    stockControlTypeDescription: this.props.products.find(product => product.code === value).stockControlTypeDescription,
                };
            }
            formattedValue = value;
            break;
        case 'length':
        case 'width':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            break;
        case 'quantity':
            formattedValue = parseInt(value.replace(/[^0-9$]/g, ''), 10);
            break;
        case 'orderReference':
        case 'customerReference':
            formattedValue = value.toUpperCase();
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState(state => ({
            formData: {
                ...state.formData,
                [name]: formattedValue,
                ...newFields,
            },
        }));
    }

    removeEmpty = (obj) => {
        const newObj = {};

        Object.keys(obj).forEach((key) => {
            if (obj[key] && typeof obj[key] === 'object') {
                newObj[key] = this.removeEmpty(obj[key]); // recurse
            } else if ((obj[key] != null) && (obj[key] !== '')) {
                newObj[key] = obj[key]; // copy value
            }
        });

        return newObj;
    };

    handleSubmitButtonClick = () => {
        const cleanedFormData = {
            ...this.removeEmpty(this.state.formData),
            deliveryNoteCode: this.props.deliveryNote.code,
            deliveryBranchCode: this.props.deliveryNote.deliveryBranchCode,
        };
        this.setState({
            disableSubmitButton: true,
        });
        this.props.addDeliveryNoteItem(cleanedFormData).then(() => {
            this.setState({
                showDialog: false,
                disableSubmitButton: false,
            });
            this.props.enqueueSnackbar('New Delivery Note Item Added', { variant: 'success' });
        }).catch(() => {
            this.setState({
                disableSubmitButton: false,
            });
            this.props.enqueueSnackbar('Add New Delivery Note Item failed', { variant: 'error' });
        });
    }

    updateCheckbox = name => (event) => {
        const newState = event.target.checked ? 1 : 0;
        console.log(name, newState);
        this.setState(state => ({
            formData: {
                ...state.formData,
                [name]: newState,
            },
        }));
    }

    updateCheckboxReverse = name => (event) => {
        const newState = event.target.checked ? 0 : 1;
        console.log(name, newState);
        this.setState(state => ({
            formData: {
                ...state.formData,
                [name]: newState,
            },
        }));
    }

    render() {
        const {
            classes,
            deliveryNote,
            errors,
            products,
            stockrooms,
            bins,
        } = this.props;

        const {
            disableSubmitButton,
            formData,
            showDialog,
        } = this.state;

        const selectedProduct = (products || []).filter(product => product.code === formData.productCode)[0] || {};
        console.log('>>>', selectedProduct);

        return (
            <React.Fragment>
                {products !== null
                    && stockrooms !== null
                    && bins !== null
                    ? (
                        <Dialog
                            open={showDialog}
                            onClose={this.handleCloseDialog}
                        >
                            <DialogContent className={classes.dialogContent}>
                                <div className={classes.dialogContentContainer}>
                                    <React.Fragment>
                                        <Typography variant="h6" gutterBottom>
                                            New Delivery Note Item
                                        </Typography>
                                        <form>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="orderReference"
                                                        id="orderReference"
                                                        onChange={this.updateData}
                                                        value={formData.orderReference}
                                                        label="Order Reference"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.orderReference}
                                                        helperText={errors.orderReference}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="customerReference"
                                                        id="customerReference"
                                                        onChange={this.updateData}
                                                        value={formData.customerReference}
                                                        label="Customer Reference"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.customerReference}
                                                        helperText={errors.customerReference}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.manufacturerCode}
                                                    >
                                                        <InputLabel htmlFor="manufacturerCode" className={classes.inputLabel}>Manufacturer</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateData}
                                                                    name="manufacturerCode"
                                                                    id="manufacturerCode"
                                                                />
                                                            )}
                                                            value={formData.manufacturerCode}
                                                        >
                                                            <MenuItem value=""><em>None</em></MenuItem>
                                                            {uniqBy(products, 'manufacturerCode').map(product => (
                                                                <MenuItem
                                                                    key={product.manufacturerCode}
                                                                    value={product.manufacturerCode}
                                                                >
                                                                    {product.manufacturer}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.manufacturerCode && (
                                                            <FormHelperText>{errors.manufacturerCode}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.productCode}
                                                    >
                                                        <InputLabel htmlFor="productCode" className={classes.inputLabel}>ModelNo</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateData}
                                                                    name="productCode"
                                                                    id="productCode"
                                                                />
                                                            )}
                                                            value={formData.productCode}
                                                        >
                                                            <MenuItem value=""><em>None</em></MenuItem>
                                                            {products.filter(product => product.manufacturerCode === formData.manufacturerCode).map(product => (
                                                                <MenuItem
                                                                    key={product.code}
                                                                    value={product.code}
                                                                >
                                                                    {product.modelNo}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.productCode && (
                                                            <FormHelperText>{errors.productCode}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                {(selectedProduct.stockControlTypeDescription === 'Tracked')
                                                    ? (
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="width"
                                                                id="width"
                                                                onChange={this.updateData}
                                                                value={formData.width}
                                                                label="Width (m)"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                error={!!errors.width}
                                                                helperText={errors.width}
                                                            />
                                                        </Grid>
                                                    )
                                                    : null
                                                }
                                                {(selectedProduct.stockControlTypeDescription === 'Tracked')
                                                    ? (
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="length"
                                                                id="length"
                                                                onChange={this.updateData}
                                                                value={formData.length}
                                                                label="Length (m)"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                error={!!errors.length}
                                                                helperText={errors.length}
                                                            />
                                                        </Grid>
                                                    )
                                                    : null
                                                }
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="quantity"
                                                        id="quantity"
                                                        onChange={this.updateData}
                                                        value={formData.quantity}
                                                        label="Quantity"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.quantity}
                                                        helperText={errors.quantity}
                                                    />
                                                </Grid>
                                                {deliveryNote.deliveryBranchHasStockrooms === 1
                                                    ? (
                                                        <Grid item xs={12}>
                                                            <FormControl
                                                                className={classes.menuFormControl}
                                                                variant="outlined"
                                                                fullWidth
                                                                error={!!errors.stockroomCode}
                                                            >
                                                                <InputLabel htmlFor="stockroomCode" className={classes.inputLabel}>Stockroom</InputLabel>
                                                                <Select
                                                                    keyboard="true"
                                                                    input={(
                                                                        <OutlinedInput
                                                                            onChange={this.updateData}
                                                                            name="stockroomCode"
                                                                            id="stockroomCode"
                                                                        />
                                                                    )}
                                                                    value={formData.stockroomCode}
                                                                >
                                                                    <MenuItem value=""><em>None</em></MenuItem>
                                                                    {stockrooms.map(stockroom => (
                                                                        <MenuItem
                                                                            key={stockroom.code}
                                                                            value={stockroom.code}
                                                                        >
                                                                            {stockroom.description}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {errors.stockroomCode && (
                                                                    <FormHelperText>{errors.stockroomCode}</FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Grid>
                                                    )
                                                    : null
                                                }
                                                {(stockrooms.find(stockroom => stockroom.code === formData.stockroomCode) || {}).hasBins === 1
                                                    ? (
                                                        <Grid item xs={12}>
                                                            <FormControl
                                                                className={classes.menuFormControl}
                                                                variant="outlined"
                                                                fullWidth
                                                                error={!!errors.binCode}
                                                            >
                                                                <InputLabel htmlFor="binCode" className={classes.inputLabel}>BIN</InputLabel>
                                                                <Select
                                                                    keyboard="true"
                                                                    input={(
                                                                        <OutlinedInput
                                                                            onChange={this.updateData}
                                                                            name="binCode"
                                                                            id="binCode"
                                                                        />
                                                                    )}
                                                                    value={formData.binCode}
                                                                >
                                                                    <MenuItem value=""><em>None</em></MenuItem>
                                                                    {bins.map(bin => (
                                                                        <MenuItem
                                                                            key={bin.code}
                                                                            value={bin.code}
                                                                        >
                                                                            {bin.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {errors.binCode && (
                                                                    <FormHelperText>{errors.binCode}</FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Grid>
                                                    )
                                                    : null
                                                }

                                            </Grid>
                                            {deliveryNote.supplierName === 'Opening Stock' && selectedProduct.stockControlTypeDescription === 'Tracked'
                                                ? (
                                                    <Grid item xs={12}>
                                                        <FormControlLabel
                                                            control={(
                                                                <Checkbox
                                                                    name="openingStockParent"
                                                                    id="openingStockParent"
                                                                    checked={formData.openingStockParent === 0}
                                                                    onChange={this.updateCheckboxReverse('openingStockParent')}
                                                                />
                                                            )}
                                                            label="Unused Item"
                                                        />
                                                    </Grid>
                                                )
                                                : null
                                            }

                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={(
                                                        <Checkbox
                                                            name="printLabel"
                                                            id="printLabel"
                                                            checked={formData.printLabel === 1}
                                                            onChange={this.updateCheckbox('printLabel')}
                                                        />
                                                    )}
                                                    label="Print Label"
                                                />
                                            </Grid>
                                            <Button
                                                onClick={this.handleSubmitButtonClick}
                                                variant="contained"
                                                className={classes.dialogButton}
                                                disabled={
                                                    disableSubmitButton
                                                    || formData.productCode === ''
                                                    || !formData.quantity > 0
                                                }
                                            >
                                        ADD
                                            </Button>
                                        </form>
                                    </React.Fragment>
                                </div>
                            </DialogContent>
                            <Divider className={classes.dialogActionsDivider} />
                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    onClick={this.handleCloseDialog}
                                    color="primary"
                                    autoFocus
                                >
                            Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                    : null
                }
            </React.Fragment>
        );
    }
}

// const formShape = {
//     manufacturerCode: PropTypes.number,
//     productCode: PropTypes.number,
//     length: PropTypes.number,
//     width: PropTypes.number,
//     quantity: PropTypes.number,
//     printLabel: PropTypes.number,
// };

const deliveryNoteShape = {
    code: PropTypes.number,
    reference: PropTypes.string,
    supplierCode: PropTypes.number,
    supplierName: PropTypes.string,
    deliveryDate: PropTypes.string,
    processedByContactCode: PropTypes.number,
    processedByFullName: PropTypes.string,
    deliveryBranchCode: PropTypes.number,
    deliveryBranchName: PropTypes.string,
};

const productShape = {
    code: PropTypes.number,
    modelNo: PropTypes.string,
    manufacturerCode: PropTypes.number,
};

deliveryNoteItemsAddDialog.propTypes = {
    addDeliveryNoteItem: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    deliveryNote: PropTypes.shape(deliveryNoteShape).isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    products: PropTypes.arrayOf(PropTypes.shape(productShape)).isRequired,
    resetShowAddItemDialog: PropTypes.func.isRequired,
    showAddItemDialog: PropTypes.bool.isRequired,
};

export default withSnackbar(withStyles(styles)(deliveryNoteItemsAddDialog));
