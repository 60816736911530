import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment-timezone';

const styles = theme => ({
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
        width: 500,
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    filterSet: {
        colour: 'red',
    },
    minTextField: {
        paddingRight: theme.spacing.unit * 1.5,
    },
    maxTextField: {
        paddingLeft: theme.spacing.unit * 1.5,
    },
    filterNotSet: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
});

class itemsTableEditDialog extends React.Component {
    state = {
        errors: [],
        disableSubmitButton: false,
        showDialog: false,
        formData: {},
        formDataDefault: {
            branchCode: '',
            itemConditionCode: '',
            itemAssignmentCode: '',
            binCode: '',
        },
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.showEditItemsDialog !== prevProps.showEditItemsDialog) {
            if (this.props.showEditItemsDialog) this.handleOpenDialog();
            this.props.resetShowEditItemsDialog();
        }
    }

    presetFormData = () => {
        this.setState(state => ({
            formData: {
                ...state.formDataDefault,
            },
        }));
    }

    handleOpenDialog = () => {
        this.presetFormData();
        this.setState({
            showDialog: true,
        });
    }

    handleCloseDialog = () => {
        this.setState({
            showDialog: false,
        });
    }

    updateData = (event) => {
        const { value, name } = event.target;
        console.log('>>>',name, value);
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;
        this.setState(state => ({
            formData: {
                ...state.formData,
                [name]: formattedValue,
            },
        }));
    }

    // handleSubmitButtonClick = () => {
    //     this.setState({
    //         disableSubmitButton: true,
    //     });
    //     const cleanedFormData = {};
    //     Object.keys(this.state.formData).map((key) => {
    //         if (this.state.formData[key] !== 'All' && this.state.formData[key] !== '') cleanedFormData[key] = this.state.formData[key];
    //     });
    //
    //
    //     this.props.editItems(cleanedFormData).then(() => {
    //         this.setState({
    //             showDialog: false,
    //             disableSubmitButton: false,
    //         });
    //     });
    // }

    handleSubmitButtonClick = () => {
        this.setState({
            disableSubmitButton: true,
        });
        const cleanedFormData = {
            itemCodes: this.props.selectData,
        };
        Object.keys(this.state.formData).map((key) => {
            if (this.state.formData[key] !== 'All' && this.state.formData[key] !== '') cleanedFormData[key] = this.state.formData[key];
        });

        this.props.editItems(cleanedFormData).then(() => {
            this.setState({
                showDialog: false,
                disableSubmitButton: false,
            });
            this.props.enqueueSnackbar('Selected Items Edited', { variant: 'success' });
        }).catch(() => {
            this.setState({
                disableSubmitButton: false,
            });
            this.props.enqueueSnackbar('Edit Selected Items failed', { variant: 'error' });
        });
    }

    render() {
        const {
            bins,
            classes,
            branches,
            itemsAssignments,
            itemsConditions,
            stockrooms,
        } = this.props;

        const {
            errors,
            disableSubmitButton,
            formData,
            showDialog,
        } = this.state;

        return (
            <React.Fragment>
                {branches !== null
                    && stockrooms !== null
                    && itemsConditions !== null
                    && itemsAssignments !== null
                    ? (
                        <Dialog
                            open={showDialog}
                            onClose={this.handleCloseDialog}
                        >
                            <DialogContent className={classes.dialogContent}>
                                <div className={classes.dialogContentContainer}>
                                    <React.Fragment>
                                        <Grid container spacing={0}>
                                            <Grid item container xs={6}>
                                                <Typography variant="h6" gutterBottom>
                                                    Edit Items
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <form>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={`${classes.menuFormControl} ${classes.filterSet}`}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.branchCode}
                                                    >
                                                        <InputLabel htmlFor="branchCode" className={classes.inputLabel}>Branch</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateData}
                                                                    name="branchCode"
                                                                    id="branchCode"
                                                                />
                                                            )}
                                                            value={formData.branchCode || ''}
                                                        >
                                                            <MenuItem value=""><em className={classes.filterNotSet}>Do not edit</em></MenuItem>
                                                            {branches.map(branch => (
                                                                <MenuItem
                                                                    key={branch.code}
                                                                    value={branch.code}
                                                                >
                                                                    {branch.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.branchCode && (
                                                            <FormHelperText>{errors.branchCode}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                {(branches.find(branch => branch.code === formData.branchCode) || {}).hasStockrooms !== 0
                                                    ? (
                                                        <Grid item xs={12}>
                                                            <FormControl
                                                                className={`${classes.menuFormControl} ${classes.filterSet}`}
                                                                variant="outlined"
                                                                fullWidth
                                                                error={!!errors.stockroomCode}
                                                            >
                                                                <InputLabel htmlFor="stockroomCode" className={classes.inputLabel}>Stockroom</InputLabel>
                                                                <Select
                                                                    keyboard="true"
                                                                    input={(
                                                                        <OutlinedInput
                                                                            onChange={this.updateData}
                                                                            name="stockroomCode"
                                                                            id="stockroomCode"
                                                                        />
                                                                    )}
                                                                    value={formData.stockroomCode || ''}
                                                                >
                                                                    <MenuItem value=""><em className={classes.filterNotSet}>Do not edit</em></MenuItem>
                                                                    {stockrooms.map(stockroom => (
                                                                        <MenuItem
                                                                            key={stockroom.code}
                                                                            value={stockroom.code}
                                                                        >
                                                                            {stockroom.description}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {errors.stockroomCode && (
                                                                    <FormHelperText>{errors.stockroomCode}</FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Grid>
                                                    )
                                                    : null
                                                }
                                                {(stockrooms.find(stockroom => stockroom.code === formData.stockroomCode) || {}).hasBins !== 0
                                                    ? (
                                                        <Grid item xs={12}>
                                                            <FormControl
                                                                className={`${classes.menuFormControl} ${classes.filterSet}`}
                                                                variant="outlined"
                                                                fullWidth
                                                                error={!!errors.binCode}
                                                            >
                                                                <InputLabel htmlFor="binCode" className={classes.inputLabel}>BIN</InputLabel>
                                                                <Select
                                                                    keyboard="true"
                                                                    input={(
                                                                        <OutlinedInput
                                                                            onChange={this.updateData}
                                                                            name="binCode"
                                                                            id="binCode"
                                                                        />
                                                                    )}
                                                                    value={formData.binCode || ''}
                                                                >
                                                                    <MenuItem value=""><em className={classes.filterNotSet}>Do not edit</em></MenuItem>
                                                                    {bins.map(bin => (
                                                                        <MenuItem
                                                                            key={bin.code}
                                                                            value={bin.code}
                                                                        >
                                                                            {bin.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {errors.binCode && (
                                                                    <FormHelperText>{errors.binCode}</FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Grid>
                                                    )
                                                    : null
                                                }
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={`${classes.menuFormControl} ${classes.filterSet}`}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.itemAssignmentCode}
                                                    >
                                                        <InputLabel htmlFor="itemAssignmentCode" className={classes.inputLabel}>Assignment</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateData}
                                                                    name="itemAssignmentCode"
                                                                    id="itemAssignmentCode"
                                                                />
                                                            )}
                                                            value={formData.itemAssignmentCode || ''}
                                                        >
                                                            <MenuItem value=""><em className={classes.filterNotSet}>Do not edit</em></MenuItem>
                                                            {itemsAssignments.map(itemsAssignment => (
                                                                <MenuItem
                                                                    key={itemsAssignment.itemAssignmentCode}
                                                                    value={itemsAssignment.itemAssignmentCode}
                                                                >
                                                                    {itemsAssignment.itemAssignmentDescription}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.itemAssignmentCode && (
                                                            <FormHelperText>{errors.itemAssignmentCode}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={`${classes.menuFormControl} ${classes.filterSet}`}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.itemConditionCode}
                                                    >
                                                        <InputLabel htmlFor="itemConditionCode" className={classes.inputLabel}>Condition</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateData}
                                                                    name="itemConditionCode"
                                                                    id="itemConditionCode"
                                                                />
                                                            )}
                                                            value={formData.itemConditionCode || ''}
                                                        >
                                                            <MenuItem value=""><em className={classes.filterNotSet}>Do not edit</em></MenuItem>
                                                            {itemsConditions.map(itemsCondition => (
                                                                <MenuItem
                                                                    key={itemsCondition.code}
                                                                    value={itemsCondition.code}
                                                                >
                                                                    {itemsCondition.description}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.itemConditionCode && (
                                                            <FormHelperText>{errors.itemConditionCode}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} align="center">
                                                    <Button
                                                        onClick={this.handleSubmitButtonClick}
                                                        className={classes.dialogButton}
                                                        disabled={disableSubmitButton}
                                                    >
                                                        SUBMIT
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </React.Fragment>
                                </div>
                            </DialogContent>
                            <Divider className={classes.dialogActionsDivider} />
                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    onClick={this.handleCloseDialog}
                                    color="primary"
                                    autoFocus
                                >
                            Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                    : null
                }
            </React.Fragment>
        );
    }
}

const branchesShape = {
    code: PropTypes.number,
    name: PropTypes.string,
};

itemsTableEditDialog.propTypes = {
    branches: PropTypes.arrayOf(PropTypes.shape(branchesShape)).isRequired,
    classes: PropTypes.object.isRequired,
    resetShowFiltersDialog: PropTypes.func.isRequired,
    showFiltersDialog: PropTypes.bool.isRequired,
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    itemsAssignments: PropTypes.arrayOf(PropTypes.object).isRequired,
    itemsConditions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withSnackbar(withStyles(styles)(itemsTableEditDialog));
