import React from 'react';

import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment-timezone';
import { sortBy } from 'lodash';
import fetch, { fetchJson2 } from '../../utilities/fetch';
import titalise from '../../utilities/titalise';
import camelCaseToTitleCase from '../../utilities/camelCaseToTitleCase';
import { andrewScottSuppliesLogo, payWeeklyCarpetsLogo } from '../../components/v2/images';
import { paymentSourceLookUp } from '../../utilities/lookUpTables';

const styles = (theme) => ({
    contentContainer: {
        marginLeft: theme.spacing.unit * 6,
        marginRight: theme.spacing.unit * 6,
    },
    logo: {
        width: 128,
        marginBottom: 24,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
        height: 32,
    },
    table: {
        // borderCollapse: 'collapse',
        border: 'thin',
        margin: '25px 0',
        fontSize: '0.9em',
        fontFamily: 'sans-serif',
        // minWidth: '400px',
    },
    tableHead: {
        '&:first-of-type': {
            borderTopLeftRadius: 4,
        },
        '&:last-of-type': {
            borderTopRightRadius: 4,
        },
        fontWeight: 'bold',
        fontSize: 14,
        // whiteSpace: 'nowrap',
        backgroundColor: '#5c6d77',
    },
    tableFooterCell: {
        '&:first-of-type': {
            borderBottomLeftRadius: 4,
        },
        '&:last-of-type': {
            borderBottomRightRadius: 4,
        },
        fontWeight: 'bold',
        fontSize: 14,
        // whiteSpace: 'nowrap',
        backgroundColor: '#839aa8',
        color: '#fff',
        marginTop: 16,
        textAlign: 'right',
    },
    customTableCell: {
        whiteSpace: 'nowrap',
        paddingLeft: 16,
        paddingRight: 16,
    },
    amount: {
        textAlign: 'right',
        width: 80,
    },
    date: {
        textAlign: 'left',
        width: 80,
    },
    paymentStatus: {
        textAlign: 'left',
        width: 150,
    },
    name: {
        fontWeight: 'bold',
        lineHeight: 1.6,
    },
    addressSummary: {
        lineHeight: 1.6,
    },
    addressSummaryContainer: {
        padding: 24,
    },
    summaryRightCol: {
        textAlign: 'right',
    },
    plainTable: {
        fontSize: 14,
        lineHeight: 1.6,
        borderBottom: 'none',
    },
    headFootRow: {
        height: 32,
    },
    declined: {
        color: '#ababab',
    },
    bumpBottom4U: {
        marginBottom: theme.spacing.unit * 4,
    },
    bumpBottom3U: {
        marginBottom: theme.spacing.unit * 3,
    },
    bumpBottom2U: {
        marginBottom: theme.spacing.unit * 2,
    },
    bumpBottom1U: {
        marginBottom: theme.spacing.unit * 1,
    },
    addressContainer: {
        pageBreakInside: 'avoid',
        breakInside: 'avoid',
    },
});

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

class AgreementStatement extends React.Component {
    state = {
        agreementLoading: true,
        contactLoading: true,
    };

    static getDerivedStateFromProps(props, state) {
        const { agreementCode } = props.match.params;
        return {
            agreementCode,
        };
    }

    componentDidMount = async () => {
        // await fetchJson2(`v2/agreement/${this.state.agreementCode}`)
        //     .then((agreement) => {
        //         this.setState({
        //             agreement: agreement.payload,
        //             agreementLoading: false,
        //         });
        //     });
        const now = moment.tz(moment(), 'Europe/London');
        const currentDate = moment(now).format('YYYY-MM-DD');
        this.setState({
            currentDate,
        });
        const agreementData = this.fetchAgreementData();
        agreementData.then(() => {
            this.fetchContactData();
        });
    };

    fetchAgreementData = async () => {
        const { agreementCode } = this.state;
        this.setState({
            agreementLoading: true,
        });

        try {
            const agreement = await fetchJson2(`v2/agreement/${agreementCode}`);
            this.setState({
                agreement: agreement.payload,
            });
            // this.checkAlertsWarnings(agreements);
        } finally {
            this.setState({
                agreementLoading: false,
            });
        }
    };

    fetchContactData = async () => {
        this.setState({
            contactLoading: true,
        });

        try {
            const contact = await fetchJson2(`v2/contact/${this.state.agreement.contactCode}`);

            this.setState({
                contact: contact.payload,
            });
        } finally {
            this.setState({
                contactLoading: false,
            });
        }
    };

    render = () => {
        const { classes } = this.props;
        const {
            agreement,
            agreementLoading,
            contact,
            contactLoading,
            currentDate,
        } = this.state;

        // const imageUrl = window.location.host === 'portal2.payweeklycarpets.co.uk' ? payWeeklyCarpetsLogo : andrewScottSuppliesLogo;
        const imageUrl = payWeeklyCarpetsLogo;

        let agreementBalance = '';
        let frequency = '';
        let runningBalance = [];
        let estimatedCompletionDate = '';
        if (!agreementLoading) {
            agreementBalance = agreement.plan.repaymentAmount - agreement.plan.paidAmount;
            const frequencyInterval = agreement.plan.scheduleInterval > 1 ? `${agreement.plan.scheduleInterval} ` : '';
            frequency = `${frequencyInterval}${agreement.plan.scheduleFrequency.toLowerCase()}`;
            // runningBalance = agreement.payments.reduce((acc, payment) => [...acc, { code: payment.code, balance: acc[acc.length - 1].balance - payment.amount }], [{ balance: agreement.plan.repaymentAmount }]);
            let currentBalance = -Math.abs(agreement.plan.repaymentAmount);
            runningBalance = sortBy(agreement.payments, 'paymentDateTime').reduce((acc, payment) => {
                currentBalance = payment.transactionStatus === 'AUTHORISED' ? currentBalance + payment.amount : currentBalance;
                return [...acc, { code: payment.code, balance: currentBalance }];
            }, []);
            const numberOfOutstandingPayments = (Math.ceil((agreement.plan.repaymentAmount - agreement.plan.paidAmount) / agreement.plan.recurringPaymentAmount)) - 1;
            estimatedCompletionDate = moment(agreement.plan.nextPaymentDate).add(numberOfOutstandingPayments, agreement.plan.scheduleFrequency === 'MONTHLY' ? 'months' : 'weeks');
        }

        return (
            <>
                {!agreementLoading && !contactLoading
                    ? (
                        <div className={classes.contentContainer}>
                            <Grid
                                container
                                spacing={0}
                            >
                                <Grid item xs={4}>
                                    <img
                                        className={classes.logo}
                                        src={imageUrl}
                                        alt="Pay Weekly Logo"
                                    />
                                </Grid>
                                <Grid item xs={3} />

                                <Grid item xs={5}>
                                    <Typography
                                        variant="h4"
                                        align="right"
                                        gutterBottom
                                    >
                                        Statement
                                    </Typography>
                                    <div>
                                        <Table aria-label="simple table">
                                            <TableBody>
                                                <TableRow key="Date">
                                                    <TableCell scope="row" className={classes.plainTable}>
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                        >
                                                            <Grid item xs={6} className={classes.name}>
                                                                Date
                                                            </Grid>
                                                            <Grid item xs={6} className={classes.summaryRightCol}>
                                                                {moment(currentDate).format('DD/MM/YYYY')}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                        >
                                                            <Grid item xs={6} className={classes.name}>
                                                                Customer Ref
                                                            </Grid>
                                                            <Grid item xs={6} className={classes.summaryRightCol}>
                                                                C
                                                                {contact.code}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                        >
                                                            <Grid item xs={6} className={classes.name}>
                                                                Agreement Ref
                                                            </Grid>
                                                            <Grid item xs={6} className={classes.summaryRightCol}>
                                                                RA
                                                                {agreement.code}
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={0}
                            >
                                <Grid item xs={4}>
                                    <Table className={classes.table} size="small" padding="dense">
                                        <TableHead>
                                            <TableRow className={classes.headFootRow}>
                                                <CustomTableCell className={classes.tableHead}>Customer</CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow className={classes.row}>
                                                <CustomTableCell className={[classes.customTableCell, classes.addressSummaryContainer]} component="th" scope="row">
                                                    <Typography className={classes.name}>{`${contact.firstname} ${contact.surname}`}</Typography>
                                                    <Typography className={classes.addressSummary}>{contact.address.address1}</Typography>
                                                    {contact.address.address2
                                                        ? (
                                                            <Typography className={classes.addressSummary}>{contact.address.address2}</Typography>
                                                        )
                                                        : ''}
                                                    <Typography className={classes.addressSummary}>{contact.address.townCity}</Typography>
                                                    <Typography className={`${classes.addressSummary} ${classes.bumpBottom2U}`}>{contact.address.postcode}</Typography>
                                                    <Typography>{contact.mobileTelephoneNumber}</Typography>
                                                    <Typography>{contact.emailAddress}</Typography>
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid item xs={2} />

                                <Grid item xs={6}>
                                    <Table className={classes.table} size="small" padding="dense">
                                        <TableHead>
                                            <TableRow className={classes.headFootRow}>
                                                <CustomTableCell className={classes.tableHead}>Account Summary</CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow className={classes.row}>
                                                <CustomTableCell className={[classes.customTableCell, classes.addressSummaryContainer]} component="th" scope="row">
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={8} className={classes.name}>
                                                            Agreement Status
                                                        </Grid>
                                                        <Grid item xs={1} />
                                                        <Grid item xs={3} className={classes.summaryRightCol}>
                                                            {titalise(agreement.plan.status)}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={8} className={classes.name}>
                                                            Agreement Amount
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            £
                                                        </Grid>
                                                        <Grid item xs={3} className={classes.summaryRightCol}>
                                                            {agreement.plan.repaymentAmount.toFixed(2)}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={8} className={classes.name}>
                                                            Credits
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            £
                                                        </Grid>
                                                        <Grid item xs={3} className={classes.summaryRightCol}>
                                                            {agreement.plan.paidAmount.toFixed(2)}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={8} className={classes.name}>
                                                            Agreement Balance
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            £
                                                        </Grid>
                                                        <Grid item xs={3} className={classes.summaryRightCol}>
                                                            {runningBalance[runningBalance.length - 1].balance.toFixed(2)}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={8} className={classes.name}>
                                                            Recurring Amount
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            £
                                                        </Grid>
                                                        <Grid item xs={3} className={classes.summaryRightCol}>
                                                            {agreement.plan.status !== 'COMPLETE' && agreement.plan.status !== 'CANCELLED'
                                                                ? (
                                                                    agreement.plan.recurringPaymentAmount.toFixed(2)
                                                                )
                                                                : 'n/a'}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={8} className={classes.name}>
                                                            Recurring Period
                                                        </Grid>
                                                        <Grid item xs={1} />
                                                        <Grid item xs={3} className={classes.summaryRightCol}>
                                                            {agreement.plan.status !== 'COMPLETE' && agreement.plan.status !== 'CANCELLED'
                                                                ? (
                                                                    <>
                                                                        { titalise(frequency) }
                                                                    </>
                                                                )
                                                                : 'n/a'}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={8} className={classes.name}>
                                                            Next Payment Due
                                                        </Grid>
                                                        <Grid item xs={1} />
                                                        <Grid item xs={3} className={classes.summaryRightCol}>
                                                            {agreement.plan.nextPaymentDate && agreement.plan.status !== 'COMPLETE' && agreement.plan.status !== 'CANCELLED'
                                                                ? (
                                                                    moment(agreement.plan.nextPaymentDate).format('DD/MM/YYYY')
                                                                )
                                                                : 'n/a'}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={8} className={classes.name}>
                                                            Estimated Completion Date
                                                        </Grid>
                                                        <Grid item xs={1} />
                                                        <Grid item xs={3} className={classes.summaryRightCol}>
                                                            {agreement.plan.nextPaymentDate && agreement.plan.status !== 'COMPLETE' && agreement.plan.status !== 'CANCELLED'
                                                                ? (
                                                                    moment(estimatedCompletionDate).format('DD/MM/YYYY')
                                                                )
                                                                : 'n/a'}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={6} className={classes.name}>
                                                            Payment Card
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.summaryRightCol}>
                                                            {agreement.plan.nextPaymentDate && agreement.plan.status !== 'COMPLETE' && agreement.plan.status !== 'CANCELLED'
                                                                ? (
                                                                    <>
                                                                        {`${agreement.plan.cardNo} exp ${agreement.plan.cardExpiry}`}
                                                                    </>
                                                                )
                                                                : 'n/a'}
                                                        </Grid>
                                                    </Grid>

                                                </CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                            >
                                <Grid item xs={12}>
                                    <Table className={classes.table} size="small" padding="dense">
                                        <TableHead>
                                            <TableRow className={classes.headFootRow}>
                                                <CustomTableCell className={classes.tableHead}>Date</CustomTableCell>
                                                <CustomTableCell className={classes.tableHead}>Type</CustomTableCell>
                                                <CustomTableCell className={classes.tableHead}>Status</CustomTableCell>
                                                <CustomTableCell className={classes.tableHead}>Debit</CustomTableCell>
                                                <CustomTableCell className={classes.tableHead}>Credit</CustomTableCell>
                                                <CustomTableCell className={classes.tableHead}>Balance</CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow className={classes.row}>
                                                <CustomTableCell className={[classes.customTableCell, classes.date]} component="th" scope="row">
                                                    {moment(agreement.createdDateTime).format('DD/MM/YYYY')}
                                                </CustomTableCell>
                                                <CustomTableCell className={[classes.customTableCell, classes.paymentStatus]} component="th" scope="row">
                                                    OPENING BALANCE
                                                </CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} component="th" scope="row" />
                                                <CustomTableCell className={[classes.customTableCell, classes.amount]} component="th" scope="row" />
                                                <CustomTableCell className={[classes.customTableCell, classes.amount]} component="th" scope="row" />
                                                <CustomTableCell className={[classes.customTableCell, classes.amount]} component="th" scope="row">
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={1}>
                                                            £
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            -
                                                            {agreement.plan.repaymentAmount.toFixed(2)}
                                                        </Grid>
                                                    </Grid>
                                                </CustomTableCell>
                                            </TableRow>

                                            {sortBy(agreement.payments, 'paymentDateTime').map((payment) => (
                                                <TableRow className={classes.row}>
                                                    <CustomTableCell className={[classes.customTableCell, classes.date]} component="th" scope="row">
                                                        {moment(payment.paymentDateTime).format('DD/MM/YYYY')}
                                                    </CustomTableCell>
                                                    <CustomTableCell className={[classes.customTableCell, classes.paymentStatus]} component="th" scope="row">
                                                        {paymentSourceLookUp[payment.source]}
                                                    </CustomTableCell>
                                                    <CustomTableCell className={classes.customTableCell} component="th" scope="row">
                                                        {payment.transactionStatus}
                                                    </CustomTableCell>
                                                    <CustomTableCell className={`${classes.customTableCell} ${classes.amount}`} component="th" scope="row">
                                                        {payment.amount < 0 && payment.transactionStatus === 'AUTHORISED'
                                                            ? (
                                                                <Grid
                                                                    container
                                                                    spacing={0}
                                                                    className={payment.transactionStatus !== 'AUTHORISED' ? classes.declined : ''}
                                                                >
                                                                    <Grid item xs={1}>
                                                                        £
                                                                    </Grid>
                                                                    <Grid item xs={11}>
                                                                        {payment.amount.toFixed(2)}
                                                                    </Grid>
                                                                </Grid>
                                                            )
                                                            : ''}
                                                    </CustomTableCell>
                                                    <CustomTableCell className={`${classes.customTableCell} ${classes.amount}`} component="th" scope="row">
                                                        {payment.amount >= 0
                                                            ? (

                                                                <Grid
                                                                    container
                                                                    spacing={0}
                                                                    className={payment.transactionStatus !== 'AUTHORISED' ? classes.declined : ''}
                                                                >
                                                                    <Grid item xs={1}>
                                                                        £
                                                                    </Grid>
                                                                    <Grid item xs={11}>{payment.amount.toFixed(2)}</Grid>
                                                                </Grid>
                                                            )
                                                            : ''}
                                                    </CustomTableCell>
                                                    <CustomTableCell className={`${classes.customTableCell} ${classes.amount}`} component="th" scope="row">
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                        >
                                                            <Grid item xs={1}>
                                                                £
                                                            </Grid>
                                                            <Grid item xs={11}>{runningBalance.find((balance) => balance.code === payment.code).balance.toFixed(2)}</Grid>
                                                        </Grid>
                                                    </CustomTableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow className={classes.headFootRow}>
                                                <CustomTableCell className={classes.tableFooterCell} colSpan={5}>Account Current Balance</CustomTableCell>
                                                <CustomTableCell className={classes.tableFooterCell}>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={1}>
                                                            £
                                                        </Grid>
                                                        <Grid item xs={11}>{runningBalance[runningBalance.length - 1].balance.toFixed(2)}</Grid>
                                                    </Grid>
                                                </CustomTableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                className={classes.addressContainer}
                            >
                                <Grid item xs={12} className={classes.name}>
                                    Pay Weekly Limited
                                </Grid>
                                <Grid item xs={12} className={classes.plainTable}>
                                    4 Mandale, South Street, Keighley, BD21 1DB
                                </Grid>
                                <Grid item xs={12} className={classes.plainTable}>
                                    Tel: 01535 609200
                                </Grid>
                                <Grid item xs={12} className={classes.plainTable}>
                                    FCA Number: FRN730821 | VAT Number: 244 174 124
                                </Grid>
                            </Grid>

                        </div>
                    )
                    : ''}
            </>

        );
    };
}

export default withStyles(styles)(withRouter(AgreementStatement));
