import React from 'react';
import {
    withStyles,
} from '@material-ui/core/styles';
import {
    AppBar,
    Button,
    CircularProgress,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@material-ui/core';
import { DateTimePicker } from 'material-ui-pickers';
import AuthorisedIcon from '@material-ui/icons/CheckCircleOutline';
import DeclinedIcon from '@material-ui/icons/HighlightOff';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import {
    Link,
    DirectLink,
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller,
} from 'react-scroll';
import { withSnackbar } from 'notistack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Error';
import CrossCircleIcon from '@material-ui/icons/Cancel';
import BulletIcon from '@material-ui/icons/FiberManualRecord';
import get from 'lodash/get';
import moment from 'moment-timezone';
import GaugeChart from 'react-gauge-chart';
import HybridTextField from '../hybridTextField';
import CustomerAgreementItems from './customerAgreementItems';
import CustomerAgreementPayments from './customerAgreementPayments';
import titalise from '../../utilities/titalise';
import Tile from '../../containers/tile';
import {
    paymentPlanStatus,
} from '../../utilities/menuOptions';

const styles = (theme) => ({
    label: {
        fontSize: 11,
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: 7,
        width: '100%',
        textAlign: 'left',
    },
    tileBottomDivider: {
        marginTop: theme.spacing.unit * 3,
        width: '100%',
    },
    bottomBarContainer: {
        width: '100%',
        textAlign: 'right',
    },
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    hybridTextIconLeft: {
        transform: 'translate(0%, 13%)',
        marginRight: theme.spacing.unit * 1,
        fontSize: 16,
    },
    hybridTextIconRight: {
        transform: 'translate(0%, 13%)',
        marginLeft: theme.spacing.unit * 1,
        fontSize: 16,
    },
    cancelledIcon: {
        color: '#d7d7d7',
        // color: '#f1180b',
    },
    warmIcon: {
        color: '#E7C251',
    },
    coldIcon: {
        color: '#BBFFFF',
    },
    notSetIcon: {
        color: '#d7d7d7',
    },
    activeIcon: {
        color: '#4caf50',
    },
    warningIcon: {
        color: '#fac518',
    },
    alert: {
        color: '#f1180b',
    },
    alertContainer: {
        /* text-align: left; */
        width: '100%',
        border: '1px solid',
        borderRadius: 4,
        marginBottom: theme.spacing.unit * 4,
        padding: theme.spacing.unit * 2,
        fontWeight: 400,
    },
    deliveriesAlertContainer: {
        /* text-align: left; */
        width: '100%',
        color: '#fff',
        backgroundColor: '#f1180b',
        borderRadius: 4,
        padding: theme.spacing.unit * 2,
        fontWeight: 600,
    },
    deliveriesOKContainer: {
        /* text-align: left; */
        width: '100%',
        color: '#fff',
        backgroundColor: '#4caf50',
        borderRadius: 4,
        padding: theme.spacing.unit * 2,
        fontWeight: 600,
    },
    bumpBottom4U: {
        marginBottom: theme.spacing.unit * 4,
    },
    warning: {
        color: '#ff9800',
    },

    tabsPaper: {
        paddingTop: 32,
        paddingLeft: 32,
        paddingRight: 32,
        paddingBottom: 24,

        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },
    tabsAppBar: {
        backgroundColor: theme.palette.primary.lighter,
        // borderTopLeftRadius: 4,
        // borderTopRightRadius: 4,
    },
    tab: {
        color: 'white',
        fontSize: 15,
        fontWeight: 500,
        height: 38,
        minHeight: 38,
    },
    tabs: {
        padding: 0,
        minHeight: 38,
        height: 38,
        // backgroundColor: 'red',
    },
    tabButton: {
        padding: 0,
        minHeight: 38,
        height: 38,
        backgroundColor: 'red',
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    formInstructions: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    },
    statusSelect: {
        width: 200,
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        width: '100%',
    },
    dialogContentContainer: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
    },
    dialogContent: {
        paddingBottom: 0,
        width: 390,
    },
    dialogActionsDivider: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    radioDescription: {
        marginLeft: 34,
        marginTop: -8,
        marginRight: theme.spacing.unit * 3,
    },
    issuerRadioDescription: {
        margin: 0,
        color: theme.palette.text.secondary,
    },
    disabledText: {
        color: 'rgba(0, 0, 0, 0.38)',
    },
    radioButtonsContainer: {
        marginLeft: theme.spacing.unit * 3,
    },
    menuItemText: {
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeight,
        color: theme.palette.text.primary,
    },
    menuItemSubtext: {
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeight,
        color: theme.palette.text.secondary,
    },
    selectMultiline: {
        height: 75,
    },
    convertDialogContent: {
        paddingBottom: 0,
        width: 540,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
        height: 48,
    },
    tableHeadRow: {
        height: 48,
    },
    tableIndex: {
        verticalAlign: 'top',
        paddingTop: theme.spacing.unit,
    },
    marginTop3: {
        marginTop: theme.spacing.unit * 3,
    },
    inputAdornment: {
        fontSize: 16,
        marginTop: 1,
        color: theme.palette.text.secondary,
    },
    sectionContainer: {
        borderBottom: '1px solid #eaeaea',
        marginBottom: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 1,
    },
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    declinedStatusIcon: {
        fontSize: 100,
        color: red[400],
        margin: theme.spacing.unit * 2,
    },
    authorisedStatusIcon: {
        fontSize: 100,
        color: green[400],
        margin: theme.spacing.unit * 2,
    },
    ErrorStatusIcon: {
        fontSize: 100,
        color: amber[400],
        margin: theme.spacing.unit * 2,
    },
    cardRadio: {
        paddingTop: '0px',
    },
    issuerRadioLabel: {
        marginTop: theme.spacing.unit * 1,
    },
    emptyContent: {
        padding: 32,
    },
    textField: {
        width: 260,
    },
    bumpTop3: {
        marginTop: theme.spacing.unit * 3,
    },
    bumpLeft3U: {
        marginLeft: theme.spacing.unit * 3,
    },
    bulletIcon: {
        fontSize: 11,
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 1.5,
    },
    deliveryAlertList: {
        marginTop: theme.spacing.unit * 1,
        fontWeight: 400,
    },
    subTile: {
        marginBottom: theme.spacing.unit * 4,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    gaugeContainer: {
        width: 100,
        // marginLeft: 'auto',
        // marginRight: 'auto',
    },
    textFieldLabel: {
        fontSize: 11,
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: 4,
    },
    tempButtonBarRight: {
        textAlign: 'right',
        marginBottom: theme.spacing.unit * 2,
    },
});

class CustomerAgreements extends React.Component {
    state = {
        controlDeviceForm: {
            command: 'ON',
        },
        disableControlDeviceSendButton: false,
        value: 0,
        showManualPaymentDialog: false,
        showSendTokenisationLinkDialog: false,
        showControlDeviceDialog: false,
        showRecordDeliveryDialog: false,
        showUpdatePaymentPlanStatusDialog: false,
        disableSubmitManualPaymentButton: false,
        disableRecordDeliverySubmitButton: false,
        disableManualPaymentNextButton: true,
        recordDeliveryData: {
            undeliveredItems: [],
            dateTime: '',
        },
        manualPaymentForm: {
            amount: '',
            paymentCard: '',
        },
        manualPaymentStage: 'amount',
        manualPaymentStages: [
            'amount',
            'cards',
            'payment',
            'result',
        ],
        manualPaymentTransctionStatus: '',
        sendTokenisationLinkForm: {
            messageType: 'sms',
            to: '',
            destination: 'current',
        },
        sendTokenisationLinkStage: 'method',
        sendTokenisationLinkStages: [
            'method',
            'detail',
        ],
        paymentPlanStatusData: {
            Status: '',
        },
        disableUpdatePaymentPlanSubmitButton: false,
    };

    async componentDidMount() {
        await this.changeAgreementTab();
    }

    componentDidUpdate(prevProps) {
        if (this.props.requestedAgreementTab !== prevProps.requestedAgreementTab) {
            this.changeAgreementTab();
        }
    }

    changeAgreementTab = async () => {
        if (this.props.requestedAgreementTab >= 0) {
            await this.scrollToAgreementsTop();
            this.setState({
                value: this.props.requestedAgreementTab,
            });
        }
    };

    scrollToAgreementsTop = () => {
        scroller.scrollTo('agreementsTop', {
            duration: 800,
            delay: 100,
            smooth: 'easeInOutQuart',
            offset: -90,
        });
    };

    capitaliseFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

    agreementStatusIcon = (alertStatus, warningStatus, cancelledStatus) => {
        switch (true) {
        case (alertStatus): {
            return (<WarningIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.alert}`} />);
        }
        case (warningStatus): {
            return (<WarningIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.warningIcon}`} />);
        }
        case (cancelledStatus): {
            return (<CrossCircleIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.cancelledIcon}`} />);
        }
        default: {
            return (<CheckCircleIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.activeIcon}`} />);
        }
        // case 'Warm': {
        //     return (this.props.classes.warmIcon);
        // }
        // case 'Cold': {
        //     return (this.props.classes.coldIcon);
        // }
        // case 'Finance': {
        //     return (this.props.classes.financeIcon);
        // }
        // default: {
        //     return (this.props.classes.notSetIcon);
        // }
        }
    };

    handleManualPaymentClick = () => {
        this.setState((state) => ({
            showManualPaymentDialog: true,
        }));
    };

    handleSendTokenisationLinkClick = () => {
        this.setState((state) => ({
            showSendTokenisationLinkDialog: true,
        }));
    };

    handleControlDeviceClick = () => {
        const currentControlDeviceState = (((this.props.customer.agreements[this.state.value] || {}).items || []).find((item) => item.controlDeviceCode !== null) || {}).controlDeviceState;
        this.setState({
            controlDeviceForm: {
                command: currentControlDeviceState === 'OFF' ? 'ON' : 'OFF',
            },
            showControlDeviceDialog: true,
        });
    };

    handleRecordDeliveryClick = () => {
        const currentAgreement = this.props.customer.agreements[this.state.value];
        const undeliveredItems = currentAgreement.items.filter((item) => item.deliveryStatus === 0).reduce((acc, item) => ([...acc, { code: item.code, deliveryStatus: 1, modelNo: item.modelNo }]), []);
        const now = moment.tz(moment(), 'Europe/London');
        const dateTime = moment(now).format('YYYY-MM-DD HH:mm:ss');
        this.setState({
            showRecordDeliveryDialog: true,
            recordDeliveryData: {
                undeliveredItems,
                dateTime,
            },
        });
    };

    handleUpdatePaymentPlanStatusClick = () => {
        this.setState({
            paymentPlanStatusData: {
                Status: this.props.customer.agreements[this.state.value].Status,
            },
            showUpdatePaymentPlanStatusDialog: true,
        });
    };

    handleCloseUpdatePaymentPlanStatusDialogClick = () => {
        this.setState({
            showUpdatePaymentPlanStatusDialog: false,
        });
    };

    handleCloseManualPaymentDialogClick = () => {
        this.setState((state) => ({
            showManualPaymentDialog: false,
            manualPaymentStage: 'amount',
        }));
    };

    handleCloseSendTokenisationLinkDialogClick = () => {
        this.setState((state) => ({
            showSendTokenisationLinkDialog: false,
            sendTokenisationLinkStage: 'method',
            sendTokenisationLinkForm: {
                messageType: 'sms',
                destination: 'current',
            },
        }));
    };

    handleCloseControlDeviceDialogClick = () => {
        this.setState((state) => ({
            showControlDeviceDialog: false,
        }));
    };

    handleCloseRecordDeliveryDialogClick = () => {
        this.setState((state) => ({
            showRecordDeliveryDialog: false,
            disableRecordDeliverySubmitButton: false,
        }));
    };

    handleManualPaymentNextClick = async () => {
        let manualPaymentStageEnum;
        manualPaymentStageEnum = this.state.manualPaymentStages.indexOf(this.state.manualPaymentStage);
        manualPaymentStageEnum += 1;
        switch (this.state.manualPaymentStages[manualPaymentStageEnum]) {
        case 'cards':
            this.setState({ disableManualPaymentNextButton: true });
            this.setState({
                disableManualPaymentNextButton: false,
            });
            break;
        case 'payment':
            this.setState({
                transactionStatusWaiting: true,
                disableManualPaymentNextButton: true,
            });
            const { amount } = this.state.manualPaymentForm;
            const agreementCode = this.props.customer.agreements[this.state.value].code;
            const { paymentCard } = this.state.manualPaymentForm;
            if (paymentCard === 'new') {
                manualPaymentStageEnum += 1; // Change manualPaymentStage to 'result'
                this.newCardManualPayment(agreementCode, amount);
            } else {
                const { cardNo } = this.props.paymentCards.find((card) => card.token === paymentCard);
                manualPaymentStageEnum += 1; // Change manualPaymentStage to 'result'
                await this.tokenManualPayment(agreementCode, amount, paymentCard, cardNo).then((paymentTransactionStatus) => {
                    this.setState({
                        transactionStatusWaiting: false,
                        manualPaymentTransctionStatus: paymentTransactionStatus,
                    });
                });
            }
            break;
        }
        this.setState({
            disableManualPaymentNextButton: false,
            manualPaymentStage: this.state.manualPaymentStages[manualPaymentStageEnum],
        });
    };

    handleSendTokenisationLinkNextClick = async () => {
        let sendTokenisationLinkStageEnum;
        sendTokenisationLinkStageEnum = this.state.sendTokenisationLinkStages.indexOf(this.state.sendTokenisationLinkStage);
        sendTokenisationLinkStageEnum += 1;
        this.setState((state) => ({
            sendTokenisationLinkStage: state.sendTokenisationLinkStages[sendTokenisationLinkStageEnum],
        }));
    };

    updateManualPaymentData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'amount':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            this.setState({
                disableManualPaymentNextButton: !parseInt(formattedValue) > 0,
            });
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState({
            manualPaymentForm: {
                ...this.state.manualPaymentForm,
                [name]: formattedValue,
            },
        });
    };

    updatePaymentPlanStatusData = (event) => {
        const { name, value } = event.target;
        event.target.value = value;

        this.setState({
            paymentPlanStatusData: {
                [name]: value,
            },
        });
    };

    updateControlDeviceData = (event) => {
        // debugger;
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            controlDeviceForm: {
                ...state.controlDeviceForm,
                [name]: formattedValue,
            },
        }));
    };

    updateRecordDeliveryData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            recordDeliveryData: {
                ...state.recordDeliveryData,
                [name]: formattedValue,
            },
        }));
    };

    updateSendTokenisationLinkData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (true) {
        case (this.state.sendTokenisationLinkForm.messageType === 'sms' && name === 'to'):
            console.log('sms & to');
            formattedValue = value.replace(/[^0-9$.]/g, '');
            this.setState({
                disableSendTokenisationLinkSendButton: formattedValue.length === 0,
            });
            break;
        case (this.state.sendTokenisationLinkForm.messageType === 'email' && name === 'to'):
            console.log('email & to');
            formattedValue = value;
            this.setState({
                disableSendTokenisationLinkSendButton: formattedValue.length === 0,
            });
            break;
        case (value === 'current'):
            formattedValue = value;
            this.setState({
                disableSendTokenisationLinkSendButton: false,
            });
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState({
            sendTokenisationLinkForm: {
                ...this.state.sendTokenisationLinkForm,
                [name]: formattedValue,
            },
        });
    };

    handleSendTokenisationLink = (event) => {
        event.preventDefault();
        this.setState({
            disableSendTokenisationLinkSendButton: true,
        });
        const agreementCode = this.props.customer.agreements[this.state.value].code;
        this.props.sendTokenisationLink(agreementCode, this.state.sendTokenisationLinkForm).then(() => {
            this.setState((state) => ({
                showSendTokenisationLinkDialog: false,
                disableSendTokenisationLinkSendButton: false,
                sendTokenisationLinkStage: 'method',
                sendTokenisationLinkForm: {
                    messageType: 'sms',
                    destination: 'current',
                },
            }));
            this.props.enqueueSnackbar('Tokenisation Link Sent', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Send Tokenisation Link failed', { variant: 'error' });
        });
    };

    handleSendControlDeviceCommand = (event) => {
        event.preventDefault();
        this.setState({
            disableControlDeviceSendButton: true,
        });
        const { controlDeviceCode } = this.props.customer.agreements[this.state.value].items.find((item) => item.controlDeviceCode != null);
        const contactCode = this.props.customer.code;
        const { command } = this.state.controlDeviceForm;

        this.props.sendControlDeviceCommand(controlDeviceCode, contactCode, command).then(() => {
            this.setState((state) => ({
                showControlDeviceDialog: false,
                disableControlDeviceSendButton: false,
            }));
            this.props.enqueueSnackbar('Conrol device command sent', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Control device command send failed', { variant: 'error' });
        });
    };

    handlePrintDeliveryLabelsClick = () => {
        const agreementCode = this.props.customer.agreements[this.state.value].code;
        this.props.printDeliveryLabels(agreementCode).then(() => {
            this.props.enqueueSnackbar('Delivery Labels Printed', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Delivery Labels Print failed', { variant: 'error' });
        });
    };

    handleCancelAgreementClick = () => {
        const agreementCode = this.props.customer.agreements[this.state.value].code;
        const params = {
            status: 'CANCELLED',
        };
        this.props.cancelAgreement(agreementCode, params).then(() => {
            this.props.enqueueSnackbar('Agreement Cancelled', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Agreement Cancel failed', { variant: 'error' });
        });
    };

    handleSubmitUpdatePaymentPlanStatusClick = () => {
        this.setState({
            disableUpdatePaymentPlanSubmitButton: true,
            showUpdatePaymentPlanStatusDialog: false,
        });
        const agreementCode = this.props.customer.agreements[this.state.value].code;
        const params = {
            userReference: this.props.customer.code,
            planReference: agreementCode,
            status: this.state.paymentPlanStatusData.Status,
        };
        this.props.updatePaymentPlanStatus(agreementCode, params).then(() => {
            this.setState({
                disableUpdatePaymentPlanSubmitButton: false,
            });
            this.props.enqueueSnackbar('Payment Plan Updated', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Payment Plan Update failed', { variant: 'error' });
        });
    };

    handleRecordDeliverySubmitClick = () => {
        this.setState({
            disableRecordDeliverySubmitButton: true,
        });
        const agreementCode = this.props.customer.agreements[this.state.value].code;
        const params = {
            ...this.state.recordDeliveryData,
        };
        this.props.recordDelivery(agreementCode, params).then(() => {
            this.setState({
                disableRecordDeliverySubmitButton: false,
                showRecordDeliveryDialog: false,
            });
            this.props.enqueueSnackbar('Agreement Delivery Updated', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableRecordDeliverySubmitButton: false,
            });
            this.props.enqueueSnackbar('Agreement Delivery Update failed', { variant: 'error' });
        });
    };

    newCardManualPayment = (agreementCode, amount) => {
        this.props.manualPayment({
            agreementCode,
            amount,
            paymentExpiry: 90,
            paymentReturnURL: `https://${window.location.hostname}/customer/${this.props.customer.code}?paymentType=manual&agreementCode=${agreementCode}`,
        });
    };

    tokenManualPayment = (agreementCode, amount, token, cardNo) => this.props.tokenManualPayment({
        agreementCode,
        paymentAmount: amount,
        paymentCardToken: token,
        paymentCardNo: cardNo,
    });

    handleTryAgainClick = () => {
        this.setState({
            manualPaymentStage: 'amount',
        });
    };

    handleAgreementChange = (event, newValue) => {
        this.setState({ value: newValue });
    };

    handleDebuggerClick = () => {
        debugger;
    };

    updateRecordDeliveryDataCheckbox = (name) => (event) => {
        const newState = event.target.checked ? 1 : 0;
        // const currentAgreement = this.props.customer.agreements[this.state.value];
        const newUndeliveredItems = this.state.recordDeliveryData.undeliveredItems.reduce((acc, item) => {
            if (item.code === name) return [...acc, { code: item.code, deliveryStatus: newState, modelNo: item.modelNo }];
            return [...acc, { code: item.code, deliveryStatus: item.deliveryStatus, modelNo: item.modelNo }];
        }, []);

        this.setState((state) => ({
            recordDeliveryData: {
                ...state.recordDeliveryData,
                undeliveredItems: newUndeliveredItems,
            },
        }));
    };

    handleRecordDeliveryDateTimeChange = (chosenDate) => {
        this.setState((state) => ({
            recordDeliveryData: {
                ...state.recordDeliveryData,
                dateTime: moment(chosenDate).format('YYYY-MM-DD HH:mm'),
            },
        }));
    };

    handleNewCustomerPageClick = () => {
        this.props.history.push(`/contact/${this.props.customer.code}`);
    };

    render() {
        const {
            agreementsAlerts,
            classes,
            customer,
            engineers,
            errors,
            paymentCards,
            requestedAgreementTab,
            transactionStatusWaiting,
            user,
        } = this.props;
        const {
            // disableSubmitManualPaymentButton,
            controlDeviceForm,
            disableControlDeviceSendButton,
            disableManualPaymentNextButton,
            disableRecordDeliverySubmitButton,
            disableSendTokenisationLinkSendButton,
            disableUpdatePaymentPlanSubmitButton,
            manualPaymentForm,
            manualPaymentStage,
            manualPaymentTransctionStatus,
            paymentPlanStatusData,
            recordDeliveryData,
            sendTokenisationLinkForm,
            sendTokenisationLinkStage,
            showControlDeviceDialog,
            showManualPaymentDialog,
            showRecordDeliveryDialog,
            showSendTokenisationLinkDialog,
            showUpdatePaymentPlanStatusDialog,
            value,
        } = this.state;

        let currentAgreement = null;
        let currentAgreementAdvancePaymentPaid = null;
        let currentAgreementAgentFeePaid = null;
        let currentAgreementCancelled = null;
        let currentAgreementCollectableAgentFee = null;
        let currentAgreementDeliveryChargePaid = null;
        let currentAgreementFullyDelivered = null;
        let currentPaymentPlanAuthorisedRecurringPayments = null;
        let currentPaymentPlanCancelled = null;

        if (get(customer, `agreements[${value}]`)) {
            currentAgreement = customer.agreements[value];
            currentAgreementFullyDelivered = !!currentAgreement.items.find((item) => item.deliveryStatus === 1);
            currentAgreementCollectableAgentFee = (currentAgreement.agentCollectedFee === 1 ? 0 : currentAgreement.agentFee);
            currentAgreementAgentFeePaid = (currentAgreement.portalPaidAmount >= currentAgreementCollectableAgentFee);
            currentAgreementDeliveryChargePaid = ((currentAgreement.deliveryAmount === 0) || (currentAgreement.portalPaidAmount >= (currentAgreementCollectableAgentFee + currentAgreement.deliveryAmount)));
            currentAgreementAdvancePaymentPaid = ((currentAgreement.depositAmount === 0) || (currentAgreement.portalPaidAmount >= (currentAgreementCollectableAgentFee + currentAgreement.deliveryAmount + currentAgreement.depositAmount)));
            currentAgreementCancelled = (currentAgreement.agreementStatus === 'CANCELLED');
            currentPaymentPlanCancelled = (currentAgreement.Status === 'CANCELLED');
            currentPaymentPlanAuthorisedRecurringPayments = currentAgreement.payments.filter((payment) => (payment.source === 'RecurringPayment' || payment.source === 'RetryPayment') && payment.transactionStatus === 'Authorised').length;

            // console.log('currentAgreementFullyDelivered:', currentAgreementFullyDelivered);
            // console.log('currentAgreementCollectableAgentFee:', currentAgreementCollectableAgentFee);
            // console.log('currentAgreementAgentFeePaid:', currentAgreementAgentFeePaid);
            // console.log('currentAgreementDeliveryChargePaid:', currentAgreementDeliveryChargePaid);
            // console.log('currentAgreementAdvancePaymentPaid:', currentAgreementAdvancePaymentPaid);
            // console.log('currentAgreementCancelled:', currentAgreementCancelled);
            // console.log('currentPaymentPlanCancelled:', currentPaymentPlanCancelled);
            // console.log('currentPaymentPlanAuthorisedRecurringPayments:', currentPaymentPlanAuthorisedRecurringPayments);
            // console.log('currentAgreement.items.length:', currentAgreement.items.length);
        }

        if (agreementsAlerts) {
            console.log('>>> THIS:', agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).badges.find((badge) => (badge.label === 'Advance Payment')));
        }

        return (
            <Tile
                allowCollapse
                initialCollapsedTile={requestedAgreementTab < 0}
                tileTitle="Agreements"
                tileMenuItems={[
                    ...(customer != null
                        && customer.agreements != null
                        && currentAgreement != null
                        && ((currentAgreement || {}).agreementStatus !== 'CANCELLED')
                        && (((currentAgreement || {}).Status === 'CANCELLED') || ((currentAgreement || {}).Status === 'None'))
                        ? [
                            {
                                label: 'Cancel Agreement',
                                action: this.handleCancelAgreementClick,
                                section: 1,
                            },
                        ]
                        : []
                    ),
                    ...(user != null
                        && customer != null
                        && user.accessLevel >= 3
                        && customer.agreements != null
                        && currentAgreement != null
                        ? [
                            {
                                label: 'Update Payment Plan Status',
                                action: this.handleUpdatePaymentPlanStatusClick,
                                section: 2,
                                disabled: true,
                            },
                        ]
                        : []
                    ),
                    ...(customer != null
                        && customer.agreements != null
                        && currentAgreement != null
                        && ((currentAgreement || {}).Status !== 'CANCELLED')
                        ? [
                            {
                                label: 'Manual Payment',
                                action: this.handleManualPaymentClick,
                                section: 2,
                                disabled: true,
                            },
                        ]
                        : []
                    ),
                    ...(customer != null
                        && customer.agreements != null
                        && currentAgreement != null
                        && ((currentAgreement || {}).Status !== 'CANCELLED')
                        ? [{
                            label: 'Request New Tokenisation',
                            action: this.handleSendTokenisationLinkClick,
                            section: 2,
                            disabled: true,
                        }]
                        : []
                    ),
                    ...(customer != null
                        && customer.agreements != null
                        && currentAgreement != null
                        && ((currentAgreement || {}).items != null)
                        && ((currentAgreement || {}).agreementStatus !== 'CANCELLED')
                        && ((currentAgreement || {}).items || []).filter((item) => item.deliveryStatus === 0).length > 0
                        && user.accessLevel >= 4
                        ? [
                            {
                                label: 'Record Delivery',
                                action: this.handleRecordDeliveryClick,
                                section: 3,
                            },
                        ]
                        : []
                    ),
                    ...(customer != null
                        && customer.agreements != null
                        && ((currentAgreement || {}).items != null)
                        // && ((currentAgreement || {}).agreementStatus !== 'CANCELLED')
                        && ((currentAgreement || {}).items || []).filter((item) => item.controlDeviceCode !== null).length > 0
                        ? [
                            {
                                label: 'Control Device',
                                action: this.handleControlDeviceClick,
                                section: 3,
                                // disabled: true,
                            },
                        ]
                        : []
                    ),
                    ...(customer != null
                        && customer.agreements != null
                        && currentAgreement != null
                        && ((currentAgreement || {}).agreementStatus !== 'CANCELLED')
                        && ((currentAgreement || {}).items || []).filter((item) => item.deliveryStatus === 0).length > 0
                        ? [
                            {
                                label: 'Print delivery labels',
                                action: this.handlePrintDeliveryLabelsClick,
                                section: 3,
                            },
                        ]
                        : []
                    ),
                ]}
                scrollToMarker="agreementsTop"
            >
                { customer !== null
                    && requestedAgreementTab != null
                    && paymentCards !== null
                    && agreementsAlerts !== null
                    && (currentAgreement || {}).items !== null
                    ? (
                        <>
                            {customer.agreements.length > 0
                                ? (
                                    <>
                                        <AppBar
                                            position="static"
                                            className={classes.tabsAppBar}
                                        >
                                            <Tabs
                                                className={classes.tabs}
                                                value={value}
                                                onChange={this.handleAgreementChange}
                                            >
                                                {customer.agreements.map((agreement) => (
                                                    <Tab
                                                        className={classes.tab}
                                                        key={agreement.code}
                                                        label={
                                                            (
                                                                <>
                                                                    {`RA${agreement.code}`}
                                                                    {this.agreementStatusIcon(
                                                                        get(agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === agreement.code), 'alerts', []).length > 0,
                                                                        get(agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === agreement.code), 'warnings', []).length > 0,
                                                                        agreement.agreementStatus === 'CANCELLED',
                                                                    )}

                                                                </>
                                                            )
                                                        }
                                                    />
                                                ))}
                                            </Tabs>
                                        </AppBar>
                                        <Paper className={classes.tabsPaper}>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12} className={classes.tempButtonBarRight}>
                                                    <Button
                                                        onClick={this.handleNewCustomerPageClick}
                                                    >
                                                        CLICK FOR NEW CUSTOMER PAGE
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            {
                                                !currentAgreementCancelled
                                                && currentAgreement.items.length > 0
                                                && !currentAgreementFullyDelivered
                                                && (
                                                    (currentAgreement.Type !== 'Continuous' && currentPaymentPlanAuthorisedRecurringPayments < (currentAgreement.ScheduleFrequency === 'Weekly' ? 3 : 1))
                                                    || !currentAgreementAgentFeePaid
                                                    || !currentAgreementDeliveryChargePaid
                                                    || !currentAgreementAdvancePaymentPaid
                                                )
                                                    ? (
                                                        <div className={`${classes.deliveriesAlertContainer} ${classes.bumpBottom4U}`}>
                                                            <div>
                                                                DO NOT DELIVER ITEMS ON THIS AGREEMENT
                                                            </div>
                                                            {!currentAgreementAgentFeePaid
                                                                && (
                                                                    <div className={classes.deliveryAlertList}>
                                                                        <BulletIcon className={classes.bulletIcon} />
                                                                        Agent fee not fully paid
                                                                        Advance payment not fully paid
                                                                        {' '}
                                                                        (balance due: £
                                                                        {(currentAgreementCollectableAgentFee - currentAgreement.portalPaidAmount).toFixed(2)}
                                                                        )
                                                                    </div>
                                                                )}
                                                            {!currentAgreementDeliveryChargePaid
                                                                && (
                                                                    <div className={classes.deliveryAlertList}>
                                                                        <BulletIcon className={classes.bulletIcon} />
                                                                        Delivery charge not fully paid
                                                                        {' '}
                                                                        (balance due: £
                                                                        {(currentAgreement.deliveryAmount - Math.max(0, (currentAgreement.portalPaidAmount - currentAgreementCollectableAgentFee))).toFixed(2)}
                                                                        )
                                                                    </div>
                                                                )}
                                                            {!currentAgreementAdvancePaymentPaid
                                                                && (
                                                                    <div className={classes.deliveryAlertList}>
                                                                        <BulletIcon className={classes.bulletIcon} />
                                                                        Advance payment not fully paid
                                                                        {' '}
                                                                        (balance due: £
                                                                        {(currentAgreement.depositAmount - Math.max(0, (currentAgreement.portalPaidAmount - (currentAgreementCollectableAgentFee + currentAgreement.deliveryAmount)))).toFixed(2)}
                                                                        )
                                                                    </div>
                                                                )}
                                                            {(currentAgreement.Type !== 'Continuous' && currentPaymentPlanAuthorisedRecurringPayments < (currentAgreement.ScheduleFrequency === 'Weekly' ? 3 : 1))
                                                                && (
                                                                    <div className={classes.deliveryAlertList}>
                                                                        <BulletIcon className={classes.bulletIcon} />
                                                                        {(currentAgreement.ScheduleFrequency === 'Weekly' ? 3 : 1)}
                                                                        {' '}
                                                                        Recurring payments have not been authorised
                                                                        {' '}
                                                                        (
                                                                        {(currentAgreement.ScheduleFrequency === 'Weekly' ? 3 : 1) - currentPaymentPlanAuthorisedRecurringPayments}
                                                                        {' '}
                                                                        additional payments required)
                                                                    </div>
                                                                )}
                                                        </div>
                                                    )
                                                    : null
                                            }

                                            {!currentAgreementCancelled
                                                && currentAgreement.items.length > 0
                                                && !currentAgreementFullyDelivered
                                                && (currentAgreement.Type === 'Continuous' || currentPaymentPlanAuthorisedRecurringPayments >= (currentAgreement.ScheduleFrequency === 'Weekly' ? 3 : 1))
                                                && currentAgreementAgentFeePaid
                                                && currentAgreementDeliveryChargePaid
                                                && currentAgreementAdvancePaymentPaid
                                                ? (
                                                    <div className={`${classes.deliveriesOKContainer} ${classes.bumpBottom4U}`}>
                                                        IT IS OK TO DELIVER ITEMS ON THIS AGREEMENT
                                                    </div>
                                                )
                                                : null}

                                            {agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).alerts.length > 0
                                                ? (
                                                    <>
                                                        <div
                                                            className={`${classes.label} ${classes.alert}`}
                                                        >
                                                            Alerts
                                                        </div>
                                                        <div className={`${classes.alertContainer} ${classes.alert}`}>
                                                            {agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).alerts.map((alert) => (
                                                                <div key={alert}>
                                                                    {alert}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                )
                                                : null}
                                            {agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).warnings.length > 0
                                                ? (
                                                    <>
                                                        <div
                                                            className={`${classes.label} ${classes.warning}`}
                                                        >
                                                            Warnings
                                                        </div>
                                                        <div className={`${classes.alertContainer} ${classes.warning}`}>
                                                            {agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).warnings.map((warning) => (
                                                                <div
                                                                    key={warning}
                                                                >
                                                                    {warning}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                )
                                                : null}

                                            <Grid
                                                container
                                                spacing={0}
                                                className={classes.sectionContainer}
                                            >
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        label="Scheduled Payments Summary"
                                                        text={`${customer.agreements[value].authorisedPayments}/${customer.agreements[value].paymentAttempts}
                                                        ${((customer.agreements[value].authorisedPayments / customer.agreements[value].paymentAttempts) * 100).toFixed(0)}%`}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <div className={classes.textFieldLabel}>
                                                        Payment Risk
                                                    </div>
                                                    <div className={classes.gaugeContainer}>
                                                        <GaugeChart
                                                            id={`gauge-chart-${customer.agreements[value].agreementCode}`}
                                                            cornerRadius={1}
                                                            hideText
                                                            nrOfLevels={5}
                                                            percent={1 - (customer.agreements[value].authorisedPayments / customer.agreements[value].paymentAttempts)}
                                                        />
                                                    </div>
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                spacing={0}
                                                className={classes.sectionContainer}
                                            >
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        alert={customer.agreements[value].agreementStatus === 'CANCELLED'}
                                                        green={customer.agreements[value].agreementStatus === 'ACTIVE'}
                                                        label="Agreement Status"
                                                        text={customer.agreements[value].agreementStatus || ' '}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        cancelled={customer.agreements[value].agreementStatus === 'CANCELLED'}
                                                        label="Agreement Created Time/Date"
                                                        text={customer.agreements[value].agreementStartDateTime || ' '}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        cancelled={customer.agreements[value].agreementStatus === 'CANCELLED'}
                                                        label="Agreement Created By"
                                                        text={customer.agreements[value].agreementCreatedByFullname}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        cancelled={customer.agreements[value].agreementStatus === 'CANCELLED'}
                                                        label="Paid Amount"
                                                        text={'£'.concat((customer.agreements[value].portalPaidAmount || 0).toFixed(2))}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {customer.agreements[value].Type === 'Repayment'
                                                        ? (
                                                            <HybridTextField
                                                                cancelled={customer.agreements[value].agreementStatus === 'CANCELLED'}
                                                                label="Repayment Amount"
                                                                text={'£'.concat((customer.agreements[value].portalPlanAmount || 0).toFixed(2))}
                                                            />
                                                        )
                                                        : (
                                                            <HybridTextField
                                                                cancelled={customer.agreements[value].agreementStatus === 'CANCELLED'}
                                                                green={customer.agreements[value].payments.reduce((n, payment) => (n + (payment.source === 'RecurringPayment' && payment.transactionStatus === 'Authorised' && payment.amount === customer.agreements[value].portalRecurringAmount)), 0) > 2}
                                                                label="Authorised Recurring Payments"
                                                                text={customer.agreements[value].payments.reduce((n, payment) => (n + (payment.source === 'RecurringPayment' && payment.transactionStatus === 'Authorised' && payment.amount === customer.agreements[value].portalRecurringAmount)), 0)}
                                                            />
                                                        )}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {customer.agreements[value].Type === 'Repayment'
                                                        ? (
                                                            <HybridTextField
                                                                cancelled={customer.agreements[value].agreementStatus === 'CANCELLED'}
                                                                label="Outstanding Amount"
                                                                text={'£'.concat((customer.agreements[value].portalPlanAmount - customer.agreements[value].portalPaidAmount).toFixed(2))}
                                                            />
                                                        )
                                                        : (<></>)}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        alert={agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).badges.find((badge) => badge.label === 'Advance Payment' && badge.style === 'alert')}
                                                        green={!(agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).badges.find((badge) => badge.label === 'Advance Payment' && badge.style === 'alert'))}
                                                        cancelled={customer.agreements[value].agreementStatus === 'CANCELLED'}
                                                        label="Advance Payment"
                                                        text={'£'.concat(customer.agreements[value].depositAmount.toFixed(2))}
                                                        fieldStyle={agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).badges.find((badge) => (badge.label === 'Advance Payment'))}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        alert={agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).badges.find((badge) => badge.label === 'Delivery Charge' && badge.style === 'alert')}
                                                        green={!(agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).badges.find((badge) => badge.label === 'Delivery Charge' && badge.style === 'alert'))}
                                                        cancelled={customer.agreements[value].agreementStatus === 'CANCELLED'}
                                                        label="Delivery Charge"
                                                        text={'£'.concat(customer.agreements[value].deliveryAmount.toFixed(2))}
                                                        fieldStyle={agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).badges.find((badge) => (badge.label === 'Delivery Charge'))}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <HybridTextField
                                                        alert={agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).badges.find((badge) => badge.label === 'Agent Fee' && badge.style === 'alert')}
                                                        green={!(agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).badges.find((badge) => badge.label === 'Agent Fee' && badge.style === 'alert'))}
                                                        cancelled={customer.agreements[value].agreementStatus === 'CANCELLED'}
                                                        label={`Agent Fee${currentAgreement.agentCollectedFee ? ' (agent collected fee)' : ''}`}
                                                        text={'£'.concat(customer.agreements[value].agentFee.toFixed(2))}
                                                        fieldStyle={agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).badges.find((badge) => (badge.label === 'Agent Fee'))}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                spacing={0}
                                                className={classes.sectionContainer}
                                            >
                                                <Grid item xs={4}>
                                                    <Grid item xs={12}>
                                                        <HybridTextField
                                                            alert={
                                                                customer.agreements[value].Status === 'CANCELLED'
                                                                || customer.agreements[value].Status === 'None'
                                                                || agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).badges.find((badge) => badge.label === 'Payment Plan Status' && badge.style === 'alert')
                                                            }
                                                            green={customer.agreements[value].Status === 'ACTIVE'}
                                                            label="Payment Plan Status"
                                                            text={customer.agreements[value].Status || ' '}
                                                            fieldStyle={agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).badges.find((badge) => (badge.label === 'Payment Plan Status'))}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid item xs={12}>
                                                        <HybridTextField
                                                            cancelled={customer.agreements[value].Status === 'CANCELLED' || customer.agreements[value].Status === 'None'}
                                                            label="Payment Plan Type"
                                                            text={customer.agreements[value].Status !== 'None' ? customer.agreements[value].Type : ''}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid item xs={12}>
                                                        <HybridTextField
                                                            cancelled={customer.agreements[value].Status === 'CANCELLED' || customer.agreements[value].Status === 'None'}
                                                            label="Payment Plan Start Date"
                                                            text={customer.agreements[value].Status !== 'None' ? customer.agreements[value].portalStartDate : ''}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid item xs={12}>
                                                        <HybridTextField
                                                            cancelled={customer.agreements[value].Status === 'CANCELLED' || customer.agreements[value].Status === 'None'}
                                                            label="Recurring Amount"
                                                            text={customer.agreements[value].Status !== 'None' ? '£'.concat(customer.agreements[value].portalRecurringAmount.toFixed(2)) : ''}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <HybridTextField
                                                            cancelled={customer.agreements[value].Status === 'CANCELLED' || customer.agreements[value].Status === 'None'}
                                                            label="Schedule Frequency"
                                                            text={customer.agreements[value].Status !== 'None' ? customer.agreements[value].ScheduleFrequency : ''}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <HybridTextField
                                                            cancelled={customer.agreements[value].Status === 'CANCELLED' || customer.agreements[value].Status === 'None'}
                                                            warning={agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).badges.find((badge) => badge.label === 'Tokenised Card' && badge.style === 'warning')}
                                                            alert={agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).badges.find((badge) => badge.label === 'Tokenised Card' && badge.style === 'alert')}
                                                            fieldStyle={agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).badges.find((badge) => (badge.label === 'Tokenised Card'))}
                                                            label="Tokenised Card"
                                                            text={customer.agreements[value].Status !== 'None' ? titalise(customer.agreements[value].Card) : ''}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid item xs={12}>
                                                        <HybridTextField
                                                            cancelled={customer.agreements[value].Status === 'CANCELLED' || customer.agreements[value].Status === 'None'}
                                                            label="Next payment Date"
                                                            text={customer.agreements[value].Status !== 'None' ? moment(customer.agreements[value].portalNextPaymentDate).format('DD-MM-YYYY') : ''}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <HybridTextField
                                                            cancelled={customer.agreements[value].Status === 'CANCELLED' || customer.agreements[value].Status === 'None'}
                                                            label="Schedule Interval"
                                                            text={customer.agreements[value].Status !== 'None' ? customer.agreements[value].ScheduleInterval : ''}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {customer.agreements[value].Card === 'YES'
                                                            ? (

                                                                <HybridTextField
                                                                    cancelled={customer.agreements[value].Status === 'CANCELLED' || customer.agreements[value].Status === 'None'}
                                                                    label="Card issuer"
                                                                    text={customer.agreements[value].Status !== 'None' && paymentCards.find !== undefined
                                                                        ? `${(paymentCards.find((paymentCard) => paymentCard.token === customer.agreements[value].CardToken) || {}).issuer
                                                                        } ${(paymentCards.find((paymentCard) => paymentCard.token === customer.agreements[value].CardToken) || {}).brand
                                                                        } ${(paymentCards.find((paymentCard) => paymentCard.token === customer.agreements[value].CardToken) || {}).type}`
                                                                        : ''}
                                                                />
                                                            )
                                                            : (<></>)}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid item xs={12}>
                                                        <HybridTextField
                                                            cancelled={customer.agreements[value].Status === 'CANCELLED' || customer.agreements[value].Status === 'None'}
                                                            alert={customer.agreements[value].arrearsAmount > 0}
                                                            fieldStyle={agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).badges.find((badge) => (badge.label === 'Arrears Amount'))}
                                                            label="Arrears Amount"
                                                            text={`£${customer.agreements[value].arrearsAmount.toFixed(2)}`}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {customer.agreements[value].ScheduleFrequency !== 'Monthly'
                                                            ? (
                                                                <HybridTextField
                                                                    cancelled={customer.agreements[value].Status === 'CANCELLED' || customer.agreements[value].Status === 'None'}
                                                                    label="Schedule Weekday"
                                                                    text={customer.agreements[value].Status !== 'None' ? moment(customer.agreements[value].portalNextPaymentDate).format('dddd') : ''}
                                                                />
                                                            )
                                                            : (<> </>)}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {customer.agreements[value].Card === 'YES'
                                                            ? (
                                                                <HybridTextField
                                                                    cancelled={customer.agreements[value].Status === 'CANCELLED' || customer.agreements[value].Status === 'None'}
                                                                    label="Card"
                                                                    text={`${customer.agreements[value].CardNo} exp ${customer.agreements[value].CardExpiry}`}
                                                                />
                                                            )
                                                            : (<></>)}
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                            {customer.agreements[value].items.length > 0
                                                ? (
                                                    <CustomerAgreementItems
                                                        items={customer.agreements[value].items}
                                                        error={errors}
                                                    />
                                                )
                                                : (
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        className={classes.sectionContainer}
                                                    >
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                cancelled={customer.agreements[value].Status === 'CANCELLED'}
                                                                alert
                                                                fieldStyle={agreementsAlerts.find((agreementAlerts) => agreementAlerts.agreementCode === customer.agreements[value].code).badges.find((badge) => (badge.label === 'Items'))}
                                                                label="Items"
                                                                text="None"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )}

                                            {
                                                !currentAgreementCancelled
                                                && currentAgreement.items.length > 0
                                                && !currentAgreementFullyDelivered
                                                && (
                                                    (currentAgreement.Type !== 'Continuous' && currentPaymentPlanAuthorisedRecurringPayments < (currentAgreement.ScheduleFrequency === 'Weekly' ? 3 : 1))
                                                    || !currentAgreementAgentFeePaid
                                                    || !currentAgreementDeliveryChargePaid
                                                    || !currentAgreementAdvancePaymentPaid
                                                )
                                                    ? (
                                                        <div className={`${classes.deliveriesAlertContainer} ${classes.bumpBottom4U}`}>
                                                            <div>
                                                                DO NOT DELIVER ITEMS ON THIS AGREEMENT
                                                            </div>
                                                            {!currentAgreementAgentFeePaid
                                                                && (
                                                                    <div className={classes.deliveryAlertList}>
                                                                        <BulletIcon className={classes.bulletIcon} />
                                                                        Agent fee not fully paid
                                                                        Advance payment not fully paid
                                                                        {' '}
                                                                        (balance due: £
                                                                        {(currentAgreementCollectableAgentFee - currentAgreement.portalPaidAmount).toFixed(2)}
                                                                        )
                                                                    </div>
                                                                )}
                                                            {!currentAgreementDeliveryChargePaid
                                                                && (
                                                                    <div className={classes.deliveryAlertList}>
                                                                        <BulletIcon className={classes.bulletIcon} />
                                                                        Delivery charge not fully paid
                                                                        {' '}
                                                                        (balance due: £
                                                                        {(currentAgreement.deliveryAmount - Math.max(0, (currentAgreement.portalPaidAmount - currentAgreementCollectableAgentFee))).toFixed(2)}
                                                                        )
                                                                    </div>
                                                                )}
                                                            {!currentAgreementAdvancePaymentPaid
                                                                && (
                                                                    <div className={classes.deliveryAlertList}>
                                                                        <BulletIcon className={classes.bulletIcon} />
                                                                        Advance payment not fully paid
                                                                        {' '}
                                                                        (balance due: £
                                                                        {(currentAgreement.depositAmount - Math.max(0, (currentAgreement.portalPaidAmount - (currentAgreementCollectableAgentFee + currentAgreement.deliveryAmount)))).toFixed(2)}
                                                                        )
                                                                    </div>
                                                                )}
                                                            {(currentAgreement.Type !== 'Continuous' && currentPaymentPlanAuthorisedRecurringPayments < (currentAgreement.ScheduleFrequency === 'Weekly' ? 3 : 1))
                                                                && (
                                                                    <div className={classes.deliveryAlertList}>
                                                                        <BulletIcon className={classes.bulletIcon} />
                                                                        {(currentAgreement.ScheduleFrequency === 'Weekly' ? 3 : 1)}
                                                                        {' '}
                                                                        Recurring payments have not been authorised
                                                                        {' '}
                                                                        (
                                                                        {(currentAgreement.ScheduleFrequency === 'Weekly' ? 3 : 1) - currentPaymentPlanAuthorisedRecurringPayments}
                                                                        {' '}
                                                                        additional payments required)
                                                                    </div>
                                                                )}
                                                        </div>
                                                    )
                                                    : null
                                            }

                                            {!currentAgreementCancelled
                                                && currentAgreement.items.length > 0
                                                && !currentAgreementFullyDelivered
                                                && (currentAgreement.Type === 'Continuous' || currentPaymentPlanAuthorisedRecurringPayments >= (currentAgreement.ScheduleFrequency === 'Weekly' ? 3 : 1))
                                                && currentAgreementAgentFeePaid
                                                && currentAgreementDeliveryChargePaid
                                                && currentAgreementAdvancePaymentPaid
                                                ? (
                                                    <div className={`${classes.deliveriesOKContainer} ${classes.bumpBottom4U}`}>
                                                        IT IS OK TO DELIVER ITEMS ON THIS AGREEMENT
                                                    </div>
                                                )
                                                : null}

                                            <CustomerAgreementPayments
                                                payments={customer.agreements[value].payments}
                                                error={errors}
                                            />

                                            {/* customer.agreements[value].Status !== 'CANCELLED' && customer.agreements[value].Status !== 'None'
                                                ? (
                                                    <Button
                                                        onClick={this.handleManualPaymentClick}
                                                        variant="contained"
                                                    >
                                            MANUAL PAYMENT
                                                    </Button>
                                                )
                                                : null
                                            */}
                                        </Paper>

                                        <Dialog
                                            open={showManualPaymentDialog}
                                            onClose={this.handleCloseManualPaymentDialogClick}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogContent className={classes.dialogContent}>
                                                <div className={classes.dialogContentContainer}>
                                                    <>
                                                        <Typography variant="h6" gutterBottom>
                                                            Manual Payment
                                                        </Typography>
                                                        <form onSubmit={this.handleSubmitManualPayment}>
                                                            {manualPaymentStage === 'amount'
                                                                ? (
                                                                    <>
                                                                        <DialogContentText>
                                                                            Enter the payment amount
                                                                        </DialogContentText>
                                                                        <TextField
                                                                            className={`${classes.formControl} ${classes.marginTop3}`}
                                                                            name="amount"
                                                                            id="amount"
                                                                            value={manualPaymentForm.amount}
                                                                            onChange={this.updateManualPaymentData}
                                                                            label="Amount"
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                            error={!!errors.weeklyRentalAmount}
                                                                            helperText={errors.weeklyRentalAmount}
                                                                            InputProps={{
                                                                                startAdornment:
                                                                        <InputAdornment
                                                                            disableTypography
                                                                            className={classes.inputAdornment}
                                                                            position="start"
                                                                        >
                                                                            £
                                                                        </InputAdornment>,
                                                                            }}
                                                                        />
                                                                        <Button
                                                                            variant="contained"
                                                                            className={classes.dialogButton}
                                                                            onClick={this.handleManualPaymentNextClick}
                                                                            disabled={disableManualPaymentNextButton}
                                                                        >
                                                                            NEXT
                                                                        </Button>
                                                                    </>
                                                                )
                                                                : null}
                                                            {manualPaymentStage === 'cards'
                                                                ? (
                                                                    <>
                                                                        <FormControl
                                                                            component="fieldset"
                                                                            className={`${classes.formControl} ${classes.radioButtonsContainer}`}
                                                                        >
                                                                            <RadioGroup
                                                                                aria-label="paymentCard"
                                                                                name="paymentCard"
                                                                                className={classes.group}
                                                                                value={manualPaymentForm.paymentCard}
                                                                                onChange={this.updateManualPaymentData}
                                                                            >
                                                                                {paymentCards.map((paymentCard) => (
                                                                                    <FormControlLabel
                                                                                        key={paymentCard.token}
                                                                                        value={paymentCard.token}
                                                                                        control={<Radio className={classes.cardRadio} />}
                                                                                        label={(
                                                                                            <div className={classes.issuerRadioLabel}>
                                                                                                Card
                                                                                                {' '}
                                                                                                {paymentCard.cardNo}
                                                                                                {' '}
                                                                                                Exp
                                                                                                {' '}
                                                                                                {paymentCard.expiry}
                                                                                                <div
                                                                                                    className={classes.issuerRadioDescription}
                                                                                                >
                                                                                                    {paymentCard.brand}
                                                                                                    {' '}
                                                                                                    {paymentCard.type}
                                                                                                    {' '}
                                                                                                    -
                                                                                                    {' '}
                                                                                                    {paymentCard.issuer}
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    />
                                                                                ))}
                                                                                <FormControlLabel
                                                                                    key="new"
                                                                                    value="new"
                                                                                    control={<Radio />}
                                                                                    label="New payment card"
                                                                                />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                        <Button
                                                                            variant="contained"
                                                                            className={classes.dialogButton}
                                                                            onClick={this.handleManualPaymentNextClick}
                                                                            disabled={disableManualPaymentNextButton}
                                                                        >
                                                                            NEXT
                                                                        </Button>
                                                                    </>
                                                                )
                                                                : null}
                                                            {manualPaymentStage === 'result'
                                                                ? (
                                                                    <>
                                                                        <Grid
                                                                            container
                                                                            spacing={0}
                                                                            direction="column"
                                                                            alignItems="center"
                                                                            justify="center"
                                                                            style={{ minHeight: '22vh' }}
                                                                        >
                                                                            <Grid
                                                                                items
                                                                                spacing={0}
                                                                                direction="column"
                                                                                alignItems="center"
                                                                                justify="center"
                                                                                style={{ width: 240, display: 'inherit' }}
                                                                            >

                                                                                {
                                                                                    (() => {
                                                                                        switch (true) {
                                                                                        case transactionStatusWaiting: {
                                                                                            return (
                                                                                                <>
                                                                                                    <CircularProgress
                                                                                                        className={classes.progress}
                                                                                                        color="secondary"
                                                                                                    />
                                                                                                    <Typography variant="h6" className={classes.title}>
                                                                                                        Please Wait
                                                                                                    </Typography>
                                                                                                </>
                                                                                            );
                                                                                        }
                                                                                        case manualPaymentTransctionStatus === 'Declined': {
                                                                                            return (
                                                                                                <>
                                                                                                    <DeclinedIcon className={classes.declinedStatusIcon} />
                                                                                                    <Typography paragraph>
                                                                                                        Payment Declined
                                                                                                    </Typography>
                                                                                                    <div>
                                                                                                        <Button
                                                                                                            onClick={this.handleTryAgainClick}
                                                                                                            type="button"
                                                                                                            variant="contained"
                                                                                                            color="primary"
                                                                                                            className={classes.actionButton}
                                                                                                        >
                                                                                                            Try Again
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </>
                                                                                            );
                                                                                        }
                                                                                        case manualPaymentTransctionStatus === 'Authorised': {
                                                                                            return (
                                                                                                <>
                                                                                                    <AuthorisedIcon className={classes.authorisedStatusIcon} />
                                                                                                    <Typography paragraph>
                                                                                                        Payment Authorised
                                                                                                    </Typography>
                                                                                                </>
                                                                                            );
                                                                                        }
                                                                                        default: {
                                                                                            return (
                                                                                                <>
                                                                                                    <ErrorIcon className={classes.ErrorStatusIcon} />
                                                                                                    <Typography paragraph>
                                                                                                        Payment Error
                                                                                                    </Typography>
                                                                                                    <Typography
                                                                                                        paragraph
                                                                                                        align="center"
                                                                                                    >
                                                                                                        The payment services provider is taking too long to respond. It is not possible to determine if the transaction was Authorised or Declined.
                                                                                                    </Typography>
                                                                                                    <Button
                                                                                                        onClick={() => this.checkTransactionStatus()}
                                                                                                        type="button"
                                                                                                        variant="contained"
                                                                                                        color="primary"
                                                                                                        className={classes.actionButton}
                                                                                                    >
                                                                                                        Check Again
                                                                                                    </Button>
                                                                                                </>
                                                                                            );
                                                                                        }
                                                                                        }
                                                                                    })()
                                                                                }

                                                                            </Grid>
                                                                        </Grid>
                                                                    </>
                                                                )
                                                                : null}
                                                        </form>
                                                    </>
                                                </div>
                                                <Divider />
                                            </DialogContent>
                                            <DialogActions className={classes.dialogActions}>
                                                <Button
                                                    onClick={this.handleCloseManualPaymentDialogClick}
                                                    color="primary"
                                                    autoFocus
                                                >
                                                    Close
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                        <Dialog
                                            open={showSendTokenisationLinkDialog}
                                            onClose={this.handleCloseSendTokenisationLinkDialogClick}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogContent className={classes.dialogContent}>
                                                <div className={classes.dialogContentContainer}>
                                                    <>
                                                        <Typography variant="h6" gutterBottom>
                                                            Send Tokenisation Request
                                                        </Typography>
                                                        <form onSubmit={this.handleSendTokenisationLink}>
                                                            {sendTokenisationLinkStage === 'method'
                                                                ? (
                                                                    <>
                                                                        <DialogContentText>
                                                                            Select delivery method
                                                                        </DialogContentText>
                                                                        <FormControl
                                                                            component="fieldset"
                                                                            className={`${classes.formControl} ${classes.radioButtonsContainer}`}
                                                                        >
                                                                            <RadioGroup
                                                                                aria-label="messageType"
                                                                                name="messageType"
                                                                                className={classes.group}
                                                                                value={sendTokenisationLinkForm.messageType}
                                                                                onChange={this.updateSendTokenisationLinkData}
                                                                            >
                                                                                <FormControlLabel
                                                                                    key="sms"
                                                                                    value="sms"
                                                                                    control={<Radio />}
                                                                                    label="SMS text"
                                                                                />
                                                                                <FormControlLabel
                                                                                    key="email"
                                                                                    value="email"
                                                                                    control={<Radio />}
                                                                                    label="Email"
                                                                                />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                        <Button
                                                                            variant="contained"
                                                                            className={classes.dialogButton}
                                                                            onClick={this.handleSendTokenisationLinkNextClick}
                                                                        >
                                                                            NEXT
                                                                        </Button>
                                                                    </>
                                                                )
                                                                : null}
                                                            {sendTokenisationLinkStage === 'detail'
                                                                ? (
                                                                    <>
                                                                        <DialogContentText>
                                                                            Select destination
                                                                        </DialogContentText>
                                                                        <FormControl
                                                                            component="fieldset"
                                                                            className={`${classes.formControl} ${classes.radioButtonsContainer}`}
                                                                        >
                                                                            <RadioGroup
                                                                                aria-label="destination"
                                                                                name="destination"
                                                                                className={classes.group}
                                                                                value={sendTokenisationLinkForm.destination}
                                                                                onChange={this.updateSendTokenisationLinkData}
                                                                            >
                                                                                <FormControlLabel
                                                                                    key="current"
                                                                                    value="current"
                                                                                    control={<Radio />}
                                                                                    label={sendTokenisationLinkForm.messageType === 'sms'
                                                                                        ? customer.mobileTelephoneNumber
                                                                                        : customer.emailAddress}
                                                                                />
                                                                                <FormControlLabel
                                                                                    key="new"
                                                                                    value="new"
                                                                                    control={<Radio />}
                                                                                    label={sendTokenisationLinkForm.messageType === 'sms'
                                                                                        ? 'New mobile number'
                                                                                        : 'New email address'}
                                                                                />
                                                                            </RadioGroup>
                                                                            {sendTokenisationLinkForm.destination === 'new' && (
                                                                                <TextField
                                                                                    className={`${classes.formControl} ${classes.marginTop3} ${classes.textField}`}
                                                                                    name="to"
                                                                                    id="to"
                                                                                    value={sendTokenisationLinkForm.to}
                                                                                    onChange={this.updateSendTokenisationLinkData}
                                                                                    label={sendTokenisationLinkForm.messageType === 'sms' ? 'New mobile number' : 'New email address'}
                                                                                    margin="normal"
                                                                                    variant="outlined"
                                                                                    required
                                                                                    error={!!errors.to}
                                                                                    helperText={errors.to}
                                                                                />
                                                                            )}
                                                                        </FormControl>
                                                                        <Button
                                                                            variant="contained"
                                                                            className={classes.dialogButton}
                                                                            disabled={disableSendTokenisationLinkSendButton}
                                                                            type="submit"
                                                                        >
                                                                            SEND
                                                                        </Button>
                                                                    </>
                                                                )
                                                                : null}
                                                        </form>
                                                    </>
                                                </div>
                                            </DialogContent>
                                            <Divider className={classes.dialogActionsDivider} />
                                            <DialogActions className={classes.dialogActions}>
                                                <Button
                                                    onClick={this.handleCloseSendTokenisationLinkDialogClick}
                                                    color="primary"
                                                    autoFocus
                                                >
                                                    Close
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                        <Dialog
                                            open={showControlDeviceDialog}
                                            onClose={this.handleCloseControlDeviceDialogClick}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogContent className={classes.dialogContent}>
                                                <div className={classes.dialogContentContainer}>
                                                    <>
                                                        <Typography variant="h6" gutterBottom>
                                                            Control Device
                                                        </Typography>
                                                        <form onSubmit={this.handleSendControlDeviceCommand}>
                                                            <>
                                                                <DialogContentText>
                                                                    The control device last reported it was
                                                                    {' '}
                                                                    {(((customer.agreements[value] || {}).items || []).find((item) => item.controlDeviceCode !== null) || {}).controlDeviceState}
                                                                    .
                                                                </DialogContentText>
                                                                <DialogContentText>
                                                                    The last command sent was
                                                                    {' '}
                                                                    {(((customer.agreements[value] || {}).items || []).find((item) => item.controlDeviceCode !== null) || {}).lastCommandSent}
&nbsp;
                                                                    on
                                                                    {' '}
                                                                    {moment((((customer.agreements[value] || {}).items || []).find((item) => item.controlDeviceCode !== null) || {}).lastCommandSentDateTime).format('DD/MM/YY')}
                                                                    {' '}
                                                                    at&nbsp;
                                                                    {moment((((customer.agreements[value] || {}).items || []).find((item) => item.controlDeviceCode !== null) || {}).lastCommandSentDateTime).format('HH:mm')}
                                                                    .
                                                                </DialogContentText>
                                                                <DialogContentText className={classes.bumpTop3}>
                                                                    Select command to send
                                                                </DialogContentText>
                                                                <FormControl
                                                                    component="fieldset"
                                                                    className={`${classes.formControl} ${classes.radioButtonsContainer}`}
                                                                >
                                                                    <RadioGroup
                                                                        aria-label="command"
                                                                        name="command"
                                                                        className={classes.group}
                                                                        value={controlDeviceForm.command}
                                                                        onChange={this.updateControlDeviceData}
                                                                    >
                                                                        <FormControlLabel
                                                                            key="ON"
                                                                            value="ON"
                                                                            control={<Radio />}
                                                                            label="ON"
                                                                        />
                                                                        <FormControlLabel
                                                                            key="OFF"
                                                                            value="OFF"
                                                                            control={<Radio />}
                                                                            label="OFF"
                                                                        />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                                <Button
                                                                    variant="contained"
                                                                    className={classes.dialogButton}
                                                                    disabled={disableControlDeviceSendButton}
                                                                    type="submit"
                                                                >
                                                                    SEND
                                                                </Button>
                                                            </>
                                                        </form>
                                                    </>
                                                </div>
                                            </DialogContent>
                                            <Divider className={classes.dialogActionsDivider} />
                                            <DialogActions className={classes.dialogActions}>
                                                <Button
                                                    onClick={this.handleCloseControlDeviceDialogClick}
                                                    color="primary"
                                                    autoFocus
                                                >
                                                    Close
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                        <Dialog
                                            open={showRecordDeliveryDialog}
                                            onClose={this.handleCloseRecordDeliveryDialogClick}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogContent className={classes.dialogContent}>
                                                <div className={classes.dialogContentContainer}>
                                                    <>
                                                        <Typography variant="h6" gutterBottom>
                                                            Record Delivery
                                                        </Typography>
                                                        <DialogContentText>
                                                            Agreement Items
                                                        </DialogContentText>
                                                        <Grid container spacing={0}>
                                                            {recordDeliveryData.undeliveredItems.map((item, i) => (
                                                                <Grid item xs={12}>
                                                                    <FormControlLabel
                                                                        control={(
                                                                            <Checkbox
                                                                                name={item.code}
                                                                                id={item.code}
                                                                                checked={item.deliveryStatus === 1}
                                                                                onChange={this.updateRecordDeliveryDataCheckbox(item.code)}
                                                                            />
                                                                        )}
                                                                        label={item.modelNo}
                                                                    />
                                                                </Grid>
                                                            ))}
                                                        </Grid>

                                                        <Grid container spacing={0}>
                                                            <Grid item xs={12}>
                                                                <DateTimePicker
                                                                    name="dateTime"
                                                                    id="dateTime"
                                                                    margin="normal"
                                                                    label="Delivery Date & Time"
                                                                    value={recordDeliveryData.dateTime}
                                                                    onChange={this.handleRecordDeliveryDateTimeChange}
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    format="DD-MM-YYYY HH:mm"
                                                                    showTodayButton
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormControl
                                                                    className={classes.menuFormControl}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    error={!!errors.engineerContactCode}
                                                                >
                                                                    <InputLabel htmlFor="engineerContactCode" className={classes.inputLabel}>Delivery Engineer</InputLabel>
                                                                    <Select
                                                                        keyboard="true"
                                                                        input={(
                                                                            <OutlinedInput
                                                                                onChange={this.updateRecordDeliveryData}
                                                                                name="engineerContactCode"
                                                                                id="engineerContactCode"
                                                                            />
                                                                        )}
                                                                        value={recordDeliveryData.engineerContactCode || ''}
                                                                    >
                                                                        <MenuItem value=""><em>None</em></MenuItem>
                                                                        {engineers.map((engineer, i) => (
                                                                            <MenuItem
                                                                                key={i}
                                                                                value={engineer.contactCode}
                                                                            >
                                                                                {engineer.fullName}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                    {errors.engineerContactCode && (
                                                                        <FormHelperText>{errors.engineerContactCode}</FormHelperText>
                                                                    )}
                                                                </FormControl>
                                                            </Grid>

                                                        </Grid>
                                                        <Button
                                                            variant="contained"
                                                            className={classes.dialogButton}
                                                            disabled={disableRecordDeliverySubmitButton || recordDeliveryData.undeliveredItems.filter((item) => item.deliveryStatus === 1).length === 0}
                                                            onClick={this.handleRecordDeliverySubmitClick}
                                                        >
                                                            SUBMIT
                                                        </Button>
                                                    </>
                                                </div>
                                            </DialogContent>
                                            <Divider className={classes.dialogActionsDivider} />
                                            <DialogActions className={classes.dialogActions}>
                                                <Button
                                                    onClick={this.handleCloseRecordDeliveryDialogClick}
                                                    color="primary"
                                                    autoFocus
                                                >
                                                    Close
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                        <Dialog
                                            open={showUpdatePaymentPlanStatusDialog}
                                            onClose={this.handleCloseUpdatePaymentPlanStatusDialogClick}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogContent className={classes.dialogContent}>
                                                <div className={classes.dialogContentContainer}>
                                                    <>
                                                        <Typography variant="h6" gutterBottom>
                                                            Payment Plan Status
                                                        </Typography>
                                                        <>
                                                            <DialogContentText>
                                                                Select new status...
                                                            </DialogContentText>

                                                            <FormControl
                                                                className={classes.menuFormControl}
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.title}
                                                            >
                                                                <InputLabel htmlFor="title" className={classes.inputLabel}>Status</InputLabel>
                                                                <Select
                                                                    keyboard="true"
                                                                    input={(
                                                                        <OutlinedInput
                                                                            onChange={this.updatePaymentPlanStatusData}
                                                                            name="Status"
                                                                            id="Status"
                                                                        />
                                                                    )}
                                                                    value={paymentPlanStatusData.Status || ''}
                                                                >
                                                                    <MenuItem value=""><em>None</em></MenuItem>
                                                                    {paymentPlanStatus.map((Status) => (
                                                                        <MenuItem
                                                                            key={Status}
                                                                            value={Status}
                                                                        >
                                                                            {Status}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {errors.Status && (
                                                                    <FormHelperText>{errors.Status}</FormHelperText>
                                                                )}
                                                            </FormControl>
                                                            <Button
                                                                variant="contained"
                                                                className={classes.dialogButton}
                                                                disabled={disableUpdatePaymentPlanSubmitButton}
                                                                onClick={this.handleSubmitUpdatePaymentPlanStatusClick}
                                                            >
                                                                SUBMIT
                                                            </Button>
                                                        </>
                                                    </>
                                                </div>
                                            </DialogContent>
                                            <Divider className={classes.dialogActionsDivider} />
                                            <DialogActions className={classes.dialogActions}>
                                                <Button
                                                    onClick={this.handleCloseUpdatePaymentPlanStatusDialogClick}
                                                    color="primary"
                                                    autoFocus
                                                >
                                                    Close
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                    </>
                                )
                                : <div className={classes.emptyContent}>No agreements found.</div>}
                        </>
                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )}
            </Tile>
        );
    }
}

export default withSnackbar(withStyles(styles)(CustomerAgreements));
