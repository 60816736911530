import React from 'react';
import {
    CssBaseline,
    Chip,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import qs from 'qs';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import Layout from './Layout';
import fetch from '../utilities/fetch';

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        marginBottom: theme.spacing.unit * 3,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    authorisedPaymentChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '87px',
    },
    declinedPaymentChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '87px',
    },
    unknownPaymentChip: {
        minWidth: '87px',
    },
    noMissedPaymentsChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '40px',
    },
    missedPaymentsChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '40px',
    },
    unknownMissedPaymentsChip: {
        minWidth: '40px',
    },
    chip: {
        height: 21,
        borderRadius: 5,
    },
});

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

class Agreements extends React.Component {
    state = {
        agreements: [],
        subset: '',
    };

    async componentDidMount() {
        const { subset } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || 0;
        this.setState({ subset });
        this.getAgreements(subset);
    }

    componentDidUpdate(prevProps) {
        const { subset: prevSubset } = qs.parse(prevProps.location.search, { ignoreQueryPrefix: true }) || 0;
        const { subset } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || 0;

        if (subset !== prevSubset) {
            this.getAgreements(subset);
        }
    }

    getAgreements = async (subset) => {
        const data = await fetch(`https://api.portal2.payweeklycarpets.co.uk/core/agreements?subset=${subset}`).then((response) => response.json());
        this.setState({ agreements: data });
    };

    handleLeadClick = (customerCode, agreementCode) => {
        this.props.history.push(`/contact/${customerCode}?agreementCode=${agreementCode}`);
    };

    render() {
        const { classes } = this.props;
        const { agreements, subset } = this.state;

        return (
            <Layout pageTitle={`Agreements - ${subset}`}>
                <CssBaseline />
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead}>#</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Agreement Code</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Customer</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Address</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Start Date</CustomTableCell>
                                <CustomTableCell align="center" className={classes.tableHead}>Arrears</CustomTableCell>
                                <CustomTableCell align="center" className={classes.tableHead}>Missed Payments</CustomTableCell>
                                <CustomTableCell align="center" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {agreements.map((agreement, index) => (
                                <TableRow onClick={() => this.handleLeadClick(agreement.contactCode, agreement.code)} className={classes.row} key={agreement.code}>
                                    <CustomTableCell component="th" scope="row">{index + 1}</CustomTableCell>
                                    <CustomTableCell align="left">{`RA${agreement.code}`}</CustomTableCell>
                                    <CustomTableCell align="left">{agreement.fullName}</CustomTableCell>
                                    <CustomTableCell align="left">{agreement.shortAddress}</CustomTableCell>
                                    <CustomTableCell align="right">{agreement.startDate}</CustomTableCell>
                                    <CustomTableCell align="center">
                                        {
                                            (() => {
                                                switch (agreement.arrears) {
                                                case 0: {
                                                    return <Chip label={`£${agreement.arrears.toFixed(2)}`} className={`${classes.chip} ${classes.authorisedPaymentChip}`} />;
                                                }
                                                default: {
                                                    return <Chip label={`£${agreement.arrears.toFixed(2)}`} className={`${classes.chip} ${classes.declinedPaymentChip}`} />;
                                                }
                                                case -999: {
                                                    return <Chip label="?" className={`${classes.chip} ${classes.unknownPaymentChip}`} />;
                                                }
                                                }
                                            })()
                                        }

                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {
                                            (() => {
                                                switch (agreement.missedPayments) {
                                                case 0: {
                                                    return <Chip label={agreement.missedPayments} className={`${classes.chip} ${classes.noMissedPaymentsChip}`} />;
                                                }
                                                default: {
                                                    return <Chip label={agreement.missedPayments} className={`${classes.chip} ${classes.missedPaymentsChip}`} />;
                                                }
                                                case -999: {
                                                    return <Chip label="?" className={`${classes.chip} ${classes.unknownMissedPaymentsChip}`} />;
                                                }
                                                }
                                            })()
                                        }

                                    </CustomTableCell>
                                    <CustomTableCell align="right"><IconButton className={classes.button} aria-label="More info"><InfoIcon /></IconButton></CustomTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Layout>
        );
    }
}

export default withStyles(styles)(Agreements);
