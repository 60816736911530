import React from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

import fetch from '../utilities/fetch';
import sleep from '../utilities/sleep';

const styles = theme => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    divider: {
        marginTop: theme.spacing.unit * 2,
        // marginBottom: theme.spacing.unit * 2,
    },
    bodyContainer: {
        minHeight: 100,
    },
    bottomBarContainer: {
        width: '100%',
        textAlign: 'right',
    },
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    dialogContentContainer: {
        // marginTop: theme.spacing.unit * 2,
        // marginBottom: theme.spacing.unit * 2,
        minWidth: 600,
        minHeight: 269,
        paddingBottom: 0,
    },
    dialogButton: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
        marginRight: theme.spacing.unit * 2,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 2,
        // marginBottom: theme.spacing.unit * 2,
    },
    dialogActions: {
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
    },
    progress: {
        margin: theme.spacing.unit * 3,
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
    },
});

class ItemRentalAgreement extends React.Component {
    state = {
        error: false,
        loading: false,
        snackbarOpen: false,
        loadingStep: false,
        showDialog: false,
        itemRentalAgreementCode: '',
        contact: [],
        saved: false,
        errors: {},
    }

    componentDidMount() {
        this.setState({
            itemRentalAgreementCode: this.props.itemRentalAgreementCode,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.itemRentalAgreementCode !== prevProps.itemRentalAgreementCode) {
            this.setState({
                itemRentalAgreementCode: this.props.itemRentalAgreementCode,
            });
        }
    }

    handleFormSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        const url = new URL(`https://api.portal2.payweeklycarpets.co.uk/core/item/${this.props.itemCode}`);
        const data = await fetch(url, {
            method: 'PATCH',
            body: JSON.stringify({
                itemRentalAgreementCode: formData.get('itemRentalAgreementCode'),
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        this.setState({
            itemRentalAgreementCode: formData.get('itemRentalAgreementCode'),
            showDialog: false,
        });
    }

    handleEditItemRentalAgreementClick = async () => {
        this.setState({
            showDialog: true,
        });
    }

    handleCloseDialogClick = () => {
        this.setState({
            showDialog: false,
        });
    }

    render() {
        const { classes } = this.props;
        const {
            itemRentalAgreementCode,
            showDialog,
            errors,
            loadingStep,
        } = this.state;

        return (
            <React.Fragment>
                <div className={classes.bodyContainer}>
                    { itemRentalAgreementCode
                        ? (
                            <Typography>
                                itemRentalAgreementCode:
                                {' '}
                                {itemRentalAgreementCode}
                                <br />
                            </Typography>
                        )
                        : (
                            <React.Fragment>
                                <Typography>
                                    No Rental Agreement Code Assigned
                                </Typography>
                            </React.Fragment>
                        )
                    }
                    <Divider className={classes.divider} />
                </div>
                <div className={classes.bottomBarContainer}>
                    <Button
                        color="default"
                        className={classes.textButton}
                        onClick={this.handleEditItemRentalAgreementClick}
                    >
                    Edit Item Rental Agreement
                    </Button>
                </div>

                <Dialog
                    open={showDialog}
                    onClose={this.handleCloseDialogClick}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={classes.dialogContentContainer}>
                        <div>
                            <React.Fragment>
                                <Typography variant="h6" gutterBottom>
                                    Edit Item Rental Agreement
                                </Typography>
                                <DialogContentText>
                                        Enter the item Rental Agreement code below and click [UPDATE].
                                </DialogContentText>
                                <form onSubmit={this.handleFormSubmit}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <TextField
                                                className={classes.formControl}
                                                name="itemRentalAgreementCode"
                                                id="itemRentalAgreementCode"
                                                label="Rental Agreement Code"
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                error={!!errors.rentalAgreementCode}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button
                                        variant="contained"
                                        className={classes.dialogButton}
                                        type="submit"
                                    >
                                        SUBMIT
                                    </Button>
                                </form>
                            </React.Fragment>
                        </div>
                        <Divider className={classes.dialogDivider} />
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button onClick={this.handleCloseDialogClick} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(ItemRentalAgreement);
