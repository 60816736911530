import React from 'react';
import {
    CssBaseline,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Layout from '../Layout';
import EngineerSchedule from '../../components/v2/engineerSchedule';
import fetch, { fetchJson2 } from '../../utilities/fetch';

const { REACT_APP_API_SERVER } = process.env;

const styles = theme => ({
});

class VisitSchedule extends React.Component {
    state = {
    }

    async componentDidMount() {
    }


    render() {
        const { classes } = this.props;
        const {
            errors,
        } = this.state;

        return (
            <Layout pageTitle="Visit Schedule">
                <React.Fragment>
                    <CssBaseline />
                    <EngineerSchedule />
                </React.Fragment>
            </Layout>
        );
    }
}

export default withStyles(styles)(VisitSchedule);
