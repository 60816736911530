import React from 'react';

import {
    Button,
    CssBaseline,
    Grid,
    Typography,
} from '@material-ui/core';

import { withRouter } from 'react-router-dom';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import { withSnackbar } from 'notistack';
import withStyles from '@material-ui/core/styles/withStyles';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment-timezone';
import fetch, { fetchJson2 } from '../utilities/fetch';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    main: {
        maxWidth: 300,
        textAlign: 'center',
        display: 'block', // Fix IE 11 issue.
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    logo: {
        margin: theme.spacing.unit * 5,
        width: 128,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    snackbar: {
        backgroundColor: theme.palette.error.dark,
    },
    snackbarIcon: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
        fontSize: 20,
        // margin: theme.spacing.unit,
    },
    snackbarMessage: {
        fontSize: 'inherit',
        // marginBottom: theme.spacing.unit,
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 1,
    },
    instructions: {
        marginBottom: theme.spacing.unit * 3,
        marginTop: theme.spacing.unit * 3,
    },
    fieldAddButton: {
        marginTop: theme.spacing.unit * 4,
    },
});

const currentDate = moment.tz(moment(), 'Europe/London');

class purchaseOrderExpectedDeliveryConfirmation extends React.Component {
    state = {
        estimatedDeliveryDate: null, // currentDate,
        responseOK: false,
    };

    static getDerivedStateFromProps(props, state) {
        const { guid } = props.match.params;
        return {
            guid,
        };
    }

    handleSaveEstimatedDeliveryDate = (event) => {
        event.preventDefault();
        this.setState({
            disableSaveButton: true,
        });
        const payload = {
            estimatedDeliveryDate: this.state.estimatedDeliveryDate,
        };

        this.saveEstimateddeliveryDate(payload).then(() => {
            this.setState({
                disableSaveButton: false,
            });
            this.props.enqueueSnackbar('Estimated Delivery Date Saved', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Save Estimated Delivery Date Failed', { variant: 'error' });
            this.setState({
                disableSaveButton: false,
            });
        });
    };

    saveEstimateddeliveryDate = async (payload) => {
        let response;
        let body;

        body = JSON.stringify(payload);
        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/purchaseOrder/estimatedDeliveryDate/${this.state.guid}`, {
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 204: {
            this.setState({
                responseOK: true,
            });
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    handleDateChange = (chosenDate) => {
        this.setState({
            estimatedDeliveryDate: moment(chosenDate).format('YYYY-MM-DD'),
        });
    };

    render = () => {
        const { classes } = this.props;
        const {
            disableSaveButton,
            estimatedDeliveryDate,
            responseOK,
        } = this.state;

        return (
            <div style={{ width: '100%' }}>
                <main className={classes.main}>
                    <CssBaseline />
                    <img
                        className={classes.logo}
                        src="https://pwc-email-images.s3.eu-west-1.amazonaws.com/logo-main-small.png"
                        alt="Pay Weekly Logo"
                    />
                    {responseOK
                        ? (
                            <Typography variant="h8" className={classes.instructions}>
                                Thank you!
                            </Typography>
                        )
                        : (
                            <>
                                <Typography variant="h8" className={classes.instructions}>
                                    Please enter the estimated delivery date of the purchase order below
                                </Typography>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <DatePicker
                                            name="estimatedDeliveryDate"
                                            id="estimatedDeliveryDate"
                                            margin="normal"
                                            label="Estimated Delivery Date"
                                            value={estimatedDeliveryDate}
                                            onChange={this.handleDateChange}
                                            fullWidth
                                            variant="outlined"
                                            format="DD/MM/YYYY"
                                            minDate={new Date(moment(currentDate).format('YYYY-MM-DD'))}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Button
                                            className={classes.fieldAddButton}
                                            onClick={this.handleSaveEstimatedDeliveryDate}
                                            disabled={!estimatedDeliveryDate}
                                        >
                                            SAVE

                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                </main>
            </div>
        );
    };
}

export default withSnackbar(withStyles(styles)(withRouter(purchaseOrderExpectedDeliveryConfirmation)));
