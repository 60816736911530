import React from 'react';
import {
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import AddIcon from '@material-ui/icons/Add';
import orderBy from 'lodash/orderBy';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import { withSnackbar } from 'notistack';
import DeliveryNoteItemsAddDialog from './deliveryNoteItemsAddDialog';

const styles = theme => ({
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
        minHeight: 100,
        paddingTop: theme.spacing.unit * 3,
    },
    progress: {
        opacity: '.5',
    },
    tableTitleLeft: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        alignItems: 'left',
        paddingTop: theme.spacing.unit * 2,
        fontWeight: 500,
        textTransform: 'uppercase',
        paddingBottom: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 2,
    },
    tableTitleRight: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 15,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        alignItems: 'right',
        paddingTop: theme.spacing.unit * 2,
        fontWeight: 500,
        textTransform: 'uppercase',
        paddingBottom: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    shortDescription: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
});

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        verticalAlign: 'top',
        paddingTop: 16,
        paddingBottom: 8,
    },
}))(TableCell);

class DeliveryNoteItems extends React.Component {
    state = {
        showAddItemDialog: false,
    }

    handleAddDeliveryNoteItemClick = () => {
        this.setState({
            showAddItemDialog: true,
        });
    }

    resetShowAddItemDialog = () => {
        this.setState({
            showAddItemDialog: false,
        });
    }

    handlePrintLabelClick = (barcode, quantity) => {
        console.log('>>>Handling Print Click');
        const barcodePrefix = barcode.substring(0, barcode.length - 6);
        let barcodePrefixLabel;
        switch (barcodePrefix) {
        case 'SC': barcodePrefixLabel = 'Stock';
            break;
        case 'P': barcodePrefixLabel = 'Product';
            break;
        default: barcodePrefixLabel = '';
        }
        this.props.printLabel(barcode, quantity).then(() => {
            this.props.enqueueSnackbar(`${barcodePrefixLabel} Label Print Request Sent`, { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar(`${barcodePrefixLabel} Label Print Request failed`, { variant: 'error' });
        });

        // if (barcodePrefix === 'SC') {
        //     this.props.printStockLabel(code).then(() => {
        //         this.props.enqueueSnackbar('Stock Label Print Request Sent', { variant: 'success' });
        //     }).catch(() => {
        //         console.log(this.props.errors);
        //         this.props.enqueueSnackbar('Stock Label Print Request failed', { variant: 'error' });
        //     });
        // } else {
        //     this.props.printProductLabel(code, quantity).then(() => {
        //         this.props.enqueueSnackbar('Product Label Print Request Sent', { variant: 'success' });
        //     }).catch(() => {
        //         console.log(this.props.errors);
        //         this.props.enqueueSnackbar('Product Label Print Request failed', { variant: 'error' });
        //     });
        // }
    }

    render() {
        const {
            bins,
            classes,
            deliveryNote,
            deliveryNoteItems,
            errors,
            products,
            stockrooms,
        } = this.props;
        const {
            showAddItemDialog,
        } = this.state;

        return (
            <React.Fragment>
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <div className={classes.tableTitleLeft}>ITEMS</div>
                    </Grid>
                    <Grid container xs={6} alignItems="flex-start" justify="flex-end" direction="row">
                        <IconButton
                            className={classes.button}
                            onClick={this.handleAddDeliveryNoteItemClick}
                        >
                            <AddIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Paper>
                    { deliveryNoteItems !== null
                        ? (
                            <React.Fragment>
                                <Table className={classes.table} padding="dense">
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell className={classes.tableHead}>Barcode</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead}>Manufacturer</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead}>Description</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead}>Colour</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead}>Length (m)</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead}>Width (m)</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead}>Quantity</CustomTableCell>
                                            <CustomTableCell className={classes.tableHead} />
                                            <CustomTableCell className={classes.tableHead} />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orderBy(deliveryNoteItems, 'code', 'desc').map(deliveryNoteItem => (
                                            <TableRow xxonClick={() => this.handleProductClick(deliveryNoteItem.barcode)} className={classes.row} key={deliveryNoteItem.barcode}>
                                                <CustomTableCell className={classes.customTableCell} align="left">
                                                    <div>{deliveryNoteItem.barcode}</div>
                                                    <div className={classes.shortDescription}>({deliveryNoteItem.stockControlTypeDescription})</div>
                                                </CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} align="left">{deliveryNoteItem.manufacturerName}</CustomTableCell>
                                                <CustomTableCell align="left">
                                                    <div>{deliveryNoteItem.modelNo}</div>
                                                    <div className={classes.shortDescription}>{deliveryNoteItem.shortDescription}</div>
                                                </CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} align="left">{deliveryNoteItem.colour}</CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} align="right">{deliveryNoteItem.length ? deliveryNoteItem.length.toFixed(2) : null}</CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} align="right">{deliveryNoteItem.width ? deliveryNoteItem.width.toFixed(2) : null}</CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} align="right">{deliveryNoteItem.stockControlTypeDescription === 'Tracked' ? null : deliveryNoteItem.quantity}</CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} align="center"><IconButton onClick={() => this.handlePrintLabelClick(deliveryNoteItem.barcode, deliveryNoteItem.quantity)} className={classes.button} aria-label="More info"><PrintIcon /></IconButton></CustomTableCell>
                                                <CustomTableCell className={classes.customTableCell} align="center"><IconButton className={classes.button} aria-label="More info"><InfoIcon /></IconButton></CustomTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <DeliveryNoteItemsAddDialog
                                    addDeliveryNoteItem={this.props.addDeliveryNoteItem}
                                    bins={bins}
                                    deliveryNote={deliveryNote}
                                    errors={errors}
                                    products={products}
                                    resetShowAddItemDialog={this.resetShowAddItemDialog}
                                    showAddItemDialog={showAddItemDialog}
                                    stockrooms={stockrooms}
                                />
                            </React.Fragment>
                        )
                        : (
                            <div className={classes.progressContainer}>
                                <CircularProgress className={classes.progress} variant="indeterminate" />
                            </div>
                        )
                    }
                </Paper>
            </React.Fragment>
        );
    }
}

const deliveryNoteItemsShape = {
    code: PropTypes.number,
    colour: PropTypes.string,
    length: PropTypes.number,
    manufacturerName: PropTypes.string,
    modelNo: PropTypes.string,
    shortDescription: PropTypes.string,
    width: PropTypes.number,
};

const deliveryNoteShape = {
    code: PropTypes.number,
    reference: PropTypes.string,
    supplierCode: PropTypes.number,
    supplierName: PropTypes.string,
    deliveryDate: PropTypes.string,
    processedByContactCode: PropTypes.number,
    processedByFullName: PropTypes.string,
    deliveryBranchCode: PropTypes.number,
    deliveryBranchName: PropTypes.string,
};

const productShape = {
    code: PropTypes.number,
    modelNo: PropTypes.string,
    manufacturerCode: PropTypes.number,
};

DeliveryNoteItems.propTypes = {
    addDeliveryNoteItem: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    deliveryNote: PropTypes.shape(deliveryNoteShape).isRequired,
    deliveryNoteItems: PropTypes.arrayOf(PropTypes.shape(deliveryNoteItemsShape)).isRequired,
    errors: PropTypes.object.isRequired,
    products: PropTypes.arrayOf(PropTypes.shape(productShape)).isRequired,
};

export default withSnackbar(withStyles(styles)(DeliveryNoteItems));
