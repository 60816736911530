import React from 'react';
import {
    withStyles,
} from '@material-ui/core/styles';
import {
    AppBar,
    Button,
    CircularProgress,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tabs,
    TextField,
    Typography,
} from '@material-ui/core';
// import PropTypes from 'prop-types';
import { DatePicker, DateTimePicker } from 'material-ui-pickers';
import AuthorisedIcon from '@material-ui/icons/CheckCircleOutline';
import DeclinedIcon from '@material-ui/icons/HighlightOff';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import UnassignIcon from '@material-ui/icons/AssignmentReturn';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import {
    Link,
    DirectLink,
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller,
} from 'react-scroll';
import { withSnackbar } from 'notistack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Error';
import CrossCircleIcon from '@material-ui/icons/Cancel';
import BulletIcon from '@material-ui/icons/FiberManualRecord';
import AddIcon from '@material-ui/icons/Add';
import SwapIcon from '@material-ui/icons/SwapHoriz';
import {
    get,
    isEmpty,
    sortBy,
} from 'lodash';
import moment from 'moment-timezone';
import GaugeChart from 'react-gauge-chart';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import classNames from 'classnames';
import { AddAPhoto } from '@material-ui/icons';
import HybridTextField from '../../hybridTextField';
// import ContactSaleImages from './contactSaleImages';
// import ContactSaleItems from './contactSaleItems';
// import ContactSalesPayments from './contactSalesPayments';
// import AgreementPaymentSummary from '../agreementPaymentSummary';
import titalise from '../../../utilities/titalise';
import Tile from '../../../containers/tile';
// import './react-pdf.css';
import {
    paymentPlanStatus,
    scheduleFrequencies,
} from '../../../utilities/menuOptions';
import EngineerSchedule from '../engineerSchedule';
import { visitPointsArray } from '../../../constants/arrays';
import ContactHistory from '../contactHistory';
import capitalisedSnakeCaseToTitleCase from '../../../utilities/capitalisedSnakeCaseToTitleCase';
import { paymentSourceLookUp } from '../../../utilities/lookUpTables';
import AgreementItemAddDialog from '../agreementItemAddDialog';
import AgreementItemExchangeDialog from '../agreementItemExchangeDialog';
import ContactSaleBadges from '../contactSaleBadges';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const styles = (theme) => ({
    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        marginBottom: 7,
        textAlign: 'left',
        width: '100%',
    },
    tileBottomDivider: {
        marginTop: theme.spacing.unit * 3,
        width: '100%',
    },
    bottomBarContainer: {
        textAlign: 'right',
        width: '100%',
    },
    textButton: {
        marginBottom: 0,
        marginTop: theme.spacing.unit * 1.5,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    hybridTextIconLeft: {
        fontSize: 16,
        marginRight: theme.spacing.unit * 1,
        transform: 'translate(0%, 13%)',
    },
    hybridTextIconRight: {
        fontSize: 16,
        marginLeft: theme.spacing.unit * 1,
        transform: 'translate(0%, 13%)',
    },
    cancelledIcon: {
        color: '#d7d7d7',
        // color: '#f1180b',
    },
    warmIcon: {
        color: '#E7C251',
    },
    coldIcon: {
        color: '#BBFFFF',
    },
    notSetIcon: {
        color: '#d7d7d7',
    },
    activeIcon: {
        color: '#4caf50',
    },
    warningIcon: {
        color: '#fac518',
    },
    alert: {
        color: '#f1180b',
    },
    alertContainer: {
        /* text-align: left; */
        border: '1px solid',
        borderRadius: 4,
        fontWeight: 400,
        marginBottom: theme.spacing.unit * 4,
        padding: theme.spacing.unit * 2,
        width: '100%',
    },
    deliveriesAlertContainer: {
        /* text-align: left; */
        backgroundColor: '#f1180b',
        borderRadius: 4,
        color: '#fff',
        fontWeight: 600,
        padding: theme.spacing.unit * 2,
        width: '100%',
    },
    deliveriesOKContainer: {
        /* text-align: left; */
        backgroundColor: '#4caf50',
        borderRadius: 4,
        color: '#fff',
        fontWeight: 600,
        padding: theme.spacing.unit * 2,
        width: '100%',
    },
    bumpBottom4U: {
        marginBottom: theme.spacing.unit * 4,
    },
    bumpBottom3U: {
        marginBottom: theme.spacing.unit * 3,
    },
    bumpBottom2U: {
        marginBottom: theme.spacing.unit * 2,
    },
    bumpBottom1U: {
        marginBottom: theme.spacing.unit * 1,
    },
    warning: {
        color: '#ff9800',
    },

    tabsPaper: {
        alignItems: 'center',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
        paddingBottom: 24,
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 32,
        width: '100%',
    },
    tabsAppBar: {
        backgroundColor: theme.palette.primary.lighter,
    },
    tab: {
        color: 'white',
        fontSize: 15,
        fontWeight: 500,
        // height: 64,
        minHeight: 38,
    },
    tabs: {
        // height: 64,
        minHeight: 38,
        padding: 0,
    },
    tabButton: {
        backgroundColor: 'red',
        height: 64,
        minHeight: 38,
        padding: 0,
    },
    inputLabel: {
        backgroundColor: '#fff',
        marginLeft: -5,
        paddingLeft: 8,
        paddingRight: 8,
    },
    formInstructions: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 3,
    },
    statusSelect: {
        width: 200,
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        width: '100%',
    },
    dialogContentContainer: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 5,
    },
    dialogContent: {
        paddingBottom: 16,
        minWidth: 390,
    },
    dialogActionsDivider: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    radioDescription: {
        marginLeft: 34,
        marginRight: theme.spacing.unit * 3,
        marginTop: -8,
    },
    issuerRadioDescription: {
        color: theme.palette.text.secondary,
        margin: 0,
    },
    disabledText: {
        color: 'rgba(0, 0, 0, 0.38)',
    },
    radioButtonsContainer: {
        marginLeft: theme.spacing.unit * 3,
    },
    menuItemText: {
        color: theme.palette.text.primary,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeight,
    },
    menuItemSubtext: {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeight,
    },
    selectMultiline: {
        height: 75,
    },
    convertDialogContent: {
        paddingBottom: 0,
        width: 540,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontSize: 14,
        fontWeight: 'bold',
        height: 48,
    },
    tableHeadRow: {
        height: 48,
    },
    tableIndex: {
        paddingTop: theme.spacing.unit,
        verticalAlign: 'top',
    },
    marginTop3: {
        marginTop: theme.spacing.unit * 3,
    },
    inputAdornment: {
        color: theme.palette.text.secondary,
        fontSize: 16,
        marginTop: 1,
    },
    sectionContainer: {
        borderBottom: '1px solid #eaeaea',
        marginBottom: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 1,
    },
    progressContainer: {
        marginBottom: theme.spacing.unit * 3,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        textAlign: 'center',
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    declinedStatusIcon: {
        color: red[400],
        fontSize: 100,
        margin: theme.spacing.unit * 2,
    },
    authorisedStatusIcon: {
        color: green[400],
        fontSize: 100,
        margin: theme.spacing.unit * 2,
    },
    ErrorStatusIcon: {
        color: amber[400],
        fontSize: 100,
        margin: theme.spacing.unit * 2,
    },
    cardRadio: {
        paddingTop: '0px',
    },
    issuerRadioLabel: {
        marginTop: theme.spacing.unit * 1,
    },
    emptyContent: {
        padding: 32,
    },
    textField: {
        width: 260,
    },
    bumpTop1U: {
        marginTop: theme.spacing.unit * 1,
    },
    bumpTop2U: {
        marginTop: theme.spacing.unit * 2,
    },
    bumpTop3U: {
        marginTop: theme.spacing.unit * 2,
    },
    bumpTop3: {
        marginTop: theme.spacing.unit * 3,
    },
    bumpLeft3U: {
        marginLeft: theme.spacing.unit * 3,
    },
    bulletIcon: {
        fontSize: 11,
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 1.5,
    },
    deliveryAlertList: {
        fontWeight: 400,
        marginTop: theme.spacing.unit * 1,
    },
    subTile: {
        marginBottom: theme.spacing.unit * 4,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    gaugeContainer: {
        width: 100,
        // marginLeft: 'auto',
        // marginRight: 'auto',
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        marginBottom: 4,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '8px 24px',
        margin: '0px 0px 0px 0px !important',
    },
    pdfDialogContent: {
        minWidth: 300,
        minHeight: 300,
    },
    signatureImage: {
        border: '1px solid darkgrey',
        boxShadow: '5px 5px 5px 1px #ccc',
        borderRadius: 5,
        maxWidth: 680,
    },
    dialogActionsRight: {
        textAlign: 'right',
    },
    dialogDownloadButton: {
        color: 'rgba(0, 0, 0, 0.87)',
        padding: '8px',
        fontSize: '0.875rem',
        minWidth: '64px',
        boxSizing: 'border-box',
        minHeight: '36px',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '500',
        lineHeight: '1.5',
        borderRadius: '4px',
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        border: '0',
        margin: '0',
        cursor: 'pointer',
        display: 'inline-flex',
        outline: 'none',
        position: 'relative',
        alignItems: 'center',
        userSelect: 'none',
        verticalAlign: 'middle',
        justifyContent: 'center',
        textDecoration: 'none',
        backgroundColor: 'transparent',
        webkitAppearance: 'none',
        webkitTapHighlightColor: 'transparent',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    },
    signatureDialogContent: {
        textAlign: 'center',
    },
    checkboxIndent: {
        marginLeft: theme.spacing.unit * 3,
    },
    buildSendContractDialog: {
        maxWidth: 400,
    },
    emailStatementDialog: {
        maxWidth: 400,
    },
    engineerScheduleDialogContent: {
        textAlign: 'center',
    },
    updatedDateTimes: {
        marginTop: theme.spacing.unit * 4,
    },
    pleaseWait: {
        marginTop: 10,
        fontWeight: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
        opacity: '.5',
        marginBottom: 72,
    },
    pleaseWaitSpinnerContainer: {
        marginTop: 24,
        marginBottom: 24,
    },
    resultIcon: {
        fontSize: 94,
    },
    error: {
        color: '#ff0000',
    },
    warning: {
        color: '#f9aa33',
    },
    success: {
        color: '#4caf50',
    },
    stateContainer: {
        marginBottom: 32,
        marginTop: 32,
        textAlign: 'center',
    },
    resultLabel: {
        marginTop: 10,
        fontWeight: 700,
    },
    resultprogress: {
        opacity: '.5',
        width: '94px !important',
        height: '94px !important',
    },
    dialogContentAlertText: {
        color: '#c80000',
        fontSize: 16,
        opacity: 0.54,
    },
    dialogContentTitle: {
        color: '#c80000',
        fontSize: 16,
        opacity: 0.54,
        fontWeight: 700,
    },
    blackTableIcons: {
        color: '#000000',
        fontSize: 22,
        opacity: 0.6,
    },
    customTableCell: {
        verticalAlign: 'middle',
        marginTop: 16,
    },
    editItemAgreementTableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    editAgreementItemTable: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
        border: '1px solid #e0e0e0',
    },
    editAgreementItemList: {
        marginLeft: theme.spacing.unit * 4,
        marginTop: theme.spacing.unit * 2,
        color: '#c80000',
    },
    dialogTitle: {
        marginBottom: theme.spacing.unit * 4,
    },
    cardIssuer: {
        color: '#a3a3a3',
    },
});

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        paddingRight: 8,
    },
    body: {
        fontSize: 12,
        verticalAlign: 'top',
        paddingTop: 16,
        paddingBottom: 8,
        paddingRight: 8,
    },
}))(TableCell);

const currentDate = moment.tz(moment(), 'Europe/London');
class scheduleDeliveryDialog extends React.Component {
    state = {
        controlDeviceForm: {
            command: 'ON',
        },
        currentEditAgreementItem: null,
        disableBuildSendContractSubmitButton: false,
        disableAgreementItemConfirmDeleteButton: false,
        disableAgreementItemConfirmUnassignButton: false,
        disableEditAgreementSubmitButton: false,
        // disableEditAgreementItemsSubmitButton: false,
        disableEditPaymentPlanSubmitButton: false,
        disableRetryPaymentSubmitButton: false,
        disableInterimPaymentSubmitButton: false,
        disableManualPaymentSubmitButton: false,
        disableManualAdjustmentSubmitButton: false,
        disableEmailStatementSubmitButton: false,
        disableControlDeviceSendButton: false,
        disableRefundPaymentsSubmitButton: false,
        editAgreementData: {},
        editAgreementItemsData: {},
        editPaymentPlanData: {},
        retryPaymentData: {},
        interimPaymentData: {},
        manualAdjustmentData: {},
        manualPaymentData: {},
        manualPaymentResult: false,
        processingManualPayment: false,
        refundPaymentsData: {},
        showEditAgreementDialog: false,
        showManualPaymentDialog: false,
        showManualAdjustmentDialog: false,
        showManualPaymentResultDialog: false,
        showSendTokenisationLinkDialog: false,
        showControlDeviceDialog: false,
        showSendDeliveryInviteDialog: false,
        // showScheduleDeliveryDialog: false,
        showEditAgreementItemsDialog: false,
        showDeleteAgreementItemDialog: false,
        showUnassignAgreementItemDialog: false,
        showAddAgreementItemDialog: false,
        showExchangeAgreementItemDialog: false,
        showEditPaymentPlanDialog: false,
        showRetryPaymentDialog: false,
        showInterimPaymentDialog: false,
        showBuildSendContractDialog: false,
        showEngineerSchedulerDialog: false,
        showEmailStatementDialog: false,
        showRefundPaymentsDialog: false,
        // showUpdatePaymentPlanStatusDialog: false,
        disableSubmitManualPaymentButton: false,
        disableScheduleDeliverySubmitButton: false,
        disableSendDeliveryInviteSendButton: false,
        disableManualPaymentNextButton: true,
        buildSendContractData: {
            sendToEmailAddress: '',
        },
        emailStatementData: {
            sendToEmailAddress: '',
        },
        scheduleDeliveryData: {
            saleItems: [],
            dateTime: '',
        },
        sendDeliveryInviteData: {
            saleItems: [],
            dateTime: '',
            installationAmount: '',
        },
        disableUpdatePaymentPlanSubmitButton: false,
        currentSaleTab: 0,
        showPdfDialog: false,
        showSignatureDialog: false,
        showOnlyBranchEngineers: true,
    };

    async componentDidMount() {
        const now = moment.tz(moment(), 'Europe/London');
        const dateTime = moment(now).format('YYYY-MM-DD');
        this.setState((state) => ({
            scheduleDeliveryData: {
                ...state.scheduleDeliveryData,
                saleItems: [],
                dateTime,
            },
            showScheduleDeliveryDialog: true,
        }));
    }

    // capitaliseFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

    // agreementStatusIcon = (alertStatus, warningStatus, cancelledStatus) => {
    //     switch (true) {
    //     case (alertStatus): {
    //         return (<WarningIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.alert}`} />);
    //     }
    //     case (warningStatus): {
    //         return (<WarningIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.warningIcon}`} />);
    //     }
    //     case (cancelledStatus): {
    //         return (<CrossCircleIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.cancelledIcon}`} />);
    //     }
    //     default: {
    //         return (<CheckCircleIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.activeIcon}`} />);
    //     }
    //     // case 'Warm': {
    //     //     return (this.props.classes.warmIcon);
    //     // }
    //     // case 'Cold': {
    //     //     return (this.props.classes.coldIcon);
    //     // }
    //     // case 'Finance': {
    //     //     return (this.props.classes.financeIcon);
    //     // }
    //     // default: {
    //     //     return (this.props.classes.notSetIcon);
    //     // }
    //     }
    // }

    // handleManualPaymentClick = () => {
    //     this.setState(state => ({
    //         showManualPaymentDialog: true,
    //     }));
    // }

    // handleSendTokenisationLinkClick = () => {
    //     this.setState(state => ({
    //         showSendTokenisationLinkDialog: true,
    //     }));
    // }

    // handleControlDeviceClick = () => {
    //     const currentControlDeviceState = (((this.props.customer.agreements[this.state.value] || {}).items || []).find(item => item.controlDeviceCode !== null) || {}).controlDeviceState;
    //     this.setState({
    //         controlDeviceForm: {
    //             command: currentControlDeviceState === 'OFF' ? 'ON' : 'OFF',
    //         },
    //         showControlDeviceDialog: true,
    //     });
    // }

    // handleScheduleDeliveryClick = () => {
    //     // const currentSale = this.props.contact.sales[this.state.currentSaleTab];
    // const now = moment.tz(moment(), 'Europe/London');
    // const dateTime = moment(now).format('YYYY-MM-DD');
    // this.setState((state) => ({
    //     scheduleDeliveryData: {
    //         ...state.scheduleDeliveryData,
    //         saleItems: [],
    //         dateTime,
    //     },
    //     showScheduleDeliveryDialog: true,
    // }));
    // };

    handleEditAgreementClick = () => {
        const currentSale = this.props.contact.agreements[this.state.currentSaleTab];
        this.setState({
            editAgreementData: {
                createdByContactCode: currentSale.createdByContactCode || '',
                minimumPreDeliveryPayments: currentSale.minimumPreDeliveryPayments,
                productTypeCode: currentSale.productTypeCode || '',
                status: currentSale.status,
            },
            showEditAgreementDialog: true,
        });
    };

    handleEditAgreementItemsClick = () => {
        const currentSale = this.props.contact.agreements[this.state.currentSaleTab];
        const currentSaleItems = currentSale.saleItems.reduce((acc, saleItem) => [...acc,
            {
                code: saleItem.code,
                modelNo: saleItem.product.modelNo,
                productCode: saleItem.product.code,
            }], []);
        this.setState({
            editAgreementItemsData: {
                saleItems: currentSaleItems,
            },
            showEditAgreementItemsDialog: true,
        });
    };

    handleEditPaymentPlanClick = () => {
        const currentSale = this.props.contact.agreements[this.state.currentSaleTab];
        this.setState({
            editPaymentPlanData: {
                cardExpiry: currentSale.cardExpiry,
                cardNo: currentSale.cardNo,
                cardToken: currentSale.plan.cardToken,
                nextPaymentDate: currentSale.plan.nextPaymentDate,
                reason: '',
                recurringPaymentAmount: currentSale.plan.recurringPaymentAmount,
                repaymentAmount: currentSale.plan.repaymentAmount,
                scheduleDay: currentSale.plan.scheduleDay,
                scheduleFrequency: currentSale.plan.scheduleFrequency,
                scheduleInterval: currentSale.plan.scheduleInterval,
                status: currentSale.plan.status,
            },
            showEditPaymentPlanDialog: true,
        });
    };

    handleRetryPaymentClick = () => {
        const currentSale = this.props.contact.agreements[this.state.currentSaleTab];
        this.setState({
            retryPaymentData: {
                retryPaymentDateTime: currentDate.format('YYYY-MM-DD HH:mm'),
                reason: '',
            },
            showRetryPaymentDialog: true,
        });
    };

    handleInterimPaymentClick = () => {
        const currentSale = this.props.contact.agreements[this.state.currentSaleTab];
        this.setState({
            interimPaymentData: {
                interimPaymentDate: currentDate.format('YYYY-MM-DD HH:mm'),
                reason: '',
            },
            showInterimPaymentDialog: true,
        });
    };

    handleManualPaymentClick = () => {
        const currentSale = this.props.contact.agreements[this.state.currentSaleTab];
        this.setState({
            manualPaymentData: {
                action: 'SMS',
                amount: currentSale.plan.recurringPaymentAmount,
                reason: '',
                requestType: this.props.paymentCards.length === 0 ? 'LINK' : 'EXISTING_CARD',
            },
            manualPaymentDialogState: 'FORM',
            showManualPaymentDialog: true,
        });
    };

    handleManualAdjustmentClick = () => {
        this.setState({
            manualAdjustmentData: {
                reason: '',
            },
            showManualAdjustmentDialog: true,
        });
    };

    handleRefundPaymentsClick = () => {
        this.setState({
            refundPaymentsData: {
                reason: '',
                requestType: 'ALL_PAYMENTS',
                refundPayments: [],
            },
            showRefundPaymentsDialog: true,
        });
    };

    handleBuildSendContractClick = () => {
        const currentSale = this.props.contact.agreements[this.state.currentSaleTab];
        this.setState((state) => ({
            buildSendContractData: {
                ...state.buildSendContractData,
            },
            showBuildSendContractDialog: true,
        }));
    };

    handleEmailStatementClick = () => {
        const currentSale = this.props.contact.agreements[this.state.currentSaleTab];
        this.setState((state) => ({
            emailStatementData: {
                ...state.emailStatementData,
                sendToEmailAddress: this.props.contact.emailAddress,
            },
            showEmailStatementDialog: true,
        }));
    };

    handleEngineerSchedulerClick = () => {
        this.setState({
            showEngineerSchedulerDialog: true,
        });
    };

    handleSendDeliveryInviteClick = () => {
        const currentSale = this.props.contact.agreements[this.state.currentSaleTab];
        const now = moment.tz(moment(), 'Europe/London');
        const dateTime = moment(now).format('YYYY-MM-DD');
        this.setState((state) => ({
            sendDeliveryInviteData: {
                ...state.sendDeliveryInviteData,
                saleItems: [],
                dateTime,
            },
            showSendDeliveryInviteDialog: true,
        }));
    };

    // handleUpdatePaymentPlanStatusClick = () => {
    //     this.setState({
    //         paymentPlanStatusData: {
    //             Status: this.props.customer.agreements[this.state.value].Status,
    //         },
    //         showUpdatePaymentPlanStatusDialog: true,
    //     });
    // }

    // handleCloseUpdatePaymentPlanStatusDialogClick = () => {
    //     this.setState({
    //         showUpdatePaymentPlanStatusDialog: false,
    //     });
    // }

    // handleCloseManualPaymentDialogClick = () => {
    //     this.setState(state => ({
    //         showManualPaymentDialog: false,
    //         manualPaymentStage: 'amount',
    //     }));
    // }

    // handleCloseSendTokenisationLinkDialogClick = () => {
    //     this.setState(state => ({
    //         showSendTokenisationLinkDialog: false,
    //         sendTokenisationLinkStage: 'method',
    //         sendTokenisationLinkForm: {
    //             messageType: 'sms',
    //             destination: 'current',
    //         },
    //     }));
    // }

    // handleCloseControlDeviceDialogClick = () => {
    //     this.setState(state => ({
    //         showControlDeviceDialog: false,
    //     }));
    // }

    handleCloseBuildSendContractDialogClick = () => {
        this.setState((state) => ({
            showBuildSendContractDialog: false,
            disableBuildSendContractSubmitButton: false,
        }));
    };

    handleCloseEngineerSchedulerDialogClick = () => {
        this.setState((state) => ({
            showEngineerSchedulerDialog: false,
        }));
    };

    handleCloseEmailStatementDialogClick = () => {
        this.setState((state) => ({
            showEmailStatementDialog: false,
            disableEmailStatementSubmitButton: false,
        }));
    };

    // handleCloseScheduleDeliveryDialogClick = () => {
    //     this.setState((state) => ({
    //         showScheduleDeliveryDialog: false,
    //         disableScheduleDeliverySubmitButton: false,
    //     }));
    // };

    handleCloseEditAgreementDialogClick = () => {
        this.setState((state) => ({
            showEditAgreementDialog: false,
            disableEditAgreementSubmitButton: false,
        }));
    };

    handleCloseEditAgreementItemsDialogClick = () => {
        this.setState((state) => ({
            showEditAgreementItemsDialog: false,
            // disableEditAgreementItemsSubmitButton: false,
        }));
    };

    handleCloseDeleteAgreementItemDialogClick = () => {
        this.setState((state) => ({
            showDeleteAgreementItemDialog: false,
            // disableEditAgreementItemsSubmitButton: false,
        }));
    };

    handleCloseUnassignAgreementItemDialogClick = () => {
        this.setState((state) => ({
            showUnassignAgreementItemDialog: false,
            // disableEditAgreementItemsSubmitButton: false,
        }));
    };

    handleCloseAddAgreementItemDialogClick = () => {
        this.setState((state) => ({
            showAddAgreementItemDialog: false,
            // disableEditAgreementItemsSubmitButton: false,
        }));
    };

    handleCloseExchangeAgreementItemDialogClick = () => {
        this.setState((state) => ({
            showExchangeAgreementItemDialog: false,
        }));
    };

    handleCloseEditPaymentPlanDialogClick = () => {
        this.setState((state) => ({
            showEditPaymentPlanDialog: false,
            // disableEditPaymentPlanSubmitButton: false,
        }));
    };

    handleCloseRetryPaymentDialogClick = () => {
        this.setState((state) => ({
            showRetryPaymentDialog: false,
            disableRetryPaymentSubmitButton: false,
        }));
    };

    handleCloseSendDeliveryInviteDialogClick = () => {
        this.setState((state) => ({
            showSendDeliveryInviteDialog: false,
            disableSendDeliveryInviteSendButton: false,
        }));
    };

    handleCloseInterimPaymentDialogClick = () => {
        this.setState((state) => ({
            showInterimPaymentDialog: false,
            disableInterimPaymentSubmitButton: false,
        }));
    };

    handleCloseManualPaymentDialogClick = () => {
        this.setState((state) => ({
            disableManualPaymentSubmitButton: false,
            showManualPaymentDialog: false,
        }));
    };

    handleCloseManualAdjustmentDialogClick = () => {
        this.setState({
            disableManualAdjustmentSubmitButton: false,
            showManualAdjustmentDialog: false,
        });
    };

    handleCloseRefundPaymentsDialogClick = () => {
        this.setState({
            disableRefundPaymentsSubmitButton: false,
            showRefundPaymentsDialog: false,
        });
    };

    handleCloseManualPaymentResultDialogClick = () => {
        this.setState({
            manualPaymentResult: null,
        });
    };

    // handleManualPaymentNextClick = async () => {
    //     let manualPaymentStageEnum;
    //     manualPaymentStageEnum = this.state.manualPaymentStages.indexOf(this.state.manualPaymentStage);
    //     manualPaymentStageEnum += 1;
    //     switch (this.state.manualPaymentStages[manualPaymentStageEnum]) {
    //     case 'cards':
    //         this.setState({ disableManualPaymentNextButton: true });
    //         this.setState({
    //             disableManualPaymentNextButton: false,
    //         });
    //         break;
    //     case 'payment':
    //         this.setState({
    //             transactionStatusWaiting: true,
    //             disableManualPaymentNextButton: true,
    //         });
    //         const amount = this.state.manualPaymentForm.amount;
    //         const agreementCode = this.props.customer.agreements[this.state.value].code;
    //         const paymentCard = this.state.manualPaymentForm.paymentCard;
    //         if (paymentCard === 'new') {
    //             manualPaymentStageEnum += 1; // Change manualPaymentStage to 'result'
    //             this.newCardManualPayment(agreementCode, amount);
    //         } else {
    //             const cardNo = this.props.paymentCards.find(card => card.token === paymentCard).cardNo;
    //             manualPaymentStageEnum += 1; // Change manualPaymentStage to 'result'
    //             await this.tokenManualPayment(agreementCode, amount, paymentCard, cardNo).then((paymentTransactionStatus) => {
    //                 this.setState({
    //                     transactionStatusWaiting: false,
    //                     manualPaymentTransctionStatus: paymentTransactionStatus,
    //                 });
    //             });
    //         }
    //         break;
    //     }
    //     this.setState({
    //         disableManualPaymentNextButton: false,
    //         manualPaymentStage: this.state.manualPaymentStages[manualPaymentStageEnum],
    //     });
    // }

    // handleSendTokenisationLinkNextClick = async () => {
    //     let sendTokenisationLinkStageEnum;
    //     sendTokenisationLinkStageEnum = this.state.sendTokenisationLinkStages.indexOf(this.state.sendTokenisationLinkStage);
    //     sendTokenisationLinkStageEnum += 1;
    //     this.setState(state => ({
    //         sendTokenisationLinkStage: state.sendTokenisationLinkStages[sendTokenisationLinkStageEnum],
    //     }));
    // }

    // updateManualPaymentData = (event) => {
    //     const { name, value } = event.target;
    //     let formattedValue;
    //     switch (name) {
    //     case 'amount':
    //         formattedValue = value.replace(/[^0-9$.]/g, '');
    //         this.setState({
    //             disableManualPaymentNextButton: !parseInt(formattedValue) > 0,
    //         });
    //         break;
    //     default:
    //         formattedValue = value;
    //         break;
    //     }
    //     event.target.value = formattedValue;

    //     this.setState({
    //         manualPaymentForm: {
    //             ...this.state.manualPaymentForm,
    //             [name]: formattedValue,
    //         },
    //     });
    // }

    // updatePaymentPlanStatusData = (event) => {
    //     const { name, value } = event.target;
    //     event.target.value = value;

    //     this.setState({
    //         paymentPlanStatusData: {
    //             [name]: value,
    //         },
    //     });
    // }

    // updateControlDeviceData = (event) => {
    //     // debugger;
    //     const { name, value } = event.target;
    //     let formattedValue;
    //     switch (name) {
    //     default:
    //         formattedValue = value;
    //         break;
    //     }
    //     event.target.value = formattedValue;

    //     this.setState(state => ({
    //         controlDeviceForm: {
    //             ...state.controlDeviceForm,
    //             [name]: formattedValue,
    //         },
    //     }));
    // }

    updateScheduleDeliveryData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            scheduleDeliveryData: {
                ...state.scheduleDeliveryData,
                [name]: formattedValue,
            },
        }));
    };

    updateAgreementData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            editAgreementData: {
                ...state.editAgreementData,
                [name]: formattedValue,
            },
        }));
    };

    updateAgreementItemsData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            editAgreementItemsData: {
                ...state.editAgreementItemsData,
                [name]: formattedValue,
            },
        }));
    };

    updatePaymentPlanData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        let additionalValues;
        const currentPaymentCard = this.props.paymentCards.find((paymentCard) => paymentCard.token === value);
        switch (name) {
        case 'scheduleInterval':
            formattedValue = value.replace(/[^0-9$]/g, '');
            break;
        case 'cardToken':
            formattedValue = value;
            additionalValues = {
                cardExpiry: currentPaymentCard.expiry,
                cardNo: currentPaymentCard.cardNo,
            };
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            editPaymentPlanData: {
                ...state.editPaymentPlanData,
                ...additionalValues,
                [name]: formattedValue,
            },
        }));
    };

    updateRetryPaymentData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'retryPaymentAmount':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            retryPaymentData: {
                ...state.retryPaymentData,
                [name]: formattedValue,
            },
        }));
    };

    updateInterimPaymentData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'interimPaymentAmount':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            interimPaymentData: {
                ...state.interimPaymentData,
                [name]: formattedValue,
            },
        }));
    };

    updateManualPaymentData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'amount':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            manualPaymentData: {
                ...state.manualPaymentData,
                [name]: formattedValue,
            },
        }));
    };

    updateManualAdjustmentData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'amount':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            manualAdjustmentData: {
                ...state.manualAdjustmentData,
                [name]: formattedValue,
            },
        }));
    };

    updateRefundPaymentsData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            refundPaymentsData: {
                ...state.refundPaymentsData,
                [name]: formattedValue,
            },
        }));
    };

    updateBuildSendContractData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            buildSendContractData: {
                ...state.buildSendContractData,
                [name]: formattedValue,
            },
        }));
    };

    updateEmailStatementData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            emailStatementData: {
                ...state.emailStatementData,
                [name]: formattedValue,
            },
        }));
    };

    updateSendDeliveryInviteData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'installationAmount': formattedValue = value.replace(/^(\d+\.?\d?\d?)?.*/, '$1');
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            sendDeliveryInviteData: {
                ...state.sendDeliveryInviteData,
                [name]: formattedValue,
            },
        }));
    };

    // updateSendTokenisationLinkData = (event) => {
    //     const { name, value } = event.target;
    //     let formattedValue;
    //     switch (true) {
    //     case (this.state.sendTokenisationLinkForm.messageType === 'sms' && name === 'to'):
    //         console.log('sms & to');
    //         formattedValue = value.replace(/[^0-9$.]/g, '');
    //         this.setState({
    //             disableSendTokenisationLinkSendButton: formattedValue.length === 0,
    //         });
    //         break;
    //     case (this.state.sendTokenisationLinkForm.messageType === 'email' && name === 'to'):
    //         console.log('email & to');
    //         formattedValue = value;
    //         this.setState({
    //             disableSendTokenisationLinkSendButton: formattedValue.length === 0,
    //         });
    //         break;
    //     case (value === 'current'):
    //         formattedValue = value;
    //         this.setState({
    //             disableSendTokenisationLinkSendButton: false,
    //         });
    //         break;
    //     default:
    //         formattedValue = value;
    //         break;
    //     }
    //     event.target.value = formattedValue;

    //     this.setState({
    //         sendTokenisationLinkForm: {
    //             ...this.state.sendTokenisationLinkForm,
    //             [name]: formattedValue,
    //         },
    //     });
    // }

    // handleSendTokenisationLink = (event) => {
    //     event.preventDefault();
    //     this.setState({
    //         disableSendTokenisationLinkSendButton: true,
    //     });
    //     const agreementCode = this.props.customer.agreements[this.state.value].code;
    //     this.props.sendTokenisationLink(agreementCode, this.state.sendTokenisationLinkForm).then(() => {
    //         this.setState(state => ({
    //             showSendTokenisationLinkDialog: false,
    //             disableSendTokenisationLinkSendButton: false,
    //             sendTokenisationLinkStage: 'method',
    //             sendTokenisationLinkForm: {
    //                 messageType: 'sms',
    //                 destination: 'current',
    //             },
    //         }));
    //         this.props.enqueueSnackbar('Tokenisation Link Sent', { variant: 'success' });
    //     }).catch(() => {
    //         console.log(this.props.errors);
    //         this.props.enqueueSnackbar('Send Tokenisation Link failed', { variant: 'error' });
    //     });
    // }

    // handleSendControlDeviceCommand = (event) => {
    //     event.preventDefault();
    //     this.setState({
    //         disableControlDeviceSendButton: true,
    //     });
    //     const controlDeviceCode = this.props.customer.agreements[this.state.value].items.find(item => item.controlDeviceCode != null).controlDeviceCode;
    //     const contactCode = this.props.customer.code;
    //     const command = this.state.controlDeviceForm.command;

    //     this.props.sendControlDeviceCommand(controlDeviceCode, contactCode, command).then(() => {
    //         this.setState(state => ({
    //             showControlDeviceDialog: false,
    //             disableControlDeviceSendButton: false,
    //         }));
    //         this.props.enqueueSnackbar('Conrol device command sent', { variant: 'success' });
    //     }).catch(() => {
    //         console.log(this.props.errors);
    //         this.props.enqueueSnackbar('Control device command send failed', { variant: 'error' });
    //     });
    // }

    // handlePrintDeliveryLabelsClick = () => {
    //     const agreementCode = this.props.customer.agreements[this.state.value].code;
    //     this.props.printDeliveryLabels(agreementCode).then(() => {
    //         this.props.enqueueSnackbar('Delivery Labels Printed', { variant: 'success' });
    //     }).catch(() => {
    //         console.log(this.props.errors);
    //         this.props.enqueueSnackbar('Delivery Labels Print failed', { variant: 'error' });
    //     });
    // }

    // handleCancelAgreementClick = () => {
    //     const agreementCode = this.props.customer.agreements[this.state.value].code;
    //     const params = {
    //         status: 'CANCELLED',
    //     };
    //     this.props.cancelAgreement(agreementCode, params).then(() => {
    //         this.props.enqueueSnackbar('Agreement Cancelled', { variant: 'success' });
    //     }).catch(() => {
    //         console.log(this.props.errors);
    //         this.props.enqueueSnackbar('Agreement Cancel failed', { variant: 'error' });
    //     });
    // }

    // handleSubmitUpdatePaymentPlanStatusClick = () => {
    //     this.setState({
    //         disableUpdatePaymentPlanSubmitButton: true,
    //         showUpdatePaymentPlanStatusDialog: false,
    //     });
    //     const agreementCode = this.props.customer.agreements[this.state.value].code;
    //     const params = {
    //         userReference: this.props.customer.code,
    //         planReference: agreementCode,
    //         status: this.state.paymentPlanStatusData.Status,
    //     };
    //     this.props.updatePaymentPlanStatus(agreementCode, params).then(() => {
    //         this.setState({
    //             disableUpdatePaymentPlanSubmitButton: false,
    //         });
    //         this.props.enqueueSnackbar('Payment Plan Updated', { variant: 'success' });
    //     }).catch(() => {
    //         console.log(this.props.errors);
    //         this.props.enqueueSnackbar('Payment Plan Update failed', { variant: 'error' });
    //     });
    // }

    handleRequestSignature = () => {
        const agreementCode = this.props.contact.agreements[this.state.currentSaleTab].code;
        this.props.requestSignature(agreementCode).then(() => {
            this.props.enqueueSnackbar('Agreement Signature Request Sent', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Agreement Signature Request failed', { variant: 'error' });
        });
    };

    handleScheduleDeliverySaveClick = () => {
        this.setState({
            disableScheduleDeliverySubmitButton: true,
        });
        const saleCode = this.props.contact.agreements[this.state.currentSaleTab].code;
        const params = {
            ...this.state.scheduleDeliveryData,
        };
        this.props.scheduleDelivery(saleCode, params).then(() => {
            this.setState({
                disableScheduleDeliverySubmitButton: false,
                showScheduleDeliveryDialog: false,
            });
            this.props.enqueueSnackbar('sale Delivery Updated', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableScheduleDeliverySubmitButton: false,
            });
            this.props.enqueueSnackbar('sale Delivery Update failed', { variant: 'error' });
        });
    };

    handleEditAgreementSaveClick = () => {
        this.setState({
            disableEditAgreementSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentSaleTab].code;
        const params = {
            ...this.state.editAgreementData,
        };
        this.props.editAgreement(agreementCode, params).then(() => {
            this.setState({
                disableEditAgreementSubmitButton: false,
                showEditAgreementDialog: false,
            });
            this.props.enqueueSnackbar('Agreement Edit Saved', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableEditAgreementSubmitButton: false,
            });
            this.props.enqueueSnackbar('Agreement Edit failed', { variant: 'error' });
        });
    };

    handleAgreementItemConfirmDeleteClick = (params) => {
        const currentEditAgreementItemCode = this.state.currentEditAgreementItem.code;
        this.setState({
            disableAgreementItemConfirmDeleteButton: true,
            currentEditAgreementItem: null,
            showDeleteAgreementItemDialog: false,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentSaleTab].code;
        this.props.deleteAgreementItem(agreementCode, this.state.currentEditAgreementItem.code, params).then(() => {
            this.setState({
                disableAgreementItemConfirmDeleteButton: false,
            });
            this.props.enqueueSnackbar('Agreement Item Deleted', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableAgreementItemConfirmDeleteButton: false,
            });
            this.props.enqueueSnackbar('Delete Agreement Item failed', { variant: 'error' });
        });
    };

    handleAgreementItemConfirmUnassignClick = (params) => {
        const currentEditAgreementItemCode = this.state.currentEditAgreementItem.code;
        this.setState({
            disableAgreementItemConfirmUnassignButton: true,
            currentEditAgreementItem: null,
            showUnassignAgreementItemDialog: false,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentSaleTab].code;
        this.props.unassignAgreementItem(agreementCode, this.state.currentEditAgreementItem.code, params).then(() => {
            this.setState({
                disableAgreementItemConfirmUnassignButton: false,
            });
            this.props.enqueueSnackbar('Agreement Item Unassigned', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableAgreementItemConfirmUnassignButton: false,
            });
            this.props.enqueueSnackbar('Unassign Agreement Item failed', { variant: 'error' });
        });
    };

    handleAgreementItemConfirmAddClick = (params) => {
        const currentEditAgreementItemCode = this.state.currentEditAgreementItem.code;
        this.setState({
            disableAgreementItemConfirmAddButton: true,
            currentEditAgreementItem: null,
            showAddAgreementItemDialog: false,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentSaleTab].code;
        this.props.AddAgreementItem(agreementCode, this.state.currentEditAgreementItem.code, params).then(() => {
            this.setState({
                disableAgreementItemConfirmAddButton: false,
            });
            this.props.enqueueSnackbar('Agreement Item Add', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableAgreementItemConfirmAddButton: false,
            });
            this.props.enqueueSnackbar('Add Agreement Item failed', { variant: 'error' });
        });
    };

    handleAgreementItemConfirmExchangeClick = (params) => {
        const currentEditAgreementItemCode = this.state.currentEditAgreementItem.code;
        this.setState({
            disableAgreementItemConfirmExchangeButton: true,
            currentEditAgreementItem: null,
            showExchangeAgreementItemDialog: false,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentSaleTab].code;
        this.props.ExchangeAgreementItem(agreementCode, this.state.currentEditAgreementItem.code, params).then(() => {
            this.setState({
                disableAgreementItemConfirmExchangeButton: false,
            });
            this.props.enqueueSnackbar('Agreement Item Exchange', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableAgreementItemConfirmExchangeButton: false,
            });
            this.props.enqueueSnackbar('Exchange Agreement Item failed', { variant: 'error' });
        });
    };

    handleEditPaymentPlanSaveClick = () => {
        this.setState({
            disableEditPaymentPlanSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentSaleTab].code;
        const params = {
            ...this.state.editPaymentPlanData,
            nextPaymentDate: this.state.editPaymentPlanData.nextPaymentDate,
            recurringPaymentAmount: parseFloat(this.state.editPaymentPlanData.recurringPaymentAmount, 10),
            repaymentAmount: parseFloat(this.state.editPaymentPlanData.repaymentAmount, 10),
            scheduleDay: (this.state.editPaymentPlanData.scheduleFrequency === 'MONTHLY'
            || this.state.editPaymentPlanData.scheduleFrequency === 'QUARTERLY') ? moment(this.state.editPaymentPlanData.nextPaymentDate).date() : undefined,
            scheduleFrequency: this.state.editPaymentPlanData.scheduleFrequency,
            scheduleInterval: this.state.editPaymentPlanData.scheduleInterval,
            status: this.state.editPaymentPlanData.status,
        };
        this.props.editPaymentPlan(agreementCode, params).then(() => {
            this.setState({
                disableEditPaymentPlanSubmitButton: false,
                showEditPaymentPlanDialog: false,
            });
            this.props.enqueueSnackbar('Payment Plan Edit Saved', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableEditPaymentPlanSubmitButton: false,
            });
            this.props.enqueueSnackbar('Payment Plan Edit failed', { variant: 'error' });
        });
    };

    handleRetryPaymentSaveClick = () => {
        this.setState({
            disableRetryPaymentSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentSaleTab].code;
        const params = {
            ...this.state.retryPaymentData,
            retryPaymentAmount: parseFloat(this.state.retryPaymentData.retryPaymentAmount, 10),
        };
        this.props.scheduleRetryPayment(agreementCode, params).then(() => {
            this.setState({
                disableRetryPaymentSubmitButton: false,
                showRetryPaymentDialog: false,
            });
            this.props.enqueueSnackbar('Retry Payment Scheduled', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableRetryPaymentSubmitButton: false,
            });
            this.props.enqueueSnackbar('Scehdule Retry Payment failed', { variant: 'error' });
        });
    };

    handleInterimPaymentSaveClick = () => {
        this.setState({
            disableInterimPaymentSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentSaleTab].code;
        const params = {
            ...this.state.interimPaymentData,
            interimPaymentAmount: parseFloat(this.state.interimPaymentData.interimPaymentAmount, 10),
        };
        this.props.scheduleInterimPayment(agreementCode, params).then(() => {
            this.setState({
                disableInterimPaymentSubmitButton: false,
                showInterimPaymentDialog: false,
            });
            this.props.enqueueSnackbar('Interim Payment Scheduled', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableInterimPaymentSubmitButton: false,
            });
            this.props.enqueueSnackbar('Scehdule Interim Payment failed', { variant: 'error' });
        });
    };

    handleManualPaymentSubmitClick = () => {
        this.setState({
            disableManualPaymentSubmitButton: true,
            manualPaymentResult: 'PROCESSING',
        });
        const agreementCode = this.props.contact.agreements[this.state.currentSaleTab].code;
        const params = {
            ...this.state.manualPaymentData,
            amount: parseFloat(this.state.manualPaymentData.amount, 10),
        };
        this.props.manualPayment(agreementCode, params).then((result) => {
            this.setState({
                disableManualPaymentSubmitButton: false,
                manualPaymentResult: result,
                showManualPaymentDialog: false,
            });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableManualPaymentSubmitButton: false,
            });
            this.props.enqueueSnackbar('Manual Payment request failed', { variant: 'error' });
        });
    };

    handleManualAdjustmentSubmitClick = () => {
        this.setState({
            disableManualAdjustmentSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentSaleTab].code;
        const params = {
            ...this.state.manualAdjustmentData,
            amount: parseFloat(this.state.manualAdjustmentData.amount),
        };
        this.props.manualAdjustment(agreementCode, params).then(() => {
            this.setState({
                disableManualAdjustmentSubmitButton: false,
                showManualAdjustmentDialog: false,
            });
            this.props.enqueueSnackbar('Manual Adjustment Successful', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableManualAdjustmentSubmitButton: false,
            });
            this.props.enqueueSnackbar('Manual Adjustment Failed', { variant: 'error' });
        });
    };

    handleRefundPaymentPlanSubmitClick = () => {
        this.setState({
            disableRefundPaymentsSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentSaleTab].code;
        const params = {
            reason: this.state.refundPaymentsData.reason,
        };
        this.props.refundPaymentPlan(agreementCode, params).then(() => {
            this.setState({
                disableRefundPaymentsSubmitButton: false,
                showRefundPaymentsDialog: false,
            });
            this.props.enqueueSnackbar('Payment Plan Refund request successful', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableRefundPaymentsSubmitButton: false,
            });
            this.props.enqueueSnackbar('Payment Plan Refund request failed', { variant: 'error' });
        });
    };

    handleRefundPaymentsSubmitClick = () => {
        this.setState({
            disableRefundPaymentsSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentSaleTab].code;
        const params = {
            ...this.state.refundPaymentsData,
        };
        this.props.refundPayments(agreementCode, params).then(() => {
            this.setState({
                disableRefundPaymentsSubmitButton: false,
                showRefundPaymentsDialog: false,
            });
            this.props.enqueueSnackbar('Refund Payments request successful', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableManualPaymentSubmitButton: false,
            });
            this.props.enqueueSnackbar('Refund Payments request failed', { variant: 'error' });
        });
    };

    handlePaymentLinkSubmitClick = () => {
        this.setState({
            disableManualPaymentSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentSaleTab].code;
        const params = {
            ...this.state.manualPaymentData,
            amount: parseFloat(this.state.manualPaymentData.amount, 10),
        };
        this.props.paymentLink(agreementCode, params).then(() => {
            this.setState({
                disableManualPaymentSubmitButton: false,
                showManualPaymentDialog: false,
            });
            this.props.enqueueSnackbar('Manual Payment request successful', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableManualPaymentSubmitButton: false,
            });
            this.props.enqueueSnackbar('Manual Payment request failed', { variant: 'error' });
        });
    };

    handleBuildSendContractSendClick = () => {
        this.setState({
            disableBuildSendContractSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentSaleTab].code;
        const params = {
            ...this.state.buildSendContractData,
        };
        this.props.buildSendContract(agreementCode, params).then(() => {
            this.setState({
                disableBuildSendContractSubmitButton: false,
                showBuildSendContractDialog: false,
            });
            this.props.enqueueSnackbar('Contract built and sent', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableBuildSendContractSubmitButton: false,
            });
            this.props.enqueueSnackbar('Build and send contract failed', { variant: 'error' });
        });
    };

    handleEmailStatementSendClick = () => {
        this.setState({
            disableEmailStatementSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentSaleTab].code;
        const params = {
            ...this.state.emailStatementData,
        };
        this.props.emailStatement(agreementCode, params).then(() => {
            this.setState({
                disableEmailStatementSubmitButton: false,
                showEmailStatementDialog: false,
            });
            this.props.enqueueSnackbar('Agreement Statement Sent', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableEmailStatementSubmitButton: false,
            });
            this.props.enqueueSnackbar('Send Agreement Statement Failed', { variant: 'error' });
        });
    };

    handleSendDeliveryInviteSendClick = () => {
        this.setState({
            disableSendDeliveryInviteSendButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentSaleTab].code;
        const formattedInstallationAmount = this.state.sendDeliveryInviteData.installationAmount ? parseInt(this.state.sendDeliveryInviteData.installationAmount, 10) : null;
        const params = {
            ...this.state.sendDeliveryInviteData,
            installationAmount: formattedInstallationAmount,
        };
        this.props.sendDeliveryInvite(agreementCode, params).then(() => {
            this.setState({
                disableSendDeliveryInviteSendButton: false,
                showSendDeliveryInviteDialog: false,
            });
            this.props.enqueueSnackbar('Agreement Delivery Invite Sent', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableSendDeliveryInviteSendButton: false,
            });
            this.props.enqueueSnackbar('Send Agreement Delivery Invite Failed', { variant: 'error' });
        });
    };

    handleEmailContractClick = () => {
        const agreementCode = this.props.contact.agreements[this.state.currentSaleTab].code;
        this.props.emailContract(agreementCode).then(() => {
            this.props.enqueueSnackbar('Contract and T&C\'s email sent', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Send Contract and T&C\'s email failed', { variant: 'error' });
        });
    };

    // newCardManualPayment = (agreementCode, amount) => {
    //     this.props.manualPayment({
    //         agreementCode,
    //         amount,
    //         paymentExpiry: 90,
    //         paymentReturnURL: `https://${window.location.hostname}/customer/${this.props.customer.code}?paymentType=manual&agreementCode=${agreementCode}`,
    //     });
    // }

    // tokenManualPayment = (agreementCode, amount, token, cardNo) => this.props.tokenManualPayment({
    //     agreementCode,
    //     paymentAmount: amount,
    //     paymentCardToken: token,
    //     paymentCardNo: cardNo,
    // })

    // handleTryAgainClick = () => {
    //     this.setState({
    //         manualPaymentStage: 'amount',
    //     });
    // }

    handleSaleChange = (event, newValue) => {
        this.setState({ currentSaleTab: newValue });
    };

    // handleDebuggerClick = () => {
    //     debugger;
    // }

    updateScheduleDeliverySaleItemCheckbox = (name) => (event) => {
        const newState = event.target.checked ? 1 : 0;
        const newSaleItems = this.state.scheduleDeliveryData.saleItems.reduce((acc, saleItem) => {
            if (saleItem.code !== name) return [...acc, saleItem];
            return [...acc];
        }, []);

        if (newState === 1) {
            newSaleItems.push({
                action: 'DELIVER',
                saleCode: this.props.contact.sales[this.state.currentSaleTab].code,
                code: name,
            });
        }

        this.setState((state) => ({
            scheduleDeliveryData: {
                ...state.scheduleDeliveryData,
                saleItems: newSaleItems,
            },
        }));
    };

    updateRefundPaymentCheckbox = (name, amount, source, paymentDateTime) => (event) => {
        const newState = event.target.checked ? 1 : 0;
        const newRefundPayments = this.state.refundPaymentsData.refundPayments.reduce((acc, refundPayment) => {
            if (refundPayment.paymentID !== name) return [...acc, refundPayment];
            return [...acc];
        }, []);

        if (newState === 1) {
            newRefundPayments.push({
                amount,
                paymentID: name,
                source,
                paymentDateTime,
            });
        }

        this.setState((state) => ({
            refundPaymentsData: {
                ...state.refundPaymentsData,
                refundPayments: newRefundPayments,
            },
        }));
    };

    updateSendDeliveryInviteAgreementItemCheckbox = (name) => (event) => {
        const newState = event.target.checked ? 1 : 0;
        const newAgreementItems = this.state.sendDeliveryInviteData.saleItems.reduce((acc, saleItem) => {
            if (saleItem.code !== name) return [...acc, saleItem];
            return [...acc];
        }, []);

        if (newState === 1) {
            newAgreementItems.push({
                action: 'DELIVER',
                agreementCode: this.props.contact.agreements[this.state.currentSaleTab].code,
                code: name,
            });
        }

        this.setState((state) => ({
            sendDeliveryInviteData: {
                ...state.sendDeliveryInviteData,
                saleItems: newAgreementItems,
            },
        }));
    };

    handleScheduleDeliveryDateChange = (chosenDate) => {
        this.setState((state) => ({
            scheduleDeliveryData: {
                ...state.scheduleDeliveryData,
                dateTime: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    handleRetryPaymentDateTimeChange = (chosenDate) => {
        this.setState((state) => ({
            retryPaymentData: {
                ...state.retryPaymentData,
                retryPaymentDateTime: moment(chosenDate).format('YYYY-MM-DD HH:mm'),
            },
        }));
    };

    handleInterimPaymentDateChange = (chosenDate) => {
        this.setState((state) => ({
            interimPaymentData: {
                ...state.interimPaymentData,
                interimPaymentDate: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    handleEditPaymentPlanNextPaymentDateChange = (chosenDate) => {
        this.setState((state) => ({
            editPaymentPlanData: {
                ...state.editPaymentPlanData,
                nextPaymentDate: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    handleSendDeliveryInviteDateChange = (chosenDate) => {
        this.setState((state) => ({
            sendDeliveryInviteData: {
                ...state.sendDeliveryInviteData,
                dateTime: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    updateShowOnlyBranchEngineersCheckbox = (name) => (event) => {
        const newState = !!event.target.checked;
        this.setState((state) => ({
            scheduleDeliveryData: {
                ...state.scheduleDeliveryData,
                engineerContactCode: undefined,
            },
            showOnlyBranchEngineers: newState,
        }));
    };

    // handleRecordDeliveryDateTimeChange = (chosenDate) => {
    //     this.setState(state => ({
    //         scheduleDeliveryData: {
    //             ...state.scheduleDeliveryData,
    //             dateTime: moment(chosenDate).format('YYYY-MM-DD HH:mm'),
    //         },
    //     }));
    // }

    handlePdfDialogOpen = (documentType) => {
        this.setState({
            documentType,
            showPdfDialog: true,
        });
    };

    handlePdfDialogClose = () => {
        this.setState({
            showPdfDialog: false,
        });
    };

    handleSignatureDialogOpen = (documentType) => {
        this.setState({
            documentType,
            showSignatureDialog: true,
        });
    };

    handleSignatureDialogClose = () => {
        this.setState({
            showSignatureDialog: false,
        });
    };

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({
            numPages,
        });
    };

    visitFromSchedule = (engineerContactCode, visitDateTime) => {
        this.setState((state) => ({
            scheduleDeliveryData: {
                ...state.scheduleDeliveryData,
                engineerContactCode,
                dateTime: moment(visitDateTime).format('YYYY-MM-DD'),
            },
            sendDeliveryInviteData: {
                ...state.sendDeliveryInviteData,
                engineerContactCode,
                dateTime: moment(visitDateTime).format('YYYY-MM-DD'),
            },
            showEngineerSchedulerDialog: false,
        }));
    };

    engineerScheduleEngingeerCode = () => {
        switch (true) {
        case (this.state.showScheduleDeliveryDialog): {
            return this.state.scheduleDeliveryData.engineerContactCode;
        }
        case (this.state.showSendDeliveryInviteDialog): {
            return this.state.sendDeliveryInviteData.engineerContactCode;
        }
        default: return null;
        }
    };

    engineerScheduleDateTime = () => {
        switch (true) {
        case (this.state.showScheduleDeliveryDialog): {
            return (moment(this.state.scheduleDeliveryData.dateTime).format('YYYY-MM-DD'));
        }
        case (this.state.showSendDeliveryInviteDialog): {
            return (moment(this.state.sendDeliveryInviteData.dateTime).format('YYYY-MM-DD'));
        }
        default: return null;
        }
    };

    // handleDeleteAgreementItemClick = async (saleItemCode) => {
    //     const currentSale = this.props.contact.agreements[this.state.currentSaleTab];
    //     const currentEditAgreementItem = currentSale.saleItems.find((saleItem) => saleItem.code === saleItemCode);

    //     this.setState({
    //         currentEditAgreementItem,
    //         showDeleteAgreementItemDialog: true,
    //     });
    // };

    handleDeleteAgreementItemClick = async (currentEditAgreementItem) => {
        this.setState({
            currentEditAgreementItem,
            showDeleteAgreementItemDialog: true,
        });
    };

    handleUnassignAgreementItemClick = async (currentEditAgreementItem) => {
        this.setState({
            currentEditAgreementItem,
            showUnassignAgreementItemDialog: true,
        });
    };

    handleAddAgreementItemClick = async () => {
        this.setState({
            showAddAgreementItemDialog: true,
        });
    };

    handleExchangeAgreementItemClick = async (currentEditAgreementItem) => {
        this.setState({
            currentEditAgreementItem,
            showExchangeAgreementItemDialog: true,
        });
    };

    render() {
        const {
            agreementSaleItems,
            classes,
            showScheduleDeliveryDialog,
            // addNewHistoryNote,
            // agreementStatuses,
            // agreementsAlerts,
            // assignStockItem,
            // branches,
            contact,
            contactSalesLoading,
            // contactHistoriesLoading,
            // contactLoading,
            contactVisitsLoading,
            // emailContract,
            engineers,
            errors,
            // exchangeAgreementItem,
            // fetchAgreementsData,
            // fetchSalesData,
            // fetchHistoriesData,
            // fetchLeadsSalesData,
            // paymentCards,
            // paymentCardsLoading,
            // productTypes,
            // scheduleDelivery,
            // customer,
            // paymentCards,
            // requestedSaleTab,
            // transactionStatusWaiting,
            // user,
            // userLoading,
            // users,
            // usersLoading,
        } = this.props;
        const {
            // buildSendContractData,
            currentSaleTab,
            // currentEditAgreementItem,
            // disableAgreementItemConfirmDeleteButton,
            // disableAgreementItemConfirmUnassignButton,
            // disableBuildSendContractSubmitButton,
            // disableEditAgreementSubmitButton,
            // // disableEditAgreementItemsSubmitButton,
            // disableEditPaymentPlanSubmitButton,
            // disableRetryPaymentSubmitButton,
            // disableInterimPaymentSubmitButton,
            // disableManualPaymentSubmitButton,
            // disableManualAdjustmentSubmitButton,
            // disableEmailStatementSubmitButton,
            disableScheduleDeliverySubmitButton,
            // disableSendDeliveryInviteSendButton,
            // disableRefundPaymentsSubmitButton,
            // documentType,
            // editAgreementData,
            // editAgreementItemsData,
            // editPaymentPlanData,
            // retryPaymentData,
            // interimPaymentData,
            // emailStatementData,
            // manualPaymentData,
            // manualAdjustmentData,
            // manualPaymentResult,
            // processingManualPayment,
            // refundPaymentsData,
            // numPages,
            scheduleDeliveryData,
            // sendDeliveryInviteData,
            // controlDeviceForm,
            // disableControlDeviceSendButton,
            // disableManualPaymentNextButton,
            // showBuildSendContractDialog,
            // showEditAgreementDialog,
            // showEditAgreementItemsDialog,
            // showDeleteAgreementItemDialog,
            // showUnassignAgreementItemDialog,
            // showAddAgreementItemDialog,
            // showExchangeAgreementItemDialog,
            // showEditPaymentPlanDialog,
            // showRetryPaymentDialog,
            // showInterimPaymentDialog,
            // showManualPaymentDialog,
            // showManualAdjustmentDialog,
            // showManualPaymentResultDialog,
            // showEmailStatementDialog,
            // showEngineerSchedulerDialog,
            showOnlyBranchEngineers,
            // disableSendTokenisationLinkSendButton,
            // disableUpdatePaymentPlanSubmitButton,
            // manualPaymentForm,
            // manualPaymentStage,
            // manualPaymentTransctionStatus,
            // paymentPlanStatusData,
            // showPdfDialog,
            // showRefundPaymentsDialog,
            // showScheduleDeliveryDialog,
            // showSendDeliveryInviteDialog,
            // sendTokenisationLinkForm,
            // sendTokenisationLinkStage,
            // showControlDeviceDialog,
            // showManualPaymentDialog,
            // showSignatureDialog,
            // showSendTokenisationLinkDialog,
            // showUpdatePaymentPlanStatusDialog,
            // value,
        } = this.state;

        let currentSale = {};
        let currentSaleLead = null;
        // const actualUnrefundedPayments = null;
        // let refundableActualPayments;
        // const totalActualUnrefundedPaymentsAmount = null;
        // const totalRefundableActualPaymentsAmount = null;

        if (get(contact, `sales[${currentSaleTab}]`)) {
            currentSale = contact.sales[currentSaleTab];
            currentSaleLead = contact.leads.find((lead) => lead.code === currentSale.leadCode);

            // actualUnrefundedPayments = currentSale.payments.filter((payment) => (
            //     payment.transactionStatus === 'AUTHORISED'
            //     && (!['MANUALADJUSTMENT', 'REVERSAL', 'REFUND'].includes(payment.source))
            //     && payment.amount > payment.refundedAmount
            //     && !payment.reversalPaymentID
            // ));

            // refundableActualPayments = actualUnrefundedPayments.filter((payment) => (
            //     payment.amount > payment.refundedAmount
            //     && moment(currentDate).diff(payment.paymentDateTime, 'years') < 2
            // ));

        // totalActualUnrefundedPaymentsAmount = actualUnrefundedPayments.reduce((acc, payment) => acc + (payment.amount - payment.refundedAmount), 0);
        // totalRefundableActualPaymentsAmount = refundableActualPayments.reduce((acc, payment) => acc + (payment.amount - payment.refundedAmount), 0);
        }

        let saleItemsVisitStatuses = [];
        console.log('>>>', !contactVisitsLoading, !contactSalesLoading, !isEmpty(currentSale));
        if (!contactVisitsLoading && !contactSalesLoading && !isEmpty(currentSale)) {
            saleItemsVisitStatuses = currentSale.saleItems.flatMap((saleItem) => {
                const saleItemCode = saleItem.code;
                const value = contact.visits.flatMap((visit) => {
                    if (visit.status === 'CANCELLED') return [];
                    return visit.visitItems.flatMap((visitItem) => {
                        if (visitItem.rentalAgreementItemCode !== saleItemCode) return [];
                        return {
                            code: saleItemCode,
                            status: visit.status,
                            visitCode: visit.code,
                            visitItemCode: visitItem.code,
                        };
                    });
                });
                if (value.length === 0) return [{ code: saleItemCode, status: null }];
                return value;
            });
        }

        // let currentContactHistories = null;
        // if (!contactLoading && !contactHistoriesLoading) {
        //     currentContactHistories = contact.histories.filter((history) => history.entryType === 'SALE' && history.entryTypeReferenceCode === `S${currentSale.code}`);
        // }

        // let currentEditAgreementItemVisit = null;
        // if (currentEditAgreementItem && !contactLoading && !contactVisitsLoading && !contactSalesLoading && !isEmpty(currentSale)) {
        //     const visitStatus = saleItemsVisitStatuses.find((saleItemsVisitStatus) => saleItemsVisitStatus.code === currentEditAgreementItem.code);
        //     currentEditAgreementItemVisit = visitStatus ? {
        //         saleItemCode: visitStatus.code,
        //         code: visitStatus.visitCode,
        //         status: visitStatus.status,
        //         visitItemCode: visitStatus.visitItemCode,
        //         visitItemQuantity: ((contact.visits.find((visit) => visit.code === visitStatus.visitCode) || {}).visitItems || {}).length,
        //     } : null;
        // }

        return (
            <>
                <Dialog
                    open={showScheduleDeliveryDialog}
                    onClose={this.handleCloseScheduleDeliveryDialogClick}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.dialogContentContainer}>
                            <>
                                <Typography variant="h6" gutterBottom>
                                    Schedule Delivery
                                </Typography>
                                <DialogContentText>
                                    Sale Items
                                </DialogContentText>
                                <Grid className={classes.checkboxIndent} container spacing={0}>
                                    {agreementSaleItems.map((saleItem) => (
                                        <Grid
                                            key={saleItem.code}
                                            item
                                            xs={12}
                                        >
                                            <FormControlLabel
                                                control={(
                                                    <Checkbox
                                                        name={saleItem.code}
                                                        id={saleItem.code}
                                                        checked={scheduleDeliveryData.saleItems.find((itemCode) => (itemCode === saleItem.code))}
                                                        onChange={this.updateScheduleDeliverySaleItemCheckbox(saleItem.code)}
                                                    />
                                                )}
                                                label={`${saleItem.product.modelNo} (${saleItem.reference})`}
                                                disabled={saleItemsVisitStatuses.find((saleItemsVisitStatus) => saleItemsVisitStatus.code === saleItem.code && saleItemsVisitStatus.status != null)}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>

                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <DatePicker
                                            name="dateTime"
                                            id="dateTime"
                                            margin="normal"
                                            label="Delivery Date"
                                            value={scheduleDeliveryData.dateTime}
                                            onChange={this.handleScheduleDeliveryDateChange}
                                            fullWidth
                                            variant="outlined"
                                            format="DD-MM-YYYY"
                                            showTodayButton
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={(
                                                <Checkbox
                                                    name={showOnlyBranchEngineers}
                                                    id={showOnlyBranchEngineers}
                                                    checked={showOnlyBranchEngineers}
                                                    onChange={this.updateShowOnlyBranchEngineersCheckbox()}
                                                />
                                            )}
                                            label="Show Only Branch Operatives"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl
                                            className={classes.menuFormControl}
                                            variant="outlined"
                                            fullWidth
                                            required
                                            // error={!!errors.engineerContactCode}
                                        >
                                            <InputLabel htmlFor="engineerContactCode" className={classes.inputLabel}>Scheduled Operative</InputLabel>
                                            <Select
                                                keyboard="true"
                                                input={(
                                                    <OutlinedInput
                                                        onChange={this.updateScheduleDeliveryData}
                                                        name="engineerContactCode"
                                                        id="engineerContactCode"
                                                    />
                                                )}
                                                value={scheduleDeliveryData.engineerContactCode || ''}
                                            >
                                                {engineers.filter((engineer) => engineer.engineer.branchCode === (showOnlyBranchEngineers ? contact.branchCode : engineer.engineer.branchCode)).map((engineer) => (
                                                    [
                                                        <MenuItem
                                                            key={engineer.code}
                                                            value={engineer.code}
                                                        >
                                                            {`${engineer.firstname} ${engineer.surname}`}
                                                        </MenuItem>,
                                                    ]
                                                ))}
                                            </Select>
                                            {errors.engineerContactCode && (
                                                <FormHelperText>{errors.engineerContactCode}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={12}>
                                            <Button
                                                className={classes.dialogButton}
                                                onClick={this.handleEngineerSchedulerClick}
                                            >
                                                SCHEDULER
                                            </Button>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl
                                        className={classes.menuFormControl}
                                        variant="outlined"
                                        fullWidth
                                        required
                                    >
                                        <InputLabel htmlFor="points" className={classes.inputLabel}>Visit Points</InputLabel>
                                        <Select
                                            keyboard="true"
                                            input={(
                                                <OutlinedInput
                                                    onChange={this.updateScheduleDeliveryData}
                                                    name="points"
                                                    id="points"
                                                />
                                            )}
                                            value={scheduleDeliveryData.points || ''}
                                        >
                                            {visitPointsArray.map((visitPoint) => (
                                                [
                                                    <MenuItem
                                                        key={visitPoint}
                                                        value={visitPoint}
                                                    >
                                                        {visitPoint}
                                                    </MenuItem>,
                                                ]
                                            ))}
                                        </Select>
                                        {errors.points && (
                                            <FormHelperText>{errors.points}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justify="center"
                                >
                                    <Grid item xs={12}>
                                        <Button
                                            className={classes.dialogButton}
                                            disabled={disableScheduleDeliverySubmitButton || scheduleDeliveryData.saleItems.length === 0 || !scheduleDeliveryData.engineerContactCode || !scheduleDeliveryData.points}
                                            onClick={this.handleScheduleDeliverySaveClick}
                                        >
                                            SAVE
                                        </Button>
                                    </Grid>
                                </Grid>

                            </>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            onClick={this.handleCloseScheduleDeliveryDialogClick}
                            color="primary"
                            autoFocus
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default withSnackbar(withStyles(styles)(scheduleDeliveryDialog));
