import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import classNames from 'classnames';
import fetch, { fetchJson2 } from '../../utilities/fetch';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    bumpBottom3: {
        marginBottom: theme.spacing.unit * 3,
    },
    dialogContentContainer: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    dialogContent: {
        minWidth: 600,
        paddingBottom: 16,
    },
    dialogButton: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 4,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        margin: '0px 0px 0px 0px !important',
        padding: '8px 24px',
    },
    dialogText: {
        marginTop: theme.spacing.unit * 2,
    },
    disabled: {
        opacity: 0.26,
    },
    dialogTitle: {
        marginBottom: theme.spacing.unit * 2,
    },
});

class EngineerExpireDialog extends React.Component {
    state = {
        disableEngineerSaveButton: false,
        expireEngineerData: {
            expireUser: true,
        },
    };

    handleExpireEngineer = () => {
        this.setState({
            disableEngineerSaveButton: true,
        });
        this.expireEngineer(this.props.engineer.engineer.code).then(() => {
            this.setState({
                disableEngineerSaveButton: false,
            });
            this.props.handleCloseExpireEngineerDialogClick();
            this.props.enqueueSnackbar('Expire Engineer Success', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Expire Engineer Failed', { variant: 'error' });
            this.setState({
                disableEngineerSaveButton: false,
            });
        });
    };

    expireEngineer = async (engineerCode) => {
        let response;
        let body;

        if (this.state.expireEngineerData.expireUser) {
            try {
                response = await fetch(`${REACT_APP_API_SERVER}v2/user/${this.props.engineer.contactUserCode}/expire`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'PATCH',
                });
            } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
                console.log(e);
            }

            if (response.headers.get('content-type')
            && response.headers.get('content-type').search('application/json') >= 0) {
                body = await response.json();
            }
            if (response.status !== 200) throw new Error({ error: 'Generic API error' });
        }

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/engineer/${engineerCode}`, {
                body: JSON.stringify({ expired: 1 }),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
              && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }
        switch (response.status) {
        case 200: {
            const newEngineer = {
                ...body.engineer,
                histories: [
                    body.history,
                    ...this.props.engineer.histories,
                ],
            };
            this.props.passNewEngineer(newEngineer);
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    updateExpireEngineerDataCheckbox = (name) => (event) => {
        const newState = !!event.target.checked;
        this.setState((state) => ({
            expireEngineerData: {
                ...state.expireEngineerData,
                [name]: newState,
            },
        }));
    };

    render() {
        const {
            classes,
            handleCloseExpireEngineerDialogClick,
            engineer,
            engineerLoading,
            showExpireEngineerDialog,
        } = this.props;
        const {
            disableEngineerSaveButton,
            expireEngineerData,
        } = this.state;

        return (
            <>
                {!engineerLoading
                    ? (
                        <Dialog
                            open={showExpireEngineerDialog}
                            onClose={handleCloseExpireEngineerDialogClick}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            className={classes.newEngineerDialog}
                        >
                            <DialogContent className={classes.dialogContent}>
                                <div className={classes.dialogContentContainer}>
                                    <>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            className={classes.dialogTitle}
                                        >
                                            Expire Operative
                                        </Typography>
                                        <DialogContentText className={classNames(classes.dialogText, classes.bumpBottom3)}>
                                            Are you sure you want to expire this operative?
                                        </DialogContentText>
                                        <Grid
                                            container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center"
                                        >
                                            <Grid item xs={12}>
                                                <FormControl component="fieldset" className={classes.formControl}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={(
                                                                <Checkbox
                                                                    name="expireUser"
                                                                    id="expireUser"
                                                                    onChange={this.updateExpireEngineerDataCheckbox('expireUser')}
                                                                    checked={expireEngineerData.expireUser}
                                                                    value={expireEngineerData.expireUser}
                                                                />
                                                            )}
                                                            classes={{ label: classes.checkBox }}
                                                            label="Expire User Account"
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    className={classes.dialogButton}
                                                    type="submit"
                                                    onClick={this.handleExpireEngineer}
                                                    disabled={disableEngineerSaveButton}
                                                >
                                                    <div>
                                                        <div>EXPIRE</div>
                                                        <div>{engineer.name}</div>
                                                    </div>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </>
                                </div>
                            </DialogContent>
                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    onClick={handleCloseExpireEngineerDialogClick}
                                    color="primary"
                                    autoFocus
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                    )
                    : ''}
            </>
        );
    }
}

export default withSnackbar(withStyles(styles)(EngineerExpireDialog));
