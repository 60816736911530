import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    OutlinedInput,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { DateTimePicker } from 'material-ui-pickers';
import fetch, { fetchJson2 } from '../../utilities/fetch';
import currentDateTime from '../../utilities/currentDateTime';
import addContactDialog from './addContactDialog';
import AddContactDialog from './addContactDialog';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    bumpBottom3: {
        marginBottom: theme.spacing.unit * 3,
    },
    dialogContentContainer: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    dialogContent: {
        minWidth: 600,
        paddingBottom: 16,
    },
    dialogButton: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 4,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        margin: '0px 0px 0px 0px !important',
        padding: '8px 24px',
    },
    dialogText: {
        marginTop: theme.spacing.unit * 2,
    },
    disabled: {
        opacity: 0.26,
    },
    dialogTitle: {
        marginBottom: theme.spacing.unit * 2,
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
});

class AddLeadAgreementSaleDialog extends React.Component {
    state = {
        showAddContactDialog: true,
        // disableTransferButton: false,
        // transferEngineerVisitsData: {
        //     transferDateTime: currentDateTime().dateTime,
        //     transferToEngineerCode: '',
        // },
    };

    handleAddContactDialogClick = () => {
        this.setState({
            showAddContactDialog: true,
        });
    };

    handleAddContactDialogCloseClick = () => {
        this.setState({
            showAddContactDialog: false,
        });
    };

    // handleTransferVisits = () => {
    //     const transferDateTime = moment(this.state.transferEngineerVisitsData.transferDateTime).format('YYYY-MM-DD HH:i');
    //     this.setState({
    //         disableTransferButton: true,
    //     });
    //     this.transferEngineerVisits(this.props.engineer.engineer.code, this.state.transferEngineerVisitsData.transferToEngineerCode, transferDateTime).then(() => {
    //         this.setState({
    //             disableTransferButton: false,
    //         });
    //         this.props.handleCloseTransferEngineerVisitsDialogClick();
    //         this.props.enqueueSnackbar('Transfer Engineer Visits Success', { variant: 'success' });
    //     }).catch(() => {
    //         this.props.enqueueSnackbar('Transfer Engineer Visits Failed', { variant: 'error' });
    //         this.setState({
    //             disableTransferButton: false,
    //         });
    //     });
    // };

    // updateTransferEngineerVisitsData = (event) => {
    //     const { name, value } = event.target;
    //     let formattedValue;
    //     switch (name) {
    //     default:
    //         formattedValue = value;
    //         break;
    //     }
    //     event.target.value = formattedValue;
    //     this.setState((state) => ({
    //         transferEngineerVisitsData: {
    //             ...state.transferEngineerVisitsData,
    //             [name]: formattedValue,
    //         },
    //     }));
    // };

    // handleTransferDateTimeChange = (chosenDate) => {
    //     this.setState((state) => ({
    //         transferEngineerVisitsData: {
    //             ...state.transferEngineerVisitsData,
    //             transferDateTime: moment(chosenDate).format('YYYY-MM-DD HH:mm'),
    //         },
    //     }));
    // };

    render() {
        const {
            classes,
            handleNewLeadAgreementSaleCloseClick,
            showAddLeadAgreementSaleDialog,
        } = this.props;
        const {
            showAddContactDialog,
            // disableTransferButton,
            // transferEngineerVisitsData,
        } = this.state;

        return (
            <>
                <Dialog
                    open={showAddLeadAgreementSaleDialog}
                    onClose={handleNewLeadAgreementSaleCloseClick}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.dialogContentContainer}>
                            <>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className={classes.dialogTitle}
                                >
                                    New Lead/Agreement/Sale
                                </Typography>
                                {/* <Grid
                                    container
                                    spacing={0}
                                    // direction="column"
                                    alignItems="center"
                                    justify="center"
                                >
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.formControl}
                                            name="sendToEmailAddress"
                                            id="sendToEmailAddress"
                                            value={buildSendContractData.sendToEmailAddress}
                                            onChange={this.updateBuildSendContractData}
                                            label="Send To Email Address"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={!!errors.sendToEmailAddress}
                                            helperText={errors.sendToEmailAddress}
                                        />
                                    </Grid>
                                </Grid> */}
                            </>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            onClick={handleNewLeadAgreementSaleCloseClick}
                            color="primary"
                            autoFocus
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <AddContactDialog
                    showAddContactDialog={showAddContactDialog}
                    handleAddContactDialogCloseClick={this.handleAddContactDialogCloseClick}
                />
            </>
        );
    }
}

export default withSnackbar(withStyles(styles)(AddLeadAgreementSaleDialog));
