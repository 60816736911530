import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import fetch, { fetchJson2 } from '../../utilities/fetch';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    dialogContentContainer: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    dialogContent: {
        minWidth: 600,
        paddingBottom: 16,
    },
    dialogButton: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 4,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        margin: '0px 0px 0px 0px !important',
        padding: '8px 24px',
    },
    dialogDivider: {
        marginBottom: theme.spacing.unit * 2,
        marginLeft: -24,
        marginRight: -24,
        marginTop: theme.spacing.unit * 2,
    },
    disabled: {
        opacity: 0.26,
    },
    dialogExpiredCheckBox: {
        textAlign: 'right',
    },
    checkBox: {
        fontSize: 16,
    },
    dialogTitle: {
        marginBottom: theme.spacing.unit * 2,
    },
});

class ManufacturerSupplierCreateDialog extends React.Component {
    state = {
        createManufacturerSupplierData: {
            name: '',
        },
        disableCreateManufacturerSupplierSaveButton: false,
        errors: [],
    };

    componentDidMount() {
        this.setFormDefaults();
    }

    setFormDefaults = () => {
        this.setState({
            createManufacturerSupplierData: {
                name: '',
            },
        });
    };

    updateCreateManufacturerSupplierData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        let addressData;
        switch (name) {
        case 'address1':
        case 'address2':
        case 'townCity':
        case 'county':
        case 'postcode':
        case 'country':
            addressData = {
                ...this.state.createManufacturerSupplierData.address,
                [name]: value,
            };
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            createManufacturerSupplierData: {
                ...state.createManufacturerSupplierData,
                [name]: formattedValue,
                address: {
                    ...state.createManufacturerSupplierData.address,
                    ...addressData,
                },
            },
        }));
    };

    handleCreateManufacturerSupplier = () => {
        this.setState({
            disableCreateManufacturerSupplierSaveButton: true,
        });
        const payload = this.state.createManufacturerSupplierData;
        this.createManufacturerSupplier(payload).then(() => {
            this.setState({
                disableCreateManufacturerSupplierSaveButton: false,
            });
            this.props.handleCloseCreateManufacturerSupplierDialogClick();
            this.props.enqueueSnackbar('Manufacturer/Supplier Created', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Create Manufacturer/Supplier Failed', { variant: 'error' });
            this.setState({
                disableCreateManufacturerSupplierSaveButton: false,
            });
        });
    };

    updateCreateManufacturerSupplierDataCheckbox = (name) => (event) => {
        const newState = !!event.target.checked;
        this.setState((state) => ({
            createManufacturerSupplierData: {
                ...state.createManufacturerSupplierData,
                [name]: newState,
            },
        }));
    };

    createManufacturerSupplier = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/manufacturerSupplier/`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
              && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newManufacturerSupplier = {
                ...body.manufacturerSupplier,
                histories: [
                    body.history,
                ],
            };
            this.props.passNewManufacturerSupplier(newManufacturerSupplier);
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    render() {
        const {
            classes,
            handleCloseCreateManufacturerSupplierDialogClick,
            manufacturerSupplierLoading,
            showCreateManufacturerSupplierDialog,
        } = this.props;

        const {
            disableCreateManufacturerSupplierSaveButton,
            createManufacturerSupplierData,
            errors,
        } = this.state;

        return (
            <>
                {!manufacturerSupplierLoading
                    ? (
                        <Dialog
                            open={showCreateManufacturerSupplierDialog}
                            onClose={() => this.props.handleCloseCreateManufacturerSupplierDialogClick()}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            className={classes.newManufacturerDialog}
                        >
                            <DialogContent className={classes.dialogContent}>
                                <div className={classes.dialogContentContainer}>
                                    <>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            className={classes.dialogTitle}
                                        >
                                            Create Manufacturer Supplier
                                        </Typography>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    className={classes.formControl}
                                                    name="name"
                                                    id="name"
                                                    onChange={this.updateCreateManufacturerSupplierData}
                                                    value={createManufacturerSupplierData.name}
                                                    label="Company Name"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    error={!!errors.name}
                                                    helperText={errors.name}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl component="fieldset" className={classes.formControl}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={(
                                                                <Checkbox
                                                                    name="manufacturer"
                                                                    id="manufacturer"
                                                                    onChange={this.updateCreateManufacturerSupplierDataCheckbox('manufacturer')}
                                                                    checked={createManufacturerSupplierData.manufacturer}
                                                                    value={createManufacturerSupplierData.manufacturer}
                                                                />
                                                            )}
                                                            classes={{ label: classes.checkBox }}
                                                            label="Manufacturer"
                                                        />
                                                        <FormControlLabel
                                                            control={(
                                                                <Checkbox
                                                                    name="supplier"
                                                                    id="supplier"
                                                                    onChange={this.updateCreateManufacturerSupplierDataCheckbox('supplier')}
                                                                    checked={createManufacturerSupplierData.supplier}
                                                                    value={createManufacturerSupplierData.supplier}
                                                                />
                                                            )}
                                                            classes={{ label: classes.checkBox }}
                                                            label="Supplier"
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        {createManufacturerSupplierData.supplier
                                            ? (
                                                <>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="address1"
                                                                id="address1"
                                                                onChange={this.updateCreateManufacturerSupplierData}
                                                                value={(createManufacturerSupplierData.address || {}).address1}
                                                                label="Address Line 1"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.address1}
                                                                helperText={errors.address1}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="address2"
                                                                id="address2"
                                                                onChange={this.updateCreateManufacturerSupplierData}
                                                                value={(createManufacturerSupplierData.address || {}).address2}
                                                                label="Address Line 2"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                error={!!errors.address2}
                                                                helperText={errors.address2}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="townCity"
                                                                id="townCity"
                                                                onChange={this.updateCreateManufacturerSupplierData}
                                                                value={(createManufacturerSupplierData.address || {}).townCity}
                                                                label="Town/City"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.townCity}
                                                                helperText={errors.townCity}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="county"
                                                                id="county"
                                                                onChange={this.updateCreateManufacturerSupplierData}
                                                                value={(createManufacturerSupplierData.address || {}).county}
                                                                label="County"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.county}
                                                                helperText={errors.county}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="postcode"
                                                                id="postcode"
                                                                onChange={this.updateCreateManufacturerSupplierData}
                                                                value={(createManufacturerSupplierData.address || {}).postcode}
                                                                label="Postcode"
                                                                margin="normal"
                                                                variant="outlined"
                                                                required
                                                                error={!!errors.postcode}
                                                                helperText={errors.postcode}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="country"
                                                                id="country"
                                                                onChange={this.updateCreateManufacturerSupplierData}
                                                                value={(createManufacturerSupplierData.address || {}).country}
                                                                label="Country"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.country}
                                                                helperText={errors.country}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Divider className={classes.dialogDivider} />
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="telephoneNumber"
                                                                id="telephoneNumber"
                                                                onChange={this.updateCreateManufacturerSupplierData}
                                                                value={createManufacturerSupplierData.telephoneNumber}
                                                                label="Telephone Number"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.telephoneNumber}
                                                                helperText={errors.telephoneNumber}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="faxNumber"
                                                                id="faxNumber"
                                                                onChange={this.updateCreateManufacturerSupplierData}
                                                                value={createManufacturerSupplierData.faxNumber}
                                                                label="Fax Number"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                error={!!errors.faxNumber}
                                                                helperText={errors.faxNumber}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="emailAddress"
                                                                id="emailAddress"
                                                                onChange={this.updateCreateManufacturerSupplierData}
                                                                value={createManufacturerSupplierData.emailAddress}
                                                                label="Email Address"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.emailAddress}
                                                                helperText={errors.emailAddress}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="accountReference"
                                                                id="accountReference"
                                                                onChange={this.updateCreateManufacturerSupplierData}
                                                                value={createManufacturerSupplierData.accountReference}
                                                                label="Account Reference"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.accountReference}
                                                                helperText={errors.accountReference}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="ourAccountReference"
                                                                id="ourAccountReference"
                                                                onChange={this.updateCreateManufacturerSupplierData}
                                                                value={createManufacturerSupplierData.ourAccountReference}
                                                                label="Our Account Reference"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.ourAccountReference}
                                                                helperText={errors.ourAccountReference}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="paymentTerms"
                                                                id="paymentTerms"
                                                                onChange={this.updateCreateManufacturerSupplierData}
                                                                value={createManufacturerSupplierData.paymentTerms}
                                                                label="Payment Terms"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.paymentTerms}
                                                                helperText={errors.paymentTerms}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                </>
                                            )
                                            : ''}
                                        <Grid
                                            container
                                            spacing={0}
                                            direction="column"
                                            className={classes.dialogExpiredCheckBox}
                                        >
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={(
                                                        <Checkbox
                                                            name="expired"
                                                            id="expired"
                                                            onChange={this.updateCreateManufacturerSupplierDataCheckbox('expired')}
                                                            checked={createManufacturerSupplierData.expired}
                                                            value={createManufacturerSupplierData.expired}
                                                        />
                                                    )}
                                                    classes={{ label: classes.checkBox }}
                                                    label="Expired"
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center"
                                        >
                                            <Grid item xs={12}>
                                                <Button
                                                    className={classes.dialogButton}
                                                    type="submit"
                                                    onClick={this.handleCreateManufacturerSupplier}
                                                    disabled={disableCreateManufacturerSupplierSaveButton
                                                || (!createManufacturerSupplierData.manufacturer && !createManufacturerSupplierData.supplier)}
                                                >
                                                    SAVE
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </>
                                </div>
                            </DialogContent>
                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    onClick={() => this.props.handleCloseCreateManufacturerSupplierDialogClick()}
                                    color="primary"
                                    autoFocus
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                    : ''}
            </>

        );
    }
}

export default withSnackbar(withStyles(styles)(ManufacturerSupplierCreateDialog));
