import React from 'react';
import {
    CssBaseline,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Dialog,
    Button,
    DialogContent,
    Grid,
    Typography,
    FormControl,
    InputLabel,
    TextField,
    DialogActions,
    Select,
    MenuItem,
    ListSubheader,
    Tooltip,
    Fade,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker, DateTimePicker } from 'material-ui-pickers';
import EditIcon from '@material-ui/icons/EditOutlined';
import FilterIcon from '@material-ui/icons/FilterListOutlined';
import SendIcon from '@material-ui/icons/SendOutlined';
import { withSnackbar } from 'notistack';
import moment from 'moment-timezone';
import Cookie from 'js-cookie';
import Layout from './Layout';
import fetch from '../utilities/fetch';

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        marginBottom: theme.spacing.unit * 3,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableHead: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    authorisedPaymentChip: {
        backgroundColor: theme.palette.primary.testPass,
        color: 'white',
        minWidth: '87px',
    },
    declinedPaymentChip: {
        backgroundColor: theme.palette.primary.testFail,
        color: 'white',
        minWidth: '87px',
    },
    unknownPaymentChip: {
        minWidth: '87px',
    },
    chip: {
        height: 21,
        borderRadius: 5,
    },
    helptip: {
        fontSize: '1em',
        backgroundColor: 'yellow',
        color: 'black',
        textAlign: 'center',
        minWidth: '120px',
    },
});

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        padding: '4px',
    },
    body: {
        fontSize: 14,
        padding: '4px',
    },
}))(TableCell);

class Arrears extends React.Component {
    state = {
        agreements: [],
        templates: [],
        arrearsMin: '20.00',
        arrearsMax: '',
        missedPaymentsMin: '2',
        missedPaymentsMax: '3',
        lastMessage: null,
        template: '',
        preview: '',
        pdf: '',
        sendOpen: false,
        filterOpen: false,
        postDate: null,
        lastMessage: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        lastRecurring: moment().subtract(2, 'days').format('YYYY-MM-DD'),
        helpSend: false,
        to: '',
    };

    async componentDidMount() {
        this.getTemplates();
        this.filterOpen();
    }

    async getTemplates() {
        try {
            const url = `${process.env.REACT_APP_API_SERVER_SYD}core/v2/templates`;
            const data = await fetch(url).then(
                (response) => response.json(),
            );
            const templates = [];
            // Sort by type/ description
            data.sort((a, b) => {
                if (a.type > b.type) {
                    return 1;
                }
                if (a.type < b.type) {
                    return -1;
                }

                if (a.description > b.description) {
                    return 1;
                }
                if (a.description < b.description) {
                    return -1;
                }

                return 0;
            });
            // Remap templates/ types to a one-dimensional array we can use in a single map command below
            for (let x = 0; x < data.length; x++) {
                if (x == 0 || data[x - 1].type != data[x].type) {
                    templates.push({ code: 0, description: data[x].type });
                }
                templates.push({
                    code: data[x].code, description: data[x].description, message: data[x].message, type: data[x].type,
                });
            }
            this.setState({ templates });
        } catch (err) {
            console.log(err);
        }
    }

    getQuery() {
        return {
            arrearsMin: this.state.arrearsMin,
            arrearsMax: this.state.arrearsMax,
            missedPaymentsMin: this.state.missedPaymentsMin,
            missedPaymentsMax: this.state.missedPaymentsMax,
            templateCode: this.state.template,
            postDate: this.state.postDate,
            lastMessage: this.state.lastMessage ? this.state.lastMessage : '',
            lastRecurring: this.state.lastRecurring ? this.state.lastRecurring : '',
            batch: moment().format('YYYY-MM-DD'),
            to: this.state.to,
        };
    }

    async update() {
        try {
            const query = this.getQuery();
            const url = `${process.env.REACT_APP_API_SERVER_SYD}core/v2/arrears?${(new URLSearchParams(query)).toString()}`;
            console.log(url);
            const data = await fetch(url).then(
                (response) => response.json(),
            );
            this.setState({ agreements: data });
        } catch (err) {
            console.log(err);
        }
    }

    editClick = (contactCode, agreementCode) => {
        const tab = window.open(`/contact/${contactCode}?agreementCode=${agreementCode}`, '_blank', 'noopener,noreferrer');
        if (tab) {
            tab.opener = null;
        }
    };

    filterOpen = async () => {
        try {
            await this.setState({
                filterOpen: true,
                _arrearsMin: this.state.arrearsMin,
                _arrearsMax: this.state.arrearsMax,
                _missedPaymentsMin: this.state.missedPaymentsMin,
                _missedPaymentsMax: this.state.missedPaymentsMax,
            });
        } catch (err) {
            console.log(err);
        }
    };

    sendOpen = async () => {
        try {
            await this.setState({
                sendOpen: true,
            });
        } catch (err) {
            console.log(err);
        }
    };

    updateData = (event) => {
        const { name, value } = event.target;
        this.setState((state) => ({
            [name]: value,
        }));
    };

    updateTemplate = async (event) => {
        try {
            this.updateData(event);
            const { name, value } = event.target;
            if (value == 0 || this.state.agreements.length == 0) {
                this.setState((state) => ({
                    preview: '',
                }));
            } else {
                const url = `${process.env.REACT_APP_API_SERVER_SYD}core/v2/templates/${value}/merge?agreementCode=${this.state.agreements[0].rentalAgreementCode}`;
                console.log(url);
                const template = await fetch(url).then(
                    (response) => response.json(),
                );
                this.setState((state) => ({
                    preview: template.message,
                    pdf: template.type == 'LETTER' ? `${process.env.REACT_APP_API_SERVER_SYD}core/v2/templates/${value}/pdf?agreementCode=${this.state.agreements[0].rentalAgreementCode}&token=${Cookie.get('authToken')}` : '',
                }));
            }
        } catch (err) {
            console.log(err);
        }
    };

    updatePostDate = (dt) => {
        this.setState((state) => ({
            postDate: moment(dt).format('YYYY-MM-DD HH:mm'),
        }));
    };

    updateLastMessage = (dt) => {
        this.setState((state) => ({
            lastMessage: moment(dt).format('YYYY-MM-DD'),
        }));
    };

    updateLastRecurring = (dt) => {
        this.setState((state) => ({
            lastRecurring: moment(dt).format('YYYY-MM-DD'),
        }));
    };

    filterCancel = () => {
        this.setState({ filterOpen: false });
    };

    sendCancel = () => {
        this.setState({ sendOpen: false });
    };

    sendPDF = () => {
        const tab = window.open(this.state.pdf, '_blank', 'noopener,noreferrer');
        if (tab) {
            tab.opener = null;
        }
    };

    sendTest = async () => {
        try {
            const query = this.getQuery();
            const url = `${process.env.REACT_APP_API_SERVER_SYD}core/v2/arrears/test?${(new URLSearchParams(query)).toString()}`;
            console.log(url);
            const data = await fetch(url).then(
                (response) => response.json(),
            );
            console.log(data);
            this.props.enqueueSnackbar('Sent Test Message!', { variant: 'success' });
        } catch (err) {
            console.log(err);
        }
    };

    filterApply = async () => {
        try {
            await this.setState({
                filterOpen: false,
                arrearsMin: parseFloat(this.state._arrearsMin) ? parseFloat(this.state._arrearsMin).toFixed(2) : '',
                arrearsMax: parseFloat(this.state._arrearsMax) ? parseFloat(this.state._arrearsMax).toFixed(2) : '',
                missedPaymentsMin: parseInt(this.state._missedPaymentsMin) ? parseInt(this.state._missedPaymentsMin) : '',
                missedPaymentsMax: parseInt(this.state._missedPaymentsMax) ? this.state._missedPaymentsMax : '',
                helpSend: true,
            });
            this.update();
            const timer = setTimeout(() => {
                this.setState({ helpSend: false });
            }, 7000);
        } catch (err) {
            console.log(err);
        }
    };

    sendApply = async () => {
        try {
            await this.setState({
                sendOpen: false,
            });
            const query = this.getQuery();
            const url = `${process.env.REACT_APP_API_SERVER_SYD}core/v2/arrears?${(new URLSearchParams(query)).toString()}`;
            const posted = await fetch(url, {
                method: 'POST',
                body: '{}',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(
                (response) => response.json(),
            );
            this.props.enqueueSnackbar(`Posted: ${posted.posted} Messages!`, { variant: 'success' });
            console.log(`Posted: ${posted.posted}`);
            this.update();
        } catch (err) {
            console.log(err);
        }
    };

    helpSendClose = () => {
        this.setState({ helpSend: false });
    };

    helpSendOpen = () => {
        this.setState({ helpSend: true });
    };

    render() {
        const { classes } = this.props;
        const { agreements, templates } = this.state;
        return (

            <Layout pageTitle="Arrears">
                <CssBaseline />
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell className={classes.tableHead} align="left">
                                    <Tooltip
                                        title={(
                                            <>
                                                <Typography>Filter</Typography>
                                                <Typography>⬇</Typography>
                                            </>
                                        )}
                                        placement="top"
                                        classes={{ tooltip: classes.helptip }}
                                        disableFocusListener
                                        TransitionComponent={Fade}
                                    >
                                        <IconButton onClick={() => this.filterOpen()} className={classes.button} aria-label="Edit"><FilterIcon /></IconButton>
                                    </Tooltip>
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>
                                    Current Arrears
                                    <br />
                                    { `${parseFloat(this.state.arrearsMin) ? `≥ £${this.state.arrearsMin}` : ''} ${parseFloat(this.state.arrearsMax) ? `< £${this.state.arrearsMax}` : ''}` }
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>
                                    Missed Payments
                                    <br />
                                    { `${parseInt(this.state.missedPaymentsMin) ? `≥ ${this.state.missedPaymentsMin}` : ''} ${parseInt(this.state.missedPaymentsMax) ? `< ${this.state.missedPaymentsMax}` : ''}` }
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Last Auth</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Decline</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Recurring</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>
                                    Last Message
                                    <br />
                                    { (this.state.lastMessage ? `≤ ${(new Date(this.state.lastMessage)).toLocaleDateString()}` : '') }
                                </CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Type</CustomTableCell>
                                <CustomTableCell className={classes.tableHead}>Description</CustomTableCell>
                                <CustomTableCell align="right">
                                    <Tooltip
                                        title={(
                                            <>
                                                <Typography>Send</Typography>
                                                <Typography>⬇</Typography>
                                            </>
                                        )}
                                        open={this.state.helpSend}
                                        placement="top"
                                        onOpen={() => this.helpSendOpen()}
                                        onClose={() => this.helpSendClose()}
                                        classes={{ tooltip: classes.helptip }}
                                        disableFocusListener
                                        TransitionComponent={Fade}
                                    >
                                        <IconButton onClick={() => this.sendOpen()} className={classes.button} aria-label="Edit"><SendIcon /></IconButton>
                                    </Tooltip>
                                </CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {agreements.map((agreement, index) => (
                                <TableRow className={classes.row} key={agreement.rentalAgreementCode}>
                                    <CustomTableCell component="th" scope="row">
                                        RA
                                        {agreement.rentalAgreementCode}
                                    </CustomTableCell>
                                    <CustomTableCell align="left">
                                        £
                                        {agreement.currentArrears.toFixed(2)}
                                    </CustomTableCell>
                                    <CustomTableCell align="left">{agreement.missedPayments}</CustomTableCell>
                                    <CustomTableCell align="left">{agreement.lastAuthorisedPaymentDate ? moment(agreement.lastAuthorisedPaymentDate).format('DD/MM/YY') : '-'}</CustomTableCell>
                                    <CustomTableCell align="left">{agreement.lastDeclinedPaymentDate ? moment(agreement.lastDeclinedPaymentDate).format('DD/MM/YY') : '-'}</CustomTableCell>
                                    <CustomTableCell align="left">{agreement.lastRecurringPaymentDate ? moment(agreement.lastRecurringPaymentDate).format('DD/MM/YY') : '-'}</CustomTableCell>
                                    <CustomTableCell align="left">{agreement.lastMessage ? moment(agreement.lastMessage).format('DD/MM/YY') : '-'}</CustomTableCell>
                                    <CustomTableCell align="left">{agreement.lastType ? agreement.lastType : '-'}</CustomTableCell>
                                    <CustomTableCell align="left">{agreement.lastDescription ? agreement.lastDescription : '-'}</CustomTableCell>
                                    <CustomTableCell align="right"><IconButton onClick={() => this.editClick(agreement.contactCode, agreement.rentalAgreementCode)} className={classes.button} aria-label="Edit"><EditIcon /></IconButton></CustomTableCell>
                                </TableRow>

                            ))}
                        </TableBody>
                    </Table>
                    <Dialog
                        open={this.state.filterOpen}
                        className={classes.dialogContent}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogContent>
                            <div className={classes.dialogContentContainer}>
                                <>
                                    <Grid container spacing={0}>
                                        <Grid item container xs={6}>
                                            <Typography variant="h6" gutterBottom>
                                                Filter Arrears
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <form>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <FormControl
                                                    required
                                                    className={`${classes.menuFormControl} ${classes.filterSet}`}
                                                    variant="outlined"
                                                    fullWidth
                                                >
                                                    <DatePicker
                                                        required
                                                        name="lastRecurring"
                                                        id="lastRecurring"
                                                        margin="normal"
                                                        label="Last Recurring Payment"
                                                        value={this.state.lastRecurring}
                                                        onChange={this.updateLastRecurring}
                                                        fullWidth
                                                        variant="outlined"
                                                        format="DD/MM/YYYY"
                                                        showTodayButton
                                                        autoOk
                                                    />
                                                    <TextField
                                                        type="number"
                                                        InputProps={{ inputProps: { min: 0, step: 0.01 } }}
                                                        className={classes.formControl}
                                                        name="_arrearsMin"
                                                        id="_arrearsMin"
                                                        value={this.state._arrearsMin}
                                                        onChange={this.updateData}
                                                        label="Current Arrears ≥"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                    <TextField
                                                        type="number"
                                                        InputProps={{ inputProps: { min: 0, step: 0.01 } }}
                                                        className={classes.formControl}
                                                        name="_arrearsMax"
                                                        id="_arrearsMax"
                                                        value={this.state._arrearsMax}
                                                        onChange={this.updateData}
                                                        label="Current Arrears <"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                    <TextField
                                                        type="number"
                                                        InputProps={{ inputProps: { min: 0, step: 1 } }}
                                                        className={classes.formControl}
                                                        name="_missedPaymentsMin"
                                                        id="_missedPaymentsMin"
                                                        value={this.state._missedPaymentsMin}
                                                        onChange={this.updateData}
                                                        label="Missed Payments ≥"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                    <TextField
                                                        type="number"
                                                        InputProps={{ inputProps: { min: 0, step: 1 } }}
                                                        className={classes.formControl}
                                                        name="_missedPaymentsMax"
                                                        id="_missedPaymentsMax"
                                                        value={this.state._missedPaymentsMax}
                                                        onChange={this.updateData}
                                                        label="Missed Payments <"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                    <DatePicker
                                                        required
                                                        name="lastMessage"
                                                        id="lastMessage"
                                                        margin="normal"
                                                        label="Last Message ≤"
                                                        value={this.state.lastMessage}
                                                        onChange={this.updateLastMessage}
                                                        fullWidth
                                                        variant="outlined"
                                                        format="DD/MM/YYYY"
                                                        showTodayButton
                                                        autoOk
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </>
                            </div>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                onClick={this.filterApply}
                                color="primary"
                                autoFocus
                            >
                                Apply
                            </Button>
                            <Button
                                onClick={this.filterCancel}
                                color="primary"
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.sendOpen}
                        className={classes.dialogContent}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogContent>
                            <div className={classes.dialogContentContainer}>
                                <>
                                    <Grid container spacing={0}>
                                        <Grid item container xs={6}>
                                            <Typography variant="h6" gutterBottom>
                                                Send Arrears Messages
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <form>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <FormControl
                                                    required
                                                    className={`${classes.menuFormControl} ${classes.filterSet}`}
                                                    variant="outlined"
                                                    fullWidth
                                                >
                                                    <TextField
                                                        type="number"
                                                        className={classes.formControl}
                                                        name="agreements"
                                                        id="agreements"
                                                        value={this.state.agreements.length > 0 ? this.state.agreements[0].total : 0}
                                                        label="Selected Agreements"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={this.state.agreements.length == 0}
                                                    />
                                                    <TextField
                                                        id="type"
                                                        className={classes.formControl}
                                                        name="template"
                                                        value={this.state.template}
                                                        onChange={this.updateTemplate}
                                                        select
                                                        label="Message"
                                                        variant="outlined"
                                                        required
                                                        error={!this.state.template}
                                                    >
                                                        {templates.map((template, index) => (
                                                            <MenuItem key={index} disabled={template.code == 0} value={template.code} style={{ fontWeight: template.code == 0 ? 'bold' : '' }}>{template.description}</MenuItem>
                                                        ))}
                                                    </TextField>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="to"
                                                        id="to"
                                                        value={this.state.to}
                                                        onChange={this.updateData}
                                                        label="Test To"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                    {this.state.preview != ''
                                                        ? (
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="preview"
                                                                id="preview"
                                                                value={this.state.preview}
                                                                label="Preview"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                readOnly
                                                                multiline
                                                            />
                                                        ) : null}
                                                    <DateTimePicker
                                                        required
                                                        name="postDate"
                                                        id="postDate"
                                                        margin="normal"
                                                        label="Send Date/ Time"
                                                        value={this.state.postDate}
                                                        onChange={this.updatePostDate}
                                                        fullWidth
                                                        variant="outlined"
                                                        format="DD/MM/YYYY HH:mm"
                                                        showTodayButton
                                                        disablePast
                                                        autoOk
                                                        error={!this.state.postDate}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </>
                            </div>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <div style={{ position: 'absolute', left: '0px' }}>
                                { this.state.pdf != ''
                                    ? (
                                        <Button
                                            onClick={this.sendPDF}
                                            color="primary"
                                            autoFocus
                                        >
                                            PDF
                                        </Button>
                                    )
                                    : null}
                                <Button
                                    onClick={this.sendTest}
                                    color="primary"
                                    autoFocus
                                    disabled={!this.state.template || this.state.agreements.length == 0 || !this.state.to}
                                >
                                    Test
                                </Button>
                            </div>
                            <Button
                                onClick={this.sendApply}
                                color="primary"
                                autoFocus
                                disabled={!this.state.template || this.state.agreements.length == 0 || !this.state.postDate}
                            >
                                Send
                            </Button>
                            <Button
                                onClick={this.sendCancel}
                                color="primary"
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
                {
                    this.state.agreements.length > 0 && this.state.agreements[0].total > 10
                        ? (
                            <div>
                                [10 of
                                {' '}
                                {this.state.agreements[0].total}
                                {' '}
                                agreements shown]
                            </div>
                        ) : null
                }
            </Layout>
        );
    }
}

export default withSnackbar(withStyles(styles)(Arrears));
