function formatTimePeriod(totalMinutes) {
    const minutesPerDay = 1440;
    const minutesPerHour = 60;

    const days = Math.floor(totalMinutes / minutesPerDay);
    const hours = Math.floor((totalMinutes % minutesPerDay) / minutesPerHour);
    const minutes = totalMinutes % minutesPerHour;
    // const formattedTimePeriod = `${days ? `${days.toFixed(0).padStart(2, '0')}d` : ''}${hours.toFixed(0).padStart(2, '0')}h${minutes.toFixed(0).padStart(2, '0')}m`;
    const formattedTimePeriod = `${days ? `${days.toFixed(0)}d ` : ''}${hours ? `${hours.toFixed(0)}h ` : ''}${minutes.toFixed(0)}m`;
    return formattedTimePeriod;
}

export default formatTimePeriod;
