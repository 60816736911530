import React from 'react';
import {
    CssBaseline,
    Grid,
    Typography,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSimCard } from '@fortawesome/free-solid-svg-icons'
import qs from 'qs';

import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import moment from 'moment-timezone';

import Layout from './Layout';
import fetch, { fetchJson } from '../utilities/fetch';
import NakedTile from '../containers/nakedTile';
import HybridReportTextField from '../components/hybridReportTextField';
import NumberWithCommas from '../utilities/numberWithCommas';
import {Line} from 'react-chartjs-2';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        marginBottom: theme.spacing.unit * 3,
    },
    headlineText: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        color: 'rgba(0, 0, 0, .7)',
        fontSize: 40,
        fontWeight: 400,
        marginBottom: theme.spacing.unit * 1,
    },
    gridContainer: {
        marginTop: theme.spacing.unit * 1,
        marginBottom: theme.spacing.unit * 1,
    },
    varianceIcon: {
        fontSize: 44,
    },
    red: {
        color: theme.palette.primary.testFail,
    },
    green: {
        color: theme.palette.primary.testPass,
    },
    variancePercentage: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontSize: 18,
        color: 'rgba(0, 0, 0, .7)',
    },
    summaryTitle: {
        backgroundColor: theme.palette.primary.lighter,
        padding: 8,
        fontWeight: 500,
        color: 'rgba(249, 170, 51, 1)',
        textTransform: 'uppercase',
    },
    summarySubTitle: {
        textAlign: 'right',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontSize: 16,
        color: 'rgba(0, 0, 0, .7)',
    },
    summaryNote: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontSize: 16,
        color: 'rgba(0, 0, 0, .5)',
    },
    summaryValue: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontSize: 16,
        color: 'rgba(0, 0, 0, 1)',
        fontWeight: 500,
    }
});

const data = {
    labels: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct'],
    datasets: [{
        data: [86,114,306,406,107,311,233,221,783,2478],
        fill: false,
        borderColor: 'rgba(249, 170, 51, 1)',
    }],
};

const options = {
    legend: false,
};

const options2 = {
    legend: false,
    scales: {
        yAxes: [{
            id: 'A',
            type: 'linear',
            position: 'left',
        }, {
            id: 'B',
            type: 'linear',
            position: 'right',
            // ticks: {
            //     max: 1,
            //     min: 0,
            // },
        }],
    },
};

class ReportAccountsOverview extends React.Component {
    state = {
        reports: {},
        summary: {},
    };

    // async componentDidMount() {
    //     const now = moment.tz(moment(), 'Europe/London');
    //     const lastMonthStr = moment(now).subtract(1, 'month').format('MMMM');
    //     const lastWeekStr = moment(now).subtract(1, 'week').week();
    //     const lastMonthNum = moment(now).subtract(1, 'month').format('M');
    //     const lastWeekNum = moment(now).subtract(1, 'week').week();
    //     this.setState({
    //         lastMonthStr,
    //         lastMonthNum: parseInt(lastMonthNum, 10),
    //         lastWeekStr,
    //         lastWeekNum: parseInt(lastWeekNum, 10),
    //     });
    //
    //     fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/report/agreements/${lastMonthNum}`)
    //         .then((agreements) => {
    //             const cumulativeData = [];
    //             agreements.map(month => (month.value)).reduce((a, b, i) => { return cumulativeData[i] = (a + b) }, 0);
    //             const lastMonthVariance = (((agreements.filter(month => month.number == lastMonthNum)[0].value) / (agreements.filter(month => month.number === lastMonthNum - 1)[0].value) * 100) - 100).toFixed(0);
    //             this.setState(state => ({
    //                 reports: {
    //                     ...state.reports,
    //                     agreements: {
    //                         data: agreements,
    //                         cumulativeData,
    //                         lastMonthVariance,
    //                     },
    //                 },
    //             }));
    //         });
    //
    //     fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/report/leads/${lastMonthNum}`)
    //         .then((leads) => {
    //             const cumulativeData = [];
    //             leads.map(month => (month.value)).reduce((a, b, i) => { return cumulativeData[i] = (a + b) }, 0);
    //             const lastMonthVariance = (((leads.filter(month => month.number == lastMonthNum)[0].value) / (leads.filter(month => month.number === lastMonthNum - 1)[0].value) * 100) - 100).toFixed(0);
    //             this.setState(state => ({
    //                 reports: {
    //                     ...state.reports,
    //                     leads: {
    //                         data: leads,
    //                         cumulativeData,
    //                         lastMonthVariance
    //                     },
    //                 },
    //             }));
    //         });
    //     fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/report/leadConversion/${lastMonthNum}`)
    //         .then((leadConversion) => {
    //             const cumulativeData = [];
    //             leadConversion.map(month => (month.value)).reduce((a, b, i) => { return cumulativeData[i] = (a + b) }, 0);
    //             const lastMonthVariance = (((leadConversion.filter(month => month.number == lastMonthNum)[0].value) / (leadConversion.filter(month => month.number === lastMonthNum - 1)[0].value) * 100) - 100).toFixed(0);
    //             this.setState(state => ({
    //                 reports: {
    //                     ...state.reports,
    //                     leadConversion: {
    //                         data: leadConversion,
    //                         cumulativeData,
    //                         lastMonthVariance
    //                     },
    //                 },
    //             }));
    //         });
    //     fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/report/netPayments/${lastMonthNum}`)
    //         .then((netPayments) => {
    //             const cumulativeData = [];
    //             netPayments.map(month => (month.value)).reduce((a, b, i) => { return cumulativeData[i] = (a + b) }, 0);
    //             console.log(cumulativeData);
    //             const lastMonthVariance = (((netPayments.filter(month => month.number == lastMonthNum)[0].value) / (netPayments.filter(month => month.number === lastMonthNum - 1)[0].value) * 100) - 100).toFixed(0);
    //             this.setState(state => ({
    //                 reports: {
    //                     ...state.reports,
    //                     netPayments: {
    //                         data: netPayments,
    //                         cumulativeData,
    //                         lastMonthVariance
    //                     },
    //                 },
    //             }));
    //         });
    //
    //         fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/report/netPayments/weekly/${lastWeekNum}`)
    //             .then((netPayments) => {
    //                 const cumulativeData = [];
    //                 netPayments.map(week => (week.value)).reduce((a, b, i) => { return cumulativeData[i] = (a + b) }, 0);
    //                 console.log(cumulativeData);
    //                 const lastWeekVariance = (((netPayments.filter(week => week.number == lastWeekNum)[0].value) / (netPayments.filter(week => week.number === lastWeekNum - 1)[0].value) * 100) - 100).toFixed(0);
    //                 this.setState(state => ({
    //                     reports: {
    //                         ...state.reports,
    //                         netPaymentsWeek: {
    //                             data: netPayments,
    //                             cumulativeData,
    //                             lastWeekVariance
    //                         },
    //                     },
    //                 }));
    //             });
    //
    //         fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/report/netAgreementValue/${lastMonthNum}`)
    //             .then((netAgreementValue) => {
    //                 const cumulativeData = [];
    //                 netAgreementValue.map(month => (month.value)).reduce((a, b, i) => { return cumulativeData[i] = (a + b) }, 0);
    //                 const lastMonthVariance = (((netAgreementValue.filter(month => month.number == lastMonthNum)[0].value) / (netAgreementValue.filter(month => month.number === lastMonthNum - 1)[0].value) * 100) - 100).toFixed(0);
    //                 this.setState(state => ({
    //                     reports: {
    //                         ...state.reports,
    //                         netAgreementValue: {
    //                             data: netAgreementValue,
    //                             cumulativeData,
    //                             lastMonthVariance
    //                         },
    //                     },
    //                 }));
    //             });
    //         fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/report/netAgreementValueBalance/${lastMonthNum}`)
    //             .then((netAgreementValueBalance) => {
    //                 const cumulativeData = [];
    //                 netAgreementValueBalance.map(month => (month.value)).reduce((a, b, i) => { return cumulativeData[i] = (a + b) }, 0);
    //                 const lastMonthVariance = (((netAgreementValueBalance.filter(month => month.number == lastMonthNum)[0].value) / (netAgreementValueBalance.filter(month => month.number === lastMonthNum - 1)[0].value) * 100) - 100).toFixed(0);
    //                 this.setState(state => ({
    //                     reports: {
    //                         ...state.reports,
    //                         netAgreementValueBalance: {
    //                             data: netAgreementValueBalance,
    //                             cumulativeData,
    //                             lastMonthVariance
    //                         },
    //                     },
    //                 }));
    //             });
    //
    // }

    async componentDidMount() {
        const now = moment.tz(moment(), 'Europe/London');
        const lastDayOfLastMonth = moment(now).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        const lastDayOfLastWeek = moment(now).subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
        console.log(lastDayOfLastWeek);
        const lastMonthStr = moment(now).subtract(1, 'month').format('MMMM');

        fetchJson('https://api.portal2.payweeklycarpets.co.uk/core/report-total-summary')
            .then((summary) => {
                this.setState({
                    summary,
                });
            });

        // const lastMonthStr = moment(now).subtract(1, 'month').format('MMMM');
        // const lastWeekStr = moment(now).subtract(1, 'week').week();
        // const lastMonthNum = moment(now).subtract(1, 'month').format('M');
        // const lastWeekNum = moment(now).subtract(1, 'week').week();
        this.setState({
            lastDayOfLastMonth,
            lastMonthStr,
            // lastMonthNum: parseInt(lastMonthNum, 10),
            // lastWeekStr,
            // lastWeekNum: parseInt(lastWeekNum, 10),
        });

        fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/report/agreements/${lastDayOfLastMonth}`)
            .then((agreements) => {
                const monthNumbers = agreements.map(month => month.number);
                const lastMonthNum = Math.max(...monthNumbers);
                const cumulativeData = [];
                agreements.map(month => (month.value)).reduce((a, b, i) => { return cumulativeData[i] = (a + b) }, 0);
                const lastMonthVariance = (((agreements.filter(month => month.number == lastMonthNum)[0].value) / (agreements.filter(month => month.number === lastMonthNum - 1)[0].value) * 100) - 100).toFixed(0);
                this.setState(state => ({
                    lastMonthNum,
                    reports: {
                        ...state.reports,
                        agreements: {
                            data: agreements,
                            cumulativeData,
                            lastMonthVariance,
                        },
                    },
                }));
            });

        fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/report/leads/${lastDayOfLastMonth}`)
            .then((leads) => {
                const monthNumbers = leads.map(month => month.number);
                const lastMonthNum = Math.max(...monthNumbers);
                const cumulativeData = [];
                leads.map(month => (month.value)).reduce((a, b, i) => { return cumulativeData[i] = (a + b) }, 0);
                const lastMonthVariance = (((leads.filter(month => month.number == lastMonthNum)[0].value) / (leads.filter(month => month.number === lastMonthNum - 1)[0].value) * 100) - 100).toFixed(0);
                this.setState(state => ({
                    lastMonthNum,
                    reports: {
                        ...state.reports,
                        leads: {
                            data: leads,
                            cumulativeData,
                            lastMonthVariance
                        },
                    },
                }));
            });

        // fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/report/leadConversion/${lastDayOfLastMonth}`)
        //     .then((leadConversion) => {
        //         const monthNumbers = leadConversion.map(month => month.number);
        //         const lastMonthNum = Math.max(...monthNumbers);
        //         const cumulativeData = [];
        //         leadConversion.map(month => (month.value)).reduce((a, b, i) => { return cumulativeData[i] = (a + b) }, 0);
        //         const lastMonthVariance = (((leadConversion.filter(month => month.number == lastMonthNum)[0].value) / (leadConversion.filter(month => month.number === lastMonthNum - 1)[0].value) * 100) - 100).toFixed(0);
        //         this.setState(state => ({
        //             lastMonthNum,
        //             reports: {
        //                 ...state.reports,
        //                 leadConversion: {
        //                     data: leadConversion,
        //                     cumulativeData,
        //                     lastMonthVariance
        //                 },
        //             },
        //         }));
        //     });
        //
        // fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/report/netPayments/${lastDayOfLastMonth}`)
        //     .then((netPayments) => {
        //         const monthNumbers = leadConversion.map(month => month.number);
        //         const lastMonthNum = Math.max(...monthNumbers);
        //         const cumulativeData = [];
        //         netPayments.map(month => (month.value)).reduce((a, b, i) => { return cumulativeData[i] = (a + b) }, 0);
        //         console.log(cumulativeData);
        //         const lastMonthVariance = (((netPayments.filter(month => month.number == lastMonthNum)[0].value) / (netPayments.filter(month => month.number === lastMonthNum - 1)[0].value) * 100) - 100).toFixed(0);
        //         this.setState(state => ({
        //             reports: {
        //                 ...state.reports,
        //                 netPayments: {
        //                     data: netPayments,
        //                     cumulativeData,
        //                     lastMonthVariance
        //                 },
        //             },
        //         }));
        //     });

            // fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/report/netPayments/weekly/${lastDayOfLastWeek}`)
            //     .then((netPayments) => {
            //         const weekDates = agreements.map(week => week.date);
            //         const lastWeekDate = Math.max(...monthNumbers);
            //         const cumulativeData = [];
            //         netPayments.map(week => (week.value)).reduce((a, b, i) => { return cumulativeData[i] = (a + b) }, 0);
            //         console.log(cumulativeData);
            //         const lastWeekVariance = (((netPayments.filter(week => week.number == lastWeekNum)[0].value) / (netPayments.filter(week => week.number === lastWeekNum - 1)[0].value) * 100) - 100).toFixed(0);
            //         this.setState(state => ({
            //             reports: {
            //                 ...state.reports,
            //                 netPaymentsWeek: {
            //                     data: netPayments,
            //                     cumulativeData,
            //                     lastWeekVariance
            //                 },
            //             },
            //         }));
            //     });

            fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/report/netAgreementValue/${lastDayOfLastMonth}`)
                .then((netAgreementValue) => {
                    const monthNumbers = netAgreementValue.map(month => month.number);
                    const lastMonthNum = Math.max(...monthNumbers);
                    const cumulativeData = [];
                    netAgreementValue.map(month => (month.value)).reduce((a, b, i) => { return cumulativeData[i] = (a + b) }, 0);
                    const lastMonthVariance = (((netAgreementValue.filter(month => month.number == lastMonthNum)[0].value) / (netAgreementValue.filter(month => month.number === lastMonthNum - 1)[0].value) * 100) - 100).toFixed(0);
                    this.setState(state => ({
                        lastMonthNum,
                        reports: {
                            ...state.reports,
                            netAgreementValue: {
                                data: netAgreementValue,
                                cumulativeData,
                                lastMonthVariance
                            },
                        },
                    }));
                });
            fetchJson(`https://api.portal2.payweeklycarpets.co.uk/core/report/netAgreementValueBalance/${lastDayOfLastMonth}`)
                .then((netAgreementValueBalance) => {
                    const monthNumbers = netAgreementValueBalance.map(month => month.number);
                    const lastMonthNum = Math.max(...monthNumbers);
                    const cumulativeData = [];
                    netAgreementValueBalance.map(month => (month.value)).reduce((a, b, i) => { return cumulativeData[i] = (a + b) }, 0);
                    const lastMonthVariance = (((netAgreementValueBalance.filter(month => month.number == lastMonthNum)[0].value) / (netAgreementValueBalance.filter(month => month.number === lastMonthNum - 1)[0].value) * 100) - 100).toFixed(0);
                    this.setState(state => ({
                        lastMonthNum,
                        reports: {
                            ...state.reports,
                            netAgreementValueBalance: {
                                data: netAgreementValueBalance,
                                cumulativeData,
                                lastMonthVariance
                            },
                        },
                    }));
                });

    }


    render() {
        const { classes } = this.props;
        const {
            reports,
            lastMonthNum,
            lastMonthStr,
            lastWeekNum,
            lastWeekStr,
            summary,
        } = this.state;

        return (
            <Layout pageTitle="Report - Accounts Overview">
                <CssBaseline />
                {summary != {} && (
                    <React.Fragment>
                        <div className={classes.summaryTitle}>
                            Sales
                        </div>
                        <Grid
                            className={classes.gridContainer}
                            container
                            spacing={32}
                        >
                            <Grid item xs={4} className={classes.summarySubTitle}>
                                Total Sales amount
                            </Grid>
                            <Grid item xs={8}>
                                <div className={classes.summaryValue}>
                                    £{NumberWithCommas((summary.SaleGrossTotalPlanAmount || 0).toFixed(2))}
                                </div>
                                <div className={classes.summaryNote}>
                                    Total value of non-cancelled Sales.
                                </div>
                            </Grid>
                            <Grid item xs={4} className={classes.summarySubTitle}>
                                Total Sales payments
                            </Grid>
                            <Grid item xs={8}>
                                <div className={classes.summaryValue}>
                                    £{NumberWithCommas((summary.saleGrossTotalPaidAmount || 0).toFixed(2))}
                                </div>
                                <div className={classes.summaryNote}>
                                    Total amount paid on Sales.
                                </div>
                            </Grid>
                        </Grid>
                        <div className={classes.summaryTitle}>
                            Continuous Rental
                        </div>
                        <Grid
                            className={classes.gridContainer}
                            container
                            spacing={32}
                        >
                            <Grid item xs={4} className={classes.summarySubTitle}>
                                Total Continous Rental payments
                            </Grid>
                            <Grid item xs={8}>
                                <div className={classes.summaryValue}>
                                    £{NumberWithCommas((summary.continuousGrossTotalPaidAmount || 0).toFixed(2))}
                                </div>
                                <div className={classes.summaryNote}>
                                    Total amount paid on continous rental agreements.
                                </div>
                            </Grid>
                        </Grid>

                        <div className={classes.summaryTitle}>
                            Repayment Rental
                        </div>
                        <Grid
                            className={classes.gridContainer}
                            container
                            spacing={32}
                        >
                            <Grid item xs={4} className={classes.summarySubTitle}>
                                Total gross Repayment Rental agreement value
                            </Grid>
                            <Grid item xs={8}>
                                <div className={classes.summaryValue}>
                                    £{NumberWithCommas((summary.repaymentGrossTotalPlanAmount || 0).toFixed(2))}
                                </div>
                                <div className={classes.summaryNote}>
                                    Total GROSS value of non-cancelled repayment rental agreements. Includes delivery and agent fees, if collected by {summary.companyName}, where applicable.
                                </div>
                            </Grid>
                            <Grid item xs={4} className={classes.summarySubTitle}>
                                Total gross Repayment Rental payments
                            </Grid>
                            <Grid item xs={8}>
                                <div className={classes.summaryValue}>
                                    £{NumberWithCommas((summary.repaymentGrossTotalPaidAmount || 0).toFixed(2))}
                                </div>
                                <div className={classes.summaryNote}>
                                    Total GROSS amount paid on repayment rental agreements. Includes delivery and agent fees, if collected by {summary.companyName}, where applicable.
                                </div>
                            </Grid>
                            <Grid item xs={4} className={classes.summarySubTitle}>
                                Total net Repayment Rental agreement value
                            </Grid>
                            <Grid item xs={8}>
                                <div className={classes.summaryValue}>
                                    £{NumberWithCommas((summary.repaymentNetTotalPlanAmount || 0).toFixed(2))}
                                </div>
                                <div className={classes.summaryNote}>
                                    Total NET value of non-cancelled repayment rental agreements. Delivery and agent fees, if collected by {summary.companyName}, deducted where applicable.
                                </div>
                            </Grid>
                            <Grid item xs={4} className={classes.summarySubTitle}>
                                Total net Repayment Rental payments
                            </Grid>
                            <Grid item xs={8}>
                                <div className={classes.summaryValue}>
                                    £{NumberWithCommas((summary.repaymentNetTotalPaidAmount || 0).toFixed(2))}
                                </div>
                                <div className={classes.summaryNote}>
                                    Total NET amount paid on repayment rental agreements. Delivery and agent fees, if collected by {summary.companyName}, deducted where applicable.
                                </div>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}

                <Grid
                    className={classes.gridContainer}
                    container
                    spacing={32}
                >

                <Grid item xs={4}>
                    {reports.netPaymentsWeek
                        && (
                    <NakedTile
                        tileTitle="Net Weekly Payments"
                        style
                    >
                        <Grid
                            className={classes.gridContainer}
                            container
                            spacing={0}
                        >
                        <Grid item xs={12}>
                            <div className={classes.headlineText}>
                                <HybridReportTextField
                                    label="Total"
                                    total
                                    text={`£${NumberWithCommas(reports.netPaymentsWeek.data.reduce((a, b) => a + b.value, 0).toFixed(2))}`}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <div className={classes.headlineText}>
                                <HybridReportTextField
                                    label={lastWeekStr}
                                    monthly
                                    text={`£${NumberWithCommas(reports.netPaymentsWeek.data.filter(week => week.number == lastWeekNum)[0].value.toFixed(2))}`}
                                />
                            </div>
                        </Grid>
                            <Grid
                                item xs={3}
                                >
                                <HybridReportTextField
                                    label="Variance"
                                    monthly
                                    icon={reports.netPaymentsWeek.lastWeekVariance > 0 ? 'up' : 'down'}
                                    text={`${reports.netPaymentsWeek.lastWeekVariance}%`}
                                />
                            </Grid>
                        </Grid>
                        <Line
                            options={options2}
                            data={{
                                labels: reports.netPaymentsWeek.data.map(week => (week.name)),
                                datasets: [{
                                    data: reports.netPaymentsWeek.data.map(week => (week.value)),
                                    fill: false,
                                    borderColor: 'rgba(249, 170, 51, 1)',
                                    yAxisID: 'A',
                                }, {
                                    data: reports.netPaymentsWeek.cumulativeData,
                                    fill: true,
                                    borderColor: 'rgba(95, 144, 187, 1)',
                                    yAxisID: 'B',
                                }],
                            }}
                        />
                    </NakedTile>
                    )}
                </Grid>


                <Grid item xs={4}>
                    {reports.netPayments
                        && (
                    <NakedTile
                        tileTitle="Net Payments"
                        style
                    >
                        <Grid
                            className={classes.gridContainer}
                            container
                            spacing={0}
                        >
                        <Grid item xs={12}>
                            <div className={classes.headlineText}>
                                <HybridReportTextField
                                    label="Total"
                                    total
                                    text={`£${NumberWithCommas(reports.netPayments.data.reduce((a, b) => a + b.value, 0).toFixed(2))}`}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <div className={classes.headlineText}>
                                <HybridReportTextField
                                    label={lastMonthStr}
                                    monthly
                                    text={`£${NumberWithCommas(reports.netPayments.data.filter(month => month.number == lastMonthNum)[0].value.toFixed(2))}`}
                                />
                            </div>
                        </Grid>
                            <Grid
                                item xs={3}
                                >
                                <HybridReportTextField
                                    label="Variance"
                                    monthly
                                    icon={reports.netPayments.lastMonthVariance > 0 ? 'up' : 'down'}
                                    text={`${reports.netPayments.lastMonthVariance}%`}
                                />
                            </Grid>
                        </Grid>
                        <Line
                            options={options2}
                            data={{
                                labels: reports.netPayments.data.map(month => (month.name)),
                                datasets: [{
                                    data: reports.netPayments.data.map(month => (month.value)),
                                    fill: false,
                                    borderColor: 'rgba(249, 170, 51, 1)',
                                    yAxisID: 'A',
                                }, {
                                    data: reports.netPayments.cumulativeData,
                                    fill: true,
                                    borderColor: 'rgba(95, 144, 187, 1)',
                                    yAxisID: 'B',
                                }],
                            }}
                        />
                    </NakedTile>
                    )}
                </Grid>
                <Grid item xs={4}>
                    {reports.netAgreementValue
                        && (
                    <NakedTile
                        tileTitle="Net Agreements Value"
                        style
                    >
                        <Grid
                            className={classes.gridContainer}
                            container
                            spacing={0}
                        >
                        <Grid item xs={12}>
                            <div className={classes.headlineText}>
                                <HybridReportTextField
                                    label="Total"
                                    total
                                    text={`£${NumberWithCommas(reports.netAgreementValue.data.reduce((a, b) => a + b.value, 0).toFixed(2))}`}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <div className={classes.headlineText}>
                                <HybridReportTextField
                                    label={`New ${lastMonthStr}`}
                                    monthly
                                    text={`£${NumberWithCommas(reports.netAgreementValue.data.filter(month => month.number == lastMonthNum)[0].value.toFixed(2))}`}
                                />
                            </div>
                        </Grid>
                            <Grid
                                item xs={3}
                                >
                                <HybridReportTextField
                                    label="Variance"
                                    monthly
                                    icon={reports.netAgreementValue.lastMonthVariance > 0 ? 'up' : 'down'}
                                    text={`${reports.netAgreementValue.lastMonthVariance}%`}
                                />
                            </Grid>
                        </Grid>
                        <Line
                            options={options2}
                            data={{
                                labels: reports.netAgreementValue.data.map(month => (month.name)),
                                datasets: [{
                                    data: reports.netAgreementValue.data.map(month => (month.value)),
                                    fill: false,
                                    borderColor: 'rgba(249, 170, 51, 1)',
                                    yAxisID: 'A',
                                }, {
                                    data: reports.netAgreementValue.cumulativeData,
                                    fill: true,
                                    borderColor: 'rgba(95, 144, 187, 1)',
                                    yAxisID: 'B',
                                }],
                            }}
                        />
                    </NakedTile>
                    )}
                </Grid>
                <Grid item xs={4}>
                    {reports.netAgreementValueBalance
                        && (
                    <NakedTile
                        tileTitle="Net Agreements Value Balance"
                        style
                    >
                        <Grid
                            className={classes.gridContainer}
                            container
                            spacing={0}
                        >
                        <Grid item xs={12}>
                            <div className={classes.headlineText}>
                                <HybridReportTextField
                                    label="Total"
                                    total
                                    text={`£${NumberWithCommas(reports.netAgreementValueBalance.data.reduce((a, b) => a + b.value, 0).toFixed(2))}`}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <div className={classes.headlineText}>
                                <HybridReportTextField
                                    label={`New ${lastMonthStr}`}
                                    monthly
                                    text={`£${NumberWithCommas(reports.netAgreementValueBalance.data.filter(month => month.number == lastMonthNum)[0].value.toFixed(2))}`}
                                />
                            </div>
                        </Grid>
                            <Grid
                                item xs={3}
                                >
                                <HybridReportTextField
                                    label="Variance"
                                    monthly
                                    icon={reports.netAgreementValueBalance.lastMonthVariance > 0 ? 'up' : 'down'}
                                    text={`${reports.netAgreementValueBalance.lastMonthVariance}%`}
                                />
                            </Grid>
                        </Grid>
                        <Line
                            options={options2}
                            data={{
                                labels: reports.netAgreementValueBalance.data.map(month => (month.name)),
                                datasets: [{
                                    data: reports.netAgreementValueBalance.data.map(month => (month.value)),
                                    fill: false,
                                    borderColor: 'rgba(249, 170, 51, 1)',
                                    yAxisID: 'A',
                                }, {
                                    data: reports.netAgreementValueBalance.cumulativeData,
                                    fill: true,
                                    borderColor: 'rgba(95, 144, 187, 1)',
                                    yAxisID: 'B',
                                }],
                            }}
                        />
                    </NakedTile>
                    )}
                </Grid>

                <Grid item xs={4}>
                    {reports.agreements
                        && (
                    <NakedTile
                        tileTitle="Agreements"
                        style
                    >
                        <Grid
                            className={classes.gridContainer}
                            container
                            spacing={0}
                        >
                        <Grid item xs={12}>
                            <div className={classes.headlineText}>
                                <HybridReportTextField
                                    label="Total"
                                    total
                                    text={`${NumberWithCommas(reports.agreements.data.reduce((a, b) => a + b.value, 0).toFixed(0))}`}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <div className={classes.headlineText}>
                                <HybridReportTextField
                                    label={`New ${lastMonthStr}`}
                                    monthly
                                    text={`${NumberWithCommas(reports.agreements.data.filter(month => month.number == lastMonthNum)[0].value.toFixed(0))}`}
                                />
                            </div>
                        </Grid>
                            <Grid
                                item xs={3}
                                >
                                <HybridReportTextField
                                    label="Variance"
                                    monthly
                                    icon={reports.agreements.lastMonthVariance > 0 ? 'up' : 'down'}
                                    text={`${reports.agreements.lastMonthVariance}%`}
                                />
                            </Grid>
                        </Grid>
                        <Line
                            options={options2}
                            data={{
                                labels: reports.agreements.data.map(month => (month.name)),
                                datasets: [{
                                    data: reports.agreements.data.map(month => (month.value)),
                                    fill: false,
                                    borderColor: 'rgba(249, 170, 51, 1)',
                                    yAxisID: 'A',
                                }, {
                                    data: reports.agreements.cumulativeData,
                                    fill: true,
                                    borderColor: 'rgba(95, 144, 187, 1)',
                                    yAxisID: 'B',
                                }],
                            }}
                        />
                    </NakedTile>
                    )}
                </Grid>

                <Grid item xs={4}>
                    {reports.leads
                        && (
                    <NakedTile
                        tileTitle="Leads"
                        style
                    >
                        <Grid
                            className={classes.gridContainer}
                            container
                            spacing={0}
                        >
                        <Grid item xs={12}>
                            <div className={classes.headlineText}>
                                <HybridReportTextField
                                    label="Total"
                                    total
                                    text={`${NumberWithCommas(reports.leads.data.reduce((a, b) => a + b.value, 0).toFixed(0))}`}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <div className={classes.headlineText}>
                                <HybridReportTextField
                                    label={`New ${lastMonthStr}`}
                                    monthly
                                    text={`${NumberWithCommas(reports.leads.data.filter(month => month.number == lastMonthNum)[0].value.toFixed(0))}`}
                                />
                            </div>
                        </Grid>
                            <Grid
                                item xs={3}
                                >
                                <HybridReportTextField
                                    label="Variance"
                                    monthly
                                    icon={reports.leads.lastMonthVariance > 0 ? 'up' : 'down'}
                                    text={`${reports.leads.lastMonthVariance}%`}
                                />
                            </Grid>
                        </Grid>
                        <Line
                            options={options2}
                            data={{
                                labels: reports.leads.data.map(month => (month.name)),
                                datasets: [{
                                    data: reports.leads.data.map(month => (month.value)),
                                    fill: false,
                                    borderColor: 'rgba(249, 170, 51, 1)',
                                    yAxisID: 'A',
                                }, {
                                    data: reports.leads.cumulativeData,
                                    fill: true,
                                    borderColor: 'rgba(95, 144, 187, 1)',
                                    yAxisID: 'B',
                                }],
                            }}
                    />
                    </NakedTile>
                    )}
                </Grid>

                <Grid item xs={4}>
                    {reports.leadConversion
                        && (
                    <NakedTile
                        tileTitle="Lead Conversion"
                        style
                    >
                        <Grid
                            className={classes.gridContainer}
                            container
                            spacing={0}
                        >
                        <Grid item xs={12}>
                            <div className={classes.headlineText}>
                                <HybridReportTextField
                                    label="Total"
                                    total
                                    text={`${NumberWithCommas(
                                            parseInt(
                                                (reports.leadConversion.data.reduce((a, b) => a + b.converted, 0))
                                                / (reports.leadConversion.data.reduce((a, b) => a + b.total, 0))
                                                * 100
                                                ,0)
                                            )
                                        }%`}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <div className={classes.headlineText}>
                                <HybridReportTextField
                                    label={`New ${lastMonthStr}`}
                                    monthly
                                    text={`${NumberWithCommas(reports.leadConversion.data.filter(month => month.number == lastMonthNum)[0].value.toFixed(0))}`}
                                />
                            </div>
                        </Grid>
                            <Grid
                                item xs={3}
                                >
                                <HybridReportTextField
                                    label="Variance"
                                    monthly
                                    icon={reports.leadConversion.lastMonthVariance > 0 ? 'up' : 'down'}
                                    text={`${reports.leadConversion.lastMonthVariance}%`}
                                />
                            </Grid>
                        </Grid>
                        <Line
                            options={options2}
                            data={{
                                labels: reports.leadConversion.data.map(month => (month.name)),
                                datasets: [{
                                    data: reports.leadConversion.data.map(month => (month.value)),
                                    fill: false,
                                    borderColor: 'rgba(249, 170, 51, 1)',
                                    yAxisID: 'A',
                                }, {
                                    data: reports.leadConversion.cumulativeData,
                                    fill: true,
                                    borderColor: 'rgba(95, 144, 187, 1)',
                                    yAxisID: 'B',
                                }],
                            }}
                        />
                    </NakedTile>
                    )}
                </Grid>

                </Grid>

            </Layout>
        );
    }
}

export default withStyles(styles)(ReportAccountsOverview);
