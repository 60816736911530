import React from 'react';
import {
    CircularProgress,
    Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tile from '../containers/tile';
import HybridTextField from './hybridTextField';
import DeliveryNoteSummaryTable from './deliveryNoteSummaryTable';

const styles = theme => ({
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
});

class DeliveryNoteDetail extends React.Component {
    state = {

    }

    render() {
        const {
            classes,
            deliveryNote,
            deliveryNoteItems,
        } = this.props;

        return (
            <Tile
                tileTitle="SUMMARY"
                style
            >
                { deliveryNote !== null
                    && deliveryNoteItems !== null
                    ? (
                        <React.Fragment>
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={6}>
                                            <HybridTextField label="Delivery Date" text={deliveryNote.deliveryDate || ' '} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <HybridTextField label="Delivery Branch" text={deliveryNote.deliveryBranchName || ' '} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <Grid item xs={6}>
                                            <HybridTextField label="Supplier" text={deliveryNote.supplierName || ' '} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <HybridTextField label="Reference" text={deliveryNote.reference || ' '} />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <HybridTextField label="Processed by" text={deliveryNote.processedByFullName || ' '} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <DeliveryNoteSummaryTable
                                        deliveryNoteItems={deliveryNoteItems}
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )
                }
            </Tile>
        );
    }
}

const deliveryNoteShape = {
    code: PropTypes.number,
    reference: PropTypes.string,
    supplierCode: PropTypes.number,
    supplierName: PropTypes.string,
    deliveryDate: PropTypes.string,
    processedByContactCode: PropTypes.number,
    processedByFullName: PropTypes.string,
    deliveryBranchCode: PropTypes.number,
    deliveryBranchName: PropTypes.string,
};

const deliveryNoteItemsShape = {
    code: PropTypes.number,
    colour: PropTypes.string,
    length: PropTypes.number,
    manufacturerName: PropTypes.string,
    modelNo: PropTypes.string,
    shortDescription: PropTypes.string,
    width: PropTypes.number,
};

DeliveryNoteDetail.propTypes = {
    classes: PropTypes.object.isRequired,
    deliveryNote: PropTypes.shape(deliveryNoteShape).isRequired,
    deliveryNoteItems: PropTypes.arrayOf(PropTypes.shape(deliveryNoteItemsShape)).isRequired,
};

export default withStyles(styles)(DeliveryNoteDetail);
