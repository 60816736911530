import React from 'react';
import {
    withStyles,
} from '@material-ui/core/styles';
import {
    AppBar,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Tab,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    Tabs,
    TextField,
    Typography,
} from '@material-ui/core';
import {
    Link,
    DirectLink,
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller,
} from 'react-scroll';
import { withSnackbar } from 'notistack';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment-timezone';
import LensIcon from '@material-ui/icons/Lens';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import HybridTextField from '../hybridTextField';
import { scheduleFrequencies, scheduleWeekdays } from '../../utilities/adelante';
import styles from './styles';
import Tile from '../../containers/tile';

const CustomTableCell = withStyles(theme => ({
    progressContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 16,
        height: 48,
    },
    body: {
        fontSize: 14,
        paddingLeft: 16,
    },
}))(TableCell);

class CustomerLeads extends React.Component {
    state = {
        value: 0,
        convertStage: 'type',
        showUpdateLeadDialog: false,
        showNewLeadDialog: false,
        showConvertLeadDialog: false,
        showDepositPaymentDialog: false,
        disableSaveLeadUpdateButton: false,
        disableSaveNewLeadButton: false,
        disableConvertNextButton: false,
        disableConvertConfirmButton: false,
        disableSubmitDepositPaymentButton: false,
        leadForm: {},
        convertForm: {
            type: 'Continuous',
            products: [],
            scheduleWeekdayCode: 1,
            scheduleDay: null,
            scheduleInterval: 1,
            firstPaymentDate: null,
            recurringPaymentAmount: null,
            agentCollectedFee: false,
        },
        newLeadForm: {
            campaignCode: undefined,
        },
        convertStages: [
            'type',
            'products',
            'plan',
            'deposit',
            // 'confirm',
        ],
        convertSaleStages: [
            'type',
            'products',
            'sale',
            'payment',
            // 'confirm',
        ],
        selectedProduct: {},
        depositPaymentForm: {
            amount: '',
        },
    }

    async componentDidMount() {
        await this.changeLeadTab();
    }

    componentDidUpdate(prevProps) {
        if (this.props.requestedLeadTab !== prevProps.requestedLeadTab) {
            this.changeLeadTab();
        }
    }

    changeLeadTab = async () => {
        if (this.props.requestedLeadTab > 0 || this.props.requestedLeadTab === 0) {
            await this.scrollToComponentTop();
            console.log('changingLeadTab');
            this.setState({
                value: this.props.requestedLeadTab,
            });
        }
    }

    scrollToComponentTop = () => {
        scroller.scrollTo('leadsTop', {
            duration: 800,
            delay: 100,
            smooth: 'easeInOutQuart',
            offset: -90,
        });
    }

    capitaliseFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

    updateLeadData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'note':
            formattedValue = this.capitaliseFirstLetter(value);
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState({
            leadForm: {
                ...this.state.leadForm,
                [name]: formattedValue,
            },
        });

        // console.log({
        //     customerForm: {
        //         ...this.state.customerForm,
        //         [name]: formattedValue,
        //     },
        // })
        // console.log(formattedValue, name);
        // console.log(this.state.customerForm);
    }

    updateNewLeadData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'campaignCode':
            formattedValue = parseInt(value);
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState({
            newLeadForm: {
                ...this.state.newLeadForm,
                [name]: formattedValue,
            },
        });
    }

    handleFirstPaymentDateChange = (chosenDate) => {
        let scheduleDay = null;
        if (this.state.convertForm.scheduleFrequency === 'Monthly') {
            scheduleDay = moment(chosenDate).format('D');
            console.log('scheduleDay:', scheduleDay);
        } else {
            scheduleDay = 0;
        }
        this.setState(state => ({
            convertForm: {
                ...state.convertForm,
                scheduleDay,
                firstPaymentDate: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    }

    handleAgentCollectedFee = (event) => {
        this.setState({
            convertForm: {
                ...this.state.convertForm,
                agentCollectedFee: event.target.checked,
            },
        });
    }

    /*
      Shedule frequency = weekly or Monthly
      Interval

      => weekly repayment rate

      const scheduleFrequency = name === 'scheduleFrequency'
        ? value
        : this.state.convertForm.scheduleInterval
      const weeklyRentalAmount = name === 'weeklyRentalAmount'
        ? value
        : this.state.convertForm.weeklyRentalAmount

      switch (scheduleFrequency) {
        case 'Weekly':
          recurringPaymentAmount = (value * interval).toFixed(2);
        case 'Monthly':
          recurringPaymentAmount = (value * 52 / 12).toFixed(2);
      }

      if(value === 'scheduleFrequency' && this.state.convertForm.scheduleFrequency !== value) {
        scheduleDay = 0;
      }

    */

    updateConvertData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        let recurringPaymentAmount;
        let scheduleDay;
        switch (true) {
        case (name === 'depositPayment'):
            this.setState(state => ({
                convertForm: {
                    ...state.convertForm,
                    depositPaymentType: null,
                },
            }));
            formattedValue = value;
            break;
        case (name === 'scheduleFrequency' && value === 'Weekly'):
            recurringPaymentAmount = ((this.state.convertForm.type === 'Continuous' ? this.state.convertForm.weeklyRentalAmount : this.state.convertForm.weeklyRepaymentAmount) * this.state.convertForm.scheduleInterval).toFixed(2);
            scheduleDay = 0;
            formattedValue = value;
            break;
        case (name === 'scheduleFrequency' && value === 'Monthly'):
            recurringPaymentAmount = ((this.state.convertForm.type === 'Continuous' ? this.state.convertForm.weeklyRentalAmount : this.state.convertForm.weeklyRepaymentAmount) * 52 / 12).toFixed(2);
            scheduleDay = this.state.convertForm.firstPaymentDate ? moment(this.state.convertForm.firstPaymentDate).format('D') : 0;
            formattedValue = value;
            break;
        case (name === 'weeklyRentalAmount' && this.state.convertForm.scheduleFrequency === 'Weekly'):
            recurringPaymentAmount = (value * this.state.convertForm.scheduleInterval).toFixed(2);
            formattedValue = value;
            break;
        case (name === 'weeklyRentalAmount' && this.state.convertForm.scheduleFrequency === 'Monthly'):
            recurringPaymentAmount = (value * 52 / 12).toFixed(2);
            formattedValue = value;
            break;
        case (name === 'weeklyRepaymentAmount' && this.state.convertForm.scheduleFrequency === 'Weekly'):
            recurringPaymentAmount = (value * this.state.convertForm.scheduleInterval).toFixed(2);
            formattedValue = value;
            break;
        case (name === 'weeklyRepaymentAmount' && this.state.convertForm.scheduleFrequency === 'Monthly'):
            recurringPaymentAmount = (value * 52 / 12).toFixed(2);
            formattedValue = value;
            break;
        case (name === 'scheduleInterval'):
            recurringPaymentAmount = ((this.state.convertForm.type === 'Continuous' ? this.state.convertForm.weeklyRentalAmount : this.state.convertForm.weeklyRepaymentAmount) * value).toFixed(2);
            formattedValue = value;
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;
        this.setState(state => ({
            convertForm: {
                ...state.convertForm,
                scheduleDay: scheduleDay || state.convertForm.scheduleDay,
                recurringPaymentAmount: recurringPaymentAmount || state.convertForm.recurringPaymentAmount,
                [name]: formattedValue,
            },
        }));
    }

    updateSelectedProduct = (event) => {
        const { name, value } = event.target;
        const selectedProduct = this.props.convertLeadProducts.find(product => product.code === value);
        selectedProduct.uid = Math.random().toString(36).substr(2, 9);
        this.setState({
            selectedProduct,
        });
    }

    handleUpdateLeadClick = () => {
        this.setState(state => ({
            leadForm: {
                code: this.props.leads[state.value].code,
                status: this.props.leads[state.value].status,
            },
            showUpdateLeadDialog: true,
        }));
    }

    handleNewLeadClick = async () => {
        await this.props.fetchCampaigns();
        this.setState({
            showNewLeadDialog: true,
        });
    }

    handleConvertLeadClick = () => {
        this.setState(state => ({
            convertForm: {
                ...state.convertForm,
                code: this.props.leads[state.value].code,
                status: this.props.leads[state.value].status,
            },
            showConvertLeadDialog: true,
        }));
    }

    handleCloseUpdateLeadDialogClick = () => {
        this.setState({ showUpdateLeadDialog: false });
    }

    handleCloseNewLeadDialogClick = () => {
        this.setState({ showNewLeadDialog: false });
    }

    handleCloseConvertLeadDialogClick = () => {
        this.setState({
            convertStage: 'type',
            // Not sure how this will effect deposit payments etc if form cleared on close.
            // convertForm: {
            //     type: 'Continuous',
            //     products: [],
            //     scheduleWeekdayCode: 1,
            //     scheduleDay: 1,
            //     scheduleInterval: 1,
            // },
            showConvertLeadDialog: false,
        });
    }

    handleCloseDepositPaymentDialogClick = () => {
        this.setState({ showDepositPaymentDialog: false });
    }

    iconStatus = (statusString) => {
        switch (statusString) {
        case 'New': {
            return (this.props.classes.notSetIcon);
        }
        case 'Hot': {
            return (this.props.classes.hotIcon);
        }
        case 'Warm': {
            return (this.props.classes.warmIcon);
        }
        case 'Cold': {
            return (this.props.classes.coldIcon);
        }
        case 'Finance': {
            return (this.props.classes.financeIcon);
        }
        default: {
            return (this.props.classes.notSetIcon);
        }
        }
    }

    handleSaveLeadUpdateClick = (event) => {
        event.preventDefault();
        this.saveUpdate();
        // console.log(this.state.leadForm);
        // this.setState({
        //     disableSaveLeadUpdateButton: true,
        // });
        // this.props.saveLeadUpdate(this.state.leadForm).then(() => {
        //     this.setState({
        //         showUpdateLeadDialog: false,
        //         disableSaveLeadUpdateButton: false,
        //     });
        //     this.props.enqueueSnackbar('Lead updated', { variant: 'success' });
        // }).catch(() => {
        //     console.log(this.props.errors);
        //     this.props.enqueueSnackbar('Lead update failed', { variant: 'error' });
        //     this.setState({
        //         disableSaveLeadUpdateButton: false,
        //     });
        // });
    }

    handleSaveNewLeadClick = (event) => {
        event.preventDefault();
        this.setState({
            disableSaveNewLeadButton: true,
        });
        this.props.saveNewLead(this.state.newLeadForm).then(() => {
            this.setState({
                showNewLeadDialog: false,
                disableSaveNewLeadButton: false,
            });
            this.props.enqueueSnackbar('New lead created', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Create new lead failed', { variant: 'error' });
            this.setState({
                disableSaveNewLeadButton: false,
            });
        });
    }

    saveUpdate = () => {
        this.setState({
            disableSaveLeadUpdateButton: true,
        });
        this.props.saveLeadUpdate(this.state.leadForm).then(() => {
            this.setState({
                showUpdateLeadDialog: false,
                disableSaveLeadUpdateButton: false,
            });
            this.props.enqueueSnackbar('Lead updated', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Lead update failed', { variant: 'error' });
            this.setState({
                disableSaveLeadUpdateButton: false,
            });
        });
    }

    handleLeadChange = (event, newValue) => {
        this.setState({ value: newValue });
    }

    handleConvertNextClick = async () => {
        let convertStageEnum;
        convertStageEnum = this.state.convertStages.indexOf(this.state.convertStage);
        convertStageEnum += 1;
        switch (this.state.convertStages[convertStageEnum]) {
        case 'products':
            this.setState({ disableConvertNextButton: true });
            await this.props.fetchConvertLeadProducts(this.state.convertForm.type);
            this.setState({
                disableConvertNextButton: false,
            });
            break;
        case 'plan':
            let sumDefaultWeeklyRentalAmount = 0;
            let sumDefaultWeeklyRepaymentAmount = 0;
            let sumDefaultDeposit = 0;
            let sumDefaultRepaymentAmount = 0;
            let sumDefaultDelivery = 0;
            let sumDefaultAgentFee = 0;
            this.setState({ disableConvertNextButton: true });
            this.state.convertForm.products.map((product) => {
                sumDefaultWeeklyRentalAmount += product.defaultWeeklyRentalAmount;
                sumDefaultWeeklyRepaymentAmount += product.defaultWeeklyRepaymentAmount;
                sumDefaultDeposit += product.newDefaultDeposit;
                sumDefaultDelivery += product.newDefaultDelivery;
                sumDefaultAgentFee += product.newDefaultAgentFee;
                sumDefaultRepaymentAmount += product.newDefaultRepaymentAmount;
            });
            this.setState(state => ({
                convertForm: {
                    ...state.convertForm,
                    weeklyRentalAmount: sumDefaultWeeklyRentalAmount.toFixed(2),
                    weeklyRepaymentAmount: sumDefaultWeeklyRepaymentAmount.toFixed(2),
                    depositAmount: sumDefaultDeposit.toFixed(2),
                    repaymentAmount: sumDefaultRepaymentAmount.toFixed(2),
                    deliveryAmount: sumDefaultDelivery.toFixed(2),
                    agentFee: sumDefaultAgentFee.toFixed(2),
                    agentCollectedFee: false,
                    // agentCollectedFeeCheckbox: false,
                },
                disableConvertNextButton: false,
            }));
            break;
        case 'sale':
            const sumSaleAmount = 0;
            // let sumDefaultWeeklyRentalAmount = 0;
            // let sumDefaultWeeklyRepaymentAmount = 0;
            // let sumDefaultDeposit = 0;
            // let sumDefaultRepaymentAmount = 0;
            // let sumDefaultDelivery = 0;
            // let sumDefaultAgentFee = 0;
            this.setState({ disableConvertNextButton: true });
            this.state.convertForm.products.map((product) => {
                sumDefaultWeeklyRentalAmount += product.defaultWeeklyRentalAmount;
                sumDefaultWeeklyRepaymentAmount += product.defaultWeeklyRepaymentAmount;
                sumDefaultDeposit += product.newDefaultDeposit;
                sumDefaultDelivery += product.newDefaultDelivery;
                sumDefaultAgentFee += product.newDefaultAgentFee;
                sumDefaultRepaymentAmount += product.newDefaultRepaymentAmount;
            });
            this.setState(state => ({
                convertForm: {
                    ...state.convertForm,
                    weeklyRentalAmount: sumDefaultWeeklyRentalAmount.toFixed(2),
                    weeklyRepaymentAmount: sumDefaultWeeklyRepaymentAmount.toFixed(2),
                    depositAmount: sumDefaultDeposit.toFixed(2),
                    repaymentAmount: sumDefaultRepaymentAmount.toFixed(2),
                    deliveryAmount: sumDefaultDelivery.toFixed(2),
                    agentFee: sumDefaultAgentFee.toFixed(2),
                    agentCollectedFee: false,
                    // agentCollectedFeeCheckbox: false,
                },
                disableConvertNextButton: false,
            }));
            break;
        }
        // if (this.state.convertStages[convertStageEnum] === 'products') {
        //     this.setState({ disableConvertNextButton: true });
        //     await this.props.fetchConvertLeadProducts();
        //     this.setState({
        //         disableConvertNextButton: false,
        //     });
        // }
        this.setState({
            convertStage: this.state.convertStages[convertStageEnum],
        });
    }

    handleConvertConfirmClick = () => {
        this.setState({
            disableConvertConfirmButton: true,
        });
        this.props.convertLead(this.state.convertForm).then((agreementCode) => {
            this.setState({
                showConvertLeadDialog: false,
                disableConvertConfirmButton: false,
            });
            switch (this.state.convertForm.depositPaymentType) {
            case 'overThePhone':
                // console.log(
                //     this.state.convertForm.depositAmount,
                //     this.state.convertForm.deliveryAmount,
                //     this.state.convertForm.agentCollectedFee,
                //     this.state.agentFee,
                //     (this.state.convertForm.agentCollectedFee ? this.state.convertForm.agentFee : 0),
                // );
                this.setState(state => ({
                    depositPaymentForm: {
                        agreementCode,
                        // amount: parseFloat(state.convertForm.depositAmount).toFixed(2),
                        amount: (parseFloat(state.convertForm.depositAmount) + parseFloat(state.convertForm.deliveryAmount) + (state.convertForm.agentCollectedFee ? parseFloat(state.convertForm.agentFee) : 0)).toFixed(2),
                    },
                    showDepositPaymentDialog: true,
                }));
                break;
            case 'linkByText':
                break;
            case 'linkByEmail':
                break;
            }
            this.props.enqueueSnackbar('Lead converted', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Lead convert failed', { variant: 'error' });
            this.setState({
                disableConvertConfirmButton: false,
            });
        });
    }

    handleAddProductClick = () => {
        this.setState(state => ({
            convertForm: {
                ...state.convertForm,
                products: [
                    ...state.convertForm.products,
                    state.selectedProduct,
                ],
            },
            selectedProduct: {
                ...state.selectedProduct,
                uid: Math.random().toString(36).substr(2, 9),
            },
        }));
    }

    removeConvertProduct = (uid) => {
        console.log(uid);
        const newProducts = [];
        this.state.convertForm.products.map((product) => {
            if (product.uid !== uid) {
                newProducts.push(product);
            }
        });
        this.setState(state => ({
            convertForm: {
                ...state.convertForm,
                products: newProducts,
            },
        }));
    }

    ordinalSuffixOf = (i) => {
        const j = i % 10;


        const k = i % 100;
        if (j == 1 && k != 11) {
            return `${i}st`;
        }
        if (j == 2 && k != 12) {
            return `${i}nd`;
        }
        if (j == 3 && k != 13) {
            return `${i}rd`;
        }
        return `${i}th`;
    }

    updateDepositPaymentData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'amount':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState({
            depositPaymentForm: {
                ...this.state.depositPaymentForm,
                [name]: formattedValue,
            },
        });
    }

    handleSubmitDepositPayment = (event) => {
        event.preventDefault();
        this.setState({
            disableSubmitDepositPaymentButton: true,
        });
        this.depositPayment(this.state.depositPaymentForm.agreementCode, this.state.depositPaymentForm.amount);
    }

    depositPayment = (agreementCode, amount) => {
        const agreement = this.props.customer.agreements.filter(depositAgreement => depositAgreement.code === agreementCode);
        this.props.manualPayment({
            agreementCode,
            amount: amount || agreement.depositAmount,
            paymentExpiry: 90,
            paymentReturnURL: `https://${window.location.hostname}/customer/${this.props.customer.code}?paymentType=deposit&agreementCode=${agreementCode}`,
        });
    }

    render() {
        const {
            campaigns,
            classes,
            convertLeadProducts,
            customer,
            errors,
            leads,
            requestedLeadTab,
        } = this.props;
        const {
            convertForm,
            convertStage,
            depositPaymentForm,
            disableConvertConfirmButton,
            disableConvertNextButton,
            disableSaveLeadUpdateButton,
            disableSaveNewLeadButton,
            disableSaveNewUpdateButton,
            disableSubmitDepositPaymentButton,
            leadForm,
            newLeadForm,
            selectedProduct,
            showConvertLeadDialog,
            showDepositPaymentDialog,
            showNewLeadDialog,
            showUpdateLeadDialog,
            value,
        } = this.state;

        return (
            <Tile
                tileTitle="Leads"
                allowCollapse
                initialCollapsedTile={requestedLeadTab < 0}
                tileMenuItems={[
                    ...(leads != null
                        ? [{
                            label: 'Update',
                            action: this.handleUpdateLeadClick,
                            section: 1,
                        }]
                        : []
                    ),
                    ...(leads != null
                        && (leads[value] || {}).status != 'Converted'
                        ? [{
                            label: 'Convert Lead',
                            action: this.handleConvertLeadClick,
                            section: 1,
                        }]
                        : []
                    ),
                    {
                        label: 'New Lead',
                        action: this.handleNewLeadClick,
                        section: 1,
                    },
                ]}
                scrollToMarker="leadsTop"
            >
                { leads !== null && requestedLeadTab !== null
                    ? (
                        <React.Fragment>
                            <AppBar
                                position="static"
                                className={classes.tabsAppBar}
                            >
                                <Tabs
                                    className={classes.tabs}
                                    value={value}
                                    onChange={this.handleLeadChange}
                                >
                                    {leads.map(lead => (
                                        <Tab
                                            className={classes.tab}
                                            key={lead.code}
                                            label={
                                                (
                                                    <React.Fragment>
                                                        {lead.campaignShortDescription}
                                                        {lead.status === 'Converted'
                                                            ? (
                                                                <CheckCircleIcon
                                                                    className={`${classes.hybridTextIconRight} ${classes.convertedIcon}`}
                                                                />
                                                            )
                                                            : (
                                                                <LensIcon
                                                                    className={`${classes.hybridTextIconRight} ${this.iconStatus(lead.status)}`}
                                                                />
                                                            )}
                                                    </React.Fragment>
                                                )
                                            }
                                        />
                                    ))}
                                </Tabs>
                            </AppBar>
                            <Paper className={classes.tabsPaper}>
                                <Grid container spacing={0}>
                                    <Grid container xs={6} spacing={0}>
                                        <Grid item xs={12}>
                                            <Grid container xs={12} spacing={24}>
                                                <Grid item>
                                                    <HybridTextField label="Campaign Title" text={(leads[value] || {}).campaignTitle} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container xs={12} spacing={24}>
                                                <Grid item>
                                                    <HybridTextField label="Campaign Description" text={(leads[value] || {}).campaignDescription} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container xs={12} spacing={24}>
                                                <Grid item>
                                                    <HybridTextField label="Source Platform" text={(leads[value] || {}).leadSourcePlatform} />
                                                </Grid>
                                                <Grid item>
                                                    <HybridTextField label="Source Media" text={(leads[value] || {}).leadSourceMedia} />
                                                </Grid>
                                                <Grid item>
                                                    <HybridTextField label="Source Type" text={(leads[value] || {}).leadSourceType} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container xs={12} spacing={24}>
                                                <Grid item>
                                                    <HybridTextField
                                                        label="Status"
                                                        icon={(leads[value] || {}).status === 'Converted'
                                                            ? (
                                                                <CheckCircleIcon
                                                                    className={`${classes.hybridTextIconLeft} ${classes.convertedIcon}`}
                                                                />
                                                            )
                                                            : (
                                                                <LensIcon
                                                                    className={`${classes.hybridTextIconLeft} ${this.iconStatus((leads[value] || {}).status)}`}
                                                                />
                                                            )}
                                                        text={(leads[value] || {}).status}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container xs={12} spacing={24}>
                                                <Grid item>
                                                    <HybridTextField label="Date" text={(leads[value] || {}).creationDate} />
                                                </Grid>
                                                <Grid item>
                                                    <HybridTextField label="Referral By" text={(leads[value] || {}).referralContactCode} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Dialog
                                open={showNewLeadDialog}
                                onClose={this.handleCloseNewLeadDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <React.Fragment>
                                            <Typography variant="h6" gutterBottom>
                                                New Lead
                                            </Typography>
                                            <DialogContentText>
                                                Select new lead...
                                            </DialogContentText>
                                            <form onSubmit={this.handleSaveNewLeadClick}>
                                                <FormControl
                                                    component="fieldset"
                                                    className={`${classes.formControl} ${classes.radioButtonsContainer}`}
                                                >
                                                    <RadioGroup
                                                        aria-label="newLeadCode"
                                                        name="campaignCode"
                                                        className={classes.group}
                                                        value={newLeadForm.campaignCode}
                                                        onChange={this.updateNewLeadData}
                                                    >
                                                        {campaigns.map(campaign => (
                                                            <FormControlLabel
                                                                key={campaign.code}
                                                                value={campaign.code}
                                                                control={<Radio />}
                                                                label={campaign.title}
                                                            />
                                                        ))}
                                                    </RadioGroup>
                                                </FormControl>
                                                <Button
                                                    variant="contained"
                                                    className={classes.dialogButton}
                                                    type="submit"
                                                    disabled={disableSaveNewLeadButton}
                                                >
                                                    SAVE
                                                </Button>
                                            </form>
                                        </React.Fragment>
                                    </div>
                                    <Divider />
                                </DialogContent>
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleCloseNewLeadDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showUpdateLeadDialog}
                                onClose={this.handleCloseUpdateLeadDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <React.Fragment>
                                            <Typography variant="h6" gutterBottom>
                                                Update Lead
                                            </Typography>
                                            <DialogContentText>
                                                Status "temperature" indicates the current likelyhood of lead conversion.
                                            </DialogContentText>
                                            <form onSubmit={this.handleSaveLeadUpdateClick}>
                                                <FormControl
                                                    className={classes.formControl}
                                                    variant="outlined"
                                                    required
                                                    error={!!errors.status}
                                                >
                                                    <InputLabel htmlFor="contactCode" className={classes.inputLabel}>Lead Status</InputLabel>
                                                    <Select
                                                        className={classes.statusSelect}
                                                        keyboard="true"
                                                        input={(
                                                            <OutlinedInput
                                                                onChange={this.updateLeadData}
                                                                name="status"
                                                                id="status"
                                                                value={leadForm.status || ''}
                                                            />
                                                        )}
                                                    >
                                                        <MenuItem
                                                            key="New"
                                                            value="New"
                                                        >
                                                        New
                                                        </MenuItem>
                                                        <MenuItem
                                                            key="Cold"
                                                            value="Cold"
                                                        >
                                                        Cold
                                                        </MenuItem>
                                                        <MenuItem
                                                            key="Warm"
                                                            value="Warm"
                                                        >
                                                        Warm
                                                        </MenuItem>
                                                        <MenuItem
                                                            key="Hot"
                                                            value="Hot"
                                                        >
                                                        Hot
                                                        </MenuItem>
                                                        <MenuItem
                                                            key="Finance"
                                                            value="Finance"
                                                        >
                                                        Finance
                                                        </MenuItem>
                                                        <MenuItem
                                                            key="Converted"
                                                            value="Converted"
                                                        >
                                                        Converted
                                                        </MenuItem>
                                                        <MenuItem
                                                            key="Closed"
                                                            value="Closed"
                                                        >
                                                        Closed
                                                        </MenuItem>
                                                        <MenuItem
                                                            key="Internal: Created"
                                                            value="Internal: Created"
                                                        >
                                                        Internal: Created
                                                        </MenuItem>
                                                    </Select>
                                                    <FormHelperText>{errors.status}</FormHelperText>
                                                </FormControl>
                                                <DialogContentText>
                                                    This note will be added to the contact history.
                                                    The current time, date, and your name will be
                                                    recorded automatically.
                                                </DialogContentText>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            onChange={this.updateLeadData}
                                                            className={classes.formControl}
                                                            name="note"
                                                            id="note"
                                                            label="Note"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            multiline
                                                            rows="6"
                                                            errors="!!errors.note"
                                                            helperText="errors.note"
                                                            autoFocus
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Button
                                                    variant="contained"
                                                    className={classes.dialogButton}
                                                    type="submit"
                                                    disabled={disableSaveLeadUpdateButton}
                                                >
                                                    SAVE
                                                </Button>
                                            </form>
                                        </React.Fragment>
                                    </div>
                                    <Divider />
                                </DialogContent>
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleCloseUpdateLeadDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showConvertLeadDialog}
                                onClose={this.handleCloseConvertLeadDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.convertDialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <React.Fragment>
                                            <Typography variant="h6" gutterBottom>
                                                Convert Lead
                                            </Typography>
                                            <form onSubmit={this.handleSubmitConvertLead}>
                                                {convertStage === 'type'
                                                    ? (
                                                        <React.Fragment>
                                                            <DialogContentText>
                                                            Select conversion type...
                                                            </DialogContentText>
                                                            <FormControl
                                                                component="fieldset"
                                                                className={`${classes.formControl} ${classes.radioButtonsContainer}`}
                                                            >
                                                                <RadioGroup
                                                                    aria-label="conversionType"
                                                                    name="type"
                                                                    className={classes.group}
                                                                    value={convertForm.type}
                                                                    onChange={this.updateConvertData}
                                                                >
                                                                    <FormControlLabel
                                                                        value="Continuous"
                                                                        control={<Radio />}
                                                                        label="Normal Rental"
                                                                    />
                                                                    <Typography
                                                                        paragraph
                                                                        color="textSecondary"
                                                                        className={classes.radioDescription}
                                                                    >
                                                                    Continuous regular payments until agreement cancelled. Customer never 'owns' rental products.
                                                                    </Typography>
                                                                    <FormControlLabel
                                                                        value="Repayment"
                                                                        control={<Radio />}
                                                                        label="Fixed-term Rental"
                                                                    />
                                                                    <Typography
                                                                        paragraph
                                                                        color="textSecondary"
                                                                        className={classes.radioDescription}
                                                                    >
                                                                    Inital agreement balance. Regular set payments until
                                                                    agreement balance is fully paid whereupon the agreement
                                                                    changes to 'Normal Rental' type with a £1.00 per year
                                                                    payment plan. Customer 'owns' rental products when the
                                                                    agreement balance is fully paid.
                                                                    </Typography>
                                                                    <FormControlLabel
                                                                        value="Sale"
                                                                        disabled
                                                                        control={<Radio />}
                                                                        label="Sale"
                                                                    />
                                                                    <Typography
                                                                        paragraph
                                                                        color="textSecondary"
                                                                        className={`${classes.radioDescription} ${classes.disabledText}`}
                                                                    >
                                                                    Customer purchases products outright with a single payment.
                                                                    </Typography>
                                                                    <FormControlLabel
                                                                        value="disabled"
                                                                        disabled
                                                                        control={<Radio />}
                                                                        label="3rd Party Hire Purchase"
                                                                    />
                                                                    <Typography
                                                                        paragraph
                                                                        color="textSecondary"
                                                                        className={`${classes.radioDescription} ${classes.disabledText}`}
                                                                    >
                                                                    Customer purchases products outright with a single payment
                                                                    from 3rd Party HP company.
                                                                    </Typography>
                                                                </RadioGroup>
                                                            </FormControl>
                                                            <Button
                                                                variant="contained"
                                                                className={classes.dialogButton}
                                                                onClick={this.handleConvertNextClick}
                                                                disabled={disableConvertNextButton}
                                                            >
                                                            NEXT
                                                            </Button>
                                                        </React.Fragment>
                                                    )
                                                    : null
                                                }
                                                {convertStage === 'products'
                                                    ? (
                                                        <React.Fragment>
                                                            <DialogContentText>
                                                            Select products...
                                                            </DialogContentText>
                                                            <FormControl
                                                                className={classes.formControl}
                                                                variant="outlined"
                                                                error={!!errors.status}
                                                            >
                                                                <InputLabel htmlFor="contactCode" className={classes.inputLabel}>Products</InputLabel>
                                                                <Select
                                                                    className={classes.selectMultiline}
                                                                    keyboard="true"
                                                                    input={(
                                                                        <OutlinedInput
                                                                            fullWidth
                                                                            onChange={this.updateSelectedProduct}
                                                                            name="selectedProduct"
                                                                            id="selectedProduct"
                                                                            value={selectedProduct.code || ''}
                                                                        />
                                                                    )}
                                                                >
                                                                    {convertLeadProducts.map(product => (
                                                                        <MenuItem
                                                                            key={product.code}
                                                                            value={product.code}
                                                                        >
                                                                            <div>
                                                                                <div
                                                                                    className={classes.menuItemText}
                                                                                >
                                                                                    {product.manufacturerModel}
                                                                                </div>
                                                                                <div
                                                                                    className={classes.menuItemSubtext}
                                                                                >
                                                                                    {product.shortDescription}
                                                                                </div>
                                                                            </div>
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                            <Button
                                                                onClick={this.handleAddProductClick}
                                                                variant="contained"
                                                                className={classes.dialogButton}
                                                                disabled={!selectedProduct.code}
                                                            >
                                                        ADD
                                                            </Button>

                                                            {convertForm.products.length === 0
                                                                ? (
                                                                    <DialogContentText className={classes.marginTop3}>
                                                                No products assigned.
                                                                    </DialogContentText>
                                                                )
                                                                : (
                                                                    <Grid container spacing={0} className={classes.marginTop3}>
                                                                        <Grid item xs={12}>
                                                                            <Table className={classes.table} padding="none">
                                                                                <TableHead className={classes.tableHead}>
                                                                                    <TableRow className={classes.tableHeadRow}>
                                                                                        <CustomTableCell className={classes.tableHead}>#</CustomTableCell>
                                                                                        <CustomTableCell className={classes.tableHead}>Product</CustomTableCell>
                                                                                        <CustomTableCell align="center" />
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {(convertForm.products || []).map((product, index) => (
                                                                                        <TableRow className={classes.row} key={product.uid}>
                                                                                            <CustomTableCell
                                                                                                className={classes.tableIndex}
                                                                                                component="th"
                                                                                                scope="row"
                                                                                            >
                                                                                                {index + 1}
                                                                                            </CustomTableCell>
                                                                                            <CustomTableCell align="left">
                                                                                                {
                                                                                                    (
                                                                                                        <React.Fragment>
                                                                                                            <div className={classes.menuItemText}>
                                                                                                                {product.manufacturerModel}
                                                                                                            </div>
                                                                                                            <div className={classes.menuItemSubtext}>
                                                                                                                {product.shortDescription}
                                                                                                            </div>
                                                                                                        </React.Fragment>
                                                                                                    )
                                                                                                }
                                                                                            </CustomTableCell>
                                                                                            <CustomTableCell align="right">
                                                                                                <IconButton
                                                                                                    onClick={() => this.removeConvertProduct(product.uid)}
                                                                                                    className={classes.button}
                                                                                                    aria-label="Remove product"
                                                                                                >
                                                                                                    <DeleteIcon />
                                                                                                </IconButton>
                                                                                            </CustomTableCell>
                                                                                        </TableRow>
                                                                                    ))}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                            <Button
                                                                variant="contained"
                                                                className={classes.dialogButton}
                                                                onClick={this.handleConvertNextClick}
                                                                disabled={convertForm.products.length === 0 || disableConvertNextButton}
                                                            >
                                                                NEXT
                                                            </Button>
                                                        </React.Fragment>
                                                    )
                                                    : null
                                                }

                                                {convertStage === 'plan'
                                                    ? (
                                                        <React.Fragment>
                                                            <DialogContentText>
                                                            Payment plan...
                                                            </DialogContentText>
                                                            <TextField
                                                                className={`${classes.formControl} ${classes.marginTop3}`}
                                                                name="depositAmount"
                                                                id="depositAmount"
                                                                value={convertForm.depositAmount}
                                                                onChange={this.updateConvertData}
                                                                label="Advance Payment"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.depositAmount}
                                                                helperText={errors.depositAmount}
                                                                InputProps={{
                                                                    startAdornment:
                                                            <InputAdornment
                                                                disableTypography
                                                                className={classes.inputAdornment}
                                                                position="start"
                                                            >
                                                                            £
                                                            </InputAdornment>,
                                                                }}
                                                            />
                                                            <TextField
                                                                className={`${classes.formControl} ${classes.marginTop3}`}
                                                                name="deliveryAmount"
                                                                id="deliveryAmount"
                                                                value={convertForm.deliveryAmount}
                                                                onChange={this.updateConvertData}
                                                                label="Delivery Fee"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.deliveryAmount}
                                                                helperText={errors.deliveryAmount}
                                                                InputProps={{
                                                                    startAdornment:
                                                            <InputAdornment
                                                                disableTypography
                                                                className={classes.inputAdornment}
                                                                position="start"
                                                            >
                                                                            £
                                                            </InputAdornment>,
                                                                }}
                                                            />
                                                            <TextField
                                                                className={`${classes.formControl} ${classes.marginTop3}`}
                                                                name="agentFee"
                                                                id="agentFee"
                                                                value={convertForm.agentFee}
                                                                onChange={this.updateConvertData}
                                                                label="Agent Fee"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.agentFee}
                                                                helperText={errors.agentFee}
                                                                InputProps={{
                                                                    startAdornment:
                                                            <InputAdornment
                                                                disableTypography
                                                                className={classes.inputAdornment}
                                                                position="start"
                                                            >
                                                                            £
                                                            </InputAdornment>,
                                                                }}
                                                            />
                                                            <FormControlLabel
                                                                control={(
                                                                    <Checkbox
                                                                        name="agentCollectedFee"
                                                                        id="agentCollectedFee"
                                                                        onChange={this.handleAgentCollectedFee}
                                                                    />
                                                                )}
                                                                label="External agent collected fee"
                                                            />
                                                            {convertForm.type === 'Repayment'
                                                                ? (
                                                                    <React.Fragment>
                                                                        <TextField
                                                                            className={`${classes.formControl} ${classes.marginTop3}`}
                                                                            name="repaymentAmount"
                                                                            id="repaymentAmount"
                                                                            value={convertForm.repaymentAmount}
                                                                            onChange={this.updateConvertData}
                                                                            label="Fixed Term Amount"
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                            error={!!errors.repaymentAmount}
                                                                            helperText={errors.repaymentAmount}
                                                                            InputProps={{
                                                                                startAdornment:
                                                                        <InputAdornment
                                                                            disableTypography
                                                                            className={classes.inputAdornment}
                                                                            position="start"
                                                                        >
                                                                                    £
                                                                        </InputAdornment>,
                                                                            }}
                                                                        />
                                                                        <TextField
                                                                            className={`${classes.formControl} ${classes.marginTop3}`}
                                                                            name="weeklyRepaymentAmount"
                                                                            id="weeklyRepaymentAmount"
                                                                            value={convertForm.weeklyRepaymentAmount}
                                                                            onChange={this.updateConvertData}
                                                                            label="Weekly Repayment Rate"
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                            error={!!errors.weeklyRepaymentAmount}
                                                                            helperText={errors.weeklyRepaymentAmount}
                                                                            InputProps={{
                                                                                startAdornment:
                                                                        <InputAdornment
                                                                            disableTypography
                                                                            className={classes.inputAdornment}
                                                                            position="start"
                                                                        >
                                                                                    £
                                                                        </InputAdornment>,
                                                                            }}
                                                                        />
                                                                    </React.Fragment>

                                                                )
                                                                : (
                                                                    <TextField
                                                                        className={`${classes.formControl} ${classes.marginTop3}`}
                                                                        name="weeklyRentalAmount"
                                                                        id="weeklyRentalAmount"
                                                                        value={convertForm.weeklyRentalAmount}
                                                                        onChange={this.updateConvertData}
                                                                        label="Weekly Rental Rate"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                        error={!!errors.weeklyRentalAmount}
                                                                        helperText={errors.weeklyRentalAmount}
                                                                        InputProps={{
                                                                            startAdornment:
                                                                        <InputAdornment
                                                                            disableTypography
                                                                            className={classes.inputAdornment}
                                                                            position="start"
                                                                        >
                                                                                £
                                                                        </InputAdornment>,
                                                                        }}
                                                                    />
                                                                )
                                                            }
                                                            <FormControl
                                                                className={classes.formControl}
                                                                variant="outlined"
                                                                error={!!errors.status}
                                                            >
                                                                <InputLabel htmlFor="scheduleFrequency" className={classes.inputLabel}>Schedule Frequency</InputLabel>
                                                                <Select
                                                                    keyboard="true"
                                                                    required
                                                                    input={(
                                                                        <OutlinedInput
                                                                            onChange={this.updateConvertData}
                                                                            name="scheduleFrequency"
                                                                            id="scheduleFrequency"
                                                                            value={convertForm.scheduleFrequency || ''}
                                                                        />
                                                                    )}
                                                                >
                                                                    {scheduleFrequencies.map(frequency => (
                                                                        <MenuItem
                                                                            key={frequency}
                                                                            value={frequency}
                                                                        >
                                                                            {frequency}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>

                                                            {convertForm.scheduleFrequency === 'Weekly' && (
                                                                <TextField
                                                                    className={`${classes.formControl} ${classes.marginTop3}`}
                                                                    name="scheduleInterval"
                                                                    id="scheduleInterval"
                                                                    value={convertForm.scheduleInterval}
                                                                    onChange={this.updateConvertData}
                                                                    label="Schedule Interval"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    error={!!errors.scheduleInterval}
                                                                    helperText={errors.scheduleInterval}
                                                                />
                                                            )}

                                                            {convertForm.scheduleFrequency === 'Weekly' && convertForm.type === 'Continuous' && (
                                                                <FormControl
                                                                    className={classes.formControl}
                                                                    variant="outlined"
                                                                    error={!!errors.status}
                                                                >
                                                                    <InputLabel htmlFor="scheduleWeekdayCode" className={classes.inputLabel}>Schedule Weekday</InputLabel>
                                                                    <Select
                                                                        keyboard="true"
                                                                        required
                                                                        input={(
                                                                            <OutlinedInput
                                                                                onChange={this.updateConvertData}
                                                                                name="scheduleWeekdayCode"
                                                                                id="scheduleWeekdayCode"
                                                                                value={convertForm.scheduleWeekdayCode || ''}
                                                                            />
                                                                        )}
                                                                    >
                                                                        {scheduleWeekdays.map(day => (
                                                                            <MenuItem
                                                                                key={day.value}
                                                                                value={day.value}
                                                                            >
                                                                                {day.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            )}

                                                            {convertForm.scheduleFrequency === 'Monthly' && convertForm.type === 'Continuous' && (
                                                                <TextField
                                                                    className={`${classes.formControl} ${classes.marginTop3}`}
                                                                    name="scheduleDay"
                                                                    id="scheduleDay"
                                                                    value={convertForm.scheduleDay || 1}
                                                                    onChange={this.updateConvertData}
                                                                    label="Schedule Day of Month"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    error={!!errors.scheduleDay}
                                                                    helperText={errors.scheduleDay}
                                                                />
                                                            )}

                                                            {convertForm.type === 'Repayment' && (convertForm.scheduleFrequency === 'Weekly' || convertForm.scheduleFrequency === 'Monthly')
                                                                && (
                                                                    <div className={classes.datePicker}>
                                                                        <DatePicker
                                                                            name="firstPaymentDate"
                                                                            id="firstPaymentDate"
                                                                            margin="normal"
                                                                            label="First Payment Date"
                                                                            value={convertForm.firstPaymentDate}
                                                                            onChange={this.handleFirstPaymentDateChange}
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            format="DD-MM-YYYY"
                                                                            disablePast
                                                                        />
                                                                    </div>
                                                                )}

                                                            {((convertForm.type === 'Repayment' && convertForm.firstPaymentDate) || (convertForm.type === 'Continuous' && convertForm.scheduleFrequency)) && (
                                                                <DialogContentText className={classes.marginTop3}>
                                                                    This plan charges the customer £
                                                                    {convertForm.scheduleFrequency === 'Weekly' && (
                                                                        <React.Fragment>
                                                                            {convertForm.recurringPaymentAmount}
                                                                            {' '}
                                                                            every
                                                                            {' '}
                                                                        </React.Fragment>
                                                                    )}
                                                                    {(convertForm.scheduleFrequency === 'Weekly' && convertForm.scheduleInterval > 1)
                                                                        ? (
                                                                            <React.Fragment>
                                                                                {convertForm.scheduleInterval}
                                                                                {' '}
                                                                                weeks
                                                                                {' '}
                                                                            </React.Fragment>
                                                                        )
                                                                        : null
                                                                    }
                                                                    {(convertForm.scheduleFrequency === 'Weekly' && convertForm.scheduleInterval === 1)
                                                                        ? (
                                                                            <React.Fragment>
                                                                                week
                                                                                {' '}
                                                                            </React.Fragment>
                                                                        )
                                                                        : null
                                                                    }
                                                                    {convertForm.scheduleFrequency === 'Weekly' && (
                                                                        <React.Fragment>
                                                                             on a
                                                                            {' '}
                                                                            {convertForm.type === 'Continuous'
                                                                                ? scheduleWeekdays.find(day => (day.value === convertForm.scheduleWeekdayCode)).label
                                                                                : moment(convertForm.firstPaymentDate).format('dddd')
                                                                            }
.
                                                                        </React.Fragment>
                                                                    )}
                                                                    {convertForm.scheduleFrequency === 'Monthly' && (
                                                                        <React.Fragment>
                                                                            {(((convertForm.type === 'Continuous' ? convertForm.weeklyRentalAmount : convertForm.weeklyRepaymentAmount) * 52) / 12).toFixed(2)}
                                                                            &nbsp;every month on the
                                                                            {' '}
                                                                            {this.ordinalSuffixOf((convertForm.type === 'Continuous' ? convertForm.scheduleDay : moment(convertForm.firstPaymentDate).format('D')))}
.
                                                                        </React.Fragment>
                                                                    )}
                                                                </DialogContentText>
                                                            )}
                                                            <Button
                                                                variant="contained"
                                                                onClick={this.handleConvertNextClick}
                                                                className={classes.dialogButton}
                                                                disabled={(convertForm.type === 'Continuous' && !convertForm.scheduleFrequency)
                                                                    || (convertForm.type === 'Repayment' && !convertForm.firstPaymentDate)}
                                                            >
                                                        NEXT
                                                            </Button>
                                                        </React.Fragment>
                                                    )
                                                    : null
                                                }

                                                {convertStage === 'sale'
                                                    ? (
                                                        <React.Fragment>
                                                            <DialogContentText>
                                                            Sale Details...
                                                            </DialogContentText>
                                                            <TextField
                                                                className={`${classes.formControl} ${classes.marginTop3}`}
                                                                name="depositAmount"
                                                                id="depositAmount"
                                                                value={convertForm.depositAmount}
                                                                onChange={this.updateConvertData}
                                                                label="Total Product Amount"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.depositAmount}
                                                                helperText={errors.depositAmount}
                                                                InputProps={{
                                                                    startAdornment:
                                                            <InputAdornment
                                                                disableTypography
                                                                className={classes.inputAdornment}
                                                                position="start"
                                                            >
                                                                            £
                                                            </InputAdornment>,
                                                                }}
                                                            />
                                                            <TextField
                                                                className={`${classes.formControl} ${classes.marginTop3}`}
                                                                name="deliveryAmount"
                                                                id="deliveryAmount"
                                                                value={convertForm.deliveryAmount}
                                                                onChange={this.updateConvertData}
                                                                label="Delivery Fee"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.deliveryAmount}
                                                                helperText={errors.deliveryAmount}
                                                                InputProps={{
                                                                    startAdornment:
                                                            <InputAdornment
                                                                disableTypography
                                                                className={classes.inputAdornment}
                                                                position="start"
                                                            >
                                                                            £
                                                            </InputAdornment>,
                                                                }}
                                                            />
                                                            <TextField
                                                                className={`${classes.formControl} ${classes.marginTop3}`}
                                                                name="agentFee"
                                                                id="agentFee"
                                                                value={convertForm.agentFee}
                                                                onChange={this.updateConvertData}
                                                                label="Agent Fee"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.agentFee}
                                                                helperText={errors.agentFee}
                                                                InputProps={{
                                                                    startAdornment:
                                                            <InputAdornment
                                                                disableTypography
                                                                className={classes.inputAdornment}
                                                                position="start"
                                                            >
                                                                            £
                                                            </InputAdornment>,
                                                                }}
                                                            />
                                                            <FormControlLabel
                                                                control={(
                                                                    <Checkbox
                                                                        name="agentCollectedFee"
                                                                        id="agentCollectedFee"
                                                                        onChange={this.handleAgentCollectedFee}
                                                                    />
                                                                )}
                                                                label="External agent collected fee"
                                                            />
                                                            <Button
                                                                variant="contained"
                                                                onClick={this.handleConvertNextClick}
                                                                className={classes.dialogButton}
                                                                disabled={(convertForm.type === 'Continuous' && !convertForm.scheduleFrequency)
                                                                    || (convertForm.type === 'Repayment' && !convertForm.firstPaymentDate)}
                                                            >
                                                        NEXT
                                                            </Button>
                                                        </React.Fragment>
                                                    )
                                                    : null
                                                }

                                                {convertStage === 'deposit'
                                                    ? (
                                                        <React.Fragment>
                                                            <DialogContentText>
                                                            Initial Payment
                                                            </DialogContentText>
                                                            <FormControl
                                                                component="fieldset"
                                                                className={`${classes.formControl} ${classes.radioButtonsContainer}`}
                                                            >
                                                                <RadioGroup
                                                                    aria-label="depositPayment"
                                                                    name="depositPayment"
                                                                    className={classes.group}
                                                                    value={convertForm.depositPayment}
                                                                    onChange={this.updateConvertData}
                                                                >
                                                                    <FormControlLabel
                                                                        value="paymentNow"
                                                                        control={<Radio />}
                                                                        label="Take deposit payment now"
                                                                    />
                                                                    <Typography
                                                                        paragraph
                                                                        color="textSecondary"
                                                                        className={classes.radioDescription}
                                                                    >
                                                                    You will be presented with payment options.
                                                                    </Typography>
                                                                    <FormControlLabel
                                                                        value="paymentLater"
                                                                        control={<Radio />}
                                                                        label="Take deposit payment later"
                                                                    />
                                                                    <Typography
                                                                        paragraph
                                                                        color="textSecondary"
                                                                        className={classes.radioDescription}
                                                                    >
                                                                    Customer will need to arrange initial payment before product delivery.
                                                                    </Typography>
                                                                </RadioGroup>
                                                            </FormControl>
                                                            {convertForm.depositPayment === 'paymentNow'
                                                                ? (
                                                                    <React.Fragment>
                                                                        <DialogContentText>
                                                                            Choose payment type...
                                                                        </DialogContentText>
                                                                        <FormControl
                                                                            component="fieldset"
                                                                            className={`${classes.formControl} ${classes.radioButtonsContainer}`}
                                                                        >
                                                                            <RadioGroup
                                                                                aria-label="depositPaymentType"
                                                                                name="depositPaymentType"
                                                                                className={classes.group}
                                                                                value={convertForm.depositPaymentType}
                                                                                onChange={this.updateConvertData}
                                                                            >
                                                                                <FormControlLabel
                                                                                    value="overThePhone"
                                                                                    control={<Radio />}
                                                                                    label="Over the phone"
                                                                                />
                                                                                <Typography
                                                                                    paragraph
                                                                                    color="textSecondary"
                                                                                    className={classes.radioDescription}
                                                                                >
                                                                                Customer must have valid card with them now.
                                                                                </Typography>
                                                                                <FormControlLabel
                                                                                    disabled
                                                                                    value="linkByText"
                                                                                    control={<Radio />}
                                                                                    label={`Text payment link (${customer.mobileTelephoneNumber})`}
                                                                                />
                                                                                <Typography
                                                                                    paragraph
                                                                                    color="textSecondary"
                                                                                    className={classes.radioDescription}
                                                                                >
                                                                                Customer will receive a link, by text message, with which to make a secure payment.
                                                                                </Typography>
                                                                                <FormControlLabel
                                                                                    disabled
                                                                                    value="linkByEmail"
                                                                                    control={<Radio />}
                                                                                    label={`Email payment link (${customer.emailAddress})`}
                                                                                />
                                                                                <Typography
                                                                                    paragraph
                                                                                    color="textSecondary"
                                                                                    className={classes.radioDescription}
                                                                                >
                                                                                Customer will receive a link, by email, with which to make a secure payment.
                                                                                </Typography>
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </React.Fragment>
                                                                )
                                                                : null
                                                            }
                                                            <Button
                                                                variant="contained"
                                                                className={classes.dialogButton}
                                                                onClick={this.handleConvertConfirmClick}
                                                                disabled={disableConvertConfirmButton || !convertForm.depositPayment || (convertForm.depositPayment === 'paymentNow' && !convertForm.depositPaymentType)}
                                                            >
                                                            CONFIRM
                                                            </Button>
                                                        </React.Fragment>
                                                    )
                                                    : null
                                                }
                                            </form>
                                        </React.Fragment>
                                    </div>
                                    <Divider />
                                </DialogContent>
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleCloseConvertLeadDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog
                                open={showDepositPaymentDialog}
                                onClose={this.handleCloseDepositPaymentDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <React.Fragment>
                                            <Typography variant="h6" gutterBottom>
                                                Initial Payment
                                            </Typography>
                                            <DialogContentText>
                                                Enter the payment amount
                                                <DialogContentText />
                                                Deposit :
                                                {' '}
                                                {convertForm.depositAmount}
                                                <DialogContentText />
                                                Delivery :
                                                {' '}
                                                {convertForm.deliveryAmount}
                                                <DialogContentText />
                                                Agent Fee :
                                                {' '}
                                                {convertForm.agentCollectedFee ? 'Already collected' : convertForm.agentFee}
                                            </DialogContentText>
                                            <form onSubmit={this.handleSubmitDepositPayment}>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={`${classes.formControl} ${classes.marginTop3}`}
                                                            name="amount"
                                                            id="amount"
                                                            value={depositPaymentForm.amount}
                                                            onChange={this.updateDepositPaymentData}
                                                            label="Amount"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            error={!!errors.weeklyRentalAmount}
                                                            helperText={errors.weeklyRentalAmount}
                                                            InputProps={{
                                                                startAdornment:
                            <InputAdornment
                                disableTypography
                                className={classes.inputAdornment}
                                position="start"
                            >
                                                                        £
                            </InputAdornment>,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Button
                                                    variant="contained"
                                                    className={classes.dialogButton}
                                                    type="submit"
                                                    disabled={disableSubmitDepositPaymentButton}
                                                >
                                                    SUBMIT
                                                </Button>
                                            </form>
                                        </React.Fragment>
                                    </div>
                                    <Divider />
                                </DialogContent>
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleCloseDepositPaymentDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </React.Fragment>
                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )
                }
            </Tile>
        );
    }
}

export default withSnackbar(withStyles(styles)(CustomerLeads));
