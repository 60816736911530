import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
    isEmpty,
} from 'lodash';
import Barcode from 'react-barcode';
import fetch, { fetchJson2 } from '../../utilities/fetch';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    dialogContentContainer: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    dialogContent: {
        minWidth: 800,
        paddingBottom: 16,
    },
    dialogButton: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 4,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        margin: '0px 0px 0px 0px !important',
        padding: '8px 24px',
    },
    dialogDivider: {
        marginBottom: theme.spacing.unit * 2,
        marginLeft: -24,
        marginRight: -24,
        marginTop: theme.spacing.unit * 2,
    },
    disabled: {
        opacity: 0.26,
    },
    dialogExpiredCheckBox: {
        textAlign: 'right',
    },
    checkBox: {
        fontSize: 16,
    },
    dialogTitle: {
        marginBottom: theme.spacing.unit * 2,
    },
    barcode: {
        marginLeft: 150,
    },
    dimensionX: {
        paddingLeft: 43,
        paddingTop: 33,
    },
    image: {
        width: '100%',
    },
});

class CuttingItemImageDialog extends React.Component {
    state = {
    };

    componentDidMount() {
    }

    render() {
        const {
            classes,
            handleCloseCuttingItemImageDialogClick,
            visitItem,
            visitItemLoading,
            showCuttingItemImageDialog,
        } = this.props;

        const {
        } = this.state;

        return (
            <>
                {!visitItemLoading && !isEmpty(visitItem)
                    ? (
                        <Dialog
                            open={showCuttingItemImageDialog}
                            onClose={() => handleCloseCuttingItemImageDialogClick()}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            className={classes.newManufacturerDialog}
                            maxWidth="lg"
                        >
                            <DialogContent className={classes.dialogContent}>
                                <div className={classes.dialogContentContainer}>
                                    <>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            className={classes.dialogTitle}
                                        >
                                            Lead Images
                                        </Typography>
                                        <Grid container spacing={0}>
                                            <Grid item lg={12}>
                                                <img
                                                    className={classes.image}
                                                    src={(visitItem.agreement.lead.images[0] || {}).link}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                </div>
                            </DialogContent>
                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    onClick={() => handleCloseCuttingItemImageDialogClick()}
                                    color="primary"
                                    autoFocus
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                    : ''}
            </>

        );
    }
}

export default withSnackbar(withStyles(styles)(CuttingItemImageDialog));
