import React from 'react';
import {
    Button,
    FormControl,
    FormHelperText,
    MenuItem,
    OutlinedInput,
    InputLabel,
    Select,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';

const styles = theme => ({
    textButton: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: 0,
    },
    formHelperText: {
        color: '#f44336',
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    formInstructions: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
    },
    nextButton: {
        width: '90%',
    },
    nextButtonContainer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100vw',
        marginBottom: theme.spacing.unit * 2,
        textAlign: 'center',
    },
});

class DeliveryCustomer extends React.Component {
    state = {
        customerForm: {},
    }

    handleAddHistoryNoteClick = () => {
        this.setState({
            showAddHistoryNoteDialog: true,
        });
    }

    handleAddHistoryNoteCloseDialogClick = () => {
        this.setState({
            showAddHistoryNoteDialog: false,
        });
    }

    updateCustomerData = (event) => {
        const { value, name } = event.target;
        // const contactFullName = this.props.customers.find(customer => customer.code === value).fullName;
        this.setState({
            customerForm: {
                ...this.state.customerForm,
                [name]: value,
                // contactFullName: contactFullName,
            },
        });
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.props.setCustomer(this.state.customerForm.contactCode).then(() => {
        }).catch(() => {
            console.log('Error setting customer');
        });
    }

    render() {
        const {
            classes,
            customers,
            delivery,
            disableNextButton,
        } = this.props;
        const {
            customerForm,
        } = this.state;
        return (
            <React.Fragment>
                <Typography variant="h6">
                    Customer
                </Typography>
                <Typography variant="body1" className={classes.formInstructions}>
                    Select delivery customer
                </Typography>
                <form onSubmit={this.handleFormSubmit}>
                    <FormControl
                        className={classes.formControl}
                        variant="outlined"
                        fullWidth
                        required
                        error={!!delivery.errors.customer}
                    >
                        <InputLabel htmlFor="contactCode" className={classes.inputLabel}>Customer</InputLabel>
                        <Select
                            keyboard="true"
                            input={(
                                <OutlinedInput
                                    onChange={this.updateCustomerData}
                                    name="contactCode"
                                    id="contactCode"
                                    value={customerForm.contactCode || ''}
                                />
                            )}
                        >
                            {customers.map(customer => (
                                <MenuItem
                                    key={customer.code}
                                    value={customer.code}
                                >
                                    {customer.fullName}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{delivery.errors.customer}</FormHelperText>
                    </FormControl>
                    {customerForm.contactCode != null
                        ? <div className={classes.nextButtonContainer}>
                            <Button
                                variant="contained"
                                className={classes.nextButton}
                                type="submit"
                                disabled={disableNextButton}
                            >
                                NEXT
                            </Button>
                        </div>
                        : null
                    }
                </form>
            </React.Fragment>
        );
    }
}

const customersShape = {
    code: PropTypes.number,
    creationDate: PropTypes.string,
    fullAddress: PropTypes.string,
    fullName: PropTypes.string,
    lastPaymentTransactionStatus: PropTypes.string,
    shortAddress: PropTypes.string,
};

DeliveryCustomer.propTypes = {
    classes: PropTypes.object.isRequired,
    customers: PropTypes.arrayOf(customersShape).isRequired,
    delivery: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(DeliveryCustomer));
