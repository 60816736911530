import React from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormHelperText,
    Grid,
    InputAdornment,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';

import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import HybridTextField from './hybridTextField';
import ErrorIcon from '@material-ui/icons/ErrorOutline';

const styles = theme => ({
    alert: {
        backgroundColor: '#F44336',
        color: '#ffffff',
    },
    alertArrears: {
        fontSize: 28,
        fontWeight: 500,
    },
    backgroundIcon: {
        display: 'inline-block',
        position: 'absolute',
        fontSize: 120,
        transform: 'translate(-50%, 25%)',
        color: 'rgba(255, 255, 255, .1)',
    },
    backgroundIconContainer: {
        textAlign: 'center',
    },
    card: {
        height: 165,
        width: 140,
        margin: theme.spacing.unit * 1.5,
    },
    cardContent: {
        position: 'relative',
        // textAlign: 'center',
    },
    more: {
        marginTop: 5,
        color: 'rgba(0, 0, 0, .5)',
        fontWeight: 500,
    },
    root: {
        flexGrow: 1,
    },
    title: {
        fontSize: 12,
        fontWeight: 500,
        color: 'rgba(255, 255, 255, 1)',
    },

    // row: {
    //     '&:nth-of-type(odd)': {
    //         backgroundColor: theme.palette.background.default,
    //     },
    // },
    // tableHead: {
    //     fontWeight: 'bold',
    //     fontSize: 14,
    // },
    // paper: {
    //     width: '100%',
    //     marginTop: theme.spacing.unit * 3,
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     overflowX: 'auto',
    //     padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    // },
    // divider: {
    //     marginTop: theme.spacing.unit * 3,
    //     marginBottom: theme.spacing.unit * 2,
    // },
    // dialogDivider: {
    //     marginTop: theme.spacing.unit * 2.5,
    //     marginBottom: theme.spacing.unit * 2,
    // },
    // bottomBarContainer: {
    //     width: '100%',
    //     textAlign: 'right',
    // },
    // textButton: {
    //     marginTop: theme.spacing.unit * 1.5,
    //     marginBottom: 0,
    // },
    // dialogActions: {
    //     marginRight: theme.spacing.unit * 2.5,
    // },
    // createdByFullNameField: {
    //     width: 350,
    //     marginLeft: theme.spacing.unit * 3,
    // },
    // createdDateField: {
    //     width: 120,
    // },
    // createdTimeField: {
    //     width: 120,
    //     marginLeft: theme.spacing.unit * 3,
    // },
    // tileBottomDivider: {
    //     marginTop: theme.spacing.unit * 3,
    // },
    // emailField: {
    //     width: 350,
    // },
    // rightOfFieldButton: {
    //     marginTop: 25,
    //     marginLeft: theme.spacing.unit * 3,
    // },
    // dialogContentContainer: {
    //     marginTop: theme.spacing.unit * 2,
    //     marginBottom: theme.spacing.unit * 2,
    // },
    // dialogButton: {
    //     marginTop: theme.spacing.unit * 2,
    //     marginBottom: theme.spacing.unit * 1,
    // },
    // dialogContent: {
    //     paddingBottom: 0,
    // },
    // dialogActionsDivider: {
    //     marginLeft: theme.spacing.unit * 3,
    //     marginRight: theme.spacing.unit * 3,
    // },
    // formHelperText: {
    //     color: '#f44336',
    // },
});

class ActionCards extends React.Component {
    state = {
        showAddHistoryNoteDialog: false,
        disableAddHistoryNoteSaveButton: false,
        historyForm: {
            note: '',
        },
    }

    handleAddHistoryNoteClick = () => {
        this.setState({
            showAddHistoryNoteDialog: true,
        });
    }

    handleAddHistoryNoteCloseDialogClick = () => {
        this.setState({
            showAddHistoryNoteDialog: false,
        });
    }

    titalise = string => string.toLowerCase()
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')

    capitaliseFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

    updateAddHistoryNoteData = (event) => {
        const { value, name } = event.target;
        let formattedValue;
        switch (name) {
        case 'note':
            formattedValue = value.capitaliseFirstLetter();
            break;
        }
        event.target.value = formattedValue;

        this.setState({
            historyForm: {
                ...this.state.historyForm,
                [name]: formattedValue,
            },
        });
    }

    handleAddHistoryNote = (event) => {
        event.preventDefault();
        this.setState({
            disableSaveHistoryNoteButton: true,
        });
        this.props.addHistoryNote(this.state.historyForm).then(() => {
            this.setState({
                showAddHistoryNoteDialog: false,
                // ADD NEW NOTE TO DISPLAY
                disableSaveHistoryNoteButton: false,
            });
            this.props.enqueueSnackbar('History note saved', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('History note save failed', { variant: 'error' });
            this.setState({
                disableSaveHistoryNoteButton: false,
            });
        });
    }

    render() {
        const {
            classes,
            history,
            errors,
        } = this.props;
        const {
            showAddHistoryNoteDialog,
            historyForm,
            disableSaveHistoryNoteButton,
        } = this.state;

        return (
            <React.Fragment>
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={2}>
                            {[0, 1, 2].map(value => (
                                <Grid key={value} item>
                                    <Card
                                        elevation={2}
                                        className={[classes.card, classes.alert]}
                                    >
                                        <div className={classes.backgroundIconContainer}>
                                            <ErrorIcon
                                                className={classes.backgroundIcon}
                                                fontSize="inherit"
                                            />
                                        </div>
                                        <CardContent className={classes.cardContent}>
                                            <Typography
                                                color="inherit"
                                                className={classes.title}
                                                gutterBottom
                                                align="center"
                                                >
                                                ARREARS ALERT
                                            </Typography>
                                            <Typography
                                                color="inherit"
                                                variant="h6"
                                                align="center"
                                                >
                                                RA1100
                                            </Typography>
                                            <Typography
                                                className={classes.alertArrears}
                                                color="inherit"
                                                variant="h5"
                                                align="center"
                                                >
                                                £13.00
                                            </Typography>
                                            <Typography
                                                color="inherit"
                                                variant="body2"
                                                align="center"
                                                >
                                                (3 payments)
                                            </Typography>
                                            <Typography
                                                className={classes.more}
                                                color="inherit"
                                                variant="body2"
                                                align="right"
                                                >
                                                more
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

const historyShape = {
    code: PropTypes.number,
    createdByFullName: PropTypes.string,
    createdDate: PropTypes.string,
    createdDateTime: PropTypes.string,
    createdTime: PropTypes.string,
    note: PropTypes.string,
};

ActionCards.propTypes = {
    classes: PropTypes.object.isRequired,
    saveContactHistory: PropTypes.func.isRequired,
    history: PropTypes.arrayOf(historyShape),

    errors: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(ActionCards));
